import React from 'react';
import SupplierPanel from './SupplierPanel';
import LoanApplication from './LoanApplication';
import SupportingDocuments from './SupportingDocuments';
import StepWrapper from './TradeLoanWrapper';
import InvoicePanel from './InvoicePanel';
const TradeLoanForm = ({ currentStep, isAdmin = true, currencyOptions, supplierCurrencyOptions, invoiceCurrencyOptions, supplierOptions, loanTermOptions, dateOfShippingSettings, invoiceDateSettings, suppliersState, tradeLoanState, handleSupplierUpdate, handleSupplierDetailUpdate, handleSupplierCurrencyUpdate, handlePaymentDetailUpdate, handleConfirmPaymentDetailsToggle, dispatch, handleTradeLoanDetailUpdate, handleTotalAmountChange, handleInvoiceDetailUpdate, handleRemoveFile, handleFileChange, handleFileReplace, handleInvoiceAmountValueChange, handleInvoiceFocus, handleInvoiceCurrencyOptionChange, handleInvoiceFileChange, handleInvoiceFileReplace, handleRemoveInvoiceFile, handleTradeLoanPaymentCurrencyUpdate, handleMaximumLoanTermUpdate, removeInvoice }) => {
    const { suppliers, selectedSupplierOptions, selectedCurrencyOptions, paymentDetailsList, supplierDetailsErrors, paymentDetailsErrors, invoices, selectedInvoiceCurrencyOptions, invoiceAmountValues, invoiceErrors, invoiceDocumentErrors } = suppliersState;
    const { tradeLoan, amountValues, tradeLoanErrors, documentErrors } = tradeLoanState;
    return (React.createElement("div", { className: 'form', "data-testid": 'trade-loan-form' },
        React.createElement(StepWrapper, { currentStep: currentStep, stepNumber: 1 },
            React.createElement(LoanApplication, { ...{
                    tradeLoanErrors,
                    currencyOptions,
                    dateOfShippingSettings,
                    handleTradeLoanDetailUpdate,
                    handleTotalAmountChange,
                    handleTradeLoanPaymentCurrencyUpdate,
                    handleMaximumLoanTermUpdate,
                    loanTermOptions,
                    isAdmin
                }, currentTradeLoanDetails: tradeLoan, currentTotalAmount: amountValues.totalAmountCents })),
        React.createElement(StepWrapper, { currentStep: currentStep, stepNumber: 2 },
            suppliers.map((supplier, index) => (React.createElement(SupplierPanel, { ...{
                    index,
                    supplierCurrencyOptions,
                    supplierOptions,
                    dispatch,
                    handleSupplierUpdate,
                    handleSupplierDetailUpdate,
                    handleSupplierCurrencyUpdate,
                    handlePaymentDetailUpdate,
                    handleConfirmPaymentDetailsToggle
                }, supplierDetailsErrors: supplierDetailsErrors[index], currentSupplierDetails: supplier, selectedSupplierOption: selectedSupplierOptions[index], selectedSupplierCurrency: selectedCurrencyOptions[index], paymentDetailsErrors: paymentDetailsErrors[index], currentPaymentDetails: paymentDetailsList[index], displayRemoveButton: suppliers.length > 1, key: index }))),
            React.createElement("button", { className: 'custom-button button -add-trade-loan-supplier', onClick: () => dispatch({ type: 'ADD_SUPPLIER' }) },
                React.createElement("span", { className: 'add' }, "+"),
                "Add another supplier")),
        React.createElement(StepWrapper, { currentStep: currentStep, stepNumber: 3 }, suppliers.map((supplier, index) => (React.createElement(InvoicePanel, { ...{
                supplier,
                index,
                invoiceDateSettings,
                invoiceCurrencyOptions,
                handleInvoiceDetailUpdate,
                handleInvoiceAmountValueChange,
                handleInvoiceFocus,
                handleInvoiceCurrencyOptionChange,
                handleInvoiceFileChange,
                handleInvoiceFileReplace,
                handleRemoveInvoiceFile,
                dispatch,
                removeInvoice
            }, invoices: invoices[index], invoiceErrors: invoiceErrors[index], invoiceDocumentErrors: invoiceDocumentErrors[index], selectedInvoiceCurrencyOptions: selectedInvoiceCurrencyOptions[index], invoiceAmountValues: invoiceAmountValues[index], key: index })))),
        React.createElement(StepWrapper, { currentStep: currentStep, stepNumber: 4 },
            React.createElement(SupportingDocuments, { ...{
                    tradeLoanErrors,
                    documentErrors,
                    handleRemoveFile,
                    handleFileChange,
                    handleFileReplace
                }, currentTradeLoanDetails: tradeLoan }))));
};
export default TradeLoanForm;
