import React from 'react';
import classNames from 'classnames';
import DaysPastIssue from '../../components/invoice-status/DaysPastIssue';
import InvoiceStatus from '../../components/invoice-status/InvoiceStatus';
import VerificationToggle from './VerificationToggle';
import ApprovalStatusToggle from './ApprovalStatusToggle';
import InvoiceActions from './InvoiceActions';
const InvoiceRow = ({ apiToken, invoice, selected, borrowerId, disapprovalReasonsOptions, borrowerInvoicesPath, customersPath, handleInvoiceId, handleApprove, handleUnapprove, handleVerify, handleUnverify, isXeroUser = false }) => {
    const { customerId } = invoice;
    const handleCheckboxChange = () => {
        handleInvoiceId(invoice.id);
    };
    const rowClass = classNames({
        selected: selected
    });
    const customerLink = `${customersPath}/${customerId}`;
    return (React.createElement("tr", { className: rowClass, "data-testid": 'invoice-row' },
        React.createElement("td", { className: 'cell' },
            React.createElement("label", { className: 'label -checkbox' },
                React.createElement("input", { type: 'checkbox', checked: selected, onChange: handleCheckboxChange, "data-testid": 'invoice-checkbox' }))),
        React.createElement("td", { className: 'cell' }, invoice.number),
        React.createElement("td", { className: 'cell' },
            React.createElement("a", { href: customerLink }, invoice.customerName)),
        isXeroUser && React.createElement("td", { className: 'cell' }, invoice.reference),
        React.createElement("td", { className: 'cell' }, `${invoice.amountDueCurrency} ${invoice.amountDueFormatted}`),
        React.createElement("td", { className: 'cell' }, invoice.issueDateFormattedString),
        React.createElement("td", { className: 'cell' },
            React.createElement(DaysPastIssue, { daysSinceIssue: invoice.daysSinceIssue })),
        React.createElement("td", { className: 'cell' },
            React.createElement(InvoiceStatus, { daysDue: invoice.daysDue, dueDate: invoice.dueDateFormattedString, isPaid: invoice.status === 'PAID', paidOnDate: invoice.paidOnDateFormattedString, displayPaidStatus: true })),
        React.createElement("td", { className: 'cell' }, invoice.commentsCount),
        React.createElement("td", { className: 'cell' },
            React.createElement(VerificationToggle, { isVerified: invoice.verified, invoiceId: invoice.id, handleVerify: handleVerify, handleUnverify: handleUnverify })),
        React.createElement("td", { className: 'cell' },
            React.createElement(ApprovalStatusToggle, { invoiceId: invoice.id, isApproved: invoice.approved, allowedForApprovalStatusToggle: invoice.allowedForApprovalStatusToggle, handleApprove: handleApprove, handleUnapprove: handleUnapprove, disapprovalReasons: invoice.disapprovalReasons, disapprovalReasonsOptions: disapprovalReasonsOptions })),
        React.createElement("td", { className: 'cell' },
            React.createElement(InvoiceActions, { authToken: apiToken, borrowerId: borrowerId, borrowerInvoicesPath: borrowerInvoicesPath, invoice: invoice }))));
};
export default InvoiceRow;
