import fetch from 'isomorphic-fetch';
import { serialize } from 'object-to-formdata';
export const createSupplier = async (apiToken, urlPath, body) => {
    const endpoint = `/api/${urlPath}/trade_loan_suppliers`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'POST',
        body: serialize(body)
    }).then((res) => res.json());
};
export const updateSupplier = async (apiToken, urlPath, tradeLoanSupplierId, body) => {
    const endpoint = `/api/${urlPath}/trade_loan_suppliers/${tradeLoanSupplierId}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: serialize(body)
    }).then((res) => res.json());
};
export const removeSupplierFile = async (apiToken, urlPath, tradeLoanSupplierId, body) => {
    const endpoint = `/api/${urlPath}/trade_loan_suppliers/${tradeLoanSupplierId}/remove_file`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const approveTradeLoanSupplier = async (apiToken, companyId, supplierId) => {
    const endpoint = `/api/admin/companies/${companyId}/trade_loan_suppliers/${supplierId}/approve`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const unapproveTradeLoanSupplier = async (apiToken, companyId, supplierId) => {
    const endpoint = `/api/admin/companies/${companyId}/trade_loan_suppliers/${supplierId}/unapprove`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
