import React from 'react';
import { getIndividualPath } from '../../utils/getIndividualPath';
const Tab = ({ name, path }) => {
    const { pathname, search } = window.location;
    const windowPath = pathname + search;
    const isInnerPathMatched = getIndividualPath(path, windowPath) || windowPath === path;
    const tabClass = isInnerPathMatched ? 'tab-link tab -active' : 'tab-link tab';
    return (React.createElement("a", { href: path, className: tabClass, "data-testid": 'tab-link' },
        React.createElement("div", { className: 'name' }, name)));
};
export default Tab;
