// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// require('@rails/ujs').start()
// require('@rails/activestorage').start()
// require('channels')
import RailsUJS from '@rails/ujs';
RailsUJS.start();
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = name => images(name, true)
// report js errors on bugsnag
import '../src/bugsnag';
// include third party js libraries entry point
import '../src/vendor';
// include the main javascript entry point
import '../src/app';
// incude the main stylesheet entry point
import '../stylesheets/app';
import '../images/app';
import '@rails/actiontext';
import 'trix';
import './surveymonkey';
