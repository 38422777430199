import React from 'react';
const TradeLoanUnassignedTransactionRow = ({ transaction, selected, handleTransactionId }) => {
    const handleCheckboxChange = () => {
        handleTransactionId(transaction.id);
    };
    return (React.createElement("tr", { "data-testid": 'unassigned-transaction-row' },
        React.createElement("td", { className: 'cell' },
            React.createElement("label", { className: 'label -checkbox' },
                React.createElement("input", { type: 'checkbox', checked: selected, onChange: handleCheckboxChange, "data-testid": 'row-checkbox' }))),
        React.createElement("td", { className: 'cell' }, transaction.id),
        React.createElement("td", { className: 'cell' }, transaction.text),
        React.createElement("td", { className: 'cell' }, transaction.date),
        React.createElement("td", { className: 'cell' }, transaction.amount),
        React.createElement("td", { className: 'cell' }, transaction.status)));
};
export default TradeLoanUnassignedTransactionRow;
