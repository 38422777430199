import React from 'react';
import classNames from 'classnames';
const DisapprovalReasonTooltip = ({ isApproved, invoiceDisapprovalReasonOptions }) => {
    const iconClass = classNames('icon-toggle', {
        '-check': isApproved,
        '-close': !isApproved
    });
    return (React.createElement("div", { className: 'icon-toggle' },
        React.createElement("div", { className: iconClass },
            React.createElement("i", { className: 'icon' })),
        !isApproved && invoiceDisapprovalReasonOptions.length !== 0 && (React.createElement("div", { className: 'tooltip tooltip-panel' }, invoiceDisapprovalReasonOptions.map((reason, key) => {
            return (React.createElement("div", { className: 'title -center', key: key }, reason));
        })))));
};
export default DisapprovalReasonTooltip;
