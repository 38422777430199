import React, { useEffect, useRef, useState } from 'react';
import CustomersTable from '../../components/customers-table/CustomersTable';
import FundingSummaryTable from '../../components/funding-summary/FundingSummaryTable';
import debounce from 'lodash.debounce';
const BorrowerCustomersDashboard = ({ apiToken, isManualLedger, debtorFiles, generateExportedFilePath, deselectAllCustomers, deselectCustomer, fetchCustomers, fetchFundingSummary, selectAllCustomers, selectCustomer }) => {
    const [customersArray, setCustomersArray] = useState([]);
    const [fundingSummary, setFundingSummary] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(0);
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedFilterOptionValue, setSelectedFilterOptionValue] = useState('');
    const [isOpenedFilter, setIsOpenedFilter] = useState(false);
    const headerRef = useRef(null);
    useEffect(() => {
        fetchCustomerPage();
    }, [
        searchQuery,
        currentPage,
        sortBy,
        sortDirection,
        selectedFilterOptionValue
    ]);
    useEffect(() => {
        updateFundingSummary();
    }, [customersArray]);
    const fetchCustomerPage = async () => {
        setIsLoading(true);
        const { data } = await fetchCustomers(apiToken, currentPage, sortBy, sortDirection, searchQuery, selectedFilterOptionValue);
        const { page, pageSize, totalItems, totalPages } = data.attributes;
        setCustomersArray(page);
        setTotalPages(totalPages);
        setTotalItems(totalItems);
        setPageSize(pageSize);
        setIsLoading(false);
    };
    const updateCustomersArray = (updatedCustomer) => {
        const updatedCustomersArray = customersArray.map((customer) => {
            return updatedCustomer.id === customer.id
                ? { ...customer, is_selected: updatedCustomer.isSelected }
                : customer;
        });
        setCustomersArray(updatedCustomersArray);
    };
    const sortCustomers = (field, defaultSort) => {
        setSortBy(field);
        if (sortBy === field && sortDirection === 'asc') {
            setSortDirection('desc');
        }
        else if (sortBy === field && sortDirection === 'desc') {
            setSortDirection('asc');
        }
        else {
            setSortDirection(defaultSort || 'desc');
        }
    };
    const handlePageClick = data => {
        // add 1 since pages from fetchCustomersForBorrower have 1 base index while react-pagination are 0 base
        setCurrentPage(data.selected + 1);
    };
    const updateFundingSummary = async () => {
        const { data } = await fetchFundingSummary(apiToken);
        setFundingSummary(data.attributes);
    };
    const deselectAllCustomersHandler = async () => {
        await deselectAllCustomers(apiToken);
        const updatedCustomersArray = customersArray.map((customer) => {
            customer.isSelected = false;
            return customer;
        });
        setCustomersArray(updatedCustomersArray);
    };
    const selectAllCustomersHandler = async () => {
        await selectAllCustomers(apiToken);
        const updatedCustomersArray = customersArray.map((customer) => {
            customer.isSelected = true;
            return customer;
        });
        setCustomersArray(updatedCustomersArray);
    };
    const switchOnHandler = async (customerId) => {
        const { data } = await selectCustomer(apiToken, customerId);
        updateCustomersArray(data.attributes);
    };
    const switchOffHandler = async (customerId) => {
        const { data } = await deselectCustomer(apiToken, customerId);
        updateCustomersArray(data.attributes);
    };
    const handleSearchQueryChange = (event) => {
        event.preventDefault();
        const queryValue = event.currentTarget.value;
        debounceQueryUpdate(queryValue);
    };
    const debounceQueryUpdate = debounce((queryValue) => {
        setSearchQuery(queryValue);
        // Properly display search results regardless of current page
        setCurrentPage(1);
    }, 300);
    const handleFilterOptionChange = filterOption => {
        setSelectedFilterOptionValue(filterOption);
        // Properly display all customers (including customers without invoices)
        if (filterOption === 'all') {
            setSortBy('name');
            setSortDirection('asc');
        }
    };
    const { fundsAvailableAll, fundsAvailableApproved, fundsAvailableSelected, unselectedDebtors } = fundingSummary;
    // For now, manual ledger & connected clients (Xero & MYOB) will have different funding summary figures
    const fundingSummaryFigures = isManualLedger
        ? [
            { label: 'Gross Receivables Ledger', value: fundsAvailableAll },
            { label: 'Unselected Debtors', value: unselectedDebtors },
            { label: 'Selected Ledger', value: fundsAvailableSelected }
        ]
        : [
            { label: 'All Customers', value: fundsAvailableAll },
            { label: 'Selected Customers', value: fundsAvailableSelected },
            { label: 'Approved Customers', value: fundsAvailableApproved }
        ];
    return (React.createElement("div", { className: 'customers-dashboard customers -borrower', "data-testid": 'borrower-customers-dashboard' },
        React.createElement("div", { className: 'table' },
            React.createElement(CustomersTable, { ...{
                    apiToken,
                    currentPage,
                    customersArray,
                    isLoading,
                    isManualLedger,
                    debtorFiles,
                    generateExportedFilePath,
                    pageSize,
                    sortBy,
                    sortDirection,
                    totalItems,
                    totalPages,
                    deselectAllCustomersHandler,
                    handlePageClick,
                    selectAllCustomersHandler,
                    sortCustomers,
                    switchOffHandler,
                    switchOnHandler,
                    handleSearchQueryChange,
                    handleFilterOptionChange,
                    setSelectedFilterOptionValue,
                    selectedFilterOptionValue,
                    setIsOpenedFilter,
                    isOpenedFilter
                }, forwardHeaderRef: headerRef })),
        React.createElement("div", { className: 'summary' },
            React.createElement(FundingSummaryTable, { figures: fundingSummaryFigures, headerText: 'Summary' }))));
};
export default BorrowerCustomersDashboard;
