import React from 'react';
import Textarea from 'react-expanding-textarea';
import AttachmentUploader from '../shared/AttachmentUploader';
import SpinnerButton from '../shared/SpinnerButton';
import EmojiPicker from '../shared/EmojiPicker';
const PostBuilder = ({ files, postBody, showEmojiPicker, validationErrors, forwardEmojiPickerRef, forwardFilePickerRef, isSubmitting, userAvatarUrl, disableSubmit, handleAttachmentTrigger, handleClearAttachments, handleFileChange, handlePostBodyChange, selectEmoji, toggleEmojiPicker }) => {
    const bodyPlaceholder = 'Have a question? Let us know!';
    return (React.createElement("div", { className: 'post-builder builder -relative', "data-testid": 'post-builder' },
        React.createElement("div", { className: 'fields' },
            React.createElement("img", { className: 'avatar', src: userAvatarUrl }),
            React.createElement(Textarea, { className: 'input form-control body -textarea -post -noresize', "data-testid": 'post-body-input', onChange: handlePostBodyChange, value: postBody, placeholder: bodyPlaceholder, name: 'body' })),
        React.createElement("div", { className: 'errors' },
            validationErrors.body &&
                validationErrors.body.map((error, i) => (React.createElement("div", { className: 'form-field-error', "data-testid": 'form-field-error', key: i }, error))),
            validationErrors.attachment &&
                validationErrors.attachment.map((error, i) => (React.createElement("div", { className: 'form-field-error', "data-testid": 'form-field-error', key: i }, error)))),
        React.createElement(AttachmentUploader, { ...{
                files,
                forwardFilePickerRef,
                handleClearAttachments,
                handleFileChange
            }, hideFilePicker: true }),
        React.createElement("div", { className: 'actions' },
            React.createElement("button", { type: 'button', onClick: handleAttachmentTrigger, className: 'icon-button button -upload-file', "data-testid": 'upload-file' },
                React.createElement("i", { className: 'icon' }),
                React.createElement("div", { className: 'text' }, "Upload File")),
            React.createElement("button", { className: 'icon-button button -emoji', type: 'button', "data-testid": 'emoji', onClick: toggleEmojiPicker, disabled: isSubmitting },
                React.createElement("i", { className: 'icon' })),
            React.createElement(SpinnerButton, { text: 'Post', isDisabled: isSubmitting || disableSubmit, isLoading: isSubmitting, buttonType: 'submit', testId: 'submit-post' })),
        React.createElement(EmojiPicker, { ...{ forwardEmojiPickerRef, selectEmoji }, isActive: showEmojiPicker, direction: 'down' })));
};
export default PostBuilder;
