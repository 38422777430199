import React, { useEffect, useState } from 'react';
import FilterListItem from './FilterListItem';
const FilterDashboard = ({ adminList, baseUrl, adminFilter, adminName }) => {
    const [selectedAdminIds, setSelectedAdminIds] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [clientFilterUrl, setClientFilterUrl] = useState(`${baseUrl}?client_filter=`);
    const [adminSearchQuery, setAdminSearchQuery] = useState('');
    const [clientFilterBy, setClientFilterBy] = useState(adminFilter);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const handleAdminId = adminId => {
        const newIds = [...selectedAdminIds];
        if (isSelected(adminId)) {
            newIds.splice(newIds.indexOf(adminId), 1);
            if (newIds.length === 0) {
                setClientFilterBy('');
            }
        }
        else {
            newIds.push(adminId);
            setClientFilterBy('individual');
        }
        setSelectedAdminIds(newIds);
    };
    const isSelected = adminId => {
        return selectedAdminIds.includes(adminId);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const searchByAdmin = adminList.filter(admin => {
        return admin.firstName
            .toLowerCase()
            .startsWith(adminSearchQuery.toLowerCase());
    });
    const parseSelectedIds = currentAdminFilter => {
        const parsedIds = currentAdminFilter
            .split(',')
            .map(i => parseInt(i.replace('admin-', '')));
        setSelectedAdminIds(parsedIds);
    };
    const initialData = () => {
        parseSelectedIds('');
        if (adminFilter === 'all') {
            setClientFilterBy('all');
            setClientFilterUrl(`${baseUrl}?client_filter=all`);
        }
        else if (adminFilter === 'assigned') {
            setClientFilterBy('assigned');
            setClientFilterUrl(`${baseUrl}?client_filter=assigned`);
        }
        else {
            setClientFilterBy('individual');
            parseSelectedIds(adminFilter);
        }
    };
    useEffect(() => {
        initialData();
    }, []);
    useEffect(() => {
        if (!isFirstRender) {
            if (clientFilterBy === 'all') {
                setClientFilterUrl(`${baseUrl}?client_filter=all`);
            }
            else if (clientFilterBy === 'assigned') {
                setClientFilterUrl(`${baseUrl}?client_filter=assigned`);
            }
            else if (clientFilterBy === 'individual') {
                const arr = selectedAdminIds.map(i => 'admin-' + i);
                setClientFilterUrl(`${baseUrl}?client_filter=` + arr.join(','));
            }
        }
        setIsFirstRender(false);
    }, [selectedAdminIds, clientFilterBy]);
    return (React.createElement("div", { "data-testid": 'filter-modal' },
        React.createElement("button", { className: 'icon-button -with-border -rectangular -filter -margin', type: 'button', onClick: () => openModal() },
            React.createElement("i", { className: 'icon' }),
            "Filter by Admin"),
        isModalOpen && (React.createElement("div", null,
            React.createElement("div", { className: 'custom-panel select-modal' },
                React.createElement("div", { className: 'header -space-between' },
                    "Filter by Admin",
                    React.createElement("button", { className: 'icon-button -cancel', onClick: () => closeModal(), type: 'button' },
                        React.createElement("i", { className: 'icon' }))),
                React.createElement("div", { className: 'search-wrapper' },
                    React.createElement("p", { className: 'label' }, "Search by name"),
                    React.createElement("div", { className: 'filter-table -unset search' },
                        React.createElement("input", { type: 'text', className: 'input', value: adminSearchQuery, onChange: e => setAdminSearchQuery(e.target.value), maxLength: 100 }),
                        React.createElement("div", { className: 'icon' }))),
                React.createElement("div", { className: 'body' },
                    React.createElement("ul", null,
                        React.createElement("li", { className: '' + (clientFilterBy === 'assigned' ? 'selected' : '') },
                            React.createElement("label", null,
                                React.createElement("span", { className: '-filter' },
                                    adminName,
                                    " (me)"),
                                React.createElement("input", { type: 'checkbox', onChange: () => {
                                        clientFilterBy === 'assigned'
                                            ? setClientFilterBy('')
                                            : setClientFilterBy('assigned');
                                        setSelectedAdminIds([]);
                                    }, checked: 'assigned' === clientFilterBy }))),
                        searchByAdmin.map(admin => (React.createElement(FilterListItem, { admin: admin, selected: isSelected(admin.id), handleAdminId: handleAdminId, selectedAdminIds: selectedAdminIds, setClientFilterBy: setClientFilterBy, key: admin.id, clientFilter: clientFilterUrl }))))),
                React.createElement("div", { className: 'footer' },
                    React.createElement("div", { className: 'action-buttons -space-between' },
                        React.createElement("a", { className: 'button -secondary -wide', href: `${baseUrl}?client_filter=all` }, "All Client"),
                        React.createElement("div", { className: 'action-buttons' },
                            React.createElement("button", { className: 'button -secondary', onClick: initialData }, "Reset"),
                            React.createElement("a", { className: 'button -primary -expanded', href: clientFilterUrl }, "Apply Filter"))))),
            React.createElement("div", { className: 'select-modal-overlay' })))));
};
export default FilterDashboard;
