import { isEmptyObject } from '../../utils';
export const defaultSupplierDetails = {
    name: '',
    currency: '',
    taxNumber: '',
    contactName: '',
    contactPhoneNumber: '',
    address: '',
    email: '',
    attachments: []
};
export const defaultPaymentDetails = {
    accountName: '',
    nameOfBank: '',
    accountNumber: '',
    bsb: '',
    isConfirmed: false
};
export const defaultInvoiceDetails = {
    invoiceNumber: '',
    invoiceDate: '',
    invoiceAmountCents: 0,
    amountToPayCents: 0,
    paymentCurrency: '',
    purchaseOrder: '',
    description: '',
    invoiceCopy: []
};
export const defaultInvoiceAmountValues = {
    invoiceAmountCents: {
        floatValue: 0,
        value: '0.00',
        formattedValue: ''
    },
    amountToPayCents: {
        floatValue: 0,
        value: '0.00',
        formattedValue: ''
    }
};
const SupplierDetailsReducer = (state, action) => {
    const { suppliers, selectedSupplierOptions, selectedCurrencyOptions, paymentDetailsList, supplierDetailsErrors, paymentDetailsErrors, invoices, selectedInvoiceCurrencyOptions, invoiceAmountValues, invoiceErrors, invoiceDocumentErrors, supplierDocumentErrors } = state;
    switch (action.type) {
        case 'ADD_SUPPLIER':
            return {
                ...state,
                suppliers: [
                    ...state.suppliers,
                    action.initialDetails || defaultSupplierDetails
                ],
                selectedSupplierOptions: [
                    ...state.selectedSupplierOptions,
                    action.initialSelectedOption || null
                ],
                selectedCurrencyOptions: [
                    ...state.selectedCurrencyOptions,
                    action.initialSelectedCurrency || null
                ],
                paymentDetailsList: [
                    ...state.paymentDetailsList,
                    action.initialPaymentDetails || defaultPaymentDetails
                ],
                supplierDetailsErrors: [...supplierDetailsErrors, {}],
                supplierDocumentErrors: [...supplierDocumentErrors, {}],
                paymentDetailsErrors: [...paymentDetailsErrors, {}],
                invoices: [
                    ...invoices,
                    action.initialInvoices || [{ ...defaultInvoiceDetails }]
                ],
                selectedInvoiceCurrencyOptions: [
                    ...selectedInvoiceCurrencyOptions,
                    action.initialSelectedInvoiceCurrencyOptions || [null]
                ],
                invoiceAmountValues: [
                    ...invoiceAmountValues,
                    action.initialInvoiceAmountValues || [
                        { ...defaultInvoiceAmountValues }
                    ]
                ],
                invoiceErrors: [...invoiceErrors, [{}]],
                invoiceDocumentErrors: [...invoiceDocumentErrors, [{}]]
            };
        case 'REMOVE_SUPPLIER':
            if (state.suppliers.length > 1 &&
                action.index >= 0 &&
                action.index < suppliers.length) {
                return {
                    ...state,
                    suppliers: [
                        ...suppliers.slice(0, action.index),
                        ...suppliers.slice(action.index + 1, suppliers.length)
                    ],
                    selectedSupplierOptions: [
                        ...selectedSupplierOptions.slice(0, action.index),
                        ...selectedSupplierOptions.slice(action.index + 1, selectedSupplierOptions.length)
                    ],
                    selectedCurrencyOptions: [
                        ...selectedCurrencyOptions.slice(0, action.index),
                        ...selectedCurrencyOptions.slice(action.index + 1, selectedCurrencyOptions.length)
                    ],
                    paymentDetailsList: [
                        ...paymentDetailsList.slice(0, action.index),
                        ...paymentDetailsList.slice(action.index + 1, paymentDetailsList.length)
                    ],
                    supplierDetailsErrors: [
                        ...supplierDetailsErrors.slice(0, action.index),
                        ...supplierDetailsErrors.slice(action.index + 1, supplierDetailsErrors.length)
                    ],
                    supplierDocumentErrors: [
                        ...supplierDocumentErrors.slice(0, action.index),
                        ...supplierDocumentErrors.slice(action.index + 1, supplierDocumentErrors.length)
                    ],
                    paymentDetailsErrors: [
                        ...paymentDetailsErrors.slice(0, action.index),
                        ...paymentDetailsErrors.slice(action.index + 1, paymentDetailsErrors.length)
                    ],
                    invoices: [
                        ...invoices.slice(0, action.index),
                        ...invoices.slice(action.index + 1, invoices.length)
                    ],
                    selectedInvoiceCurrencyOptions: [
                        ...selectedInvoiceCurrencyOptions.slice(0, action.index),
                        ...selectedInvoiceCurrencyOptions.slice(action.index + 1, selectedInvoiceCurrencyOptions.length)
                    ],
                    invoiceAmountValues: [
                        ...invoiceAmountValues.slice(0, action.index),
                        ...invoiceAmountValues.slice(action.index + 1, invoiceAmountValues.length)
                    ],
                    invoiceErrors: [
                        ...invoiceErrors.slice(0, action.index),
                        ...invoiceErrors.slice(action.index + 1, invoiceErrors.length)
                    ],
                    invoiceDocumentErrors: [
                        ...invoiceDocumentErrors.slice(0, action.index),
                        ...invoiceDocumentErrors.slice(action.index + 1, invoiceErrors.length)
                    ]
                };
            }
            else {
                return { ...state };
            }
        case 'UPDATE_SUPPLIER_FIELD':
            if (action.index >= 0 && action.index < suppliers.length) {
                return {
                    ...state,
                    suppliers: [
                        ...suppliers.slice(0, action.index),
                        {
                            ...suppliers[action.index],
                            [action.fieldName]: action.value
                        },
                        ...suppliers.slice(action.index + 1, suppliers.length)
                    ]
                };
            }
            else {
                return { ...state };
            }
        case 'PRELOAD_SUPPLIER':
            if (action.index >= 0 && action.index < selectedSupplierOptions.length) {
                return {
                    ...state,
                    suppliers: [
                        ...suppliers.slice(0, action.index),
                        action.details || defaultSupplierDetails,
                        ...suppliers.slice(action.index + 1, suppliers.length)
                    ],
                    selectedSupplierOptions: [
                        ...selectedSupplierOptions.slice(0, action.index),
                        action.option,
                        ...selectedSupplierOptions.slice(action.index + 1, selectedSupplierOptions.length)
                    ],
                    selectedCurrencyOptions: [
                        ...selectedCurrencyOptions.slice(0, action.index),
                        action.currencyOption || null,
                        ...selectedCurrencyOptions.slice(action.index + 1, selectedCurrencyOptions.length)
                    ]
                };
            }
            else {
                return { ...state };
            }
        case 'PRELOAD_PAYMENT_DETAILS':
            if (action.index >= 0 && action.index < paymentDetailsList.length) {
                return {
                    ...state,
                    paymentDetailsList: [
                        ...paymentDetailsList.slice(0, action.index),
                        action.details || defaultPaymentDetails,
                        ...paymentDetailsList.slice(action.index + 1, paymentDetailsList.length)
                    ]
                };
            }
            else {
                return { ...state };
            }
        case 'UPDATE_SUPPLIER_CURRENCY':
            if (action.index >= 0 && action.index < suppliers.length) {
                return {
                    ...state,
                    suppliers: [
                        ...suppliers.slice(0, action.index),
                        {
                            ...suppliers[action.index],
                            currency: action.currencyOption ? action.currencyOption.value : ''
                        },
                        ...suppliers.slice(action.index + 1, suppliers.length)
                    ],
                    selectedCurrencyOptions: [
                        ...selectedCurrencyOptions.slice(0, action.index),
                        action.currencyOption || null,
                        ...selectedCurrencyOptions.slice(action.index + 1, selectedCurrencyOptions.length)
                    ]
                };
            }
            else {
                return { ...state };
            }
        case 'REFRESH_SUPPLIER':
            if (action.index >= 0 && action.index < suppliers.length) {
                return {
                    ...state,
                    suppliers: [
                        ...suppliers.slice(0, action.index),
                        action.supplierDetails,
                        ...suppliers.slice(action.index + 1, suppliers.length)
                    ]
                };
            }
            else {
                return { ...state };
            }
        case 'REFRESH_PAYMENT_DETAILS':
            if (action.index >= 0 && action.index < paymentDetailsList.length) {
                return {
                    ...state,
                    paymentDetailsList: [
                        ...paymentDetailsList.slice(0, action.index),
                        action.paymentDetails,
                        ...paymentDetailsList.slice(action.index + 1, paymentDetailsList.length)
                    ]
                };
            }
            else {
                return { ...state };
            }
        case 'UPDATE_PAYMENT_DETAILS_FIELD':
            if (action.index >= 0 && action.index < paymentDetailsList.length) {
                return {
                    ...state,
                    paymentDetailsList: [
                        ...paymentDetailsList.slice(0, action.index),
                        {
                            ...paymentDetailsList[action.index],
                            [action.fieldName]: action.value
                        },
                        ...paymentDetailsList.slice(action.index + 1, paymentDetailsList.length)
                    ]
                };
            }
            else {
                return { ...state };
            }
        case 'UPDATE_SUPPLIER_DETAILS_ERRORS':
            return {
                ...state,
                supplierDetailsErrors: action.errors
            };
        case 'UPDATE_SINGLE_SUPPLIER_ERRORS':
            return {
                ...state,
                supplierDetailsErrors: [
                    ...supplierDetailsErrors.slice(0, action.index),
                    action.errors,
                    ...supplierDetailsErrors.slice(action.index + 1, supplierDetailsErrors.length)
                ]
            };
        case 'UPDATE_PAYMENT_DETAILS_ERRORS': {
            return {
                ...state,
                paymentDetailsErrors: action.errors
            };
        }
        case 'UPDATE_SINGLE_PAYMENT_DETAILS_ERRORS': {
            if (action.index >= 0 && action.index < paymentDetailsErrors.length) {
                return {
                    ...state,
                    paymentDetailsErrors: [
                        ...paymentDetailsErrors.slice(0, action.index),
                        action.errors,
                        ...paymentDetailsErrors.slice(action.index + 1, paymentDetailsErrors.length)
                    ]
                };
            }
            else {
                return { ...state };
            }
        }
        case 'ADD_INVOICE':
            if (action.supplierIndex >= 0 &&
                action.supplierIndex < suppliers.length) {
                return {
                    ...state,
                    invoices: [
                        ...invoices.slice(0, action.supplierIndex),
                        [...invoices[action.supplierIndex], { ...defaultInvoiceDetails }],
                        ...invoices.slice(action.supplierIndex + 1, invoices.length)
                    ],
                    selectedInvoiceCurrencyOptions: [
                        ...selectedInvoiceCurrencyOptions.slice(0, action.supplierIndex),
                        [...selectedInvoiceCurrencyOptions[action.supplierIndex], null],
                        ...selectedInvoiceCurrencyOptions.slice(action.supplierIndex + 1, selectedInvoiceCurrencyOptions.length)
                    ],
                    invoiceAmountValues: [
                        ...invoiceAmountValues.slice(0, action.supplierIndex),
                        [
                            ...invoiceAmountValues[action.supplierIndex],
                            { ...defaultInvoiceAmountValues }
                        ],
                        ...invoiceAmountValues.slice(action.supplierIndex + 1, invoiceAmountValues.length)
                    ],
                    invoiceErrors: [
                        ...invoiceErrors.slice(0, action.supplierIndex),
                        [...invoiceErrors[action.supplierIndex], {}],
                        ...invoiceErrors.slice(action.supplierIndex + 1, invoiceErrors.length)
                    ],
                    invoiceDocumentErrors: [
                        ...invoiceDocumentErrors.slice(0, action.supplierIndex),
                        [...invoiceDocumentErrors[action.supplierIndex], {}],
                        ...invoiceDocumentErrors.slice(action.supplierIndex + 1, invoiceDocumentErrors.length)
                    ]
                };
            }
            else {
                return { ...state };
            }
        case 'REMOVE_INVOICE':
            if (invoices[action.supplierIndex].length > 1) {
                return {
                    ...state,
                    invoices: [
                        ...invoices.slice(0, action.supplierIndex),
                        [
                            ...invoices[action.supplierIndex].slice(0, action.invoiceIndex),
                            ...invoices[action.supplierIndex].slice(action.invoiceIndex + 1, invoices[action.supplierIndex].length)
                        ],
                        ...invoices.slice(action.supplierIndex + 1, invoices.length)
                    ],
                    selectedInvoiceCurrencyOptions: [
                        ...selectedInvoiceCurrencyOptions.slice(0, action.supplierIndex),
                        [
                            ...selectedInvoiceCurrencyOptions[action.supplierIndex].slice(0, action.invoiceIndex),
                            ...selectedInvoiceCurrencyOptions[action.supplierIndex].slice(action.invoiceIndex + 1, selectedInvoiceCurrencyOptions[action.supplierIndex].length)
                        ],
                        ...selectedInvoiceCurrencyOptions.slice(action.supplierIndex + 1, selectedInvoiceCurrencyOptions.length)
                    ],
                    invoiceAmountValues: [
                        ...invoiceAmountValues.slice(0, action.supplierIndex),
                        [
                            ...invoiceAmountValues[action.supplierIndex].slice(0, action.invoiceIndex),
                            ...invoiceAmountValues[action.supplierIndex].slice(action.invoiceIndex + 1, invoiceAmountValues[action.supplierIndex].length)
                        ],
                        ...invoiceAmountValues.slice(action.supplierIndex + 1, invoiceAmountValues.length)
                    ],
                    invoiceErrors: [
                        ...invoiceErrors.slice(0, action.supplierIndex),
                        [
                            ...invoiceErrors[action.supplierIndex].slice(0, action.invoiceIndex),
                            ...invoiceErrors[action.supplierIndex].slice(action.invoiceIndex + 1, invoiceErrors[action.supplierIndex].length)
                        ],
                        ...invoiceErrors.slice(action.supplierIndex + 1, invoiceErrors.length)
                    ],
                    invoiceDocumentErrors: [
                        ...invoiceDocumentErrors.slice(0, action.supplierIndex),
                        [
                            ...invoiceDocumentErrors[action.supplierIndex].slice(0, action.invoiceIndex),
                            ...invoiceDocumentErrors[action.supplierIndex].slice(action.invoiceIndex + 1, invoiceDocumentErrors[action.supplierIndex].length)
                        ],
                        ...invoiceDocumentErrors.slice(action.supplierIndex + 1, invoiceDocumentErrors.length)
                    ]
                };
            }
            return { ...state };
        case 'UPDATE_INVOICE_FIELD':
            if (action.supplierIndex >= 0 &&
                action.supplierIndex < suppliers.length &&
                action.invoiceIndex >= 0 &&
                action.invoiceIndex < invoices[action.supplierIndex].length) {
                return {
                    ...state,
                    invoices: [
                        ...invoices.slice(0, action.supplierIndex),
                        [
                            ...invoices[action.supplierIndex].slice(0, action.invoiceIndex),
                            {
                                ...invoices[action.supplierIndex][action.invoiceIndex],
                                [action.fieldName]: action.value
                            },
                            ...invoices[action.supplierIndex].slice(action.invoiceIndex + 1, invoices[action.supplierIndex].length)
                        ],
                        ...invoices.slice(action.supplierIndex + 1, invoices.length)
                    ]
                };
            }
            else {
                return { ...state };
            }
        case 'UPDATE_INVOICE_AMOUNT':
            if (action.supplierIndex >= 0 &&
                action.supplierIndex < suppliers.length &&
                action.invoiceIndex >= 0 &&
                action.invoiceIndex < invoices[action.supplierIndex].length) {
                return {
                    ...state,
                    invoices: [
                        ...invoices.slice(0, action.supplierIndex),
                        [
                            ...invoices[action.supplierIndex].slice(0, action.invoiceIndex),
                            {
                                ...invoices[action.supplierIndex][action.invoiceIndex],
                                [action.fieldName]: action.centsAmount
                            },
                            ...invoices[action.supplierIndex].slice(action.invoiceIndex + 1, invoices[action.supplierIndex].length)
                        ],
                        ...invoices.slice(action.supplierIndex + 1, invoices.length)
                    ],
                    invoiceAmountValues: [
                        ...invoiceAmountValues.slice(0, action.supplierIndex),
                        [
                            ...invoiceAmountValues[action.supplierIndex].slice(0, action.invoiceIndex),
                            {
                                ...invoiceAmountValues[action.supplierIndex][action.invoiceIndex],
                                [action.fieldName]: action.values
                            },
                            ...invoiceAmountValues[action.supplierIndex].slice(action.invoiceIndex + 1, invoiceAmountValues[action.supplierIndex].length)
                        ],
                        ...invoiceAmountValues.slice(action.supplierIndex + 1, invoiceAmountValues.length)
                    ]
                };
            }
            else {
                return { ...state };
            }
        case 'UPDATE_INVOICE_CURRENCY':
            if (action.supplierIndex >= 0 &&
                action.supplierIndex < suppliers.length &&
                action.invoiceIndex >= 0 &&
                action.invoiceIndex < invoices[action.supplierIndex].length) {
                return {
                    ...state,
                    invoices: [
                        ...invoices.slice(0, action.supplierIndex),
                        [
                            ...invoices[action.supplierIndex].slice(0, action.invoiceIndex),
                            {
                                ...invoices[action.supplierIndex][action.invoiceIndex],
                                [action.fieldName]: action.option ? action.option.value : ''
                            },
                            ...invoices[action.supplierIndex].slice(action.invoiceIndex + 1, invoices[action.supplierIndex].length)
                        ],
                        ...invoices.slice(action.supplierIndex + 1, invoices.length)
                    ],
                    selectedInvoiceCurrencyOptions: [
                        ...selectedInvoiceCurrencyOptions.slice(0, action.supplierIndex),
                        [
                            ...selectedInvoiceCurrencyOptions[action.supplierIndex].slice(0, action.invoiceIndex),
                            action.option || null,
                            ...selectedInvoiceCurrencyOptions[action.supplierIndex].slice(action.invoiceIndex + 1, selectedInvoiceCurrencyOptions[action.supplierIndex].length)
                        ],
                        ...selectedInvoiceCurrencyOptions.slice(action.supplierIndex + 1, selectedInvoiceCurrencyOptions.length)
                    ]
                };
            }
            else {
                return { ...state };
            }
        case 'UPDATE_INVOICE_FILES':
            if (action.supplierIndex >= 0 &&
                action.supplierIndex < suppliers.length &&
                action.invoiceIndex >= 0 &&
                action.invoiceIndex < invoices[action.supplierIndex].length) {
                return {
                    ...state,
                    invoices: [
                        ...invoices.slice(0, action.supplierIndex),
                        [
                            ...invoices[action.supplierIndex].slice(0, action.invoiceIndex),
                            {
                                ...invoices[action.supplierIndex][action.invoiceIndex],
                                [action.fieldName]: action.files
                            },
                            ...invoices[action.supplierIndex].slice(action.invoiceIndex + 1, invoices[action.supplierIndex].length)
                        ],
                        ...invoices.slice(action.supplierIndex + 1, invoices.length)
                    ]
                };
            }
            else {
                return { ...state };
            }
        case 'UPDATE_INVOICE_ERRORS':
            return {
                ...state,
                invoiceErrors: action.errors
            };
        case 'UPDATE_SINGLE_INVOICE_ERRORS':
            if (action.supplierIndex >= 0 &&
                action.supplierIndex < suppliers.length &&
                action.invoiceIndex >= 0 &&
                action.invoiceIndex < invoices[action.supplierIndex].length) {
                return {
                    ...state,
                    invoiceErrors: [
                        ...invoiceErrors.slice(0, action.supplierIndex),
                        [
                            ...invoiceErrors[action.supplierIndex].slice(0, action.invoiceIndex),
                            action.errors,
                            ...invoiceErrors[action.supplierIndex].slice(action.invoiceIndex + 1, invoiceErrors[action.supplierIndex].length)
                        ],
                        ...invoiceErrors.slice(action.supplierIndex + 1, invoiceErrors.length)
                    ]
                };
            }
            else {
                return {
                    ...state
                };
            }
        case 'UPDATE_INVOICE_DOCUMENT_ERRORS': {
            if (action.supplierIndex >= 0 &&
                action.supplierIndex < suppliers.length &&
                action.invoiceIndex >= 0 &&
                action.invoiceIndex < invoices[action.supplierIndex].length) {
                if (isEmptyObject(action.errors)) {
                    const { [action.fieldName]: value, ...updatedErrors } = invoiceDocumentErrors[action.supplierIndex][action.invoiceIndex];
                    return {
                        ...state,
                        invoiceDocumentErrors: [
                            ...invoiceDocumentErrors.slice(0, action.supplierIndex),
                            [
                                ...invoiceDocumentErrors[action.supplierIndex].slice(0, action.invoiceIndex),
                                updatedErrors,
                                ...invoiceDocumentErrors[action.supplierIndex].slice(action.invoiceIndex + 1, invoiceDocumentErrors[action.supplierIndex].length)
                            ],
                            ...invoiceDocumentErrors.slice(action.supplierIndex + 1, invoiceDocumentErrors.length)
                        ]
                    };
                }
                else {
                    return {
                        ...state,
                        invoiceDocumentErrors: [
                            ...invoiceDocumentErrors.slice(0, action.supplierIndex),
                            [
                                ...invoiceDocumentErrors[action.supplierIndex].slice(0, action.invoiceIndex),
                                {
                                    ...invoiceDocumentErrors[action.supplierIndex][action.invoiceIndex],
                                    [action.fieldName]: action.errors
                                },
                                ...invoiceDocumentErrors[action.supplierIndex].slice(action.invoiceIndex + 1, invoiceDocumentErrors[action.supplierIndex].length)
                            ],
                            ...invoiceDocumentErrors.slice(action.supplierIndex + 1, invoiceDocumentErrors.length)
                        ]
                    };
                }
            }
            else {
                return { ...state };
            }
        }
        case 'REFRESH_INVOICE':
            if (action.supplierIndex >= 0 &&
                action.supplierIndex < suppliers.length &&
                action.invoiceIndex >= 0 &&
                action.invoiceIndex < invoices[action.supplierIndex].length) {
                return {
                    ...state,
                    invoices: [
                        ...invoices.slice(0, action.supplierIndex),
                        [
                            ...invoices[action.supplierIndex].slice(0, action.invoiceIndex),
                            action.invoiceDetails,
                            ...invoices[action.supplierIndex].slice(action.invoiceIndex + 1, invoices[action.supplierIndex].length)
                        ],
                        ...invoices.slice(action.supplierIndex + 1, invoices.length)
                    ]
                };
            }
            else {
                return {
                    ...state
                };
            }
        case 'UPDATE_SUPPLIER_FILES':
            if (action.index >= 0 && action.index < suppliers.length) {
                return {
                    ...state,
                    suppliers: [
                        ...suppliers.slice(0, action.index),
                        {
                            ...suppliers[action.index],
                            [action.fieldName]: action.files
                        },
                        ...suppliers.slice(action.index + 1, suppliers.length)
                    ]
                };
            }
            else {
                return { ...state };
            }
        case 'UPDATE_SUPPLIER_DOCUMENT_ERRORS': {
            if (action.index >= 0 && action.index < suppliers.length) {
                if (isEmptyObject(action.errors)) {
                    const { [action.fieldName]: value, ...updatedErrors } = supplierDocumentErrors[action.index];
                    return {
                        ...state,
                        supplierDocumentErrors: [
                            ...supplierDocumentErrors.slice(0, action.index),
                            updatedErrors,
                            ...supplierDocumentErrors.slice(action.index + 1, supplierDocumentErrors.length)
                        ]
                    };
                }
                else {
                    return {
                        ...state,
                        supplierDocumentErrors: [
                            ...supplierDocumentErrors.slice(0, action.index),
                            {
                                ...supplierDocumentErrors[action.index],
                                [action.fieldName]: action.errors
                            },
                            ...supplierDocumentErrors.slice(action.index + 1, supplierDocumentErrors.length)
                        ]
                    };
                }
            }
            else {
                return { ...state };
            }
        }
        default:
            return { ...state };
    }
};
export default SupplierDetailsReducer;
