import React, { useState, useEffect, useRef } from 'react';
import { fetchDebtorGrossLedger as defaultFetchDebtorGrossLedger } from './../../api/debtor_gross_ledger';
import { showNotifyToast as defaultShowNotifyToast } from './../../utils';
import TableSearchBar from '../../components/admin-customers-table/TableSearchBar';
import PaginationBar from '../../components/shared/PaginationBar';
import debounce from 'lodash.debounce';
import ExportedFilesDownloadModal from '../../components/shared/ExportFilesDownloadModal';
import SortableTabs from '../../components/table-headers/SortableTabs';
const AdminDebtorGrossLedgerAnalysis = ({ apiKey, borrowerId, companyId, generateExportedFilePath, debtorFiles, fetchDebtorGrossLedger = defaultFetchDebtorGrossLedger, showNotifyToast = defaultShowNotifyToast }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [sortBy, setSortBy] = useState('gross_receivables_ledger');
    const [sortDirection, setSortDirection] = useState('desc');
    const [headerWidths, setHeaderWidths] = useState([]);
    const headerRef = useRef(null);
    const sortCustomers = (field, defaultSort) => {
        setSortBy(field);
        if (sortBy === field && sortDirection === 'asc') {
            setSortDirection('desc');
        }
        else if (sortBy === field && sortDirection === 'desc') {
            setSortDirection('asc');
        }
        else {
            setSortDirection(defaultSort || 'desc');
        }
    };
    useEffect(() => {
        if (headerRef && headerRef.current) {
            const headerWidthList = [...headerRef.current.children[0].children].map(tableHeader => tableHeader.getBoundingClientRect().width);
            setHeaderWidths(headerWidthList);
        }
        const fetchCustomers = async () => {
            try {
                setIsLoading(true);
                const { data } = await fetchDebtorGrossLedger(apiKey, companyId, sortDirection, currentPage, searchQuery, sortBy);
                const { page, totalItems, totalPages } = data.attributes;
                setCustomers(page);
                setTotalItems(totalItems);
                setTotalPages(totalPages);
            }
            catch (err) {
                showNotifyToast({
                    type: 'error',
                    text: 'Failed to fetch customers'
                });
            }
            finally {
                setIsLoading(false);
            }
        };
        fetchCustomers();
    }, [searchQuery, currentPage, sortBy, sortDirection]);
    const negativeStyle = (receivableValue) => {
        if (receivableValue < 0) {
            return '-red-text';
        }
        return '';
    };
    const adminUserCustomerPath = `/admin/borrowers/${borrowerId}/customers`;
    const handleSearchQueryChange = (event) => {
        event.preventDefault();
        const queryValue = event.currentTarget.value;
        debounceQueryUpdate(queryValue);
    };
    const debounceQueryUpdate = debounce((queryValue) => {
        setSearchQuery(queryValue);
    }, 750);
    const handlePageClick = data => {
        setCurrentPage(data.selected + 1);
    };
    const headerFields = [
        { text: 'Debtor Code', field: 'source_id', defaultSort: 'asc' },
        { text: 'Debtor Name', field: 'name', defaultSort: 'asc' },
        { text: 'Selected', field: 'is_selected' },
        { text: 'Approval Status', field: 'earlypay_status' },
        { text: 'Concentration %', field: 'current_exposure', defaultSort: 'desc' },
        {
            text: 'Gross Receivables Ledger',
            field: 'gross_receivables_ledger',
            defaultSort: 'desc'
        },
        {
            text: '0-30 Days',
            field: 'receivables_0_to_30_cents',
            defaultSort: 'desc'
        },
        {
            text: '31-60 Days',
            field: 'receivables_31_to_60_cents',
            defaultSort: 'desc'
        },
        {
            text: '61-90 Days',
            field: 'receivables_61_to_90_cents',
            defaultSort: 'desc'
        },
        {
            text: '91-120 Days',
            field: 'receivables_91_to_120_cents',
            defaultSort: 'desc'
        },
        {
            text: '120+ Days',
            field: 'receivables_120_plus_cents',
            defaultSort: 'desc'
        },
        {
            text: '90 Days + Aging %',
            field: 'receivables_90_days_plus_aging',
            defaultSort: 'desc'
        },
        { text: 'DSO' },
        { text: 'Dilutions %' },
        { text: 'Verified %' }
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'header -space-between' },
            React.createElement("div", { className: 'title -items-center -gap' },
                React.createElement("div", { className: 'text' }, "Debtor Gross Ledger Analysis"),
                React.createElement("div", { className: 'action' },
                    React.createElement(TableSearchBar, { handleSearchQueryChange: handleSearchQueryChange, searchPlaceholder: 'Search debtor name or debtor code', displaySearchInput: true, expanded: true }))),
            React.createElement("span", { className: 'actions' },
                React.createElement(ExportedFilesDownloadModal, { label: 'Export as Excel', debtorFiles: debtorFiles, generateExportedFilePath: generateExportedFilePath }))),
        React.createElement("table", { className: 'custom-table' },
            React.createElement(SortableTabs, { ...{
                    headerFields,
                    isLoading,
                    sortBy,
                    sortDirection,
                    forwardHeaderRef: headerRef,
                    headerWidths
                }, sortItems: sortCustomers }),
            isLoading ? (React.createElement("tbody", { "data-testid": 'loading' },
                React.createElement("tr", null,
                    React.createElement("td", { className: 'cell', colSpan: 15 },
                        React.createElement("div", { className: 'loading-container -table' },
                            React.createElement("div", { className: 'loading-spinner' })))))) : (React.createElement("tbody", null, customers.map((customer) => {
                return (React.createElement("tr", { key: customer.id },
                    React.createElement("td", { className: 'cell' }, customer.sourceId),
                    React.createElement("td", { className: 'cell' },
                        React.createElement("a", { href: `${adminUserCustomerPath}/${customer.id}` }, customer.name)),
                    React.createElement("td", { className: 'cell' }, customer.isSelected ? 'Yes' : 'No'),
                    React.createElement("td", { className: 'cell' }, customer.earlypayStatus === 'PENDING'
                        ? 'UNDER REVIEW'
                        : customer.earlypayStatus),
                    React.createElement("td", { className: 'cell' }, customer.currentExposureFormatted),
                    React.createElement("td", { className: `cell ${negativeStyle(customer.grossReceivablesLedgerFormatted)}` }, customer.grossReceivablesLedgerFormatted),
                    React.createElement("td", { className: `cell ${negativeStyle(customer.receivables0To30DaysFormatted)}` }, customer.receivables0To30DaysFormatted),
                    React.createElement("td", { className: `cell ${negativeStyle(customer.receivables31To60DaysFormatted)}` }, customer.receivables31To60DaysFormatted),
                    React.createElement("td", { className: `cell ${negativeStyle(customer.receivables61To90DaysFormatted)}` }, customer.receivables61To90DaysFormatted),
                    React.createElement("td", { className: `cell ${negativeStyle(customer.receivables91To120DaysFormatted)}` }, customer.receivables91To120DaysFormatted),
                    React.createElement("td", { className: `cell ${negativeStyle(customer.receivables120PlusDaysFormatted)}` }, customer.receivables120PlusDaysFormatted),
                    React.createElement("td", { className: 'cell' }, customer.grossLedger90PlusDaysAgingFormatted),
                    React.createElement("td", { className: 'cell' }, customer.dso),
                    React.createElement("td", { className: 'cell' }, customer.dilutionFormatted),
                    React.createElement("td", { className: 'cell' }, customer.verifiedFormatted)));
            })))),
        totalPages > 1 && (React.createElement(PaginationBar, { ...{
                currentPage,
                pageSize: 10,
                totalPages,
                totalItems,
                resource: 'debtors',
                handlePageClick
            } }))));
};
export default AdminDebtorGrossLedgerAnalysis;
