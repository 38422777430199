import React from 'react';
import DocumentPicker from './DocumentPicker';
import ErrorList from '../shared/ErrorList';
const SupplierDetailsEdit = ({ index, currentSupplierDetails, supplierDetailsErrors, supplierDocumentErrors, handleSupplierDetailUpdate, handleSupplierFileChange, handleRemoveSupplierFile, handleSupplierFileReplace, handleDeleteSupplierFile }) => {
    const { name, taxNumber, contactName, contactPhoneNumber, address, email, attachments, existingAttachments } = currentSupplierDetails;
    return (React.createElement("div", { className: 'form-section section', "data-testid": 'supplier-details-edit' },
        React.createElement("div", { className: 'body', "data-testid": 'supplier-body' },
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { htmlFor: 'supplier-name', className: 'label -uppercase -required' }, "Supplier Name"),
                React.createElement("input", { className: 'input form-control', maxLength: 100, name: `supplier_details[${index}][name]`, value: name, "data-testid": 'supplier-name', id: 'supplier-name', onChange: handleSupplierDetailUpdate }),
                React.createElement(ErrorList, { errors: supplierDetailsErrors, field: 'name' })),
            React.createElement("div", { className: 'form-group', "data-testid": 'input' },
                React.createElement("label", { htmlFor: 'supplier-tax-number', className: 'label -uppercase' }, "ABN / Company Number"),
                React.createElement("input", { className: 'input form-control', maxLength: 100, size: 50, name: `supplier_details[${index}][tax_number]`, onChange: handleSupplierDetailUpdate, id: 'supplier-tax-number', "data-testid": 'supplier-tax-number', value: taxNumber }),
                React.createElement(ErrorList, { errors: supplierDetailsErrors, field: 'tax_number' })),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { htmlFor: 'supplier-address', className: 'label -uppercase -required' }, "Address"),
                React.createElement("input", { className: 'input form-control', maxLength: 100, size: 50, name: `supplier_details[${index}][address]`, onChange: handleSupplierDetailUpdate, value: address, id: 'supplier-address', "data-testid": 'supplier-address' }),
                React.createElement(ErrorList, { errors: supplierDetailsErrors, field: 'address' })),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { htmlFor: 'supplier-contact-name', className: 'label -uppercase' }, "Contact Name"),
                React.createElement("input", { className: 'input form-control', maxLength: 100, size: 50, name: `supplier_details[${index}][contact_name]`, value: contactName, onChange: handleSupplierDetailUpdate, id: 'supplier-contact-name', "data-testid": 'supplier-contact-name' }),
                React.createElement(ErrorList, { errors: supplierDetailsErrors, field: 'contact_name' })),
            React.createElement("div", { className: 'form-row -col-2' },
                React.createElement("div", { className: 'form-group', "data-testid": 'input' },
                    React.createElement("label", { htmlFor: 'supplier-contact-phone-number', className: 'label -uppercase' }, "Contact Phone Number"),
                    React.createElement("input", { className: 'input form-control', maxLength: 100, size: 50, name: `supplier_details[${index}][contact_phone_number]`, onChange: handleSupplierDetailUpdate, value: contactPhoneNumber, id: 'supplier-contact-phone-number', "data-testid": 'supplier-contact-phone-number' }),
                    React.createElement(ErrorList, { errors: supplierDetailsErrors, field: 'contact_phone_number' })),
                React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { htmlFor: 'supplier-email', className: 'label -uppercase' }, "Contact Email Address"),
                    React.createElement("input", { className: 'input form-control', maxLength: 100, size: 50, name: `supplier_details[${index}][email]`, value: email, onChange: handleSupplierDetailUpdate, id: 'supplier-email', "data-testid": 'supplier-email' }),
                    React.createElement(ErrorList, { errors: supplierDetailsErrors, field: 'email' }))),
            React.createElement("div", { className: 'form-group group' },
                React.createElement("div", { className: 'label -uppercase' }, "Attachments"),
                React.createElement(DocumentPicker, { inputName: `supplier_details[${index}][attachments]`, errors: supplierDocumentErrors['attachments'] || {}, files: attachments, existingFiles: existingAttachments, handleRemoveFile: handleRemoveSupplierFile, handleFileChange: handleSupplierFileChange, handleFileReplace: handleSupplierFileReplace, handleDeleteFile: handleDeleteSupplierFile }),
                React.createElement(ErrorList, { errors: supplierDetailsErrors, field: 'attachments' })))));
};
export default SupplierDetailsEdit;
