import React, { useState, useEffect } from 'react';
import Drawer from '../shared/Drawer';
import { fetchAdjustments } from '../../api/adjustment';
import NewAdjustment from './NewAdjustment';
import AdjustmentCard from './AdjustmentCard';
var Actions;
(function (Actions) {
    Actions[Actions["VIEW_ADJUSTMENTS"] = 0] = "VIEW_ADJUSTMENTS";
    Actions[Actions["NEW_ADJUSTMENT"] = 1] = "NEW_ADJUSTMENT";
})(Actions || (Actions = {}));
const CashAdjustmentsDrawer = ({ customersOptions, currentUserId, apiToken, companyId, uploadedInvoicesFileId, reasons, isReconciled, userId, exportPath }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [adjustments, setAdjustments] = useState();
    const [action, setAction] = useState(Actions.VIEW_ADJUSTMENTS);
    const handleGoBack = () => {
        setAction(Actions.VIEW_ADJUSTMENTS);
    };
    useEffect(() => {
        const fetchData = async () => {
            const adjustments = await fetchAdjustments(apiToken, companyId, uploadedInvoicesFileId);
            setAdjustments(adjustments);
        };
        fetchData();
    }, [action]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'shadow-reconciliation-row -clickable', onClick: () => setIsOpen(true) },
            React.createElement("div", { className: 'label' },
                "Cash Adjustments",
                ' ',
                React.createElement("div", { className: 'custom-icon -clickable -open-outline' }))),
        React.createElement(Drawer, { isOpen: isOpen, onClose: () => setIsOpen(false) },
            action === Actions.VIEW_ADJUSTMENTS && (React.createElement("div", { className: 'cash-received-drawer' },
                React.createElement("div", { className: 'actions' },
                    React.createElement("button", { className: 'back', onClick: () => setIsOpen(false) }, "Go Back"),
                    !isReconciled && (React.createElement("button", { className: 'button -apply', onClick: () => setAction(Actions.NEW_ADJUSTMENT) }, "New Cash Adjustment"))),
                React.createElement("div", { className: 'header' },
                    "Cash Adjustments",
                    React.createElement("div", { className: 'action-buttons' },
                        React.createElement("a", { className: 'button -secondary', href: exportPath }, "Export"))),
                React.createElement("div", { className: 'separator' }),
                React.createElement("div", { className: 'transactions' }, adjustments === null || adjustments === void 0 ? void 0 : adjustments.map(adjustment => (React.createElement(AdjustmentCard, { key: adjustment.id, row: adjustment, userId: userId })))))),
            action === Actions.NEW_ADJUSTMENT && (React.createElement(NewAdjustment, { customersOptions: customersOptions, currentUserId: currentUserId, reasons: reasons, apiToken: apiToken, companyId: companyId, uploadedInvoicesFileId: uploadedInvoicesFileId, onGoBack: handleGoBack })))));
};
export default CashAdjustmentsDrawer;
