import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import ErrorList from '../shared/ErrorList';
import SpinnerButton from '../shared/SpinnerButton';
import { numberToPercentageFixed } from '../../utils/numberToPercentage';
import { updateDebtorAgeingThreshold } from '../../api/client_setup';
import { isAPIValidationErrors, isAPIErrors } from '../../../types';
import { showNotifyToast } from '../../utils';
const DebtorAgeingThresholdForm = ({ authToken, borrowerId, client, isReadOnly = false, incrementSaves }) => {
    const [clientInfo, setClientInfo] = useState(client);
    const [isLoading, setIsLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const { debtorAgeingThreshold } = clientInfo;
    const [currentDebtorAgeingThreshold, setCurrentDebtorAgeingThreshold] = useState({
        floatValue: debtorAgeingThreshold * 100,
        value: numberToPercentageFixed(debtorAgeingThreshold),
        formattedValue: ''
    });
    const handleDebtorAgeingThresholdChange = (values) => {
        setCurrentDebtorAgeingThreshold(values);
    };
    const handleSave = async () => {
        const body = {
            company: {
                debtorAgeingThreshold: currentDebtorAgeingThreshold.floatValue / 100 || 0
            }
        };
        setIsLoading(true);
        try {
            const response = await updateDebtorAgeingThreshold(authToken, borrowerId, body);
            if (isAPIValidationErrors(response)) {
                throw response;
            }
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            const updatedClientInfo = response.data.attributes;
            setClientInfo(updatedClientInfo);
            setCurrentDebtorAgeingThreshold({
                floatValue: updatedClientInfo.debtorAgeingThreshold * 100,
                value: numberToPercentageFixed(updatedClientInfo.debtorAgeingThreshold),
                formattedValue: ''
            });
            setValidationErrors({});
            if (incrementSaves) {
                incrementSaves();
            }
            showNotifyToast({
                text: 'Successfully updated Debtor Ageing Threshold.',
                type: 'success'
            });
        }
        catch (err) {
            if (isAPIValidationErrors(err)) {
                setValidationErrors(err.errors);
            }
            showNotifyToast({
                text: 'Failed to update Debtor Ageing Threshold.',
                type: 'error'
            });
        }
        setIsLoading(false);
    };
    return (React.createElement("div", { className: 'client-setup-form form -debtor-ageing-threshold', "data-testid": 'debtor-ageing-threshold-form' },
        React.createElement("div", { className: 'form-group group' },
            React.createElement("div", { className: 'text' }, "If the 90+ days ageing bucket is above the set percentage threshold, then invoices belonging to the debtor will be unapproved, this will take a few minutes to take effect."),
            React.createElement("label", { className: 'label' }, "Set Threshold (%)"),
            React.createElement(NumberFormat, { className: 'input form-control', suffix: '%', decimalScale: 1, allowNegative: false, value: currentDebtorAgeingThreshold.value, onValueChange: handleDebtorAgeingThresholdChange, disabled: isReadOnly, "data-testid": 'debtor-ageing-threshold-input' }),
            React.createElement(ErrorList, { errors: validationErrors, field: 'debtor_ageing_threshold' })),
        React.createElement("div", { className: 'action-buttons actions' },
            React.createElement(SpinnerButton, { text: 'Save', buttonType: 'button', isLoading: isLoading, handleClick: handleSave, className: 'button -primary', isDisabled: isReadOnly, testId: 'save' }))));
};
export default DebtorAgeingThresholdForm;
