import React from 'react';
import CompanyName from '../CompanyName';
const UndoDrawdown = ({ event }) => {
    const { attributes } = event;
    const { createdAtFormatted, companyName, companyLink, eventable: { attributes: { amount } } } = attributes;
    return (React.createElement("div", { className: 'event-box event', "data-testid": 'undo-drawdown-activity' },
        React.createElement("div", { className: 'icon' },
            React.createElement("div", { className: 'activity-icon -undo-drawdown' })),
        React.createElement("div", { className: 'description' },
            React.createElement("div", { className: 'title' },
                "Earlypay reversed",
                React.createElement("span", { className: 'bold' }, " drawdown "),
                "of",
                React.createElement("span", { className: 'amount -red' },
                    " ",
                    amount,
                    " "),
                "for ",
                React.createElement(CompanyName, { name: companyName, companyLink: companyLink })),
            React.createElement("div", { className: 'date' },
                " ",
                createdAtFormatted,
                " "))));
};
export default UndoDrawdown;
