import React from 'react';
import Select from 'react-select';
import { dropdownStyles } from '../../custom-styles/dropdown';
import { isEmptyObject } from '../../utils';
import ErrorList from '../shared/ErrorList';
const SupplierDetails = ({ index, currentSupplierDetails, supplierOptions, selectedSupplierOption, supplierDetailsErrors, handleSupplierUpdate, handleSupplierDetailUpdate }) => {
    const isNewSupplier = selectedSupplierOption && selectedSupplierOption.value === 'new_supplier';
    const { name, taxNumber, contactName, contactPhoneNumber, address, email } = currentSupplierDetails;
    return (React.createElement("div", { className: 'form-section', "data-testid": 'supplier-details' },
        React.createElement("div", { className: 'body' },
            React.createElement("div", { className: 'form-group -section' },
                React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { className: 'label -uppercase -required' }, "Find or create new supplier"),
                    React.createElement(Select, { value: selectedSupplierOption, placeholder: 'Select a supplier', isClearable: true, options: supplierOptions, onChange: handleSupplierUpdate, className: 'select', styles: dropdownStyles, name: `supplier[${index}]` }),
                    !selectedSupplierOption &&
                        !isEmptyObject(supplierDetailsErrors) && (React.createElement(ErrorList, { errors: { supplier: ['Please select an option.'] }, field: 'supplier' }))),
                isNewSupplier && (React.createElement("div", { className: 'form-group', "data-testid": 'new-supplier-fields' },
                    React.createElement("label", { htmlFor: 'supplier-name', className: 'label -uppercase -required' }, "Supplier Name"),
                    React.createElement("input", { className: 'input form-control', maxLength: 100, name: `supplier_details[${index}][name]`, value: name, "data-testid": 'supplier-name', id: 'supplier-name', onChange: handleSupplierDetailUpdate }),
                    React.createElement(ErrorList, { errors: supplierDetailsErrors, field: 'name' }))))),
        !!selectedSupplierOption && (React.createElement("div", { className: 'heading', "data-testid": 'supplier-heading' },
            React.createElement("h1", { className: 'title2' }, "Supplier Details"))),
        !!selectedSupplierOption && (React.createElement("div", { className: 'body', "data-testid": 'supplier-body' },
            React.createElement("div", { className: 'form-group -section' },
                React.createElement("div", { className: 'form-group', "data-testid": 'input' },
                    React.createElement("label", { htmlFor: 'supplier-tax-number', className: 'label -uppercase' }, "ABN / Company Number"),
                    React.createElement("input", { className: 'input form-control', maxLength: 100, size: 50, name: `supplier_details[${index}][tax_number]`, onChange: handleSupplierDetailUpdate, id: 'supplier-tax-number', "data-testid": 'supplier-tax-number', value: taxNumber }),
                    React.createElement(ErrorList, { errors: supplierDetailsErrors, field: 'tax_number' })),
                React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { htmlFor: 'supplier-address', className: 'label -uppercase -required' }, "Address"),
                    React.createElement("input", { className: 'input form-control', maxLength: 100, size: 50, name: `supplier_details[${index}][address]`, onChange: handleSupplierDetailUpdate, value: address, id: 'supplier-address', "data-testid": 'supplier-address' }),
                    React.createElement(ErrorList, { errors: supplierDetailsErrors, field: 'address' })),
                React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { htmlFor: 'supplier-contact-name', className: 'label -uppercase' }, "Contact Name"),
                    React.createElement("input", { className: 'input form-control', maxLength: 100, size: 50, name: `supplier_details[${index}][contact_name]`, value: contactName, onChange: handleSupplierDetailUpdate, id: 'supplier-contact-name', "data-testid": 'supplier-contact-name' }),
                    React.createElement(ErrorList, { errors: supplierDetailsErrors, field: 'contact_name' })),
                React.createElement("div", { className: 'form-row -col-2' },
                    React.createElement("div", { className: 'form-group', "data-testid": 'input' },
                        React.createElement("label", { htmlFor: 'supplier-contact-phone-number', className: 'label -uppercase' }, "Contact Phone Number"),
                        React.createElement("input", { className: 'input form-control', maxLength: 100, size: 50, name: `supplier_details[${index}][contact_phone_number]`, onChange: handleSupplierDetailUpdate, value: contactPhoneNumber, id: 'supplier-contact-phone-number', "data-testid": 'supplier-contact-phone-number' }),
                        React.createElement(ErrorList, { errors: supplierDetailsErrors, field: 'contact_phone_number' })),
                    React.createElement("div", { className: 'form-group' },
                        React.createElement("label", { htmlFor: 'supplier-email', className: 'label -uppercase' }, "Contact Email Address"),
                        React.createElement("input", { className: 'input form-control', maxLength: 100, size: 50, name: `supplier_details[${index}][email]`, value: email, onChange: handleSupplierDetailUpdate, id: 'supplier-email', "data-testid": 'supplier-email' }),
                        React.createElement(ErrorList, { errors: supplierDetailsErrors, field: 'email' }))))))));
};
export default SupplierDetails;
