import Noty from 'noty';
// this is a wrapper function for Noty that sets some defaults
export const showNotifyToast = ({ text, type, layout = 'bottomRight', 
// used mint here since it has less styles to override
theme = 'mint', timeout = 5000, closeWith = ['click'], progressBar = false, redirectLink = '' }) => {
    new Noty({
        text,
        type,
        layout,
        theme,
        timeout,
        closeWith,
        progressBar,
        callbacks: {
            afterShow: function () {
                if (redirectLink !== '') {
                    setTimeout(() => {
                        window.location.assign(redirectLink);
                    }, 3000);
                }
            }
        }
    }).show();
};
