import React, { useState } from 'react';
import { deleteMultipleTradeLoanRepayments } from '../../api/trade_loan_repayment';
import { showNotifyToast } from '../../utils';
import SpinnerButton from '../../components/shared/SpinnerButton';
const TradeLoanRepaymentsTable = ({ repayments, apiToken, pagePath }) => {
    const [selectedIds, setSelectedIds] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const handleChange = ({ target }) => {
        const id = parseInt(target.value);
        if (selectedIds.includes(id)) {
            setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
        }
        else {
            setSelectedIds([...selectedIds, id]);
        }
    };
    const toggleSelectAll = ({ target }) => {
        if (target.checked) {
            const ids = repayments.map(repayment => repayment.id);
            setSelectedIds(ids);
        }
        else {
            setSelectedIds([]);
        }
    };
    const deleteTradeLoanRepayments = () => {
        setIsProcessing(true);
        deleteMultipleTradeLoanRepayments(apiToken, selectedIds)
            .then(() => {
            window.location.reload();
        })
            .catch(error => {
            showNotifyToast({ text: error.message, type: 'error' });
            setIsProcessing(false);
        });
    };
    const handleDelete = () => {
        if (selectedIds.length > 0) {
            if (selectedIds.length === 1 ||
                window.confirm('Do you want to delete these multiple repayments?')) {
                deleteTradeLoanRepayments();
            }
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'header -space-between' },
            "Repayments",
            status === 'new' && (React.createElement("div", { className: 'buttons', "data-testid": 'header-buttons' },
                React.createElement("div", { className: 'action-buttons -flexend' },
                    React.createElement(SpinnerButton, { className: 'custom-button button -repayment-bulk-delete', handleClick: handleDelete, isDisabled: Object.values(selectedIds).filter(Boolean).length === 0, isLoading: isProcessing, text: 'Delete Repayment' }))))),
        React.createElement("table", { className: 'custom-table', "data-testid": 'repayments-table' },
            React.createElement("thead", null,
                React.createElement("tr", { className: 'tall' },
                    status === 'new' ? (React.createElement("th", { className: 'header' },
                        React.createElement("input", { type: 'checkbox', onChange: toggleSelectAll }))) : null,
                    React.createElement("th", { className: 'header' }, "ID"),
                    React.createElement("th", { className: 'header' }, "Date"),
                    React.createElement("th", { className: 'header' }, "Trade Loan"),
                    React.createElement("th", { className: 'header' }, "Payment Count"),
                    React.createElement("th", { className: 'header' }, "Amount"),
                    React.createElement("th", { className: 'header' }, "Action"))),
            React.createElement("tbody", null, repayments.map(({ id, createdAt, tradeLoanName, tradeLoanPath, paymentsCount, amount }) => {
                return (React.createElement("tr", { className: 'customer-row', key: id },
                    status === 'new' ? (React.createElement("td", { className: 'cell' },
                        React.createElement("input", { type: 'checkbox', name: `repayments[${id}]`, id: `repayments_${id}`, onChange: handleChange, value: id, checked: selectedIds.includes(id) }))) : null,
                    React.createElement("td", { className: 'cell' }, id),
                    React.createElement("td", { className: 'cell' }, createdAt),
                    React.createElement("td", { className: 'cell' },
                        React.createElement("a", { href: tradeLoanPath }, tradeLoanName)),
                    React.createElement("td", { className: 'cell' }, paymentsCount),
                    React.createElement("td", { className: 'cell' }, amount),
                    React.createElement("td", { className: 'cell' },
                        React.createElement("a", { href: `${pagePath}/${id}` }, "View"))));
            })))));
};
export default TradeLoanRepaymentsTable;
