import classNames from 'classnames';
import React from 'react';
import SupplierDetailsForm from './SupplierDetails';
import PaymentDetailsForm from './PaymentDetails';
import { isEmptyObject } from '../../utils';
const SupplierPanel = ({ index, supplierCurrencyOptions, supplierOptions, supplierDetailsErrors, currentSupplierDetails, selectedSupplierOption, selectedSupplierCurrency, paymentDetailsErrors, currentPaymentDetails, displayRemoveButton = true, dispatch, handleSupplierUpdate, handleSupplierDetailUpdate, handleSupplierCurrencyUpdate, handlePaymentDetailUpdate, handleConfirmPaymentDetailsToggle }) => {
    const isFirstPanel = index === 0;
    const labelClass = classNames('header -space-between', {
        '-error': !isEmptyObject(supplierDetailsErrors) ||
            !isEmptyObject(paymentDetailsErrors)
    });
    const newSupplier = selectedSupplierOption && selectedSupplierOption.value === 'new_supplier';
    return (React.createElement("div", { className: 'custom-panel panel -collapsible', "data-testid": 'supplier-panel' },
        React.createElement("input", { className: 'toggle', id: `supplier-${index}-label`, "data-testid": `supplier-${index}-checkbox`, type: 'checkbox', defaultChecked: isFirstPanel }),
        React.createElement("label", { className: labelClass, htmlFor: `supplier-${index}-label` }, `Supplier ${index + 1}`),
        React.createElement("div", { className: 'body -padded' },
            React.createElement(SupplierDetailsForm, { ...{
                    index,
                    supplierCurrencyOptions,
                    supplierOptions,
                    supplierDetailsErrors,
                    currentSupplierDetails,
                    selectedSupplierOption,
                    selectedSupplierCurrency,
                    handleSupplierUpdate,
                    handleSupplierDetailUpdate,
                    handleSupplierCurrencyUpdate
                } }),
            selectedSupplierOption && (React.createElement(PaymentDetailsForm, { ...{
                    index,
                    handlePaymentDetailUpdate,
                    handleConfirmPaymentDetailsToggle,
                    paymentDetailsErrors,
                    currentPaymentDetails
                } })),
            newSupplier && (React.createElement("div", { className: 'body', "data-testid": 'notice-note' },
                React.createElement("div", { className: 'form-group form-section -section' },
                    React.createElement("div", { className: 'notice' },
                        React.createElement("span", { className: 'custom-icon -notice' }),
                        React.createElement("p", null,
                            "The ",
                            React.createElement("b", null, "supplier"),
                            " above will be saved once the application is complete."))))),
            displayRemoveButton && (React.createElement("div", { className: 'form-section' },
                React.createElement("button", { className: 'custom-button button -remove-trade-loan-supplier', onClick: () => dispatch({ type: 'REMOVE_SUPPLIER', index: index }), "data-testid": 'remove' }, "Remove Supplier"))))));
};
export default SupplierPanel;
