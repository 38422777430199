import fetch from 'isomorphic-fetch';
export const fetchUnreconciledPayments = async (apiToken, companyId, startDate, endDate, status = 'unreconciled') => {
    const endpoint = `/api/admin/companies/${companyId}/manual_ledger/payments?start_date=${startDate}&end_date=${endDate}&status=${status}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
