import React, { useState } from 'react';
import classNames from 'classnames';
import ReportModal from './ReportModal';
const Panel = ({ isOpened, csvUrl, pdfUrl, excelUrl }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [baseUrl, setBaseUrl] = useState(csvUrl);
    const [hasSelected, setHasSelected] = useState(false);
    const [selectedReport, setSelectedReport] = useState('CSV');
    const panelClass = classNames('report-download-panel panel', {
        '-active': isOpened
    });
    const buttonClass = classNames('button -active', {
        '-disabled': !hasSelected
    });
    /*
      Temporary so that modal will be disabled and just go straight to download for now
      Make sure to re-import MouseEvent when uncommenting this
    const handleOnClickDownload = (event: MouseEvent) => {
        if (selectedReport == 'PDF' || selectedReport == 'XLSX') {
        event.preventDefault()
        setIsModalVisible(true)
      } else {
        setIsModalVisible(false)
      }
      setIsModalVisible(false)
    }*/
    /* remove this when modal is enabled again */
    const handleOnClickDownload = () => {
        setIsModalVisible(false);
    };
    const handleCloseModal = () => {
        setIsModalVisible(false);
    };
    const handleSelectedReportChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue === 'pdf') {
            setBaseUrl(pdfUrl);
            setSelectedReport('PDF');
        }
        else if (selectedValue === 'xlsx') {
            setBaseUrl(excelUrl);
            setSelectedReport('XLSX');
        }
        else {
            setBaseUrl(csvUrl);
            setSelectedReport('CSV');
        }
        setHasSelected(true);
    };
    return (React.createElement("div", { className: panelClass, "data-testid": 'panel' },
        React.createElement(ReportModal, { ...{
                isModalVisible: isModalVisible,
                path: baseUrl,
                handleCloseModal: handleCloseModal
            } }),
        React.createElement("div", { className: 'radio -title' }, "Summary Report"),
        React.createElement("div", { className: 'radio' },
            React.createElement("input", { type: 'radio', id: 'csv_button', value: 'csv', className: 'input', name: 'selected_report_download', onChange: handleSelectedReportChange }),
            React.createElement("label", { htmlFor: 'csv_button', className: 'label' }, "Download as .csv")),
        React.createElement("div", { className: 'radio -title' }, "Detailed Report"),
        React.createElement("div", { className: 'radio' },
            React.createElement("input", { type: 'radio', id: 'pdf_button', value: 'pdf', className: 'input', name: 'selected_report_download', onChange: handleSelectedReportChange }),
            React.createElement("label", { htmlFor: 'pdf_button', className: 'label' }, "Download as .pdf")),
        React.createElement("div", { className: 'radio' },
            React.createElement("input", { type: 'radio', id: 'excel_button', value: 'xlsx', className: 'input', name: 'selected_report_download', onChange: handleSelectedReportChange }),
            React.createElement("label", { htmlFor: 'excel_button', className: 'label' }, "Download as .xlsx")),
        React.createElement("div", { className: 'actions' },
            React.createElement("a", { className: buttonClass, href: baseUrl, onClick: handleOnClickDownload },
                "Download ",
                selectedReport))));
};
export default Panel;
