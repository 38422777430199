import React from 'react';
import AssignBorrowerDropdown from './assign-transactions/AssignBorrowerDropdown';
import DeleteTransaction from './delete-transaction/DeleteTransaction';
import AllocateClientDropdown from './allocate-client/AllocateClientDropdown';
const HeaderButtons = ({ csrfToken, path, allocatePath, selectedIdString, apiToken, companyOptions, unassignedTransactions, selectedTransactionIds, setUnassignedTransactions, setSelectedTransactionIds }) => {
    const disable = selectedIdString === '';
    return (React.createElement("div", { className: 'buttons', "data-testid": 'header-buttons' },
        React.createElement(AllocateClientDropdown, { ...{
                csrfToken,
                allocatePath,
                companyOptions,
                selectedIdString,
                disable
            } }),
        React.createElement(AssignBorrowerDropdown, { ...{
                csrfToken,
                path,
                companyOptions,
                selectedIdString,
                disable
            } }),
        React.createElement(DeleteTransaction, { ...{
                path,
                csrfToken,
                selectedIdString,
                apiToken,
                unassignedTransactions,
                selectedTransactionIds,
                setUnassignedTransactions,
                setSelectedTransactionIds,
                disable
            } })));
};
export default HeaderButtons;
