const inputBg = '244, 245, 247';
const neutral20 = '#f4f5f7';
const neutral30 = '#ebecf0';
const neutral50 = '#c1c7d0';
const neutral80 = '#97a0af';
const neutral800 = '#172b4d';
export const dropdownStyles = {
    control: (base, state) => ({
        ...base,
        width: '100%',
        color: neutral800,
        overflow: 'hidden',
        padding: '0 16px',
        fontSize: '14px',
        border: 'none',
        borderRadius: '4px',
        boxShadow: state.isFocused
            ? `inset 0 0 0 2px rgba(${inputBg}, 0.2)`
            : 'none',
        backgroundColor: neutral20,
        height: '48px',
        ':hover': {
            boxShadow: `inset 0 0 0 2px rgba(${inputBg}, 0.2)`,
            backgroundColor: neutral30
        }
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    option: (base, state) => ({
        ...base,
        padding: '8px 16px',
        fontSize: '14px',
        fontWeight: state.isSelected ? '600' : '400'
    }),
    menu: base => ({
        ...base,
        border: 'none',
        borderRadius: '4px',
        overflow: 'hidden',
        backgroundColor: `rgba(${inputBg}, 1)`
    }),
    menuList: base => ({
        ...base,
        border: 'none',
        padding: '0'
    }),
    input: () => ({
        padding: '0'
    }),
    valueContainer: base => ({
        ...base,
        padding: '0'
    }),
    dropdownIndicator: base => ({
        ...base,
        color: 'black',
        ':hover': {
            color: 'black'
        }
    }),
    clearIndicator: base => ({
        ...base,
        color: neutral80,
        ':hover': {
            color: neutral80
        }
    }),
    placeholder: base => ({
        ...base,
        color: neutral50
    })
};
export const errorStyle = {
    container: base => ({
        ...base,
        border: '1px solid #bf2600',
        borderRadius: '4px'
    }),
    control: base => ({
        ...base,
        backgroundColor: '#ffebe6'
    })
};
