import fetch from 'isomorphic-fetch';
import queryString from 'query-string';
export const fetchEvents = async (apiToken, scope, page, filter, dateFilter = '') => {
    const params = { page, 'action_filter[]': filter, date_filter: dateFilter };
    const endpoint = `/api/${scope}/activities?${queryString.stringify(params)}`;
    return fetch(endpoint, {
        headers: {
            Authorization: apiToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((res) => res.json());
};
