import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import Modal from '../shared/Modal';
import { formatPrice } from '../../utils/formatPrice';
const FindAndAssignInvoicesModal = ({ payment, selectedInvoiceIds, formAttributes: { path, csrfToken }, invoices }) => {
    const { amount, amountCents, text, dateFormatted } = payment;
    const [isOpen, setIsOpen] = useState(false);
    const [amountLeftCents, setAmountLeftCents] = useState(amountCents);
    const [searchQuery, setSearchQuery] = useState();
    const [filteredInvoices, setFilteredInvoices] = useState(invoices);
    const [currentSelectedInvoiceIds, setCurrentSelectedInvoiceIds] = useState(selectedInvoiceIds);
    // Converts invoices array into object with invoice id as key and invoice as value for faster lookup
    const invoicesObject = invoices.reduce((acc, invoice) => ((acc[invoice.id] = invoice), acc), {});
    const handleCloseModal = () => {
        setIsOpen(false);
    };
    const handleSelectInvoiceClick = (event) => {
        const invoiceId = Number(event.currentTarget.getAttribute('data-invoice-id'));
        if (invoiceId && !currentSelectedInvoiceIds.includes(invoiceId)) {
            setCurrentSelectedInvoiceIds([...currentSelectedInvoiceIds, invoiceId]);
        }
        event.stopPropagation();
    };
    const handleRemoveInvoiceClick = (event) => {
        const invoiceId = Number(event.currentTarget.getAttribute('data-invoice-id'));
        setCurrentSelectedInvoiceIds(currentSelectedInvoiceIds.filter(id => id !== invoiceId));
        event.stopPropagation();
    };
    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const filterInvoices = () => {
        if (searchQuery) {
            const filteredInvoices = invoices.filter(invoice => invoice.number.toLowerCase().includes(searchQuery.toLowerCase()) ||
                invoice.customerName.toLowerCase().includes(searchQuery.toLowerCase()));
            setFilteredInvoices(filteredInvoices);
        }
        else {
            setFilteredInvoices(invoices);
        }
    };
    useEffect(() => {
        const amountLeftCents = currentSelectedInvoiceIds.reduce((acc, invoiceId) => acc - invoicesObject[invoiceId].amountCents, amountCents);
        setAmountLeftCents(amountLeftCents);
    }, [currentSelectedInvoiceIds]);
    useEffect(() => {
        filterInvoices();
    }, [searchQuery]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'action-buttons -mb-2' },
            React.createElement("button", { className: 'button -default -loose', onClick: () => setIsOpen(true), "data-testid": 'find-and-assign-invoices-button' }, "Find Invoices")),
        React.createElement(Modal, { isOpen: isOpen, onClose: handleCloseModal, contentSize: 'lg', dataTestId: 'find-and-match-invoices-modal' },
            React.createElement("div", { className: 'find-and-assign-invoices-modal' },
                React.createElement("div", { className: 'title' }, "Find Invoices"),
                React.createElement("div", { className: 'content' },
                    React.createElement("div", { className: 'reconcile-payments-container' },
                        React.createElement("p", { className: 'label -text-center' }, "AMOUNT LEFT"),
                        React.createElement("p", { className: `amountleft ${amountLeftCents < 0 ? '-red' : ''}` }, formatPrice(amountLeftCents / 100)),
                        React.createElement("div", { className: 'heading' },
                            React.createElement("div", { className: 'title' }, "Bank Statement Lines"),
                            React.createElement("div", { className: 'title' }, "Invoices")),
                        React.createElement("div", { className: 'find-and-match-container' },
                            React.createElement("div", { className: 'statements -justify-between' },
                                React.createElement("div", { className: 'payment' },
                                    React.createElement("div", { className: 'manual-ledger-statement-card' },
                                        React.createElement("div", { className: 'column' },
                                            React.createElement("div", { className: 'title' }, text)),
                                        React.createElement("div", { className: 'column' },
                                            React.createElement("div", { className: 'date' }, dateFormatted),
                                            React.createElement("div", { className: 'amount' }, amount)))),
                                React.createElement("div", { className: 'selected' }, currentSelectedInvoiceIds.map(invoiceId => {
                                    const invoice = invoicesObject[invoiceId];
                                    return (React.createElement("div", { className: 'invoice', key: invoice.id, "data-invoice-id": invoice.id },
                                        React.createElement("div", { className: 'manual-ledger-statement-card -selected' },
                                            React.createElement("div", { className: 'column' },
                                                React.createElement("div", { className: 'title' }, invoice.customer_name),
                                                React.createElement("div", null, invoice.number)),
                                            React.createElement("div", { className: 'column' },
                                                React.createElement("div", { className: 'date' }, invoice.issue_date_formatted_string),
                                                React.createElement("div", { className: 'amount' }, invoice.amountFormatted))),
                                        React.createElement("div", { className: 'action-buttons' },
                                            React.createElement("button", { className: 'button -danger -link -loose', "data-invoice-id": invoice.id, onClick: handleRemoveInvoiceClick }, "Remove Invoice"))));
                                }))),
                            React.createElement("hr", { className: 'custom-divider -my-6' }),
                            React.createElement("div", { className: 'heading' }, "Click one or more invoices"),
                            React.createElement("div", { className: 'filter-table -expanded filter' },
                                React.createElement("input", { type: 'text', className: 'input -p-2', placeholder: 'Enter invoice number or customer name', onChange: handleSearchInputChange, maxLength: 100, "data-testid": 'filter-input' }),
                                React.createElement("div", { className: 'icon' })),
                            React.createElement("div", { className: 'invoices' },
                                filteredInvoices.length === 0 && (React.createElement("p", null, "There are no invoices to display")),
                                filteredInvoices.length > 0 &&
                                    filteredInvoices.map(invoice => {
                                        const { id, customerName, number, issueDateFormattedString, amountFormatted } = invoice;
                                        const invoiceCardClass = classNames('manual-ledger-statement-card', 'card', { '-selected': currentSelectedInvoiceIds.includes(id) });
                                        return (React.createElement("div", { key: id, "data-invoice-id": id, onClick: handleSelectInvoiceClick, className: invoiceCardClass },
                                            React.createElement("div", { className: 'column' },
                                                React.createElement("div", { className: 'title' }, customerName),
                                                React.createElement("div", null, number)),
                                            React.createElement("div", { className: 'column' },
                                                React.createElement("div", { className: 'date' }, issueDateFormattedString),
                                                React.createElement("div", { className: 'amount' }, amountFormatted))));
                                    }))))),
                React.createElement("form", { action: path, method: 'post', className: 'actions' },
                    React.createElement("div", { className: 'actions action-buttons' },
                        React.createElement("button", { type: 'button', className: 'button -loose', onClick: handleCloseModal }, "Cancel"),
                        React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
                        React.createElement("input", { type: 'hidden', name: 'payment_id', value: payment.id }),
                        currentSelectedInvoiceIds.map(invoiceId => (React.createElement("input", { key: invoiceId, type: 'hidden', name: 'selected_invoices_ids[]', value: invoiceId }))),
                        React.createElement("button", { type: 'submit', className: 'button -primary -loose' }, "Assign")))))));
};
export default FindAndAssignInvoicesModal;
