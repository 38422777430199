import React, { useState } from 'react';
import classNames from 'classnames';
import Panel from './Panel';
const FilterRepayment = ({ urlPath }) => {
    const [isOpened, setIsOpened] = useState(false);
    const togglePanel = () => setIsOpened(!isOpened);
    const buttonClass = classNames('icon-button button', '-rectangular', '-border-brand-a-400', '-filter', '-process-button', '-icon-filter-brand-a-400', '-text-brand-a-400');
    return (React.createElement("div", { className: 'process-panel -filter-process', "data-testid": 'filter-dropdown' },
        React.createElement("a", { href: '#', className: buttonClass, onClick: togglePanel },
            React.createElement("div", { className: 'text' }, "Download"),
            React.createElement("div", { className: 'icon' })),
        React.createElement(Panel, { ...{
                isOpened,
                urlPath
            } })));
};
export default FilterRepayment;
