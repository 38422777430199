import React from 'react';
import ApproveBulkInvoices from './approve-tab/ApproveBulkInvoices';
import classNames from 'classnames';
import VerifyBulkInvoices from './verify-tab/VerifyBulkInvoices';
import UnverifyBulkInvoices from './unverify-tab/UnverifyBulkInvoices';
import UnapproveBulkInvoices from './unapprove-tab/UnapproveBulkInvoices';
const HeaderButtons = ({ csrfToken, selectedIdString, apiToken, borrowerId, selectedInvoiceIds, setInvoices, setSelectedInvoiceIds, selectedInvoices, handleBulkApproveInvoices, handleBulkUnapproveInvoices, handleBulkVerifyInvoices, handleBulkUnverifyInvoices, isModalOpenForVerify, openModalForVerify, closeModalForVerify, isModalOpenForUnverify, openModalForUnverify, closeModalForUnverify, isModalOpenForUnapprove, openModalForUnapprove, closeModalForUnapprove, isModalOpenForApprove, openModalForApprove, closeModalForApprove, handleInvoiceId, disapprovalReasonsOptions }) => {
    const disable = selectedIdString === '';
    const buttonClass = classNames('dropdown-button', '-outline', {
        '-disabled': disable
    });
    const textClass = classNames('button', {
        '-disabled': disable
    });
    return (React.createElement("div", { className: 'buttons', "data-testid": 'header-buttons' },
        React.createElement("div", { className: buttonClass },
            React.createElement("div", { className: textClass }, "Mark as"),
            React.createElement("div", { className: 'container' },
                React.createElement("div", { className: 'toggle-list list' },
                    React.createElement(VerifyBulkInvoices, { ...{
                            csrfToken,
                            selectedIdString,
                            disable,
                            borrowerId,
                            apiToken,
                            selectedInvoiceIds,
                            setInvoices,
                            setSelectedInvoiceIds,
                            selectedInvoices,
                            isModalOpenForVerify,
                            openModalForVerify,
                            closeModalForVerify,
                            handleBulkVerifyInvoices
                        } }),
                    React.createElement(UnverifyBulkInvoices, { ...{
                            csrfToken,
                            selectedIdString,
                            disable,
                            borrowerId,
                            apiToken,
                            selectedInvoiceIds,
                            setInvoices,
                            setSelectedInvoiceIds,
                            selectedInvoices,
                            isModalOpenForUnverify,
                            openModalForUnverify,
                            closeModalForUnverify,
                            handleBulkUnverifyInvoices
                        } }),
                    React.createElement(ApproveBulkInvoices, { ...{
                            csrfToken,
                            selectedIdString,
                            disable,
                            borrowerId,
                            apiToken,
                            selectedInvoiceIds,
                            setInvoices,
                            setSelectedInvoiceIds,
                            selectedInvoices,
                            isModalOpenForApprove,
                            openModalForApprove,
                            closeModalForApprove,
                            handleBulkApproveInvoices,
                            handleInvoiceId,
                            disapprovalReasonsOptions
                        } }),
                    React.createElement(UnapproveBulkInvoices, { ...{
                            csrfToken,
                            selectedIdString,
                            disable,
                            borrowerId,
                            apiToken,
                            selectedInvoiceIds,
                            setInvoices,
                            setSelectedInvoiceIds,
                            selectedInvoices,
                            isModalOpenForUnapprove,
                            openModalForUnapprove,
                            closeModalForUnapprove,
                            handleBulkUnapproveInvoices
                        } }))))));
};
export default HeaderButtons;
