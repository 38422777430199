import React, { useState, useEffect } from 'react';
import NumberField from './NumberField';
import NumberFormat from 'react-number-format';
const NumberFormGroupWithGst = ({ model, attribute, defaultValue, gstDefaultChecked, gstDecimalScale = 2, labelText, allowNegative = false, readOnly = false, required = false }) => {
    const [isGstChecked, setIsGstChecked] = useState(gstDefaultChecked);
    const [currentValue, setCurrentValue] = useState(defaultValue);
    const [currentValueWithGst, setCurrentValueWithGst] = useState();
    useEffect(() => {
        if (isGstChecked) {
            setCurrentValueWithGst(Number(currentValue) * 1.1);
        }
    }, [currentValue, isGstChecked]);
    const handleOnValueChange = ({ floatValue, value }) => {
        if (floatValue) {
            setCurrentValueWithGst(floatValue * 1.1);
        }
        setCurrentValue(value);
    };
    return (React.createElement("div", { className: 'form-group number-input -oneline' },
        React.createElement("label", { className: 'label' },
            labelText,
            React.createElement(NumberField, { model: model, attribute: attribute, defaultValue: currentValue, allowNegative: allowNegative, readOnly: readOnly, onValueChange: handleOnValueChange, required: required }),
            currentValueWithGst !== undefined && isGstChecked && (React.createElement("div", { className: 'gstvalue' },
                "with GST:",
                ' ',
                React.createElement(NumberFormat, { displayType: 'text', value: currentValueWithGst.toFixed(gstDecimalScale), thousandSeparator: true })))),
        React.createElement("label", { className: 'label -gst-label' },
            "Add GST",
            React.createElement("input", { name: `${model}[${attribute}_gst]`, type: 'hidden', value: '0' }),
            React.createElement("input", { type: 'checkbox', className: 'input form-control', name: `${model}[${attribute}_gst]`, defaultChecked: gstDefaultChecked, onChange: () => setIsGstChecked(!isGstChecked) }))));
};
export default NumberFormGroupWithGst;
