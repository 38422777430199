import React from 'react';
import InvoiceDetailHeader from './InvoiceDetailHeader';
const InvoiceDetails = ({ invoices }) => {
    const multipleInvoices = invoices.length > 1;
    const doubleBottomClass = !multipleInvoices ? '-double-bottom' : '';
    const marginLeft = multipleInvoices ? 'content' : '';
    return (React.createElement("div", { className: 'list', "data-testid": 'invoice-details' }, invoices.map((invoice, index) => (React.createElement("div", { className: 'panel', key: invoice.number },
        multipleInvoices && (React.createElement(InvoiceDetailHeader, { number: invoice.number, status: invoice.status, index: index + 1 })),
        React.createElement("div", { className: `form-row -col-2 ${marginLeft}` },
            React.createElement("div", { className: `form-group -form-floating ${doubleBottomClass}` },
                React.createElement("label", { className: 'label -small', htmlFor: 'amount_due' }, "Amount Due"),
                React.createElement("input", { type: 'text', name: 'amount_due', id: 'amount_due', value: invoice.amountDue, className: 'input -emphasize -smaller', disabled: true })),
            React.createElement("div", { className: `form-group -form-floating ${doubleBottomClass}` },
                React.createElement("label", { className: 'label -small', htmlFor: 'issue_date' }, "Issue Date"),
                React.createElement("input", { type: 'text', name: 'issue_date', id: 'issue_date', value: invoice.issueDate, className: 'input -emphasize -smaller', disabled: true }))))))));
};
export default InvoiceDetails;
