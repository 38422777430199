import React, { useState } from 'react';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import { updateDebtorConcentrations } from '../../api/client_setup';
import { convertCentsString } from '../../utils/convertCents';
import { numberToPercentageFixed } from '../../utils/numberToPercentage';
import { isAPIValidationErrors, isAPIErrors } from '../../../types';
import { showNotifyToast } from '../../utils';
import { dropdownStyles } from '../../custom-styles/dropdown';
import ErrorList from '../shared/ErrorList';
import SpinnerButton from '../shared/SpinnerButton';
const DebtorConcentrationsForm = ({ authToken, borrowerId, client, platformDefaultLimitCents, platformDefaultLimitRate, isReadOnly = false, incrementSaves }) => {
    const concentrationTypeOptions = [
        {
            value: 'global_default_concentrations',
            label: 'Global Default Concentrations'
        },
        {
            value: 'custom_client_concentrations',
            label: 'Custom Client Concentrations'
        }
    ];
    const calculationTypeOptions = [
        { value: 'greater_of', label: 'Greater Of' },
        { value: 'lesser_of', label: 'Lesser Of' }
    ];
    const [clientInfo, setClientInfo] = useState(client);
    const { applyDefaultConcentrationLimits: applyDefaults, defaultConcentrationCalculationType: calculationType, defaultLimitCents: defaultLimitCents, defaultLimitRate: defaultLimitRate } = clientInfo;
    const defaultConcentrationType = applyDefaults
        ? concentrationTypeOptions[0]
        : concentrationTypeOptions[1];
    const defaultCalculationType = calculationTypeOptions.find(opt => opt.value === calculationType) ||
        calculationTypeOptions[0];
    const gdcInputValue = `Greater of $${convertCentsString(platformDefaultLimitCents)} and ${numberToPercentageFixed(platformDefaultLimitRate)}%`;
    const [selectedConcentrationType, setSelectedConcentrationType] = useState(defaultConcentrationType);
    const [currentCalculationType, setCurrentCalculationType] = useState(defaultCalculationType);
    const [currentDefaultLimitAmount, setCurrentDefaultLimitAmount] = useState({
        floatValue: defaultLimitCents / 100,
        value: convertCentsString(defaultLimitCents),
        formattedValue: ''
    });
    const [currentDefaultLimitRate, setCurrentDefaultLimitRate] = useState({
        floatValue: defaultLimitRate * 100,
        value: numberToPercentageFixed(defaultLimitRate),
        formattedValue: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const handleSelectedConcentrationTypeChange = (selectedOption) => {
        setSelectedConcentrationType(selectedOption);
    };
    const handleCurrentCalculationTypeChange = (selectedOption) => {
        setCurrentCalculationType(selectedOption);
    };
    const handleCurrentDefaultLimitCentsChange = (values) => {
        setCurrentDefaultLimitAmount(values);
    };
    const handleCurrentDefaultLimitRateChange = (values) => {
        setCurrentDefaultLimitRate(values);
    };
    const handleSave = async () => {
        const body = {
            company: {
                concentrationType: selectedConcentrationType
                    ? selectedConcentrationType.value
                    : '',
                defaultConcentrationCalculationType: currentCalculationType
                    ? currentCalculationType.value
                    : '',
                defaultLimitAmount: currentDefaultLimitAmount.value || '0',
                defaultLimitRate: currentDefaultLimitRate.value || '0'
            }
        };
        setIsLoading(true);
        try {
            const response = await updateDebtorConcentrations(authToken, borrowerId, body);
            if (isAPIValidationErrors(response)) {
                throw response;
            }
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            const { attributes } = response.data;
            setClientInfo(attributes);
            setCurrentDefaultLimitAmount({
                floatValue: attributes.defaultLimitCents / 100,
                value: convertCentsString(attributes.defaultLimitCents),
                formattedValue: ''
            });
            setCurrentDefaultLimitRate({
                floatValue: attributes.defaultLimitRate * 100,
                value: numberToPercentageFixed(attributes.defaultLimitRate),
                formattedValue: ''
            });
            setValidationErrors({});
            if (incrementSaves) {
                incrementSaves();
            }
            showNotifyToast({
                text: 'Successfully updated Debtor Concentrations.',
                type: 'success'
            });
        }
        catch (err) {
            if (isAPIValidationErrors(err)) {
                setValidationErrors(err.errors);
            }
            showNotifyToast({
                text: 'Failed to update Debtor Concentrations.',
                type: 'error'
            });
        }
        setIsLoading(false);
    };
    const isGlobalDefaultConcentration = selectedConcentrationType &&
        selectedConcentrationType.value === 'global_default_concentrations';
    const isCustomerClientConcentration = selectedConcentrationType &&
        selectedConcentrationType.value === 'custom_client_concentrations';
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'form form-pane', "data-testid": 'debtor-concentrations-form' },
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label -uppercase' }, "Concentration Type"),
                React.createElement(Select, { options: concentrationTypeOptions, value: selectedConcentrationType, styles: dropdownStyles, onChange: handleSelectedConcentrationTypeChange, isDisabled: isReadOnly })),
            isGlobalDefaultConcentration && (React.createElement("div", { className: 'form-group' },
                React.createElement("input", { value: gdcInputValue, className: 'input form-control', disabled: true, "data-testid": 'gdc-input' }))),
            isCustomerClientConcentration && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { className: 'label -uppercase' }, "Calculation Type"),
                    React.createElement(Select, { options: calculationTypeOptions, value: currentCalculationType, styles: dropdownStyles, isDisabled: isReadOnly, onChange: handleCurrentCalculationTypeChange }),
                    React.createElement(ErrorList, { errors: validationErrors, field: 'default_concentration_calculation_type' })),
                React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { className: 'label -uppercase' }, "Default Limit ($)"),
                    React.createElement(NumberFormat, { className: 'input form-control', prefix: '$', decimalScale: 2, thousandSeparator: true, allowNegative: false, value: currentDefaultLimitAmount.value, onValueChange: handleCurrentDefaultLimitCentsChange, disabled: isReadOnly, "data-testid": 'limit-cents-input' })),
                React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { className: 'label -uppercase' }, "Default Limit (%)"),
                    React.createElement(NumberFormat, { className: 'input form-control', suffix: '%', decimalScale: 1, allowNegative: false, value: currentDefaultLimitRate.value, onValueChange: handleCurrentDefaultLimitRateChange, disabled: isReadOnly, "data-testid": 'limit-rate-input' })))),
            React.createElement("div", { className: 'action-buttons' },
                React.createElement(SpinnerButton, { text: 'Save', buttonType: 'button', isLoading: isLoading, handleClick: handleSave, className: 'button -primary', isDisabled: isReadOnly, testId: 'save' }))),
        React.createElement("div", { className: 'funding-attributes' },
            React.createElement("div", { className: 'stats' },
                React.createElement("div", { className: 'data-figure figure -unpadded' },
                    React.createElement("div", { className: 'label' }, "Effective Default Limit"),
                    React.createElement("div", { className: 'data' }, clientInfo.effectiveDefaultLimitFormatted))))));
};
export default DebtorConcentrationsForm;
