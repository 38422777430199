import React from 'react';
import IconToggle from '../shared/IconToggle';
const PaymentRow = ({ payment, handlePaymentId, selected }) => {
    const { id, amountFormatted, accountName, fromCollectionAccount, currency, customerApproved, customerName, invoiceNumber, isReconciled, updatedDateUtc, invoiceUrl } = payment;
    const handleCheckboxChange = () => {
        handlePaymentId(id);
    };
    return (React.createElement("tr", { onClick: handleCheckboxChange },
        React.createElement("td", { className: 'cell' },
            React.createElement("label", { className: 'label -checkbox' },
                React.createElement("input", { type: 'checkbox', checked: selected, onChange: handleCheckboxChange, "data-testid": `payments-checkbox-${id}` }))),
        React.createElement("td", { className: 'cell' }, id),
        React.createElement("td", { className: 'cell' },
            React.createElement("a", { href: invoiceUrl }, invoiceNumber)),
        React.createElement("td", { className: 'cell' }, customerName),
        React.createElement("td", { className: 'cell' }, currency),
        React.createElement("td", { className: 'cell' }, amountFormatted),
        React.createElement("td", { className: 'cell' }, accountName),
        React.createElement("td", { className: 'cell' }, updatedDateUtc),
        React.createElement("td", { className: 'cell' },
            ' ',
            React.createElement(IconToggle, { isOn: customerApproved, onIcon: 'check', offIcon: 'close' })),
        React.createElement("td", { className: 'cell' },
            React.createElement(IconToggle, { isOn: fromCollectionAccount, onIcon: 'check', offIcon: 'close' })),
        React.createElement("td", { className: 'cell' },
            React.createElement(IconToggle, { isOn: isReconciled, onIcon: 'check', offIcon: 'close' }))));
};
export default PaymentRow;
