import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import { computeTradeLoanCommission } from '../../api/commission';
import { dropdownStyles } from '../../custom-styles/dropdown';
import { showNotifyToast } from '../../utils';
import { isAPIErrors } from '../../../types';
import format from 'date-fns/format';
const CommissionDetails = ({ isPreview = false, borrowerId, apiToken, monthOption, tradeLoanOptions = [], selectedCommissions, setSelectedCommissions, totalTally, setTotalTally }) => {
    const [tradeLoansDropdown, setTradeLoanDropdown] = useState([]);
    useEffect(() => {
        updateTotalTally();
    }, [selectedCommissions]);
    useEffect(() => {
        const options = tradeLoanOptions.map(trade_loan => {
            return {
                value: trade_loan.value.toString(),
                label: trade_loan.label,
                isDisabled: false
            };
        });
        setTradeLoanDropdown(options);
    }, [tradeLoanOptions]);
    const updateTotalTally = () => {
        const tally = Object.values(selectedCommissions)
            .filter(data => {
            return data.status === 'ok';
        })
            .reduce((prev, current) => {
            const currentAmount = typeof current.amount === 'number'
                ? current.amount
                : parseFloat(current.amount);
            const currentGstAmount = typeof current.gstAmount === 'number'
                ? current.gstAmount
                : parseFloat(current.gstAmount);
            const amount = prev.amount + currentAmount;
            const gstAmount = prev.gstAmount + currentGstAmount;
            return {
                amount: amount,
                gstAmount: gstAmount,
                all: amount + gstAmount
            };
        }, { amount: 0, gstAmount: 0, all: 0 });
        setTotalTally(tally);
    };
    const computeAmount = async (id) => {
        try {
            const targetDate = format(new Date(monthOption.value), `yyyy-MM-dd`);
            const response = await computeTradeLoanCommission(apiToken, borrowerId.toString(), id, targetDate);
            return response;
        }
        catch (err) {
            showNotifyToast({
                text: `Something went wrong while computing client's commissions. Please try again.`,
                type: 'error'
            });
            return { amount: 0, gst: 0, status: 'failed' };
        }
    };
    const addCommissionTradeLoan = () => {
        const entries = Object.entries(selectedCommissions).length;
        setSelectedCommissions({
            ...selectedCommissions,
            [`item${entries}`]: {
                id: null,
                amount: 0,
                gstAmount: 0,
                status: 'initial'
            }
        });
    };
    const subTotal = ({ amount, gstAmount }) => {
        return (parseFloat(amount) + parseFloat(gstAmount)).toFixed(2);
    };
    const buildLabel = (data) => {
        const selected = tradeLoansDropdown.filter(({ value }) => value === data.id.toString());
        return selected.length ? selected[0].label : '';
    };
    const handleDelete = (target) => {
        setSelectedCommissions({
            ...selectedCommissions,
            [`${target}`]: { status: 'deleted' }
        });
    };
    const handleChangeTradeLoan = async (selected, target) => {
        try {
            const { amount, gst } = await computeAmount(selected.value);
            const formattedData = {
                id: selected.value,
                amount: amount,
                gstAmount: gst,
                status: 'ok'
            };
            setSelectedCommissions({
                ...selectedCommissions,
                [`${target}`]: formattedData
            });
        }
        catch (err) {
            if (isAPIErrors(err)) {
                throw new Error(err.errors.map(error => error.title).join(','));
            }
        }
    };
    const handleAmountChange = (newAmount, target) => {
        const currentData = selectedCommissions[`${target}`];
        const tempNewAmount = newAmount || 0;
        const updatedData = {
            ...currentData,
            amount: tempNewAmount,
            gstAmount: tempNewAmount * 0.1
        };
        setSelectedCommissions({
            ...selectedCommissions,
            [`${target}`]: updatedData
        });
    };
    const handleGstChange = (newGstAmount, target) => {
        const currentData = selectedCommissions[`${target}`];
        const tempNewGstAmount = newGstAmount || 0;
        const updatedData = { ...currentData, gstAmount: tempNewGstAmount };
        setSelectedCommissions({
            ...selectedCommissions,
            [`${target}`]: updatedData
        });
    };
    return (React.createElement("table", { className: 'commission-table' },
        React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement("th", null, "Trade Loan"),
                React.createElement("th", null, "Amount"),
                React.createElement("th", null, "GST"),
                React.createElement("th", null, "Total (inc GST)"),
                React.createElement("th", null))),
        React.createElement("tbody", null,
            Object.entries(selectedCommissions)
                .filter(([_key, data]) => data.status === 'ok' || (!isPreview && data.status === 'initial'))
                .map(([key, data]) => {
                return (React.createElement("tr", { key: key, id: key },
                    React.createElement("td", null, isPreview ? (React.createElement("p", null, buildLabel(data))) : (React.createElement(Select, { options: tradeLoansDropdown, className: 'select', styles: dropdownStyles, onChange: (e) => {
                            handleChangeTradeLoan(e, key);
                        }, name: 'broker_client', value: tradeLoansDropdown.filter(({ value }) => value === data.id), isDisabled: isPreview }))),
                    React.createElement("td", null,
                        React.createElement(NumberFormat, { className: `input form-control -right ${!isPreview &&
                                '-bare-padded'}`, thousandSeparator: true, allowNegative: false, decimalScale: 2, value: data.amount, readOnly: !isPreview, onValueChange: e => {
                                handleAmountChange(e.floatValue, key);
                            } })),
                    React.createElement("td", null,
                        React.createElement(NumberFormat, { className: `input form-control -right ${!isPreview &&
                                '-bare-padded'}`, thousandSeparator: true, allowNegative: false, decimalScale: 2, value: data.gstAmount, readOnly: !isPreview, onValueChange: e => {
                                handleGstChange(e.floatValue, key);
                            } })),
                    React.createElement("td", null,
                        React.createElement("div", { className: 'total' },
                            React.createElement(NumberFormat, { value: subTotal(data), thousandSeparator: true, displayType: 'text', decimalScale: 2 }))),
                    React.createElement("td", null, !isPreview && (React.createElement("button", { type: 'button', className: 'icon-button -trash-red-500 -square -border-red-75', onClick: () => {
                            handleDelete(key);
                        } },
                        React.createElement("i", { className: 'icon' }))))));
            }),
            !isPreview && (React.createElement("tr", null,
                React.createElement("td", { colSpan: 5 },
                    React.createElement("button", { type: 'button', className: 'custom-button button -add-commission', onClick: addCommissionTradeLoan }, "Add Commission"))))),
        React.createElement("tfoot", null,
            React.createElement("tr", null,
                React.createElement("td", null, "Total"),
                React.createElement("td", null,
                    React.createElement("span", { className: 'tally' },
                        React.createElement(NumberFormat, { value: totalTally.amount, thousandSeparator: true, displayType: 'text', decimalScale: 2 }))),
                React.createElement("td", null,
                    React.createElement("span", { className: 'tally' },
                        React.createElement(NumberFormat, { value: totalTally.gstAmount, thousandSeparator: true, displayType: 'text', decimalScale: 2 }))),
                React.createElement("td", null,
                    React.createElement("span", null,
                        React.createElement(NumberFormat, { value: totalTally.all, thousandSeparator: true, displayType: 'text', decimalScale: 2 })))))));
};
export default CommissionDetails;
