import fetch from 'isomorphic-fetch';
import { buildHeaders } from './shared';
import queryString from 'query-string';
export const setRtgsRequiredOn = async (apiToken, drawdownId) => {
    const endpoint = `/api/admin/drawdowns/${drawdownId}/set_rtgs_required_on`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const setRtgsRequiredOff = async (apiToken, drawdownId) => {
    const endpoint = `/api/admin/drawdowns/${drawdownId}/set_rtgs_required_off`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const setProcessedAtBank = async (apiToken, drawdownId) => {
    const endpoint = `/api/admin/drawdowns/${drawdownId}/set_processed_at_bank`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const unsetProcessedAtBank = async (apiToken, drawdownId) => {
    const endpoint = `/api/admin/drawdowns/${drawdownId}/unset_processed_at_bank`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const fetchDrawdownsRTGS = async (apiToken, page) => {
    const params = {
        page
    };
    const search = queryString.stringify(params);
    const endpoint = `/api/admin/drawdowns_rtgs?${search}`;
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'GET'
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.map(error => error.title).join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
export const fetchDrawdownsThirdParty = async (apiToken, page) => {
    const params = {
        page
    };
    const search = queryString.stringify(params);
    const endpoint = `/api/admin/drawdowns_third_party?${search}`;
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'GET'
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.map(error => error.title).join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
export const fetchDrawdownsEquipment = async (apiToken, page) => {
    const params = {
        page
    };
    const search = queryString.stringify(params);
    const endpoint = `/api/admin/drawdowns_equipment?${search}`;
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'GET'
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.map(error => error.title).join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
export const fetchDrawdownsPlatformEntry = async (apiToken, page) => {
    const params = {
        page
    };
    const search = queryString.stringify(params);
    const endpoint = `/api/admin/drawdowns_platform_entry?${search}`;
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'GET'
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.map(error => error.title).join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
export const fetchDrawdownsOther = async (apiToken, page) => {
    const params = {
        page
    };
    const search = queryString.stringify(params);
    const endpoint = `/api/admin/drawdowns_other?${search}`;
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'GET'
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.map(error => error.title).join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
export const fetchDrawdownsRegular = async (apiToken, page) => {
    const params = {
        page
    };
    const search = queryString.stringify(params);
    const endpoint = `/api/admin/drawdowns_regular?${search}`;
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'GET'
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.map(error => error.title).join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
