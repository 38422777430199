import Dinero from 'dinero.js';
import bigDecimal from 'js-big-decimal';
export const convertToCents = (amount) => {
    const decimalAmount = new bigDecimal(amount);
    const multiplier = new bigDecimal(100);
    return parseInt(decimalAmount
        .multiply(multiplier)
        .round()
        .getValue());
};
export const convertToMoneyObject = (amount, cents = false, currency = 'AUD') => {
    const amountCents = cents ? amount : convertToCents(amount);
    return Dinero({ amount: amountCents, currency });
};
