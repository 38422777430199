import React from 'react';
import PaginationBar from '../shared/PaginationBar';
import SortableTableHeaders from '../table-headers/SortableTabs';
import AdminCreditorsTableBody from './AdminCreditorsTableBody';
const AdminCreditorsTable = ({ apiToken, borrowerCreditorsPath, borrowerId, currentPage, creditorsArray, isLoading, hasErrors, pageSize, sortBy, sortDirection, totalPages, totalItems, forwardHeaderRef, headerWidths, handlePageClick, sortCreditors }) => {
    const headerFields = [
        { text: 'Creditors', field: 'name' },
        { text: 'Current' },
        { text: 'Overdue', field: 'invoice_amount_due', defaultSort: 'desc' },
        { text: 'Action' }
    ];
    return (React.createElement("div", { className: 'custom-panel customers-table -admin', "data-testid": 'admin-creditors-table' },
        React.createElement("div", { className: 'header -space-between' }, "Creditors"),
        React.createElement("table", { className: 'custom-table' },
            React.createElement(SortableTableHeaders, { ...{
                    forwardHeaderRef,
                    headerFields,
                    headerWidths,
                    isLoading,
                    sortBy,
                    sortDirection
                }, sortItems: sortCreditors }),
            React.createElement(AdminCreditorsTableBody, { ...{
                    apiToken,
                    borrowerCreditorsPath,
                    borrowerId,
                    creditorsArray,
                    hasErrors,
                    isLoading
                } })),
        totalItems > pageSize && (React.createElement(PaginationBar, { ...{
                currentPage,
                totalPages,
                totalItems,
                pageSize,
                handlePageClick
            }, resource: 'creditors' }))));
};
export default AdminCreditorsTable;
