import React, { useState } from 'react';
import classNames from 'classnames';
import CustomPassword from '../shared/CustomPassword';
import Criterion from './Criterion';
const PasswordValidation = ({ labelText, labelClassName = 'label', model, name, errors = {}, placeholder, isAutoFocused = false, ariaLabel, required = false, hasError = false }) => {
    const [isWithinLimit, setIsWithinLimit] = useState(false);
    const [hasUppercase, setHasUppercase] = useState(false);
    const [hasLowercase, setHasLowercase] = useState(false);
    const [hasDigit, setHasDigit] = useState(false);
    const [hasSpecialChar, setHasSpecialChar] = useState(false);
    const validatePassword = (password) => {
        const checkLength = /^([a-zA-Z0-9_]|[^a-zA-Z0-9_]){8,25}$/.test(password);
        const checkLowercase = /[a-z]+/.test(password);
        const checkUppercase = /[A-Z]+/.test(password);
        const checkDigit = /[0-9]+/.test(password);
        const checkSpecialChar = /\W/.test(password);
        setIsWithinLimit(checkLength);
        setHasLowercase(checkLowercase);
        setHasUppercase(checkUppercase);
        setHasDigit(checkDigit);
        setHasSpecialChar(checkSpecialChar);
    };
    const criteria = [
        {
            isChecked: isWithinLimit,
            text: 'Must be 8-25 characters long'
        },
        {
            isChecked: hasUppercase,
            text: 'Must have at least 1 uppercase letter'
        },
        {
            isChecked: hasLowercase,
            text: 'Must have at least 1 lowercase letter'
        },
        {
            isChecked: hasDigit,
            text: 'Must have at least 1 number'
        },
        {
            isChecked: hasSpecialChar,
            text: 'Must have at least 1 non-alphanumeric character'
        }
    ];
    const labelClass = classNames(labelClassName, {
        '-error': hasError,
        '-required': required === true
    });
    return (React.createElement("div", { className: 'password-validation', "data-testid": 'password-validation' },
        labelText && React.createElement("label", { className: labelClass }, labelText),
        React.createElement(CustomPassword, { ...{ model, hasError, validatePassword }, field: name, model: model, errors: errors, maxLength: 100, placeholder: placeholder, size: 100, isAutoFocused: isAutoFocused, ariaLabel: ariaLabel, labelClassName: labelClass }),
        React.createElement("div", { className: 'custom-list list -checkbox' }, criteria.map((criterion, i) => {
            return React.createElement(Criterion, { key: i, ...criterion });
        }))));
};
export default PasswordValidation;
