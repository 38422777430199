import React from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
const TradeLoanInterestTooltip = ({ tooltipId = 'interest-tooltip', baseRate, penalty, interest, withBaseRate, interestStatus = 'normal' }) => {
    const tooltipTheme = {
        backgroundColor: '#ffffff',
        textColor: '#091e42',
        border: true,
        borderColor: '#c1c7d0'
    };
    const displayPenalty = interestStatus === 'penalty';
    const noticeIconClass = classNames('custom-icon -notice', {
        '-red': interestStatus === 'penalty',
        '-blue': interestStatus === 'normal',
        '-yellow': interestStatus === 'transitioning'
    });
    const InterestTooltipText = () => {
        return (React.createElement("p", { className: 'custom-text' },
            "Interest (",
            React.createElement("span", { className: 'text -blue' },
                interest,
                "%"),
            ")"));
    };
    const BaseRateTooltipText = () => {
        return (React.createElement("p", { className: 'custom-text' },
            "Base Rate (",
            React.createElement("span", { className: 'text -blue' },
                baseRate,
                "%"),
            ")"));
    };
    const PenaltyTooltipText = () => {
        return (React.createElement("p", { className: 'custom-text' },
            ' ',
            "Default (",
            React.createElement("span", { className: 'text -red' },
                penalty,
                "%"),
            ")"));
    };
    const TooltipText = () => {
        return (React.createElement("div", { className: 'body' },
            React.createElement("div", { className: 'custom-text' },
                React.createElement(InterestTooltipText, null),
                withBaseRate && (React.createElement(React.Fragment, null,
                    ' ',
                    "+ ",
                    React.createElement(BaseRateTooltipText, null))),
                displayPenalty && (React.createElement(React.Fragment, null,
                    ' ',
                    "+ ",
                    React.createElement(PenaltyTooltipText, null))))));
    };
    return (React.createElement("div", { className: 'tooltip-wrapper', "data-testid": 'text-tooltip' },
        React.createElement("div", { className: 'text', "data-tip": true, "data-for": tooltipId },
            React.createElement("i", { "aria-label": 'info-icon', className: noticeIconClass })),
        React.createElement(ReactTooltip, { ...tooltipTheme, id: tooltipId, place: 'bottom', effect: 'solid' },
            React.createElement("div", { className: 'tooltip-content', "data-testid": 'tooltip' },
                React.createElement("div", { className: 'title' }, "Breakdown"),
                React.createElement(TooltipText, null)))));
};
export default TradeLoanInterestTooltip;
