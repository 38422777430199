import React from 'react';
import Flatpickr from 'react-flatpickr';
const DebtorHistoriesDatePicker = ({ currentDate, allowedDates }) => {
    const dateSettings = {
        altInput: true,
        altFormat: 'd/m/Y',
        dateFormat: 'Y-m-d',
        ...(allowedDates && { enable: allowedDates })
    };
    return (React.createElement(Flatpickr, { className: 'field form-control', placeholder: 'Select a date', value: currentDate, options: dateSettings, name: 'record_date' }));
};
export default DebtorHistoriesDatePicker;
