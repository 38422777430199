import React from 'react';
import DrawdownRTGSDashboard from './DrawdownRTGSDashboard';
import DrawdownThirdPartyDashboard from './DrawdownThirdPartyDashboard';
import DrawdownEquipmentDashboard from './DrawdownEquipmentDashboard';
import DrawdownPlatformEntryDashboard from './DrawdownPlatformEntryDashboard';
import DrawdownRegularDashboard from './DrawdownRegularDashboard';
import DrawdownOtherDashboard from './DrawdownOtherDashboard';
const DrawdownApprovedDashboard = ({ apiToken, path, abaPath }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawdownRTGSDashboard, { path: path, abaPath: abaPath, apiToken: apiToken, tableName: 'RTGS Drawdowns' }),
        React.createElement(DrawdownThirdPartyDashboard, { path: path, apiToken: apiToken, tableName: 'Third-party Payments' }),
        React.createElement(DrawdownEquipmentDashboard, { path: path, abaPath: abaPath, apiToken: apiToken, tableName: 'Equipment Finance and Trade Payments' }),
        React.createElement(DrawdownPlatformEntryDashboard, { path: path, abaPath: abaPath, apiToken: apiToken, tableName: 'Platform Entry Payments' }),
        React.createElement(DrawdownRegularDashboard, { path: path, abaPath: abaPath, apiToken: apiToken, tableName: 'Regular Drawdowns' }),
        React.createElement(DrawdownOtherDashboard, { path: path, apiToken: apiToken, tableName: 'Other Drawdowns' })));
};
export default DrawdownApprovedDashboard;
