import React, { useState } from 'react';
import AmountField from './AmountField';
import ConfirmStepActions from './ConfirmStepActions';
import Dropdown from './Dropdown';
import Pane from './Pane';
import Receipt from './Receipt';
import { convertCents } from '../../utils/convertCents';
import { isEmptyString } from '../../utils/isEmptyString';
import { fetchFundingAvailable } from '../../api/company';
import { showNotifyToast } from '../../utils';
import LoadingSpinner from '../shared/LoadingSpinner';
import Bugsnag from '@bugsnag/js';
const AdminRequestDrawdown = ({ backLink, companies, formAttributes, apiToken }) => {
    const [companyId, setCompanyId] = useState(0);
    const [companyFeeRate, setCompanyFeeRate] = useState(0);
    const [minimumDrawdownFee, setMinimumDrawdownFee] = useState(0);
    const [companyreserveAmount, setCompanyreserveAmount] = useState(0);
    const [companyFundingAvailable, setCompanyFundingAvailable] = useState('$0.00');
    const [isFetchingFundingAvailable, setIsFetchingFundingAvailable] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [errorMessage, setErrorMessage] = useState('');
    const [amount, setAmount] = useState({
        floatValue: 0,
        value: 0,
        formattedValue: ''
    });
    const handleChangeAmount = (values) => {
        setAmount(values);
    };
    const companiesData = companies.map(company => ({
        value: company.id,
        label: company.name,
        feeRate: company.drawdownRate,
        drawdownFee: convertCents(company.minimumDrawdownFeeCents),
        reserveAmount: convertCents(company.reserveAmountCents)
    }));
    const handleSelectCompany = async (company) => {
        const { value, feeRate, drawdownFee, reserveAmount } = company;
        try {
            setIsFetchingFundingAvailable(true);
            const { fundingAvailable } = await fetchFundingAvailable(apiToken, value);
            setCompanyFundingAvailable(fundingAvailable);
        }
        catch (error) {
            if (error instanceof Error) {
                Bugsnag.notify(error);
                showNotifyToast({
                    text: `Unable to fetch funding available: ${error.message}.`,
                    type: 'error'
                });
            }
        }
        finally {
            setIsFetchingFundingAvailable(false);
        }
        setCompanyFeeRate(feeRate);
        setCompanyId(value);
        setMinimumDrawdownFee(drawdownFee);
        setCompanyreserveAmount(reserveAmount);
    };
    const handleSubmit = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };
    const handleNextStep = () => {
        if (validateDrawdownAmount()) {
            setCurrentStep(currentStep + 1);
        }
    };
    const handlePrevStep = () => setCurrentStep(currentStep - 1);
    const validateDrawdownAmount = () => {
        if (currentStep !== 1) {
            return true;
        }
        if (isEmptyString(amount.formattedValue)) {
            setErrorMessage('Field must not be blank.');
            return false;
        }
        else {
            setErrorMessage('');
            return true;
        }
    };
    return (React.createElement("form", { onKeyDown: handleSubmit, action: formAttributes.url, acceptCharset: 'UTF-8', method: 'post', "data-testid": 'admin-request-drawdown' },
        React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: formAttributes.csrfToken }),
        React.createElement("div", { className: 'drawdown-container' },
            React.createElement(Pane, { title: 'Create', step: 1, currentStep: currentStep },
                React.createElement("h4", { className: 'text -medium' }, "Borrow from"),
                React.createElement(Dropdown, { options: companiesData, handleSelectCompany: handleSelectCompany, hasSelectedCompany: true, name: 'drawdown[company_id]' }),
                React.createElement("h4", { className: 'text -medium' }, "Request an amount of"),
                React.createElement(AmountField, { handleChangeAmount: handleChangeAmount, errorMessage: errorMessage, isDisabled: !companyId }),
                React.createElement("div", { className: 'text -margin-top -with-loading-spinner' },
                    'Funding Available: ',
                    isFetchingFundingAvailable ? (React.createElement(LoadingSpinner, { className: '-very-small -margin-left' })) : (companyFundingAvailable)),
                React.createElement("div", { className: 'actions action-buttons' },
                    React.createElement("input", { type: 'hidden', name: 'drawdown[amount]', value: amount.floatValue }),
                    React.createElement("a", { href: backLink, className: 'button -tertiary' }, "Cancel"),
                    React.createElement("a", { className: 'button -primary', onClick: handleNextStep }, "Request"))),
            React.createElement(Pane, { title: 'Confirm', step: 2, currentStep: currentStep },
                React.createElement(Receipt, { requestedAmount: amount.floatValue, drawdownFeeRate: companyFeeRate, minimumDrawdownFee: minimumDrawdownFee, reserveAmount: companyreserveAmount }),
                React.createElement(ConfirmStepActions, { ...{ backLink, handlePrevStep } })))));
};
export default AdminRequestDrawdown;
