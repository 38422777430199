import React from 'react';
import classNames from 'classnames';
import CustomFlatpickr from '../shared/CustomFlatpickr';
const BrokerActivityFilter = ({ isOpened, activeFilters, dateFilter, flatpickrSettings, forwardDateClearRef, forwardDatePickerRef, applyFilters, clearFilters, handleFilterToggle }) => {
    const panelClass = classNames('activity-filter filter -broker panel', {
        '-active': isOpened
    });
    return (React.createElement("div", { className: panelClass, "data-testid": 'broker-feed-filters' },
        React.createElement("div", { className: 'title -main' }, "Filter by"),
        React.createElement("div", { className: 'title' }, "Date"),
        React.createElement("div", { className: 'body' },
            React.createElement(CustomFlatpickr, { ...{
                    flatpickrSettings,
                    forwardDateClearRef,
                    forwardDatePickerRef
                }, selectedDate: dateFilter, placeholderText: 'Select a date', clearButtonText: 'Clear Date' })),
        React.createElement("div", { className: 'title' }, "Categories"),
        React.createElement("div", { className: 'body' },
            React.createElement("div", { className: 'category-list categories' },
                React.createElement("label", { className: 'category-option option', "data-testid": 'drawdown-option' },
                    React.createElement("input", { type: 'checkbox', name: 'drawdowns', className: 'input', onChange: handleFilterToggle, checked: activeFilters.drawdowns }),
                    React.createElement("div", { className: 'body' },
                        React.createElement("div", { className: 'icon -drawdown' }),
                        React.createElement("div", { className: 'text' }, "Drawdown"),
                        React.createElement("div", { className: 'checkbox' }))),
                React.createElement("label", { className: 'category-option option', "data-testid": 'client-invitation-option' },
                    React.createElement("input", { type: 'checkbox', name: 'client_invitations', className: 'input', onChange: handleFilterToggle, checked: activeFilters.client_invitations }),
                    React.createElement("div", { className: 'body' },
                        React.createElement("div", { className: 'icon -client-invitation' }),
                        React.createElement("div", { className: 'text' }, "Client Invitation"),
                        React.createElement("div", { className: 'checkbox' }))))),
        React.createElement("div", { className: 'actions' },
            React.createElement("button", { className: 'custom-button button -clear-filters', onClick: () => clearFilters(), "data-testid": 'clear-all' }, "Clear All"),
            React.createElement("button", { className: 'custom-button button -apply-filters', onClick: () => applyFilters(), "data-testid": 'apply-filters' }, "Apply Filter"))));
};
export default BrokerActivityFilter;
