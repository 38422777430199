import React from 'react';
import NumberFormat from 'react-number-format';
const PenaltyConfirmation = ({ currentStep, currentDate, formattedTradeLoanId, accumulatedPenaltyInterest, closeModal, stepForward, stepBack, handleAccumulatedPenaltyValueChange, finishConfirmation }) => {
    const hasAccumulatedPenaltyInterest = accumulatedPenaltyInterest.floatValue &&
        accumulatedPenaltyInterest.floatValue > 0;
    if (currentStep === 1) {
        return (React.createElement("div", { className: 'custom-panel', "data-testid": 'penalty-confirmation-step-1' },
            React.createElement("div", { className: 'header' }, "Enter Accumulated Default Interest"),
            React.createElement("div", { className: 'body' },
                React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { className: 'label -uppercase' }, "Accumulated Default Interest ($)"),
                    React.createElement(NumberFormat, { className: 'input form-control', value: accumulatedPenaltyInterest.value, onValueChange: handleAccumulatedPenaltyValueChange, thousandSeparator: true, allowNegative: false, decimalScale: 2, fixedDecimalScale: true })),
                React.createElement("div", { className: 'action-buttons buttons' },
                    React.createElement("button", { className: 'button -cancel -with-border', type: 'button', onClick: () => closeModal(), "data-testid": 'cancel-step-1' }, "Cancel"),
                    React.createElement("button", { className: 'button -primary', type: 'button', onClick: () => stepForward(), "data-testid": 'next-step-1' }, hasAccumulatedPenaltyInterest ? 'Next' : 'Skip')))));
    }
    if (currentStep === 2) {
        return (React.createElement("div", { className: 'custom-panel', "data-testid": 'penalty-confirmation-step-2' },
            React.createElement("div", { className: 'header' }, "Confirmation"),
            React.createElement("div", { className: 'body' },
                React.createElement("div", { className: 'text-group -spaced -inline-block' },
                    "You're about to turn on default rates for",
                    ' ',
                    React.createElement("b", null,
                        "#",
                        formattedTradeLoanId),
                    " Loan and add accumulated interest of",
                    ' ',
                    React.createElement("b", null,
                        "$",
                        accumulatedPenaltyInterest.formattedValue),
                    " as of",
                    ' ',
                    currentDate,
                    "."),
                React.createElement("div", { className: 'action-buttons buttons' },
                    React.createElement("button", { className: 'button -cancel -with-border', type: 'button', onClick: () => stepBack(), "data-testid": 'cancel-step-2' }, "Go Back"),
                    React.createElement("button", { className: 'button -primary', type: 'button', onClick: () => finishConfirmation(), "data-testid": 'next-step-2' }, "Yes, I am sure")))));
    }
    return null;
};
export default PenaltyConfirmation;
