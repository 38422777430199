import React from 'react';
const XmasLogo = ({ logoClass }) => {
    return (React.createElement("svg", { viewBox: '0 0 312 109', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', className: logoClass, "data-testid": 'xmas-skippr-logo' },
        React.createElement("path", { className: 'letter', d: 'M59.255 56.7037C55.4464 53.4734 50.6661 50.8077 44.9142 48.7066C41.9161 47.6582 39.6272 46.7092 38.0477 45.8596C36.4681 45.01 35.6784 44.061 35.6784 43.0126C35.6784 41.6399 36.3465 40.4484 37.6829 39.4383C39.0193 38.4281 40.7007 37.9231 42.7272 37.9231C45.2508 37.9147 47.7643 38.2408 50.2016 38.8928C52.5907 39.5393 55.3248 40.5489 58.4039 41.9216L63.1442 31.3805C60.3893 29.524 57.027 28.0296 53.0573 26.8971C49.0876 25.7646 45.7253 25.1995 42.9704 25.2017C35.9215 25.2017 30.3312 26.7966 26.1994 29.9865C22.0676 33.1764 20.0011 37.5589 20 43.1338C20 47.5763 21.8622 51.2516 25.5865 54.1598C29.3108 57.0679 33.9695 59.4508 39.5626 61.3084C46.457 63.4084 49.9042 66.0332 49.9042 69.1827C49.9042 70.8 48.8915 72.0521 46.8662 72.9388C44.7561 73.846 42.4782 74.3003 40.1804 74.2722C35.8876 74.2722 30.7431 72.9394 24.7469 70.2737L20 80.936C22.5106 82.8755 25.7919 84.37 29.8437 85.4194C33.8956 86.4689 37.42 86.9936 40.417 86.9936C48.3564 86.9936 54.4359 85.3987 58.6553 82.2088C62.8748 79.0189 64.9812 74.4754 64.9746 68.5782C64.9703 63.8933 63.0637 59.9351 59.255 56.7037Z' }),
        React.createElement("path", { className: 'letter', d: 'M119.305 36.228L110.432 25.0808L85.1534 48.222V0L69.475 1.33177V85.541H85.1534V66.2754L91.4744 60.7026L106.3 85.541H124.408L102.776 50.6448L119.305 36.228Z' }),
        React.createElement("path", { className: 'letter', d: 'M145.701 26.7764H129.903V85.5411H145.701V26.7764Z' }),
        React.createElement("path", { className: 'letter', d: 'M179.248 25.2019C173.662 25.2019 168.476 27.0584 163.691 30.7714L160.532 26.7728H152.51V109L168.189 106.25V84.2075C171.913 86.064 175.437 86.9923 178.762 86.9923C184.999 86.9923 190.387 85.7004 194.925 83.1166C199.401 80.5952 203.02 76.7978 205.316 72.2118C207.705 67.5269 208.9 62.1146 208.901 55.9751C208.901 46.5244 206.248 39.0328 200.942 33.5005C195.636 27.9681 188.405 25.2019 179.248 25.2019ZM188.849 68.6358C186.337 71.912 182.934 73.5501 178.64 73.5501C174.994 73.5501 171.51 72.4193 168.189 70.1576V42.1643C171.753 39.8273 175.237 38.6588 178.64 38.6588C183.015 38.6588 186.438 40.2543 188.91 43.4453C191.381 46.6363 192.616 50.8162 192.616 55.9849C192.616 61.1492 191.361 65.3662 188.849 68.6358Z' }),
        React.createElement("path", { className: 'letter', d: 'M240.476 25.2019C234.89 25.2019 229.704 27.0584 224.92 30.7714L221.76 26.7728H213.738V109L229.417 106.25V84.2075C233.141 86.064 236.665 86.9923 239.99 86.9923C246.234 86.9923 251.622 85.7004 256.155 83.1166C260.631 80.5952 264.249 76.7978 266.546 72.2118C268.933 67.5269 270.129 62.1146 270.131 55.9751C270.131 46.5244 267.477 39.0328 262.17 33.5005C256.863 27.9681 249.632 25.2019 240.476 25.2019ZM250.077 68.6358C247.565 71.9055 244.162 73.5436 239.868 73.5501C236.222 73.5501 232.738 72.4193 229.417 70.1576V42.1643C232.981 39.8273 236.465 38.6588 239.868 38.6588C244.243 38.6588 247.666 40.2543 250.138 43.4453C252.609 46.6363 253.844 50.8162 253.844 55.9849C253.844 61.1492 252.589 65.3662 250.077 68.6358Z' }),
        React.createElement("path", { className: 'letter', d: 'M308.84 26.7764C305.922 26.7764 302.559 27.786 298.752 29.8052C294.944 31.8244 291.622 34.0866 288.787 36.5918L283.561 26.7764H275.297V85.5411H291.097V49.6768C294.421 46.7412 298.119 44.2561 302.096 42.2858C306.025 40.3463 309.326 39.3771 312 39.3782L308.84 26.7764Z' }),
        React.createElement("path", { className: 'letter', d: 'M125.979 22.4582H145.861C145.861 22.4582 150.819 12.9229 145.861 0C145.861 0 141.276 15.1376 125.979 22.4582Z' }),
        React.createElement("g", { className: 'hat' },
            React.createElement("path", { className: 'body', d: 'M10 8.49996C16 7.3 27.6667 17.3333 33 22C33 29.2 22.3333 36 17 38.5C13 32 14.5 18 10 18C6.4 18 4.66667 23.1667 3 26C4.5 20.1667 4 9.69992 10 8.49996Z' }),
            React.createElement("path", { className: 'bottom', d: 'M19.3041 44.1335C27.0761 40.3825 34.7318 30.7382 38.0683 25.6682C38.3255 25.2774 38.2649 24.7649 37.934 24.434L34.4811 20.9811C33.9984 20.4984 33.1838 20.6287 32.8431 21.2202C28.7525 28.3217 20.3466 34.9748 15.8093 37.977C15.3597 38.2745 15.2155 38.8694 15.4871 39.335L18.0547 43.7366C18.309 44.1726 18.8495 44.3529 19.3041 44.1335Z' }),
            React.createElement("circle", { className: 'top', cx: '4', cy: '24', r: '4' }))));
};
export default XmasLogo;
