import classNames from 'classnames';
import React, { Fragment } from 'react';
import { findOptionByValue, hasValue } from '../../utils';
const CustomOptionSelector = ({ options, selectedOption, customOptionValue, fieldLabel, fieldName, modelName, children, isRequired = false, handleOptionChange }) => {
    const mainLabelClass = classNames('label -uppercase', {
        '-required': isRequired
    });
    const customLabelClass = classNames('label -uppercase', {
        '-required': isRequired
    });
    const renderCustomField = children &&
        hasValue(selectedOption, customOptionValue) &&
        findOptionByValue(options, customOptionValue);
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: mainLabelClass }, fieldLabel),
            React.createElement("div", { className: 'custom-toggle' }, options.map((option, key) => (React.createElement(Fragment, { key: key },
                React.createElement("input", { type: 'radio', className: 'button', name: `${modelName}[${fieldName}]`, id: `${fieldName}_${option.value}`, value: option.value, checked: hasValue(selectedOption, option.value), onChange: handleOptionChange }),
                React.createElement("label", { className: 'label', htmlFor: `${fieldName}_${option.value}` }, option.label)))))),
        renderCustomField && (React.createElement("div", { className: 'form-group' },
            React.createElement("label", { htmlFor: `custom_${fieldName}`, className: customLabelClass }, `Custom ${fieldLabel}`),
            children))));
};
export default CustomOptionSelector;
