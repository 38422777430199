import React from 'react';
import Select from 'react-select';
import CustomPassword from '../shared/CustomPassword';
import ErrorList from '../shared/ErrorList';
import Input from './Input';
import PasswordValidation from './PasswordValidation';
const NewUserContainer = ({ errors, borrowerRoleOptions = [], backUrl, editPhoneNumber, editorUserRole }) => {
    const backLink = backUrl || '/';
    return (React.createElement("div", { className: 'profile-layout', "data-testid": 'edit-profile-container' },
        React.createElement("div", { className: 'form-group -section' },
            React.createElement("div", { className: 'form-row -col-2' },
                React.createElement(Input, { maxLength: 100, labelText: 'First Name', name: 'first_name', size: 100, required: true, errors: errors }),
                React.createElement(Input, { maxLength: 100, labelText: 'Last Name', name: 'last_name', size: 100, required: true, errors: errors })),
            editPhoneNumber && (React.createElement(Input, { maxLength: 50, labelText: 'Phone Number', name: 'phone_number', size: 100, required: true, errors: errors })),
            React.createElement(Input, { labelText: 'Email', inputType: 'email', name: 'email', required: true, errors: errors }),
            (editorUserRole === 'admin' || editorUserRole === 'super_admin') && (React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label -required' }, "Borrower Role"),
                React.createElement(Select, { options: borrowerRoleOptions, name: 'borrower_role', defaultValue: borrowerRoleOptions[0], required: true }),
                React.createElement(ErrorList, { errors: errors, field: 'borrower_role' })))),
        React.createElement("div", { className: 'form-group -section' },
            React.createElement(PasswordValidation, { labelText: 'Password', model: 'user', name: 'password', required: true, errors: errors }),
            React.createElement(CustomPassword, { field: 'password_confirmation', model: 'user', ariaLabel: '', labelText: 'Password Confirmation', placeholder: '', maxLength: 100, size: 100, required: true, errors: errors })),
        React.createElement("div", { className: 'actions' },
            React.createElement("a", { href: backLink, className: 'button -mute' }, "Cancel"),
            React.createElement("input", { type: 'submit', className: 'custom-button button -primary', name: 'commit', value: 'Create', "data-disable-with": 'Please wait...' }))));
};
export default NewUserContainer;
