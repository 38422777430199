import React from 'react';
import Modal from '../shared/Modal';
const ABAModal = ({ isVisible, path, drawdown, handleRefresh }) => {
    return (React.createElement(Modal, { isOpen: isVisible, onClose: handleRefresh },
        React.createElement("div", { className: 'custom-panel -modal -customer-statements-modal', role: 'dialog', "aria-modal": 'true', "data-testid": 'aba-modal' },
            React.createElement("div", { className: 'header' }, "Confirmation"),
            React.createElement("div", { className: 'body' },
                React.createElement("div", { className: 'content -no-border-bottom' },
                    React.createElement("div", { className: 'section' }, "Downloading the drawdown's ABA file or including it in bulk ABA download will be considered as a processed transaction, and as a result will be removed from the table."),
                    React.createElement("div", { className: 'label -drawdown' }, "Drawdowns"),
                    React.createElement("div", { className: 'drawdown' }, `#${drawdown.id} - ${drawdown.companyName}`))),
            React.createElement("div", { className: 'action-buttons -border -padding' },
                React.createElement("button", { className: 'button -neutral', onClick: handleRefresh }, "Cancel"),
                React.createElement("a", { className: 'button -primary', "data-method": 'put', href: path }, "Confirm")))));
};
export default ABAModal;
