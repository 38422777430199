import React, { useState } from 'react';
import classNames from 'classnames';
import { buildUrlQuery } from '../../utils/url';
const Panel = ({ baseUrl, isOpened, isSelected, isApproved, isActive }) => {
    const [selectedFilter, setSelectedFilter] = useState(Boolean(isSelected));
    const [approvedFilter, setApprovedFilter] = useState(Boolean(isApproved));
    const [activeFilter, setActiveFilter] = useState(Boolean(isActive));
    const filterUrl = buildUrlQuery({
        selected: selectedFilter === true ? selectedFilter.toString() : '',
        approved: approvedFilter === true ? approvedFilter.toString() : '',
        active: activeFilter === true ? activeFilter.toString() : ''
    }, baseUrl);
    const panelClass = classNames('panel', {
        '-active': isOpened
    });
    const handleSelectedFilterChange = (event) => {
        const isChecked = event.target.checked;
        setSelectedFilter(isChecked);
    };
    const handleApprovedFilterChange = (event) => {
        const isChecked = event.target.checked;
        setApprovedFilter(isChecked);
    };
    const handleActiveFilterChange = (event) => {
        const isChecked = event.target.checked;
        setActiveFilter(isChecked);
    };
    return (React.createElement("div", { className: panelClass, "data-testid": 'panel' },
        React.createElement("div", { className: 'title' }, "Filter by"),
        React.createElement("div", { className: 'category-list categories' },
            React.createElement("label", { className: 'category-option option' },
                React.createElement("input", { type: 'checkbox', name: 'selected', className: 'input', checked: selectedFilter, onChange: handleSelectedFilterChange, "data-testid": 'selected-filter-checkbox' }),
                React.createElement("div", { className: 'body' },
                    React.createElement("div", { className: 'text -filter' }, "Selected"),
                    React.createElement("div", { className: 'checkbox' }))),
            React.createElement("label", { className: 'category-option option' },
                React.createElement("input", { type: 'checkbox', name: 'approved', className: 'input', checked: approvedFilter, onChange: handleApprovedFilterChange, "data-testid": 'approved-filter-checkbox' }),
                React.createElement("div", { className: 'body' },
                    React.createElement("div", { className: 'text -filter' }, "Approved"),
                    React.createElement("div", { className: 'checkbox' }))),
            React.createElement("label", { className: 'category-option option' },
                React.createElement("input", { type: 'checkbox', name: 'active', className: 'input', checked: activeFilter, onChange: handleActiveFilterChange, "data-testid": 'active-filter-checkbox' }),
                React.createElement("div", { className: 'body' },
                    React.createElement("div", { className: 'text -filter' }, "Active"),
                    React.createElement("div", { className: 'checkbox' })))),
        React.createElement("div", { className: 'action-buttons actions -space-between' },
            React.createElement("a", { className: 'custom-button button -clear-filters', href: baseUrl }, "Clear All"),
            React.createElement("a", { className: 'button -primary -loose', href: filterUrl }, "Apply Filter"))));
};
export default Panel;
