export const isCustomerEvent = (event) => event.attributes.eventableType === 'Customer';
export const isDrawdownEvent = (event) => event.attributes.eventableType === 'Drawdown';
export const isInvoiceEvent = (event) => event.attributes.eventableType === 'Invoice';
export const isPostEvent = (event) => event.attributes.eventableType === 'Post';
export const isRepaymentEvent = (event) => event.attributes.eventableType === 'Repayment';
export const isCreditNoteAllocationEvent = (event) => event.attributes.eventableType ===
    'CreditNoteAllocation';
export const isXeroPaymentEvent = (event) => event.attributes.eventableType === 'XeroPayment';
export const isReferralEvent = (event) => event.attributes.eventableType === 'Referral';
export const isSupplierEvent = (event) => event.attributes.eventableType === 'Supplier';
