import React, { useState, useReducer } from 'react';
import { updateSupportingDocuments as defaultUpdateSupportingDocuments, removeTradeLoanFile as defaultRemoveTradeLoanFile } from '../../api/trade_loan';
import { showNotifyToast as defaultShowNotifyToast, getBracketValues, removeDuplicateFiles, isEmptyObject, snakeToCamelCase } from '../../utils';
import { compileFileNames, compileExistingFileNames } from '../../utils/tradeLoanFiles';
import { isAPIErrors, isAPIValidationErrors } from '../../../types';
import { validateFileSize, validateTradeLoanDuplicateFile } from '../../validations/file';
import TradeLoanDetailsReducer from '../../reducers/trade-loans/TradeLoanDetailsReducer';
import SpinnerButton from '../../components/shared/SpinnerButton';
import SupportingDocumentsEdit from '../../components/trade-loan/SupportingDocumentsEdit';
const DocumentsEditDashboard = ({ apiToken, tradeLoanId, urlPath = 'borrower', initialTradeLoanDetails, updateSupportingDocuments = defaultUpdateSupportingDocuments, removeTradeLoanFile = defaultRemoveTradeLoanFile, showNotifyToast = defaultShowNotifyToast }) => {
    const initialState = {
        tradeLoan: initialTradeLoanDetails,
        amountValues: {},
        tradeLoanErrors: {},
        documentErrors: {},
        selectedPaymentCurrencyOption: null
    };
    const [tradeLoanState, tradeLoanDispatch] = useReducer(TradeLoanDetailsReducer, initialState);
    const { tradeLoan, tradeLoanErrors, documentErrors } = tradeLoanState;
    const [isSaving, setIsSaving] = useState(false);
    const handleFileChange = (acceptedFiles, targetName) => {
        const [fieldName] = getBracketValues(targetName);
        const newFiles = [...acceptedFiles];
        const currentFiles = tradeLoan[snakeToCamelCase(fieldName)] || [];
        const allFiles = removeDuplicateFiles([
            ...currentFiles,
            ...newFiles
        ]);
        tradeLoanDispatch({
            type: 'UPDATE_FILES',
            fieldName: snakeToCamelCase(fieldName),
            files: allFiles
        });
        validateDocumentsFrontend(allFiles, snakeToCamelCase(fieldName));
    };
    const handleFileReplace = (event) => {
        const targetName = event.target['name'];
        const [fieldName, index] = getBracketValues(targetName);
        const files = event.target.files;
        const newFiles = [...files];
        const currentFiles = tradeLoan[snakeToCamelCase(fieldName)] || [];
        const updatedFiles = removeDuplicateFiles([
            ...currentFiles.slice(0, parseInt(index)),
            ...newFiles,
            ...currentFiles.slice(parseInt(index) + 1, currentFiles.length)
        ]);
        tradeLoanDispatch({
            type: 'UPDATE_FILES',
            fieldName: snakeToCamelCase(fieldName),
            files: updatedFiles
        });
        validateDocumentsFrontend(updatedFiles, snakeToCamelCase(fieldName));
    };
    const validateDocumentsFrontend = (files, fieldName) => {
        tradeLoanDispatch({
            type: 'UPDATE_DOCUMENT_ERRORS',
            fieldName: snakeToCamelCase(fieldName),
            errors: totalErrors(files, fieldName)
        });
    };
    const generateFileErrors = (file, fieldName) => {
        const fileSizeValidations = validateFileSize(file, 20000000)
            ? []
            : [`The file needs to be less than 20MB.`];
        const fileNameList = compileFileNames([snakeToCamelCase(fieldName)], tradeLoan);
        const selectedFileValidations = validateTradeLoanDuplicateFile(file, fileNameList)
            ? []
            : [`The file has already been selected in another field.`];
        const existingFileNameList = compileExistingFileNames(tradeLoan);
        const existingFileValidations = validateTradeLoanDuplicateFile(file, existingFileNameList)
            ? []
            : [`The file already exists.`];
        const validations = [
            ...fileSizeValidations,
            ...selectedFileValidations,
            ...existingFileValidations
        ];
        return validations;
    };
    const totalErrors = (files, fieldName) => {
        return files.reduce((acc, file) => {
            if (file) {
                const errors = generateFileErrors(file, fieldName);
                if (errors.length > 0) {
                    return { ...acc, [file.name]: errors };
                }
                else {
                    return acc;
                }
            }
            else {
                return acc;
            }
        }, {});
    };
    const handleRemoveFile = (event) => {
        if (event.target) {
            const targetName = event.target['name'];
            const [fieldName, fileIndex] = getBracketValues(targetName);
            const fieldFiles = tradeLoan[snakeToCamelCase(fieldName)];
            const updatedFiles = [
                ...fieldFiles.slice(0, parseInt(fileIndex)),
                ...fieldFiles.slice(parseInt(fileIndex) + 1, fieldFiles.length)
            ];
            tradeLoanDispatch({
                type: 'UPDATE_FILES',
                fieldName: snakeToCamelCase(fieldName),
                files: updatedFiles
            });
            validateDocumentsFrontend(updatedFiles, snakeToCamelCase(fieldName));
        }
    };
    const saveTradeLoan = async () => {
        setIsSaving(true);
        const body = {
            tradeLoanDetails: tradeLoan
        };
        try {
            const response = await updateSupportingDocuments(apiToken, tradeLoanId, body, urlPath);
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            if (isAPIValidationErrors(response)) {
                tradeLoanDispatch({
                    type: 'UPDATE_TRADE_LOAN_ERRORS',
                    errors: response.errors
                });
                showNotifyToast({
                    text: 'Please check if all fields are filled out correctly.',
                    type: 'error'
                });
            }
            else {
                // clear previous errors
                tradeLoanDispatch({
                    type: 'UPDATE_TRADE_LOAN_ERRORS',
                    errors: {}
                });
                tradeLoanDispatch({
                    type: 'REFRESH_TRADE_LOAN',
                    tradeLoanDetails: response.data.attributes
                });
                showNotifyToast({
                    text: 'Successfully updated supporting documents.',
                    type: 'success'
                });
            }
        }
        catch {
            showNotifyToast({
                text: 'Failed to update supporting documents. Please try again.',
                type: 'error'
            });
        }
        setIsSaving(false);
    };
    const handleSave = () => {
        if (isEmptyObject(documentErrors)) {
            saveTradeLoan();
        }
        else {
            showNotifyToast({
                text: 'Please check if selected files are valid.',
                type: 'error'
            });
        }
    };
    const handleDeleteFile = (event) => {
        const targetName = event.target['name'];
        const [fieldName, fileIndex] = getBracketValues(targetName);
        deleteFile(snakeToCamelCase(fieldName), parseInt(fileIndex));
    };
    const deleteFile = async (fieldName, fileIndex) => {
        const body = {
            fieldName,
            index: fileIndex
        };
        if (window.confirm('Are you sure you want to delete this existing file?')) {
            try {
                const response = await removeTradeLoanFile(apiToken, tradeLoanId, body, urlPath);
                if (isAPIErrors(response) || isAPIValidationErrors(response)) {
                    throw response.errors;
                }
                const existingFieldName = snakeToCamelCase(`existing_${fieldName}`);
                const fieldFiles = tradeLoan[existingFieldName];
                const updatedFiles = [
                    ...fieldFiles.slice(0, fileIndex),
                    ...fieldFiles.slice(fileIndex + 1, fieldFiles.length)
                ];
                tradeLoanDispatch({
                    type: 'UPDATE_FILES',
                    fieldName: existingFieldName,
                    files: updatedFiles
                });
                showNotifyToast({
                    text: 'Successfully deleted file.',
                    type: 'success'
                });
            }
            catch {
                showNotifyToast({
                    text: 'Failed to delete file. Please try again.',
                    type: 'error'
                });
            }
        }
    };
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'documents-edit-dashboard' },
        React.createElement("div", { className: 'header' }, "Documents"),
        React.createElement("div", { className: 'body' },
            React.createElement(SupportingDocumentsEdit, { currentTradeLoanDetails: tradeLoan, documentErrors: documentErrors, tradeLoanErrors: tradeLoanErrors, handleRemoveFile: handleRemoveFile, handleFileChange: handleFileChange, handleFileReplace: handleFileReplace, handleDeleteFile: handleDeleteFile }),
            React.createElement("div", { className: 'actions' },
                React.createElement(SpinnerButton, { text: 'Save', className: 'custom-button button -trade-loan-save', handleClick: handleSave, isLoading: isSaving, testId: 'save-trade-loan' })))));
};
export default DocumentsEditDashboard;
