import React from 'react';
import CompanyName from '../CompanyName';
const PastIssueDateInvoice = ({ event }) => {
    const { attributes } = event;
    const { createdAtFormatted, companyLink, companyName, customerLink, invoiceLink, eventable: { attributes: { number: invoiceNumber, amountFormatted, customerName } } } = attributes;
    return (React.createElement("div", { className: 'event-box event', "data-testid": 'past-issue-date-invoice-activity' },
        React.createElement("div", { className: 'icon' },
            React.createElement("div", { className: 'activity-icon -past-issue-date-invoice' })),
        React.createElement("div", { className: 'description' },
            React.createElement("div", { className: 'title' },
                "Earlypay marked invoice",
                React.createElement("span", { className: 'bold' },
                    React.createElement("a", { href: invoiceLink }, ` ${invoiceNumber} `)),
                "of",
                React.createElement("span", { className: 'amount -green' },
                    " ",
                    amountFormatted,
                    " "),
                "for",
                React.createElement("a", { href: customerLink }, ` ${customerName} `),
                "as ineligible due to being 90 days past issue date for",
                ' ',
                React.createElement(CompanyName, { name: companyName, companyLink: companyLink })),
            React.createElement("div", { className: 'date' },
                " ",
                createdAtFormatted,
                " "))));
};
export default PastIssueDateInvoice;
