import React, { useState } from 'react';
import classNames from 'classnames';
import { buildUrlQuery } from '../../utils/url';
import Select from 'react-select';
const Panel = ({ isOpened, debtorsOptions, selectedDebtorsOption, borrowerId, dateOptions, selectedDateOption }) => {
    const [debtorsFilter, setDebtorsFilter] = useState((selectedDebtorsOption && selectedDebtorsOption.value) || 'all');
    const [dateFilter, setDateFilter] = useState((selectedDateOption && selectedDateOption.value) || '');
    const baseUrl = `/admin/borrowers/${borrowerId}/aged_receivables_reports`;
    const filterUrl = buildUrlQuery({
        filter: debtorsFilter || '',
        report_date: dateFilter || ''
    }, baseUrl);
    const panelClass = classNames('panel', {
        '-active': isOpened
    });
    return (React.createElement("div", { className: panelClass, "data-testid": 'panel' },
        React.createElement("div", { className: 'dropdowns' },
            React.createElement("div", { className: 'form-group -collapse option' },
                React.createElement("div", { className: 'label -uppercase  -small' }, "As Of"),
                React.createElement("div", { className: 'dropdown-select -long -full-width -no-max' },
                    React.createElement(Select, { options: dateOptions, defaultValue: selectedDateOption, className: 'select', onChange: selectedDateOption => setDateFilter(selectedDateOption.value) }))),
            React.createElement("div", { className: 'form-group -collapse option' },
                React.createElement("div", { className: 'label -uppercase -small' }, "Filter By"),
                React.createElement("div", { className: 'dropdown-select -long -full-width -no-max' },
                    React.createElement(Select, { options: debtorsOptions, defaultValue: selectedDebtorsOption, className: 'select', onChange: selectedDebtorsOption => setDebtorsFilter(selectedDebtorsOption.value) }))),
            React.createElement("div", { className: 'action-buttons flex-grid ' },
                React.createElement("a", { className: 'button -primary -loose -loose-panel', href: filterUrl }, "Apply Filter")))));
};
export default Panel;
