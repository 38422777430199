import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Twemoji } from 'react-emoji-render';
import { editComment } from '../../api/comment';
import useOutsideClick from '../../hooks/useOutsideClick';
import { compileVariantClasses, formatDisplayDateTime, showNotifyToast } from '../../utils';
import { isCommentErrors, isCommentValidationErrors } from '../../../types';
import ToggleMenuWrapper from '../toggle-menu/ToggleMenuWrapper';
import EditForm from './EditForm';
const CommentItem = ({ apiToken, commentableId, commentableType, comment, scope, currentUserId, toggleMenuEnabled = false, variantClasses, removeComment }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [currentComment, setCurrentComment] = useState(comment);
    const [commentBodyInput, setCommentBodyInput] = useState(comment.body);
    const [validationErrors, setValidationErrors] = useState({});
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [emojiPickerDirection, setEmojiPickerDirection] = useState('');
    const [toggleMenuActive, setToggleMenuActive] = useState(false);
    const emojiPickerRef = useRef(null);
    const editTextFieldRef = useRef(null);
    const toggleMenuRef = useRef(null);
    const focusTextField = useCallback(() => {
        if (isEditable && editTextFieldRef && editTextFieldRef.current) {
            editTextFieldRef.current.focus();
            editTextFieldRef.current.select();
        }
    }, [isEditable, editTextFieldRef]);
    useEffect(focusTextField, [isEditable]);
    useOutsideClick(emojiPickerRef, () => {
        if (showEmojiPicker) {
            setShowEmojiPicker(false);
        }
    });
    useOutsideClick(toggleMenuRef, () => {
        if (toggleMenuActive) {
            setToggleMenuActive(false);
        }
    });
    const { id, attachments, body, user, createdAt, isAdminAuthor, isPrivate } = currentComment;
    const timeZone = 'Australia/Sydney';
    const editSuccessText = 'Your comment has been updated successfully.';
    const editErrorText = 'Sorry, we could not update your comment at this time.';
    const className = variantClasses
        ? compileVariantClasses('comment-item comment', variantClasses)
        : 'comment-item comment';
    const fileName = (url) => {
        return url
            .split('/')
            .reverse()[0]
            .split('?')[0];
    };
    const showToggleMenu = toggleMenuEnabled && isAdminAuthor;
    const showDeleteOption = currentUserId ? currentUserId === user.id : false;
    const handleCommentBodyChange = (event) => {
        event.preventDefault();
        setCommentBodyInput(event.currentTarget.value);
    };
    const handleMenuToggle = () => {
        toggleMenuActive ? setToggleMenuActive(false) : setToggleMenuActive(true);
    };
    // const handleEditClick = () => {
    //   setIsEditable(true)
    //   setToggleMenuActive(false)
    // }
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSaving(true);
        try {
            const commentUpdate = {
                body: commentBodyInput
            };
            const response = await editComment(apiToken, commentUpdate, id, commentableId, commentableType, scope);
            if (isCommentValidationErrors(response) || isCommentErrors(response)) {
                throw response.errors;
            }
            setCurrentComment(response.data.attributes);
            setIsEditable(false);
            setValidationErrors({});
            showNotifyToast({ text: editSuccessText, type: 'success' });
        }
        catch (err) {
            setValidationErrors(err);
            showNotifyToast({
                text: editErrorText,
                type: 'error'
            });
        }
        setIsSaving(false);
    };
    const selectEmoji = emoji => {
        setCommentBodyInput(currentBody => currentBody + emoji.native);
    };
    const toggleEmojiPicker = (event) => {
        const screenMiddleY = window.innerHeight / 2;
        const direction = event.screenY < screenMiddleY ? 'down' : 'up';
        setEmojiPickerDirection(direction);
        setShowEmojiPicker(current => !current);
    };
    const closeEditor = () => {
        setIsEditable(false);
        setCommentBodyInput(currentComment.body);
        setValidationErrors({});
    };
    return (React.createElement("div", { ...{ className }, "data-testid": 'comment-item' },
        React.createElement("img", { className: 'avatar', src: user.avatarUrl }),
        React.createElement("div", { className: 'body' },
            React.createElement("div", { className: 'comment-heading heading' },
                React.createElement("div", { className: 'details' },
                    React.createElement("div", { className: 'name' }, `${user.firstName} ${user.lastName}`),
                    React.createElement("div", { className: 'timestamp' }, formatDisplayDateTime(createdAt, timeZone)),
                    isPrivate && React.createElement("div", { className: 'label -private' }, "Private")),
                showToggleMenu && (React.createElement(ToggleMenuWrapper, { container: 'widget', isActive: toggleMenuActive, forwardToggleMenuRef: toggleMenuRef, handleToggle: handleMenuToggle }, showDeleteOption && (React.createElement("div", { onClick: () => window.confirm('Are you sure you want to delete this comment?') && removeComment(comment.id), "data-testid": 'delete-button', className: 'button -delete' }, "Delete"))))),
            React.createElement("div", { className: 'text' }, isEditable ? (React.createElement(EditForm, { ...{
                    emojiPickerDirection,
                    isSaving,
                    validationErrors,
                    closeEditor,
                    handleCommentBodyChange,
                    handleSubmit,
                    selectEmoji,
                    showEmojiPicker,
                    toggleEmojiPicker
                }, commentBody: commentBodyInput, forwardEditTextFieldRef: editTextFieldRef, forwardEmojiPickerRef: emojiPickerRef })) : (React.createElement(Twemoji, { text: body }))),
            React.createElement("div", { className: 'attachments' }, attachments.map((attachment, index) => (React.createElement("a", { className: 'attachment', href: attachment.file.url, target: '_blank', rel: 'noreferrer', key: index }, fileName(attachment.file.url))))))));
};
export default CommentItem;
