import React from 'react';
const Sidebar = ({ hasShadow, userType }) => {
    const headings = {
        admin: [
            { title: 'Ledger Summary', type: 'heading' },
            { title: 'Opening Ledger', type: 'subheading' },
            { title: 'New Invoices', type: 'subheading' },
            { title: 'Voided Invoices', type: 'subheading' },
            { title: 'Closing Ledger', type: 'subheading' },
            { title: 'Approved Receivables', type: 'subheading' },
            { title: 'Unapproved Receivables', type: 'subheading' },
            { title: 'Unselected Receivables', type: 'subheading' },
            { title: 'Concentration Adjustment', type: 'subheading' },
            { title: 'Availability Adjustment', type: 'subheading' },
            { title: 'New 90+ Aged', type: 'subheading' },
            { title: 'LTV', type: 'subheading' },
            { title: 'Credit Limit', type: 'subheading' },
            { title: 'Closing Suspense', type: 'subheading' },
            { title: 'Funding Available', type: 'subheading' },
            { title: 'Start of Day', type: 'heading' },
            { title: 'Total Outstanding', type: 'subheading' },
            { title: 'FIU', type: 'subheading' },
            { title: 'Outstanding Fees', type: 'subheading' },
            { title: 'Outstanding Interest', type: 'subheading' },
            { title: 'Credit Reserve', type: 'subheading' },
            { title: 'Repayment', type: 'heading' },
            { title: 'Total Repayment', type: 'subheading' },
            { title: 'FIU Repayment', type: 'subheading' },
            { title: 'Residual Transfer', type: 'subheading' },
            { title: 'Transfer Back', type: 'subheading' },
            { title: 'Transfer Reserve', type: 'subheading' },
            { title: 'Drawdown', type: 'heading' },
            { title: 'New Drawdowns', type: 'subheading' },
            { title: 'GST', type: 'subheading' },
            { title: 'Fees', type: 'heading' },
            { title: 'Drawdown', type: 'subheading' },
            { title: 'Admin', type: 'subheading' },
            { title: 'Insurance', type: 'subheading' },
            { title: 'Aged Receivables', type: 'subheading' },
            { title: 'One-off', type: 'subheading' },
            { title: 'Minimum Admin Fee', type: 'subheading' },
            { title: 'Minimum Monthly Interest Fee', type: 'subheading' },
            { title: 'Minimum Monthly Drawdown Fee', type: 'subheading' },
            { title: 'Dilutions', type: 'heading' },
            { title: 'Total Credit Notes', type: 'subheading' },
            { title: 'Unallocated Credit Notes', type: 'subheading' },
            { title: 'Allocated Credit Notes', type: 'subheading' },
            { title: 'Credit Reserve', type: 'heading' },
            { title: 'Repayment Transfer', type: 'subheading' },
            { title: 'Fee and interest credits', type: 'subheading' },
            { title: 'Drawdown Transfer', type: 'subheading' },
            { title: 'Fees and interest charged', type: 'subheading' },
            { title: 'Interest', type: 'heading' },
            { title: 'Daily Interest Charge', type: 'subheading' },
            { title: 'End of Day', type: 'heading' },
            { title: 'Fees Charged', type: 'subheading' },
            { title: 'Interest Charged', type: 'subheading' },
            { title: 'Principal Outstanding', type: 'subheading' },
            { title: 'Outstanding Fees', type: 'subheading' },
            { title: 'Outstanding Interest', type: 'subheading' },
            { title: 'Total Outstanding', type: 'subheading' },
            { title: 'Credit Reserve', type: 'subheading' }
        ],
        adminShadow: [
            { title: 'Ledger Summary', type: 'heading' },
            { title: 'Gross Receivables Ledger', type: 'subheading' },
            { title: 'Unselected Debtors', type: 'subheading' },
            { title: 'New Invoices', type: 'subheading' },
            // { title: 'New Credit Notes', type: 'subheading' },
            // { title: 'New Adjustments', type: 'subheading' },
            // { title: 'Unusual Transactions (Net)', type: 'subheading' },
            // { title: 'Amended Transactions (Net)', type: 'subheading' },
            { title: 'Selected Ledger', type: 'subheading' },
            // { title: 'Newly Aged Disapprovals', type: 'subheading' },
            // { title: 'Other Disapprovals', type: 'subheading' },
            { title: 'Adjustments', type: 'heading' },
            { title: 'Unallocated Payments', type: 'subheading' },
            { title: 'Unapproved Debtors', type: 'subheading' },
            { title: 'Pending Invoices', type: 'subheading' },
            { title: 'Ineligible Adjustments', type: 'subheading' },
            { title: 'Approved Ledger', type: 'subheading' },
            { title: 'Availability', type: 'heading' },
            { title: 'Advance Rate', type: 'subheading' },
            { title: 'Debtor Advance Rate Adjustment', type: 'subheading' },
            { title: 'Maximum Permitted Funding', type: 'subheading' },
            { title: 'Start of Day', type: 'heading' },
            { title: 'Total Balance Outstanding', type: 'subheading' },
            { title: 'Funds in Use', type: 'subheading' },
            { title: 'Accrued Fees', type: 'subheading' },
            { title: 'Accrued Interest', type: 'subheading' },
            { title: 'Surplus Funds', type: 'subheading' },
            { title: 'Repayment', type: 'heading' },
            { title: 'Total Repayment', type: 'subheading' },
            { title: 'FIU Repayment', type: 'subheading' },
            { title: 'Transfer to Surplus Funds', type: 'subheading' },
            { title: 'Transfer Back', type: 'subheading' },
            { title: 'Residual Transfer', type: 'subheading' },
            { title: 'Drawdown', type: 'heading' },
            { title: 'New Drawdowns', type: 'subheading' },
            { title: 'Fees', type: 'heading' },
            { title: 'Drawdown', type: 'subheading' },
            { title: 'Administration', type: 'subheading' },
            { title: 'Insurance', type: 'subheading' },
            { title: 'Aged Fees', type: 'subheading' },
            { title: 'One-off', type: 'subheading' },
            { title: 'Minimum Admin Fee', type: 'subheading' },
            { title: 'Minimum Monthly Interest Fee', type: 'subheading' },
            { title: 'Minimum Monthly Drawdown Fee', type: 'subheading' },
            { title: 'Interest', type: 'heading' },
            { title: 'Daily Interest Accrued', type: 'subheading' },
            { title: 'Surplus Funds', type: 'heading' },
            { title: 'Transfer from Repayments', type: 'subheading' },
            { title: 'Transfer from Fee & Interest Credits', type: 'subheading' },
            { title: 'Transfer to Drawdown', type: 'subheading' },
            { title: 'Transfer to Fees & Interest Charged', type: 'subheading' },
            { title: 'End of Day', type: 'heading' },
            { title: 'Fees Charged', type: 'subheading' },
            { title: 'Interest Charged', type: 'subheading' },
            { title: 'Funds in Use', type: 'subheading' },
            { title: 'Accrued Fees', type: 'subheading' },
            { title: 'Accrued Interest', type: 'subheading' },
            { title: 'Surplus Funds', type: 'subheading' },
            { title: 'Total Balance Outstanding', type: 'subheading' },
            { title: 'Availability Reduction', type: 'subheading' },
            // { title: 'Facility Limit Excess', type: 'subheading' },
            { title: 'Available Funds', type: 'subheading' },
            { title: 'Facility Limit', type: 'subheading' },
            { title: 'LTV', type: 'subheading' }
        ],
        borrower: [
            { title: 'Approved Receivables', type: 'subheading' },
            { title: 'Funding Available', type: 'subheading' },
            { title: 'Repayment', type: 'heading' },
            { title: 'Total Repayment', type: 'subheading' },
            { title: 'Principal', type: 'subheading' },
            { title: 'Residual', type: 'subheading' },
            { title: 'Transfer', type: 'subheading' },
            { title: 'Drawdown', type: 'heading' },
            { title: 'Drawdowns', type: 'subheading' },
            { title: 'GST', type: 'subheading' },
            { title: 'Fees', type: 'heading' },
            { title: 'Drawdown', type: 'subheading' },
            { title: 'Admin', type: 'subheading' },
            { title: 'Insurance', type: 'subheading' },
            { title: 'Aged Receivables', type: 'subheading' },
            { title: 'Minimum Admin Fee', type: 'subheading' },
            { title: 'Minimum Monthly Interest Fee', type: 'subheading' },
            { title: 'Minimum Monthly Drawdown Fee', type: 'subheading' },
            { title: 'Interest', type: 'heading' },
            { title: 'Daily Interest Charge', type: 'subheading' },
            { title: 'End of Day', type: 'heading' },
            { title: 'Principal Outstanding', type: 'subheading' },
            { title: 'Interest Outstanding', type: 'subheading' },
            { title: 'Total Outstanding', type: 'subheading' }
        ],
        borrowerShadow: [
            { title: 'Ledger Summary', type: 'heading' },
            { title: 'Gross Receivables Ledger', type: 'subheading' },
            { title: 'Unselected Debtors', type: 'subheading' },
            { title: 'New Invoices', type: 'subheading' },
            // hidden until computations are finalized
            // { title: 'New Credit Notes', type: 'subheading' },
            // { title: 'New Adjustments', type: 'subheading' },
            { title: 'Selected Ledger', type: 'subheading' },
            // hidden until computations are finalized
            // { title: 'Newly Aged Disapprovals', type: 'subheading' },
            // { title: 'Other Disapprovals', type: 'subheading' },
            { title: 'Adjustments', type: 'heading' },
            { title: 'Unallocated Payments', type: 'subheading' },
            { title: 'Unapproved Debtors', type: 'subheading' },
            { title: 'Pending Invoices', type: 'subheading' },
            { title: 'Ineligible Adjustments', type: 'subheading' },
            { title: 'Approved Ledger', type: 'subheading' },
            { title: 'Availability', type: 'heading' },
            { title: 'Advance Rate', type: 'subheading' },
            { title: 'Debtor Advance Rate Adjustment', type: 'subheading' },
            { title: 'Maximum Permitted Funding', type: 'subheading' },
            { title: 'Start of Day', type: 'heading' },
            { title: 'Total Balance Outstanding', type: 'subheading' },
            { title: 'Funds in Use', type: 'subheading' },
            { title: 'Accrued Fees', type: 'subheading' },
            { title: 'Accrued Interest', type: 'subheading' },
            { title: 'Surplus Funds', type: 'subheading' },
            { title: 'Repayment', type: 'heading' },
            { title: 'Total Repayment', type: 'subheading' },
            { title: 'Drawdown', type: 'heading' },
            { title: 'New Drawdowns', type: 'subheading' },
            { title: 'Fees', type: 'heading' },
            { title: 'Drawdown', type: 'subheading' },
            { title: 'Administration', type: 'subheading' },
            { title: 'Insurance', type: 'subheading' },
            { title: 'Aged Fees', type: 'subheading' },
            { title: 'One-off', type: 'subheading' },
            { title: 'Minimum Admin Fee', type: 'subheading' },
            { title: 'Minimum Monthly Interest Fee', type: 'subheading' },
            { title: 'Minimum Monthly Drawdown Fee', type: 'subheading' },
            { title: 'Interest', type: 'heading' },
            { title: 'Daily Interest Accrued', type: 'subheading' },
            { title: 'End of Day', type: 'heading' },
            { title: 'Fees Charged', type: 'subheading' },
            { title: 'Interest Charged', type: 'subheading' },
            { title: 'Funds in Use', type: 'subheading' },
            { title: 'Accrued Fees', type: 'subheading' },
            { title: 'Accrued Interest', type: 'subheading' },
            { title: 'Surplus Funds', type: 'subheading' },
            { title: 'Total Balance Outstanding', type: 'subheading' },
            { title: 'Availability Reduction', type: 'subheading' },
            // { title: 'Facility Limit Excess', type: 'subheading' },
            { title: 'Available Funds', type: 'subheading' },
            { title: 'Facility Limit', type: 'subheading' }
        ]
    };
    const sidebarClass = hasShadow ? 'sidebar -shadowed' : 'sidebar';
    const userRows = () => {
        switch (userType) {
            case 'borrower':
                return headings.borrower;
            case 'admin':
                return headings.admin;
            case 'adminShadow':
                return headings.adminShadow;
            case 'borrowerShadow':
                return headings.borrowerShadow;
            default:
                return [];
        }
    };
    return (React.createElement("div", { className: sidebarClass, "data-testid": 'sidebar' },
        React.createElement("div", { className: 'transaction-column' },
            React.createElement("div", { className: 'heading -sidebar' }),
            userRows().map(({ title, type }, i) => {
                const variant = type === 'heading'
                    ? '-heading'
                    : type === 'subheading'
                        ? '-subheading'
                        : '';
                return (React.createElement("div", { className: `heading -sidebar ${variant}`, key: i }, title));
            }))));
};
export default Sidebar;
