import React from 'react';
import ReactTooltip from 'react-tooltip';
const InfoTooltip = ({ tooltipId, tooltipDirection, customStyle, children }) => {
    return (React.createElement("div", null,
        React.createElement("div", { "data-tip": true, "data-for": tooltipId, className: 'custom-tooltip', "data-testid": 'tooltip' },
            React.createElement("div", { className: 'tooltip custom-icon -help' },
                React.createElement("div", { className: 'icon' }))),
        React.createElement(ReactTooltip, { id: tooltipId, place: tooltipDirection, effect: 'solid', border: true, borderColor: '#000', type: 'light', className: `${customStyle} -background` }, children)));
};
export default InfoTooltip;
