import React, { Component } from 'react';
import Select from 'react-select';
class Dropdown extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            options: this.props.options,
            selectedOption: null,
            value: '',
            name: this.props.name,
            hasSelectedCompany: this.props.hasSelectedCompany
        };
        this.handleChange = selectedOption => {
            const { handleSelectCompany } = this.props;
            this.setState({ selectedOption, value: selectedOption.value });
            if (handleSelectCompany) {
                handleSelectCompany(selectedOption);
            }
        };
    }
    render() {
        const { selectedOption, options, value, name } = this.state;
        const { hasSelectedCompany } = this.props;
        const customStyles = {
            control: (base, state) => ({
                ...base,
                fontSize: '28px',
                height: '56px',
                border: 'none',
                borderRadius: '0',
                boxShadow: 'none',
                borderBottom: state.isFocused
                    ? '1px solid #0747a6'
                    : hasSelectedCompany
                        ? '1px solid #e6e6e6'
                        : '1px solid #ff5630',
                ':hover': {
                    borderBottom: '1px solid #0747a6'
                },
                transition: 'border-bottom 400ms'
            }),
            indicatorSeparator: () => ({
                display: 'none'
            }),
            singleValue: () => ({
                color: '#0747a6',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                width: '100%'
            }),
            menu: base => ({
                ...base,
                fontSize: '14px',
                border: 'none',
                borderRadius: '0 0 4px 4px',
                marginTop: '0',
                boxShadow: `0 4px 12px rgba(7, 71, 166, 0.1), 0 4px 4px rgba(7, 71, 166, 0.07)`
            }),
            menuList: base => ({
                ...base,
                border: 'none',
                padding: '0'
            }),
            dropdownIndicator: (base, state) => ({
                ...base,
                width: '40px',
                color: state.isFocused
                    ? '#0747a6'
                    : hasSelectedCompany
                        ? '#e6e6e6'
                        : '#ff5630',
                ':hover': {
                    color: '#0747a6'
                }
            }),
            valueContainer: base => ({
                ...base,
                padding: '0',
                width: 'calc(100% - 40px)',
                flexWrap: 'nowrap'
            }),
            placeholder: base => ({
                ...base,
                color: hasSelectedCompany ? '#e6e6e6' : '#bf2600',
                fontWeight: '300',
                transition: 'color 400ms'
            }),
            option: (base, state) => ({
                ...base,
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                color: state.isSelected ? '#fff' : '#42526e',
                backgroundColor: state.isSelected ? '#2684ff' : '#fff',
                ':hover': {
                    color: '#fff',
                    backgroundColor: '#2684ff'
                }
            })
        };
        const fieldClass = hasSelectedCompany ? 'field -full' : 'field -full -error';
        return (React.createElement("div", { className: fieldClass },
            React.createElement("div", { className: 'form-group -section -no-outline form -bare' },
                React.createElement(Select, { styles: customStyles, placeholder: 'Search for company...', value: selectedOption, onChange: this.handleChange, options: options, isSearchable: true, maxMenuHeight: '140px' }),
                React.createElement("input", { type: 'hidden', name: name, value: value, "data-testid": 'dropdown' }))));
    }
}
export default Dropdown;
