import React from 'react';
import Flatpickr from 'react-flatpickr';
import { updateReconcilingAsOf } from '../../api/uploaded_ledger';
const ReconcilingAsOf = ({ apiToken, uploadedInvoicesFileId, companyId, reconciledAt, reconcilingAsOf }) => {
    const handleDateChange = async (date, dateString) => {
        await updateReconcilingAsOf(apiToken, companyId, uploadedInvoicesFileId, {
            uploadedLedger: { reconcilingAsOf: dateString }
        });
    };
    if (reconciledAt) {
        return (React.createElement("div", { className: 'title' },
            React.createElement("div", { className: 'text' }, "Reconciled at"),
            React.createElement(Flatpickr, { value: reconcilingAsOf, className: 'form-control -bg-white -w-10', options: { altInput: true }, disabled: true })));
    }
    return (React.createElement("div", { className: 'title' },
        React.createElement("div", { className: 'text' }, "Reconciling as of"),
        React.createElement(Flatpickr, { value: reconcilingAsOf, onChange: handleDateChange, name: 'uploaded_ledger[payments_date_range]', className: 'form-control -bg-white -w-10', options: { altInput: true } })));
};
export default ReconcilingAsOf;
