import React, { useEffect, useState } from 'react';
import AmountField from './AmountField';
import ConfirmStepActions from './ConfirmStepActions';
import Pane from './Pane';
import Receipt from './Receipt';
import ReconcileStep from './ReconcileStep';
import XeroReconcileStep from './XeroReconcileStep';
import { isEmptyString } from '../../utils/isEmptyString';
const BorrowerRequestDrawdown = ({ backLink, drawdownFeeRate, formAttributes, fundingAvailable = 0, bankTransactions, minDrawdownFee, isXeroUser, reserveAmount }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [errorMessage, setErrorMessage] = useState('');
    const [amount, setAmount] = useState({
        floatValue: 0,
        value: 0,
        formattedValue: ''
    });
    const handleChangeAmount = (values) => {
        setAmount(values);
    };
    const withBanktransactions = bankTransactions && bankTransactions.length !== 0;
    useEffect(() => {
        if (isXeroUser && !withBanktransactions) {
            setCurrentStep(2);
        }
    }, []);
    const validateDrawdownAmount = () => {
        if (currentStep !== 2) {
            return true;
        }
        if (isEmptyString(amount.formattedValue)) {
            setErrorMessage('Field must not be blank.');
            return false;
        }
        else if (amount.floatValue <= 0) {
            setErrorMessage('Amount must not be less than or equal to 0.');
            return false;
        }
        else if (amount.floatValue > fundingAvailable) {
            setErrorMessage('Amount must not be greater than funding available.');
            return false;
        }
        else {
            setErrorMessage('');
            return true;
        }
    };
    const handleNextStep = () => {
        if (validateDrawdownAmount()) {
            setCurrentStep(currentStep + 1);
        }
    };
    const handlePrevStep = () => setCurrentStep(currentStep - 1);
    const handleSubmit = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };
    return (React.createElement("form", { onKeyDown: handleSubmit, action: formAttributes.url, acceptCharset: 'UTF-8', method: 'post', "data-testid": 'borrower-request-drawdown' },
        React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: formAttributes.csrfToken }),
        React.createElement("div", { className: 'drawdown-container' },
            React.createElement(Pane, { title: 'Reconcile', step: 1, currentStep: currentStep }, isXeroUser ? (React.createElement(XeroReconcileStep, { bankTransactions: bankTransactions })) : (React.createElement(ReconcileStep, { handleNextStep: handleNextStep, backLink: backLink }))),
            React.createElement(Pane, { title: 'Request', step: 2, currentStep: currentStep },
                React.createElement("h3", { className: 'text -medium' }, "How much would you like to borrow?"),
                React.createElement("h5", { className: 'text' },
                    "You currently have $",
                    fundingAvailable,
                    " available to drawdown."),
                React.createElement(AmountField, { handleChangeAmount: handleChangeAmount, errorMessage: errorMessage }),
                React.createElement("div", { className: 'actions action-buttons' },
                    React.createElement("input", { type: 'hidden', name: 'drawdown[amount]', value: amount.floatValue }),
                    React.createElement("a", { href: backLink, className: 'button -tertiary' }, "Cancel"),
                    React.createElement("a", { className: 'button -primary', onClick: handleNextStep }, "Request"))),
            React.createElement(Pane, { title: 'Confirm', step: 3, currentStep: currentStep },
                React.createElement(Receipt, { drawdownFeeRate: drawdownFeeRate, minimumDrawdownFee: minDrawdownFee, requestedAmount: amount.floatValue, reserveAmount: reserveAmount }),
                React.createElement("div", { className: 'note' },
                    React.createElement("strong", null, "Note:"),
                    " This drawdown is subject to review by Earlypay. If we require more information on this request, we will be in touch soon."),
                React.createElement(ConfirmStepActions, { ...{ backLink, handlePrevStep } })))));
};
export default BorrowerRequestDrawdown;
