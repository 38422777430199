import fetch from 'isomorphic-fetch';
export const fetchBrokerCompanyAssignedAdmins = async (apiToken, brokerId) => {
    const endpoint = `/api/admin/brokers/${brokerId}/company/assigned_admins`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const setBrokerCompanyAssignedAdmins = async (apiToken, brokerId, assignedAdminIds) => {
    const endpoint = `/api/admin/brokers/${brokerId}/company/assign_admins`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify({ admin_ids: assignedAdminIds })
    }).then((res) => res.json());
};
