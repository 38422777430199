import React, { useState } from 'react';
import classNames from 'classnames';
import { convertCentsString } from '../../utils/convertCents';
import { numberToPercentageFixed } from '../../utils/numberToPercentage';
import Select from 'react-select';
import { dropdownStyles } from '../../custom-styles/dropdown';
const DebtorConcentrationsForm = ({ csrfToken, path, applyDefaults, calculationType, defaultLimitCents, defaultLimitRate, platformDefaultLimitCents, platformDefaultLimitRate, readOnly = false }) => {
    const concentrationTypeOptions = [
        {
            value: 'global_default_concentrations',
            label: 'Global Default Concentrations'
        },
        {
            value: 'customer_client_concentrations',
            label: 'Customer Client Concentrations'
        }
    ];
    const calculationTypeOptions = [
        { value: 'greater_of', label: 'Greater Of' },
        { value: 'lesser_of', label: 'Lesser Of' }
    ];
    const defaultConcentrationType = applyDefaults
        ? concentrationTypeOptions[0]
        : concentrationTypeOptions[1];
    const defaultCalculationType = calculationTypeOptions.find(opt => opt.value === calculationType) ||
        calculationTypeOptions[0];
    const gdcInputValue = `Greater of $${convertCentsString(platformDefaultLimitCents)} and ${numberToPercentageFixed(platformDefaultLimitRate)}%`;
    const [selectedConcentrationType, setSelectedConcentrationType] = useState(defaultConcentrationType.value);
    const submitButtonClass = classNames('button -primary', {
        '-disabled': readOnly
    });
    return (React.createElement("form", { method: 'post', action: path, className: 'form form-pane', "data-testid": 'debtor-concentrations-form' },
        React.createElement("input", { type: 'hidden', name: '_method', value: 'put' }),
        React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: 'label' }, "Concentration Type"),
            React.createElement(Select, { name: 'company[concentration_type]', options: concentrationTypeOptions, defaultValue: defaultConcentrationType, styles: dropdownStyles, onChange: selectedOption => setSelectedConcentrationType(selectedOption.value), isDisabled: readOnly })),
        selectedConcentrationType === 'global_default_concentrations' && (React.createElement("div", { className: 'form-group' },
            React.createElement("input", { value: gdcInputValue, className: 'input form-control', disabled: true, "data-testid": 'gdc-input' }))),
        selectedConcentrationType === 'customer_client_concentrations' && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, "Calculation Type"),
                React.createElement(Select, { name: 'company[default_concentration_calculation_type]', options: calculationTypeOptions, defaultValue: defaultCalculationType, styles: dropdownStyles, isDisabled: readOnly })),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, "Default Limit ($)"),
                React.createElement("input", { className: 'input form-control', type: 'number', name: 'company[default_limit_cents]', defaultValue: convertCentsString(defaultLimitCents), placeholder: '0.00', disabled: readOnly, "data-testid": 'limit-cents-input' })),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, "Default Limit (%)"),
                React.createElement("input", { className: 'input form-control', type: 'number', name: 'company[default_limit_rate]', defaultValue: numberToPercentageFixed(defaultLimitRate), placeholder: '0.0', disabled: readOnly, "data-testid": 'limit-rate-input' })))),
        React.createElement("div", { className: 'action-buttons' },
            React.createElement("input", { type: 'submit', className: submitButtonClass, disabled: readOnly }))));
};
export default DebtorConcentrationsForm;
