import React from 'react';
import CustomerRow from './CustomerRow';
const CustomersTableBody = ({ customersArray, isLoading, switchOffHandler, switchOnHandler, isManualLedger }) => {
    if (isLoading) {
        return (React.createElement("tbody", { "data-testid": 'loading' },
            React.createElement("tr", null,
                React.createElement("td", { className: 'cell', colSpan: 6 }, "Loading..."))));
    }
    if (customersArray.length === 0) {
        return (React.createElement("tbody", { "data-testid": 'no-record' },
            React.createElement("tr", null,
                React.createElement("td", { className: 'cell', colSpan: 6 }, "There are no Debtors to display"))));
    }
    return (React.createElement("tbody", { "data-testid": 'loaded' }, customersArray.map((customer) => {
        return (React.createElement(CustomerRow, { key: customer.id, customer: customer, switchOnHandler: switchOnHandler, switchOffHandler: switchOffHandler, ...{ isManualLedger } }));
    })));
};
export default CustomersTableBody;
