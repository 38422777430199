import React, { useState } from 'react';
import classNames from 'classnames';
import Panel from './Panel';
import DownloadPanel from '../download-repayment/Panel';
const FilterRepayment = ({ bankOptions, selectedBank, transferAmountGreaterThanZero, status = 'processed', urlPath }) => {
    const [isOpened, setIsOpened] = useState(false);
    const [isDownloadOpened, setIsDownloadOpened] = useState(false);
    const togglePanel = () => setIsOpened(!isOpened);
    const toggleDownloadPanel = () => setIsDownloadOpened(!isDownloadOpened);
    const buttonClass = classNames('icon-button button', '-rectangular', '-border-brand-a-400', '-filter', '-process-button', '-icon-filter-brand-a-400', '-text-brand-a-400');
    const buttonDownloadClass = classNames('icon-button button', '-rectangular', '-primary', '-filter', '-process-button');
    return (React.createElement("div", { className: 'process-panel -filter-process', "data-testid": 'filter-dropdown' },
        React.createElement("a", { href: '#', className: buttonClass, onClick: togglePanel },
            React.createElement("div", { className: 'text' }, "Filter"),
            React.createElement("div", { className: 'icon' })),
        React.createElement(Panel, { ...{
                isOpened,
                bankOptions,
                selectedBank,
                transferAmountGreaterThanZero,
                status
            } }),
        React.createElement("a", { href: '#', className: buttonDownloadClass, onClick: toggleDownloadPanel },
            React.createElement("div", { className: 'text' }, "Download")),
        React.createElement(DownloadPanel, { ...{
                isOpened: isDownloadOpened,
                urlPath
            } })));
};
export default FilterRepayment;
