import classNames from 'classnames';
import React from 'react';
import { isListOfEmptyRecords } from '../../utils';
import InvoiceDetailsForm from './InvoiceDetailsForm';
const InvoicePanel = ({ supplier, index, invoices, selectedInvoiceCurrencyOptions, invoiceAmountValues, invoiceDateSettings, invoiceErrors, invoiceDocumentErrors, invoiceCurrencyOptions, isSavingList = Array(invoices.length).fill(false), handleInvoiceDetailUpdate, handleInvoiceAmountValueChange, handleInvoiceFocus, handleInvoiceCurrencyOptionChange, handleInvoiceFileChange, handleInvoiceFileReplace, handleRemoveInvoiceFile, dispatch, saveInvoice, removeInvoice, handleDeleteInvoiceFile }) => {
    const isFirstPanel = index === 0;
    const labelClass = classNames('header -space-between', {
        '-error': !isListOfEmptyRecords(invoiceErrors) ||
            !isListOfEmptyRecords(invoiceDocumentErrors)
    });
    return (React.createElement("div", { className: 'custom-panel -collapsible -no-margin', "data-testid": 'invoice-panel' },
        React.createElement("input", { className: 'toggle', id: `supplier-invoices-${index}-label`, type: 'checkbox', defaultChecked: isFirstPanel, "data-testid": `supplier-invoices-${index}-checkbox` }),
        React.createElement("label", { className: labelClass, htmlFor: `supplier-invoices-${index}-label` }, `Supplier ${index + 1} - ${supplier.name}`),
        React.createElement("div", { className: 'body trade-loan-invoice-form -padded' },
            invoices.map((invoice, invoiceIndex) => (React.createElement(InvoiceDetailsForm, { ...{
                    invoiceDateSettings,
                    invoiceCurrencyOptions,
                    handleInvoiceDetailUpdate,
                    handleInvoiceAmountValueChange,
                    handleInvoiceFocus,
                    handleInvoiceCurrencyOptionChange,
                    handleInvoiceFileChange,
                    handleInvoiceFileReplace,
                    handleRemoveInvoiceFile,
                    handleDeleteInvoiceFile,
                    dispatch
                }, invoice: invoice, invoiceErrors: invoiceErrors[invoiceIndex], invoiceDocumentErrors: invoiceDocumentErrors[invoiceIndex], selectedInvoiceCurrencyOption: selectedInvoiceCurrencyOptions[invoiceIndex], invoiceAmountValues: invoiceAmountValues[invoiceIndex], supplierIndex: index, invoiceIndex: invoiceIndex, key: `${index}-${invoiceIndex}`, displayRemoveButton: invoices.length > 1, saveInvoice: saveInvoice, removeInvoice: removeInvoice, isSaving: isSavingList[invoiceIndex] }))),
            React.createElement("div", { className: 'form-section add' },
                React.createElement("button", { className: 'custom-button button -add-trade-loan-invoice', onClick: () => dispatch({ type: 'ADD_INVOICE', supplierIndex: index }) }, "Add Another Invoice")))));
};
export default InvoicePanel;
