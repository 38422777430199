import React, { useState } from 'react';
import NewInvoicesTable from './NewInvoicesTable';
import VariedInvoicesTable from './VariedInvoicesTable';
import PaidInvoicesTable from './PaidInvoicesTable';
import VerifyInvoicesToastPanel from './VerifyInvoicesToastPanel';
import VerifyInvoicePanel from '../invoice-verification/VerifyInvoicePanel';
import Dinero from 'dinero.js';
const InvoiceGroupList = ({ apiToken, companyId, invoiceBatchId, invoiceBatch, invoicePath, customerPath, verificationReasons }) => {
    const defaultCurrency = 'AUD';
    const [isInvoiceSelected, setIsInvoiceSelected] = useState(false);
    const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);
    const [selectedInvoicesTotalAmountDue, setSelectedInvoicesTotalAmountDue] = useState(Dinero({ amount: 0, currency: defaultCurrency }));
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [newInvoicesTotalAmountDue, setNewInvoicesTotalAmountDue] = useState(Dinero({ amount: 0, currency: defaultCurrency }));
    const [variedInvoicesTotalAmountDue, setVariedInvoicesTotalAmountDue] = useState(Dinero({ amount: 0, currency: defaultCurrency }));
    const [newInvoicesCount, setNewInvoicesCount] = useState(0);
    const [variedInvoicesCount, setVariedInvoicesCount] = useState(0);
    const [showVerificationForm, setShowVerificationForm] = useState(false);
    const handleSelectedInvoice = (invoice) => {
        const newIds = [...selectedInvoiceIds];
        const newInvoices = [...selectedInvoices];
        const invoiceId = invoice.invoiceId;
        const invoiceAmount = Dinero({
            amount: invoice.amountDueCents,
            currency: defaultCurrency
        });
        if (isSelected(invoiceId)) {
            newIds.splice(newIds.indexOf(invoiceId), 1);
            setSelectedInvoicesTotalAmountDue(selectedInvoicesTotalAmountDue.subtract(invoiceAmount));
            newInvoices.splice(newInvoices.findIndex(item => item.invoiceId === invoiceId), 1);
        }
        else {
            newIds.push(invoiceId);
            setSelectedInvoicesTotalAmountDue(selectedInvoicesTotalAmountDue.add(invoiceAmount));
            newInvoices.push(invoice);
        }
        setSelectedInvoiceIds(newIds);
        setSelectedInvoices(newInvoices);
        if (newIds.length > 0) {
            setIsInvoiceSelected(true);
        }
        else {
            setIsInvoiceSelected(false);
        }
    };
    const isSelected = invoiceId => {
        return selectedInvoiceIds.includes(invoiceId);
    };
    const NewInvoicesTotalDue = (totalDue, count) => {
        const invoicesTotalDue = Dinero({
            amount: totalDue,
            currency: defaultCurrency
        });
        setNewInvoicesTotalAmountDue(invoicesTotalDue);
        setNewInvoicesCount(count);
    };
    const VariedInvoicesTotalDue = (totalDue, count) => {
        const invoicesTotalDue = Dinero({
            amount: totalDue,
            currency: defaultCurrency
        });
        setVariedInvoicesTotalAmountDue(invoicesTotalDue);
        setVariedInvoicesCount(count);
    };
    const handleVerifyButton = () => {
        setShowVerificationForm(true);
        setIsInvoiceSelected(false);
    };
    const handleCancelVerifyButton = () => {
        setIsInvoiceSelected(false);
        setSelectedInvoiceIds([]);
        setSelectedInvoices([]);
        setSelectedInvoicesTotalAmountDue(Dinero({ amount: 0, currency: defaultCurrency }));
    };
    const cancelVerification = () => {
        setShowVerificationForm(false);
        setIsInvoiceSelected(true);
    };
    return (React.createElement("div", { className: 'invoice-group-list' },
        React.createElement("div", { className: 'text-group -spaced' },
            React.createElement("div", { className: 'text -larger' },
                React.createElement("div", { className: 'bold' }, "New Invoices"))),
        React.createElement(NewInvoicesTable, { ...{
                apiToken,
                companyId,
                invoiceBatchId,
                invoiceBatch,
                invoicePath,
                customerPath,
                selectedInvoiceIds,
                handleSelectedInvoice
            }, totalInvoices: NewInvoicesTotalDue }),
        React.createElement("div", { className: 'text-group -spaced' },
            React.createElement("div", { className: 'text -larger' },
                React.createElement("div", { className: 'bold' }, "Varied Invoices"))),
        React.createElement(VariedInvoicesTable, { ...{
                apiToken,
                companyId,
                invoiceBatchId,
                invoiceBatch,
                invoicePath,
                customerPath,
                selectedInvoiceIds,
                handleSelectedInvoice
            }, totalInvoices: VariedInvoicesTotalDue }),
        React.createElement("div", { className: 'text-group -spaced' },
            React.createElement("div", { className: 'text -larger' },
                React.createElement("div", { className: 'bold' }, "Paid Invoices"))),
        React.createElement(PaidInvoicesTable, { ...{
                apiToken,
                companyId,
                invoiceBatchId,
                invoicePath,
                customerPath
            } }),
        isInvoiceSelected && (React.createElement(VerifyInvoicesToastPanel, { selectedInvoiceCount: selectedInvoiceIds.length, selectedInvoicesTotalAmountDue: selectedInvoicesTotalAmountDue
                .setLocale('en-AU')
                .toFormat(), allInvoicesTotalAmountDue: newInvoicesTotalAmountDue
                .add(variedInvoicesTotalAmountDue)
                .setLocale('en-AU')
                .toFormat(), invoicesCount: newInvoicesCount + variedInvoicesCount, handleVerifyButton: handleVerifyButton, handleCancelVerifyButton: handleCancelVerifyButton })),
        showVerificationForm && (React.createElement(VerifyInvoicePanel, { ...{
                apiToken,
                companyId,
                invoiceBatchId,
                verificationReasons,
                selectedInvoices,
                selectedInvoiceIds,
                cancelVerification
            }, selectedInvoicesTotalAmountDue: selectedInvoicesTotalAmountDue
                .setLocale('en-AU')
                .toFormat() }))));
};
export default InvoiceGroupList;
