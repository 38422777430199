import React, { useState } from 'react';
import classNames from 'classnames';
import Panel from './Panel';
const FilterTradeLoan = ({ baseUrl, filter }) => {
    const [isOpened, setIsOpened] = useState(false);
    const togglePanel = () => setIsOpened(!isOpened);
    const buttonClass = classNames('icon-button button', '-rectangular', '-border-brand-a-400', '-filter', '-process-button', '-icon-filter-brand-a-400', '-text-brand-a-400');
    return (React.createElement("div", { className: 'filter' },
        React.createElement("div", { className: 'button' },
            React.createElement("a", { href: '#', className: buttonClass, onClick: togglePanel },
                React.createElement("div", { className: 'text' }, "Filter"),
                React.createElement("div", { className: 'icon' }))),
        React.createElement(Panel, { ...{
                isOpened,
                baseUrl,
                filter
            } })));
};
export default FilterTradeLoan;
