import React from 'react';
import NumberFormat from 'react-number-format';
import { formatDateWithYear } from '../../utils/formatDateWithYear';
const CommissionRatesSummary = ({ upfrontCommission, gstIncluded, crTrailingFeeRate, crTrailingInterestRate, arTrailingFeeRate, arTrailingInterestRate, arTrailingExpiryDate }) => {
    const upfrontCommissionGst = upfrontCommission && upfrontCommission * (gstIncluded ? 1 : 1.1);
    const arTrailingExpiryDateFormatted = arTrailingExpiryDate
        ? formatDateWithYear(arTrailingExpiryDate)
        : '-';
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'section', "data-testid": 'commission-rates-summary' },
            React.createElement("div", { className: 'subtitle -large' }, "Commission Rates (Upfront)"),
            React.createElement("div", { className: 'description -space-between' },
                React.createElement("div", null, "Upfront Commission ($)"),
                React.createElement(NumberFormat, { value: upfrontCommission, defaultValue: '-', displayType: 'text', decimalScale: 2, fixedDecimalScale: true, thousandSeparator: true, prefix: '$' })),
            React.createElement("div", { className: 'description -space-between' },
                React.createElement("div", null, "Upfront Commission inc GST ($)"),
                React.createElement(NumberFormat, { value: upfrontCommissionGst, defaultValue: '-', displayType: 'text', decimalScale: 2, fixedDecimalScale: true, thousandSeparator: true, prefix: '$', "data-testid": 'upfront-commission-gst' }))),
        React.createElement("div", { className: 'divider' }),
        React.createElement("div", { className: 'section' },
            React.createElement("div", { className: 'subtitle -large' }, "Commission Rates (Trailing)"),
            React.createElement("div", { className: 'description -space-between' },
                React.createElement("div", null, "Admin Fee Commission (%)"),
                React.createElement(NumberFormat, { value: crTrailingFeeRate, defaultValue: '-', displayType: 'text', decimalScale: 2, suffix: '%' })),
            React.createElement("div", { className: 'description -space-between' },
                React.createElement("div", null, "Interest Commission (%)"),
                React.createElement(NumberFormat, { value: crTrailingInterestRate, defaultValue: '-', displayType: 'text', decimalScale: 2, suffix: '%' }))),
        React.createElement("div", { className: 'divider' }),
        React.createElement("div", { className: 'section' },
            React.createElement("div", { className: 'subtitle -large' }, "Aggregator Rates (Trailing)"),
            React.createElement("div", { className: 'description -space-between' },
                React.createElement("div", null, "Admin Fee Commission (%)"),
                React.createElement(NumberFormat, { value: arTrailingFeeRate, defaultValue: '-', displayType: 'text', decimalScale: 2, suffix: '%' })),
            React.createElement("div", { className: 'description -space-between' },
                React.createElement("div", null, "Interest Commission (%)"),
                React.createElement(NumberFormat, { value: arTrailingInterestRate, defaultValue: '-', displayType: 'text', decimalScale: 2, suffix: '%' })),
            React.createElement("div", { className: 'description -space-between' },
                React.createElement("div", null, "Expiry Date"),
                React.createElement("div", null, arTrailingExpiryDateFormatted)))));
};
export default CommissionRatesSummary;
