import React, { useLayoutEffect, useRef, useState } from 'react';
import Column from './Column';
import Sidebar from './Sidebar';
const TransactionHistory = ({ drawdownPath, isCompanyActive, hasDrawdowns, placeholderImage, transactionHistory, userType = 'borrower', isOwner }) => {
    const wrapper = useRef(null);
    // Scrolling States
    const [scrollWidth, setScrollWidth] = useState(0);
    const [wrapperWidth, setWrapperWidth] = useState(0);
    const [leftOffset, setLeftOffset] = useState(0);
    const [hasScrolled, setHasScrolled] = useState(false);
    const [hasReachedEnd, setHasReachedEnd] = useState(false);
    useLayoutEffect(() => {
        if (!wrapper.current)
            return;
        if (transactionHistory.length > 0) {
            setScrollWidth(wrapper.current.scrollWidth);
            setWrapperWidth(wrapper.current.clientWidth);
        }
        else {
            setScrollWidth(0);
            setWrapperWidth(0);
        }
    }, []);
    const handleScroll = () => {
        if (!wrapper.current)
            return;
        setLeftOffset(wrapper.current.scrollLeft);
        const scrollPosition = wrapper.current.scrollLeft;
        const endpoint = scrollWidth - wrapperWidth;
        if (scrollPosition && scrollPosition > 0) {
            setHasScrolled(true);
            scrollPosition === endpoint
                ? setHasReachedEnd(true)
                : setHasReachedEnd(false);
        }
        else {
            setHasScrolled(false);
        }
    };
    const containerClass = hasReachedEnd
        ? 'transaction-history'
        : 'transaction-history -masked';
    return (React.createElement("div", { className: 'data-pane -compact -flat', "data-testid": 'transaction-history' }, transactionHistory.length > 0 ? (React.createElement("div", { className: containerClass },
        React.createElement(Sidebar, { hasShadow: hasScrolled, userType: userType, key: 'th-sidebar' }),
        React.createElement("div", { className: 'wrapper', ref: wrapper, onScroll: handleScroll },
            React.createElement("ul", { className: 'content' }, transactionHistory.map((transaction, idx) => (React.createElement(Column, { transaction: transaction, leftOffset: leftOffset, wrapperWidth: wrapperWidth, key: idx, userType: userType }))))))) : (React.createElement("div", { className: 'placeholder-pane' },
        React.createElement("div", { className: 'image' },
            React.createElement("img", { src: placeholderImage })),
        React.createElement("div", { className: 'title' }, "Uh oh!"),
        React.createElement("div", { className: 'body' }, "You don\u2019t have any transaction history to display."),
        isCompanyActive && isOwner && !hasDrawdowns && (React.createElement("a", { href: drawdownPath, className: 'button' }, "Create Drawdown"))))));
};
export default TransactionHistory;
