import React from 'react';
const CustomerAbnDetailsPanel = ({ abnData, fetchAbnLink }) => {
    return (React.createElement("div", { className: 'custom-panel' },
        React.createElement("div", { className: 'header -space-between' },
            "ABN Details",
            React.createElement("a", { href: fetchAbnLink, className: 'link', "data-method": 'post', "data-disable-with": 'Fetching ABN Details...' }, "Fetch ABN Details")),
        abnData && (React.createElement("table", { className: 'custom-table' },
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Main Name"),
                    React.createElement("td", { className: 'value' }, abnData === null || abnData === void 0 ? void 0 : abnData.mainName)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Legal Name"),
                    React.createElement("td", { className: 'value' }, abnData === null || abnData === void 0 ? void 0 : abnData.legalName)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Trading Name/s"),
                    React.createElement("td", { className: 'value' }, abnData === null || abnData === void 0 ? void 0 : abnData.tradingName)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "State"),
                    React.createElement("td", { className: 'value' }, abnData === null || abnData === void 0 ? void 0 : abnData.addressStateCode)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Active From Date"),
                    React.createElement("td", { className: 'value' }, abnData === null || abnData === void 0 ? void 0 : abnData.activeFromDate)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "GST Registered"),
                    React.createElement("td", { className: 'value' }, abnData === null || abnData === void 0 ? void 0 : abnData.gstFromDate)))))));
};
export default CustomerAbnDetailsPanel;
