import classNames from 'classnames';
import React from 'react';
import NotificationItem from './NotificationItem';
const NotificationList = (props) => {
    const { notifications, seeAllPath, isAdmin, handleMarkAllAsRead, amendedInvoiceThreshold } = props;
    const notificationListClass = classNames('list', { '-admin': isAdmin });
    return (React.createElement("div", { className: notificationListClass, "data-testid": 'notification-list' },
        React.createElement("div", { className: 'header' },
            React.createElement("div", { className: 'title' }, " Notifications "),
            React.createElement("a", { className: 'link', onClick: () => handleMarkAllAsRead() }, "Mark All as Read")),
        notifications.data.map((notification, index) => (React.createElement(NotificationItem, { key: index, notification: notification, amendedInvoiceThreshold: amendedInvoiceThreshold }))),
        React.createElement("div", { className: 'actions' },
            React.createElement("a", { className: 'link', href: seeAllPath }, "See All"))));
};
export default NotificationList;
