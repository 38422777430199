import React, { useEffect, useState } from 'react';
import { fetchBrokerCompanyAssignedAdmins, setBrokerCompanyAssignedAdmins } from '../../api/broker_company';
import { fetchAdmins } from '../../api/user';
import { showNotifyToast } from '../../utils';
import AssignedAdminsList from './AssignedAdminsList';
import AdminSelect from './AdminSelect';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
const BrokerAssignedAdminsPanel = ({ apiToken, brokerId }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [admins, setAdmins] = useState([]);
    const [assignedAdmins, setAssignedAdmins] = useState([]);
    const [selectedAdminIds, setSelectedAdminIds] = useState([]);
    const [options, setOptions] = useState([]);
    const [values, setValues] = useState([]);
    const fetchData = async () => {
        try {
            const { data: adminData } = await fetchAdmins(apiToken);
            setAdmins(adminData.map(admin => admin.attributes));
            const { data: assignedAdminData } = await fetchBrokerCompanyAssignedAdmins(apiToken, brokerId);
            setAssignedAdmins(assignedAdminData.map(admin => admin.attributes));
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong while fetching admins. Please try again.',
                type: 'error'
            });
        }
        setIsLoading(false);
    };
    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        setOptions(admins.map((admin) => {
            return {
                value: admin.id,
                label: `${admin.firstName} ${admin.lastName}`
            };
        }));
        setValues(assignedAdmins.map((admin) => {
            return {
                value: admin.id,
                label: `${admin.firstName} ${admin.lastName}`
            };
        }));
    }, [admins, assignedAdmins]);
    const handleEdit = () => {
        setIsEditing(true);
    };
    const handleCancel = () => {
        setSelectedAdminIds(assignedAdmins.map((admin) => admin.id));
        setIsEditing(false);
    };
    const handleUpdate = async () => {
        setIsLoading(true);
        setIsEditing(false);
        try {
            const { data } = await setBrokerCompanyAssignedAdmins(apiToken, brokerId, selectedAdminIds);
            setAssignedAdmins(data.map(admin => admin.attributes));
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong while assigning admins. Please try again.',
                type: 'error'
            });
        }
        setIsLoading(false);
    };
    const handleChange = options => {
        setSelectedAdminIds(options === null ? [] : options.map(o => o.value));
    };
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'broker-assigned-admins-panel' },
        React.createElement("div", { className: 'header -space-between' },
            "Assigned Admins",
            isEditing || (React.createElement("a", { href: '#', onClick: handleEdit, "data-testid": 'edit-action' }, "Edit"))),
        isEditing ||
            (isLoading ? (React.createElement(LoadingSpinner, { className: '-small' })) : (React.createElement(AssignedAdminsList, { assignedAdmins: assignedAdmins, isAdmin: true }))),
        isEditing && (React.createElement(AdminSelect, { ...{
                options,
                values,
                handleChange,
                handleCancel,
                handleUpdate
            } }))));
};
export default BrokerAssignedAdminsPanel;
