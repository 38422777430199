import classNames from 'classnames';
import React, { useState } from 'react';
import Flatpickr from 'react-flatpickr';
import ErrorList from '../shared/ErrorList';
const InvoiceDatesForm = ({ csrfToken, formUrl, resource, errors }) => {
    const [selectedCallDate, setSelectedCallDate] = useState(resource.callDate || '');
    const [selectedWillPayDate, setSelectedWillPayDate] = useState(resource.willPayDate || '');
    const handleSelectedCallDateChange = (selectedCallDates, dateString) => {
        setSelectedCallDate(dateString);
    };
    const handleSelectedWillPayDateChange = (selectedWillPayDates, dateString) => {
        setSelectedWillPayDate(dateString);
    };
    const flatpickrCallDateSettings = {
        altInput: true,
        altFormat: 'd M Y',
        dateFormat: 'Y-m-d',
        onChange: handleSelectedCallDateChange
    };
    const flatpickrWillPayDateSettings = {
        altInput: true,
        altFormat: 'd M Y',
        dateFormat: 'Y-m-d',
        onChange: handleSelectedWillPayDateChange
    };
    const hasCallDateError = errors.call_date !== undefined;
    const callDateLabelClass = classNames('label -uppercase', {
        '-error': hasCallDateError
    });
    const hasWillPayDateError = errors.will_pay_date !== undefined;
    const willPayDateLabelClass = classNames('label -uppercase', {
        '-error': hasWillPayDateError
    });
    return (React.createElement("form", { className: 'invoice-dates-form form', action: formUrl, acceptCharset: 'UTF-8', method: 'post' },
        React.createElement("input", { type: 'hidden', name: '_method', value: 'put' }),
        React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
        React.createElement("input", { type: 'hidden', name: 'invoice[call_date]', value: selectedCallDate }),
        React.createElement("input", { type: 'hidden', name: 'invoice[will_pay_date]', value: selectedWillPayDate }),
        React.createElement("div", { className: 'form-group date' },
            React.createElement("label", { className: callDateLabelClass }, "Call Date"),
            React.createElement(Flatpickr, { value: selectedCallDate, className: 'input form-control', options: flatpickrCallDateSettings }),
            React.createElement(ErrorList, { errors: errors, field: 'call_date' })),
        React.createElement("div", { className: 'form-group date' },
            React.createElement("label", { className: willPayDateLabelClass }, "Will Pay Date"),
            React.createElement(Flatpickr, { value: selectedWillPayDate, className: 'input form-control', options: flatpickrWillPayDateSettings }),
            React.createElement(ErrorList, { errors: errors, field: 'will_pay_date' })),
        React.createElement("div", { className: 'action-buttons' },
            React.createElement("button", { type: 'submit', className: 'button -primary' }, "Save"))));
};
export default InvoiceDatesForm;
