import { fileNameFromUrl, replaceSpaces, snakeToCamelCase } from '../utils';
const defaultFileFieldNames = [
    'purchaseOrder',
    'proFormaInvoice',
    'transportDocument',
    'otherDocuments'
];
const defaultExistingFileFieldNames = defaultFileFieldNames.map(field => {
    return snakeToCamelCase(`existing_${field}`);
});
export function compileFileNames(excludedFieldNames, model, fileFieldNames = defaultFileFieldNames) {
    return fileFieldNames.reduce((list, key) => {
        if (model[key] && !excludedFieldNames.includes(key)) {
            const fileNames = model[key].map(file => {
                return replaceSpaces(file.name, '_');
            });
            return [...list, ...fileNames];
        }
        else {
            return [...list];
        }
    }, []);
}
export function compileExistingFileNames(model, existingFileFieldNames = defaultExistingFileFieldNames) {
    return existingFileFieldNames.reduce((list, key) => {
        if (model[key]) {
            const fileNames = model[key].map(file => {
                return fileNameFromUrl(file.url);
            });
            return [...list, ...fileNames];
        }
        else {
            return [...list];
        }
    }, []);
}
