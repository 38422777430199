import React from 'react';
import classNames from 'classnames';
const DownloadPanel = ({ abaPath, ids, abaDownloadStep, singleABA }) => {
    const downloadABAClass = classNames('content custom-panel', {
        '-lighten': !true
    });
    const downloadStep = abaDownloadStep === 2;
    return (React.createElement("div", { className: 'borrower-settings-container' },
        React.createElement("div", { className: downloadABAClass },
            React.createElement("div", { className: 'header' },
                !downloadStep && React.createElement("i", { className: 'custom-icon -circle -neutral-70' }),
                downloadStep && React.createElement("i", { className: 'custom-icon -circle -brand-400' }),
                "Download ABA"),
            downloadStep && (React.createElement("div", { className: 'body -center' },
                React.createElement("button", { className: 'icon-button -download-aba bottomspace' },
                    React.createElement("i", { className: 'icon' })),
                React.createElement("h2", { className: 'bottomspace' }, "Download Ready"),
                React.createElement("div", { className: 'action-buttons -center -mb-2 bottomspace' }, singleABA ? (React.createElement("a", { className: 'icon-button button -download-neutral-0 button -primary', "data-method": 'put', href: `${abaPath}/${ids}/download_aba`, "data-testid": 'download-panel-button' },
                    React.createElement("i", { className: 'icon' }),
                    " ",
                    React.createElement("div", null, "Download ABA File"))) : (React.createElement("a", { className: 'icon-button button -download-neutral-0 button -primary', "data-method": 'put', href: `${abaPath}/bulk_download_aba?ids=${ids}`, "data-testid": 'download-panel-button' },
                    React.createElement("i", { className: 'icon' }),
                    " ",
                    React.createElement("div", null, "Download ABA File")))),
                React.createElement("a", { href: `${abaPath}?status=approved`, className: 'bottomspace' }, "Go back to the previous page"))))));
};
export default DownloadPanel;
