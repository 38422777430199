import React, { useState } from 'react';
import { disable as defaultDisable, enable as defaultEnable } from '../../api/invoice_partial_payment';
import { showNotifyToast as defaultShowNotifyToast } from '../../utils';
import ToggleSwitch from '../shared/ToggleSwitch';
const InvoicePartialPaymentForm = ({ apiToken, borrowerId, disapprovePartPaidInvoice, enable = defaultEnable, disable = defaultDisable, showNotifyToast = defaultShowNotifyToast }) => {
    const errorMessage = 'Something went wrong. Please try again.';
    const [disapproveInvoiceStatus, setDisapproveInvoiceStatus] = useState(disapprovePartPaidInvoice);
    const enableDisapprovePartPaidInvoice = async () => {
        try {
            const response = await enable(apiToken, borrowerId);
            setDisapproveInvoiceStatus(response.disapprovePartPaidInvoice);
            if (response) {
                showNotifyToast({
                    text: 'Successfully enabled Automatically disapprove invoice with partial payments',
                    type: 'success'
                });
            }
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
    };
    const disableDisapprovePartPaidInvoice = async () => {
        try {
            const response = await disable(apiToken, borrowerId);
            setDisapproveInvoiceStatus(response.disapprovePartPaidInvoice);
            if (response) {
                showNotifyToast({
                    text: 'Successfully disabled Automatically disapprove invoice with partial payments',
                    type: 'success'
                });
            }
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
    };
    return (React.createElement("div", { "data-testid": 'invoice-partial-payment' },
        React.createElement("div", { className: 'form-group group -oneline' },
            React.createElement("label", { className: 'label' }, "Automatically disapprove invoice if partial payment received"),
            React.createElement(ToggleSwitch, { isToggled: disapproveInvoiceStatus, switchOn: () => enableDisapprovePartPaidInvoice(), switchOff: () => disableDisapprovePartPaidInvoice(), testId: 'toggle-disapprove-part-paid-invoice' })),
        React.createElement("p", { className: 'paragraph' }, "Invoices that receive payments that do not cover the full invoice amount due will be automatically disapproved if this setting is turned on.")));
};
export default InvoicePartialPaymentForm;
