import React from 'react';
import AttachmentGallery from '../../attachment-gallery/AttachmentGallery';
import PostBody from '../PostBody';
const BrokerWelcomePost = ({ event }) => {
    const { attributes } = event;
    const { createdAtFormatted, brokerCompanyName, eventable: { attributes: { body, attachments } } } = attributes;
    return (React.createElement("div", { className: 'event-box event', "data-testid": 'broker-welcome-post-activity' },
        React.createElement("div", { className: 'icon' },
            React.createElement("div", { className: 'activity-icon -add-post' })),
        React.createElement("div", { className: 'description' },
            React.createElement("div", { className: 'title', "data-testid": 'activity-title' },
                "Earlypay added a post for ",
                React.createElement("span", null,
                    " ",
                    brokerCompanyName,
                    " ")),
            React.createElement("div", { className: 'date' }, createdAtFormatted),
            React.createElement("div", { className: 'event-breakdown -post' },
                React.createElement("div", { className: 'info' },
                    React.createElement(PostBody, { isEmojiFormatted: true, bodyText: body })),
                React.createElement("div", { className: 'attachments' },
                    React.createElement(AttachmentGallery, { ...{ attachments } }))))));
};
export default BrokerWelcomePost;
