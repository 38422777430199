import React, { useState } from 'react';
import { humanizeString } from '../../utils';
const InvoiceFinanceTypeSelector = ({ disabled, invoiceFinanceTypes, manualLedgerTypes, selectedInvoiceFinanceType, selectedManualLedgerType }) => {
    const [currentInvoiceFinanceType, setCurrentInvoiceFinanceType] = useState(selectedInvoiceFinanceType);
    const handleInvoiceFinanceTypeChange = event => {
        setCurrentInvoiceFinanceType(event.target.value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: 'label -uppercase' }, "Invoice Finance Type"),
            React.createElement("div", { className: 'dropdown' },
                React.createElement("select", { disabled: disabled, className: 'input form-control', name: 'company[invoice_finance_type]', defaultValue: selectedInvoiceFinanceType, onChange: handleInvoiceFinanceTypeChange }, invoiceFinanceTypes.map((invoiceFinanceType, index) => (React.createElement("option", { key: index, value: invoiceFinanceType }, humanizeString(invoiceFinanceType))))))),
        currentInvoiceFinanceType === 'manual_ledger' && (React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: 'label -uppercase' }, "Manual Ledger Type"),
            React.createElement("div", { className: 'dropdown' },
                React.createElement("select", { disabled: disabled, className: 'input form-control', name: 'company[manual_ledger_type]', defaultValue: selectedManualLedgerType }, manualLedgerTypes.map((manualLedgerType, index) => (React.createElement("option", { key: index, value: manualLedgerType }, humanizeString(manualLedgerType))))))))));
};
export default InvoiceFinanceTypeSelector;
