import classNames from 'classnames';
import React from 'react';
import CustomFlatpickr from '../shared/CustomFlatpickr';
const Filters = (props) => {
    const { filters, currentFilterName, dateFilter, flatpickrSettings, forwardDateClearRef, forwardDatePickerRef, setCurrentFilters, setCurrentFilterName } = props;
    const itemClass = (filter) => classNames({
        item: true,
        '-active': currentFilterName === filter
    });
    const handleOnFilterChange = (filter) => {
        setCurrentFilters(filter.filters);
        setCurrentFilterName(filter.name);
    };
    return (React.createElement("div", { className: 'activity-filter filter', "data-testid": 'activity-feed-filter' },
        React.createElement("div", { className: 'title' }, "Date"),
        React.createElement(CustomFlatpickr, { ...{ flatpickrSettings, forwardDateClearRef, forwardDatePickerRef }, selectedDate: dateFilter, pickerBackground: 'white', placeholderText: 'Filter by date', clearButtonText: 'Clear Date' }),
        React.createElement("div", { className: 'title' }, " Activity "),
        filters.map((filter, i) => {
            return (React.createElement("div", { key: i, className: itemClass(filter.name), onClick: () => handleOnFilterChange(filter), "data-testid": `${filter.name}-filter` },
                React.createElement("div", { className: `icon -${filter.name}` }),
                React.createElement("span", { className: 'text' },
                    " ",
                    filter.name,
                    " "),
                React.createElement("div", { className: 'count' },
                    " ",
                    filter.count,
                    " ")));
        })));
};
export default Filters;
