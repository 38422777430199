import React from 'react';
const FormButtons = ({ steps, currentStep, cancelPath, handleBack }) => {
    const firstStep = steps[0];
    const lastStep = steps[steps.length - 1];
    const backButton = currentStep === firstStep ? (React.createElement("a", { href: cancelPath, className: 'button -danger -wide' }, "Cancel")) : (React.createElement("button", { className: 'button -danger -wide', type: 'button', onClick: handleBack }, "Back"));
    const nextButtonText = currentStep === lastStep ? 'Save' : 'Next';
    return (React.createElement("div", { className: 'action-buttons -space-between', "data-testid": 'form-buttons' },
        backButton,
        React.createElement("button", { className: 'button -primary -wide', type: 'submit', form: currentStep + '-form' }, nextButtonText)));
};
export default FormButtons;
