import React from 'react';
const ConfirmationPanel = ({ drawdowns, abaDownloadStep, setAbaDownloadStep }) => {
    const confirmationStep = abaDownloadStep === 1;
    return (React.createElement("div", { className: 'borrower-settings-container', "data-testid": 'confirmation-panel' },
        React.createElement("div", { className: 'content custom-panel' },
            React.createElement("div", { className: 'header' },
                confirmationStep && React.createElement("i", { className: 'custom-icon -circle -brand-400' }),
                !confirmationStep && React.createElement("i", { className: 'custom-icon -approve' }),
                "Confirmation"),
            confirmationStep && (React.createElement("div", { className: 'body', "data-testid": 'confirmation-panel-body' },
                React.createElement("div", { className: 'content -no-border-bottom' },
                    React.createElement("div", { className: 'section' }, "Downloading the drawdown's ABA file or including it in bulk ABA download will be considered as a processed transaction, and as a result will be removed from the table."),
                    React.createElement("div", { className: 'label -drawdown' }, "Drawdowns"),
                    drawdowns.map(drawdown => {
                        return (React.createElement("div", { className: 'drawdown', key: drawdown.id }, `#${drawdown.id} - ${drawdown.companyName}`));
                    })))),
            confirmationStep && (React.createElement("div", { className: 'action-buttons -border -padding', "data-testid": 'confirmation-panel-button' },
                React.createElement("a", { className: 'button -neutral', href: '/admin/drawdowns?status=approved' }, "Cancel"),
                React.createElement("button", { className: 'button -primary', "data-testid": 'confirm-download-aba', onClick: () => setAbaDownloadStep(2) }, "Confirm"))))));
};
export default ConfirmationPanel;
