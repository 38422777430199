import React from 'react';
import classNames from 'classnames';
const ABAIcon = ({ isDownloaded, path }) => {
    const abaClass = classNames('icon', {
        '-downloaded': isDownloaded
    });
    return (React.createElement("a", { className: 'icon-button -aba', href: path },
        React.createElement("div", { className: abaClass }, "A")));
};
export default ABAIcon;
