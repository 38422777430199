import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import ReserveAmountModal from './ReserveAmountModal';
import { convertToMoneyObject } from '../../utils/money';
import { hasReserveError, afterAmountInput, afterAmount, totalInputAmount, zeroReserveAndTotal, computeInputField } from '../../utils/reserveAmount';
const UseReserveAmount = ({ path, client, csrfToken }) => {
    const tooltipTheme = {
        backgroundColor: '#091e42',
        textColor: '#fff',
        border: true,
        borderRadius: 8,
        borderColor: '#c1c7d0'
    };
    const { feesOutstandingCents, loanOutstandingCents, interestOutstandingCents, reserveAmountCents, totalOutstandingCents } = client;
    const initializeMoney = convertToMoneyObject(0);
    const [isWriteOff, setIsWriteOff] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [afterReserve, setAfterReserve] = useState(initializeMoney);
    const [afterInterestOutstanding, setAfterInterestOutstanding] = useState(initializeMoney);
    const [afterFeesOutstanding, setAfterFeesOutstanding] = useState(initializeMoney);
    const [afterLoanOutstanding, setAfterLoanOutstanding] = useState(initializeMoney);
    const [afterTotalOutstanding, setAfterTotalOutstanding] = useState(initializeMoney);
    const reserveAmountMoney = convertToMoneyObject(reserveAmountCents, true);
    const totalOutstandingMoney = convertToMoneyObject(totalOutstandingCents, true);
    const interestOutstandingMoney = convertToMoneyObject(interestOutstandingCents, true);
    const feesOutstandingMoney = convertToMoneyObject(feesOutstandingCents, true);
    const loanOutstandingMoney = convertToMoneyObject(loanOutstandingCents, true);
    const initializeOutstanding = computeInputField({
        reserve: reserveAmountMoney,
        loan: loanOutstandingMoney,
        fees: feesOutstandingMoney,
        interest: interestOutstandingMoney,
        total: totalOutstandingMoney
    });
    const [currentLoanOutstanding, setCurrentLoanOutstanding] = useState({
        floatValue: initializeOutstanding.loanInput.toUnit(),
        value: initializeOutstanding.loanInput.toUnit().toString(),
        formattedValue: ''
    });
    const [currentFeesOutstanding, setCurrentFeesOutstanding] = useState({
        floatValue: initializeOutstanding.feesInput.toUnit(),
        value: initializeOutstanding.feesInput.toUnit().toString(),
        formattedValue: ''
    });
    const [currentInterestOutstanding, setCurrentInterestOutstanding] = useState({
        floatValue: initializeOutstanding.interestInput.toUnit(),
        value: initializeOutstanding.interestInput.toUnit().toString(),
        formattedValue: ''
    });
    useEffect(() => {
        const afterInterest = afterAmountInput(interestOutstandingMoney, currentInterestOutstanding.floatValue, isWriteOff);
        const afterFee = afterAmountInput(feesOutstandingMoney, currentFeesOutstanding.floatValue, isWriteOff);
        const afterLoan = afterAmountInput(loanOutstandingMoney, currentLoanOutstanding.floatValue, isWriteOff);
        const afterReserveTotal = afterAmount(reserveAmountMoney, sumInput);
        const afterTotal = afterAmountInput(totalOutstandingMoney, sumInput.toUnit(), isWriteOff);
        setAfterReserve(afterReserveTotal);
        setAfterInterestOutstanding(afterInterest);
        setAfterFeesOutstanding(afterFee);
        setAfterLoanOutstanding(afterLoan);
        setAfterTotalOutstanding(afterTotal);
    }, [
        isVisible,
        currentInterestOutstanding,
        currentInterestOutstanding,
        currentLoanOutstanding
    ]);
    const toggleModal = () => {
        isVisible ? setIsVisible(false) : setIsVisible(true);
    };
    const sumInput = totalInputAmount({
        fees: currentInterestOutstanding.floatValue,
        interest: currentFeesOutstanding.floatValue,
        loan: currentLoanOutstanding.floatValue
    });
    const outstandingError = hasReserveError({
        reserveAmountMoney: reserveAmountMoney.toUnit(),
        loan: currentLoanOutstanding.floatValue,
        fees: currentFeesOutstanding.floatValue,
        interest: currentInterestOutstanding.floatValue
    });
    const handleOutstandingInterestChange = (values) => {
        setCurrentInterestOutstanding(values);
    };
    const handleOutstandingFeesChange = (values) => {
        setCurrentFeesOutstanding(values);
    };
    const handlePrincipalOutstandingChange = (values) => {
        setCurrentLoanOutstanding(values);
    };
    const handleWriteOffChange = () => {
        setIsWriteOff(!isWriteOff);
    };
    const interestInputClass = classNames('input form-control', {
        '-error': interestOutstandingCents === 0 ? false : outstandingError
    });
    const feesInputClass = classNames('input form-control', {
        '-error': feesOutstandingCents === 0 ? false : outstandingError
    });
    const loanInputClass = classNames('input form-control', {
        '-error': loanOutstandingCents === 0 ? false : outstandingError
    });
    const submitClass = classNames('button -primary', {
        '-disabled': (outstandingError ||
            totalOutstandingCents === 0 ||
            sumInput.toUnit() === 0) &&
            !isWriteOff
    });
    const panelClass = classNames('custom-panel', {
        '-lighten': zeroReserveAndTotal(reserveAmountCents, totalOutstandingCents)
    });
    return (React.createElement("div", { className: panelClass, "data-testid": 'use-reserve-amount' },
        React.createElement("div", { className: 'header' }, "Use Reserve Amount"),
        React.createElement("div", { className: 'content' },
            !zeroReserveAndTotal(reserveAmountCents, totalOutstandingCents) && (React.createElement("div", { className: 'form-group' },
                React.createElement("div", { className: 'label -uppercase' }, "Remaining Credit Reserve"),
                React.createElement(NumberFormat, { value: afterAmount(reserveAmountMoney, sumInput).toUnit(), defaultValue: '-', displayType: 'text', decimalScale: 2, thousandSeparator: true, prefix: '$', className: 'label -large', "data-testid": 'remaining-reserve-amount' }))),
            zeroReserveAndTotal(reserveAmountCents, totalOutstandingCents) && (React.createElement("div", { className: 'reserve', "data-testid": 'label-zero-reserve-total' },
                React.createElement("div", null, "Total Credit Reserve Amount"),
                React.createElement("div", null, "Total Outstanding"))),
            zeroReserveAndTotal(reserveAmountCents, totalOutstandingCents) && (React.createElement("div", { className: 'reserve', "data-testid": 'value-zero-reserve-total' },
                React.createElement(NumberFormat, { value: reserveAmountCents, defaultValue: '-', displayType: 'text', decimalScale: 2, thousandSeparator: true, prefix: '$', "data-testid": 'fees-outstanding' }),
                React.createElement(NumberFormat, { value: totalOutstandingCents, defaultValue: '-', displayType: 'text', decimalScale: 2, thousandSeparator: true, prefix: '$', "data-testid": 'fees-outstanding' }))),
            React.createElement("hr", { className: 'custom-divider -my-6' }),
            React.createElement("div", { className: 'section' },
                React.createElement("div", { className: 'subtitle -large' }, "Pay-off Outstanding Amounts")),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label -uppercase' }, "Principal Outstanding"),
                React.createElement(NumberFormat, { thousandSeparator: true, decimalScale: 2, prefix: '$', value: currentLoanOutstanding.value, allowNegative: false, onValueChange: handlePrincipalOutstandingChange, className: loanInputClass, disabled: !(loanOutstandingCents > 0), isAllowed: values => {
                        const { floatValue } = values;
                        const value = floatValue || 0;
                        return value <= loanOutstandingMoney.toUnit();
                    }, "data-testid": 'principal-outstanding-amount' }),
                React.createElement("div", { className: 'footer ' },
                    React.createElement("div", null, "Principal Outstanding"),
                    React.createElement(NumberFormat, { value: loanOutstandingMoney.toUnit(), defaultValue: '-', displayType: 'text', decimalScale: 2, thousandSeparator: true, prefix: '$', "data-testid": 'principal-outstanding' }))),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label -uppercase' }, "Outstanding Fees"),
                React.createElement(NumberFormat, { thousandSeparator: true, decimalScale: 2, prefix: '$', value: currentFeesOutstanding.value, allowNegative: false, onValueChange: handleOutstandingFeesChange, className: feesInputClass, disabled: !(feesOutstandingCents > 0), isAllowed: values => {
                        const { floatValue } = values;
                        const value = floatValue || 0;
                        return value <= feesOutstandingMoney.toUnit();
                    }, "data-testid": 'fees-outstanding-amount' }),
                React.createElement("div", { className: 'footer' },
                    React.createElement("div", null, "Outstanding Fees"),
                    React.createElement(NumberFormat, { value: feesOutstandingMoney.toUnit(), defaultValue: '-', displayType: 'text', decimalScale: 2, thousandSeparator: true, prefix: '$', "data-testid": 'fees-outstanding' }))),
            React.createElement("div", { className: 'form-group -space-between' },
                React.createElement("label", { className: 'label -uppercase' }, "Outstanding Interest"),
                React.createElement(NumberFormat, { thousandSeparator: true, decimalScale: 2, prefix: '$', value: currentInterestOutstanding.value, allowNegative: false, onValueChange: handleOutstandingInterestChange, className: interestInputClass, disabled: !(interestOutstandingCents > 0), isAllowed: values => {
                        const { floatValue } = values;
                        const value = floatValue || 0;
                        return value <= interestOutstandingMoney.toUnit();
                    }, "data-testid": 'interest-outstanding-amount' }),
                React.createElement("div", { className: 'footer' },
                    React.createElement("div", null, "Outstanding Interest"),
                    React.createElement(NumberFormat, { value: interestOutstandingMoney.toUnit(), defaultValue: '-', displayType: 'text', decimalScale: 2, thousandSeparator: true, prefix: '$', "data-testid": 'interest-outstanding' }))),
            reserveAmountCents < totalOutstandingCents && (React.createElement("div", { className: 'form-group' },
                React.createElement("div", { className: 'label -checkbox' },
                    React.createElement("input", { type: 'checkbox', className: 'checkbox', name: `write_off[amount]`, checked: isWriteOff, disabled: zeroReserveAndTotal(reserveAmountCents, totalOutstandingCents), id: 'write-off-checkbox', onChange: handleWriteOffChange, "data-testid": 'write-off-checkbox' }),
                    ' ',
                    React.createElement("label", { htmlFor: 'write-off-checkbox' }, "Write off any remaining amounts"),
                    React.createElement("div", { "data-tip": true, "data-for": 'use-reserve-tooltip', className: 'custom-tooltip', "data-testid": 'tooltip' },
                        React.createElement("div", { className: 'tooltip custom-icon -notice -blue' },
                            React.createElement("div", { className: 'icon' }))),
                    React.createElement(ReactTooltip, { ...tooltipTheme, id: 'use-reserve-tooltip', place: 'right', effect: 'solid' },
                        React.createElement("div", { className: 'tooltip-content -medium' }, "Writing off remaining amounts will update any outstanding balances to 0."))))),
            outstandingError && (React.createElement("div", { className: 'form-group', "data-testid": 'reserve-error-message' },
                React.createElement("div", { className: 'warning' },
                    React.createElement("div", null,
                        React.createElement("i", { className: 'custom-icon -notice -red' })),
                    React.createElement("div", null, "The credit reserve amounts have stretched beyond it's limit. Try adjusting those outstanding amounts."))))),
        React.createElement("div", { className: 'action-buttons buttons space -border -padding' },
            React.createElement("button", { type: 'submit', className: submitClass, onClick: toggleModal, "data-testid": 'reserve-button-modal' }, "Pay Outstanding Amount")),
        React.createElement(ReserveAmountModal, { ...{
                isVisible,
                toggleModal,
                feesOutstandingMoney,
                loanOutstandingMoney,
                interestOutstandingMoney,
                reserveAmountMoney,
                totalOutstandingMoney,
                currentLoanOutstanding: convertToMoneyObject(currentLoanOutstanding.floatValue || 0),
                currentFeesOutstanding: convertToMoneyObject(currentFeesOutstanding.floatValue || 0),
                currentInterestOutstanding: convertToMoneyObject(currentInterestOutstanding.floatValue || 0),
                afterReserve,
                afterInterestOutstanding,
                afterFeesOutstanding,
                afterLoanOutstanding,
                afterTotalOutstanding,
                isWriteOff,
                path,
                csrfToken
            } })));
};
export default UseReserveAmount;
