import React, { useState, useEffect } from 'react';
import DrawdownTable from '../../components/drawdowns-table/DrawdownTable';
import { showNotifyToast } from '../../utils/notifyToast';
import { fetchDrawdownsPlatformEntry, setProcessedAtBank } from '../../api/drawdown';
const DrawdownPlatformEntryDashboard = ({ apiToken, tableName, path, abaPath }) => {
    const [drawdownArray, setDrawdownArray] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const fetchDrawdownsData = async () => {
        setIsLoading(true);
        try {
            const { data } = await fetchDrawdownsPlatformEntry(apiToken, currentPage);
            const { page, totalItems, totalPages, pageSize } = data.attributes;
            setDrawdownArray(page);
            setTotalPages(totalPages);
            setPageSize(pageSize);
            setTotalItems(totalItems);
            setIsLoading(false);
        }
        catch (err) {
            const error = err;
            showNotifyToast({
                text: error.message,
                type: 'error'
            });
        }
    };
    const processedDrawdown = async (id) => {
        try {
            await setProcessedAtBank(apiToken, id);
            showNotifyToast({
                text: 'Drawdown successfully processed!',
                type: 'success'
            });
            fetchDrawdownsData();
        }
        catch (err) {
            const error = err;
            showNotifyToast({
                text: error.message,
                type: 'error'
            });
        }
    };
    const sortDrawdown = (field, defaultSort) => {
        setSortBy(field);
        if (sortBy === field && sortDirection === 'asc') {
            setSortDirection('desc');
        }
        else if (sortBy === field && sortDirection === 'desc') {
            setSortDirection('asc');
        }
        else {
            setSortDirection(defaultSort || 'asc');
        }
    };
    useEffect(() => {
        fetchDrawdownsData();
    }, [currentPage]);
    const handlePageClick = data => {
        setCurrentPage(data.selected + 1);
    };
    return (React.createElement(DrawdownTable, { fetchDrawdowns: fetchDrawdownsData, showABA: false, ...{
            tableName,
            sortDrawdown,
            drawdownArray,
            totalPages,
            pageSize,
            totalItems,
            isLoading,
            handlePageClick,
            sortDirection,
            currentPage,
            sortBy,
            path,
            processedDrawdown,
            abaPath
        } }));
};
export default DrawdownPlatformEntryDashboard;
