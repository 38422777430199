import React, { useMemo, useState } from 'react';
import { updateDebtorDetails as defaultUpdateDebtorDetails } from '../../api/debtor';
import BonafideFormModal from '../../components/bonafide-form-modal/BonafideFormModal';
import ToggleSwitch from '../../components/shared/ToggleSwitch';
import { showNotifyToast } from '../../utils/notifyToast';
const DebtorBonafidePanel = ({ apiToken, debtor, industriesOptions, updateDebtorDetails = defaultUpdateDebtorDetails }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const [attribute, setAttribute] = useState('');
    const [attributeValue, setAttributeValue] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [debtorInfo, setDebtorInfo] = useState(debtor);
    const { name, postCode, state } = debtor;
    const debtorId = useMemo(() => {
        return debtor.id;
    }, [debtor]);
    const alaresId = useMemo(() => {
        return debtor.alaresId;
    }, [debtor]);
    const googleParams = useMemo(() => {
        const params = [name, postCode, state];
        return encodeURIComponent(params.filter(val => val).join(' ')).replace('%20', '+');
    }, [debtor]);
    const showEditModal = (field, value) => {
        setAttribute(field);
        setAttributeValue(value);
        setShowModal(true);
    };
    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            const response = await updateDebtorDetails(apiToken, debtorId, {
                [`${attribute}`]: attributeValue
            });
            if (response['data'])
                setDebtorInfo(response['data']['attributes']);
            if (response['errors']) {
                showNotifyToast({
                    text: response['errors'],
                    type: 'error'
                });
            }
            setShowModal(false);
        }
        catch (error) {
            if (error instanceof Error) {
                showNotifyToast({
                    text: error.message,
                    type: 'error'
                });
            }
        }
    };
    const toggleHandler = async (key, value) => {
        try {
            const response = await updateDebtorDetails(apiToken, debtorId, {
                [`${key}`]: value
            });
            if (response['data'])
                setDebtorInfo(response['data']['attributes']);
            if (response['errors']) {
                showNotifyToast({
                    text: response['errors'],
                    type: 'error'
                });
            }
        }
        catch (error) {
            if (error instanceof Error) {
                showNotifyToast({
                    text: error.message,
                    type: 'error'
                });
            }
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'custom-panel' },
            React.createElement("div", { className: 'header -space-between' },
                React.createElement("span", null, "Debtor Bonafide"),
                debtor.validated ? (React.createElement("a", { className: 'custom-button -outline -box-shadow', href: `/admin/debtors/${debtorId}/unvalidate` }, "Unvalidate")) : (React.createElement("a", { className: 'custom-button -outline -box-shadow', href: `/admin/debtors/${debtorId}/validate` }, "Validate"))),
            React.createElement("div", { className: 'content' },
                React.createElement("table", { className: 'custom-table bonafide-table' },
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", { className: 'cell -wrap -short' },
                                React.createElement("div", { className: 'text -bold' }, "ABR Validation")),
                            React.createElement("td", { className: 'value' },
                                React.createElement("a", { className: 'link', href: `/admin/debtors/${debtorId}/validate_abn` }, debtor.abrValidated ? 'Revalidate ABN' : 'Validate ABN'))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: 'cell -wrap -short' },
                                React.createElement("div", { className: 'text -bold' }, "Alares & NCI Alerts")),
                            React.createElement("td", { className: 'value' }, alaresId ? (React.createElement(React.Fragment, null,
                                alaresId,
                                "\u00A0",
                                React.createElement("a", { href: `/admin/debtors/${debtorId}/alares_unwatch` }, "(Unlink)"))) : (React.createElement("a", { href: `/admin/debtors/${debtorId}/alares_watch` }, "Link to Alares")))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: 'cell -wrap -short' },
                                React.createElement("div", { className: 'text -bold' }, "Nature of Business")),
                            React.createElement("td", { className: 'value hover-edit' },
                                React.createElement("p", { className: 'value' }, (_a = debtorInfo.businessNature) !== null && _a !== void 0 ? _a : 'N/A'),
                                React.createElement("a", { onClick: () => {
                                        var _a;
                                        showEditModal('business_nature', (_a = debtorInfo.businessNature) !== null && _a !== void 0 ? _a : '');
                                    }, className: 'action' }, "Edit"))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: 'cell -wrap -short' },
                                React.createElement("div", { className: 'text -bold' }, "Industry Type")),
                            React.createElement("td", { className: 'value hover-edit' },
                                React.createElement("p", { className: 'value' }, (_c = (_b = debtorInfo.industry) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : 'N/A'),
                                React.createElement("a", { onClick: () => {
                                        var _a, _b;
                                        showEditModal('industry_type', (_b = (_a = debtorInfo.industry) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '');
                                    }, className: 'action' }, "Edit"))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: 'cell -wrap -short' },
                                React.createElement("div", { className: 'text -bold' }, "Reason for Request"),
                                React.createElement("small", null, "Why is this RA approval being requested?")),
                            React.createElement("td", { className: 'value hover-edit' },
                                React.createElement("p", { className: 'value' }, (_d = debtorInfo.requestReason) !== null && _d !== void 0 ? _d : 'N/A'),
                                React.createElement("a", { onClick: () => {
                                        var _a;
                                        showEditModal('request_reason', (_a = debtorInfo.requestReason) !== null && _a !== void 0 ? _a : '');
                                    }, className: 'action' }, "Edit"))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: 'cell -wrap -short' },
                                React.createElement("div", { className: 'text -bold' }, "Goods & Services"),
                                React.createElement("small", null, "What goods & services does our clients (see connected debtors) provide to the debtor?")),
                            React.createElement("td", { className: 'value hover-edit' },
                                React.createElement("p", { className: 'value' }, (_e = debtorInfo.servicesOffered) !== null && _e !== void 0 ? _e : 'N/A'),
                                React.createElement("a", { onClick: () => {
                                        var _a;
                                        showEditModal('services_offered', (_a = debtorInfo.servicesOffered) !== null && _a !== void 0 ? _a : '');
                                    }, className: 'action' }, "Edit"))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: 'cell -wrap -short' },
                                React.createElement("div", { className: 'text -bold' }, "Online and Social Media"),
                                React.createElement("small", null, "Have you found any adverse information pertaining to the debtor, the director(s) or shareholder(s)"),
                                React.createElement("a", { target: '_blank', href: `https://www.google.com/search?q=${googleParams}`, rel: 'noreferrer' }, "\u00A0Google Search")),
                            React.createElement("td", { className: 'value' },
                                React.createElement(ToggleSwitch, { isToggled: debtorInfo.haveOnlinePresence, switchOn: () => toggleHandler('have_online_presence', true), switchOff: () => toggleHandler('have_online_presence', false) }))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: 'cell -wrap -short' },
                                React.createElement("div", { className: 'text -bold' }, "Business Email")),
                            React.createElement("td", { className: 'value hover-edit' },
                                React.createElement("p", { className: 'value' }, (_f = debtorInfo.businessEmail) !== null && _f !== void 0 ? _f : 'N/A'),
                                React.createElement("a", { onClick: () => {
                                        var _a;
                                        showEditModal('business_email', (_a = debtorInfo.businessEmail) !== null && _a !== void 0 ? _a : '');
                                    }, className: 'action' }, "Edit"))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: 'cell -wrap -short' },
                                React.createElement("div", { className: 'text -bold' }, "Phone Number"),
                                React.createElement("small", null, "Does the phone number connect? Who answers the phone? Can we confirm the phone number via a Google search?"),
                                React.createElement("a", { target: '_blank', href: `https://www.google.com/maps/search/${googleParams}`, rel: 'noreferrer' }, "\u00A0Maps Search")),
                            React.createElement("td", { className: 'value hover-edit' },
                                React.createElement("p", { className: 'value' }, (_g = debtorInfo.phoneNumber) !== null && _g !== void 0 ? _g : 'N/A'),
                                React.createElement("a", { onClick: () => {
                                        var _a;
                                        showEditModal('phone_number', (_a = debtorInfo.phoneNumber) !== null && _a !== void 0 ? _a : '');
                                    }, className: 'action' }, "Edit"))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: 'cell -wrap -short' },
                                React.createElement("div", { className: 'text -bold' }, "Website URL"),
                                React.createElement("small", null, "What google search or website confirms that this appears to be a legitimate business?"),
                                React.createElement("a", { target: '_blank', href: `https://www.google.com/search?q=${googleParams}`, rel: 'noreferrer' }, "\u00A0Google Search")),
                            React.createElement("td", { className: 'value hover-edit' },
                                React.createElement("p", { className: 'value' }, (_h = debtorInfo.websiteUrl) !== null && _h !== void 0 ? _h : 'N/A'),
                                React.createElement("a", { onClick: () => {
                                        var _a;
                                        showEditModal('website_url', (_a = debtorInfo.websiteUrl) !== null && _a !== void 0 ? _a : '');
                                    }, className: 'action' }, "Edit"))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: 'cell -wrap -short' },
                                React.createElement("div", { className: 'text -bold' }, "Google Maps"),
                                React.createElement("small", null, "Does Google Maps Search confirm that the Debtor may be out of trading out of a residential address? If yes, explain why the Debtor is trading out of a residential address?"),
                                React.createElement("a", { target: '_blank', href: `https://www.google.com/maps/search/${googleParams}`, rel: 'noreferrer' }, "\u00A0Maps Search")),
                            React.createElement("td", { className: 'value' },
                                React.createElement(ToggleSwitch, { isToggled: debtorInfo.isGmapSearchable, switchOn: () => toggleHandler('is_gmap_searchable', true), switchOff: () => toggleHandler('is_gmap_searchable', false) }))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: 'cell -wrap -short' },
                                React.createElement("div", { className: 'text -bold' }, "Equifax Search"),
                                React.createElement("small", null, "Adverse? (Defaults, Judgements, Court Actions, Current or Prior Administrations associated with Director(s)) Is Credit File well established with multiple credit enquiries and PPSR registrations?")),
                            React.createElement("td", { className: 'value' },
                                React.createElement(ToggleSwitch, { isToggled: debtorInfo.isEquifaxSearchable, switchOn: () => toggleHandler('is_equifax_searchable', true), switchOff: () => toggleHandler('is_equifax_searchable', false) }))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: 'cell -wrap -short' },
                                React.createElement("div", { className: 'text -bold' }, "Government Debtor")),
                            React.createElement("td", { className: 'value' },
                                React.createElement(ToggleSwitch, { isToggled: debtorInfo.isGovernmentDebtor, switchOn: () => toggleHandler('is_government_debtor', true), switchOff: () => toggleHandler('is_government_debtor', false) }))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: 'cell -wrap -short' },
                                React.createElement("div", { className: 'text -bold' }, "Major Corporation Debtor")),
                            React.createElement("td", { className: 'value' },
                                React.createElement(ToggleSwitch, { isToggled: debtorInfo.isMajorCorporationDebtor, switchOn: () => toggleHandler('is_major_corporation_debtor', true), switchOff: () => toggleHandler('is_major_corporation_debtor', false) }))))))),
        React.createElement(BonafideFormModal, { ...{
                attribute,
                attributeValue,
                showModal,
                submitHandler,
                setShowModal,
                setAttributeValue,
                industriesOptions
            } })));
};
export default DebtorBonafidePanel;
