import React from 'react';
import { isCustomerEvent, isCreditNoteAllocationEvent, isDrawdownEvent, isInvoiceEvent, isPostEvent, isReferralEvent, isRepaymentEvent, isXeroPaymentEvent } from '../../../types';
import AddGlobalPost from './activities/AddGlobalPost';
import AddPost from './activities/AddPost';
import AmendInvoiceAmountDue from './activities/AmendInvoiceAmountDue';
import AmendInvoiceDueDate from './activities/AmendInvoiceDueDate';
import ApproveCustomerByAdmin from './activities/ApproveCustomerByAdmin';
import ApproveDrawdown from './activities/ApproveDrawdown';
import UndoDrawdown from './activities/UndoDrawdown';
import BrokerPost from './activities/BrokerPost';
import ClientAcceptInvitation from './activities/ClientAcceptInvitation';
import CommentOnCustomer from './activities/CommentOnCustomer';
import CommentOnInvoice from './activities/CommentOnInvoice';
import InvoiceMarkAsEligible from './activities/InvoiceMarkAsEligible';
import InvoiceMarkAsIneligible from './activities/InvoiceMarkAsIneligible';
import NewCreditNote from './activities/NewCreditNote';
import NewInvoice from './activities/NewInvoice';
import NewXeroPayment from './activities/NewXeroPayment';
import OverdueTaskInvoice from './activities/OverdueTaskInvoice';
import PastIssueDateInvoice from './activities/PastIssueDateInvoice';
import PayInvoice from './activities/PayInvoice';
import ProcessRepayment from './activities/ProcessRepayment';
import RequestDrawdown from './activities/RequestDrawdown';
import UnapproveCustomerByAdmin from './activities/UnapproveCustomerByAdmin';
import VoidInvoice from './activities/VoidInvoice';
import WelcomePost from './activities/WelcomePost';
import BrokerWelcomePost from './activities/BrokerWelcomePost';
import ApproveInvoice from './activities/ApproveInvoice';
import UnapproveInvoice from './activities/UnapproveInvoice';
import CustomerMarkedAsPendingByAdmin from './activities/CustomerMarkedAsPendingByAdmin';
const Action = ({ event }) => {
    const { attributes } = event;
    const { action } = attributes;
    switch (action) {
        case 'request_drawdown':
            if (isDrawdownEvent(event)) {
                return React.createElement(RequestDrawdown, { event: event });
            }
            break;
        case 'approve_drawdown':
            if (isDrawdownEvent(event)) {
                return React.createElement(ApproveDrawdown, { event: event });
            }
            break;
        case 'undo_drawdown':
            if (isDrawdownEvent(event)) {
                return React.createElement(UndoDrawdown, { event: event });
            }
            break;
        case 'process_repayment':
            if (isRepaymentEvent(event)) {
                return React.createElement(ProcessRepayment, { event: event });
            }
            break;
        case 'new_invoice':
            if (isInvoiceEvent(event)) {
                return React.createElement(NewInvoice, { event: event });
            }
            break;
        case 'pay_invoice':
            if (isInvoiceEvent(event)) {
                return React.createElement(PayInvoice, { event: event });
            }
            break;
        case 'amend_invoice_due_date':
            if (isInvoiceEvent(event)) {
                return React.createElement(AmendInvoiceDueDate, { event: event });
            }
            break;
        case 'amend_invoice_amount_due': {
            if (isInvoiceEvent(event)) {
                return React.createElement(AmendInvoiceAmountDue, { event: event });
            }
            break;
        }
        case 'void_invoice': {
            if (isInvoiceEvent(event)) {
                return React.createElement(VoidInvoice, { event: event });
            }
            break;
        }
        case 'amend_invoice_by_overdue_task':
            if (isInvoiceEvent(event)) {
                return React.createElement(OverdueTaskInvoice, { event: event });
            }
            break;
        case 'amend_invoice_by_past_issue_date_task':
            if (isInvoiceEvent(event)) {
                return React.createElement(PastIssueDateInvoice, { event: event });
            }
            break;
        case 'invoice_marked_as_eligible':
            if (isInvoiceEvent(event)) {
                return React.createElement(InvoiceMarkAsEligible, { event: event });
            }
            break;
        case 'invoice_marked_as_ineligible':
            if (isInvoiceEvent(event)) {
                return React.createElement(InvoiceMarkAsIneligible, { event: event });
            }
            break;
        case 'unapprove_customer_by_admin':
            if (isCustomerEvent(event)) {
                return React.createElement(UnapproveCustomerByAdmin, { event: event });
            }
            break;
        case 'approve_customer_by_admin':
            if (isCustomerEvent(event)) {
                return React.createElement(ApproveCustomerByAdmin, { event: event });
            }
            break;
        case 'customer_marked_as_pending_by_admin':
            if (isCustomerEvent(event)) {
                return React.createElement(CustomerMarkedAsPendingByAdmin, { event: event });
            }
            break;
        case 'comment_on_customer':
            if (isCustomerEvent(event)) {
                return React.createElement(CommentOnCustomer, { event: event });
            }
            break;
        case 'comment_on_invoice':
            if (isInvoiceEvent(event)) {
                return React.createElement(CommentOnInvoice, { event: event });
            }
            break;
        case 'add_post':
            if (isPostEvent(event)) {
                return React.createElement(AddPost, { event: event });
            }
            break;
        case 'add_global_post':
            if (isPostEvent(event)) {
                return React.createElement(AddGlobalPost, { event: event });
            }
            break;
        case 'new_credit_note':
            if (isCreditNoteAllocationEvent(event)) {
                return React.createElement(NewCreditNote, { event: event });
            }
            break;
        case 'new_xero_payment':
            if (isXeroPaymentEvent(event)) {
                return React.createElement(NewXeroPayment, { event: event });
            }
            break;
        case 'client_accept_invitation':
            if (isReferralEvent(event)) {
                return React.createElement(ClientAcceptInvitation, { event: event });
            }
            break;
        case 'welcome_post':
            if (isPostEvent(event)) {
                return React.createElement(WelcomePost, { event: event });
            }
            break;
        case 'broker_post':
            if (isPostEvent(event)) {
                return React.createElement(BrokerPost, { event: event });
            }
            break;
        case 'broker_welcome_post':
            if (isPostEvent(event)) {
                return React.createElement(BrokerWelcomePost, { event: event });
            }
            break;
        case 'approve_invoice':
            if (isInvoiceEvent(event)) {
                return React.createElement(ApproveInvoice, { event: event });
            }
            break;
        case 'unapprove_invoice':
            if (isInvoiceEvent(event)) {
                return React.createElement(UnapproveInvoice, { event: event });
            }
            break;
    }
    return null;
};
export default Action;
