import React from 'react';
import { formatPrice } from '../../utils/formatPrice';
const InvoicesTooltip = ({ classVariant, currency, title, invoices, total, style }) => {
    return (React.createElement("div", { className: `tooltip tooltip-panel ${classVariant}`, "data-testid": 'invoices-tooltip', style: style },
        React.createElement("div", { className: 'title' }, title),
        invoices.map(invoice => {
            const amount = invoice.amountDueCents / 100;
            return (React.createElement("div", { className: 'row', key: invoice.id },
                React.createElement("div", { className: 'number' }, invoice.number),
                React.createElement("div", { className: 'customer' }, invoice.customerName),
                React.createElement("div", { className: 'amount' }, formatPrice(amount))));
        }),
        React.createElement("div", { className: 'row -total' },
            React.createElement("div", { className: 'description' }, "Total"),
            React.createElement("div", { className: 'amount', "data-currency": currency }, formatPrice(total)))));
};
export default InvoicesTooltip;
