import React, { useState } from 'react';
import AddressList from '../../components/addresses/AddressList';
import AddressForm from '../../components/addresses/AddressForm';
const Addresses = ({ fetchAddress, setErrorList, setAction, setAddress, addresses, path, isLoading, disableCreate, disableEdit, action, regions, address, currentPrimaryAddress, csrfToken, errors }) => {
    const [isChangingPrimaryAddress, setIsChangingPrimaryAddress] = useState(false);
    const [selectedPrimaryAddress, setSelectedPrimaryAddress] = useState(currentPrimaryAddress);
    const handleEditButton = async (id) => {
        fetchAddress(id);
        setErrorList({});
    };
    const handleNewButton = () => {
        setAction('new');
        setAddress({});
        setErrorList({});
    };
    return (React.createElement("div", { "data-testid": 'addresses-container' },
        React.createElement(AddressList, { ...{
                setAction,
                addresses,
                handleEditButton,
                path,
                currentPrimaryAddress,
                isChangingPrimaryAddress,
                setIsChangingPrimaryAddress,
                selectedPrimaryAddress,
                setSelectedPrimaryAddress
            } }),
        !isLoading && (!disableCreate || !disableEdit) && (React.createElement(AddressForm, { ...{
                action,
                setAction,
                regions,
                address,
                path,
                csrfToken,
                handleNewButton,
                errors
            } }))));
};
export default Addresses;
