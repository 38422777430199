import React from 'react';
import CompanyName from '../CompanyName';
const RequestDrawdown = ({ event }) => {
    const { attributes } = event;
    const { companyName, companyLink, createdAtFormatted, eventable: { attributes: { amount } } } = attributes;
    return (React.createElement("div", { className: 'event-box event', "data-testid": 'request-drawdown-activity' },
        React.createElement("div", { className: 'icon' },
            React.createElement("div", { className: 'activity-icon -request-drawdown' })),
        React.createElement("div", { className: 'description' },
            React.createElement("div", { className: 'title' },
                React.createElement(CompanyName, { name: companyName, companyLink: companyLink }),
                " has requested a",
                React.createElement("span", { className: 'amount -green' },
                    " ",
                    amount,
                    " "),
                React.createElement("span", { className: 'bold' }, " drawdown ")),
            React.createElement("div", { className: 'date' },
                " ",
                createdAtFormatted,
                " "))));
};
export default RequestDrawdown;
