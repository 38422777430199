import React from 'react';
import classNames from 'classnames';
import ErrorList from '../shared/ErrorList';
const Input = ({ errors = {}, defaultValue = '', name, inputType = 'text', isDisabled = false, labelText, maxLength, required = false, size }) => {
    const labelClass = classNames('label', {
        '-required': required === true
    });
    return (React.createElement("div", { className: 'form-group', "data-testid": 'input' },
        React.createElement("label", { className: labelClass, htmlFor: `user_${name}` }, labelText),
        React.createElement("input", { className: 'input form-control', disabled: isDisabled, type: inputType, maxLength: maxLength || undefined, size: size || undefined, defaultValue: defaultValue, name: `user[${name}]`, id: `user_${name}`, autoComplete: 'new-password' }),
        React.createElement(ErrorList, { errors: errors, field: name })));
};
export default Input;
