import React from 'react';
import classNames from 'classnames';
const PaymentCard = ({ payment, handleTogglePayment }) => {
    const paymentCardClass = classNames('manual-ledger-payment-card', '-cursor-pointer', {
        '-selected': payment.isSelected
    });
    return (React.createElement("div", { className: paymentCardClass, onClick: () => handleTogglePayment(payment) },
        React.createElement("div", { className: 'details' },
            (payment === null || payment === void 0 ? void 0 : payment.isSelected) && (React.createElement("input", { key: payment.id, type: 'hidden', name: 'uploaded_ledger[selected_payments_ids][]', value: payment.id })),
            React.createElement("div", { className: 'amount' },
                " ",
                payment.amount,
                " "),
            React.createElement("div", { className: 'date' },
                " ",
                payment.dateFormatted,
                " ")),
        React.createElement("div", { className: 'text' }, payment.text)));
};
export default PaymentCard;
