import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Modal from '../shared/Modal';
const BonafideFormModal = ({ attribute, attributeValue, showModal, submitHandler, setShowModal, setAttributeValue, industriesOptions }) => {
    const [industry, setIndustry] = useState();
    const toggleModal = () => {
        setShowModal(!showModal);
    };
    const handleChange = ({ target: { value } }) => {
        setAttributeValue(value);
    };
    const handleSelectChange = (selectedOption) => {
        setIndustry(selectedOption);
        setAttributeValue(selectedOption.value);
    };
    useEffect(() => {
        if (attribute === 'industry_type') {
            const option = industriesOptions.find(opt => {
                return opt.value === attributeValue;
            });
            setIndustry(option);
        }
    }, [attributeValue]);
    return (React.createElement(Modal, { isOpen: showModal, onClose: toggleModal },
        React.createElement("form", { className: 'form', onSubmit: submitHandler },
            React.createElement("div", { className: 'custom-panel -shadow-none -no-margin' },
                React.createElement("div", { className: 'form-group content -no-margin' },
                    React.createElement("label", { htmlFor: 'lineTwo', className: 'label' },
                        React.createElement("h2", null,
                            "EDIT ",
                            attribute.replace('_', ' ').toUpperCase())),
                    attribute === 'industry_type' ? (React.createElement(Select, { onChange: handleSelectChange, options: industriesOptions, value: industry })) : (React.createElement("input", { type: 'text', defaultValue: attributeValue, className: 'input form-control', onChange: handleChange })))),
            React.createElement("div", { className: `action-buttons buttons space -border -padding` },
                React.createElement("button", { className: 'button -neutral', type: 'button', onClick: toggleModal }, "Close"),
                React.createElement("input", { type: 'submit', className: 'button -primary -wide', name: 'commit', value: 'Save', "data-disable-with": 'Updating...' })))));
};
export default BonafideFormModal;
