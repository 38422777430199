import React, { useReducer, useState } from 'react';
import { updateSupplierDetails as defaultUpdateSupplierDetails, createSupplierDetails as defaultCreateSupplierDetails, removeSupplierDetails as defaultRemoveSupplierDetails, removeSupplierDetailsFile as defaultRemoveSupplierDetailsFile } from '../../api/trade_loan';
import { isTradeLoanSupplierAPIErrors, isAPIErrors, isAPIValidationErrors } from '../../../types';
import { isEmptyObject, getBracketValues, removeDuplicateFiles, snakeToCamelCase } from '../../utils';
import { compileExistingFileNames } from '../../utils/tradeLoanFiles';
import { showNotifyToast as defaultShowNotifyToast } from '../../utils/notifyToast';
import { validateFileSize, validateTradeLoanDuplicateFile } from '../../validations/file';
import SupplierDetailsReducer from '../../reducers/trade-loans/SupplierDetailsReducer';
import SupplierEditPanel from '../../components/trade-loan/SupplierEditPanel';
const SuppliersEditDashboard = ({ apiToken, tradeLoanId, urlPath, initialSuppliers, initialPaymentDetails, updateSupplierDetails = defaultUpdateSupplierDetails, createSupplierDetails = defaultCreateSupplierDetails, removeSupplierDetails = defaultRemoveSupplierDetails, removeSupplierDetailsFile = defaultRemoveSupplierDetailsFile, showNotifyToast = defaultShowNotifyToast }) => {
    const initialState = {
        suppliers: initialSuppliers,
        selectedSupplierOptions: Array(initialSuppliers.length).fill(null),
        selectedCurrencyOptions: Array(initialSuppliers.length).fill(null),
        paymentDetailsList: initialPaymentDetails,
        supplierDetailsErrors: Array(initialSuppliers.length).fill({}),
        paymentDetailsErrors: Array(initialPaymentDetails.length).fill({}),
        invoices: [],
        selectedInvoiceCurrencyOptions: [],
        invoiceAmountValues: [],
        invoiceErrors: [],
        invoiceDocumentErrors: [],
        supplierDocumentErrors: Array(initialSuppliers.length).fill({})
    };
    const [suppliersState, dispatch] = useReducer(SupplierDetailsReducer, initialState);
    const { suppliers, paymentDetailsList, supplierDetailsErrors, supplierDocumentErrors, paymentDetailsErrors } = suppliersState;
    const [savingStatuses, setSavingStatuses] = useState(Array(initialSuppliers.length).fill(false));
    const handleSupplierDetailUpdate = (event) => {
        const targetName = event.target['name'];
        const [index, fieldName] = getBracketValues(targetName);
        const value = event.target['value'];
        dispatch({
            type: 'UPDATE_SUPPLIER_FIELD',
            index: parseInt(index),
            fieldName: snakeToCamelCase(fieldName),
            value
        });
    };
    const handlePaymentDetailUpdate = (event) => {
        const targetName = event.target['name'];
        const [index, fieldName] = getBracketValues(targetName);
        const value = event.target['value'];
        dispatch({
            type: 'UPDATE_PAYMENT_DETAILS_FIELD',
            index: parseInt(index),
            fieldName: snakeToCamelCase(fieldName),
            value
        });
    };
    const handleConfirmPaymentDetailsToggle = (event) => {
        const targetName = event.target['name'];
        const [index, fieldName] = getBracketValues(targetName);
        const value = event.target.checked;
        dispatch({
            type: 'UPDATE_PAYMENT_DETAILS_FIELD',
            index: parseInt(index),
            fieldName: snakeToCamelCase(fieldName),
            value
        });
    };
    const updateSavingStatus = (index, value) => {
        setSavingStatuses([
            ...savingStatuses.slice(0, index),
            value,
            ...savingStatuses.slice(index + 1, savingStatuses.length)
        ]);
    };
    const generateFileErrors = (file, index) => {
        const fileSizeValidations = validateFileSize(file, 20000000)
            ? []
            : [`The file needs to be less than 20MB.`];
        const supplier = suppliers[index];
        const fileFieldNames = ['attachments'];
        const existingFileFieldNames = fileFieldNames.map(field => {
            return `existing_${field}`;
        });
        const existingFileNameList = compileExistingFileNames(supplier, existingFileFieldNames);
        const existingFileValidations = validateTradeLoanDuplicateFile(file, existingFileNameList)
            ? []
            : [`The file already exists.`];
        const validations = [...fileSizeValidations, ...existingFileValidations];
        return validations;
    };
    const totalErrors = (files, index) => {
        return files.reduce((acc, file) => {
            if (file) {
                const errors = generateFileErrors(file, index);
                if (errors.length > 0) {
                    return { ...acc, [file.name]: errors };
                }
                else {
                    return acc;
                }
            }
            else {
                return acc;
            }
        }, {});
    };
    const validateSupplierDocumentsFrontend = (files, index, fieldName) => {
        dispatch({
            type: 'UPDATE_SUPPLIER_DOCUMENT_ERRORS',
            index,
            fieldName,
            errors: totalErrors(files, index)
        });
    };
    const saveSupplierDetails = async (index) => {
        const documentErrors = supplierDocumentErrors[index];
        updateSavingStatus(index, true);
        if (isEmptyObject(documentErrors)) {
            const supplier = suppliers[index];
            const body = {
                supplierDetails: supplier,
                paymentDetails: paymentDetailsList[index]
            };
            if (!supplier.id) {
                throw new Error('Not allowed to update a supplier without a supplier id.');
            }
            try {
                const response = await updateSupplierDetails(apiToken, tradeLoanId, supplier.id, body, urlPath);
                if (isAPIErrors(response)) {
                    throw response.errors;
                }
                if (isTradeLoanSupplierAPIErrors(response)) {
                    dispatch({
                        type: 'UPDATE_SINGLE_SUPPLIER_ERRORS',
                        index: index,
                        errors: response.supplierErrors
                    });
                    dispatch({
                        type: 'UPDATE_SINGLE_PAYMENT_DETAILS_ERRORS',
                        index: index,
                        errors: response.paymentDetailsErrors
                    });
                    showNotifyToast({
                        text: 'Please check if all fields are filled out correctly.',
                        type: 'error'
                    });
                }
                else {
                    // clear previous errors
                    dispatch({
                        type: 'UPDATE_SINGLE_SUPPLIER_ERRORS',
                        index: index,
                        errors: {}
                    });
                    dispatch({
                        type: 'UPDATE_SINGLE_PAYMENT_DETAILS_ERRORS',
                        index: index,
                        errors: {}
                    });
                    dispatch({
                        type: 'REFRESH_SUPPLIER',
                        index: index,
                        supplierDetails: response.supplier
                    });
                    dispatch({
                        type: 'REFRESH_PAYMENT_DETAILS',
                        index: index,
                        paymentDetails: response.paymentDetails
                    });
                    showNotifyToast({
                        text: 'Successfully updated supplier.',
                        type: 'success'
                    });
                }
            }
            catch {
                showNotifyToast({
                    text: 'Failed to update supplier. Please try again.',
                    type: 'error'
                });
            }
        }
        else {
            showNotifyToast({
                text: 'Please check if selected files are valid.',
                type: 'error'
            });
        }
        updateSavingStatus(index, false);
    };
    const createNewSupplierDetails = async (index) => {
        const documentErrors = supplierDocumentErrors[index];
        updateSavingStatus(index, true);
        if (isEmptyObject(documentErrors)) {
            const supplier = suppliers[index];
            const body = {
                supplierDetails: supplier,
                paymentDetails: paymentDetailsList[index]
            };
            try {
                const response = await createSupplierDetails(apiToken, tradeLoanId, body, urlPath);
                if (isAPIErrors(response)) {
                    throw response.errors;
                }
                if (isTradeLoanSupplierAPIErrors(response)) {
                    dispatch({
                        type: 'UPDATE_SINGLE_SUPPLIER_ERRORS',
                        index: index,
                        errors: response.supplierErrors
                    });
                    dispatch({
                        type: 'UPDATE_SINGLE_PAYMENT_DETAILS_ERRORS',
                        index: index,
                        errors: response.paymentDetailsErrors
                    });
                    showNotifyToast({
                        text: 'Please check if all fields are filled out correctly.',
                        type: 'error'
                    });
                }
                else {
                    // clear previous errors
                    dispatch({
                        type: 'UPDATE_SINGLE_SUPPLIER_ERRORS',
                        index: index,
                        errors: {}
                    });
                    dispatch({
                        type: 'UPDATE_SINGLE_PAYMENT_DETAILS_ERRORS',
                        index: index,
                        errors: {}
                    });
                    dispatch({
                        type: 'REFRESH_SUPPLIER',
                        index: index,
                        supplierDetails: response.supplier
                    });
                    dispatch({
                        type: 'REFRESH_PAYMENT_DETAILS',
                        index: index,
                        paymentDetails: response.paymentDetails
                    });
                    showNotifyToast({
                        text: 'Successfully created supplier.',
                        type: 'success'
                    });
                }
            }
            catch {
                showNotifyToast({
                    text: 'Failed to create supplier. Please try again.',
                    type: 'error'
                });
            }
        }
        else {
            showNotifyToast({
                text: 'Please check if selected files are valid.',
                type: 'error'
            });
        }
        updateSavingStatus(index, false);
    };
    const removeSupplier = async (index) => {
        const supplier = suppliers[index];
        if (supplier.id) {
            if (window.confirm('Are you sure you want to delete this existing supplier?')) {
                try {
                    const response = await removeSupplierDetails(apiToken, tradeLoanId, supplier.id, urlPath);
                    if (isAPIErrors(response)) {
                        throw response.errors;
                    }
                    showNotifyToast({
                        text: 'Successfully deleted supplier.',
                        type: 'success'
                    });
                    dispatch({ type: 'REMOVE_SUPPLIER', index: index });
                }
                catch {
                    showNotifyToast({
                        text: 'Failed to delete supplier. Please try again.',
                        type: 'error'
                    });
                }
            }
        }
        else {
            dispatch({ type: 'REMOVE_SUPPLIER', index: index });
        }
    };
    const handleSupplierFileChange = (acceptedFiles, targetName) => {
        const [index, fieldName] = getBracketValues(targetName);
        const newFiles = [...acceptedFiles];
        const currentFiles = suppliers[index][fieldName] || [];
        const allFiles = removeDuplicateFiles([
            ...currentFiles,
            ...newFiles
        ]);
        dispatch({
            type: 'UPDATE_SUPPLIER_FILES',
            index: parseInt(index),
            fieldName,
            files: allFiles
        });
        validateSupplierDocumentsFrontend(allFiles, parseInt(index), fieldName);
    };
    const handleRemoveSupplierFile = (event) => {
        const targetName = event.target['name'];
        const [index, fieldName, fileIndex] = getBracketValues(targetName);
        const fieldFiles = suppliers[index][fieldName];
        const updatedFiles = [
            ...fieldFiles.slice(0, parseInt(fileIndex)),
            ...fieldFiles.slice(parseInt(fileIndex) + 1, fieldFiles.length)
        ];
        dispatch({
            type: 'UPDATE_SUPPLIER_FILES',
            index: parseInt(index),
            fieldName,
            files: updatedFiles
        });
        validateSupplierDocumentsFrontend(updatedFiles, parseInt(index), fieldName);
    };
    const handleSupplierFileReplace = (event) => {
        const targetName = event.target['name'];
        const [index, fieldName, fileIndex] = getBracketValues(targetName);
        const files = event.target.files;
        const newFiles = [...files];
        const currentFiles = suppliers[index][fieldName] || [];
        const updatedFiles = removeDuplicateFiles([
            ...currentFiles.slice(0, parseInt(fileIndex)),
            ...newFiles,
            ...currentFiles.slice(parseInt(fileIndex) + 1, currentFiles.length)
        ]);
        dispatch({
            type: 'UPDATE_SUPPLIER_FILES',
            index: parseInt(index),
            fieldName,
            files: updatedFiles
        });
        validateSupplierDocumentsFrontend(updatedFiles, parseInt(index), fieldName);
    };
    const handleDeleteSupplierFile = (event) => {
        const targetName = event.target['name'];
        const [index, fieldName, fileIndex] = getBracketValues(targetName);
        removeSupplierFile(fieldName, parseInt(index), parseInt(fileIndex));
    };
    const removeSupplierFile = async (fieldName, index, fileIndex) => {
        const supplier = suppliers[index];
        const body = {
            fieldName,
            index: fileIndex
        };
        if (!supplier.id) {
            throw new Error('Not allowed to remove a supplier without a supplier id.');
        }
        if (window.confirm('Are you sure you want to delete this existing file?')) {
            try {
                const response = await removeSupplierDetailsFile(apiToken, tradeLoanId, supplier.id, body, urlPath);
                if (isAPIErrors(response) || isAPIValidationErrors(response)) {
                    throw response.errors;
                }
                const existingFieldName = `existing_${fieldName}`;
                const fieldFiles = suppliers[index][existingFieldName];
                const updatedFiles = [
                    ...fieldFiles.slice(0, fileIndex),
                    ...fieldFiles.slice(fileIndex + 1, fieldFiles.length)
                ];
                dispatch({
                    type: 'UPDATE_SUPPLIER_FILES',
                    index,
                    fieldName: existingFieldName,
                    files: updatedFiles
                });
                showNotifyToast({
                    text: 'Successfully deleted file.',
                    type: 'success'
                });
            }
            catch {
                showNotifyToast({
                    text: 'Failed to delete file. Please try again.',
                    type: 'error'
                });
            }
        }
    };
    return (React.createElement("div", { className: 'suppliers-edit-dashboard', "data-testid": 'suppliers-edit-dashboard' },
        suppliers.map((supplier, index) => (React.createElement(SupplierEditPanel, { index: index, key: index, currentSupplierDetails: supplier, currentPaymentDetails: paymentDetailsList[index], supplierDetailsErrors: supplierDetailsErrors[index], supplierDocumentErrors: supplierDocumentErrors[index], paymentDetailsErrors: paymentDetailsErrors[index], isSaving: savingStatuses[index], handleSupplierDetailUpdate: handleSupplierDetailUpdate, handlePaymentDetailUpdate: handlePaymentDetailUpdate, handleConfirmPaymentDetailsToggle: handleConfirmPaymentDetailsToggle, saveSupplierDetails: saveSupplierDetails, createNewSupplierDetails: createNewSupplierDetails, removeSupplier: removeSupplier, displayRemoveButton: suppliers.length > 1, handleSupplierFileChange: handleSupplierFileChange, handleRemoveSupplierFile: handleRemoveSupplierFile, handleSupplierFileReplace: handleSupplierFileReplace, handleDeleteSupplierFile: handleDeleteSupplierFile }))),
        React.createElement("button", { className: 'custom-button button -add-trade-loan-supplier', onClick: () => dispatch({ type: 'ADD_SUPPLIER' }) }, "Add Supplier")));
};
export default SuppliersEditDashboard;
