import React, { useState } from 'react';
import classNames from 'classnames';
import ReportsModal from './ReportsModal';
const DrawdownsDownload = ({ basePath, status, currentDate }) => {
    const [isOpened, setIsOpened] = useState(false);
    const togglePanel = () => setIsOpened(!isOpened);
    const buttonClass = classNames('icon-button button', '-filter', '-icon-filter-neutral-500', '-text-neutral-500', '-border-neutral-60', '-rectangular', '-background-neutral-0', '-active');
    return (React.createElement("div", { className: 'toggle-panel -drawdowns-download -report' },
        React.createElement("button", { className: buttonClass, onClick: togglePanel },
            React.createElement("div", { className: 'text' }, "Download Report")),
        React.createElement(ReportsModal, { ...{ isOpened, togglePanel, basePath, currentDate, status } })));
};
export default DrawdownsDownload;
