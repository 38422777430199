import classNames from 'classnames';
import React, { Component } from 'react';
import Select from 'react-select';
class DropdownSelect extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            selectedOption: this.props.defaultOption
        };
        this.handleChange = selectedOption => {
            this.setState({
                selectedOption
            });
            this.props.customSetValue && this.props.customSetValue(selectedOption.value);
        };
    }
    render() {
        const { field, isClearable, label, model, order, placeholder, width } = this.props;
        const dropdownClass = classNames('dropdown-select', this.props.customClassName, {
            '-long': width && width === 'long',
            '-wide': width && width === 'wide',
            '-front': order && order === 'front'
        });
        return (React.createElement("div", { className: dropdownClass, "data-testid": 'invoice-dropdown' },
            label && React.createElement("label", { className: 'label' }, label),
            React.createElement(Select, { options: this.props.options, value: this.state.selectedOption, onChange: this.handleChange, className: 'select', name: model ? `${model}[${field}]` : `${field}`, placeholder: placeholder, isClearable: isClearable, disabled: this.props.readOnly })));
    }
}
export default DropdownSelect;
