import classNames from 'classnames';
import React from 'react';
import { buildCompanyDisplayedName } from '../../utils/hit';
const InvoiceHitDisplay = ({ hit, userType }) => {
    const { companyName, companyDisplayName, companyUseDisplayName, path, status } = hit;
    const link = path || '';
    const adminUser = userType === 'admin';
    const statusText = status || '';
    const statusClassName = classNames('hit-status status', {
        '-invoice': true,
        [`-${statusText.toLowerCase()}`]: true
    });
    const companyDisplayedName = buildCompanyDisplayedName(companyName, companyDisplayName, companyUseDisplayName);
    return (React.createElement("div", { className: 'search-hit hit -invoice', "data-testid": 'invoice-hit' },
        React.createElement("a", { href: link, className: 'body link' },
            React.createElement("div", { className: 'icon' }),
            React.createElement("div", { className: 'details' },
                React.createElement("div", { className: 'heading' }, hit.number),
                adminUser ? (React.createElement("div", { className: 'text', "data-testid": 'company-name' }, companyDisplayedName)) : (React.createElement("div", { className: 'text', "data-testid": 'attribute-label' }, "Invoice Number"))),
            React.createElement("div", { className: statusClassName }, status))));
};
export default InvoiceHitDisplay;
