import classNames from 'classnames';
import React from 'react';
import { buildCompanyDisplayedName } from '../../utils/hit';
const CustomerHitDisplay = ({ hit, userType }) => {
    const { companyName, companyDisplayName, companyUseDisplayName, name, path, status } = hit;
    const isAdmin = userType === 'admin';
    const statusText = status || '';
    const statusClassName = classNames('hit-status status', {
        '-customer': true,
        [`-${statusText.toLowerCase()}`]: true
    });
    const companyDisplayedName = buildCompanyDisplayedName(companyName, companyDisplayName, companyUseDisplayName);
    return (React.createElement("div", { className: 'search-hit hit -customer', "data-testid": 'customer-hit' },
        React.createElement("a", { href: path, className: 'body link' },
            React.createElement("div", { className: 'icon' }),
            React.createElement("div", { className: 'details' },
                React.createElement("div", { className: 'heading' }, name),
                isAdmin ? (React.createElement("div", { className: 'text' }, companyDisplayedName)) : (React.createElement("div", { className: 'text' }, "Customer Name"))),
            React.createElement("div", { className: statusClassName }, status))));
};
export default CustomerHitDisplay;
