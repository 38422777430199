import classNames from 'classnames';
import React from 'react';
import Dropdown from './Dropdown';
const FileUpload = ({ formAttributes, typeOptions, userType = 'admin' }) => {
    const { csrfToken, url, model } = formAttributes;
    const isAdmin = userType === 'admin';
    const isUser = userType === 'user';
    const fileUploadClass = classNames('file-upload', {
        '-admin': isAdmin,
        '-user': isUser
    });
    const formClass = classNames('form', {
        '-admin': isAdmin,
        '-user': isUser
    });
    const selectClass = classNames('select', {
        '-admin': isAdmin,
        '-user': isUser
    });
    const fileClass = classNames('file', {
        '-admin': isAdmin,
        '-user': isUser
    });
    return (React.createElement("div", { className: fileUploadClass, "data-testid": 'file-upload' },
        React.createElement("form", { encType: 'multipart/form-data', action: url, acceptCharset: 'UTF-8', method: 'post', className: formClass },
            React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
            typeOptions.length > 1 ? (React.createElement(Dropdown, { options: typeOptions, model: model, attribute: 'attachment_type', className: selectClass })) : (React.createElement("input", { type: 'hidden', name: `${model}[attachment_type]`, value: typeOptions[0].value, "data-testid": 'hidden-input' })),
            React.createElement("input", { type: 'file', name: `${model}[file]`, id: `${model}_file`, className: fileClass }),
            React.createElement("input", { type: 'submit', className: 'custom-button upload -default -narrow', name: 'commit', value: 'Upload', "data-disable-with": 'Uploading...' }))));
};
export default FileUpload;
