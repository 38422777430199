import React, { useState, useRef, useEffect } from 'react';
import { isAPIErrors } from '../../../types';
import debounce from 'lodash.debounce';
import { showNotifyToast } from '../../utils/notifyToast';
import { fetchInvoices as defaultFetchInvoices, approveMultipleInvoices as defaultApproveMultipleInvoices, unapproveMultipleInvoices as defaultUnapproveMultipleInvoices, verifyMultipleInvoices as defaultVerifyMultipleInvoices, unverifyMultipleInvoices as defaultUnverifyMultipleInvoices, approve as defaultApprove, unapprove as defaultUnapprove, setVerified as defaultVerify, setUnverified as defaultUnverify } from '../../api/invoice';
import { fetchCustomer } from '../../api/customer';
import InvoicesTable from '../../components/invoices-table/InvoicesTable';
import CustomerInvoicesSummary from '../../components/admin-borrower-customer/CustomerInvoicesSummary';
import CustomerInfo from '../../components/admin-borrower-customer/CustomerInfo';
import CustomerAgeing from '../../components/admin-borrower-customer/CustomerAgeing';
import CustomerAuditLogsPanel from '../../components/admin-borrower-customer/CustomerAuditLogsPanel';
import CommentPanel from '../../components/comment-panel/CommentPanel';
import CustomerAbnDetailsPanel from '../../components/admin-borrower-customer/CustomerAbnDetailsPanel';
import FilterTabs from '../../components/filter-tabs/FilterTabs';
import { fetchCreditNotes as defaultFetchCreditNotes } from '../../api/credit_notes';
import CreditNotesTable from './CreditNotesTable';
import AdjustmentsTable from './AdjustmentsTable';
import { fetchCustomerAdjustments as defaultFetchCustomerAdjustments } from '../../api/adjustment';
const ExpandedCustomerInvoicesTableContainer = ({ mainCustomer, customerStatementLink, isXeroUser = false, borrowerId, customerId, apiToken, csrfToken, defaultFilter, defaultFilterText, approveMultipleInvoices = defaultApproveMultipleInvoices, unapproveMultipleInvoices = defaultUnapproveMultipleInvoices, verifyMultipleInvoices = defaultVerifyMultipleInvoices, unverifyMultipleInvoices = defaultUnverifyMultipleInvoices, approve = defaultApprove, unapprove = defaultUnapprove, setVerified = defaultVerify, setUnverified = defaultUnverify, fetchInvoices = defaultFetchInvoices, fetchCreditNotes = defaultFetchCreditNotes, fetchCustomerAdjustments = defaultFetchCustomerAdjustments, searchPlaceholder = 'Search customer name', borrowerInvoicesPath, customersPath, backLink, isPolicyEdit, editCustomerLink, isMyobUser = false, refreshLink, fetchAbnLink, disapprovalReasonsOptions, abnData, customerPhones, customerAddresses, effectiveConcentrationLimit, isManualLedger, isDebtorsLedgerRecalculating, debtorLimit, concentrationLimit, enableActions, ageingPanelHeader, totalApprovedFormatted, totalFormatted, dpi0To30DaysApprovedFormatted, dpi0To30DaysFormatted, dpi31To60DaysApprovedFormatted, dpi31To60DaysFormatted, dpi61To90DaysApprovedFormatted, dpi61To90DaysFormatted, dpi90DaysApprovedFormatted, dpi90DaysFormatted, currentUserId, commentableType, scope, userAvatarUrl, groupedAuditLogs, customerPath, defaultFilterTab, checkIcon }) => {
    const [invoicesData, setInvoices] = useState([]);
    const [customerData, setCustomer] = useState({});
    const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);
    const [computedPrice, setComputedPrice] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(defaultFilter);
    const [selectedFilterText, setSelectedFilterText] = useState(defaultFilterText);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [selectedFilterTab, setSelectedFilterTab] = useState(defaultFilterTab);
    const [creditNotesData, setCreditNotes] = useState([]);
    const [adjustmentsData, setAdjustments] = useState([]);
    const [isModalOpenForSingleApprove, setIsModalOpenForSingleApprove] = useState(false);
    const [isModalOpenForApprove, setIsModalOpenForApprove] = useState(false);
    const [isModalOpenForVerify, setIsModalOpenForVerify] = useState(false);
    const [isModalOpenForUnverify, setIsModalOpenForUnverify] = useState(false);
    const [isModalOpenForUnapprove, setIsModalOpenForUnapprove] = useState(false);
    const openModalForSingleApprove = () => {
        setIsModalOpenForSingleApprove(true);
    };
    const closeModalForSingleApprove = () => {
        setIsModalOpenForSingleApprove(false);
    };
    const openModalForApprove = () => {
        setIsModalOpenForApprove(true);
    };
    const closeModalForApprove = () => {
        setIsModalOpenForApprove(false);
    };
    const openModalForVerify = () => {
        setIsModalOpenForVerify(true);
    };
    const closeModalForVerify = () => {
        setIsModalOpenForVerify(false);
    };
    const openModalForUnverify = () => {
        setIsModalOpenForUnverify(true);
    };
    const closeModalForUnverify = () => {
        setIsModalOpenForUnverify(false);
    };
    const openModalForUnapprove = () => {
        setIsModalOpenForUnapprove(true);
    };
    const closeModalForUnapprove = () => {
        setIsModalOpenForUnapprove(false);
    };
    const handlePageClick = data => {
        setCurrentPage(data.selected + 1);
    };
    const allCheckboxRef = useRef(null);
    const handleAllCheckboxChange = (e) => {
        const allInvoiceIds = invoicesData.map(t => t.id);
        const price = invoicesData.reduce((prev, curr) => prev + curr.amountDueCents, 0);
        if (e.target.checked) {
            setSelectedInvoiceIds(allInvoiceIds);
            setSelectedInvoices(invoicesData);
            setComputedPrice(price);
        }
        else {
            setSelectedInvoiceIds([]);
            setSelectedInvoices([]);
            setComputedPrice(0);
        }
    };
    const handleInvoiceId = (invoiceId) => {
        const newIds = [...selectedInvoiceIds];
        if (isSelected(invoiceId)) {
            newIds.splice(newIds.indexOf(invoiceId), 1);
        }
        else {
            newIds.push(invoiceId);
        }
        const newInvoices = invoicesData.filter((invoiceData) => {
            return newIds.includes(invoiceData.id);
        });
        const price = newInvoices.reduce((prev, curr) => prev + curr.amountDueCents, 0);
        setSelectedInvoiceIds(newIds);
        setSelectedInvoices(newInvoices);
        setComputedPrice(price);
        const isAllSelected = newIds.length === invoicesData.length;
        toggleAllCheckbox(isAllSelected);
    };
    const selectedIdString = () => {
        return selectedInvoiceIds.join(',');
    };
    const isSelected = invoiceId => {
        return selectedInvoiceIds.includes(invoiceId);
    };
    const toggleAllCheckbox = checked => {
        if (allCheckboxRef && allCheckboxRef.current) {
            allCheckboxRef.current.checked = checked;
        }
    };
    useEffect(() => {
        const fetchInvoicesData = async () => {
            setIsLoading(true);
            try {
                const response = await fetchInvoices(apiToken, borrowerId, currentPage, sortBy, sortDirection, selectedFilter, searchQuery, customerId);
                const data = response.data;
                if (data && data.attributes) {
                    const { page, totalItems, totalPages, pageSize } = data.attributes;
                    setInvoices(page);
                    setTotalItems(totalItems);
                    setTotalPages(totalPages);
                    setPageSize(pageSize);
                    setSelectedInvoiceIds([]);
                    setComputedPrice(0);
                }
                else {
                    showNotifyToast({
                        text: 'Error fetching invoices data',
                        type: 'error'
                    });
                }
            }
            catch (err) {
                const error = err;
                showNotifyToast({
                    text: error.message,
                    type: 'error'
                });
            }
            finally {
                setIsLoading(false);
            }
        };
        selectedFilterTab === 'invoices' && fetchInvoicesData();
        selectedFilterTab === 'credit_notes' && fetchCreditNotesData();
        selectedFilterTab === 'adjustments' && fetchAdjustmentsData();
        fetchCustomerData();
    }, [currentPage, sortBy, sortDirection, selectedFilter, searchQuery]);
    const fetchCustomerData = async () => {
        try {
            const { data } = await fetchCustomer(apiToken, customerId, borrowerId);
            setCustomer(data.attributes);
        }
        catch (err) {
            const error = err;
            showNotifyToast({
                text: error.message,
                type: 'error'
            });
        }
    };
    const fetchCreditNotesData = async () => {
        setIsLoading(true);
        try {
            const response = await fetchCreditNotes(apiToken, borrowerId, customerId, currentPage);
            const data = response.data;
            if (data && data.attributes) {
                const { page, totalItems, totalPages, pageSize } = data.attributes;
                setCreditNotes(page);
                setTotalItems(totalItems);
                setTotalPages(totalPages);
                setPageSize(pageSize);
            }
            else {
                showNotifyToast({
                    text: 'Error fetching credit notes data',
                    type: 'error'
                });
            }
            setSelectedFilterTab('credit_notes');
        }
        catch (err) {
            const error = err;
            showNotifyToast({
                text: error.message,
                type: 'error'
            });
        }
        finally {
            setIsLoading(false);
        }
    };
    const fetchAdjustmentsData = async () => {
        setIsLoading(true);
        try {
            const response = await fetchCustomerAdjustments(apiToken, borrowerId, customerId, currentPage);
            const data = response.data;
            if (data && data.attributes) {
                const { page, totalItems, totalPages, pageSize } = data.attributes;
                setAdjustments(page);
                setTotalItems(totalItems);
                setTotalPages(totalPages);
                setPageSize(pageSize);
            }
            else {
                showNotifyToast({
                    text: 'Error fetching adjustments data',
                    type: 'error'
                });
            }
            setSelectedFilterTab('adjustments');
        }
        catch (err) {
            const error = err;
            showNotifyToast({
                text: error.message,
                type: 'error'
            });
        }
        finally {
            setIsLoading(false);
        }
    };
    const sortInvoices = (field, defaultSort) => {
        setSortBy(field);
        if (sortBy === field && sortDirection === 'asc') {
            setSortDirection('desc');
        }
        else if (sortBy === field && sortDirection === 'desc') {
            setSortDirection('asc');
        }
        else {
            setSortDirection(defaultSort || 'asc');
        }
    };
    const filterInvoices = (filter, filterText) => {
        setSelectedFilter(filter);
        setSelectedFilterText(filterText);
    };
    const handleSearchQueryChange = (event) => {
        event.preventDefault();
        const queryValue = event.currentTarget.value;
        debounceQueryUpdate(queryValue);
    };
    const debounceQueryUpdate = debounce((queryValue) => {
        setSearchQuery(queryValue);
    }, 300);
    const handleBulkApproveInvoices = async () => {
        try {
            const response = await approveMultipleInvoices(apiToken, borrowerId, selectedInvoiceIds);
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            const updatedInvoices = invoicesData.map(invoiceData => {
                if (selectedInvoiceIds.includes(invoiceData.id)) {
                    return { ...invoiceData, approval_status: 'APPROVED', approved: true };
                }
                else {
                    return invoiceData;
                }
            });
            setInvoices(updatedInvoices);
            fetchCustomerData();
            setSelectedInvoiceIds([]);
            setSelectedInvoices([]);
            setIsModalOpenForApprove(false);
            showNotifyToast({
                text: 'Successfully approved selected invoices.',
                type: 'success'
            });
        }
        catch (err) {
            const error = err;
            showNotifyToast({
                text: error.message,
                type: 'error'
            });
        }
    };
    const handleBulkUnapproveInvoices = async () => {
        try {
            const response = await unapproveMultipleInvoices(apiToken, borrowerId, selectedInvoiceIds);
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            const updatedInvoices = invoicesData.map(invoiceData => {
                if (selectedInvoiceIds.includes(invoiceData.id)) {
                    return {
                        ...invoiceData,
                        approval_status: 'UNAPPROVED',
                        approved: false
                    };
                }
                else {
                    return invoiceData;
                }
            });
            setInvoices(updatedInvoices);
            fetchCustomerData();
            setSelectedInvoiceIds([]);
            setSelectedInvoices([]);
            setIsModalOpenForUnapprove(false);
            showNotifyToast({
                text: 'Successfully unapproved selected invoices.',
                type: 'success'
            });
        }
        catch (err) {
            const error = err;
            showNotifyToast({
                text: error.message,
                type: 'error'
            });
        }
    };
    const handleBulkVerifyInvoices = async () => {
        try {
            const response = await verifyMultipleInvoices(apiToken, borrowerId, selectedInvoiceIds);
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            const updatedInvoices = invoicesData.map(invoiceData => {
                if (selectedInvoiceIds.includes(invoiceData.id)) {
                    return { ...invoiceData, verified: true };
                }
                else {
                    return invoiceData;
                }
            });
            setInvoices(updatedInvoices);
            setSelectedInvoiceIds([]);
            setSelectedInvoices([]);
            setIsModalOpenForVerify(false);
            showNotifyToast({
                text: 'Successfully verified selected invoices.',
                type: 'success'
            });
        }
        catch (err) {
            const error = err;
            showNotifyToast({
                text: error.message,
                type: 'error'
            });
        }
    };
    const handleBulkUnverifyInvoices = async () => {
        try {
            const response = await unverifyMultipleInvoices(apiToken, borrowerId, selectedInvoiceIds);
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            const updatedInvoices = invoicesData.map(invoiceData => {
                if (selectedInvoiceIds.includes(invoiceData.id)) {
                    return { ...invoiceData, verified: false };
                }
                else {
                    return invoiceData;
                }
            });
            setInvoices(updatedInvoices);
            setSelectedInvoiceIds([]);
            setSelectedInvoices([]);
            setIsModalOpenForUnverify(false);
            showNotifyToast({
                text: 'Successfully unverified selected invoices.',
                type: 'success'
            });
        }
        catch (err) {
            const error = err;
            showNotifyToast({
                text: error.message,
                type: 'error'
            });
        }
    };
    const handleSubmitSingleApprove = async () => {
        try {
            if (selectedInvoice) {
                await approve(apiToken, borrowerId, selectedInvoice.id);
                const updatedInvoices = invoicesData.map(invoiceData => {
                    if (invoiceData.id === selectedInvoice.id) {
                        return {
                            ...invoiceData,
                            approval_status: 'APPROVED',
                            approved: true
                        };
                    }
                    else {
                        return invoiceData;
                    }
                });
                setSelectedInvoice(null);
                setInvoices(updatedInvoices);
                fetchCustomerData();
                closeModalForSingleApprove();
            }
            else {
                showNotifyToast({
                    text: 'No invoice selected.',
                    type: 'error'
                });
            }
        }
        catch (err) {
            const error = err;
            showNotifyToast({
                text: error.message,
                type: 'error'
            });
        }
    };
    const handleApprove = async (invoiceId) => {
        const invoice = invoicesData.filter((invoiceData) => {
            return invoiceData.id === invoiceId;
        })[0];
        setSelectedInvoice(invoice);
        openModalForSingleApprove();
    };
    const handleUnapprove = async (invoiceId) => {
        try {
            await unapprove(apiToken, borrowerId, invoiceId);
            const updatedInvoices = invoicesData.map(invoiceData => {
                if (invoiceData.id === invoiceId) {
                    return {
                        ...invoiceData,
                        approval_status: 'UNAPPROVED',
                        approved: false
                    };
                }
                else {
                    return invoiceData;
                }
            });
            setInvoices(updatedInvoices);
            fetchCustomerData();
        }
        catch (err) {
            const error = err;
            showNotifyToast({
                text: error.message,
                type: 'error'
            });
        }
    };
    const handleVerify = async (invoiceId) => {
        try {
            await setVerified(apiToken, borrowerId, invoiceId);
            const updatedInvoices = invoicesData.map(invoiceData => {
                if (invoiceData.id === invoiceId) {
                    return { ...invoiceData, verified: true };
                }
                else {
                    return invoiceData;
                }
            });
            setInvoices(updatedInvoices);
            fetchCustomerData();
        }
        catch (err) {
            const error = err;
            showNotifyToast({
                text: error.message,
                type: 'error'
            });
        }
    };
    const handleUnverify = async (invoiceId) => {
        try {
            await setUnverified(apiToken, borrowerId, invoiceId);
            const updatedInvoices = invoicesData.map(invoiceData => {
                if (invoiceData.id === invoiceId) {
                    return { ...invoiceData, verified: false };
                }
                else {
                    return invoiceData;
                }
            });
            setInvoices(updatedInvoices);
            fetchCustomerData();
        }
        catch (err) {
            const error = err;
            showNotifyToast({
                text: error.message,
                type: 'error'
            });
        }
    };
    const headerFields = [
        {
            text: '',
            type: 'checkbox',
            checkboxRef: allCheckboxRef
        },
        { text: '#' },
        { text: 'Customer', field: 'customer_name' },
        ...(isXeroUser ? [{ text: 'Reference', field: 'reference' }] : []),
        { text: 'Amount Due', field: 'amount_due_cents', defaultSort: 'desc' },
        { text: 'Issue Date', field: 'issue_date' },
        { text: 'DPI' },
        { text: 'Due Date', field: 'due_date' },
        { text: 'Comments', field: 'comments_count' },
        { text: 'Verified', field: 'verified' },
        { text: 'Approved' },
        { text: 'Action' }
    ];
    const filterDropdown = [
        { text: 'Everything', filter: 'everything' },
        { text: 'Outstanding', filter: 'outstanding' },
        { text: 'Current', filter: 'current' },
        { text: 'Ineligible', filter: 'ineligible' },
        { text: 'Overdue', filter: 'overdue' },
        { text: '1-30 days Overdue', filter: 'overdue_1_to_30_days' },
        { text: '31-60 days Overdue', filter: 'overdue_31_to_60_days' },
        { text: '> 60 days Overdue', filter: 'overdue_greater_than_60_days' },
        { text: 'Paid (Last 6 Months)', filter: 'paid_last_6_months' },
        { text: 'Sort by Issue Date', filter: 'sort_by_issue_date' },
        { text: 'Credit', filter: 'credit' },
        { text: 'Approved', filter: 'approved' },
        { text: 'Unapproved', filter: 'unapproved' },
        { text: 'Unselected', filter: 'unselected' },
        { text: 'Submitted', filter: 'submitted' }
    ];
    const filterTabs = [
        {
            name: 'Invoices',
            path: `${customerPath}?tab=invoices`,
            tag: 'invoices',
            counter: customerData.totalInvoicesFormatted
        },
        {
            name: 'Credit Notes',
            path: `${customerPath}?tab=credit_notes`,
            tag: 'credit_notes',
            counter: customerData.totalCreditNotesFormatted
        },
        {
            name: 'Adjustments',
            path: `${customerPath}?tab=adjustments`,
            tag: 'adjustments',
            counter: customerData.totalAdjustmentsFormatted
        }
    ];
    return (React.createElement("div", { className: 'borrower-container', "data-testid": 'expanded-customer-invoices' },
        React.createElement("div", { className: 'summary' },
            React.createElement(CustomerInfo, { customer: mainCustomer, apiToken: apiToken, isPolicyEdit: isPolicyEdit, editCustomerLink: editCustomerLink, isXeroUser: isXeroUser, isMyobUser: isMyobUser, refreshLink: refreshLink, borrowerId: borrowerId, customerPhones: customerPhones, customerAddresses: customerAddresses, effectiveConcentrationLimit: effectiveConcentrationLimit, isManualLedger: isManualLedger, debtorLimit: debtorLimit, concentrationLimit: concentrationLimit, enableActions: enableActions, checkIcon: checkIcon, debtorBalance: customerData.debtorBalanceFormatted }),
            React.createElement(CustomerAbnDetailsPanel, { abnData: abnData, fetchAbnLink: fetchAbnLink }),
            React.createElement(CustomerInvoicesSummary, { customer: customerData, customerStatementLink: customerStatementLink, isManualLedger: isManualLedger, isDebtorsLedgerRecalculating: isDebtorsLedgerRecalculating }),
            React.createElement(CustomerAgeing, { customer: customerData, isManualLedger: isManualLedger, ageingPanelHeader: ageingPanelHeader, totalApprovedFormatted: totalApprovedFormatted, totalFormatted: totalFormatted, dpi0To30DaysApprovedFormatted: dpi0To30DaysApprovedFormatted, dpi0To30DaysFormatted: dpi0To30DaysFormatted, dpi31To60DaysApprovedFormatted: dpi31To60DaysApprovedFormatted, dpi31To60DaysFormatted: dpi31To60DaysFormatted, dpi61To90DaysApprovedFormatted: dpi61To90DaysApprovedFormatted, dpi61To90DaysFormatted: dpi61To90DaysFormatted, dpi90DaysApprovedFormatted: dpi90DaysApprovedFormatted, dpi90DaysFormatted: dpi90DaysFormatted }),
            React.createElement("div", { className: 'custom-panel comments' },
                React.createElement("div", { className: 'header' }, " Comments "),
                React.createElement("div", { className: 'body' },
                    React.createElement(CommentPanel, { apiToken: apiToken, currentUserId: currentUserId, feedVariantClasses: ['compact'], formVariantClasses: ['compact'], itemVariantClasses: ['compact'], commentableType: commentableType, commentableId: customerId, panelVariantClasses: ['compact', 'form-first'], scope: scope, privacySettingEnabled: true, toggleMenuEnabled: true, userAvatarUrl: userAvatarUrl }))),
            React.createElement(CustomerAuditLogsPanel, { groupedAuditLogs: groupedAuditLogs })),
        React.createElement("div", { className: 'table -full-width -invoices' },
            React.createElement(FilterTabs, { tabs: filterTabs }),
            selectedFilterTab === 'invoices' && (React.createElement(InvoicesTable, { ...{
                    apiToken,
                    borrowerId,
                    borrowerInvoicesPath,
                    closeModalForApprove,
                    closeModalForUnapprove,
                    closeModalForUnverify,
                    closeModalForVerify,
                    computedPrice,
                    csrfToken,
                    currentPage,
                    customersPath,
                    debounceQueryUpdate,
                    disapprovalReasonsOptions,
                    filterDropdown,
                    filterInvoices,
                    handleAllCheckboxChange,
                    handleApprove,
                    handleBulkApproveInvoices,
                    handleBulkUnapproveInvoices,
                    handleBulkUnverifyInvoices,
                    handleBulkVerifyInvoices,
                    handleInvoiceId,
                    handlePageClick,
                    handleSearchQueryChange,
                    handleSubmitSingleApprove,
                    handleUnapprove,
                    handleUnverify,
                    handleVerify,
                    headerFields,
                    invoicesData,
                    isLoading,
                    isModalOpenForApprove,
                    isModalOpenForUnapprove,
                    isModalOpenForUnverify,
                    isModalOpenForVerify,
                    isSelected,
                    isXeroUser,
                    openModalForApprove,
                    openModalForUnapprove,
                    openModalForUnverify,
                    openModalForVerify,
                    pageSize,
                    searchPlaceholder,
                    selectedFilter,
                    selectedFilterText,
                    selectedIdString,
                    selectedInvoiceIds,
                    selectedInvoices,
                    setInvoices,
                    setSelectedInvoiceIds,
                    sortBy,
                    sortDirection,
                    sortInvoices,
                    toggleAllCheckbox,
                    totalItems,
                    totalPages,
                    closeModalForSingleApprove,
                    isModalOpenForSingleApprove
                }, selectedInvoice: selectedInvoice || null })),
            selectedFilterTab === 'credit_notes' && (React.createElement(CreditNotesTable, { ...{
                    isLoading,
                    handlePageClick,
                    currentPage,
                    pageSize,
                    totalPages,
                    totalItems
                }, creditNotes: creditNotesData })),
            selectedFilterTab === 'adjustments' && (React.createElement(AdjustmentsTable, { ...{
                    isLoading,
                    handlePageClick,
                    currentPage,
                    pageSize,
                    totalPages,
                    totalItems
                }, adjustments: adjustmentsData }))),
        React.createElement("div", { className: 'back' },
            React.createElement("a", { href: backLink, className: 'button custom-button -back' }, "Go Back"))));
};
export default ExpandedCustomerInvoicesTableContainer;
