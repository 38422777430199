import React, { useEffect, useRef, useState } from 'react';
import hotkeys from 'hotkeys-js';
import debounce from 'lodash.debounce';
import { fetchSearchResults } from '../../api/search';
import SearchBar from '../../components/search-bar/SearchBar';
import SearchResults from '../../components/search-bar/SearchResults';
const AdminSearchPanel = ({ apiToken, isActiveByDefault }) => {
    const [isActive, setIsActive] = useState(isActiveByDefault || false);
    const [query, setQuery] = useState('');
    const [hits, setHits] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const searchInputRef = useRef(null);
    const searchActive = query && query.length > 3;
    useEffect(() => {
        loadSearchResults();
    }, [query]);
    useEffect(() => {
        if (searchInputRef && searchInputRef.current && isActive) {
            searchInputRef.current.focus();
        }
    }, [isActive]);
    hotkeys('ctrl+k', { keyup: true }, event => {
        // prevent key spam when user holds down the keys
        if (event.type === 'keyup') {
            setIsActive(currentIsActive => !currentIsActive);
            resetPanel();
        }
        return false;
    });
    // allows hotkeys to be active in inputs, select, and textarea
    hotkeys.filter = () => {
        return true;
    };
    const resetPanel = () => {
        setQuery('');
        setHits([]);
        setHasErrors(false);
    };
    const showPanel = () => {
        setIsActive(true);
    };
    const hidePanel = () => {
        setIsActive(false);
        resetPanel();
    };
    const handleSearchQueryChange = (event) => {
        event.preventDefault();
        const queryValue = event.currentTarget.value;
        debounceQueryUpdate(queryValue);
    };
    const debounceQueryUpdate = debounce((queryValue) => {
        setQuery(queryValue);
    }, 300);
    const handleSearchQueryClear = (event) => {
        event.preventDefault();
        resetPanel();
        if (searchInputRef && searchInputRef.current) {
            searchInputRef.current.value = '';
        }
    };
    const loadSearchResults = async () => {
        if (searchActive) {
            setHasErrors(false);
            setIsLoading(true);
            try {
                setHits(await fetchSearchResults(apiToken, query));
                setIsLoading(false);
            }
            catch (err) {
                setHasErrors(true);
                setIsLoading(false);
            }
        }
        else {
            setHasErrors(false);
            setHits([]);
        }
    };
    return (React.createElement("div", { className: 'search-panel search -admin' },
        React.createElement("div", { className: 'icon-link trigger' },
            React.createElement("div", { className: 'icon -search', onClick: isActive ? hidePanel : showPanel, "data-testid": 'icon-trigger' }),
            React.createElement("div", { className: 'label' },
                React.createElement("div", { className: 'text' }, "Search"))),
        isActive && (React.createElement("div", { className: 'panel', "data-testid": 'panel' },
            React.createElement("div", { className: 'background', onClick: hidePanel }),
            React.createElement("div", { className: 'custom-panel search' },
                React.createElement(SearchBar, { ...{ query, handleSearchQueryChange, handleSearchQueryClear }, forwardSearchInputRef: searchInputRef, userType: 'admin' }),
                searchActive && (React.createElement(SearchResults, { ...{ hits, hasErrors, isLoading }, userType: 'admin' }))),
            React.createElement("div", { className: 'tip' },
                React.createElement("div", { className: 'text' }, "Tip: Open and close this faster with Ctrl+K"))))));
};
export default AdminSearchPanel;
