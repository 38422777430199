import React from 'react';
import IconToggle from '../shared/IconToggle';
const ApprovalStatusToggle = ({ invoiceId, isApproved, allowedForApprovalStatusToggle, disapprovalReasons, disapprovalReasonsOptions, handleApprove, handleUnapprove }) => {
    return (React.createElement("div", { className: 'icon-toggle', "data-testid": 'approval-status-toggle' },
        React.createElement(IconToggle, { isOn: isApproved, isToggleable: allowedForApprovalStatusToggle, onIcon: 'check', offIcon: 'close', switchOff: () => handleUnapprove(invoiceId), switchOn: () => handleApprove(invoiceId) }),
        !isApproved && disapprovalReasons.length !== 0 && (React.createElement("div", { className: 'tooltip tooltip-panel' }, disapprovalReasonsOptions.map((item, index) => {
            return (disapprovalReasons.includes(item.value) && (React.createElement("div", { key: `${invoiceId}-reason-${index}`, className: 'title -center' }, item.label)));
        })))));
};
export default ApprovalStatusToggle;
