import React, { useState, useRef, useEffect } from 'react';
import AdminClientsTable from '../../components/admin-clients-table/AdminClientsTable';
import { showNotifyToast } from '../../utils/notifyToast';
import { setBrokerClient } from '../../api/broker_client';
const AdminClientsDashboard = ({ apiToken, brokerCompanyId, fetchClientsForBroker, fetchAvailableClients }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [clientsArray, setClientsArray] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(0);
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [headerWidths, setHeaderWidths] = useState([]);
    const [showClientModal, setShowClientModal] = useState(false);
    const [availableClients, setAvailableClients] = useState([]);
    const headerRef = useRef(null);
    const errorMessage = 'Something went wrong. Please try again.';
    useEffect(() => {
        if (headerRef && headerRef.current) {
            const headerWidthList = [...headerRef.current.children[0].children].map(tableHeader => tableHeader.getBoundingClientRect().width);
            setHeaderWidths(headerWidthList);
        }
        getAvailableClients();
        fetchClients();
    }, [currentPage, sortBy, sortDirection]);
    const fetchClients = async () => {
        setIsLoading(true);
        try {
            const { data } = await fetchClientsForBroker(apiToken, brokerCompanyId, currentPage, sortBy, sortDirection);
            const { page, pageSize, totalItems, totalPages } = data.attributes;
            setClientsArray(page);
            setTotalPages(totalPages);
            setTotalItems(totalItems);
            setPageSize(pageSize);
        }
        catch (err) {
            if (err instanceof Error) {
                showNotifyToast({
                    text: errorMessage,
                    type: 'error'
                });
            }
        }
        setIsLoading(false);
    };
    const getAvailableClients = async () => {
        setIsLoading(true);
        try {
            const data = await fetchAvailableClients(apiToken);
            setAvailableClients(data);
        }
        catch (err) {
            if (err instanceof Error) {
                showNotifyToast({
                    text: errorMessage,
                    type: 'error'
                });
            }
        }
        setIsLoading(false);
    };
    const handleSetClient = async (clientId) => {
        if (!clientId)
            return;
        setIsLoading(true);
        try {
            const data = await setBrokerClient(apiToken, brokerCompanyId, clientId);
            if (data) {
                setShowClientModal(false);
                getAvailableClients();
                fetchClients();
            }
        }
        catch (err) {
            if (err instanceof Error) {
                showNotifyToast({
                    text: errorMessage,
                    type: 'error'
                });
            }
        }
        setIsLoading(false);
    };
    const sortClients = (field, defaultSort) => {
        setSortBy(field);
        if (sortBy === field && sortDirection === 'asc') {
            setSortDirection('desc');
        }
        else if (sortBy === field && sortDirection === 'desc') {
            setSortDirection('asc');
        }
        else {
            setSortDirection(defaultSort || 'asc');
        }
    };
    const handlePageClick = data => {
        setCurrentPage(data.selected + 1);
    };
    return (React.createElement("div", { className: 'clients-dashboard -admin', "data-testid": 'admin-clients-dashboard' },
        React.createElement("div", { className: 'table' },
            React.createElement(AdminClientsTable, { ...{
                    apiToken,
                    isLoading,
                    brokerCompanyId,
                    currentPage,
                    clientsArray,
                    sortBy,
                    sortDirection,
                    pageSize,
                    totalPages,
                    totalItems,
                    headerWidths,
                    sortClients,
                    handlePageClick,
                    showClientModal,
                    setShowClientModal,
                    availableClients,
                    handleSetClient
                }, forwardHeaderRef: headerRef }))));
};
export default AdminClientsDashboard;
