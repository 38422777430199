import classNames from 'classnames';
import React from 'react';
const AttachmentUploader = ({ files, forwardFilePickerRef, hideFilePicker = false, handleClearAttachments, handleFileChange }) => {
    const hasFiles = files.length > 0;
    const uploaderClass = classNames('attachment-uploader attachments', {
        '-has-files': hasFiles
    });
    const fileInputClass = classNames('input', {
        '-hidden': hideFilePicker
    });
    return (React.createElement("div", { className: uploaderClass },
        React.createElement("input", { className: fileInputClass, type: 'file', multiple: true, ref: forwardFilePickerRef, onChange: handleFileChange, "data-testid": 'attachment-file-picker', name: 'attachments[]' }),
        hasFiles && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'files' },
                files.map((file, index) => (React.createElement("div", { className: 'file', key: index, "data-testid": 'file-item' }, file.name))),
                React.createElement("button", { className: 'icon-button button -cancel -attachment', onClick: handleClearAttachments, "data-testid": 'clear-attachments' },
                    React.createElement("i", { className: 'icon' })))))));
};
export default AttachmentUploader;
