import React from 'react';
import DocumentPicker from './DocumentPicker';
import ErrorList from '../shared/ErrorList';
const SupportingDocuments = ({ currentTradeLoanDetails, tradeLoanErrors, documentErrors, handleRemoveFile, handleFileChange, handleFileReplace }) => {
    const { purchaseOrder, proFormaInvoice, transportDocument, otherDocuments } = currentTradeLoanDetails;
    return (React.createElement("div", { className: 'form-section -trade-loan -supporting-documents -background -padded', "data-testid": 'supporting-documents' },
        React.createElement("div", { className: 'body' },
            React.createElement("div", { className: 'text' }, "We represent and warrant each of the following."),
            React.createElement("div", { className: 'form-group group' },
                React.createElement("div", { className: 'label -uppercase' }, "Purchase Order"),
                React.createElement(ErrorList, { errors: documentErrors['purchaseOrder'] || {}, field: 'collectionErrors' }),
                React.createElement(DocumentPicker, { inputName: 'trade_loan_details[purchase_order]', files: purchaseOrder, handleFileChange: handleFileChange, handleFileReplace: handleFileReplace, handleRemoveFile: handleRemoveFile, errors: documentErrors['purchaseOrder'] || {} }),
                React.createElement(ErrorList, { errors: tradeLoanErrors, field: 'purchase_order' })),
            React.createElement("div", { className: 'form-group group' },
                React.createElement("div", { className: 'label -uppercase' }, "Pro-Forma Invoice"),
                React.createElement(ErrorList, { errors: documentErrors['proFormaInvoice'] || {}, field: 'collectionErrors' }),
                React.createElement(DocumentPicker, { inputName: 'trade_loan_details[pro_forma_invoice]', files: proFormaInvoice, handleFileChange: handleFileChange, handleFileReplace: handleFileReplace, handleRemoveFile: handleRemoveFile, errors: documentErrors['proFormaInvoice'] || {} }),
                React.createElement(ErrorList, { errors: tradeLoanErrors, field: 'pro_forma_invoice' })),
            React.createElement("div", { className: 'form-group group' },
                React.createElement("div", { className: 'label -uppercase' }, "Transport Document (e.g. Bill of Lading or Airway Bill)"),
                React.createElement(ErrorList, { errors: documentErrors['transportDocument'] || {}, field: 'collectionErrors' }),
                React.createElement(DocumentPicker, { inputName: 'trade_loan_details[transport_document]', files: transportDocument, handleFileChange: handleFileChange, handleFileReplace: handleFileReplace, handleRemoveFile: handleRemoveFile, errors: documentErrors['transportDocument'] || {} }),
                React.createElement(ErrorList, { errors: tradeLoanErrors, field: 'transport_document' })),
            React.createElement("div", { className: 'form-group group' },
                React.createElement("div", { className: 'label -uppercase' }, "Other Documents"),
                React.createElement(ErrorList, { errors: documentErrors['otherDocuments'] || {}, field: 'collectionErrors' }),
                React.createElement(DocumentPicker, { inputName: 'trade_loan_details[otherDocuments]', files: otherDocuments, handleFileChange: handleFileChange, handleFileReplace: handleFileReplace, handleRemoveFile: handleRemoveFile, errors: documentErrors['otherDocuments'] || {} }),
                React.createElement(ErrorList, { errors: tradeLoanErrors, field: 'other_documents' })),
            React.createElement("div", { className: 'text' }, "By submitting this Application, I certify that the above information is true, accurate and complete."))));
};
export default SupportingDocuments;
