import React from 'react';
const CommissionRatesTrailing = ({ crTrailingFeeRate, crTrailingInterestRate, setCrTrailingFeeRate, setCrTrailingInterestRate }) => {
    return (React.createElement("div", { "data-testid": 'commission-rates-trailing' },
        React.createElement("div", { className: 'form-row -col-2' },
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label -required' }, "Admin Fee Commission (%)"),
                React.createElement("input", { className: 'input form-control', name: 'commission_details[trailing_fee_commission_rate]', type: 'number', step: 'any', defaultValue: crTrailingFeeRate, onChange: e => setCrTrailingFeeRate(Number(e.target.value)), required: true, "data-testid": 'cr-fee-input' })),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label -required' }, "Interest Commission (%)"),
                React.createElement("input", { className: 'input form-control', name: 'commission_details[trailing_interest_commission_rate]', type: 'number', step: 'any', defaultValue: crTrailingInterestRate, onChange: e => setCrTrailingInterestRate(Number(e.target.value)), required: true, "data-testid": 'cr-interest-input' })))));
};
export default CommissionRatesTrailing;
