import React from 'react';
import Avatar from './Avatar';
const AvatarWrapper = ({ currentAvatarUrl, initialsAvatarUrl, removeAvatar, selectedFileUrl, handleClick }) => {
    if (removeAvatar) {
        return (React.createElement(Avatar, { imageUrl: initialsAvatarUrl, testId: 'initials-avatar', handleClick: handleClick }));
    }
    if (selectedFileUrl) {
        return (React.createElement(Avatar, { imageUrl: selectedFileUrl, testId: 'preview-avatar', handleClick: handleClick }));
    }
    return (React.createElement(Avatar, { imageUrl: currentAvatarUrl, testId: 'current-avatar', handleClick: handleClick }));
};
export default AvatarWrapper;
