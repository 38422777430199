import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { buildUrlQuery } from '../../../utils/url';
import CustomFlatpickr from '../../shared/CustomFlatpickr';
const Panel = ({ isOpened, status = 'processed' }) => {
    const [dateRange, setDateRange] = useState('');
    const handleSelectedDateChange = (selectedDates, dateString) => {
        setDateRange(dateString);
    };
    const flatpickrSettings = {
        wrap: true,
        altInput: true,
        altFormat: 'd M Y',
        dateFormat: 'Y-m-d',
        mode: 'range',
        onChange: handleSelectedDateChange
    };
    const dateClearRef = useRef(null);
    const datePickerRef = useRef(null);
    const baseUrl = '/admin/trade_loan_repayments';
    const filterUrl = buildUrlQuery({
        status: status,
        date_range: dateRange
    }, baseUrl);
    const panelClass = classNames('panel', {
        '-active': isOpened
    });
    return (React.createElement("div", { className: panelClass, "data-testid": 'panel' },
        React.createElement("div", { className: 'dropdowns' },
            React.createElement("div", { className: 'form-group -collapse' },
                React.createElement("div", { className: 'label -double-spaced' }, "Filter By"),
                React.createElement("label", { className: 'label -above-spaced -uppercase -small' }, "Date Range"),
                React.createElement(CustomFlatpickr, { ...{ flatpickrSettings }, forwardDatePickerRef: datePickerRef, forwardDateClearRef: dateClearRef, selectedDate: '', pickerBackground: 'white', placeholderText: 'Filter by date', clearButtonText: 'Clear Date', name: 'date_range' })),
            React.createElement("div", { className: 'action-buttons' },
                React.createElement("a", { className: 'button -cancel -border -loose -loose-panel', href: filterUrl }, "Reset"),
                React.createElement("a", { className: 'button -primary -loose -loose-panel', href: filterUrl }, "Apply Filter")))));
};
export default Panel;
