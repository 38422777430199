import React from 'react';
import CompanyName from '../CompanyName';
const ApproveCustomerByAdmin = ({ event }) => {
    const { attributes } = event;
    const { companyName, createdAtFormatted, customerLink, eventable: { attributes: { name: customerName } }, totalAmountDue } = attributes;
    return (React.createElement("div", { className: 'event-box event', "data-testid": 'approve-customer-by-admin' },
        React.createElement("div", { className: 'icon' },
            React.createElement("div", { className: 'activity-icon -approve-customer' })),
        React.createElement("div", { className: 'description' },
            React.createElement("div", { className: 'title' },
                "Earlypay has approved ",
                React.createElement("a", { href: customerLink }, customerName),
                ' ',
                "increasing eligible invoices for",
                ' ',
                React.createElement(CompanyName, { name: companyName, companyLink: customerLink }),
                " by",
                ' ',
                React.createElement("span", { className: 'amount -green' }, totalAmountDue)),
            React.createElement("div", { className: 'date' }, createdAtFormatted))));
};
export default ApproveCustomerByAdmin;
