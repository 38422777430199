import fetch from 'isomorphic-fetch';
import queryString from 'query-string';
export const fetchCustomers = async (apiToken, page, sortBy, sortDirection, searchQuery, filterOption) => {
    const params = {
        page,
        ...(sortBy && { sort_by: sortBy }),
        ...(sortDirection && { sort_direction: sortDirection }),
        ...(filterOption && { filter_option: filterOption }),
        ...(searchQuery && { search_query: searchQuery })
    };
    const search = queryString.stringify(params);
    const endpoint = `/api/borrower/customers?${search}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const selectCustomer = async (apiToken, customerId) => {
    const endpoint = `/api/borrower/customers/${customerId}/select`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const deselectCustomer = async (apiToken, customerId) => {
    const endpoint = `/api/borrower/customers/${customerId}/deselect`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const deselectAllCustomers = async (apiToken) => {
    const endpoint = `/api/borrower/customers/deselect_all`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const selectAllCustomers = async (apiToken) => {
    const endpoint = `/api/borrower/customers/select_all`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const fetchFundingSummary = async (apiToken) => {
    const endpoint = `/api/borrower/customers/fetch_funding_summary`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
// single customer
export const fetchCustomer = async (apiToken, customerId, borrowerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
