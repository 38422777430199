import React, { useState, useRef } from 'react';
import UnassignedTransactionRow from './UnassignedTransactionRow';
import HeaderButtons from './HeaderButtons';
const UnassignedTransactionsTable = ({ csrfToken, path, allocatePath, transactions, companyOptions, apiToken }) => {
    const [unassignedTransactions, setUnassignedTransactions] = useState(transactions);
    const [selectedTransactionIds, setSelectedTransactionIds] = useState([]);
    const headers = [
        'ID',
        'Description',
        'Bank',
        'Client',
        'Date',
        'Amount',
        'Status'
    ];
    const allCheckboxRef = useRef(null);
    const handleAllCheckboxChange = (e) => {
        if (e.target.checked) {
            const allTransactionIds = transactions.map(t => t.id);
            setSelectedTransactionIds(allTransactionIds);
        }
        else {
            setSelectedTransactionIds([]);
        }
    };
    const handleTransactionId = transactionId => {
        const newIds = [...selectedTransactionIds];
        if (isSelected(transactionId)) {
            newIds.splice(newIds.indexOf(transactionId), 1);
        }
        else {
            newIds.push(transactionId);
        }
        setSelectedTransactionIds(newIds);
        const isAllSelected = newIds.length === transactions.length;
        toggleAllCheckbox(isAllSelected);
    };
    const selectedIdString = () => {
        return selectedTransactionIds.join(',');
    };
    const isSelected = transactionId => {
        return selectedTransactionIds.includes(transactionId);
    };
    const toggleAllCheckbox = checked => {
        if (allCheckboxRef && allCheckboxRef.current) {
            allCheckboxRef.current.checked = checked;
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'header -space-between' },
            "Transaction",
            unassignedTransactions.length !== 0 && (React.createElement(HeaderButtons, { ...{
                    csrfToken,
                    path,
                    allocatePath,
                    companyOptions,
                    apiToken,
                    unassignedTransactions,
                    selectedTransactionIds,
                    setUnassignedTransactions,
                    setSelectedTransactionIds
                }, selectedIdString: selectedIdString() }))),
        React.createElement("div", { "data-testid": 'unassigned-transactions-table' },
            React.createElement("table", { className: 'custom-table' },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: 'header' },
                            React.createElement("label", { className: 'label -checkbox' },
                                React.createElement("input", { type: 'checkbox', ref: allCheckboxRef, onChange: handleAllCheckboxChange, disabled: unassignedTransactions.length === 0, "data-testid": 'all-checkbox' }))),
                        headers.map(header => (React.createElement("th", { className: 'header', key: header }, header))))),
                React.createElement("tbody", null, unassignedTransactions.length !== 0 ? (unassignedTransactions.map(t => (React.createElement(UnassignedTransactionRow, { transaction: t, selected: isSelected(t.id), handleTransactionId: handleTransactionId, key: t.id })))) : (React.createElement("tr", { "data-testid": 'no-record' },
                    React.createElement("td", { className: 'placeholder', colSpan: 6 }, "There are no transactions to display"))))))));
};
export default UnassignedTransactionsTable;
