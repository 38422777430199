import React from 'react';
import UserRole from './UserRole';
const Contact = ({ user, isAdmin, isPrimary }) => {
    return (React.createElement("div", { className: 'contact -assigned-admin' },
        React.createElement("div", { className: 'avatar' },
            React.createElement("img", { src: user.avatarUrl })),
        React.createElement("div", { className: 'details' },
            React.createElement("div", { className: 'name' }, `${user.firstName} ${user.lastName}`),
            React.createElement("div", { className: 'roles' },
                isPrimary && React.createElement(UserRole, { role: 'primary', "data-testid": 'user-role' }),
                isAdmin && (React.createElement(UserRole, { role: user.adminRole, "data-testid": 'user-role' }))))));
};
export default Contact;
