export const fileNameFromUrl = (url) => {
    return url
        .split('/')
        .reverse()[0]
        .split('?')[0];
};
export const versionUrl = (url, version) => {
    const pieces = url.split('/');
    return pieces
        .map((piece, index) => {
        if (index === pieces.length - 1) {
            return `${version}_${piece}`;
        }
        else {
            return piece;
        }
    })
        .reduce((acc, piece) => `${acc}/${piece}`);
};
export const isImageUrl = (url) => {
    const fileName = fileNameFromUrl(url);
    const regex = /.(jpg|jpeg|png|gif|tiff|bmp)$/;
    return regex.test(fileName);
};
export const isImageFile = (file) => {
    const mimeTypes = [
        'image/jpeg',
        'image/png',
        'image/tiff',
        'image/gif',
        'image/bmp'
    ];
    return mimeTypes.includes(file.type);
};
// converter snippet based on https://stackoverflow.com/a/46639837
export const fileToBase64 = (file) => {
    if (file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    return Promise.resolve(null);
};
export const removeDuplicateFiles = (files) => {
    return [...new Set(files.map((file) => file.name))].reduce((acc, name) => {
        const searchedFile = files.find((file) => file.name === name);
        if (searchedFile) {
            return [...acc, searchedFile];
        }
        else {
            return [...acc];
        }
    }, []);
};
