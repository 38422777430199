import React from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
const TextTooltip = ({ text, tooltipText, tooltipId, tooltipDirection = 'bottom', activeTheme = 'default', textTheme = 'default' }) => {
    const themes = {
        light: {
            backgroundColor: '#ffffff',
            textColor: '#091e42',
            border: true,
            borderColor: '#c1c7d0'
        },
        default: {
            backgroundColor: '#172b4d'
        }
    };
    const textClass = classNames('text', {
        '-danger': textTheme === 'danger'
    });
    return (React.createElement("div", { className: 'custom-tooltip -text-tooltip -no-left-margin -fit-content', "data-testid": 'text-tooltip' },
        React.createElement("div", { className: textClass, "data-tip": true, "data-for": tooltipId }, text),
        React.createElement(ReactTooltip, { ...themes[activeTheme], id: tooltipId, place: tooltipDirection, effect: 'solid' }, tooltipText)));
};
export default TextTooltip;
