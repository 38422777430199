import React, { useState } from 'react';
import Modal from '../shared/Modal';
const ExportedFilesDownloadModal = ({ label = 'Export Debtors', debtorFiles, generateExportedFilePath }) => {
    const [isVisible, setIsVisible] = useState(false);
    const toggleModal = () => isVisible ? setIsVisible(false) : setIsVisible(true);
    const truncateFilename = (fullStr, strLen = 55, separator = '...', frontChars = 28, backChars = 24) => {
        if (fullStr.length <= strLen)
            return fullStr;
        return (fullStr.substring(0, frontChars) +
            separator +
            fullStr.substring(fullStr.length - backChars));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("a", { onClick: toggleModal, className: 'icon-button button -text-primary -rectangular -border-brand-a-400', "data-testid": 'invoice-pdfs-modal-button' }, label),
        React.createElement(Modal, { isOpen: isVisible, onClose: toggleModal, contentSize: 'md', dataTestId: 'invoice-pdfs-modal' },
            React.createElement("div", { className: 'custom-panel -shadow-none' },
                React.createElement("div", { className: 'header -space-between' },
                    React.createElement("div", null, label),
                    React.createElement("div", { className: 'icon-button -close -modal', onClick: toggleModal },
                        React.createElement("div", { className: 'icon' }))),
                React.createElement("div", { className: 'content -adjust-height custom-scrollbar' },
                    React.createElement("table", { className: 'custom-table -outline', "data-testid": 'invoice-pdfs-table' },
                        React.createElement("thead", { className: 'table-headers' },
                            React.createElement("tr", { className: 'row' },
                                React.createElement("th", { className: 'header' }, "File name & created date"),
                                React.createElement("th", { className: 'header -right' },
                                    React.createElement("a", { href: generateExportedFilePath, className: 'custom-button -loose -blue -refresh' }, "Generate New File")))),
                        React.createElement("tbody", null, debtorFiles.map(debtorFile => (React.createElement("tr", { key: debtorFile.id, className: '-nowrap' },
                            React.createElement("td", { className: 'cell' },
                                React.createElement("div", null, truncateFilename(debtorFile.fileName)),
                                React.createElement("div", { className: 'sub' }, debtorFile.createdAt)),
                            React.createElement("td", { className: 'cell -flex-center' },
                                React.createElement("a", { href: debtorFile.url, className: 'icon-button -rectangular -border-brand-a-400 -text-brand-a-400' }, "Download"))))))))))));
};
export default ExportedFilesDownloadModal;
