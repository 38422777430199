import React, { useState, Fragment } from 'react';
import IconToggle from '../shared/IconToggle';
import { formatPrice } from '../../utils/formatPrice';
import AccordionTable from './AccordionTable';
import classNames from 'classnames';
const PaymentRow = ({ payment, handlePaymentId, selected }) => {
    const [isAccordionClose, setIsAccordionClose] = useState(true);
    const toggleClose = () => {
        isAccordionClose ? setIsAccordionClose(false) : setIsAccordionClose(true);
    };
    const { id, accountName, amountCents, customerName, customerUrl, customerApproved, invoicesCount, date, memo } = payment;
    const handleCheckboxChange = () => {
        handlePaymentId(id);
    };
    const arrowClass = classNames('icon', {
        '-closed': isAccordionClose,
        '-open': !isAccordionClose
    });
    const cellClass = classNames({
        cell: isAccordionClose,
        'cell -color-neutral-10': !isAccordionClose
    });
    return (React.createElement(Fragment, null,
        React.createElement("tr", null,
            React.createElement("td", { className: cellClass, onClick: handleCheckboxChange },
                React.createElement("label", { className: 'label -checkbox' },
                    React.createElement("input", { type: 'checkbox', checked: selected, onChange: handleCheckboxChange, "data-testid": `payments-checkbox-${id}` }))),
            React.createElement("td", { className: cellClass }, id),
            React.createElement("td", { className: cellClass }, date),
            React.createElement("td", { className: cellClass },
                React.createElement("a", { href: customerUrl }, customerName)),
            React.createElement("td", { className: cellClass }, accountName),
            React.createElement("td", { className: cellClass }, memo),
            React.createElement("td", { className: cellClass }, formatPrice(amountCents / 100)),
            React.createElement("td", { className: cellClass },
                ' ',
                React.createElement(IconToggle, { isOn: customerApproved, onIcon: 'check', offIcon: 'close' })),
            React.createElement("td", { className: cellClass }, invoicesCount),
            React.createElement("td", { className: cellClass, onClick: toggleClose },
                React.createElement("i", { className: arrowClass }))),
        React.createElement(AccordionTable, { invoices: payment.invoicePayments, isClose: isAccordionClose })));
};
export default PaymentRow;
