import React from 'react';
import AdminClientRow from './AdminClientRow';
const AdminClientsTableBody = ({ apiToken, brokerCompanyId, isLoading, clientsArray }) => {
    if (isLoading) {
        return (React.createElement("tbody", { "data-testid": 'loading' },
            React.createElement("tr", null,
                React.createElement("td", { className: 'cell', colSpan: 7 },
                    React.createElement("div", { className: 'loading-container -table' },
                        React.createElement("div", { className: 'loading-spinner' }))))));
    }
    if (clientsArray.length === 0) {
        return (React.createElement("tbody", { "data-testid": 'no-record' },
            React.createElement("tr", null,
                React.createElement("td", { className: 'cell', colSpan: 7 }, "There are no Clients to display"))));
    }
    return (React.createElement("tbody", { "data-testid": 'loaded' }, clientsArray.map((client) => {
        return (React.createElement(AdminClientRow, { ...{
                apiToken,
                brokerCompanyId,
                client
            }, key: client.id }));
    })));
};
export default AdminClientsTableBody;
