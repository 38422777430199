import React from 'react';
import AmountField from './AmountField';
import CompanyName from '../CompanyName';
const ApproveDrawdown = ({ event }) => {
    const { attributes } = event;
    const { createdAtFormatted, companyName, companyLink, eventable: { attributes: { amount, gst, drawdownFee, netAmount, destinationAccountDetail } } } = attributes;
    return (React.createElement("div", { className: 'event-box event', "data-testid": 'approve-drawdown-activity' },
        React.createElement("div", { className: 'icon' },
            React.createElement("div", { className: 'activity-icon -approve-drawdown' })),
        React.createElement("div", { className: 'description' },
            React.createElement("div", { className: 'title' },
                "Earlypay approved",
                React.createElement("span", { className: 'bold' }, " drawdown "),
                "of",
                React.createElement("span", { className: 'amount -green' },
                    " ",
                    amount,
                    " "),
                "for ",
                React.createElement(CompanyName, { name: companyName, companyLink: companyLink })),
            React.createElement("div", { className: 'date' },
                " ",
                createdAtFormatted,
                " "),
            React.createElement("div", { className: 'event-breakdown -drawdown' },
                React.createElement("div", { className: 'info' },
                    React.createElement("div", { className: 'field' },
                        React.createElement("div", { className: 'box' },
                            React.createElement("div", { className: 'label' }, " Total "),
                            React.createElement("div", { className: 'text' },
                                " ",
                                amount,
                                " "))),
                    React.createElement(AmountField, { label: 'Drawdown Fee', classVariant: '-red', amount: drawdownFee }),
                    React.createElement(AmountField, { label: 'GST', classVariant: '-red', amount: gst }),
                    React.createElement(AmountField, { label: 'Net Amount', classVariant: '-green', amount: netAmount }),
                    React.createElement("div", { className: 'field' },
                        React.createElement("div", { className: 'label' }, " Destination Account "),
                        React.createElement("div", { className: 'text' },
                            " ",
                            destinationAccountDetail,
                            " ")))))));
};
export default ApproveDrawdown;
