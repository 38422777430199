import React, { useState } from 'react';
import classNames from 'classnames';
import Drawer from '../shared/Drawer';
import NumberField from '../shared/NumberField';
import { convertCentsString, convertCents } from '../../utils/convertCents';
import ToggleMenu from '../toggle-menu/ToggleMenu';
const AvailabilityAdjustmentDrawer = ({ availabilityAdjustmentFormatted, path, csrfToken, adjustments }) => {
    const [isOpen, setIsOpen] = useState(false);
    const initialAvailabilityAdjustment = {
        formattedValue: '',
        value: convertCentsString(0),
        floatValue: convertCents(0)
    };
    const [availabilityAdjustment, setAvailabilityAdjustment] = useState(initialAvailabilityAdjustment);
    const handleAvailabilityAdjustmentChange = (value) => {
        setAvailabilityAdjustment(value);
    };
    const submitButtonClass = classNames('button -primary', {
        '-disabled': availabilityAdjustment.floatValue === undefined ||
            availabilityAdjustment.floatValue === 0
    });
    const links = id => [
        {
            title: 'Delete',
            path: `${path}/${id}/archive`,
            icon: '-delete',
            method: 'put'
        }
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'shadow-reconciliation-row -clickable', onClick: () => setIsOpen(true) },
            React.createElement("div", { className: 'label' },
                "Availability Adjustment ",
                React.createElement("div", { className: 'custom-icon -open-outline' })),
            React.createElement("div", { className: 'value' }, availabilityAdjustmentFormatted)),
        React.createElement(Drawer, { isOpen: isOpen, onClose: () => setIsOpen(false) },
            React.createElement("form", { acceptCharset: 'UTF-8', method: 'post', action: path, className: 'form' },
                React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
                React.createElement("div", { className: 'cash-received-drawer' },
                    React.createElement("div", { className: 'actions' },
                        React.createElement("button", { className: 'back', onClick: () => setIsOpen(false) }, "Go Back")),
                    React.createElement("div", { className: 'custom-panel' },
                        React.createElement("div", { className: 'data-figure -center -p-8' },
                            React.createElement("div", { className: 'label eligible' }, "Total Availability Adjustment Amount"),
                            React.createElement("div", { className: 'data' }, availabilityAdjustmentFormatted))),
                    React.createElement("div", { className: 'custom-panel' },
                        React.createElement("div", { className: 'header' }, "Create Availability Adjustment"),
                        React.createElement("div", { className: 'content' },
                            React.createElement(NumberField, { model: 'availability_adjustment', attribute: 'amount', onValueChange: handleAvailabilityAdjustmentChange, defaultValue: availabilityAdjustment.value }),
                            React.createElement("div", { className: 'form-group group' },
                                React.createElement("label", { htmlFor: 'reserve-amount-note', className: 'label -uppercase' }, "Note"),
                                React.createElement("textarea", { className: 'input form-control -textarea', name: 'availability_adjustment[notes]', defaultValue: '' })),
                            React.createElement("div", { className: 'action-buttons buttons space -mt-3 -space-between' },
                                React.createElement("button", { className: 'button -neutral', onClick: () => setIsOpen(false) }, "Cancel"),
                                React.createElement("button", { className: submitButtonClass, type: 'submit' }, "Save")))),
                    adjustments.map(({ amountFormatted, notes, date, createdBy, id }) => (React.createElement("div", { className: 'custom-panel', key: id },
                        React.createElement("div", { className: 'actions -space-between -row -bt-0' },
                            React.createElement("div", null,
                                React.createElement("div", { className: 'form-group' },
                                    React.createElement("div", { className: 'label -uppercase' }, "Amount"),
                                    React.createElement("div", { className: 'label-large' }, amountFormatted))),
                            React.createElement("div", null,
                                React.createElement(ToggleMenu, { links: links(id), ...{ csrfToken, icon: 'ellipsis-white' } }))),
                        React.createElement("div", { className: 'content -px-4' },
                            React.createElement("div", { className: 'section' },
                                React.createElement("div", { className: 'subtitle' }, "Note"),
                                React.createElement("div", { className: 'description' }, notes))),
                        React.createElement("div", { className: 'actions -space-between -row' },
                            React.createElement("div", null,
                                React.createElement("div", { className: 'form-group' },
                                    React.createElement("div", { className: 'label -uppercase' }, "Created By"),
                                    React.createElement("div", { className: 'label-large' }, createdBy))),
                            React.createElement("div", null,
                                React.createElement("div", { className: 'form-group' },
                                    React.createElement("div", { className: 'label -uppercase' }, "Date"),
                                    React.createElement("div", { className: 'label-large' }, date))))))))))));
};
export default AvailabilityAdjustmentDrawer;
