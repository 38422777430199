import fetch from 'isomorphic-fetch';
import { buildHeaders } from './shared';
export const deleteMultipleTradeLoanRepayments = async (apiToken, ids) => {
    const params = {
        trade_loan_ids: ids
    };
    const endpoint = `/api/admin/trade_loan_repayments/delete_multiple_trade_loan_repayments`;
    const headers = buildHeaders(apiToken);
    return fetch(endpoint, {
        headers,
        method: 'DELETE',
        body: JSON.stringify(params)
    }).then((res) => (res.status === 204 ? res : res.json()));
};
