import React from 'react';
import classNames from 'classnames';
import PaginationBar from '../shared/PaginationBar';
import SortableTabs from '../table-headers/SortableTabs';
import DrawdownCheckboxRow from './DrawdownCheckboxRow';
import { randomEmptyMessage } from '../../utils';
const DrawdownCheckboxTable = ({ tableName, sortDrawdown, drawdownArray, totalPages, pageSize, totalItems, currentPage, isLoading, path, abaPath, handlePageClick, processedDrawdown, fetchDrawdownsData, selectedDrawdownIds, selectedANZDrawdownIds, handleDrawdownId, isSelected, handleAllCheckboxChange, allCheckboxRef }) => {
    const disabled = selectedDrawdownIds.length === 0;
    const textClass = classNames('text', {
        '-neutral-100-text': selectedDrawdownIds.length === 0,
        '-neutral-800-text': selectedDrawdownIds.length > 0
    });
    const buttonClass = classNames('icon-button button -drawdown -download -rectangular', {
        '-download-neutral-50 -background-neutral-20 ': selectedDrawdownIds.length === 0,
        '-download-neutral-800 -background-neutral-30': selectedDrawdownIds.length > 0
    });
    const headerFields = [
        {
            text: '',
            type: 'checkbox',
            checkboxRef: allCheckboxRef
        },
        { text: 'ID', field: 'id', defaultSort: 'desc' },
        { text: 'Company Name' },
        { text: 'Approved At' },
        { text: 'Amount' },
        { text: 'Fees' },
        { text: 'Net Amount' },
        { text: 'Payment Reference' },
        { text: 'Funder' },
        { text: 'Destination Account' },
        { text: 'Action' }
    ];
    const emptyMessageArray = [
        'Woohoo! No Drawdowns to process!',
        'Yay! Nothing to do!',
        'Relax! Nothing to see here.'
    ];
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'drawdown-table' },
        React.createElement("div", { className: 'header -space-between' },
            tableName,
            disabled ? (React.createElement("button", { className: buttonClass, disabled: disabled, "data-testid": 'disable-bulk-aba-button' },
                React.createElement("i", { className: 'icon' }),
                React.createElement("div", { className: textClass }, "BULK ABA"))) : (React.createElement("a", { className: buttonClass, href: `${abaPath}?ids=${selectedANZDrawdownIds}&single_aba=false`, "data-testid": 'bulk-aba-button' },
                React.createElement("i", { className: 'icon' }),
                React.createElement("div", { className: textClass }, "BULK ABA")))),
        React.createElement("table", { className: 'custom-table', "data-testid": 'table-drawdown-checkbox' },
            !isLoading && (React.createElement(SortableTabs, { isLoading: false, headerFields: headerFields, sortItems: sortDrawdown, checkboxOnChange: handleAllCheckboxChange, sortBy: 'id', sortDirection: 'desc' })),
            isLoading ? (React.createElement("tbody", { "data-testid": 'loading' },
                React.createElement("tr", null,
                    React.createElement("td", { className: 'cell', colSpan: 12 },
                        React.createElement("div", { className: 'loading-container -table' },
                            React.createElement("div", { className: 'loading-spinner' })))))) : (React.createElement("tbody", null, drawdownArray.length !== 0 ? (drawdownArray.map(drawdown => (React.createElement(DrawdownCheckboxRow, { key: drawdown.id, selected: isSelected(drawdown.id), ...{
                    drawdown,
                    handleDrawdownId,
                    processedDrawdown,
                    path,
                    fetchDrawdownsData,
                    abaPath
                } })))) : (React.createElement("tr", { "data-testid": 'no-record' },
                React.createElement("td", { className: 'cell', colSpan: 12 },
                    React.createElement("div", { className: 'loading-container -table' }, randomEmptyMessage(emptyMessageArray)))))))),
        totalItems > pageSize && (React.createElement(PaginationBar, { ...{
                currentPage,
                totalPages,
                totalItems,
                pageSize,
                handlePageClick
            }, resource: tableName }))));
};
export default DrawdownCheckboxTable;
