import React from 'react';
import BankTransactions from './BankTransactions';
const XeroReconcileStep = ({ bankTransactions }) => {
    if (bankTransactions === undefined) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("h3", { className: 'text -medium', "data-testid": 'xero-reconcile-step' }, "Please reconcile your bank transactions"),
        React.createElement("h5", { className: 'text' }, "The below payments into the Earlypay Collections Account are unreconciled."),
        React.createElement(BankTransactions, { bankTransactions: bankTransactions || [] }),
        React.createElement("h5", { className: 'text' }, "Please reconcile before creating a drawdown or contact your Account Manager if you have any questions."),
        React.createElement("div", { className: 'actions action-buttons' },
            React.createElement("a", { className: 'button -primary', onClick: () => window.location.reload() }, "Check again"))));
};
export default XeroReconcileStep;
