import React from 'react';
const CustomerInvoicesSummary = ({ customer, customerStatementLink, isManualLedger = false, isDebtorsLedgerRecalculating = false }) => {
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'customer-invoices-summary' },
        React.createElement("div", { className: 'header -space-between' },
            React.createElement("div", { className: 'text' }, "Summary"),
            React.createElement("div", { className: 'text' },
                React.createElement("a", { href: customerStatementLink }, "View Debtor Statement"))),
        isManualLedger ? (React.createElement("table", { className: 'custom-table' },
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Gross Receivables Ledger"),
                    React.createElement("td", { className: 'value' }, customer.grossReceivablesLedgerFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Pending Invoices"),
                    React.createElement("td", { className: 'value' }, customer.pendingInvoicesFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Ineligible Adjustment"),
                    isDebtorsLedgerRecalculating ? (React.createElement("td", { className: 'value -font-size-1' },
                        React.createElement("div", { className: 'badge-risk -medium' }, "Processing"))) : (React.createElement("td", { className: 'value' }, customer.ineligibleAdjustmentsFormatted))),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical -pl-12' }, "Disapproved Invoices"),
                    React.createElement("td", { className: 'value' }, customer.disapprovedInvoicesFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical -pl-12' }, "Debtor Limit Excess"),
                    React.createElement("td", { className: 'value' }, customer.debtorLimitExcessFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical -pl-12' }, "Concentration Limit Excess"),
                    React.createElement("td", { className: 'value' }, customer.concentrationLimitExcessFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Approved Ledger"),
                    React.createElement("td", { className: 'value' }, customer.approvedLedgerFormatted))))) : (React.createElement("table", { className: 'custom-table' },
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Total Receivables"),
                    React.createElement("td", { className: 'value' }, customer.receivablesFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Approved Receivables"),
                    React.createElement("td", { className: 'value' }, customer.approvedReceivablesFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Unapproved Receivables"),
                    React.createElement("td", { className: 'value' }, customer.unapprovedReceivablesFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Concentration Adjustment"),
                    React.createElement("td", { className: 'value' }, customer.concentrationAdjustmentFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Verified Receivables"),
                    React.createElement("td", { className: 'value' }, customer.verifiedReceivablesFormatted)))))));
};
export default CustomerInvoicesSummary;
