import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import StepWrapper from './StepWrapper';
import CommissionDetails from './CommissionDetails';
import AddessLine from './AddressLine';
import { SaveClientCommissions } from '../../api/commission';
import { dropdownStyles } from '../../custom-styles/dropdown';
import { last24Months, monthLong } from '../../utils';
import format from 'date-fns/format';
import { showNotifyToast } from '../../utils';
const CommissionForm = ({ apiToken, currentStep, logoPath, broker, email, clientOptions = [], initiateSave, backLink, brokerAddress }) => {
    const [isSaving, setIsSaving] = useState(false);
    useEffect(() => {
        if (initiateSave && !isSaving) {
            setIsSaving(true);
            SaveCommissions();
        }
    }, [initiateSave]);
    const { type, month } = broker;
    const buildInitial = (options, defaultValue) => {
        return options.find(data => data.value === defaultValue) || options[1];
    };
    const typeOptions = [
        {
            value: 'aggregator_commission',
            label: 'Aggregator Commission',
            isDisabled: true
        },
        { value: 'trailing_commission', label: 'Trailing Commission' }
    ];
    const monthOptions = last24Months().map(m => {
        const labelFormatted = format(m, `MMMM yyyy`);
        const valueFormatted = format(m, `MMMM dd, yyyy`);
        return { value: valueFormatted, label: labelFormatted };
    });
    const [selectedClients, setSelectedClients] = useState({
        item0: {
            id: '',
            amount: '0',
            gstAmount: '0',
            status: 'initial'
        }
    });
    const [totalTally, setTotalTally] = useState({
        amount: '0',
        gstAmount: '0',
        all: '0.00'
    });
    const [typeOption, setTypeOption] = useState(buildInitial(typeOptions, type));
    const [monthOption, setMonthOption] = useState(buildInitial(monthOptions, month));
    const handleTypeChange = (selectedOption) => {
        setTypeOption(selectedOption);
    };
    const handleMonthChange = (selectedOption) => {
        setMonthOption(selectedOption);
    };
    const SaveCommissions = async () => {
        try {
            const targetDate = format(new Date(monthOption.value), `yyyy-MM-dd`);
            const filteredClients = Object.values(selectedClients).filter(data => data.status === 'ok');
            const response = await SaveClientCommissions(apiToken, broker.id, targetDate, filteredClients);
            if (response.status === 'created') {
                showNotifyToast({
                    text: `Commissions successfully created`,
                    type: 'success',
                    redirectLink: backLink
                });
            }
            else {
                setIsSaving(false);
            }
        }
        catch (err) {
            showNotifyToast({
                text: `Something went wrong while saving client's commissions. Please try again.`,
                type: 'error'
            });
            setIsSaving(false);
        }
    };
    const today = format(new Date(), `dd MMMM yyyy`);
    return (React.createElement("div", { className: 'form', "data-testid": 'commission-form' },
        React.createElement(StepWrapper, { currentStep: currentStep, stepNumber: 1 },
            React.createElement("div", { className: 'custom-panel -commission' },
                React.createElement("div", { className: 'body -padded' },
                    React.createElement("div", { className: 'form-group -collapse' },
                        React.createElement("div", { className: 'form-row -col-2' },
                            React.createElement("div", { className: 'form-group' },
                                React.createElement("label", { className: 'label -uppercase -required' }, " Type "),
                                React.createElement(Select, { value: typeOption, onChange: handleTypeChange, options: typeOptions, className: 'select', styles: dropdownStyles, name: 'broker[type]' })),
                            React.createElement("div", { className: 'form-group' },
                                React.createElement("label", { className: 'label -uppercase -required' }, " Month "),
                                React.createElement(Select, { value: monthOption, onChange: handleMonthChange, options: monthOptions, className: 'select', styles: dropdownStyles, name: 'broker[month]' }))))))),
        React.createElement(StepWrapper, { currentStep: currentStep, stepNumber: 2 },
            React.createElement("div", { className: 'broker-statement' },
                React.createElement("div", { className: 'header' },
                    React.createElement("div", { className: 'logo' },
                        React.createElement("img", { src: logoPath }),
                        React.createElement("p", null, "earlypay.com.au")),
                    React.createElement("div", { className: 'details' },
                        React.createElement("p", null, "1300 760 205"),
                        React.createElement("p", null, "hello@earlypay.com.au "),
                        React.createElement("p", null, "Suite 1, Level 5, 201 Miller St,"),
                        React.createElement("p", null, "North Sydney NSW 2060"),
                        React.createElement("p", null,
                            "Date: ",
                            today))),
                React.createElement("div", { className: 'body' },
                    React.createElement("div", { className: 'header' },
                        ' ',
                        "Broker Statement for ",
                        monthLong(new Date(monthOption.value)),
                        ' '),
                    React.createElement("div", { className: 'info' },
                        React.createElement("p", null, "Earlypay Cashflow Finance Pty Ltd"),
                        React.createElement("p", null, "ABN: 97 093 756 524"),
                        React.createElement("p", null, "Suite 1, Level 5, 201 Miller St,"),
                        React.createElement("p", null, "North Sydney NSW 2060")),
                    React.createElement("div", { className: 'header' }, " Recipient Created Tax Invoice "),
                    React.createElement("div", { className: 'info' },
                        React.createElement("p", null, broker.name),
                        React.createElement("p", null,
                            "ABN: ",
                            broker.registrationNumber),
                        React.createElement(AddessLine, { ...brokerAddress }),
                        React.createElement("p", null,
                            "Email: ",
                            email)),
                    React.createElement(CommissionDetails, { ...{
                            broker,
                            apiToken,
                            monthOption,
                            clientOptions,
                            selectedClients,
                            setSelectedClients,
                            totalTally,
                            setTotalTally
                        }, isPreview: false }),
                    React.createElement("div", { className: 'footer' },
                        React.createElement("p", { className: 'label' },
                            ' ',
                            "Many thanks for your support of our business."),
                        React.createElement("div", { className: 'details' },
                            React.createElement("p", null, "Please contact us at any time if you believe we may be able to assist you or your client with any financial needs. We look forward to continuing business with you.")),
                        React.createElement("div", { className: 'info' },
                            React.createElement("p", null, "Earlypay Cashflow Finance Pty Ltd (ABN 97 093 756 524) and Classic Funding Group Pty Ltd (ABN 84 057 766 551) are Earlypay Ltd (ABN 88 098 952 277) group companies")))))),
        React.createElement(StepWrapper, { currentStep: currentStep, stepNumber: 3 },
            React.createElement("div", { className: 'broker-statement' },
                React.createElement("div", { className: 'header' },
                    React.createElement("div", { className: 'logo' },
                        React.createElement("img", { src: logoPath }),
                        React.createElement("p", null, "earlypay.com.au")),
                    React.createElement("div", { className: 'details' },
                        React.createElement("p", null, "1300 760 205"),
                        React.createElement("p", null, "hello@earlypay.com.au "),
                        React.createElement("p", null, "Suite 1, Level 5, 201 Miller St,"),
                        React.createElement("p", null, "North Sydney NSW 2060"),
                        React.createElement("p", null,
                            "Date: ",
                            today))),
                React.createElement("div", { className: 'body' },
                    React.createElement("div", { className: 'header' },
                        ' ',
                        "Broker Statement for ",
                        monthLong(new Date(monthOption.value)),
                        ' '),
                    React.createElement("div", { className: 'info' },
                        React.createElement("p", null, "Earlypay Ltd"),
                        React.createElement("p", null, "ABN: 88 098 952 277"),
                        React.createElement("p", null, "Suite 1, Level 5, 201 Miller St,"),
                        React.createElement("p", null, "North Sydney NSW 2060")),
                    React.createElement("div", { className: 'header' }, " Recipient Created Tax Invoice "),
                    React.createElement("div", { className: 'info' },
                        React.createElement("p", null, broker.name),
                        React.createElement("p", null,
                            "ABN: ",
                            broker.registrationNumber),
                        React.createElement("p", null, broker.address),
                        React.createElement(AddessLine, { ...brokerAddress }),
                        React.createElement("p", null,
                            "Email: ",
                            email)),
                    React.createElement(CommissionDetails, { ...{
                            broker,
                            apiToken,
                            monthOption,
                            clientOptions,
                            selectedClients,
                            setSelectedClients,
                            totalTally,
                            setTotalTally
                        }, isPreview: true }),
                    React.createElement("div", { className: 'footer' },
                        React.createElement("p", { className: 'label' },
                            ' ',
                            "Many thanks for your support of our business."),
                        React.createElement("div", { className: 'details' },
                            React.createElement("p", null, "Please contact us at any time if you believe we may be able to assist you or your client with any financial needs. We look forward to continuing business with you.")),
                        React.createElement("div", { className: 'info' },
                            React.createElement("p", null, "Earlypay Cashflow Finance Pty Ltd (ABN 97 093 756 524) and Classic Funding Group Pty Ltd (ABN 84 057 766 551) are Earlypay Ltd (ABN 88 098 952 277) group companies"))))))));
};
export default CommissionForm;
