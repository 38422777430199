import React, { useState, useReducer } from 'react';
import Bugsnag from '@bugsnag/js';
import { isAPIValidationErrors, isAPIValidationMultipleErrors, isAPIValidationMultipleArrayErrors, isAPIErrors } from '../../../types';
import { fetchSupplierDetailsforBorrower as defaultFetchSupplierDetailsForBorrower, validatePaymentDetails as defaultValidatePaymentDetails, validateSupplierDetails as defaultValidateSupplierDetails, validateTradeLoanDetails as defaultValidateTradeLoanDetails, validateSupportingDocuments as defaultValidateSupportingDocuments, validateTradeLoanInvoices as defaultValidateTradeLoanInvoices, createTradeLoan as defaultCreateTradeLoan } from '../../api/trade_loan';
import { showNotifyToast as defaultShowNotifyToast } from '../../utils/notifyToast';
import { isEmptyObject, isListOfEmptyRecords, is2DListOfEmptyRecords, removeDuplicateFiles, getBracketValues, snakeToCamelCase } from '../../utils';
import { compileFileNames } from '../../utils/tradeLoanFiles';
import { compileFileNames as compileInvoiceFileNames } from '../../utils/tradeLoanInvoiceFiles';
import { validateFileSize, validateTradeLoanDuplicateFile, validateNumberOfFiles } from '../../validations/file';
import SupplierDetailsReducer, { defaultSupplierDetails, defaultPaymentDetails, defaultInvoiceDetails, defaultInvoiceAmountValues } from '../../reducers/trade-loans/SupplierDetailsReducer';
import TradeLoanDetailsReducer, { companyDefaultTradeLoanDetails } from '../../reducers/trade-loans/TradeLoanDetailsReducer';
import Subheader from '../../components/trade-loan/Subheader';
import ActionButtons from '../../components/trade-loan/ActionButtons';
import TradeLoanSteps from '../../components/trade-loan/TradeLoanSteps';
import TradeLoanForm from '../../components/trade-loan/TradeLoanForm';
const TradeLoanApplicationDashboard = ({ apiToken, urlPath = 'borrower', backLink, currencyOptions, supplierCurrencyOptions, invoiceCurrencyOptions, supplierOptions, company, isAdmin = true, preloadedSuppliers, initialStep = 1, preloadedTradeLoan, enableRedirect = true, loanTermOptions, validatePaymentDetails = defaultValidatePaymentDetails, validateSupplierDetails = defaultValidateSupplierDetails, validateTradeLoanDetails = defaultValidateTradeLoanDetails, validateSupportingDocuments = defaultValidateSupportingDocuments, validateTradeLoanInvoices = defaultValidateTradeLoanInvoices, createTradeLoan = defaultCreateTradeLoan, fetchSupplierDetailsforBorrower = defaultFetchSupplierDetailsForBorrower, showNotifyToast = defaultShowNotifyToast }) => {
    const [currentStep, setCurrentStep] = useState(initialStep);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [focusedInvoiceField, setFocusedInvoiceField] = useState({
        invoiceIndex: 0,
        supplierIndex: 0,
        fieldName: ''
    });
    const findCurrencyOption = (currency) => {
        return (supplierCurrencyOptions.find((option) => option.value === currency) || null);
    };
    const clearForm = () => {
        localStorage.clear();
        if (enableRedirect) {
            window.location.reload();
        }
    };
    const saveProgress = () => {
        let invoiceAmount = [];
        const invoiceAmounts = [];
        let noFileInvoice = [];
        const noFileInvoices = [];
        suppliers.map((supplier, index) => {
            invoices[index].map((invoice) => {
                const amount = invoice.invoiceAmountCents || 0;
                const amountToPay = invoice.amountToPayCents || 0;
                invoiceAmount.push({
                    invoiceAmountCents: {
                        value: (amount / 100).toFixed(2),
                        floatValue: 0,
                        formattedValue: ''
                    },
                    amountToPayCents: {
                        value: (amountToPay / 100).toFixed(2),
                        floatValue: 0,
                        formattedValue: ''
                    }
                });
                noFileInvoice.push({
                    amountToPayCents: invoice.amountToPayCents,
                    description: invoice.description,
                    invoiceAmountCents: invoice.invoiceAmountCents,
                    invoiceDate: invoice.invoiceDate,
                    invoiceNumber: invoice.invoiceNumber,
                    paymentCurrency: invoice.paymentCurrency,
                    purchaseOrder: invoice.purchaseOrder,
                    invoiceCopy: []
                });
            });
            invoiceAmounts.push(invoiceAmount);
            invoiceAmount = [];
            noFileInvoices.push(noFileInvoice);
            noFileInvoice = [];
        });
        const TradeLoanDetails = {
            tradeLoan: {
                dateOfShipping: tradeLoan.dateOfShipping,
                descriptionOfGoods: tradeLoan.descriptionOfGoods,
                exceptions: tradeLoan.exceptions,
                maximumLoanTerm: tradeLoan.maximumLoanTerm,
                totalAmountCurrency: tradeLoan.totalAmountCurrency,
                requestDrawdownDate: tradeLoan.requestDrawdownDate,
                shippedFrom: tradeLoan.shippedFrom,
                shippedTo: tradeLoan.shippedTo,
                totalAmountCents: tradeLoan.totalAmountCents,
                otherDocuments: [],
                proFormaInvoice: [],
                purchaseOrder: [],
                transportDocument: []
            },
            paymentDetailsList,
            suppliers,
            invoices: noFileInvoices,
            invoiceErrors,
            supplierDetailsErrors,
            paymentDetailsErrors,
            invoiceAmounts
        };
        localStorage.setItem('tradeLoanDetails', JSON.stringify(TradeLoanDetails));
        showNotifyToast({
            text: 'Trade loan application saved!',
            type: 'success'
        });
    };
    const savedTradeLoanDetails = localStorage['tradeLoanDetails']
        ? localStorage.getItem('tradeLoanDetails')
        : null;
    const parsedTradeLoan = savedTradeLoanDetails
        ? JSON.parse(savedTradeLoanDetails)
        : null;
    let savedPaymentOption = [];
    const savedPaymentOptions = [];
    const savedSupplierOptions = [];
    let initialDocumentError = [];
    const initialDocumentErrors = [];
    const savedTradeLoanInfo = parsedTradeLoan
        ? {
            savedTradeLoan: parsedTradeLoan['tradeLoan'],
            savedSuppliers: parsedTradeLoan['suppliers'],
            savedPaymentDetails: parsedTradeLoan['paymentDetailsList'],
            savedInvoices: parsedTradeLoan['invoices'],
            savedAmount: (parsedTradeLoan['tradeLoan'].totalAmountCents / 100).toFixed(2),
            savedInvoiceErrors: parsedTradeLoan['invoiceErrors'],
            savedSupplierDetailErrors: parsedTradeLoan['supplierDetailsErrors'],
            savedPaymentDetailErrors: parsedTradeLoan['paymentDetailsErrors'],
            savedInvoiceAmounts: parsedTradeLoan['invoiceAmounts']
        }
        : {};
    const initializeSavedDetails = () => {
        savedTradeLoanInfo.savedSuppliers.map((supplier, index) => {
            var _a;
            savedTradeLoanInfo.savedInvoices[index].map((invoice) => {
                savedPaymentOption.push({
                    value: invoice.paymentCurrency,
                    label: invoice.paymentCurrency
                });
                initialDocumentError.push({});
            });
            savedPaymentOptions.push(savedPaymentOption);
            savedPaymentOption = [];
            initialDocumentErrors.push(initialDocumentError);
            initialDocumentError = [];
            if (supplier.id) {
                savedSupplierOptions.push({
                    value: (_a = supplier.id) === null || _a === void 0 ? void 0 : _a.toString(),
                    label: supplier.name
                });
            }
            else {
                savedSupplierOptions.push({
                    value: 'new_supplier',
                    label: 'New Supplier'
                });
            }
        });
    };
    const savedState = {
        suppliers: savedTradeLoanInfo.savedSuppliers,
        selectedSupplierOptions: savedSupplierOptions,
        selectedCurrencyOptions: [null],
        paymentDetailsList: savedTradeLoanInfo.savedPaymentDetails,
        supplierDetailsErrors: savedTradeLoanInfo.savedSupplierDetailErrors,
        paymentDetailsErrors: savedTradeLoanInfo.savedPaymentDetailErrors,
        invoices: savedTradeLoanInfo.savedInvoices,
        selectedInvoiceCurrencyOptions: savedPaymentOptions,
        invoiceAmountValues: savedTradeLoanInfo.savedInvoiceAmounts,
        invoiceErrors: savedTradeLoanInfo.savedInvoiceErrors,
        invoiceDocumentErrors: initialDocumentErrors,
        supplierDocumentErrors: [{}]
    };
    const initialState = {
        suppliers: [{ ...defaultSupplierDetails }],
        selectedSupplierOptions: [null],
        selectedCurrencyOptions: [null],
        paymentDetailsList: [{ ...defaultPaymentDetails }],
        supplierDetailsErrors: [{}],
        paymentDetailsErrors: [{}],
        invoices: [[{ ...defaultInvoiceDetails }]],
        selectedInvoiceCurrencyOptions: [[null]],
        invoiceAmountValues: [[{ ...defaultInvoiceAmountValues }]],
        invoiceErrors: [[{}]],
        invoiceDocumentErrors: [[{}]],
        supplierDocumentErrors: [{}]
    };
    const checkProgress = () => {
        if (savedTradeLoanDetails) {
            initializeSavedDetails();
            return savedState;
        }
        else {
            return initialState;
        }
    };
    const [suppliersState, dispatch] = useReducer(SupplierDetailsReducer, preloadedSuppliers || checkProgress());
    const { suppliers, paymentDetailsList, supplierDetailsErrors, paymentDetailsErrors, invoices, invoiceErrors, invoiceDocumentErrors } = suppliersState;
    const initialTradeLoanState = {
        tradeLoan: savedTradeLoanInfo.savedTradeLoan
            ? savedTradeLoanInfo.savedTradeLoan
            : { ...companyDefaultTradeLoanDetails(company) },
        amountValues: {
            totalAmountCents: {
                floatValue: 0,
                value: savedTradeLoanInfo.savedAmount
                    ? savedTradeLoanInfo.savedAmount
                    : '0.00',
                formattedValue: ''
            }
        },
        tradeLoanErrors: {},
        documentErrors: {},
        selectedPaymentCurrencyOption: null
    };
    const [tradeLoanState, tradeLoanDispatch] = useReducer(TradeLoanDetailsReducer, preloadedTradeLoan || initialTradeLoanState);
    const { tradeLoan, documentErrors } = tradeLoanState;
    const hasReusedSupplier = suppliers.filter(supplier => supplier.id).length > 0;
    const handleDateOfShippingChange = (selectedDates, dateString) => {
        tradeLoanDispatch({
            type: 'UPDATE_TRADE_LOAN_DETAIL',
            fieldName: 'dateOfShipping',
            value: dateString
        });
    };
    const dateOfShippingSettings = {
        altInput: true,
        altFormat: 'm/d/Y',
        dateFormat: 'Y-m-d',
        onChange: handleDateOfShippingChange
    };
    const handleSupplierUpdate = (selectedOption, action) => {
        const [index] = getBracketValues(action.name);
        if (!selectedOption) {
            dispatch({
                type: 'PRELOAD_SUPPLIER',
                index: parseInt(index),
                option: null
            });
            dispatch({
                type: 'PRELOAD_PAYMENT_DETAILS',
                index: parseInt(index)
            });
        }
        else if (selectedOption && selectedOption.value === 'new_supplier') {
            dispatch({
                type: 'PRELOAD_SUPPLIER',
                index: parseInt(index),
                option: selectedOption
            });
            dispatch({
                type: 'PRELOAD_PAYMENT_DETAILS',
                index: parseInt(index)
            });
        }
        else {
            loadSupplierDetails(parseInt(index), selectedOption);
        }
    };
    const handleSupplierDetailUpdate = (event) => {
        const targetName = event.target['name'];
        const [index, fieldName] = getBracketValues(targetName);
        const value = event.target['value'];
        dispatch({
            type: 'UPDATE_SUPPLIER_FIELD',
            index: parseInt(index),
            fieldName: snakeToCamelCase(fieldName),
            value
        });
    };
    const handleSupplierCurrencyUpdate = (selectedOption, action) => {
        const [index] = getBracketValues(action.name);
        dispatch({
            type: 'UPDATE_SUPPLIER_CURRENCY',
            index: parseInt(index),
            currencyOption: selectedOption
        });
    };
    const handlePaymentDetailUpdate = (event) => {
        const targetName = event.target['name'];
        const [index, fieldName] = getBracketValues(targetName);
        const value = event.target['value'];
        dispatch({
            type: 'UPDATE_PAYMENT_DETAILS_FIELD',
            index: parseInt(index),
            fieldName: snakeToCamelCase(fieldName),
            value
        });
    };
    const handleConfirmPaymentDetailsToggle = (event) => {
        const targetName = event.target['name'];
        const [index, fieldName] = getBracketValues(targetName);
        const value = event.target.checked;
        dispatch({
            type: 'UPDATE_PAYMENT_DETAILS_FIELD',
            index: parseInt(index),
            fieldName: snakeToCamelCase(fieldName),
            value
        });
    };
    const handleTradeLoanDetailUpdate = (event) => {
        const targetName = event.target['name'];
        const [fieldName] = getBracketValues(targetName);
        const value = event.target['value'];
        tradeLoanDispatch({
            type: 'UPDATE_TRADE_LOAN_DETAIL',
            fieldName: snakeToCamelCase(fieldName),
            value
        });
    };
    const handleTradeLoanPaymentCurrencyUpdate = (selectedOption) => {
        tradeLoanDispatch({
            type: 'UPDATE_CURRENCY_FIELD',
            fieldName: 'totalAmountCurrency',
            selectedOptionFieldName: 'selectedPaymentCurrencyOption',
            option: selectedOption
        });
    };
    const handleMaximumLoanTermUpdate = value => {
        tradeLoanDispatch({
            type: 'UPDATE_MAXIMUM_LOAN_TERM_FIELD',
            fieldName: 'maximumLoanTerm',
            value
        });
    };
    const handleFileChange = (acceptedFiles, targetName) => {
        const [fieldName] = getBracketValues(targetName);
        const newFiles = [...acceptedFiles];
        const currentFiles = tradeLoan[snakeToCamelCase(fieldName)] || [];
        const allFiles = removeDuplicateFiles([
            ...currentFiles,
            ...newFiles
        ]);
        tradeLoanDispatch({
            type: 'UPDATE_FILES',
            fieldName: snakeToCamelCase(fieldName),
            files: allFiles
        });
        validateDocumentsFrontend(allFiles, snakeToCamelCase(fieldName));
    };
    const handleFileReplace = (event) => {
        const targetName = event.target['name'];
        const [fieldName, index] = getBracketValues(targetName);
        const files = event.target.files;
        const newFiles = [...files];
        const currentFiles = tradeLoan[snakeToCamelCase(fieldName)] || [];
        const updatedFiles = removeDuplicateFiles([
            ...currentFiles.slice(0, parseInt(index)),
            ...newFiles,
            ...currentFiles.slice(parseInt(index) + 1, currentFiles.length)
        ]);
        tradeLoanDispatch({
            type: 'UPDATE_FILES',
            fieldName: snakeToCamelCase(fieldName),
            files: updatedFiles
        });
        validateDocumentsFrontend(updatedFiles, snakeToCamelCase(fieldName));
    };
    const validateDocumentsFrontend = (files, fieldName) => {
        tradeLoanDispatch({
            type: 'UPDATE_DOCUMENT_ERRORS',
            fieldName: snakeToCamelCase(fieldName),
            errors: totalErrors(files, fieldName)
        });
    };
    const generateFileErrors = (file, fieldName) => {
        const fileSizeValidations = validateFileSize(file, 20000000)
            ? []
            : [`The file needs to be less than 20MB.`];
        const fileNameList = compileFileNames([fieldName], tradeLoan);
        const selectedFileValidations = validateTradeLoanDuplicateFile(file, fileNameList)
            ? []
            : [`The file has already been selected in another field.`];
        const validations = [...fileSizeValidations, ...selectedFileValidations];
        return validations;
    };
    const generateCollectionErrors = (files, limit = 5) => {
        const numberOfFilesValidations = validateNumberOfFiles(files, limit)
            ? []
            : [
                `File upload is limited to ${limit} files. If you wish to upload more files you can do so after the loan has been created by clicking 'Edit'.`
            ];
        if (numberOfFilesValidations.length > 0) {
            const errors = {
                collectionErrors: numberOfFilesValidations
            };
            return errors;
        }
        else {
            return {};
        }
    };
    const totalErrors = (files, fieldName) => {
        const individualErrors = files.reduce((acc, file) => {
            if (file) {
                const errors = generateFileErrors(file, fieldName);
                if (errors.length > 0) {
                    return { ...acc, [file.name]: errors };
                }
                else {
                    return acc;
                }
            }
            else {
                return acc;
            }
        }, {});
        return { ...individualErrors, ...generateCollectionErrors(files, 3) };
    };
    const handleRemoveFile = (event) => {
        if (event.target) {
            const targetName = event.target['name'];
            const [fieldName, fileIndex] = getBracketValues(targetName);
            const fieldFiles = tradeLoan[snakeToCamelCase(fieldName)];
            const updatedFiles = [
                ...fieldFiles.slice(0, parseInt(fileIndex)),
                ...fieldFiles.slice(parseInt(fileIndex) + 1, fieldFiles.length)
            ];
            tradeLoanDispatch({
                type: 'UPDATE_FILES',
                fieldName: snakeToCamelCase(fieldName),
                files: updatedFiles
            });
            validateDocumentsFrontend(updatedFiles, snakeToCamelCase(fieldName));
        }
    };
    const handleTotalAmountChange = values => {
        tradeLoanDispatch({
            type: 'UPDATE_AMOUNT',
            fieldName: 'totalAmountCents',
            centsAmount: Math.round(values.floatValue * 100) || 0,
            values
        });
    };
    const handleInvoiceDetailUpdate = (event) => {
        const targetName = event.target['name'];
        const [supplierIndex, invoiceIndex, fieldName] = getBracketValues(targetName);
        const value = event.target['value'];
        dispatch({
            type: 'UPDATE_INVOICE_FIELD',
            supplierIndex: parseInt(supplierIndex),
            invoiceIndex: parseInt(invoiceIndex),
            fieldName: snakeToCamelCase(fieldName),
            value
        });
    };
    const handleInvoiceDateChange = (selectedDates, dateString, instance) => {
        const targetName = instance.input.name;
        const [supplierIndex, invoiceIndex, fieldName] = getBracketValues(targetName);
        dispatch({
            type: 'UPDATE_INVOICE_FIELD',
            supplierIndex: parseInt(supplierIndex),
            invoiceIndex: parseInt(invoiceIndex),
            fieldName: snakeToCamelCase(fieldName),
            value: dateString
        });
    };
    const invoiceDateSettings = {
        altInput: true,
        altFormat: 'm/d/Y',
        dateFormat: 'Y-m-d',
        onChange: handleInvoiceDateChange
    };
    const handleInvoiceAmountValueChange = (values) => {
        if (focusedInvoiceField) {
            dispatch({
                type: 'UPDATE_INVOICE_AMOUNT',
                supplierIndex: focusedInvoiceField.supplierIndex,
                invoiceIndex: focusedInvoiceField.invoiceIndex,
                fieldName: snakeToCamelCase(focusedInvoiceField.fieldName),
                centsAmount: values.floatValue
                    ? Math.round(values.floatValue * 100)
                    : 0,
                values
            });
        }
    };
    const handleInvoiceFocus = (event) => {
        if (event.target) {
            const targetName = event.target['name'];
            const [supplierIndex, invoiceIndex, fieldName] = getBracketValues(targetName);
            setFocusedInvoiceField({
                supplierIndex: parseInt(supplierIndex),
                invoiceIndex: parseInt(invoiceIndex),
                fieldName: snakeToCamelCase(fieldName)
            });
        }
    };
    const handleInvoiceCurrencyOptionChange = (selectedOption, action) => {
        const [supplierIndex, invoiceIndex, fieldName] = getBracketValues(action.name);
        dispatch({
            type: 'UPDATE_INVOICE_CURRENCY',
            supplierIndex: parseInt(supplierIndex),
            invoiceIndex: parseInt(invoiceIndex),
            fieldName: snakeToCamelCase(fieldName),
            option: selectedOption
        });
    };
    const handleInvoiceFileChange = (acceptedFiles, targetName) => {
        const [supplierIndex, invoiceIndex, fieldName] = getBracketValues(targetName);
        const newFiles = [...acceptedFiles];
        const currentFiles = invoices[supplierIndex][invoiceIndex][snakeToCamelCase(fieldName)];
        const allFiles = removeDuplicateFiles([
            ...currentFiles,
            ...newFiles
        ]);
        dispatch({
            type: 'UPDATE_INVOICE_FILES',
            supplierIndex: parseInt(supplierIndex),
            invoiceIndex: parseInt(invoiceIndex),
            fieldName: snakeToCamelCase(fieldName),
            files: allFiles
        });
        validateInvoiceDocumentsFrontend(allFiles, parseInt(supplierIndex), parseInt(invoiceIndex), snakeToCamelCase(fieldName));
    };
    const handleInvoiceFileReplace = (event) => {
        const targetName = event.target['name'];
        const [supplierIndex, invoiceIndex, fieldName, fileIndex] = getBracketValues(targetName);
        const files = event.target.files;
        const newFiles = [...files];
        const currentFiles = invoices[supplierIndex][invoiceIndex][snakeToCamelCase(fieldName)] || [];
        const updatedFiles = removeDuplicateFiles([
            ...currentFiles.slice(0, parseInt(fileIndex)),
            ...newFiles,
            ...currentFiles.slice(parseInt(fileIndex) + 1, currentFiles.length)
        ]);
        dispatch({
            type: 'UPDATE_INVOICE_FILES',
            supplierIndex: parseInt(supplierIndex),
            invoiceIndex: parseInt(invoiceIndex),
            fieldName: snakeToCamelCase(fieldName),
            files: updatedFiles
        });
        validateInvoiceDocumentsFrontend(updatedFiles, parseInt(supplierIndex), parseInt(invoiceIndex), snakeToCamelCase(fieldName));
    };
    const handleRemoveInvoiceFile = (event) => {
        const targetName = event.target['name'];
        const [supplierIndex, invoiceIndex, fieldName, fileIndex] = getBracketValues(targetName);
        const fieldFiles = invoices[supplierIndex][invoiceIndex][snakeToCamelCase(fieldName)];
        const updatedFiles = [
            ...fieldFiles.slice(0, parseInt(fileIndex)),
            ...fieldFiles.slice(parseInt(fileIndex) + 1, fieldFiles.length)
        ];
        dispatch({
            type: 'UPDATE_INVOICE_FILES',
            supplierIndex: parseInt(supplierIndex),
            invoiceIndex: parseInt(invoiceIndex),
            fieldName: snakeToCamelCase(fieldName),
            files: updatedFiles
        });
        validateInvoiceDocumentsFrontend(updatedFiles, parseInt(supplierIndex), parseInt(invoiceIndex), snakeToCamelCase(fieldName));
    };
    const removeInvoice = (supplierIndex, invoiceIndex) => {
        dispatch({
            type: 'REMOVE_INVOICE',
            supplierIndex,
            invoiceIndex
        });
    };
    const validateInvoiceDocumentsFrontend = (files, supplierIndex, invoiceIndex, fieldName) => {
        dispatch({
            type: 'UPDATE_INVOICE_DOCUMENT_ERRORS',
            supplierIndex,
            invoiceIndex,
            fieldName: snakeToCamelCase(fieldName),
            errors: totalInvoiceErrors(files, supplierIndex, invoiceIndex, snakeToCamelCase(fieldName))
        });
    };
    const generateInvoiceFileErrors = (file, supplierIndex, invoiceIndex, fieldName) => {
        const fileSizeValidations = validateFileSize(file, 20000000)
            ? []
            : [`The file needs to be less than 20MB.`];
        const invoice = invoices[supplierIndex][invoiceIndex];
        const fileNameList = compileInvoiceFileNames([snakeToCamelCase(fieldName)], invoice);
        const selectedFileValidations = validateTradeLoanDuplicateFile(file, fileNameList)
            ? []
            : [`The file has already been selected in another field.`];
        const validations = [...fileSizeValidations, ...selectedFileValidations];
        return validations;
    };
    const totalInvoiceErrors = (files, supplierIndex, invoiceIndex, fieldName) => {
        const individualErrors = files.reduce((acc, file) => {
            if (file) {
                const errors = generateInvoiceFileErrors(file, supplierIndex, invoiceIndex, snakeToCamelCase(fieldName));
                if (errors.length > 0) {
                    return { ...acc, [file.name]: errors };
                }
                else {
                    return acc;
                }
            }
            else {
                return acc;
            }
        }, {});
        return { ...individualErrors, ...generateCollectionErrors(files, 3) };
    };
    const handleSubmitSupplierDetails = async () => {
        const confirmationMessage = 'Please note any changes made to existing suppliers will be saved. Click OK if you wish to continue.';
        if (hasReusedSupplier) {
            window.confirm(confirmationMessage) && submitSupplierDetails();
        }
        else {
            submitSupplierDetails();
        }
    };
    const submitSupplierDetails = async () => {
        const supplierDetailsBody = {
            tradeLoanSuppliers: suppliers
        };
        const paymentDetailsBody = {
            tradeLoanPayments: paymentDetailsList
        };
        setIsSubmitting(true);
        try {
            const supplierDetailsValidation = await validateSupplierDetails(apiToken, supplierDetailsBody, urlPath);
            if (isAPIValidationMultipleErrors(supplierDetailsValidation)) {
                dispatch({
                    type: 'UPDATE_SUPPLIER_DETAILS_ERRORS',
                    errors: supplierDetailsValidation.multipleErrors
                });
            }
            if (isAPIErrors(supplierDetailsValidation)) {
                throw supplierDetailsValidation.errors;
            }
            const paymentDetailsValidation = await validatePaymentDetails(apiToken, paymentDetailsBody, urlPath);
            if (isAPIValidationMultipleErrors(paymentDetailsValidation)) {
                dispatch({
                    type: 'UPDATE_PAYMENT_DETAILS_ERRORS',
                    errors: paymentDetailsValidation.multipleErrors
                });
            }
            if (isAPIErrors(paymentDetailsValidation)) {
                throw paymentDetailsValidation.errors;
            }
            if (isListOfEmptyRecords(supplierDetailsValidation.multipleErrors) &&
                isListOfEmptyRecords(paymentDetailsValidation.multipleErrors)) {
                setCurrentStep(3);
            }
            else {
                showNotifyToast({
                    text: 'Please check if all fields are filled out correctly.',
                    type: 'error'
                });
            }
        }
        catch {
            showNotifyToast({
                text: 'Failed to validate fields. Please try again.',
                type: 'error'
            });
        }
        setIsSubmitting(false);
    };
    const handleSubmitLoanApplication = async () => {
        setIsSubmitting(true);
        const tradeLoanBody = {
            tradeLoan: tradeLoan
        };
        try {
            const tradeLoanValidation = await validateTradeLoanDetails(apiToken, tradeLoanBody, urlPath);
            if (isAPIValidationErrors(tradeLoanValidation)) {
                tradeLoanDispatch({
                    type: 'UPDATE_TRADE_LOAN_ERRORS',
                    errors: tradeLoanValidation.errors
                });
            }
            if (isAPIErrors(tradeLoanValidation.errors)) {
                throw tradeLoanValidation.errors;
            }
            if (isEmptyObject(tradeLoanValidation.errors)) {
                setCurrentStep(2);
            }
            else {
                showNotifyToast({
                    text: 'Please check if all fields are filled out correctly.',
                    type: 'error'
                });
            }
        }
        catch {
            showNotifyToast({
                text: 'Failed to validate fields. Please try again.',
                type: 'error'
            });
        }
        setIsSubmitting(false);
    };
    const loadSupplierDetails = async (index, option) => {
        const supplierId = option.value;
        try {
            if (supplierId !== 'new_supplier') {
                const { supplier, paymentDetails } = await fetchSupplierDetailsforBorrower(apiToken, supplierId, urlPath);
                const currencyOption = findCurrencyOption(supplier.currency);
                dispatch({
                    type: 'PRELOAD_SUPPLIER',
                    option,
                    index,
                    details: supplier,
                    currencyOption
                });
                dispatch({
                    type: 'PRELOAD_PAYMENT_DETAILS',
                    index,
                    details: paymentDetails
                });
            }
        }
        catch (err) {
            showNotifyToast({
                text: 'Failed to fill up supplier details. Please try again.',
                type: 'error'
            });
            dispatch({ type: 'PRELOAD_SUPPLIER', option: null, index });
            dispatch({ type: 'PRELOAD_PAYMENT_DETAILS', index });
        }
    };
    const handleSubmitInvoiceDetails = async () => {
        if (is2DListOfEmptyRecords(invoiceDocumentErrors)) {
            submitInvoiceDetails();
        }
        else {
            showNotifyToast({
                text: 'Please check if selected files are valid.',
                type: 'error'
            });
        }
    };
    const submitInvoiceDetails = async () => {
        const invoiceDetailsBody = {
            tradeLoanInvoices: invoices
        };
        try {
            setIsSubmitting(true);
            const invoicesValidation = await validateTradeLoanInvoices(apiToken, invoiceDetailsBody, urlPath);
            if (isAPIValidationMultipleArrayErrors(invoicesValidation)) {
                dispatch({
                    type: 'UPDATE_INVOICE_ERRORS',
                    errors: invoicesValidation.multipleArrayErrors
                });
            }
            if (isAPIErrors(invoicesValidation)) {
                throw invoicesValidation.errors;
            }
            if (is2DListOfEmptyRecords(invoicesValidation.multipleArrayErrors)) {
                setCurrentStep(4);
            }
            else {
                showNotifyToast({
                    text: 'Please check if all fields are filled out correctly.',
                    type: 'error'
                });
            }
        }
        catch {
            showNotifyToast({
                text: 'Failed to validate fields. Please try again.',
                type: 'error'
            });
        }
        setIsSubmitting(false);
    };
    const handleSubmitSupportingDocuments = async () => {
        if (isEmptyObject(documentErrors)) {
            submitSupportingDocuments();
        }
        else {
            showNotifyToast({
                text: 'Please check if selected files are valid.',
                type: 'error'
            });
        }
    };
    const submitSupportingDocuments = async () => {
        const tradeLoanBody = {
            tradeLoan
        };
        try {
            setIsSubmitting(true);
            const supportingDocumentsValidation = await validateSupportingDocuments(apiToken, tradeLoanBody, urlPath);
            if (isAPIValidationErrors(supportingDocumentsValidation)) {
                tradeLoanDispatch({
                    type: 'UPDATE_TRADE_LOAN_ERRORS',
                    errors: supportingDocumentsValidation.errors
                });
            }
            if (isAPIErrors(supportingDocumentsValidation.errors)) {
                throw supportingDocumentsValidation.errors;
            }
            if (isEmptyObject(supportingDocumentsValidation.errors)) {
                submitTradeLoan();
            }
            else {
                showNotifyToast({
                    text: 'Please check if all fields are filled out correctly.',
                    type: 'error'
                });
                setIsSubmitting(false);
            }
        }
        catch {
            showNotifyToast({
                text: 'Failed to validate fields. Please try again.',
                type: 'error'
            });
            setIsSubmitting(false);
        }
    };
    const submitTradeLoan = async () => {
        const fullTradeLoanBody = {
            tradeLoan,
            tradeLoanPayments: paymentDetailsList,
            tradeLoanSuppliers: suppliers,
            tradeLoanInvoices: invoices
        };
        try {
            setIsSubmitting(true);
            const response = await createTradeLoan(apiToken, fullTradeLoanBody, urlPath);
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            if (enableRedirect) {
                if (urlPath === 'borrower') {
                    location.href = '/trade_loans/success';
                }
                else {
                    location.href = `/${urlPath}/trade_loans/success`;
                }
            }
            localStorage.clear();
        }
        catch (error) {
            Bugsnag.notify(error);
            showNotifyToast({
                text: 'Failed to submit trade loan application. Please try again',
                type: 'error'
            });
            setIsSubmitting(false);
        }
    };
    const stepInfo = [
        {
            stepLabel: 'Loan Application',
            nextButtonText: 'Next',
            nextButtonHandler: () => handleSubmitLoanApplication()
        },
        {
            stepLabel: 'Supplier Details',
            previousButtonText: 'Go Back',
            previousButtonHandler: () => setCurrentStep(1),
            nextButtonText: 'Next',
            nextButtonHandler: () => handleSubmitSupplierDetails()
        },
        {
            stepLabel: 'Invoice Details',
            previousButtonText: 'Go Back',
            previousButtonHandler: () => setCurrentStep(2),
            nextButtonText: 'Next',
            nextButtonHandler: () => handleSubmitInvoiceDetails()
        },
        {
            stepLabel: 'Supporting Documents',
            previousButtonText: 'Go Back',
            previousButtonHandler: () => setCurrentStep(3),
            nextButtonText: 'Submit',
            nextButtonHandler: () => handleSubmitSupportingDocuments()
        }
    ];
    return (React.createElement("div", { className: 'trade-loan-dashboard -application', "data-testid": 'trade-loan-dashboard' },
        React.createElement(Subheader, { ...{ backLink } }),
        React.createElement(TradeLoanSteps, { ...{ currentStep }, stepLabels: stepInfo.map(step => step.stepLabel) }),
        React.createElement(TradeLoanForm, { ...{
                apiToken,
                currentStep,
                isAdmin,
                currencyOptions,
                supplierCurrencyOptions,
                invoiceCurrencyOptions,
                supplierOptions,
                loanTermOptions,
                dateOfShippingSettings,
                invoiceDateSettings,
                suppliersState,
                tradeLoanState,
                handleSupplierUpdate,
                handleSupplierDetailUpdate,
                handleSupplierCurrencyUpdate,
                handlePaymentDetailUpdate,
                handleConfirmPaymentDetailsToggle,
                handleInvoiceDetailUpdate,
                dispatch,
                handleTradeLoanDetailUpdate,
                handleTotalAmountChange,
                handleRemoveFile,
                handleFileChange,
                handleFileReplace,
                handleInvoiceAmountValueChange,
                handleInvoiceFocus,
                handleInvoiceCurrencyOptionChange,
                handleInvoiceFileChange,
                handleInvoiceFileReplace,
                handleRemoveInvoiceFile,
                handleTradeLoanPaymentCurrencyUpdate,
                handleMaximumLoanTermUpdate,
                removeInvoice,
                urlPath
            } }),
        React.createElement(ActionButtons, { ...{ currentStep, isSubmitting, saveProgress, clearForm }, stepInfo: stepInfo[currentStep - 1] })));
};
export default TradeLoanApplicationDashboard;
