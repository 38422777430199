import React from 'react';
import HeaderTab from '../table-headers/HeaderTab';
import AdminCustomerStatementsRow from './AdminCustomerStatementsRow';
const AdminCustomerStatementsTable = ({ isLoading, customersArray, checkedCustomers, handleCustomerCheckbox, isVisible }) => {
    const headerFields = isVisible
        ? [{ text: 'Debtor' }, { text: 'Balance' }, { text: 'Email' }]
        : [
            { text: 'Debtor' },
            { text: 'Balance' },
            { text: 'Selected' },
            { text: 'Disclose Type' },
            { text: 'Email' },
            { text: 'Send Statement' }
        ];
    if (customersArray.length === 0) {
        return (React.createElement("div", { className: 'business-info', "data-testid": 'no-customers' },
            React.createElement("div", { className: 'info' }, "There are no Debtors to display")));
    }
    return (React.createElement("table", { className: 'custom-table -scrollable', "data-testid": 'customer-statements-table' },
        React.createElement("thead", { className: 'table-headers -sticky' },
            React.createElement("tr", { className: 'row -header' }, headerFields.map((header, index) => (React.createElement(HeaderTab, { isLoading: isLoading, headerField: header, key: index }))))),
        React.createElement("tbody", null, customersArray.map((customer) => {
            return (React.createElement(AdminCustomerStatementsRow, { ...{
                    customer,
                    checkedCustomers,
                    handleCustomerCheckbox,
                    isVisible
                }, key: customer.id }));
        }))));
};
export default AdminCustomerStatementsTable;
