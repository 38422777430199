import React from 'react';
import CompanyName from '../CompanyName';
import Payments from './Payments';
import RepaymentAmountBox from './RepaymentAmountBox';
import ReserveAmountBox from './ReserveAmountBox';
const ProcessRepayment = ({ event }) => {
    const { attributes } = event;
    const { createdAtFormatted, companyName, companyLink, eventable: { attributes: { amount, principal, interest, transfer, residual, payments, reserve } } } = attributes;
    const transferBack = transfer === '$0.00' ? residual : transfer;
    return (React.createElement("div", { className: 'event-box event', "data-testid": 'process-repayment-activity' },
        React.createElement("div", { className: 'icon' },
            React.createElement("div", { className: 'activity-icon -process-repayment' })),
        React.createElement("div", { className: 'description' },
            React.createElement("div", { className: 'title' },
                "Earlypay processed a",
                React.createElement("span", { className: 'bold' }, " repayment "),
                "of",
                React.createElement("span", { className: 'amount -blue' },
                    " ",
                    amount,
                    " "),
                "for ",
                React.createElement(CompanyName, { name: companyName, companyLink: companyLink })),
            React.createElement("div", { className: 'date' },
                " ",
                createdAtFormatted,
                " "),
            React.createElement("div", { className: 'event-breakdown -repayment' },
                React.createElement("div", { className: 'info -horizontal' },
                    React.createElement(RepaymentAmountBox, { label: 'Principal Repaid', classVariant: '-blue', amount: principal }),
                    React.createElement(RepaymentAmountBox, { label: 'Interest Repaid', classVariant: '-blue', amount: interest }),
                    React.createElement(RepaymentAmountBox, { label: 'Transfer Back', classVariant: '-green', amount: transferBack }),
                    React.createElement(ReserveAmountBox, { label: 'Reserve', classVariant: '-blue', amount: reserve }))),
            React.createElement(Payments, { payments: payments, total: amount }))));
};
export default ProcessRepayment;
