import React, { useRef, useState } from 'react';
import Select from 'react-select';
import queryString from 'query-string';
import { dropdownStyles } from '../../custom-styles/dropdown';
import CustomFlatpickr from '../shared/CustomFlatpickr';
const AuditLogFilters = ({ baseUrl, filters, actionOptions, userTypeOptions }) => {
    const { action: filterAction, userType: filterUserType, date: filterDate } = filters;
    const initialAction = actionOptions.find((option) => option.value === filterAction) || null;
    const initialUserType = userTypeOptions.find((option) => option.value === filterUserType) || null;
    const [date, setDate] = useState(filterDate || '');
    const [action, setAction] = useState(initialAction);
    const [userType, setUserType] = useState(initialUserType);
    const datePickerRef = useRef(null);
    const dateClearRef = useRef(null);
    const handleDateChange = (selectedDates, dateString) => {
        setDate(dateString);
    };
    const flatpickrSettings = {
        wrap: true,
        altInput: true,
        altFormat: 'm/d/Y',
        dateFormat: 'Y-m-d',
        defaultDate: filterDate,
        onChange: handleDateChange
    };
    const handleActionChange = (selectedOption) => {
        setAction(selectedOption);
    };
    const handleUserTypeChange = (selectedOption) => {
        setUserType(selectedOption);
    };
    const handleClear = () => {
        if (dateClearRef && dateClearRef.current) {
            dateClearRef.current.click();
        }
        setAction(null);
        setUserType(null);
    };
    const params = {
        ...(date && { date }),
        ...(action && { audit_action: action.value }),
        ...(userType && { user_type: userType.value })
    };
    const link = Object.keys(params).length > 0
        ? `${baseUrl}?${queryString.stringify(params)}`
        : baseUrl;
    return (React.createElement("div", { className: 'audit-log-filters' },
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: 'label -uppercase' }, "Date"),
            React.createElement(CustomFlatpickr, { ...{ flatpickrSettings }, selectedDate: date, pickerBackground: 'neutral-20', forwardDatePickerRef: datePickerRef, forwardDateClearRef: dateClearRef })),
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: 'label -uppercase' }, "User Type"),
            React.createElement(Select, { value: userType, placeholder: 'Select a user type', isClearable: true, options: userTypeOptions, onChange: handleUserTypeChange, className: 'select', styles: dropdownStyles })),
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: 'label -uppercase' }, "Action"),
            React.createElement(Select, { value: action, placeholder: 'Select an action', isClearable: true, options: actionOptions, onChange: handleActionChange, className: 'select', styles: dropdownStyles })),
        React.createElement("div", { className: 'action-buttons actions -space-between' },
            React.createElement("button", { type: 'button', className: 'button -cancel', onClick: handleClear }, "Clear All"),
            React.createElement("a", { href: link, className: 'button -primary' }, "Apply Filter"))));
};
export default AuditLogFilters;
