import React, { Fragment } from 'react';
import classNames from 'classnames';
const AccordionTable = ({ invoices, isClose }) => {
    const accordionTableClass = classNames('accordion-table', {
        '-close': isClose
    });
    return (React.createElement(Fragment, null,
        React.createElement("tr", { className: accordionTableClass },
            React.createElement("th", { className: 'header table-header' }),
            React.createElement("th", { className: 'header table-header' }, "Invoice ID"),
            React.createElement("th", { className: 'header table-header' }, "Date"),
            React.createElement("th", { className: 'header table-header' }),
            React.createElement("th", { className: 'header table-header' }, "Account Name"),
            React.createElement("th", { className: 'header table-header' }, "Receipt Number"),
            React.createElement("th", { className: 'header table-header' }, "Amount"),
            React.createElement("th", { className: 'header table-header' }),
            React.createElement("th", { className: 'header table-header' }),
            React.createElement("th", { className: 'header table-header' })),
        invoices.map((invoice, index) => (React.createElement("tr", { className: accordionTableClass, key: index },
            React.createElement("td", { className: 'cell -color-neutral-10' }),
            React.createElement("td", { className: 'cell -color-neutral-10' }, invoice.invoiceId),
            React.createElement("td", { className: 'cell -color-neutral-10' }, invoice.date),
            React.createElement("td", { className: 'cell -color-neutral-10' }),
            React.createElement("td", { className: 'cell -color-neutral-10' }, invoice.accountName),
            React.createElement("td", { className: 'cell -color-neutral-10' }, invoice.receiptNumber),
            React.createElement("td", { className: 'cell -color-neutral-10' }, invoice.amount),
            React.createElement("td", { className: 'cell -color-neutral-10' }),
            React.createElement("td", { className: 'cell -color-neutral-10 action-buttons -space-evenly', colSpan: 2 },
                React.createElement("button", { className: 'button' },
                    React.createElement("a", { href: invoice.invoiceUrl }, "View Invoice"))),
            React.createElement("td", { className: 'cell -color-neutral-10' }))))));
};
export default AccordionTable;
