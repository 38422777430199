import React, { useEffect, useState } from 'react';
import { isAPIValidationErrors } from '../../../types';
import InvoiceDetails from './InvoiceDetails';
import ReactDOM from 'react-dom';
import InvoiceDetailHeader from './InvoiceDetailHeader';
import VerifyInvoiceForm from './VerifyInvoiceForm';
import { showNotifyToast as defaultShowNotifyToast } from '../../utils';
import { verifyInvoices as defaultVerifyInvoices } from '../../api/invoice_batch';
import ErrorList from '../../components/shared/ErrorList';
import Drawer from '../../components/shared/Drawer';
const VerifyInvoicePanel = ({ apiToken, companyId, invoiceBatchId, verificationReasons, selectedInvoices, selectedInvoiceIds, cancelVerification, selectedInvoicesTotalAmountDue, verifyInvoices = defaultVerifyInvoices, showNotifyToast = defaultShowNotifyToast }) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
    }, []);
    const [reason, setReason] = useState('');
    const [notes, setNotes] = useState('');
    const [attachment, setAttachment] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const invoicesCount = selectedInvoices.length;
    const singleInvoice = invoicesCount === 1;
    const buttonText = singleInvoice
        ? 'Verify'
        : `Verify ${invoicesCount} Invoices`;
    const invoiceString = singleInvoice ? 'invoice' : 'invoices';
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await verifyInvoices(apiToken, companyId, invoiceBatchId, selectedInvoiceIds, reason, notes, attachment);
            if (isAPIValidationErrors(response)) {
                throw response;
            }
            setValidationErrors({});
            if (response) {
                location.reload();
                showNotifyToast({
                    text: `Successfully verified ${invoicesCount} invoices`,
                    type: 'success'
                });
            }
        }
        catch (err) {
            if (isAPIValidationErrors(err)) {
                setValidationErrors(err.errors);
            }
            showNotifyToast({
                text: `Failed to verify ${invoiceString}.`,
                type: 'error'
            });
        }
    };
    const InvoiceDetailsDiv = () => {
        return (React.createElement("div", { className: 'body', tabIndex: -1, "data-testid": 'multiple-invoices-panel' },
            React.createElement(InvoiceDetails, { invoices: selectedInvoices }),
            !singleInvoice && (React.createElement("div", { className: 'footer' },
                React.createElement("div", { className: 'custom-text' },
                    React.createElement("div", { className: 'title' }, "Total")),
                React.createElement("div", { className: 'custom-text' },
                    React.createElement("div", { className: 'text -large' }, selectedInvoicesTotalAmountDue))))));
    };
    return ReactDOM.createPortal(React.createElement(Drawer, { isOpen: true, onClose: cancelVerification },
        React.createElement("div", { className: 'verification-drawer-container', "data-testid": 'verification-drawer-container' },
            !singleInvoice && React.createElement(InvoiceDetailsDiv, null),
            React.createElement("form", { method: 'post', onSubmit: handleSubmit, encType: 'multipart/form-data', className: 'body', tabIndex: -1 },
                React.createElement("div", { className: 'info' },
                    React.createElement("div", { className: 'panel' },
                        singleInvoice && (React.createElement(InvoiceDetailHeader, { number: selectedInvoices[0].number, status: selectedInvoices[0].status })),
                        singleInvoice && (React.createElement("div", { className: 'fields' },
                            React.createElement("div", { className: 'form-group -form-floating' },
                                React.createElement("label", { className: 'label -small', htmlFor: 'customer_name' }, "Name"),
                                React.createElement("input", { type: 'text', name: 'customer_name', id: 'customer_name', value: selectedInvoices[0].customerName, className: 'input -emphasize', disabled: true })),
                            React.createElement(InvoiceDetails, { invoices: selectedInvoices }))),
                        React.createElement(ErrorList, { errors: validationErrors, field: 'invoice_ids' }),
                        React.createElement(VerifyInvoiceForm, { ...{
                                verificationReasons,
                                setNotes,
                                setReason,
                                setAttachment,
                                validationErrors
                            } }))),
                React.createElement("div", { className: 'footer' },
                    React.createElement("div", { className: 'action-buttons buttons space -padding -space-between' },
                        React.createElement("button", { className: 'button -cancel -with-border -loose-width', onClick: cancelVerification }, "Cancel"),
                        React.createElement("input", { type: 'submit', className: 'button -primary -loose-width', value: buttonText, "data-testid": 'verify' })))),
            React.createElement("div", { className: 'background' }))), document.body);
};
export default VerifyInvoicePanel;
