import React, { useState } from 'react';
import classNames from 'classnames';
import { activatePenalty as defaultActivatePenalty, deactivatePenalty as defaultDeactivatePenalty } from '../../api/trade_loan';
import { showNotifyToast as defaultShowNotifyToast } from '../../utils';
import Modal from '../shared/Modal';
import ToggleSwitch from '../shared/ToggleSwitch';
import PenaltyDisplay from './PenaltyDisplay';
import PenaltyConfirmation from './PenaltyConfirmation';
const PenaltyControls = ({ apiToken, tradeLoan, companyId, tradeLoanId, currentDate, initialStep = 1, activatePenalty = defaultActivatePenalty, deactivatePenalty = defaultDeactivatePenalty, showNotifyToast = defaultShowNotifyToast }) => {
    const { penaltyApplied, penaltyStartDate, penaltyToggleable: isPenaltyToggleable, appliedInterestRate, formattedId: formattedTradeLoanId } = tradeLoan;
    const defaultNumberFormatValues = {
        floatValue: 0,
        value: '0.00',
        formattedValue: '0.00'
    };
    const [isPenaltyOn, setIsPenaltyOn] = useState(penaltyApplied);
    const [currentStartDate, setCurrentStartDate] = useState(penaltyStartDate);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [accumulatedPenaltyInterest, setAccumulatedPenaltyInterest] = useState(defaultNumberFormatValues);
    const [currentStep, setCurrentStep] = useState(initialStep);
    const openConfirmaion = () => {
        openModal();
    };
    const finishConfirmation = async () => {
        await switchOn();
        closeModal();
    };
    const stepBack = () => {
        setCurrentStep(currentStep - 1);
    };
    const stepForward = () => {
        setCurrentStep(currentStep + 1);
    };
    const handleAccumulatedPenaltyValueChange = (values) => {
        setAccumulatedPenaltyInterest(values);
    };
    const switchOn = async () => {
        try {
            const accumulatedPenaltyInterestValue = accumulatedPenaltyInterest.floatValue || 0;
            const response = await activatePenalty(apiToken, companyId, tradeLoanId, accumulatedPenaltyInterestValue);
            setIsPenaltyOn(response.data.attributes.penaltyApplied);
            setCurrentStartDate(response.data.attributes.penaltyStartDate);
            setAccumulatedPenaltyInterest(defaultNumberFormatValues);
            setCurrentStep(1);
            showNotifyToast({
                text: 'Trade Loan Default successfully activated.',
                type: 'success'
            });
        }
        catch {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
    };
    const switchOff = async () => {
        try {
            const response = await deactivatePenalty(apiToken, companyId, tradeLoanId);
            setIsPenaltyOn(response.data.attributes.penaltyApplied);
            setCurrentStartDate('');
            showNotifyToast({
                text: 'Trade Loan Default successfully deactivated.',
                type: 'success'
            });
        }
        catch {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
    };
    const openModal = () => {
        setIsConfirmationModalOpen(true);
    };
    const closeModal = () => {
        setIsConfirmationModalOpen(false);
        setCurrentStep(1);
        setAccumulatedPenaltyInterest(defaultNumberFormatValues);
    };
    const panelClass = classNames('custom-panel penalty-controls', {
        '-danger': isPenaltyOn
    });
    const switchVariant = isPenaltyOn ? 'danger' : 'safe';
    return (React.createElement("div", { className: panelClass, "data-testid": 'penalty-controls' },
        React.createElement("div", { className: 'header -space-between' },
            isPenaltyOn ? 'Default Rate Applied' : 'Apply Default Rate',
            isPenaltyToggleable && (React.createElement(ToggleSwitch, { isToggled: isPenaltyOn, switchOn: openConfirmaion, switchOff: switchOff, variant: switchVariant }))),
        React.createElement("div", { className: 'body' },
            React.createElement(PenaltyDisplay, { isPenaltyOn: isPenaltyOn, isPenaltyToggleable: isPenaltyToggleable, penaltyStartDate: currentStartDate, appliedInterestRate: appliedInterestRate })),
        React.createElement(Modal, { isOpen: isConfirmationModalOpen, onClose: closeModal },
            React.createElement(PenaltyConfirmation, { currentStep: currentStep, currentDate: currentDate, formattedTradeLoanId: formattedTradeLoanId || '', accumulatedPenaltyInterest: accumulatedPenaltyInterest, closeModal: closeModal, stepForward: stepForward, stepBack: stepBack, handleAccumulatedPenaltyValueChange: handleAccumulatedPenaltyValueChange, finishConfirmation: finishConfirmation }))));
};
export default PenaltyControls;
