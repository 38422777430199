import classNames from 'classnames';
import React, { useState } from 'react';
import { isEmptyString } from '../../utils/isEmptyString';
import ErrorList from '../shared/ErrorList';
const CustomPassword = ({ field, model, errors = {}, ariaLabel = 'Password', defaultValue, defaultVisible = false, hasError = false, isAutoFocused = false, isDisabled = false, labelText, labelClassName = 'label', defaultInputClassName = 'form-control input', maxLength = 50, placeholder = '', size = 50, required = false, validatePassword }) => {
    const [passwordVisible, setPasswordVisible] = useState(defaultVisible);
    const [showToggle, setShowToggle] = useState(false);
    const inputType = passwordVisible ? 'text' : 'password';
    const inputName = `${model}[${field}]`;
    const inputId = `${model}_${field}`;
    const buttonClassName = classNames('icon-button button', {
        '-eye': passwordVisible,
        '-eye-off': !passwordVisible
    });
    const labelClass = classNames(labelClassName, {
        '-error': hasError,
        '-required': required === true
    });
    const inputClassName = classNames(defaultInputClassName, {
        '-error': hasError
    });
    const toggleVisibility = () => {
        setPasswordVisible(passwordVisible ? false : true);
    };
    const handleChange = (event) => {
        const value = event.target.value;
        isEmptyString(value) ? setShowToggle(false) : setShowToggle(true);
        if (validatePassword) {
            validatePassword(value);
        }
    };
    return (React.createElement("div", { className: 'custom-password password', "data-testid": 'custom-password' },
        labelText && (React.createElement("label", { className: labelClass, htmlFor: `user_${field}`, "data-testid": 'label-text' }, labelText)),
        React.createElement("input", { ...{ defaultValue, maxLength, placeholder, size }, "aria-label": ariaLabel, autoComplete: 'new-password', autoFocus: isAutoFocused, className: inputClassName, disabled: isDisabled, id: inputId, name: inputName, type: inputType, "data-testid": 'password-input', onChange: handleChange }),
        showToggle && (React.createElement("button", { className: buttonClassName, onClick: toggleVisibility, type: 'button', "aria-label": 'Show/Hide Password', "data-testid": 'toggle' },
            React.createElement("i", { className: 'icon' }))),
        React.createElement(ErrorList, { errors: errors, field: field })));
};
export default CustomPassword;
