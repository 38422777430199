import React, { useState } from 'react';
import classNames from 'classnames';
import Panel from './Panel';
const MisbankedFilters = ({ baseUrl, isSelected, isApproved, isActive }) => {
    const [isOpened, setIsOpened] = useState(false);
    const togglePanel = () => setIsOpened(!isOpened);
    const buttonClass = classNames('icon-button button', '-filter', '-icon-filter-neutral-500', '-text-neutral-500', '-border-neutral-60', '-rectangular', '-background-neutral-0', {
        '-active': isOpened
    });
    return (React.createElement("div", { className: 'toggle-panel -filter-dropdown -broker', "data-testid": 'misbanked-filters' },
        React.createElement("a", { href: '#', className: buttonClass, onClick: togglePanel },
            React.createElement("div", { className: 'icon' }),
            React.createElement("div", { className: 'text' }, "Filters")),
        React.createElement(Panel, { ...{
                baseUrl,
                isOpened,
                isSelected,
                isApproved,
                isActive
            } })));
};
export default MisbankedFilters;
