import React, { useState, useEffect } from 'react';
import Flatpickr from 'react-flatpickr';
import queryString from 'query-string';
const DatePicker = ({ date, allowedDates, path, allowRedirect = true, label, minDate }) => {
    const [currentDate, setCurrentDate] = useState(date);
    const [isFirstRender, setIsFirstRender] = useState(true);
    useEffect(() => {
        if (!isFirstRender && allowRedirect) {
            const params = { date: currentDate };
            location.href = `${path}?${queryString.stringify(params)}`;
        }
        setIsFirstRender(false);
    }, [currentDate]);
    const handleDateChange = (selectedDates, dateString) => {
        setCurrentDate(dateString);
    };
    const dateSettings = {
        altInput: true,
        altFormat: 'd/m/Y',
        dateFormat: 'Y-m-d',
        onChange: handleDateChange,
        ...(minDate && { minDate: 'today' }),
        ...(allowedDates && { enable: allowedDates })
    };
    return (React.createElement("div", { className: 'form-group', "data-testid": 'input' },
        React.createElement("label", { className: 'label -uppercase' }, label),
        React.createElement(Flatpickr, { value: currentDate, className: 'input form-control', options: dateSettings })));
};
export default DatePicker;
