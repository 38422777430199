import React, { useState } from 'react';
import classNames from 'classnames';
import TextTooltip from '../../components/custom-tooltip/TextTooltip';
import ABAModal from './ABAModal';
import ViewIcon from './ViewIcon';
import ProcessIcon from './ProcessIcon';
import ABAIcon from './ABAIcon';
const DrawdownRow = ({ drawdown, path, processedDrawdown, fetchDrawdowns, showABA }) => {
    const { id, companyName, approvedOn, amount, paymentReference, funder, destinationAccountDetail, abaDownloaded, companyId, netAmount, drawdownFee } = drawdown;
    const [isVisible, setIsVisible] = useState(false);
    const toggleModal = () => {
        isVisible ? setIsVisible(false) : setIsVisible(true);
    };
    const handleRefresh = () => {
        toggleModal();
        fetchDrawdowns();
    };
    const actionClass = classNames('action-buttons -space-between', {
        '-drawdown': showABA,
        '-process': !showABA
    });
    return (React.createElement("tr", { "data-testid": 'drawdown-row' },
        React.createElement("td", { className: 'cell' }, id),
        React.createElement("td", { className: 'cell' },
            React.createElement("a", { href: `/admin/companies/${companyId}` }, companyName)),
        React.createElement("td", { className: 'cell' }, approvedOn),
        React.createElement("td", { className: 'cell' }, amount),
        React.createElement("td", { className: 'cell' }, drawdownFee),
        React.createElement("td", { className: 'cell' }, netAmount),
        React.createElement("td", { className: 'cell' }, paymentReference),
        React.createElement("td", { className: 'cell' }, funder),
        React.createElement("td", { className: 'cell -wrap' }, destinationAccountDetail),
        React.createElement("td", { className: 'cell' },
            React.createElement("div", { className: actionClass },
                React.createElement(TextTooltip, { text: React.createElement(ViewIcon, { url: `${path}/${id}` }), tooltipText: 'View', tooltipId: 'view', activeTheme: 'light' }),
                React.createElement(TextTooltip, { text: React.createElement(ProcessIcon, { ...{ processedDrawdown, id } }), tooltipText: 'Process', tooltipId: 'process', activeTheme: 'light' }),
                showABA && (React.createElement(TextTooltip, { text: React.createElement(ABAIcon, { path: `${path}?status=aba`, isDownloaded: abaDownloaded }), tooltipText: 'Download ABA', tooltipId: 'aba', activeTheme: 'light' })),
                React.createElement(ABAModal, { path: `${path}/${id}/download_aba`, ...{ isVisible, drawdown, toggleModal, handleRefresh } })))));
};
export default DrawdownRow;
