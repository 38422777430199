import React, { useMemo, useState } from 'react';
import Modal from '../shared/Modal';
import Select from 'react-select';
const AdminClientModal = ({ isModalOpen, closeModal, availableClients, handleSetClient }) => {
    const [selectedClient, setSelectedClient] = useState();
    const [showConfirm, setShowConfirm] = useState(false);
    const options = useMemo(() => {
        return availableClients.map(client => {
            return { value: client.id, label: client.displayedName };
        });
    }, [availableClients]);
    const handleOnchange = (selectedOption) => {
        setSelectedClient(selectedOption);
    };
    const clearSelection = () => {
        setSelectedClient(null);
        setShowConfirm(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { isOpen: isModalOpen, onClose: () => {
                closeModal();
                clearSelection();
            } },
            !showConfirm && (React.createElement("div", { className: 'custom-panel -modal', "data-testid": 'admin-client-modal-1' },
                React.createElement("div", { className: 'header' }, "Clients"),
                React.createElement("div", { className: 'content' },
                    React.createElement(Select, { onChange: handleOnchange, options: options, value: selectedClient })),
                React.createElement("div", { className: 'footer custom-action -space-between' },
                    React.createElement("button", { onClick: () => {
                            closeModal();
                            clearSelection();
                        }, className: 'custom-button button -outline' }, "Cancel"),
                    React.createElement("button", { onClick: () => setShowConfirm(true), className: 'custom-button button -default' }, "Assign")))),
            showConfirm && selectedClient && (React.createElement("div", { className: 'custom-panel -modal', "data-testid": 'admin-client-modal-2' },
                React.createElement("div", { className: 'header' }, "Clients"),
                React.createElement("div", { className: 'content' },
                    React.createElement("p", null,
                        "Are you sure you to assign ", selectedClient === null || selectedClient === void 0 ? void 0 :
                        selectedClient.label,
                        " as client?")),
                React.createElement("div", { className: 'footer custom-action -space-between' },
                    React.createElement("button", { onClick: () => setShowConfirm(false), className: 'custom-button button -outline' }, "Go back"),
                    React.createElement("button", { onClick: () => {
                            handleSetClient(selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.value);
                            clearSelection();
                        }, className: 'custom-button button -default' }, "Confirm")))))));
};
export default AdminClientModal;
