import React from 'react';
import classNames from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import CustomPassword from '../shared/CustomPassword';
import ErrorList from '../shared/ErrorList';
import PasswordValidation from '../../components/edit-profile-container/PasswordValidation';
const InvoiceFinanceForm = ({ csrfToken, errors, formUrl, otherParams, siteKey, userData, captchaValue, handleCaptchaChange }) => {
    // const { referralToken, utmCampaign, utmMedium, utmSource } = otherParams
    const { referralToken } = otherParams;
    const { firstName, lastName, signupCompanyName, email } = userData;
    const firstNameLabelClass = classNames('label -required', {
        '-error': errors.firstName
    });
    const firstNameInputClass = classNames('form-control -outline', {
        '-error': errors.firstName
    });
    const lastNameLabelClass = classNames('label -required', {
        '-error': errors.lastName
    });
    const lastNameInputClass = classNames('form-control -outline', {
        '-error': errors.lastName
    });
    const signupCompanyLabelClass = classNames('label -required', {
        '-error': errors.signupCompanyName
    });
    const signupCompanyNameInputClass = classNames('form-control -outline', {
        '-error': errors.signupCompanyName
    });
    const emailLabelClass = classNames('label -required', {
        '-error': errors.email
    });
    const emailInputClass = classNames('form-control -outline', {
        '-error': errors.email
    });
    const passwordLabelClass = classNames('label -required', {
        '-error': errors.password
    });
    const passwordConfirmationLabelClass = classNames('label -required', {
        '-error': errors.passwordConfirmation
    });
    const hasPasswordError = errors.password && errors.password.length > 0;
    const hasPasswordConfirmationError = errors.passwordConfirmation && errors.passwordConfirmation.length > 0;
    return (React.createElement("form", { className: 'registration-form form -full -no-margin-top -invoice-finance', action: formUrl, acceptCharset: 'UTF-8', method: 'post' },
        React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
        React.createElement("div", { className: 'flex-grid -half' },
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { htmlFor: 'user_first_name', className: firstNameLabelClass }, "First Name"),
                React.createElement("input", { type: 'text', id: 'user_first_name', name: 'user[first_name]', className: firstNameInputClass, defaultValue: firstName || '' }),
                React.createElement(ErrorList, { errors: errors, field: 'firstName' })),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { htmlFor: 'user_last_name', className: lastNameLabelClass }, "Last Name"),
                React.createElement("input", { type: 'text', id: 'user_last_name', name: 'user[last_name]', className: lastNameInputClass, defaultValue: lastName || '' }),
                React.createElement(ErrorList, { errors: errors, field: 'lastName' }))),
        React.createElement("div", { className: 'flex-grid' },
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { htmlFor: 'user_signup_company_name', className: signupCompanyLabelClass }, "Business Name"),
                React.createElement("input", { type: 'text', id: 'user_signup_company_name', name: 'user[signup_company_name]', className: signupCompanyNameInputClass, defaultValue: signupCompanyName || '' }),
                React.createElement(ErrorList, { errors: errors, field: 'signupCompanyName' }))),
        React.createElement("div", { className: 'flex-grid' },
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { htmlFor: 'user_email', className: emailLabelClass }, "Email"),
                React.createElement("input", { type: 'email', id: 'user_email', name: 'user[email]', className: emailInputClass, defaultValue: email, maxLength: 255 }),
                React.createElement(ErrorList, { errors: errors, field: 'email' }))),
        React.createElement("div", { className: 'flex-grid' },
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { htmlFor: 'user_password', className: passwordLabelClass }, "Password"),
                React.createElement(CustomPassword, { field: 'password', model: 'user', placeholder: '', hasError: hasPasswordError, defaultInputClassName: 'form-control input -outline' }),
                React.createElement(ErrorList, { errors: errors, field: 'password' }))),
        React.createElement("div", { className: 'flex-grid' },
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { htmlFor: 'user_password_confirmation', className: passwordConfirmationLabelClass }, "Confirm Password"),
                React.createElement(PasswordValidation, { model: 'user', name: 'password_confirmation', hasError: hasPasswordConfirmationError, labelClassName: 'label', inputClassName: 'form-control input -outline' }),
                React.createElement(ErrorList, { errors: errors, field: 'passwordConfirmation' }))),
        React.createElement("div", { className: 'flex-grid' },
            React.createElement("div", { className: 'form-group -recaptcha' },
                React.createElement(ReCAPTCHA, { sitekey: siteKey, onChange: handleCaptchaChange }),
                React.createElement("input", { type: 'hidden', value: captchaValue, name: 'user[recaptcha_response]' }))),
        React.createElement("div", { className: 'actions action-buttons -signup-wizard' },
            React.createElement("button", { type: 'submit', className: 'button -primary -next' }, "Submit")),
        referralToken && (React.createElement("input", { type: 'hidden', name: 'user[referral_token]', value: referralToken }))));
};
export default InvoiceFinanceForm;
