import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { numberToPercentageFixed } from '../../utils/numberToPercentage';
import ErrorList from '../shared/ErrorList';
import ToggleSwitch from '../shared/ToggleSwitch';
import TradeLoanInterestTooltip from './TradeLoanInterestTooltip';
const TradeFinanceFeeEditor = ({ formatAttributes, tradeFinanceFee, tradeLoanBaseRate, readOnly = false, errors }) => {
    const { applyBaseRate, drawdownFee, tradeFacilityInterestMarginRate, overdueInterestStepUp, minimumDrawdownFee, facilityLimit, autoPenaltyEnabled } = tradeFinanceFee || 0;
    const [isBaseRateApplied, setIsBaseRateApplied] = useState(applyBaseRate);
    const [isAutoPenaltyEnabled, setIsAutoPenaltyEnabled] = useState(autoPenaltyEnabled);
    const switchOnAutoPenalty = () => {
        setIsAutoPenaltyEnabled(true);
    };
    const switchOffAutoPenalty = () => {
        setIsAutoPenaltyEnabled(false);
    };
    const { url, csrfToken } = formatAttributes;
    const percentageValue = value => {
        return value && numberToPercentageFixed(value, 2);
    };
    const initialValue = {
        formattedValue: '',
        value: percentageValue(tradeFacilityInterestMarginRate),
        floatValue: tradeFacilityInterestMarginRate * 100
    };
    const [currentMargin, setCurrentMargin] = useState(initialValue);
    const calculateTradeLoanInterestRate = (interestValue, withBaseRate, withPenalty) => {
        const baseRate = withBaseRate ? Number(tradeLoanBaseRate) : 0;
        const interest = interestValue.floatValue
            ? interestValue.floatValue / 100
            : 0;
        const penalty = withPenalty ? Number(overdueInterestStepUp) : 0;
        return baseRate + interest + penalty;
    };
    const [tradeLoanInterestRateWithPenalty, setTradeLoanInterestRateWithPenalty] = useState(calculateTradeLoanInterestRate(initialValue, isBaseRateApplied, true));
    const [tradeLoanInterestRateWithNoPenalty, setTradeLoanInterestRateWithNoPenalty] = useState(calculateTradeLoanInterestRate(initialValue, isBaseRateApplied, false));
    useEffect(() => {
        setTradeLoanInterestRateWithPenalty(calculateTradeLoanInterestRate(currentMargin, isBaseRateApplied, true));
        setTradeLoanInterestRateWithNoPenalty(calculateTradeLoanInterestRate(currentMargin, isBaseRateApplied, false));
    }, [currentMargin, isBaseRateApplied]);
    const handleMarginValue = (values) => {
        setCurrentMargin(values);
    };
    const submitClassNames = classNames('custom-button update -default -narrow', {
        '-disabled': readOnly
    });
    const baseRateToggleOn = () => {
        setIsBaseRateApplied(true);
    };
    const baseRateToggleOff = () => {
        setIsBaseRateApplied(false);
    };
    return (React.createElement("form", { className: 'form form-pane', action: url, acceptCharset: 'UTF-8', method: 'post', "data-testid": 'trade-finance-fee-editor' },
        React.createElement("input", { type: 'hidden', name: '_method', value: 'put' }),
        React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
        React.createElement("div", { className: 'form-row -col-2' },
            React.createElement("div", { className: 'form-group -double-bottom' },
                React.createElement("label", { className: 'label -uppercase -required' }, "Drawdown Fee (%)"),
                React.createElement(NumberFormat, { defaultValue: percentageValue(drawdownFee), className: 'input form-control -gap', allowNegative: false, id: 'drawdown-fee', "data-testid": 'drawdown-fee', name: 'trade_finance_fee[drawdown_fee]', readOnly: readOnly }),
                React.createElement(ErrorList, { errors: errors, field: 'drawdown_fee' })),
            React.createElement("div", { className: 'form-group -double-bottom' },
                React.createElement("label", { className: 'label -uppercase -required' }, "Minimum Drawdown Fee ($)"),
                React.createElement(NumberFormat, { defaultValue: minimumDrawdownFee, className: 'input form-control -gap', allowNegative: false, thousandSeparator: true, decimalScale: 2, fixedDecimalScale: true, id: 'minimum-drawdown-fee-cents', "data-testid": 'minimum-drawdown-fee', name: 'trade_finance_fee[minimum_drawdown_fee]', readOnly: readOnly }),
                React.createElement(ErrorList, { errors: errors, field: 'minimum_drawdown_fee' }))),
        React.createElement("div", { className: 'form-row -col-2' },
            React.createElement("div", { className: 'form-group -double-bottom' },
                React.createElement("label", { className: 'label -uppercase -required -nowrap' }, "Trade Facility Interest (%)"),
                React.createElement(NumberFormat, { value: currentMargin.value, onValueChange: handleMarginValue, className: 'input form-control -gap', decimalScale: 2, allowNegative: false, id: 'trade-facility-interest-margin-rate', "data-testid": 'trade-facility-interest-margin-rate', name: 'trade_finance_fee[trade_facility_interest_margin_rate]', readOnly: readOnly }),
                React.createElement(ErrorList, { errors: errors, field: 'trade_facility_interest_margin_rate' })),
            React.createElement("div", { className: 'form-group -double-bottom' },
                React.createElement("label", { className: 'label -uppercase -required' }, "Facility Limit ($)"),
                React.createElement(NumberFormat, { defaultValue: facilityLimit, className: 'input form-control -gap', allowNegative: false, thousandSeparator: true, decimalScale: 2, fixedDecimalScale: true, id: 'facility-limit-cents', "data-testid": 'facility-limit-cents', name: 'trade_finance_fee[facility_limit]', readOnly: readOnly }),
                React.createElement(ErrorList, { errors: errors, field: 'facility_limit' }))),
        React.createElement("div", { className: 'form-row -col-2' },
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label -uppercase -double-spaced' }, "Default Rate (%)"),
                React.createElement("div", { className: 'text' },
                    percentageValue(overdueInterestStepUp),
                    " %"))),
        React.createElement("hr", { className: 'custom-divider -my-6' }),
        React.createElement("div", { className: 'heading' }, "Trade Loan Interest Rate"),
        React.createElement("div", { className: 'form-row -col-2' },
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label -uppercase -double-spaced' }, "When not in default"),
                React.createElement("div", { className: 'custom-text' },
                    React.createElement("div", { className: 'text -blue -large' },
                        percentageValue(tradeLoanInterestRateWithNoPenalty),
                        " %",
                        React.createElement(TradeLoanInterestTooltip, { tooltipId: 'non-default', baseRate: percentageValue(tradeLoanBaseRate), interest: currentMargin.value, withBaseRate: isBaseRateApplied, interestStatus: 'normal' })))),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label -uppercase -double-spaced' }, "When in default"),
                React.createElement("div", { className: 'custom-text' },
                    React.createElement("div", { className: 'text -red -large' },
                        percentageValue(tradeLoanInterestRateWithPenalty),
                        " %",
                        React.createElement(TradeLoanInterestTooltip, { tooltipId: 'default', baseRate: percentageValue(tradeLoanBaseRate), penalty: percentageValue(overdueInterestStepUp), interest: currentMargin.value, withBaseRate: isBaseRateApplied, interestStatus: 'penalty' }))))),
        React.createElement("hr", { className: 'custom-divider -my-6' }),
        React.createElement("div", { className: 'form-group -oneline' },
            React.createElement("label", { className: 'label -bold' }, "Automatic Default Interest"),
            React.createElement(ToggleSwitch, { isToggled: isAutoPenaltyEnabled, switchOn: switchOnAutoPenalty, switchOff: switchOffAutoPenalty, name: 'trade_finance_fee[auto_penalty_enabled]' })),
        React.createElement("div", { className: 'form-group -inline -double-bottom' },
            React.createElement("div", { className: 'text' }, "When automatic default interest is turned on, default interest will be charged as soon as a trade loan goes past its due date")),
        React.createElement("hr", { className: 'custom-divider -my-6' }),
        React.createElement("div", { className: 'form-group -oneline' },
            React.createElement("label", { className: 'label -bold' }, "Base Rate"),
            React.createElement(ToggleSwitch, { isToggled: isBaseRateApplied, switchOn: baseRateToggleOn, switchOff: baseRateToggleOff, name: 'trade_finance_fee[apply_base_rate]' })),
        React.createElement("div", { className: 'form-group -inline -double-bottom' },
            React.createElement("div", { className: 'text' }, "When using the base rate the calculation for interest is Base Rate + Trade Facility Interest Margin. If the base rate is not used the Trade Facility Interest Margin rate will be the interest rate applied.")),
        React.createElement("div", { className: 'form-group -inline' },
            React.createElement("div", { className: 'input -medium' },
                React.createElement("label", { className: 'label -uppercase' }, "Base Rate (%)"),
                React.createElement("div", { className: 'text' }, percentageValue(tradeLoanBaseRate))),
            React.createElement("div", { className: 'input -medium' },
                React.createElement("label", { className: 'label -uppercase' }, "Trade Facility Interest (%)"),
                React.createElement("div", { className: 'text' }, currentMargin.value))),
        React.createElement("div", { className: 'action-buttons actions' },
            React.createElement("input", { type: 'submit', className: submitClassNames, name: 'commit', value: 'Save', "data-disable-with": 'Saving...', disabled: readOnly }))));
};
export default TradeFinanceFeeEditor;
