import React from 'react';
import { format, utcToZonedTime } from 'date-fns-tz';
import { humanizeString } from '../../utils';
const CustomerAuditLogsPanel = ({ groupedAuditLogs }) => {
    return (React.createElement("div", { className: 'custom-panel' },
        React.createElement("div", { className: 'header' },
            React.createElement("div", { className: 'title' }, "Audit Log")),
        React.createElement("div", { className: 'timeline-panel', "data-testid": 'customer-audit-logs-panel' }, Object.keys(groupedAuditLogs).length > 0 ? (Object.keys(groupedAuditLogs).map(date => {
            return (React.createElement("div", { className: 'timeline-log -audit', key: date },
                React.createElement("div", { className: 'date' },
                    React.createElement("div", { className: 'text' },
                        React.createElement("div", { className: 'day -month' }, new Date(date).toLocaleDateString('en-US', {
                            month: 'short'
                        })),
                        React.createElement("div", { className: 'month' }, new Date(date).toLocaleDateString('en-US', {
                            year: 'numeric'
                        }))),
                    React.createElement("div", { className: 'number' }, new Date(date).toLocaleDateString('en-US', {
                        day: 'numeric'
                    }))),
                React.createElement("div", { className: 'activities' }, groupedAuditLogs[date].map(activity => {
                    return (React.createElement("div", { key: activity.id, className: 'time-line -audit' },
                        React.createElement("div", { className: `status-box status -inverted ${activity.statusClassVariant}` }, humanizeString(activity.action)),
                        React.createElement("div", { className: 'info' },
                            React.createElement("div", { className: 'time' }, format(utcToZonedTime(activity.createdAt, 'Australia/Sydney'), 'h:mm a')),
                            activity.descriptionTag && (React.createElement("div", { className: 'body', dangerouslySetInnerHTML: {
                                    __html: activity.descriptionTag
                                } }))),
                        React.createElement("div", { className: 'author' },
                            activity.userAvatar && (React.createElement("img", { alt: activity.userFullName, src: activity.userAvatar, className: 'avatar' })),
                            React.createElement("div", { className: 'name' }, activity.userFullName))));
                }))));
        })) : (React.createElement("div", { className: 'placeholder' }, "There are no audit logs to display.")))));
};
export default CustomerAuditLogsPanel;
