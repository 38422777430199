import React from 'react';
import AdminCreditorRow from './AdminCreditorRow';
const AdminCreditorsTableBody = ({ apiToken, borrowerCreditorsPath, borrowerId, creditorsArray, hasErrors, isLoading }) => {
    if (isLoading) {
        return (React.createElement("tbody", { "data-testid": 'loading' },
            React.createElement("tr", null,
                React.createElement("td", { className: 'cell', colSpan: 7 },
                    React.createElement("div", { className: 'loading-container -table' },
                        React.createElement("div", { className: 'loading-spinner' }))))));
    }
    if (creditorsArray.length === 0) {
        return (React.createElement("tbody", { "data-testid": 'no-record' },
            React.createElement("tr", null,
                React.createElement("td", { className: 'cell', colSpan: 7 }, "There are no Creditors to display"))));
    }
    if (hasErrors) {
        return (React.createElement("tbody", { "data-testid": 'error-record' },
            React.createElement("tr", null,
                React.createElement("td", { className: 'cell', colSpan: 7 }, "Could not fetch Creditors"))));
    }
    return (React.createElement("tbody", { "data-testid": 'loaded' }, creditorsArray.map((creditor) => {
        return (React.createElement(AdminCreditorRow, { ...{
                apiToken,
                borrowerId,
                creditor,
                borrowerCreditorsPath
            }, key: creditor.id }));
    })));
};
export default AdminCreditorsTableBody;
