import React from 'react';
import ReactTooltip from 'react-tooltip';
const PaymentTooltip = ({ totalRepayment, principalRepaid, feesRepaid, tooltipId, interestRepaid, recordDate, payment, transferRepayment }) => {
    const theme = {
        backgroundColor: '#ffffff',
        textColor: '#091e42',
        border: true,
        borderColor: '#c1c7d0'
    };
    return (React.createElement("div", { className: 'custom-tooltip -text-group-tooltip -no-left-margin -inline-block', "data-testid": 'text-tooltip' },
        React.createElement("div", { className: 'text' }, payment),
        React.createElement("span", { className: 'custom-icon -notice -blue', "data-tip": true, "data-for": tooltipId }),
        React.createElement(ReactTooltip, { ...theme, id: tooltipId, place: 'bottom', effect: 'solid' },
            React.createElement("div", { className: 'text-group -tooltip' },
                React.createElement("label", { className: 'title' },
                    "A repayment on",
                    React.createElement("span", { className: 'bold' },
                        " ",
                        recordDate,
                        " "),
                    "was processed."),
                React.createElement("div", { className: 'text -larger spacing' },
                    React.createElement("span", { className: 'bold' }, "Total Repayment"),
                    React.createElement("span", { className: 'bold value' }, totalRepayment)),
                React.createElement("div", { className: 'text -larger spacing' },
                    React.createElement("span", { className: 'bold' }, "Principal Repaid"),
                    React.createElement("span", { className: 'bold value' }, principalRepaid)),
                React.createElement("div", { className: 'text -larger spacing' },
                    React.createElement("span", { className: 'bold' }, "Fees Repaid"),
                    React.createElement("span", { className: 'bold value' }, feesRepaid)),
                React.createElement("div", { className: 'text -larger spacing' },
                    React.createElement("span", { className: 'bold' }, "Interest Repaid"),
                    React.createElement("span", { className: 'bold value' }, interestRepaid)),
                React.createElement("div", { className: 'text -larger spacing' },
                    React.createElement("span", { className: 'bold' }, "Transfer Repayment"),
                    React.createElement("span", { className: 'bold value' }, transferRepayment))))));
};
export default PaymentTooltip;
