import React, { useLayoutEffect, useRef, useState } from 'react';
const Pane = ({ children, currentStep, title, step }) => {
    const [contentHeight, setContentHeight] = useState(0);
    const headingClass = () => {
        if (step < currentStep) {
            return 'drawdown-heading -done';
        }
        else if (step === currentStep) {
            return 'drawdown-heading -active';
        }
        else if (step > currentStep) {
            return 'drawdown-heading';
        }
    };
    const content = useRef(null);
    useLayoutEffect(() => {
        if (content.current) {
            setContentHeight(content.current.scrollHeight);
        }
    }, []);
    const contentStyle = {
        height: step === currentStep ? `${contentHeight}px` : '0'
    };
    return (React.createElement("div", { className: 'pane', "data-testid": `pane-step-${step}` },
        React.createElement("div", { className: headingClass(), "data-testid": `heading-step-${step}` },
            React.createElement("span", { className: 'indicator' }),
            title),
        React.createElement("div", { className: 'content', style: contentStyle },
            React.createElement("div", { className: 'drawdown-content', ref: content }, children))));
};
export default Pane;
