import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
const SlideshowContainer = ({ slides }) => {
    return (React.createElement("div", { className: 'wrapper' },
        React.createElement(Splide, { options: {
                rewind: true,
                perMove: 1,
                arrows: false,
                interval: 3500,
                pauseOnHover: false,
                pauseOnFocus: false,
                autoplay: true,
                drag: false,
                type: 'fade',
                keyboard: false,
                easing: 'all 0.5s cubic-bezier(0.23, 1, 0.32, 1)'
            } }, slides.map(({ id, icon, text }) => {
            return (React.createElement(SplideSlide, { key: id },
                React.createElement("div", { className: 'slide-show' },
                    React.createElement("div", { className: 'icon' },
                        React.createElement("img", { className: 'logo', src: icon })),
                    React.createElement("div", { className: 'text' }, text))));
        }))));
};
export default SlideshowContainer;
