import React, { useEffect, useRef, useState } from 'react';
import AdminCreditorsTable from '../../components/admin-creditors-table/AdminCreditorsTable';
const AdminCreditorsDashboard = ({ apiToken, borrowerCreditorsPath, borrowerId, fetchCreditorsForBorrower }) => {
    const [creditorsArray, setCreditorsArray] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(0);
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [headerWidths, setHeaderWidths] = useState([]);
    const [hasErrors, setHasErrors] = useState(false);
    const headerRef = useRef(null);
    useEffect(() => {
        if (headerRef && headerRef.current) {
            const headerWidthList = [...headerRef.current.children[0].children].map(tableHeader => tableHeader.getBoundingClientRect().width);
            setHeaderWidths(headerWidthList);
        }
        fetchCreditors();
    }, [currentPage, sortBy, sortDirection]);
    const fetchCreditors = async () => {
        setIsLoading(true);
        try {
            const { data } = await fetchCreditorsForBorrower(apiToken, borrowerId, currentPage, sortBy, sortDirection);
            const { page, pageSize, totalItems, totalPages } = data.attributes;
            setCreditorsArray(page);
            setTotalPages(totalPages);
            setTotalItems(totalItems);
            setPageSize(pageSize);
            setIsLoading(false);
            setHasErrors(false);
        }
        catch (err) {
            setHasErrors(true);
        }
    };
    const sortCreditors = (field, defaultSort) => {
        setSortBy(field);
        if (sortBy === field && sortDirection === 'asc') {
            setSortDirection('desc');
        }
        else if (sortBy === field && sortDirection === 'desc') {
            setSortDirection('asc');
        }
        else {
            setSortDirection(defaultSort || 'asc');
        }
    };
    const handlePageClick = data => {
        setCurrentPage(data.selected + 1);
    };
    return (React.createElement("div", { className: 'customers-dashboard customers -admin', "data-testid": 'admin-creditors-dashboard' },
        React.createElement("div", { className: 'creditors' },
            React.createElement(AdminCreditorsTable, { ...{
                    apiToken,
                    borrowerCreditorsPath,
                    borrowerId,
                    currentPage,
                    creditorsArray,
                    isLoading,
                    pageSize,
                    sortBy,
                    sortDirection,
                    totalPages,
                    totalItems,
                    headerWidths,
                    hasErrors,
                    handlePageClick,
                    sortCreditors
                }, forwardHeaderRef: headerRef }))));
};
export default AdminCreditorsDashboard;
