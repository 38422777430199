import classNames from 'classnames';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import ScrollLock from 'react-scrolllock';
import AccordionPane from '../shared/AccordionPane';
const MobileMenu = ({ menuButtonClass, menuClass, isOpenDefault }) => {
    const [isOpen, setIsOpen] = useState(isOpenDefault || false);
    const [isToggling, setIsToggling] = useState(false);
    const [didMount, setDidMount] = useState(false);
    useEffect(() => {
        setDidMount(true);
    }, []);
    useLayoutEffect(() => {
        if (didMount) {
            setIsToggling(true);
            const timer = setTimeout(() => {
                setIsToggling(false);
            }, 360);
            return () => clearTimeout(timer);
        }
    }, [isOpen]);
    const openMenu = (e) => {
        e.preventDefault();
        if (!isToggling) {
            setIsOpen(true);
        }
    };
    const closeMenu = (e) => {
        e.preventDefault();
        if (!isToggling) {
            setIsOpen(false);
        }
    };
    const extendedMenuButtonClass = classNames(menuButtonClass, {
        '-close': isOpen
    });
    const navigationClass = classNames('navigation', {
        '-open': isOpen,
        '-closed': !isOpen
    });
    return (React.createElement("div", { className: menuClass, "data-testid": 'mobile-menu' },
        React.createElement("button", { className: extendedMenuButtonClass, onClick: isOpen ? closeMenu : openMenu, "aria-label": 'mobile menu', "data-testid": 'menu-trigger' },
            React.createElement("i", { className: 'icon' })),
        React.createElement(ScrollLock, { isActive: isOpen },
            React.createElement("div", { className: navigationClass, "data-testid": 'navigation' },
                React.createElement(AccordionPane, { title: 'Business Loan Guides', isMenu: true },
                    React.createElement("div", { className: 'navigation-menu -accordion' },
                        React.createElement("a", { href: '/invoice-finance', className: 'link' }, "Invoice Finance"),
                        React.createElement("a", { href: '/debtor-finance', className: 'link' }, "Debtor Finance"),
                        React.createElement("a", { href: '/invoice-discounting', className: 'link' }, "Invoice Discounting"),
                        React.createElement("a", { href: '/single-invoice-finance', className: 'link' }, "Single Invoice Finance"),
                        React.createElement("a", { href: '/invoice-factoring', className: 'link' }, "Invoice Factoring"),
                        React.createElement("a", { href: '/equipment-finance', className: 'link' }, "Equipment Finance"))),
                React.createElement(AccordionPane, { title: 'Learn', isMenu: true },
                    React.createElement("div", { className: 'navigation-menu -accordion' },
                        React.createElement("a", { href: '/cash-flow-liquidity-working-capital', className: 'link' }, "Business Finance Fundamentals"),
                        React.createElement("a", { href: '/managing-business-cash-flow', className: 'link' }, "Managing Business Cash Flow"),
                        React.createElement("a", { href: '/managing-solvency-profitability-and-efficiency', className: 'link' }, "Managing Solvency, Profitability and Efficiency"),
                        React.createElement("a", { href: '/cash-conversion-cycle', className: 'link' }, "The Cash Conversion Cycle"),
                        React.createElement("a", { href: '/business-loan-calculator', className: 'link' }, "Business Loan Calculator"))),
                React.createElement("a", { href: 'https://blog.earlypay.com.au', className: 'link' }, "Blog"),
                React.createElement("a", { href: '/login', className: 'link' }, "Log In"),
                React.createElement("a", { href: '/register', className: 'link' }, "Sign Up")))));
};
export default MobileMenu;
