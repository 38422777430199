import classNames from 'classnames';
import React, { useState, useRef, Fragment } from 'react';
import NumberFormat from 'react-number-format';
import { formatDateWithYearAU } from '../../utils/formatDateWithYearAU';
import Modal from '../../components/shared/Modal';
import CustomFlatpickr from '../../components/shared/CustomFlatpickr';
import ErrorList from '../../components/shared/ErrorList';
const SuspenseModal = ({ path, csrfToken, errors, collectionAccountCount }) => {
    const initialAmount = {
        formattedValue: '',
        value: '0',
        floatValue: 0
    };
    const [currentStep, setCurrentStep] = useState(1);
    const [reference, setReference] = useState('');
    const [description, setDescription] = useState('');
    const [allocatedAmount, setAllocatedAmount] = useState(initialAmount);
    const [selectedDate, setSelectedDate] = useState('');
    const dateClearRef = useRef(null);
    const datePickerRef = useRef(null);
    const hasErrors = Object.keys(errors).length > 0;
    const [isVisible, setIsVisible] = useState(hasErrors);
    const toggleModal = () => {
        isVisible ? setIsVisible(false) : setIsVisible(true);
    };
    const stepOneClass = classNames({
        '-hidden': currentStep !== 1
    });
    const stepTwoClass = classNames({
        '-hidden': currentStep !== 2
    });
    const handleReferenceChange = event => {
        setReference(event.target.value);
    };
    const handleDescriptionChange = event => {
        setDescription(event.target.value);
    };
    const handleAllocatedAmountChange = (values) => {
        setAllocatedAmount(values);
    };
    const handleSelectedDateChange = (selectedDates, dateString) => {
        setSelectedDate(dateString);
    };
    const flatpickrSettings = {
        wrap: true,
        altInput: true,
        altFormat: 'd M Y',
        dateFormat: 'Y-m-d',
        maxDate: 'today',
        defaultDate: selectedDate,
        onChange: handleSelectedDateChange
    };
    return (React.createElement("div", null,
        collectionAccountCount > 0 ? (React.createElement("button", { className: 'custom-button -default', onClick: () => toggleModal() }, "Create Suspense")) : (React.createElement("button", { className: 'custom-button -disabled', disabled: true }, "Create Suspense")),
        React.createElement(Modal, { isOpen: isVisible, onClose: toggleModal },
            React.createElement("form", { acceptCharset: 'UTF-8', method: 'post', action: path, className: 'form' },
                React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
                React.createElement("div", { className: 'custom-panel -modal', role: 'dialog', "aria-modal": 'true', "data-testid": 'customer-modal' },
                    React.createElement(Fragment, null,
                        React.createElement("div", { className: `header ${stepOneClass}` }, "Create Suspense"),
                        React.createElement("div", { className: `content ${stepOneClass}` },
                            React.createElement("div", { className: 'section' },
                                React.createElement("div", { className: 'description' },
                                    React.createElement("div", { className: 'form-group' },
                                        React.createElement("label", { className: 'label -uppercase -required' }, "Reference"),
                                        React.createElement("input", { type: 'text', name: 'bank_transaction[reference]', className: 'input form-control', value: reference, onChange: handleReferenceChange }),
                                        React.createElement(ErrorList, { errors: errors, field: 'reference' })),
                                    React.createElement("div", { className: 'form-group' },
                                        React.createElement("label", { className: 'label -uppercase' }, "Description"),
                                        React.createElement("textarea", { name: 'bank_transaction[description]', className: 'input form-control -textarea', value: description, onChange: handleDescriptionChange })),
                                    React.createElement("div", { className: 'form-group' },
                                        React.createElement("label", { className: 'label -uppercase -required' }, "Unallocated amount"),
                                        React.createElement(NumberFormat, { thousandSeparator: true, decimalScale: 2, prefix: '$', name: 'bank_transaction[amount]', id: 'bank_transaction[amount]', value: allocatedAmount.value, allowNegative: false, onValueChange: handleAllocatedAmountChange, className: 'input form-control', "data-testid": 'bank_transaction_amount_amount_field' }),
                                        React.createElement(ErrorList, { errors: errors, field: 'amount_cents' })),
                                    React.createElement("div", { className: 'form-group' },
                                        React.createElement("label", { className: 'label -uppercase -required' }, "Date"),
                                        React.createElement(CustomFlatpickr, { ...{ selectedDate, flatpickrSettings }, forwardDatePickerRef: datePickerRef, forwardDateClearRef: dateClearRef, clearButtonText: 'Clear Date', name: `bank_transaction[date]`, pickerBackground: 'neutral-20', placeholderText: '' }),
                                        React.createElement(ErrorList, { errors: errors, field: 'date' }))))),
                        React.createElement("div", { className: `action-buttons buttons space -border -padding ${stepOneClass}` },
                            React.createElement("button", { className: 'button -neutral', type: 'button', onClick: toggleModal }, "Cancel"),
                            React.createElement("button", { className: 'button -primary -wide', type: 'button', onClick: () => setCurrentStep(2) }, "Create"))),
                    React.createElement(Fragment, null,
                        React.createElement("div", { className: `header ${stepTwoClass}` }, "Confirmation"),
                        React.createElement("div", { className: `content ${stepTwoClass}` },
                            React.createElement("div", { className: 'section' },
                                React.createElement("div", { className: 'description' },
                                    React.createElement("span", { className: 'flag' }, "Warning!"),
                                    " You cannot delete a suspense once created. Please review the details below before confirming it.")),
                            React.createElement("div", { className: 'form-control -textarea -confirmation' },
                                React.createElement("div", { className: 'form-group' },
                                    React.createElement("label", { className: 'label -uppercase' }, "Reference"),
                                    React.createElement("div", { className: 'value' }, reference)),
                                React.createElement("div", { className: 'form-group' },
                                    React.createElement("label", { className: 'label -uppercase' }, "Description"),
                                    React.createElement("div", { className: 'value' }, description)),
                                React.createElement("div", { className: 'form-group' },
                                    React.createElement("label", { className: 'label -uppercase' }, "Unallocated Amount"),
                                    React.createElement("div", { className: 'value' },
                                        "$",
                                        allocatedAmount.value)),
                                React.createElement("div", { className: 'form-group' },
                                    React.createElement("label", { className: 'label -uppercase -print' }, "Date"),
                                    React.createElement("div", { className: 'value' }, formatDateWithYearAU(selectedDate))))),
                        React.createElement("div", { className: `action-buttons buttons space -border -padding ${stepTwoClass}` },
                            React.createElement("button", { className: 'button -neutral', type: 'button', onClick: () => setCurrentStep(1) }, "Go Back"),
                            React.createElement("input", { type: 'submit', className: 'button -primary -wide', name: 'commit', value: 'Yes, I confirm', "data-disable-with": 'Updating...' }))))))));
};
export default SuspenseModal;
