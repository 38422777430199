import React from 'react';
import ProcessingBadge from './ProcessingBadge';
const LedgerSummary = ({ funding, isDebtorsLedgerRecalculating }) => {
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'ledger-summary' },
        React.createElement("div", { className: 'header' }, "Ledger Summary"),
        React.createElement("table", { className: 'custom-table' },
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Gross Receivables Ledger"),
                    isDebtorsLedgerRecalculating ? (React.createElement(ProcessingBadge, null)) : (React.createElement("td", { className: 'value' }, funding.grossReceivablesLedgerFormatted))),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "LESS: Unselected Debtors"),
                    isDebtorsLedgerRecalculating ? (React.createElement(ProcessingBadge, null)) : (React.createElement("td", { className: 'value' }, funding.unselectedDebtorsFormatted))),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical -right -neutral-900' }, "Selected Ledger"),
                    isDebtorsLedgerRecalculating ? (React.createElement(ProcessingBadge, null)) : (React.createElement("td", { className: 'value' }, funding.selectedLedgerFormatted))),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }),
                    React.createElement("td", { className: 'value' })),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical -neutral-900' }, "Adjustments"),
                    React.createElement("td", { className: 'value' })),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "LESS: Unallocated Payments"),
                    React.createElement("td", { className: 'value' }, funding.unallocatedPaymentsFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "LESS: Unapproved Debtors"),
                    isDebtorsLedgerRecalculating ? (React.createElement(ProcessingBadge, null)) : (React.createElement("td", { className: 'value' }, funding.unapprovedDebtorsFormatted))),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "LESS: Pending Invoices"),
                    isDebtorsLedgerRecalculating ? (React.createElement(ProcessingBadge, null)) : (React.createElement("td", { className: 'value' }, funding.pendingInvoicesFormatted))),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "LESS: Ineligible Adjustments"),
                    isDebtorsLedgerRecalculating ? (React.createElement(ProcessingBadge, null)) : (React.createElement("td", { className: 'value' }, funding.ineligibleAdjustmentsFormatted))),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical -right -neutral-900' }, "Approved Ledger"),
                    isDebtorsLedgerRecalculating ? (React.createElement(ProcessingBadge, null)) : (React.createElement("td", { className: 'value' }, funding.approvedLedgerFormatted)))))));
};
export default LedgerSummary;
