import React, { useState } from 'react';
import Select from 'react-select';
const options = [
    { value: 'PENDING', label: 'UNDER REVIEW' },
    { value: 'APPROVED', label: 'APPROVED' },
    { value: 'UNAPPROVED', label: 'UNAPPROVED' }
];
const EarlypayStatusSelect = ({ customer, updateEarlypayStatusHandler }) => {
    const [selectedOption, setSelectedOption] = useState(options.find(option => option.value === customer.earlypayStatus));
    const handleEarlypayStatusChange = newSelectedOption => {
        const labelLowercase = newSelectedOption.label.toLowerCase();
        const confirmationMessage = `Are you sure you want to change the status to ${labelLowercase} for ${customer.name}?`;
        const userConfirmed = window.confirm(confirmationMessage);
        if (userConfirmed) {
            setSelectedOption(newSelectedOption);
            updateEarlypayStatusHandler(customer.id, newSelectedOption.value);
        }
    };
    return (React.createElement("div", { className: 'dropdown-select' },
        React.createElement(Select, { key: customer.id, options: options, value: selectedOption, className: 'select -w-36', onChange: handleEarlypayStatusChange })));
};
export default EarlypayStatusSelect;
