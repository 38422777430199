import React, { useRef, useState } from 'react';
import Modal from '../shared/Modal';
import AttachmentUploader from '../shared/AttachmentUploader';
import { FileDrop } from 'react-file-drop';
const PopupFileUpload = ({ formAttributes }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [files, setFiles] = useState([]);
    const filePickerRef = useRef(null);
    const handleFileChange = (event) => {
        const currentFileList = event.target.files;
        const currentFiles = [...currentFileList];
        setFiles(currentFiles);
    };
    const handleClearAttachments = (event) => {
        event.preventDefault();
        clearAttachments();
    };
    const clearAttachments = () => {
        if (filePickerRef && filePickerRef.current) {
            filePickerRef.current.value = '';
        }
        setFiles([]);
    };
    const handleAttachmentTrigger = () => {
        if (filePickerRef && filePickerRef.current) {
            filePickerRef.current.click();
        }
    };
    const toggleModal = () => {
        setIsVisible(!isVisible);
    };
    const handleDropChange = (files) => {
        if (files === null)
            return;
        if (filePickerRef.current !== null) {
            filePickerRef.current.files = files;
        }
        const currentFiles = [...files];
        setFiles(currentFiles);
    };
    const { url, csrfToken } = formAttributes;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'custom-icon -add-circle', onClick: toggleModal },
            React.createElement("i", { className: 'icon' }),
            " Upload Files"),
        React.createElement(Modal, { isOpen: isVisible, onClose: toggleModal },
            React.createElement("div", { className: '-admin', "data-testid": 'file-upload' },
                React.createElement("form", { encType: 'multipart/form-data', action: url, acceptCharset: 'UTF-8', method: 'post', className: 'file-upload -admin' },
                    React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
                    React.createElement("div", { className: 'form-group' },
                        React.createElement(FileDrop, { onDrop: files => handleDropChange(files), className: 'filedrop-area' },
                            React.createElement("button", { className: 'custom-button -outline', type: 'button', "data-testid": 'attachment', onClick: handleAttachmentTrigger }, "Browse"),
                            React.createElement("span", { className: 'label' }, "Upload or drag your files here")),
                        React.createElement(AttachmentUploader, { ...{
                                files,
                                handleClearAttachments,
                                handleFileChange
                            }, forwardFilePickerRef: filePickerRef, hideFilePicker: true })),
                    React.createElement("div", { className: 'form-group' },
                        React.createElement("input", { type: 'submit', className: 'custom-button upload -default -narrow', name: 'commit', value: 'Upload', "data-disable-with": 'Uploading...' })))))));
};
export default PopupFileUpload;
