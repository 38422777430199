import React from 'react';
const ToggleInput = ({ attribute, isChecked, labelText, model, readOnly = false, switchOn, switchOff }) => {
    return (React.createElement("div", { className: 'form-group toggle-input', "data-testid": 'toggle-input' },
        React.createElement("label", { className: 'label toggle-switch', "data-testid": `${attribute}-toggle` },
            React.createElement("input", { name: `${model}[${attribute}]`, type: 'hidden', value: '0' }),
            React.createElement("input", { type: 'checkbox', className: 'input form-control', value: '1', checked: isChecked, onChange: isChecked ? switchOff : switchOn, name: `${model}[${attribute}]`, disabled: readOnly, "data-testid": `${attribute}-checkbox` }),
            React.createElement("span", { className: 'track' },
                React.createElement("span", { className: 'peg' })),
            React.createElement("div", { className: 'text' }, labelText))));
};
export default ToggleInput;
