import fetch from 'isomorphic-fetch';
import { buildHeaders } from './shared';
export const enable = async (apiToken, borrowerId, settingsId, name) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/notification_settings/${settingsId}/enable`;
    const body = {
        [name]: true
    };
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'PUT',
            body: JSON.stringify(body)
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.map(error => error.title).join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
export const disable = async (apiToken, borrowerId, settingsId, name) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/notification_settings/${settingsId}/disable`;
    const body = {
        [name]: false
    };
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'PUT',
            body: JSON.stringify(body)
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.map(error => error.title).join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
