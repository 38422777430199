import React from 'react';
import Select from 'react-select';
const DesignatedAddressPanel = ({ path, email, setEmail, csrfToken }) => {
    const debtorOptions = [
        { value: 'all', label: 'All' },
        { value: 'selected', label: 'Selected' },
        { value: 'unselected', label: 'Unselected' }
    ];
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'designated-address-panel' },
        React.createElement("div", { className: 'header' }, "Send all statements to designated address"),
        React.createElement("form", { className: 'form form-pane', method: 'post', action: path },
            React.createElement("div", { className: 'form-group' },
                React.createElement("p", { className: 'paragraph' }, "Send an email containing all debtor statements for this client to the email address below.")),
            React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
            React.createElement("input", { type: 'hidden', name: 'email_type', value: 'designated_address' }),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'field' },
                    React.createElement("div", { className: 'form-group' },
                        React.createElement("label", { className: 'label' }, "Email Address"),
                        React.createElement("input", { type: 'text', className: 'input form-control', value: email, onChange: e => setEmail(e.target.value), name: 'email', "data-testid": 'designated-address-panel-email' })),
                    React.createElement("div", { className: 'form-group' },
                        React.createElement("label", { className: 'label' }, "Debtor Options"),
                        React.createElement(Select, { name: 'debtor_type', options: debtorOptions, defaultValue: debtorOptions[0] })))),
            React.createElement("div", { className: 'button' },
                React.createElement("div", { className: 'action-buttons -send-statements' },
                    React.createElement("input", { type: 'submit', value: 'Send', className: 'button -primary -full-width', "data-disable-with": 'Send' }))))));
};
export default DesignatedAddressPanel;
