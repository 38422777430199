import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import eachMonthOfInterval from 'date-fns/eachMonthOfInterval';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import subMonths from 'date-fns/subMonths';
import toDate from 'date-fns/toDate';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import { formatToTimeZone } from 'date-fns-timezone';
import { utcToZonedTime } from 'date-fns-tz';
import jstz from 'jstz';
export const getTimeAgoFormat = (date, referenceDate = new Date()) => {
    const diff = differenceInCalendarDays(referenceDate, toDate(date));
    if (diff < 1) {
        return 'hh:mm A';
    }
    if (diff >= 1 && diff < 2) {
        return '[Yesterday]';
    }
    if (diff >= 2 && diff < 7) {
        return 'ddd';
    }
    return 'MMM D';
};
export const timeAgo = (date, referenceDate = new Date()) => {
    const formatString = getTimeAgoFormat(date, referenceDate);
    const timeZone = jstz.determine();
    const options = {
        timeZone: timeZone.name()
    };
    return formatToTimeZone(date, formatString, options);
};
export const formatDisplayDateTime = (dateString, timeZone) => {
    const convertedDateString = utcToZonedTime(parseISO(dateString), timeZone);
    const formatString = `dd MMM yyyy 'at' H:mm`;
    return format(convertedDateString, formatString);
};
export const last24Months = (date = new Date()) => {
    const result = eachMonthOfInterval({
        start: subMonths(date, 24),
        end: date
    });
    return result.reverse();
};
export const monthLong = (date = new Date()) => {
    const start = format(startOfMonth(date), `dd MMMM yyyy`);
    const end = format(endOfMonth(date), `dd MMMM yyyy`);
    return start + ' to ' + end;
};
