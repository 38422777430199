import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { showNotifyToast } from '../../utils/notifyToast';
const CopyUrl = ({ url, description, buttonLabel = 'Copy Link', buttonType = 'icon', label }) => {
    const handleCopy = () => {
        showNotifyToast({ text: 'Copied to clipboard.', type: 'success' });
    };
    const isTextButtonType = buttonType === 'text';
    return (React.createElement("div", { className: 'copy-url', "data-testid": 'copy-url' },
        label && (React.createElement("div", { className: 'label', "data-testid": 'label' }, label)),
        React.createElement("div", { className: 'form' },
            React.createElement("input", { type: 'text', readOnly: true, value: url, className: 'input' }),
            React.createElement(CopyToClipboard, { text: url, onCopy: handleCopy }, isTextButtonType ? (React.createElement("button", { className: 'button -text', "data-testid": 'copy-button-text' }, buttonLabel)) : (React.createElement("button", { className: 'button', "data-testid": 'copy-button-icon' },
                React.createElement("i", { className: 'bg-icon icon -document' }))))),
        description && (React.createElement("div", { className: 'description', "data-testid": 'description' }, description))));
};
export default CopyUrl;
