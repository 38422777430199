import React from 'react';
const ReconcileStep = ({ handleNextStep, backLink }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("h3", { className: 'text -medium', "data-testid": 'reconcile-step' }, "Have you reconciled your payments?"),
        React.createElement("h5", { className: 'text' }, "Reconciling payments helps Earlypay provide you with a more accurate funding available amount."),
        React.createElement("div", { className: 'actions action-buttons' },
            React.createElement("a", { href: backLink, className: 'button -tertiary' }, "No, I havent"),
            React.createElement("a", { className: 'button -primary', onClick: handleNextStep }, "Yes, I have"))));
};
export default ReconcileStep;
