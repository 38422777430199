import React, { useState } from 'react';
import IconToggle from '../shared/IconToggle';
import { setUnverified, setVerified } from '../../api/invoice';
import { showNotifyToast } from '../../utils';
const VerificationToggle = ({ authToken, borrowerId, invoice }) => {
    const { verified, id } = invoice;
    const [isOn, setIsOn] = useState(verified);
    const [isToggleable, setIsToggleable] = useState(true);
    const setInvoiceToVerified = async () => {
        setIsToggleable(false);
        try {
            const { data } = await setVerified(authToken, borrowerId, id);
            setIsOn(data.attributes.verified);
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsToggleable(true);
    };
    const setInvoiceToUnverified = async () => {
        setIsToggleable(false);
        try {
            const { data } = await setUnverified(authToken, borrowerId, id);
            setIsOn(data.attributes.verified);
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsToggleable(true);
    };
    return (React.createElement("div", { className: 'verification-toggle', "data-testid": 'verification-toggle' },
        React.createElement(IconToggle, { ...{ isOn, isToggleable }, onIcon: 'check', offIcon: 'close', switchOff: () => setInvoiceToUnverified(), switchOn: () => setInvoiceToVerified() })));
};
export default VerificationToggle;
