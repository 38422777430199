import React, { useState } from 'react';
import classNames from 'classnames';
import Item from './Item';
import useScrollEffect from '../../hooks/useScrollEffect';
const VerticalNavigation = ({ items, activeItemColor, isScrollable = false }) => {
    const [currentScroll, setCurrentScroll] = useState(0);
    const handleScroll = () => {
        setCurrentScroll(window.pageYOffset);
    };
    useScrollEffect(handleScroll);
    const verticalNavigationClass = classNames('vertical-navigation', {
        '-fixed': currentScroll > 144,
        '-scrollable': isScrollable
    });
    return (React.createElement("nav", { className: verticalNavigationClass, "data-testid": 'vertical-navigation' }, items.map((item, i) => {
        return React.createElement(Item, { key: i, item: item, activeColor: activeItemColor });
    })));
};
export default VerticalNavigation;
