import React from 'react';
const Author = ({ actorFullname, currentUserRole, companyPath, companyName }) => {
    if (currentUserRole === 'borrower') {
        return (React.createElement("span", { "data-testid": 'notification-author-borrower' },
            " ",
            actorFullname,
            " "));
    }
    else {
        return (React.createElement("span", { "data-testid": 'notification-author-admin' },
            actorFullname,
            " from ",
            React.createElement("a", { href: companyPath },
                companyName,
                " ")));
    }
};
export default Author;
