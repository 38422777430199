import React, { useState } from 'react';
import IconToggle from '../shared/IconToggle';
import { showNotifyToast } from '../../utils';
import { setProcessedAtBank, unsetProcessedAtBank } from '../../api/drawdown';
const ProcessedAtBankToggle = ({ authToken, drawdown }) => {
    const { processedAtBankOn, id } = drawdown;
    const isProcessed = processedAtBankOn !== null;
    const [isOn, setIsOn] = useState(isProcessed);
    const [isToggleable, setIsToggleable] = useState(true);
    const setDrawdownProcessedAtBankOn = async () => {
        setIsToggleable(false);
        try {
            const { data } = await setProcessedAtBank(authToken, id);
            const isProcessed = data.attributes.processedAtBankOn !== null;
            setIsOn(isProcessed);
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsToggleable(true);
    };
    const setDrawdownProcessedAtBankOff = async () => {
        setIsToggleable(false);
        try {
            const { data } = await unsetProcessedAtBank(authToken, id);
            const isProcessed = data.attributes.processedAtBankOn !== null;
            setIsOn(isProcessed);
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsToggleable(true);
    };
    return (React.createElement("div", { className: 'processed-at-bank-toggle', "data-testid": 'processed-at-bank-toggle' },
        React.createElement(IconToggle, { ...{ isOn, isToggleable }, onIcon: 'check', offIcon: 'close', switchOff: () => setDrawdownProcessedAtBankOff(), switchOn: () => setDrawdownProcessedAtBankOn() })));
};
export default ProcessedAtBankToggle;
