import React from 'react';
const TwoFactorStepThree = ({ linkBack, successIcon }) => {
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'two-factor-step-three' },
        React.createElement("div", { className: 'header' }, "2FA Activated"),
        React.createElement("div", { className: 'content' },
            React.createElement("div", { className: 'icon' },
                React.createElement("img", { className: 'logo', src: successIcon })),
            React.createElement("p", null, "Great job! The next time you log in, we'll send you a 6-digit verification code via SMS which you will have to enter in order to gain access to your account.")),
        React.createElement("div", { className: 'actions custom-action' },
            React.createElement("div", { className: 'action -no-pad' },
                React.createElement("a", { href: linkBack, className: 'custom-button -default -blue' }, "Done")))));
};
export default TwoFactorStepThree;
