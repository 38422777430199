import React, { useEffect, useRef, useState } from 'react';
import { fetchEvents } from '../api/event';
import { removeDuplicates } from '../utils/removeDuplicates';
import ActivitiesContext from '../contexts/ActivitiesContext';
import ActivityFeed from '../components/activity-feed/ActivityFeed';
import BrokerFilters from '../components/activity-feed/BrokerFilters';
import PostInputPanel from '../containers/post-input-panel/PostInputPanel';
const AdminBorrowerActivities = ({ apiToken, currentUserId, brokerId, disablePosting = false, userAvatarUrl }) => {
    const initialActiveFilters = {
        client_invitations: false,
        client_statuses: false,
        commissions_updates: false,
        drawdowns: false
    };
    const [events, setEvents] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [dateFilter, setDateFilter] = useState('');
    const [currentFilters, setCurrentFilters] = useState([]);
    const [activeFilters, setActiveFilters] = useState(initialActiveFilters);
    const dateClearRef = useRef(null);
    const datePickerRef = useRef(null);
    const scope = `admin/brokers/${brokerId}`;
    const context = {
        apiToken,
        feedType: 'broker-scoped-admin'
    };
    const nextFetches = () => {
        if (hasMore === true) {
            handleFetchEvents(page);
            setPage(page + 1);
        }
    };
    const handleFetchEvents = async (currentPage) => {
        try {
            const response = await fetchEvents(apiToken, scope, currentPage, currentFilters, dateFilter);
            if (response.last_page === true) {
                setHasMore(false);
            }
            setEvents(prevEvents => removeDuplicates([...prevEvents, ...response.data]));
            setHasErrors(false);
        }
        catch (err) {
            setHasErrors(true);
        }
        setIsLoading(false);
    };
    const handleFilterToggle = (event) => {
        const isChecked = event.target.checked;
        setActiveFilters({
            ...activeFilters,
            [event.target.name]: isChecked
        });
    };
    const applyFilters = () => {
        const activeFilterKeys = Object.keys(activeFilters);
        const appliedFilters = activeFilterKeys.reduce((acc, key) => {
            if (activeFilters[key] === true) {
                return [...acc, key];
            }
            return acc;
        }, []);
        setCurrentFilters(appliedFilters);
    };
    const clearFilters = () => {
        if (dateClearRef && dateClearRef.current) {
            dateClearRef.current.click();
        }
        setActiveFilters({ ...initialActiveFilters });
        setCurrentFilters([]);
    };
    const handleFeedRefresh = () => {
        clearFilters();
    };
    const handleDateFilterChange = (selectedDates, dateString) => {
        setDateFilter(dateString);
    };
    const flatpickrSettings = {
        wrap: true,
        dateFormat: 'd/m/Y',
        onChange: handleDateFilterChange
    };
    useEffect(() => {
        handleFetchEvents(1);
    }, []);
    useEffect(() => {
        setIsLoading(true);
        setPage(2);
        setEvents([]);
        handleFetchEvents(1);
    }, [currentFilters, dateFilter]);
    return (React.createElement("div", { className: 'activities-container activities -admin -broker-scoped' },
        React.createElement(ActivitiesContext.Provider, { value: context },
            React.createElement("div", { className: 'sidebar' },
                React.createElement(BrokerFilters, { ...{
                        activeFilters,
                        dateFilter,
                        flatpickrSettings,
                        applyFilters,
                        clearFilters,
                        handleFilterToggle
                    }, forwardDateClearRef: dateClearRef, forwardDatePickerRef: datePickerRef })),
            React.createElement("div", { className: 'body' },
                !disablePosting && (React.createElement(PostInputPanel, { ...{ apiToken, scope, userAvatarUrl }, handleBrokerFeedRefresh: handleFeedRefresh })),
                React.createElement(ActivityFeed, { ...{
                        events,
                        isLoading,
                        hasErrors,
                        hasMore,
                        nextFetches,
                        userAvatarUrl,
                        currentUserId
                    } })))));
};
export default AdminBorrowerActivities;
