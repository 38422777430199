import fetch from 'isomorphic-fetch';
import queryString from 'query-string';
export const fetchDebtorGrossLedger = async (apiToken, companyId, direction, page, search, sortBy) => {
    const params = {
        page,
        ...(direction && { sort_direction: direction }),
        ...(search && { search_query: search }),
        ...(sortBy && { sort_by: sortBy })
    };
    const searchParams = queryString.stringify(params);
    const endpoint = `/api/admin/companies/${companyId}/debtor_gross_ledger_analysis?${searchParams}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
