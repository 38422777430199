import React, { useState } from 'react';
import ToggleSwitch from '../shared/ToggleSwitch';
import ProcessingBadge from '../../components/manual-ledger-funding/ProcessingBadge';
const CustomRecourseTerm = ({ defaultRecourseTerm, isCustomRecourseTermProcessing, applyDefaultRecourseTerm, csrfToken, formUrl, customRecourseTerm }) => {
    const [useCustomRecourseTerm, setUseCustomRecourseTerm] = useState(!applyDefaultRecourseTerm);
    return (React.createElement("div", { className: 'custom-panel -setting-pane' },
        React.createElement("div", { className: 'header -space-between' },
            React.createElement("div", null, "Recourse Term"),
            isCustomRecourseTermProcessing && React.createElement(ProcessingBadge, null),
            React.createElement("div", { className: 'buttons' },
                React.createElement("div", { className: 'label' }, "Use Custom Recourse Term"),
                React.createElement(ToggleSwitch, { isToggled: useCustomRecourseTerm, switchOn: () => setUseCustomRecourseTerm(true), switchOff: () => setUseCustomRecourseTerm(false) }))),
        React.createElement("div", { className: 'body' },
            React.createElement("form", { method: 'post', action: formUrl, className: 'form' },
                React.createElement("input", { type: 'hidden', name: '_method', value: 'put' }),
                React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
                React.createElement("input", { type: 'hidden', name: 'apply_default_recourse_term', value: !useCustomRecourseTerm ? 'true' : 'false' }),
                useCustomRecourseTerm ? (React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { htmlFor: 'custom_recourse_term', className: 'label' }, "Custom Recourse Term"),
                    React.createElement("input", { type: 'number', minLength: 1, name: 'custom_recourse_term', defaultValue: customRecourseTerm, className: 'input form-control -w-10 -outline' }))) : (React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { className: 'label' }, "Default Recourse Term"),
                    React.createElement("div", { className: 'label -large' }, defaultRecourseTerm))),
                React.createElement("div", { className: 'action-buttons actions -flexstart' },
                    React.createElement("input", { value: 'Save', type: 'submit', className: 'button -primary', "data-disable-with": 'Saving...' }))))));
};
export default CustomRecourseTerm;
