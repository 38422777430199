import React, { useState, useEffect } from 'react';
import { fetchUploadedLedgerRow } from '../../api/uploaded_ledger';
import Drawer from '../shared/Drawer';
import Loading from '../shared/Loading';
import ReconciliationWarningIndicator from './ReconciliationWarningIndicator';
const CreditNotesDrawer = ({ apiToken, rowType, uploadedLedgerId, companyId, header, totalCreditNotesFormatted, userId, exportPath, displayWarningIcon = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [rows, setRows] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const customerPath = (row) => {
        return `/admin/borrowers/${userId}/customers/${row.customerId}`;
    };
    useEffect(() => {
        const fetchRows = async () => {
            const rows = await fetchUploadedLedgerRow(apiToken, companyId, uploadedLedgerId, rowType);
            setIsFetching(false);
            setRows(rows);
        };
        fetchRows();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'shadow-reconciliation-row -sub -clickable', onClick: () => setIsOpen(true) },
            React.createElement("div", { className: 'label' },
                header,
                " ",
                React.createElement("div", { className: 'custom-icon -clickable -open-outline' })),
            React.createElement("div", { className: 'value' },
                React.createElement(ReconciliationWarningIndicator, { hidden: !displayWarningIcon }),
                totalCreditNotesFormatted)),
        React.createElement(Drawer, { isOpen: isOpen, onClose: () => setIsOpen(false) },
            React.createElement("div", { className: 'cash-received-drawer' },
                React.createElement("div", { className: 'actions' },
                    React.createElement("button", { className: 'back', onClick: () => setIsOpen(false) }, "Go Back")),
                React.createElement("div", { className: 'header' },
                    header,
                    React.createElement("div", { className: 'action-buttons' },
                        React.createElement("a", { className: 'button -secondary', href: exportPath }, "Export"))),
                React.createElement("div", { className: 'transactions' },
                    isFetching && React.createElement(Loading, null),
                    !isFetching &&
                        rows.map(row => (React.createElement("div", { className: 'manual-ledger-payment-card', key: row.creditNoteId },
                            React.createElement("div", { className: 'details' },
                                React.createElement("div", { className: 'text' }, row.number),
                                React.createElement("div", { className: 'text' },
                                    React.createElement("a", { href: customerPath(row) }, row.customerName))),
                            React.createElement("div", { className: 'details' },
                                React.createElement("div", { className: 'amount' },
                                    row.remainingCreditFormatted,
                                    ' ',
                                    row.reinstatedAt && (React.createElement("div", { className: 'risk-badge -high' }, "Reinstated"))),
                                React.createElement("div", { className: 'date' },
                                    " ",
                                    row.issueDate,
                                    " "))))))))));
};
export default CreditNotesDrawer;
