import React from 'react';
import InvoiceRow from './InvoiceRow';
import PaginationBar from '../shared/PaginationBar';
import SortableTabs from '../table-headers/SortableTabs';
import FilterDropdown from './FilterDropdown';
import TableSearchBar from './TableSearchBar';
import HeaderButtons from './HeaderButtons';
import ApproveInvoice from './approve-tab/ApproveInvoice';
import { formatPrice } from '../../utils/formatPrice';
const InvoicesTable = ({ apiToken, borrowerId, borrowerInvoicesPath, closeModalForApprove, closeModalForSingleApprove, closeModalForUnapprove, closeModalForUnverify, closeModalForVerify, computedPrice, csrfToken, currentPage, customersPath, disapprovalReasonsOptions, filterDropdown, filterInvoices, handleAllCheckboxChange, handleApprove, handleBulkApproveInvoices, handleBulkUnapproveInvoices, handleBulkUnverifyInvoices, handleBulkVerifyInvoices, handleInvoiceId, handlePageClick, handleSearchQueryChange, handleSubmitSingleApprove, handleUnapprove, handleUnverify, handleVerify, headerFields, invoicesData, isLoading, isModalOpenForApprove, isModalOpenForSingleApprove, isModalOpenForUnapprove, isModalOpenForUnverify, isModalOpenForVerify, isSelected, isXeroUser, openModalForApprove, openModalForUnapprove, openModalForUnverify, openModalForVerify, pageSize, searchPlaceholder, selectedFilter, selectedFilterText, selectedIdString, selectedInvoice, selectedInvoiceIds, selectedInvoices, setInvoices, setSelectedInvoiceIds, sortBy, sortDirection, sortInvoices, totalItems, totalPages }) => {
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'invoices-table' },
        React.createElement("div", { className: 'header -space-between -compute' },
            React.createElement(TableSearchBar, { ...{ handleSearchQueryChange, searchPlaceholder } }),
            computedPrice > 0 ? (React.createElement("div", { className: 'amount', "data-testid": 'compute-selected' },
                "- Selected Total Amount - ",
                formatPrice(computedPrice / 100))) : (''),
            React.createElement(HeaderButtons, { ...{
                    csrfToken,
                    apiToken,
                    borrowerId,
                    invoicesData,
                    selectedInvoiceIds,
                    setInvoices,
                    setSelectedInvoiceIds,
                    selectedInvoices,
                    handleBulkApproveInvoices,
                    handleBulkUnapproveInvoices,
                    handleBulkVerifyInvoices,
                    handleBulkUnverifyInvoices,
                    openModalForVerify,
                    closeModalForVerify,
                    isModalOpenForVerify,
                    openModalForUnverify,
                    closeModalForUnverify,
                    isModalOpenForUnverify,
                    openModalForUnapprove,
                    closeModalForUnapprove,
                    isModalOpenForUnapprove,
                    openModalForApprove,
                    closeModalForApprove,
                    isModalOpenForApprove,
                    handleInvoiceId
                }, selectedIdString: selectedIdString(), disapprovalReasonsOptions: disapprovalReasonsOptions }),
            React.createElement(ApproveInvoice, { ...{
                    csrfToken,
                    borrowerId,
                    apiToken,
                    selectedInvoice,
                    handleSubmitSingleApprove,
                    disapprovalReasonsOptions
                }, isModalOpenForApprove: isModalOpenForSingleApprove, closeModalForApprove: closeModalForSingleApprove }),
            React.createElement(FilterDropdown, { filterDropdown: filterDropdown, selectedFilter: selectedFilter, selectedFilterText: selectedFilterText, filterInvoices: filterInvoices })),
        isLoading ? (React.createElement("table", { className: 'custom-table' },
            React.createElement("tbody", { "data-testid": 'loading' },
                React.createElement("tr", null,
                    React.createElement("td", { className: 'cell', colSpan: 12 },
                        React.createElement("div", { className: 'loading-container -table' },
                            React.createElement("div", { className: 'loading-spinner' }))))))) : (React.createElement("table", { className: 'custom-table' },
            React.createElement(SortableTabs, { isLoading: isLoading, headerFields: headerFields, sortBy: sortBy, sortDirection: sortDirection, sortItems: sortInvoices, checkboxOnChange: handleAllCheckboxChange }),
            React.createElement("tbody", null, invoicesData.length !== 0 ? (invoicesData.map(t => (React.createElement(InvoiceRow, { invoice: t, selected: isSelected(t.id), handleInvoiceId: handleInvoiceId, key: t.id, isXeroUser: isXeroUser, borrowerId: borrowerId, disapprovalReasonsOptions: disapprovalReasonsOptions, apiToken: apiToken, borrowerInvoicesPath: borrowerInvoicesPath, customersPath: customersPath, handleApprove: handleApprove, handleUnapprove: handleUnapprove, handleVerify: handleVerify, handleUnverify: handleUnverify })))) : (React.createElement("tr", { "data-testid": 'no-record' },
                React.createElement("td", { className: 'placeholder', colSpan: 12 }, "There are no transactions to display")))))),
        totalItems > pageSize && (React.createElement(PaginationBar, { ...{
                currentPage,
                pageSize,
                totalPages,
                totalItems,
                handlePageClick
            }, resource: 'invoices' }))));
};
export default InvoicesTable;
