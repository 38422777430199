import fetch from 'isomorphic-fetch';
export const fetchRiskLog = (apiToken, riskActivityId, scope) => {
    const endpoint = `/api/${scope}/risk_activities/${riskActivityId}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const editRiskActivity = (apiToken, riskActivityId, body, scope) => {
    const endpoint = `/api/${scope}/risk_activities/${riskActivityId}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const resolveRiskActivity = (apiToken, riskActivityId, scope) => {
    const endpoint = `/api/${scope}/risk_activities/${riskActivityId}/resolve`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const archiveRiskActivity = (apiToken, riskActivityId, scope) => {
    const endpoint = `/api/${scope}/risk_activities/${riskActivityId}/archive`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
