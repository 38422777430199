import React, { useLayoutEffect, useRef, useState } from 'react';
import { compileVariantClasses } from '../../utils';
import CommentItem from './CommentItem';
const CommentFeed = ({ apiToken, commentableId, commentableType, comments, hasFetchErrors, isLoading, isLoadingMoreComments, scope, itemName, currentUserId, itemVariantClasses, toggleMenuEnabled = false, variantClasses, removeComment, handleFeedRefresh, hasMoreComments, handleLoadMoreComments }) => {
    const loadedFeedRef = useRef(null);
    const [feedHeight, setFeedHeight] = useState(80);
    const itemNameText = itemName || 'comments';
    const placeholder = `You haven't added any ${itemNameText}s yet`;
    useLayoutEffect(() => {
        if (loadedFeedRef && loadedFeedRef.current) {
            setFeedHeight(loadedFeedRef.current.offsetHeight + 80);
        }
    }, [isLoading]);
    if (isLoading) {
        return (React.createElement("div", { className: variantClasses
                ? compileVariantClasses('comment-feed feed -loading', variantClasses)
                : 'comment-feed feed -loading', "data-testid": 'loading', style: { height: feedHeight } },
            React.createElement("div", { className: 'loading-container -comment-feed' },
                React.createElement("div", { className: 'loading-spinner spinner' }))));
    }
    if (hasFetchErrors) {
        return (React.createElement("div", { className: variantClasses
                ? compileVariantClasses('comment-feed feed -error', variantClasses)
                : 'comment-feed feed -error', "data-testid": 'error' },
            React.createElement("div", { className: 'placeholder-pane -comment-feed-error' },
                React.createElement("div", { className: 'title' }, "Uh oh!"),
                React.createElement("div", { className: 'body' }, "Could not fetch comments."),
                React.createElement("button", { className: 'custom-button button -blue -refresh', type: 'button', onClick: handleFeedRefresh }, "Refresh"))));
    }
    if (comments.data.length === 0) {
        return (React.createElement("div", { className: variantClasses
                ? compileVariantClasses('comment-feed feed -empty', variantClasses)
                : 'comment-feed feed -empty', "data-testid": 'empty' }, placeholder));
    }
    return (React.createElement("div", { className: variantClasses
            ? compileVariantClasses('comment-feed feed -loaded', variantClasses)
            : 'comment-feed feed -loaded', "data-testid": 'loaded', ref: loadedFeedRef },
        comments.data.map(comment => (React.createElement(CommentItem, { ...{
                apiToken,
                currentUserId,
                commentableId,
                commentableType,
                scope,
                toggleMenuEnabled,
                removeComment
            }, comment: comment.attributes, variantClasses: itemVariantClasses, key: comment.id }))),
        isLoadingMoreComments && (React.createElement("div", { className: 'loading-container -comment-feed' },
            React.createElement("div", { className: 'loading-spinner spinner' }))),
        hasMoreComments && (React.createElement("div", { className: 'action-buttons -center -mt-3' },
            React.createElement("button", { disabled: isLoading, className: `button -secondary ${isLoadingMoreComments ? '-disabled' : ''}`, onClick: handleLoadMoreComments }, "Load older comments")))));
};
export default CommentFeed;
