import React from 'react';
import classNames from 'classnames';
const FilterListItem = ({ admin, selected, handleAdminId, disable = false }) => {
    const rowClass = classNames({
        selected: selected
    });
    return (React.createElement("li", { className: rowClass },
        React.createElement("label", null,
            React.createElement("img", { className: 'image', src: admin.avatarUrl }),
            React.createElement("span", null, `${admin.firstName} ${admin.lastName}`),
            React.createElement("input", { type: 'checkbox', checked: selected, onChange: () => handleAdminId(admin.id), disabled: disable }))));
};
export default FilterListItem;
