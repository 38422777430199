import React, { useState } from 'react';
import classNames from 'classnames';
const Panel = ({ isOpened, urlPath }) => {
    const urlPathArray = urlPath.split('?');
    const baseCsvUrl = '/admin/trade_loan_repayments/export.csv?' + urlPathArray[1];
    const baseExcelUrl = '/admin/trade_loan_repayments/export.xlsx?' + urlPathArray[1];
    const [downloadUrl, setDownloadUrl] = useState(urlPath);
    const [selectedDownload, setSelectedDownload] = useState('');
    const panelClass = classNames('panel', {
        '-active': isOpened
    });
    const buttonCsvClass = classNames('radio', {
        '-selected': selectedDownload === 'csv'
    });
    const buttonExcelClass = classNames('radio', {
        '-selected': selectedDownload === 'xlsx'
    });
    const handleSelectedReportChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue === 'xlsx') {
            setDownloadUrl(baseExcelUrl);
        }
        else {
            setDownloadUrl(baseCsvUrl);
        }
        setSelectedDownload(selectedValue);
    };
    return (React.createElement("div", { className: panelClass, "data-testid": 'panel' },
        React.createElement("div", { className: 'label -uppercase  -small' }, "File Type"),
        React.createElement("div", { className: buttonCsvClass },
            React.createElement("input", { type: 'radio', id: 'csv_button', value: 'csv', className: 'input', name: 'selected_report_download', onChange: handleSelectedReportChange }),
            React.createElement("label", { htmlFor: 'csv_button', className: 'label' }, "Download as .csv")),
        React.createElement("div", { className: buttonExcelClass },
            React.createElement("input", { type: 'radio', id: 'excel_button', value: 'xlsx', className: 'input', name: 'selected_report_download', onChange: handleSelectedReportChange }),
            React.createElement("label", { htmlFor: 'excel_button', className: 'label' }, "Download as .xlsx")),
        React.createElement("div", { className: 'action-buttons flex-grid ' },
            React.createElement("a", { className: 'button -primary -loose -loose-width', href: downloadUrl }, "Download List"))));
};
export default Panel;
