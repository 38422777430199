import React, { useEffect, useState } from 'react';
import ToggleTextField from '../../components/toggle-text-field/ToggleTextField';
const CompanyProfileDashboard = ({ apiToken, borrowerId, scope, fetchBorrowerCompany, showNotifyToast, updateBorrowerCompanyBlurb }) => {
    const [blurbInput, setBlurbInput] = useState('');
    const [blurbIsEditable, setBlurbIsEditable] = useState(false);
    const [company, setCompany] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    useEffect(() => {
        if (!isSubmitting) {
            fetchCompanyDetails();
        }
    }, [isSubmitting]);
    const fetchCompanyDetails = async () => {
        setIsLoading(true);
        const { data } = await fetchBorrowerCompany(apiToken, borrowerId, scope);
        setCompany(data.attributes);
        setBlurbInput(data.attributes.adminBlurb);
        setIsLoading(false);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        const blurb = {
            company: {
                blurb: blurbInput
            }
        };
        await updateBorrowerCompanyBlurb(apiToken, blurb, borrowerId, scope);
        setIsSubmitting(false);
        setBlurbIsEditable(false);
        showNotifyToast({ text: 'Blurb updated successfully.', type: 'success' });
    };
    const handleTextFieldChange = (event) => {
        event.preventDefault();
        setBlurbInput(event.currentTarget.value);
    };
    return (React.createElement("div", { className: 'company-profile-dashboard', "data-testid": 'company-profile-dashboard' },
        React.createElement(ToggleTextField, { ...{ isLoading, isSubmitting, handleSubmit, handleTextFieldChange }, defaultValue: company.adminBlurb ? company.adminBlurb : '', placeholder: 'Add a description...', isEditable: blurbIsEditable, setIsEditable: setBlurbIsEditable })));
};
export default CompanyProfileDashboard;
