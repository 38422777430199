import React from 'react';
import AccordionPane from '../shared/AccordionPane';
const AccordionContainer = () => (React.createElement("div", { className: 'accordion-container', "data-testid": 'accordion-container' },
    React.createElement(AccordionPane, { title: 'What is Earlypay?', type: 'how-it-works' },
        React.createElement("p", null, "Earlypay is a market leader in helping you borrow against your customer invoices in the easiest possible way. Our smart integration with your cloud accounting software updates your available funds as invoices are raised and paid and you can access funds with a click of a button."),
        React.createElement("p", null, "By posting your transaction information into your accounting software, reconciling customer invoices and loan repayments to your bank feeds is a breeze."),
        React.createElement("p", null, "All this adds up to less time and effort managing your finance and more time to focus on running your business.")),
    React.createElement(AccordionPane, { title: 'Is Earlypay for me?', type: 'how-it-works' },
        React.createElement("p", null, "To get started with Earlypay you will need:"),
        React.createElement("br", null),
        React.createElement("ul", { className: 'check-list list -display-only' },
            React.createElement("li", { className: 'item -checked' },
                React.createElement("p", { className: 'icon' }),
                React.createElement("p", { className: 'description' }, " A valid ACN or ABN ")),
            React.createElement("li", { className: 'item -checked' },
                React.createElement("p", { className: 'icon' }),
                React.createElement("p", { className: 'description' }, " Invoices with other strong businesses ")),
            React.createElement("li", { className: 'item -checked' },
                React.createElement("p", { className: 'icon' }),
                React.createElement("p", { className: 'description' }, "Invoices for goods or services that have been delivered")),
            React.createElement("li", { className: 'item -checked' },
                React.createElement("p", { className: 'icon' }),
                React.createElement("p", { className: 'description' }, "You use Xero, MYOB AccountRight or Quickbooks"))),
        React.createElement("br", null),
        React.createElement("p", null, "We work with businesses across a wide range of business areas including media and marketing, consulting services, recruitment and labour hire, manufacturers, wholesalers and transport companies. Construction is generally an area we won\u2019t be able to help with.")),
    React.createElement(AccordionPane, { title: 'How much can I borrow?', type: 'how-it-works' },
        React.createElement("p", null, "The amount you can borrow is linked to the value of eligible invoices. Eligible invoices are invoices that are less than 90 days past the issue date, for work that has been completed and are with other strong Australian businesses. Earlypay can advance up to 80% of the value of eligible invoices.")),
    React.createElement(AccordionPane, { title: 'How much does it cost?', type: 'how-it-works' },
        React.createElement("p", null, "Earlypay\u2019s pricing structure is simple, easy to understand and you only pay for what you use. We have only two charges: a fee when you draw funds and interest charged on your outstanding loan balance."),
        React.createElement("p", null, "There are a range of factors that affect the cost of borrowing from Earlypay including your industry, time in business and the quality and diversity of your customers. Generally though, the cost of borrowing from Earlypay is amongst the lowest of the invoice finance lenders (especially once the fees and charges of our competitors are included). And then there\u2019s the time and money you will save in Admin with Earlypay.")),
    React.createElement(AccordionPane, { title: 'What information will Earlypay need?', type: 'how-it-works' },
        React.createElement("p", null, "Earlypay will access your financial statements, accounts receivable ledger and payable information through Xero. We will often also need information such as bank statements, tax statements, trust deeds and identification documents of directors.")),
    React.createElement(AccordionPane, { title: 'How do I drawdown funds?', type: 'how-it-works' },
        React.createElement("p", null, "Invoices in the Earlypay platform are updated from Xero as they are raised and paid so your accounts receivables ledger is always up to date."),
        React.createElement("p", null, "In the Earlypay platform, you can then select the Customers and Invoices that you would like to borrow against. Once approved by Earlypay, these become Eligible Invoices that are used to draw funds against and Earlypay typically can advance up to 80% of the face value of the invoices."),
        React.createElement("p", null, "Once the Eligible Invoices are approved by Earlypay, it\u2019s simply a matter of drawing down funds on the Earlypay platform and you will receive the money in your account the same day."),
        React.createElement("p", null, "Details of loan drawdowns and drawdown fees are also posted into Xero for easy reconciliation against your bank feeds, keeping your loan balance and interest expense always up to date.")),
    React.createElement(AccordionPane, { title: 'How do invoice payments work?', type: 'how-it-works' },
        React.createElement("p", null, "After you are approved, Earlypay will set up a new Collections Account where your customers can pay their invoices and bank feeds for this account will be connected to your Xero."),
        React.createElement("p", null, "When customers pay their invoices into the Collections Account, you will see a Receive Money transaction in Xero that can be reconciled against the relevant invoices."),
        React.createElement("p", null, "Every business day, the customer payments in the Collections Account are used to pay interest and principal off the Earlypay loan. If the loan balance is zero, funds will be transferred to the regular operating bank account of your business."),
        React.createElement("p", null, "Interest, principal repayments and transfer amounts will be posted by Earlypay to your Xero and they will automatically match your Spend Money bank transactions so reconciliation is a simple, one-click process."),
        React.createElement("p", null, "As we lend against Eligible Invoices, it\u2019s important that customers make payments into the Collections Account and if any payments are misdirected, they will need to be immediately transferred to that account.")),
    React.createElement(AccordionPane, { title: 'What security does Earlypay take?', type: 'how-it-works' },
        React.createElement("p", null, "Earlypay uses your accounts receivable ledger as the primary security for your loan, not real estate. Like most Invoice Finance providers, Earlypay will register security interests relating to the accounts receivables and a general charge over your business and typically asks for personal guarantees from Directors.")),
    React.createElement(AccordionPane, { title: 'How can I monitor my invoices in the Earlypay platform?', type: 'how-it-works' },
        React.createElement("p", null, "Your invoice details feed from Xero to the Earlypay platform and are updated when the status in Xero changes. The Earlypay platform displays invoices in an interactive Aged Debtors report that lets you monitor the ledger both at customer and invoice levels and add notes to help with receivables management."))));
export default AccordionContainer;
