import React, { useState, useEffect, useRef } from 'react';
import DrawdownCheckboxTable from '../../components/drawdowns-table/DrawdownCheckboxTable';
import { showNotifyToast } from '../../utils/notifyToast';
import { fetchDrawdownsRegular, setProcessedAtBank } from '../../api/drawdown';
const DrawdownRegularDashboard = ({ apiToken, tableName, path, abaPath }) => {
    const allCheckboxRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const [drawdownArray, setDrawdownArray] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [disableBulkABA, setDisableBulkABA] = useState(false);
    const [warningBulkABA, setWarningBulkABA] = useState(false);
    const [selectedDrawdownIds, setSelectedDrawdownIds] = useState([]);
    const [selectedANZDrawdownIds, setSelectedANZDrawdownIds] = useState([]);
    const [selectedDrawdown, setSelectedDrawdown] = useState([]);
    const fetchDrawdownsData = async () => {
        setIsLoading(true);
        try {
            const { data } = await fetchDrawdownsRegular(apiToken, currentPage);
            const { page, totalItems, totalPages, pageSize } = data.attributes;
            setDrawdownArray(page);
            setTotalPages(totalPages);
            setPageSize(pageSize);
            setTotalItems(totalItems);
            setIsLoading(false);
        }
        catch (err) {
            const error = err;
            showNotifyToast({
                text: error.message,
                type: 'error'
            });
        }
    };
    const processedDrawdown = async (id) => {
        try {
            await setProcessedAtBank(apiToken, id);
            showNotifyToast({
                text: 'Drawdown successfully processed!',
                type: 'success'
            });
            fetchDrawdownsData();
        }
        catch (err) {
            const error = err;
            showNotifyToast({
                text: error.message,
                type: 'error'
            });
        }
    };
    const sortDrawdown = (field, defaultSort) => {
        setSortBy(field);
        if (sortBy === field && sortDirection === 'asc') {
            setSortDirection('desc');
        }
        else if (sortBy === field && sortDirection === 'desc') {
            setSortDirection('asc');
        }
        else {
            setSortDirection(defaultSort || 'asc');
        }
    };
    useEffect(() => {
        fetchDrawdownsData();
    }, [currentPage]);
    const handlePageClick = data => {
        setCurrentPage(data.selected + 1);
        setSelectedDrawdownIds([]);
        setSelectedDrawdown([]);
    };
    const isSelected = drawdownId => {
        return selectedDrawdownIds.includes(drawdownId);
    };
    const handleDrawdownId = (drawdownId) => {
        const newIds = [...selectedDrawdownIds];
        if (isSelected(drawdownId)) {
            newIds.splice(newIds.indexOf(drawdownId), 1);
        }
        else {
            newIds.push(drawdownId);
        }
        setSelectedDrawdownIds(newIds);
        const isAllSelected = newIds.length === drawdownArray.length;
        const filterDrawdown = drawdownArray.filter(drawdown => newIds.includes(drawdown.id));
        const filterANZ = filterDrawdown
            .filter(item => item.funder === 'ANZ')
            .map(item => item.id);
        const warningBulk = filterDrawdown.filter(item => item.funder !== 'ANZ').length > 0;
        const disableBulk = filterANZ.length === 0;
        setDisableBulkABA(disableBulk);
        setWarningBulkABA(warningBulk);
        setSelectedDrawdown(filterDrawdown);
        setSelectedANZDrawdownIds(filterANZ);
        toggleAllCheckbox(isAllSelected);
    };
    const toggleAllCheckbox = (checked) => {
        if (allCheckboxRef && allCheckboxRef.current) {
            allCheckboxRef.current.checked = checked;
        }
    };
    const handleAllCheckboxChange = (e) => {
        if (e.target.checked) {
            const filterANZ = drawdownArray
                .filter(item => item.funder === 'ANZ')
                .map(item => item.id);
            const arrayANZ = drawdownArray.filter(item => item.funder === 'ANZ');
            const disableBulk = filterANZ.length === 0;
            setDisableBulkABA(disableBulk);
            setWarningBulkABA(false);
            setSelectedDrawdownIds(filterANZ);
            setSelectedANZDrawdownIds(filterANZ);
            setSelectedDrawdown(arrayANZ);
        }
        else {
            setSelectedDrawdownIds([]);
            setSelectedDrawdown([]);
        }
    };
    const toggleModal = () => {
        isVisible ? setIsVisible(false) : setIsVisible(true);
    };
    const handleRefresh = () => {
        toggleModal();
        fetchDrawdownsData();
        setSelectedDrawdownIds([]);
        setSelectedDrawdown([]);
    };
    return (React.createElement(DrawdownCheckboxTable, { ...{
            tableName,
            sortDrawdown,
            drawdownArray,
            totalPages,
            pageSize,
            totalItems,
            isLoading,
            handlePageClick,
            currentPage,
            path,
            processedDrawdown,
            fetchDrawdownsData,
            selectedDrawdownIds,
            selectedDrawdown,
            handleDrawdownId,
            setSelectedDrawdownIds,
            selectedANZDrawdownIds,
            setSelectedDrawdown,
            isSelected,
            disableBulkABA,
            warningBulkABA,
            toggleAllCheckbox,
            handleAllCheckboxChange,
            allCheckboxRef,
            isVisible,
            toggleModal,
            handleRefresh,
            abaPath
        } }));
};
export default DrawdownRegularDashboard;
