import classNames from 'classnames';
import React from 'react';
import { humanizeString } from '../../utils';
const DrawdownType = ({ type, destinationAccountName }) => {
    const isRtgsDrawdown = type === 'rtgs_drawdown';
    const isThirdPartyPayments = type === 'third_party_payments';
    const isRegularDrawdown = type === 'regular_drawdown';
    const isEFPayment = type === 'ef_payment';
    const isTradePayment = type === 'trade_payment';
    const isPlatformEntry = type === 'platform_entry';
    const isOtherDrawdown = type === 'other_drawdown';
    const drawdownClass = classNames('drawdown-type', {
        '-rtgs-drawdown': isRtgsDrawdown,
        '-third-party-payments': isThirdPartyPayments,
        '-regular-drawdown': isRegularDrawdown,
        '-other-drawdown': isOtherDrawdown,
        '-ef-payment': isEFPayment,
        '-trade-payment': isTradePayment,
        '-platform-entry': isPlatformEntry
    });
    if (type) {
        return (React.createElement("div", { className: drawdownClass, "aria-label": 'drawdown type', "data-testid": 'drawdown-type' }, type !== 'other_drawdown'
            ? humanizeString(type)
            : destinationAccountName));
    }
    return null;
};
export default DrawdownType;
