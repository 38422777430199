import React from 'react';
import NumberFormat from 'react-number-format';
import { formatDate } from '../../utils/formatDate';
const CustomTooltip = ({ active, label, payload }) => {
    const shortDateWithYear = { month: 'short', day: 'numeric', year: 'numeric' };
    if (active && payload) {
        return (React.createElement("div", { className: 'recharts-tooltip', "data-testid": 'custom-tooltip' },
            React.createElement("div", { className: 'label' }, formatDate(label, shortDateWithYear)),
            React.createElement(NumberFormat, { renderText: value => React.createElement("div", { className: 'amount' }, value), displayType: 'text', value: payload[0].value, prefix: '$', thousandSeparator: true })));
    }
    return null;
};
export default CustomTooltip;
