import React, { useState } from 'react';
import Modal from '../../components/shared/Modal';
const InvoiceDisapprovalModal = ({ disapprovalReasons }) => {
    const [isVisible, setIsVisible] = useState(false);
    const toggleModal = () => {
        setIsVisible(!isVisible);
    };
    const inlineFlex = {
        display: 'inline-flex'
    };
    return (React.createElement("div", { style: inlineFlex },
        disapprovalReasons && disapprovalReasons.length > 0 && (React.createElement("button", { className: 'custom-button -outline -loose', onClick: () => toggleModal() }, "View Disapproval History")),
        React.createElement(Modal, { isOpen: isVisible, onClose: toggleModal, contentSize: 'lg' },
            React.createElement("div", { className: 'custom-panel -no-margin' },
                React.createElement("div", { className: 'header -space-between' },
                    React.createElement("span", null, "Disapproval Reasons"),
                    React.createElement("button", { className: 'icon-button close -cancel', type: 'button', onClick: toggleModal, "data-testid": 'close-button' },
                        React.createElement("i", { className: 'icon' }))),
                React.createElement("div", { className: 'content' },
                    React.createElement("table", { className: 'custom-table' },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", { className: 'cell' }, "Name"),
                                React.createElement("th", { className: 'cell' }, "Disapproved By"),
                                React.createElement("th", { className: 'cell' }, "Created at"),
                                React.createElement("th", { className: 'cell' }, "Updated at"),
                                React.createElement("th", { className: 'cell' }, "Status"))),
                        React.createElement("tbody", null, disapprovalReasons.map(data => {
                            return (React.createElement("tr", { key: data.id },
                                React.createElement("td", { className: 'cell' }, data.reason),
                                React.createElement("td", { className: 'cell' }, data.createdBy),
                                React.createElement("td", { className: 'cell' }, data.createdAt),
                                React.createElement("td", { className: 'cell' }, data.updatedAt),
                                React.createElement("td", { className: 'cell' },
                                    React.createElement("span", { className: `status-box ${data.status === 'Active' ? '-safe' : '-high'}` }, data.status))));
                        }))))))));
};
export default InvoiceDisapprovalModal;
