import classNames from 'classnames';
import React, { useState, useRef, Fragment } from 'react';
import useOutsideClick from '../../hooks/useOutsideClick';
import FormButton from './FormButton';
import Link from './Link';
import Modal from '../shared/Modal';
const ToggleMenu = ({ links, csrfToken, container, modals, icon = 'settings' }) => {
    const [isShown, setIsShown] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const menuRef = useRef(null);
    const handleClick = () => {
        isShown ? setIsShown(false) : setIsShown(true);
    };
    const toggleModal = () => {
        isVisible ? setIsVisible(false) : setIsVisible(true);
        setIsShown(false);
    };
    useOutsideClick(menuRef, () => {
        if (isShown) {
            setIsShown(false);
        }
    });
    const buttonClass = classNames('button', {
        '-active': isShown,
        '-ellipsis': icon === 'ellipsis',
        '-ellipsis-white': icon === 'ellipsis-white',
        '-settings': icon === 'settings'
    });
    const containerClass = classNames('toggle-menu', {
        '-widget': container === 'widget'
    });
    const listClass = classNames('list', 'toggle-list', {
        '-show': isShown
    });
    return (React.createElement(Fragment, null,
        modals && !modals.isDisabled && (React.createElement(Modal, { isOpen: isVisible, onClose: toggleModal },
            React.createElement("div", { className: 'custom-panel -modal', role: 'dialog', "aria-modal": 'true' },
                React.createElement("div", { className: 'header' }, modals.headerText),
                React.createElement("div", { className: 'content' },
                    React.createElement("div", { className: 'section' },
                        !!modals.warningText && (React.createElement("div", { className: 'description' },
                            React.createElement("span", { className: 'flag' }, "Warning!"),
                            " ",
                            modals.warningText)),
                        React.createElement("div", { className: 'description' }, modals.contents))),
                React.createElement("div", { className: 'footer' },
                    React.createElement("div", { className: 'action-buttons buttons space' },
                        React.createElement("button", { className: 'button -reset -transparent', onClick: toggleModal }, "Cancel"),
                        React.createElement("a", { className: 'button -primary', "data-method": modals.method, href: modals.path, "data-testid": 'modal-submit' }, (modals.method === 'delete' && 'Yes, Delete') || 'Continue')))))),
        React.createElement("div", { ref: menuRef, className: containerClass, "data-testid": 'toggle-menu' },
            React.createElement("div", { className: buttonClass, onClick: handleClick }),
            React.createElement("div", { className: listClass },
                links.map((link, i) => {
                    return link.linkType && link.linkType === 'button' ? (!link.isDisabled && (React.createElement(FormButton, { ...link, csrfToken: csrfToken, key: i }))) : (React.createElement(Link, { key: i, ...link }));
                }),
                modals
                    ? !modals.isDisabled && (React.createElement("a", { className: `button ${modals.icon}`, onClick: toggleModal }, modals.title))
                    : null))));
};
export default ToggleMenu;
