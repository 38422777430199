import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import CustomFlatpickr from '../shared/CustomFlatpickr';
import RiskTeamSelect from './RiskTeamSelect';
import ErrorList from '../shared/ErrorList';
const ProvisionForm = ({ csrfToken, formUrl, title, modelName, riskTeam, errors }) => {
    const [selectedDate, setSelectedDate] = useState('');
    const dateClearRef = useRef(null);
    const datePickerRef = useRef(null);
    const [provisionAmount, setProvisionAmount] = useState({
        floatValue: 0,
        value: '0',
        formattedValue: ''
    });
    const handleSelectedDateChange = (selectedDates, dateString) => {
        setSelectedDate(dateString);
    };
    const flatpickrSettings = {
        wrap: true,
        altInput: true,
        altFormat: 'd M Y',
        dateFormat: 'Y-m-d',
        defaultDate: selectedDate,
        onChange: handleSelectedDateChange
    };
    const handleProvisionChange = (values) => {
        setProvisionAmount(values);
    };
    const hasDateError = errors.date !== undefined;
    const dateLabelClass = classNames('label -required', {
        '-error': errors.date
    });
    const provisionLabelClass = classNames('label -required', {
        '-error': errors.provisionAmount
    });
    const provisionInputClass = classNames('input form-control', {
        '-error': errors.provisionAmount
    });
    return (React.createElement("form", { className: 'form', action: formUrl, acceptCharset: 'UTF-8', method: 'post' },
        React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: dateLabelClass }, "Date"),
            React.createElement(CustomFlatpickr, { ...{ selectedDate, flatpickrSettings }, forwardDatePickerRef: datePickerRef, forwardDateClearRef: dateClearRef, hasError: hasDateError, clearButtonText: 'Clear Date', name: `${modelName}[date]`, pickerBackground: 'neutral-20', placeholderText: ' ' }),
            React.createElement(ErrorList, { errors: errors, field: 'date' })),
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: 'label' }, "Notes"),
            React.createElement("textarea", { className: 'input form-control -textarea', name: `${modelName}[notes]`, defaultValue: '' })),
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: provisionLabelClass }, `${title} Amount`),
            React.createElement(NumberFormat, { thousandSeparator: true, decimalScale: 2, prefix: '$', value: provisionAmount.value, allowNegative: false, name: `${modelName}[provision_amount]`, onValueChange: handleProvisionChange, className: provisionInputClass, "data-testid": 'provision-amount' }),
            React.createElement(ErrorList, { errors: errors, field: 'provisionAmount' })),
        React.createElement(RiskTeamSelect, { ...{
                riskTeam,
                modelName,
                errors
            } }),
        React.createElement("div", { className: 'action-buttons' },
            React.createElement("button", { type: 'submit', className: 'button -primary' }, "Create"))));
};
export default ProvisionForm;
