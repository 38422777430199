import { useEffect } from 'react';
const useScrollEffect = handleScroll => {
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });
};
export default useScrollEffect;
