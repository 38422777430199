import React from 'react';
import { fileNameFromUrl } from '../../utils';
const AttachmentBox = ({ attachment }) => {
    const { file: { url } } = attachment;
    return (React.createElement("a", { className: 'attachment-box attachment', "data-testid": 'attachment-box', href: url, target: '_blank', rel: 'noreferrer' },
        React.createElement("i", { className: 'icon' }),
        React.createElement("div", { className: 'details' },
            React.createElement("div", { className: 'filename' }, fileNameFromUrl(url)))));
};
export default AttachmentBox;
