import React, { useState } from 'react';
import { humanizeString } from '../../utils';
import { useDropzone } from 'react-dropzone';
import ErrorList from '../../components/shared/ErrorList';
const VerifyInvoiceForm = ({ verificationReasons, setNotes, setReason, setAttachment, validationErrors }) => {
    const [fileName, setFileName] = useState(null);
    const handleFileChange = event => {
        const file = event.target.files[0];
        setFileName(file.name);
        setAttachment(file);
    };
    const onDrop = acceptedFiles => {
        setFileName(acceptedFiles[0].name);
        setAttachment(acceptedFiles[0]);
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop
    });
    return (React.createElement("div", null,
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: 'label' }, "Reason for Verification"),
            verificationReasons.map((type, key) => (React.createElement("div", { className: 'custom-radio -verify-invoice', key: key },
                React.createElement("input", { type: 'radio', className: 'input -visible', name: 'reason', id: `reason_${type}`, value: type, onChange: event => setReason(event.target.value) }),
                React.createElement("label", { className: 'label -text -left', htmlFor: `reason_${type}` }, humanizeString(type))))),
            React.createElement(ErrorList, { errors: validationErrors, field: 'reason' })),
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: 'label' }, "Notes"),
            React.createElement("textarea", { className: 'form-control -textarea', maxLength: 100, name: 'notes', id: 'notes', onChange: event => setNotes(event.target.value) })),
        React.createElement("div", { className: 'form-group' },
            React.createElement("div", { className: 'label' }, "Supporting document"),
            React.createElement("div", { className: 'upload-attachment', ...getRootProps() },
                React.createElement("input", { ...getInputProps(), className: 'input-field', name: 'attachment', id: 'attachment', onChange: handleFileChange }),
                React.createElement("label", { className: `label ${isDragActive ? `-active` : ``}`, htmlFor: 'attachment' },
                    React.createElement("i", { className: 'icon -upload' }),
                    React.createElement("p", { className: 'text' }, "Select a file to upload"),
                    React.createElement("p", { className: 'subtext' }, "or drag and drop it here")),
                fileName && (React.createElement("section", { className: 'uploaded' },
                    React.createElement("i", { className: 'icon -file' }),
                    React.createElement("span", { className: 'filename' }, fileName)))),
            React.createElement(ErrorList, { errors: validationErrors, field: 'attachment' }))));
};
export default VerifyInvoiceForm;
