import React from 'react';
import Author from './Author';
const AddPost = (props) => {
    const { notification } = props;
    const { attributes } = notification;
    const { actorAvatar, actorFullname, showPath, createdAtInWords, currentUserRole, companyName, companyPath } = attributes;
    return (React.createElement("div", { className: 'event-panel -notification custom-panel event' },
        React.createElement("div", { className: 'event-box -notification -unread', "data-testid": 'add-post' },
            React.createElement("div", { className: 'user icon' },
                React.createElement("img", { src: actorAvatar, className: 'avatar' })),
            React.createElement("div", { className: 'description' },
                React.createElement("div", { className: 'title' },
                    React.createElement(Author, { ...{
                            actorFullname,
                            currentUserRole,
                            companyPath,
                            companyName
                        } }),
                    "added a",
                    React.createElement("a", { href: showPath }, " post ")),
                React.createElement("div", { className: 'date' },
                    " ",
                    createdAtInWords)))));
};
export default AddPost;
