import fetch from 'isomorphic-fetch';
import queryString from 'query-string';
export const fetchClientsForBroker = async (apiToken, brokerCompanyId, page, sortBy, sortDirection) => {
    const params = {
        page,
        ...(sortBy && { sort_by: sortBy }),
        ...(sortDirection && { sort_direction: sortDirection })
    };
    const search = queryString.stringify(params);
    const endpoint = `/api/admin/brokers/${brokerCompanyId}/clients?${search}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'applications/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'Get'
    }).then((res) => res.json());
};
