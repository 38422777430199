import classNames from 'classnames';
import React from 'react';
import { formatPrice } from '../../utils/formatPrice';
import FundsAvailable from './FundsAvailable';
import LoanEligibleReceivables from './LoanToEligibleReceivables';
const FundingPanel = ({ funds, isConnected, disableRefreshLink, refreshDataPath }) => {
    const { fundsAvailable, creditLimit, approvedReceivables, loanOutstanding, ltv, advanceRate } = funds;
    const refreshClass = classNames('icon-link link', {
        '-disabled': !isConnected || disableRefreshLink
    });
    const formatCents = amount => amount / 100;
    // TODO(dt): Improve
    const newFundsAvailable = formatCents(+fundsAvailable);
    const newCreditLimit = formatCents(+creditLimit);
    const newApprovedReceivables = formatCents(+approvedReceivables);
    const newLoanOutstanding = formatCents(+loanOutstanding);
    return (React.createElement("div", { "data-testid": 'funding-panel', className: 'custom-panel' },
        React.createElement("div", { className: 'header -refresh -space-between' },
            "Your Funds",
            React.createElement("a", { href: refreshDataPath, className: refreshClass, "data-test-id": 'refresh-data-link' },
                React.createElement("div", { className: 'icon' }),
                React.createElement("div", { className: 'text' }, "Refresh Data"))),
        React.createElement(FundsAvailable, { funds: newFundsAvailable }),
        React.createElement(LoanEligibleReceivables, { ltv: ltv, advanceRate: advanceRate }),
        React.createElement("div", { className: 'item icon-figure' },
            React.createElement("div", { className: 'icon -cash' }),
            React.createElement("div", { className: 'figure' },
                React.createElement("div", { className: 'label' }, "Loan Outstanding"),
                React.createElement("div", { className: 'data' }, formatPrice(newLoanOutstanding)))),
        React.createElement("div", { className: 'item icon-figure' },
            React.createElement("div", { className: 'icon -wand' }),
            React.createElement("div", { className: 'figure' },
                React.createElement("div", { className: 'label' }, "Approved Receivables"),
                React.createElement("div", { className: 'data' }, formatPrice(newApprovedReceivables)))),
        React.createElement("div", { className: 'item icon-figure' },
            React.createElement("div", { className: 'icon -wallet' }),
            React.createElement("div", { className: 'figure' },
                React.createElement("div", { className: 'label' }, "Credit Limit"),
                React.createElement("div", { className: 'data' }, formatPrice(newCreditLimit))))));
};
export default FundingPanel;
