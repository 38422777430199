import classNames from 'classnames';
import React from 'react';
const DaysPastIssue = ({ daysSinceIssue = 0 }) => {
    const statusBoxClassName = classNames('status-box', {
        '-high': daysSinceIssue > 60,
        '-medium': daysSinceIssue > 30 && daysSinceIssue <= 60,
        '-low': daysSinceIssue > 0 && daysSinceIssue <= 30,
        '-safe': daysSinceIssue <= 0
    });
    return (React.createElement("div", { className: statusBoxClassName, "data-testid": 'days-past-issue' }, `${daysSinceIssue} Days`));
};
export default DaysPastIssue;
