import React from 'react';
import SortableTableHeaders from '../table-headers/SortableTabs';
import AdminClientsTableBody from './AdminClientsTableBody';
import PaginationBar from '../shared/PaginationBar';
import AdminClientModal from './AdminClientModal';
const AdminClientsTable = ({ apiToken, brokerCompanyId, currentPage, clientsArray, sortBy, sortDirection, pageSize, totalPages, totalItems, forwardHeaderRef, headerWidths, isLoading, sortClients, handlePageClick, showClientModal, setShowClientModal, availableClients, handleSetClient }) => {
    const headerFields = [{ text: 'Client', field: 'name' }, { text: 'Action' }];
    return (React.createElement("div", { className: 'custom-panel -admin', "data-testid": 'admin-clients-table' },
        React.createElement("div", { className: 'header -space-between' },
            "Clients",
            React.createElement("button", { className: 'custom-button -default', onClick: () => {
                    setShowClientModal(true);
                } }, "Assign Client")),
        React.createElement("table", { className: 'custom-table' },
            React.createElement(SortableTableHeaders, { ...{
                    forwardHeaderRef,
                    headerFields,
                    headerWidths,
                    isLoading,
                    sortBy,
                    sortDirection
                }, sortItems: sortClients }),
            React.createElement(AdminClientsTableBody, { ...{
                    apiToken,
                    brokerCompanyId,
                    clientsArray,
                    isLoading
                } })),
        totalItems > pageSize && (React.createElement(PaginationBar, { ...{
                currentPage,
                totalPages,
                totalItems,
                pageSize,
                handlePageClick
            }, resource: 'clients' })),
        React.createElement(AdminClientModal, { isModalOpen: showClientModal, availableClients: availableClients, closeModal: () => {
                setShowClientModal(false);
            }, handleSetClient: handleSetClient })));
};
export default AdminClientsTable;
