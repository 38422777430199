import React from 'react';
const BankTransactions = (props) => {
    const { bankTransactions } = props;
    return (React.createElement("div", { className: 'borrower-bank-transactions' },
        React.createElement("table", { className: 'custom-table table', "data-testid": 'request-drawdown-bank-transactions' },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { className: 'header' }, " Date "),
                    React.createElement("th", { className: 'header' }, " Description "),
                    React.createElement("th", { className: 'header' }, " Amount "))),
            React.createElement("tbody", null, bankTransactions.map((bt, i) => {
                return (React.createElement("tr", { key: i },
                    React.createElement("td", { className: 'cell' }, bt.date),
                    React.createElement("td", { className: 'cell' }, bt.description),
                    React.createElement("td", { className: 'cell' }, bt.amount)));
            })))));
};
export default BankTransactions;
