import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import NotificationList from './NotificationList';
import OnHoverLabel from './OnHoverLabel';
import { fetchAdminNotifications, fetchBorrowerNotifications, markAdminNotificationsAsRead, markBorrowerNotificationsAsRead } from '../../api/notification';
import useOutsideClick from '../../hooks/useOutsideClick';
import { showNotifyToast } from '../../utils';
const Notifications = (props) => {
    const { apiToken, seeAllPath, isAdmin, amendedInvoiceThreshold } = props;
    const [displayNotifList, setDisplayNotifList] = useState(false);
    const notificationRef = useRef(null);
    const [unreadCount, setUnreadCount] = useState(0);
    const [notifications, setNotifications] = useState({ data: [] });
    const iconClass = classNames('icon', 'icon-link', { '-admin': isAdmin });
    const handleMarkAllAsRead = async () => {
        const markAllAsRead = isAdmin === true
            ? markAdminNotificationsAsRead
            : markBorrowerNotificationsAsRead;
        try {
            await markAllAsRead(apiToken);
            setNotifications({ data: [] });
            setUnreadCount(0);
            setDisplayNotifList(false);
            showNotifyToast({
                text: 'All your notifications have been marked as read.',
                type: 'success'
            });
        }
        catch {
            showNotifyToast({
                text: 'Unable to mark notifications as read. Please try again.',
                type: 'error'
            });
        }
    };
    useOutsideClick(notificationRef, () => {
        setDisplayNotifList(false);
    });
    useEffect(() => {
        const fetchNotifications = isAdmin === true ? fetchAdminNotifications : fetchBorrowerNotifications;
        fetchNotifications(apiToken).then(res => {
            setNotifications(res.notifications);
            setUnreadCount(res.unreadCount);
        });
    }, [unreadCount]);
    if (unreadCount === 0) {
        return (React.createElement("div", { className: 'notification-container item' },
            React.createElement("a", { className: 'link', href: seeAllPath, "data-testid": 'notification-index-link' },
                React.createElement("div", { className: iconClass },
                    React.createElement("div", { className: 'icon -notification' }),
                    React.createElement(OnHoverLabel, { isAdmin: isAdmin })))));
    }
    return (React.createElement("div", { ref: notificationRef, className: 'notification-container item', "data-testid": 'notifications' },
        React.createElement("div", { className: iconClass, onClick: () => setDisplayNotifList(current => !current) },
            React.createElement("div", { className: 'icon -notification' }),
            React.createElement(OnHoverLabel, { isAdmin: isAdmin }),
            unreadCount > 0 && React.createElement("div", { className: 'badge-count' }, unreadCount)),
        displayNotifList && (React.createElement(NotificationList, { isAdmin: isAdmin, seeAllPath: seeAllPath, notifications: notifications, handleMarkAllAsRead: handleMarkAllAsRead, amendedInvoiceThreshold: amendedInvoiceThreshold }))));
};
export default Notifications;
