import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const ProgressBar = ({ percentage, isHalfBar }) => {
    const strokeWidth = isHalfBar ? 18 : 12;
    const ratio = isHalfBar ? 0.5 : 1;
    const sideProgressBarStyle = {
        height: '152px',
        display: 'flex'
    };
    const fullBarStyle = {
        pathColor: '#E9B949',
        textColor: '#7C5E10',
        trailColor: '#FCEFC7'
    };
    const halfBarStyle = {
        rotation: 1 / 2 + 1 / 4,
        strokeLinecap: 'butt',
        pathColor: '#7BC47F',
        trailColor: '#dfe1e6',
        textSize: '14px',
        textColor: '#05400A'
    };
    return (React.createElement("div", { style: sideProgressBarStyle },
        React.createElement(CircularProgressbar, { value: percentage, text: `${percentage}%`, circleRatio: ratio, strokeWidth: strokeWidth, styles: buildStyles(isHalfBar ? halfBarStyle : fullBarStyle) })));
};
export default ProgressBar;
