import classNames from 'classnames';
import React from 'react';
const Panel = ({ csrfToken, isOpened, path }) => {
    const panelClass = classNames('panel', {
        '-active': isOpened
    });
    return (React.createElement("form", { method: 'post', action: path, className: panelClass, "data-testid": 'panel', encType: 'multipart/form-data' },
        React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
        React.createElement("div", { className: 'fields' },
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, "CSV File"),
                React.createElement("input", { name: 'csv_file', type: 'file', accept: '.csv', className: 'form-control input' }))),
        React.createElement("button", { className: 'submit' }, "Upload Data")));
};
export default Panel;
