import classNames from 'classnames';
import React from 'react';
const SearchBar = ({ query, handleSearchQueryChange, handleSearchQueryClear, forwardSearchBarRef, forwardSearchInputRef, placeholder, userType, handleSearchBarBlur, handleSearchBarFocus }) => {
    const hasQueryLength = query.length > 0;
    const searchBarClass = classNames('search-bar bar', {
        '-borrower': userType === 'borrower',
        '-admin': userType === 'admin'
    });
    const placeholderText = placeholder || 'Search for a company, customer, etc.';
    return (React.createElement("div", { className: searchBarClass, ref: forwardSearchBarRef, "data-testid": 'search-bar' },
        React.createElement("div", { className: 'icon' }),
        React.createElement("input", { type: 'text', className: 'input', placeholder: placeholderText, onChange: handleSearchQueryChange, ref: forwardSearchInputRef, onFocus: handleSearchBarFocus, onBlur: handleSearchBarBlur, maxLength: 100, "data-testid": 'search-input' }),
        hasQueryLength && (React.createElement("a", { className: 'clear', "data-testid": 'clear-button', onClick: handleSearchQueryClear }, "Clear"))));
};
export default SearchBar;
