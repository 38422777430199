import React from 'react';
import SpinnerButton from '../shared/SpinnerButton';
const Subheader = ({ currentStep, stepInfo }) => {
    const { nextButtonText, nextButtonHandler, previousButtonText, previousButtonHandler } = stepInfo;
    return (React.createElement("div", { className: 'sub-header header -sticky' },
        React.createElement("h1", { className: 'label' }, " Create Commission "),
        React.createElement("div", { className: 'actions', "data-testid": 'actions' },
            previousButtonHandler && previousButtonText && (React.createElement(SpinnerButton, { text: previousButtonText || '', className: 'custom-button button -trade-loan-prev', isLoading: false, buttonType: 'button', handleClick: () => previousButtonHandler(), testId: `step-${currentStep}-previous` })),
            nextButtonHandler && nextButtonText && (React.createElement(SpinnerButton, { text: nextButtonText || '', className: 'custom-button button -trade-loan-next', isLoading: false, buttonType: 'button', handleClick: () => nextButtonHandler(), testId: `step-${currentStep}-next` })))));
};
export default Subheader;
