import classNames from 'classnames';
import React from 'react';
import RegularLogo from '../earlypay-logo/RegularLogo';
import XmasLogo from '../earlypay-logo/XmasLogo';
const EarlypayLogo = ({ color, size, type }) => {
    const logoClass = classNames('earlypay-logo logo', {
        '-white': color === 'white',
        '-blue': color === 'blue',
        '-neutral': color === 'neutral',
        '-xmas': type === 'xmas',
        '-medium': size === 'medium'
    });
    if (type === 'xmas') {
        return React.createElement(XmasLogo, { ...{ logoClass } });
    }
    else if (type === 'regular') {
        return React.createElement(RegularLogo, { ...{ logoClass } });
    }
    else {
        return React.createElement(RegularLogo, { ...{ logoClass } });
    }
};
export default EarlypayLogo;
