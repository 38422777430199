import classNames from 'classnames';
import React from 'react';
import { numberToPercentage } from '../../utils/numberToPercentage';
const LoanToEligibleReceivables = (props) => {
    const { ltv, advanceRate } = props;
    const iconClass = classNames('icon', '-pulse', {
        '-warning': ltv > advanceRate
    });
    const dataClass = classNames('data', { '-warning': ltv > advanceRate });
    return (React.createElement("div", { className: 'item icon-figure', "data-testid": 'loan-to-eligible-receivables' },
        React.createElement("div", { className: iconClass, "data-testid": 'ltv-icon' }),
        React.createElement("div", { className: 'figure' },
            React.createElement("div", { className: 'label' }, "Loan to Approved Invoices"),
            React.createElement("div", { className: dataClass, "data-testid": 'ltv-data' },
                numberToPercentage(ltv),
                React.createElement("span", { className: 'info' }, ` (${numberToPercentage(advanceRate)} limit)`)))));
};
export default LoanToEligibleReceivables;
