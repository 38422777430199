import classNames from 'classnames';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';
const Modal = ({ isOpen, onClose, children, contentSize = 'sm', dataTestId }) => {
    const wrapperClass = classNames('modal-container modal', {
        '-xs': contentSize === 'xs',
        '-sm': contentSize === 'sm',
        '-md': contentSize === 'md',
        '-lg': contentSize === 'lg',
        '-xl': contentSize === 'xl'
    });
    useEffect(() => {
        // Prevents scrolling when modal is open
        isOpen
            ? (document.body.style.overflow = 'hidden')
            : (document.body.style.overflow = 'unset');
    }, [isOpen]);
    const onKeyDown = (event) => {
        // When esc key is pressed
        if (event.keyCode === 27 && isOpen) {
            onClose();
        }
    };
    useEffect(() => {
        document.addEventListener('keydown', onKeyDown, false);
        return () => {
            document.removeEventListener('keydown', onKeyDown, false);
        };
    }, [isOpen]);
    if (isOpen) {
        return ReactDOM.createPortal(React.createElement(FocusLock, { autoFocus: false },
            React.createElement("div", { className: wrapperClass, "aria-label": 'modal', "data-testid": dataTestId },
                React.createElement("div", { className: 'body', "aria-modal": true, role: 'dialog', tabIndex: -1 }, children),
                React.createElement("div", { className: 'background', onClick: () => onClose() }))), document.body);
    }
    return null;
};
export default Modal;
