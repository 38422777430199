import React from 'react';
const Payments = ({ payments, total }) => {
    return (React.createElement("div", { className: 'tooltip-panel -no-border -repayment', "data-testid": 'activity-feed-repayment-payments' },
        payments.map((payment, i) => (React.createElement("div", { className: 'row', key: i },
            React.createElement("span", { className: 'description' },
                " ",
                payment.attributes.text,
                " "),
            React.createElement("span", { className: 'amount -gray' },
                " ",
                payment.attributes.amount,
                " ")))),
        React.createElement("div", { className: 'row -total' },
            React.createElement("span", { className: 'description' }, " Total "),
            React.createElement("span", { className: 'amount -blue' },
                " ",
                total,
                " "))));
};
export default Payments;
