import classNames from 'classnames';
import React from 'react';
import { useLightbox } from 'simple-react-lightbox';
import { fileNameFromUrl } from '../../utils';
const AttachmentThumb = ({ attachment, collapseItems = false, collapsedItemsCount = 0, index, inNextRows = false }) => {
    const { file: { url, thumb } } = attachment;
    const { openLightbox } = useLightbox();
    const thumbClass = classNames('attachment-thumb attachment', {
        '-collapsed': inNextRows && collapseItems
    });
    const fileName = fileNameFromUrl(url);
    const thumbUrl = thumb ? thumb.url : url;
    const displayCollapsedCount = collapsedItemsCount > 0 && collapseItems;
    const handleOpen = (event) => {
        event.preventDefault();
        if (index !== undefined) {
            openLightbox(index);
        }
    };
    return (React.createElement("a", { href: url, className: thumbClass, "data-attribute": 'SRL', "data-testid": 'attachment-thumb', onClick: handleOpen },
        displayCollapsedCount && (React.createElement("div", { className: 'overlay', "data-testid": 'see-more' }, `+${collapsedItemsCount}`)),
        React.createElement("img", { className: 'image', src: thumbUrl, alt: fileName })));
};
export default AttachmentThumb;
