import React from 'react';
import CompanyName from '../CompanyName';
const VoidInvoice = ({ event }) => {
    const { attributes } = event;
    const { companyLink, companyName, createdAtFormatted, customerLink, eventable: { attributes: { number: invoiceNumber, amountFormatted, customerName } }, invoiceLink } = attributes;
    return (React.createElement("div", { className: 'event-box event', "data-testid": 'void-invoice' },
        React.createElement("div", { className: 'icon' },
            React.createElement("div", { className: 'activity-icon -void-invoice' })),
        React.createElement("div", { className: 'description' },
            React.createElement("div", { className: 'title' },
                React.createElement(CompanyName, { name: companyName, companyLink: companyLink }),
                " voided",
                ' ',
                React.createElement("span", { className: 'bold' },
                    React.createElement("a", { href: invoiceLink }, invoiceNumber)),
                ' ',
                "of ",
                React.createElement("span", { className: 'amount -green' }, amountFormatted),
                " for",
                ' ',
                React.createElement("a", { href: customerLink }, customerName)),
            React.createElement("div", { className: 'date' }, createdAtFormatted))));
};
export default VoidInvoice;
