import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { archiveRiskActivity, editRiskActivity, resolveRiskActivity } from '../../api/risk_log';
import { showNotifyToast } from '../../utils';
import Actions from './Actions';
import Description from './Description';
const RiskActivityDetails = ({ apiToken, borrowerId, initialRiskActivity, enableActions = false }) => {
    const [riskActivity, setRiskActivity] = useState(initialRiskActivity);
    const [isMenuActive, setIsMenuActive] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedText, setEditedText] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [actionsVisible, setActionsVisible] = useState(false);
    const editTextFieldRef = useRef(null);
    useEffect(() => {
        if (isEditing && editTextFieldRef && editTextFieldRef.current) {
            editTextFieldRef.current.focus();
            editTextFieldRef.current.select();
        }
    }, [isEditing]);
    const { authorAvatarUrl, authorFullName, description, formattedTime, isArchived, resolvedAt, statusClassVariant } = riskActivity;
    const scope = `admin/borrowers/${borrowerId}`;
    const bodyClassName = classNames('body', {
        '-visible-actions': actionsVisible || isMenuActive,
        '-invisible-actions': !actionsVisible && !isMenuActive
    });
    const isResolved = resolvedAt !== null;
    const toggleMenu = () => {
        isMenuActive ? setIsMenuActive(false) : setIsMenuActive(true);
    };
    const handleArchive = async (event) => {
        event.preventDefault();
        if (window.confirm('Are you sure you want to archive this risk activity?')) {
            setIsMenuActive(false);
            setIsSaving(true);
            try {
                const response = await archiveRiskActivity(apiToken, riskActivity.id, scope);
                setRiskActivity(response.data.attributes);
                showNotifyToast({
                    text: 'Successfully archived risk activity.',
                    type: 'success'
                });
            }
            catch {
                showNotifyToast({
                    text: 'Something went wrong. Please try again.',
                    type: 'error'
                });
            }
            setIsSaving(false);
        }
    };
    const handleResolve = async (event) => {
        event.preventDefault();
        setIsMenuActive(false);
        setIsSaving(true);
        try {
            const response = await resolveRiskActivity(apiToken, riskActivity.id, scope);
            setRiskActivity(response.data.attributes);
            showNotifyToast({
                text: 'Successfully resolved risk activity.',
                type: 'success'
            });
        }
        catch {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsSaving(false);
    };
    const handleEdit = (event) => {
        event.preventDefault();
        setIsEditing(true);
        setIsMenuActive(false);
        setEditedText(description);
    };
    const handleEditorClose = (event) => {
        event.preventDefault();
        setIsEditing(false);
        setEditedText('');
    };
    const handleTextChange = (event) => {
        event.preventDefault();
        setEditedText(event.currentTarget.value);
    };
    const handleSave = async () => {
        const requestBody = {
            riskLog: {
                description: editedText
            }
        };
        setIsSaving(true);
        try {
            const response = await editRiskActivity(apiToken, riskActivity.id, requestBody, scope);
            setRiskActivity(response.data.attributes);
            setIsEditing(false);
            setEditedText('');
            showNotifyToast({
                text: 'Successfully updated risk activity.',
                type: 'success'
            });
        }
        catch {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsSaving(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `info ${statusClassVariant}`, "data-testid": 'risk-activity-info' },
            React.createElement("div", { className: 'time' }, formattedTime),
            React.createElement("div", { className: bodyClassName, onMouseEnter: () => setActionsVisible(true), onMouseLeave: () => setActionsVisible(false) },
                React.createElement(Description, { ...{
                        description,
                        editedText,
                        isArchived,
                        isEditing,
                        isSaving,
                        resolvedAt,
                        handleEditorClose,
                        handleSave,
                        handleTextChange
                    }, forwardEditTextFieldRef: editTextFieldRef }),
                enableActions && (React.createElement(Actions, { ...{
                        isArchived,
                        isMenuActive,
                        isResolved,
                        isSaving,
                        handleArchive,
                        handleEdit,
                        handleResolve,
                        setActionsVisible,
                        toggleMenu
                    } })))),
        React.createElement("div", { className: 'author', "data-testid": 'risk-activity-author' },
            React.createElement("img", { className: 'avatar', src: authorAvatarUrl }),
            React.createElement("div", { className: 'name' }, authorFullName))));
};
export default RiskActivityDetails;
