import React from 'react';
import ActivitiesContext from '../../contexts/ActivitiesContext';
import Activity from '../../components/activity-feed/Activity';
const ActivityWrapper = ({ apiToken, event, feedType, userAvatarUrl, currentUserId }) => {
    const context = { apiToken, feedType };
    return (React.createElement("div", { className: 'activity-wrapper activity' },
        React.createElement(ActivitiesContext.Provider, { value: context },
            React.createElement(Activity, { ...{ event, userAvatarUrl, currentUserId } }))));
};
export default ActivityWrapper;
