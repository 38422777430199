import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import useScrollEffect from '../../hooks/useScrollEffect';
import EarlypayLogo from '../shared/EarlypayLogo';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';
const LandingNavbar = ({ maxHeroHeight }) => {
    const [currentScroll, setCurrentScroll] = useState(0);
    const handleScroll = () => {
        setCurrentScroll(window.pageYOffset);
    };
    const viewport9 = 992.1;
    const isDesktopMenu = useMediaQuery({ query: `(min-width: ${viewport9}px)` });
    useScrollEffect(handleScroll);
    useEffect(() => {
        setCurrentScroll(window.pageYOffset);
    }, []);
    const currentLocation = window.location.pathname;
    const isAboutPage = currentLocation === '/about';
    const isXeroPage = currentLocation === '/xero';
    const isHomePage = currentLocation === '/';
    const heroHeight = Math.min(window.innerHeight, maxHeroHeight);
    const hasTrigger = currentScroll >= heroHeight;
    const navClass = classNames('navigation-bar', {
        '-colored': hasTrigger,
        '-fixed': hasTrigger,
        '-absolute': !hasTrigger,
        '-slide-out': hasTrigger,
        '-home': isHomePage,
        '-xero': isXeroPage,
        '-about': isAboutPage
    });
    const signUpButtonClass = classNames('custom-button button -narrow -padding-small -neutral-0-text -header', {
        '-background-transparent-white-30': true,
        '-background-transparent-white-20': isXeroPage && !hasTrigger,
        '-background-brand-a-400': isHomePage || (hasTrigger && !isXeroPage),
        '-background-xero-blue-700': isXeroPage && hasTrigger,
        '-compressed': hasTrigger
    });
    const menuClass = classNames('navigation-menu menu', {
        '-desktop': isDesktopMenu,
        '-mobile': !isDesktopMenu,
        '-text-neutral-300': hasTrigger || (isHomePage && !hasTrigger)
    });
    const menuButtonClass = classNames('icon-button button', {
        '-menu': true,
        '-menu-neutral-0': true,
        '-menu-brand-a-400': isHomePage || (hasTrigger && !isXeroPage),
        '-menu-xero-blue-700': isXeroPage && hasTrigger
    });
    const dropdownClass = classNames('custom-dropdown dropdown -landing', {
        '-text-neutral-300': hasTrigger || (isHomePage && !hasTrigger)
    });
    const setLogoColor = () => {
        if (isHomePage && !hasTrigger) {
            return 'blue';
        }
        else if (hasTrigger) {
            return 'blue';
        }
        else {
            return 'white';
        }
    };
    return (React.createElement("nav", { className: navClass, "data-testid": 'landing-navbar' },
        React.createElement("div", { className: 'wrapper' },
            React.createElement("a", { href: '/', className: 'brand', "aria-label": 'Back to home.' },
                React.createElement(EarlypayLogo, { color: setLogoColor() })),
            isDesktopMenu ? (React.createElement(DesktopMenu, { ...{ dropdownClass, menuClass, signUpButtonClass } })) : (React.createElement(MobileMenu, { ...{ menuButtonClass, menuClass } })))));
};
export default LandingNavbar;
