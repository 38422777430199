import React from 'react';
import Modal from '../shared/Modal';
const ApproveWarningForSeletedModal = ({ isSelectedWarningModalOpen, closeApproveSelectedWarningModal }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { isOpen: isSelectedWarningModalOpen, onClose: closeApproveSelectedWarningModal },
            React.createElement("div", { className: 'custom-panel -modal', "data-testid": 'approve-customer-warning-modal' },
                React.createElement("div", { className: 'header' },
                    React.createElement("div", { className: 'custom-icon icon -warning -alert' },
                        "Approve Customer Error \u00A0 ",
                        React.createElement("i", { className: 'icon' }))),
                React.createElement("div", { className: 'content' },
                    React.createElement("p", null, "Please select the customer first"),
                    React.createElement("br", null))))));
};
export default ApproveWarningForSeletedModal;
