import classNames from 'classnames';
import React, { Fragment, useState } from 'react';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import { dropdownStyles } from '../../custom-styles/dropdown';
import { findOptionByValue } from '../../utils';
import ErrorList from '../shared/ErrorList';
import Flatpickr from 'react-flatpickr';
import { formatDateWithYear } from '../../utils/formatDateWithYear';
const LoanApplication = ({ currentTradeLoanDetails, currentTotalAmount, currencyOptions = [], tradeLoanErrors, dateOfShippingSettings, isOutsideContainer = true, isAdmin = true, loanTermOptions = [], handleTradeLoanDetailUpdate, handleTotalAmountChange, handleTradeLoanPaymentCurrencyUpdate, handleMaximumLoanTermUpdate }) => {
    const { dateOfShipping, maximumLoanTerm, shippedFrom, descriptionOfGoods, totalAmountCurrency, dueDate, status, exceptions } = currentTradeLoanDetails;
    const sectionClass = classNames('form-section section -loan-application', {
        '-outside-container': isOutsideContainer
    });
    const selectedPaymentCurrencyOption = findOptionByValue(currencyOptions, totalAmountCurrency);
    const showDueDate = isAdmin && status === 'processed';
    const [loanTermValue, setLoanTermValue] = useState(maximumLoanTerm.toString());
    const [isCustomLoanTerm, setIsCustomLoanTerm] = useState(!loanTermOptions.some(option => option.value === loanTermValue));
    const loanTermOptionCheck = (term) => {
        if ((term === '0' && isCustomLoanTerm) || term === loanTermValue)
            return true;
        return false;
    };
    const handleLoanTermChange = (event) => {
        const value = event.target['value'];
        if (value === '0') {
            setIsCustomLoanTerm(true);
            setLoanTermValue('');
        }
        else {
            setIsCustomLoanTerm(false);
            setLoanTermValue(value);
        }
        handleMaximumLoanTermUpdate(value);
    };
    return (React.createElement("div", { className: sectionClass, "data-testid": 'loan-application' },
        React.createElement("div", { className: 'form-row -col-2' },
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { htmlFor: 'trade-loan-shipped-from', className: 'label -uppercase -required' }, "Shipped from (Country)"),
                React.createElement("input", { className: 'input form-control', maxLength: 100, value: shippedFrom, name: 'trade_loan_details[shipped_from]', onChange: handleTradeLoanDetailUpdate, id: 'trade-loan-shipped-from', "data-testid": 'trade-loan-shipped-from' }),
                React.createElement(ErrorList, { errors: tradeLoanErrors, field: 'shipped_from' })),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label -uppercase -required' }, "Payment Currency"),
                React.createElement(Select, { value: selectedPaymentCurrencyOption, placeholder: 'Select currency', options: currencyOptions, onChange: handleTradeLoanPaymentCurrencyUpdate, className: 'select', styles: dropdownStyles, name: `trade_loan_details[payment_currency]` }),
                React.createElement(ErrorList, { errors: tradeLoanErrors, field: 'payment_currency' }))),
        React.createElement("div", { className: 'form-row -col-2' },
            React.createElement("div", { className: 'form-group', "data-testid": 'input' },
                React.createElement("label", { className: 'label -uppercase' }, "Date of Shipping (Optional)"),
                React.createElement(Flatpickr, { value: dateOfShipping, className: 'input form-control', options: dateOfShippingSettings, name: 'trade_loan_details[date_of_shipping]' }),
                React.createElement(ErrorList, { errors: tradeLoanErrors, field: 'date_of_shipping' })),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { htmlFor: 'trade-loan-total-amount', className: 'label -uppercase -required' }, "Total Currency Amount"),
                React.createElement(NumberFormat, { value: currentTotalAmount.value, className: 'input form-control', thousandSeparator: true, allowNegative: false, decimalScale: 2, onValueChange: handleTotalAmountChange, id: 'trade-loan-total-amount', "data-testid": 'trade-loan-total-amount' }),
                React.createElement(ErrorList, { errors: tradeLoanErrors, field: 'total_amount_cents' }))),
        !isAdmin ? (React.createElement("div", { className: 'borrower-loan-term', "data-testid": 'borrower-loan-term' },
            React.createElement("div", { className: 'form-row -col-2' },
                React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { htmlFor: 'trade-loan-request-term', className: 'label -uppercase -required' }, "Term of Loan"),
                    React.createElement("input", { type: 'number', step: 0, className: 'input form-control', maxLength: 100, value: maximumLoanTerm, name: 'trade_loan_details[maximum_loan_term]', id: 'trade-loan-request-term', "data-testid": 'trade-loan-request-term', onChange: handleTradeLoanDetailUpdate }),
                    React.createElement(ErrorList, { errors: tradeLoanErrors, field: 'maximum_loan_term' }))))) : (React.createElement("div", { className: 'admin-loan-term', "data-testid": 'admin-loan-term' },
            React.createElement("hr", { className: 'custom-divider -my-6' }),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { htmlFor: 'trade-loan-request-term', className: 'label -uppercase -required' }, "Term of Loan"),
                React.createElement("div", { className: 'custom-toggle' }, loanTermOptions.map((type, key) => (React.createElement(Fragment, { key: key },
                    React.createElement("input", { type: 'radio', className: 'button', name: 'trade_loan_details[maximum_loan_term]', id: `maximum_loan_term_${type.value}`, value: type.value, defaultChecked: loanTermOptionCheck(type.value), onChange: handleLoanTermChange, "data-testid": `loan_term_option_${type.value}` }),
                    React.createElement("label", { className: 'label', htmlFor: `maximum_loan_term_${type.value}` }, type.label))))),
                React.createElement(ErrorList, { errors: tradeLoanErrors, field: 'maximum_loan_term' })),
            isAdmin && isCustomLoanTerm && (React.createElement("div", { className: 'form-row -col-2' },
                React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { htmlFor: 'trade-loan-maximum-loan-term', className: 'label -uppercase -required' }, "Set Custom Term of Loan (Days)"),
                    React.createElement("input", { type: 'number', step: 0, min: 1, className: 'input form-control', defaultValue: loanTermValue, name: 'trade_loan_details[maximum_loan_term]', onChange: handleTradeLoanDetailUpdate, id: 'trade-loan-maximum-loan-term', "data-testid": 'trade-loan-maximum-loan-term' })))))),
        showDueDate && (React.createElement("div", { className: 'form-row -col-2' },
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label -uppercase -double-spaced' }, "Due Date"),
                React.createElement("div", { className: 'text' }, formatDateWithYear(dueDate))))),
        React.createElement("hr", { className: 'custom-divider -my-6' }),
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { htmlFor: 'trade-loan-description-of-goods', className: 'label -uppercase -required' }, "Brief description of goods"),
            React.createElement("textarea", { className: 'form-control -textarea', maxLength: 100, name: 'trade_loan_details[description_of_goods]', value: descriptionOfGoods, onChange: handleTradeLoanDetailUpdate, id: 'trade-loan-description-of-goods', "data-testid": 'trade-loan-description-of-goods' }),
            React.createElement(ErrorList, { errors: tradeLoanErrors, field: 'description_of_goods' })),
        React.createElement("div", { className: 'form-group -max-width' },
            React.createElement("p", { className: 'paragraph' }, "We represent and warrant each of the following."),
            React.createElement("p", { className: 'paragraph' },
                React.createElement("span", { className: 'bullet' }, "A."),
                " Except as disclosed in paragraph (c), the representations and warranties in the Facility Agreement would be true if they had been made at the date of this Application Form and on the Drawdown Date in respect of the facts and circumstances then subsisting."),
            React.createElement("p", { className: 'paragraph' },
                React.createElement("span", { className: 'bullet' }, "B."),
                " Except as disclosed in paragraphs (c), no Event of Default subsists or will result from the drawing."),
            React.createElement("p", { className: 'paragraph' },
                React.createElement("span", { className: 'bullet' }, "C."),
                " Details of the exceptions to paragraph (a) and (b) and the remedial action we have taken or propose to take are as follows:"),
            React.createElement("div", { className: 'form-group' },
                React.createElement("textarea", { className: 'form-control -textarea', maxLength: 100, name: 'trade_loan_details[exceptions]', value: exceptions, onChange: handleTradeLoanDetailUpdate, "data-testid": 'trade-loan-exceptions' })),
            React.createElement("p", { className: 'paragraph' },
                React.createElement("span", { className: 'bullet' }, "NOTE"),
                ": Inclusion of a statement under paragraph (c) will not prejudice the conditions precedent of the Facility Agreement."))));
};
export default LoanApplication;
