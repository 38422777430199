import React from 'react';
import RecoveryCode from './RecoveryCode';
const TwoFactorStepTwo = ({ apiToken, decrementStep, incrementStep, recoveryKeyIcon }) => {
    return (React.createElement("div", { className: 'custom-panel  -left-align', "data-testid": 'two-factor-step-two' },
        React.createElement("div", { className: 'header' }, "Your recovery code"),
        React.createElement("div", { className: 'content' },
            React.createElement("div", { className: 'custom-icon' },
                React.createElement("img", { className: 'logo', src: recoveryKeyIcon })),
            React.createElement("br", null),
            React.createElement(RecoveryCode, { apiToken: apiToken })),
        React.createElement("div", { className: 'actions custom-action' },
            React.createElement("div", { className: 'action -no-pad' },
                React.createElement("button", { onClick: incrementStep, className: 'custom-button -default' }, "Continue")),
            React.createElement("div", { className: 'action -no-pad' },
                React.createElement("button", { onClick: decrementStep, className: 'custom-button -outline -margin-right-8px' }, "Back")))));
};
export default TwoFactorStepTwo;
