import React from 'react';
// Components
import ToggleSwitch from '../shared/ToggleSwitch';
import CustomerStatus from './CustomerStatus';
const CustomerRow = ({ customer, isManualLedger, switchOnHandler, switchOffHandler }) => {
    const tooltipText = 'If you no longer wish to fund against this customer, please leave a message and let Earlypay know.';
    const onConfirmationText = `Are you sure you want to select ${customer.name}?`;
    const offConfirmationText = `Are you sure you want to deselect ${customer.name}?`;
    //   if (isManualLedger) {
    //     return (
    //       <tr data-testid='customer-row-manual-ledger'>
    //         <td className='cell'>{customer.sourceId}</td>
    //         <td className='cell'>
    //           <a href={customer.customerLink}>{customer.name}</a>
    //         </td>
    //         <td className='cell'>{customer.isSelected ? 'Yes' : 'No'}</td>
    //         <td className='cell'>
    //           <CustomerStatus status={customer.earlypayStatus} />
    //         </td>
    //         <td className='cell'>{customer.advanceRateFormatted}</td>
    //         <td className='cell'>
    //           {customer.approvedInsuranceLimitFormatted === '-'
    //             ? '$0.00'
    //             : customer.approvedInsuranceLimitFormatted}
    //         </td>
    //         <td className='cell'>
    //           <td className='cell'>{customer.debtorLimitFormatted}</td>
    //         </td>
    //         <td className='cell'>{customer.concentrationLimitFormatted}</td>
    //         <td className='cell'>{customer.selectedLedgerFormatted}</td>
    //         <td className='cell'>{customer.pendingInvoicesFormatted}</td>
    //         <td className='cell -color-neutral-20 '>
    //           {customer.disapprovedInvoicesFormatted}
    //         </td>
    //         <td className='cell -color-neutral-20'>
    //           {customer.debtorLimitExcessFormatted}
    //         </td>
    //         <td className='cell -color-neutral-20'>
    //           {customer.concentrationLimitExcessFormatted}
    //         </td>
    //         <td className='cell'>{customer.ineligibleAdjustmentsFormatted}</td>
    //         <td className='cell'>{customer.approvedLedgerFormatted}</td>
    //         <td className='cell'>{customer.advanceRateAdjustmentFormatted}</td>
    //       </tr>
    //     )
    //   }
    // TODO(dt): To be replaced by logic above
    // this is temporary, Vivian requested to display gross receivables ledger instead of current and overdue receivables
    // for manual ledger clients
    if (isManualLedger) {
        return (React.createElement("tr", { "data-testid": 'customer-row-manual-ledger' },
            React.createElement("td", { className: 'cell' },
                React.createElement("a", { href: customer.customerLink }, customer.name)),
            React.createElement("td", { className: 'cell' }, customer.grossReceivablesLedgerFormatted),
            React.createElement("td", { className: 'cell' },
                React.createElement(CustomerStatus, { status: customer.earlypayStatus })),
            React.createElement("td", { className: 'cell' },
                React.createElement(ToggleSwitch, { isToggled: customer.isSelected, isSwitchDisabled: customer.isSelected, tooltipText: tooltipText, switchOn: () => window.confirm(onConfirmationText) && switchOnHandler(customer.id), switchOff: () => window.confirm(offConfirmationText) &&
                        switchOffHandler(customer.id) })),
            React.createElement("td", { className: 'cell' },
                React.createElement("a", { href: customer.customerLink }, "View"))));
    }
    else {
        return (React.createElement("tr", { "data-testid": 'customer-row' },
            React.createElement("td", { className: 'cell' },
                React.createElement("a", { href: customer.customerLink }, customer.name)),
            React.createElement("td", { className: 'cell' }, customer.currentReceivablesFormatted),
            React.createElement("td", { className: 'cell' }, customer.overdueReceivablesFormatted),
            React.createElement("td", { className: 'cell' },
                React.createElement(CustomerStatus, { status: customer.earlypayStatus })),
            React.createElement("td", { className: 'cell' },
                React.createElement(ToggleSwitch, { isToggled: customer.isSelected, isSwitchDisabled: customer.isSelected, tooltipText: tooltipText, switchOn: () => window.confirm(onConfirmationText) && switchOnHandler(customer.id), switchOff: () => window.confirm(offConfirmationText) &&
                        switchOffHandler(customer.id) })),
            React.createElement("td", { className: 'cell' },
                React.createElement("a", { href: customer.customerLink }, "View"))));
    }
};
export default CustomerRow;
