import React from 'react';
const ReminderStatus = ({ reminder, fulfillmentMessage }) => {
    const hasNoSetReminder = !reminder || !reminder.deadline;
    if (hasNoSetReminder) {
        return (React.createElement("div", { className: 'reminder-status status -not-set', "data-testid": 'reminder-not-set' },
            React.createElement("div", { className: 'custom-icon icon -alert -low' },
                React.createElement("div", { className: 'icon' })),
            React.createElement("div", { className: 'text' }, "You have not set a reminder yet")));
    }
    if (reminder && reminder.isFulfilled) {
        return (React.createElement("div", { className: 'reminder-status status -fulfilled', "data-testid": 'reminder-fulfilled' },
            React.createElement("div", { className: 'custom-icon icon -check -green-300' },
                React.createElement("i", { className: 'icon' })),
            React.createElement("div", { className: 'text' }, fulfillmentMessage)));
    }
    if (reminder) {
        return (React.createElement("div", { className: 'reminder-status status -set', "data-testid": 'reminder-set' },
            React.createElement("div", { className: 'custom-icon icon -warning -alert' },
                React.createElement("i", { className: 'icon' })),
            React.createElement("div", { className: 'text' },
                "Emails will be sent on",
                ' ',
                React.createElement("span", { className: 'date' }, `${reminder.deadlineInWords}, 08:00AM`))));
    }
    return null;
};
export default ReminderStatus;
