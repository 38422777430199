import { isEmptyObject } from '../../utils';
export const defaultTradeLoanDetails = {
    requestDrawdownDate: '',
    maximumLoanTerm: '120',
    dateOfShipping: '',
    shippedFrom: '',
    shippedTo: '',
    totalAmountCents: 0,
    descriptionOfGoods: '',
    exceptions: '',
    totalAmountCurrency: '',
    dueDate: '',
    status: '',
    processedAt: '',
    penaltyApplied: false,
    penaltyToggleable: true,
    penaltyStartDate: '',
    appliedInterestRate: 0.1,
    purchaseOrder: [],
    proFormaInvoice: [],
    transportDocument: [],
    otherDocuments: []
};
export const companyDefaultTradeLoanDetails = (company) => {
    return {
        ...defaultTradeLoanDetails,
        maximum_loan_term: company.tradeLoanDefaultTerm
    };
};
const TradeLoanDetailsReducer = (state, action) => {
    const { tradeLoan, amountValues, documentErrors } = state;
    switch (action.type) {
        case 'UPDATE_TRADE_LOAN_DETAIL':
            return {
                ...state,
                tradeLoan: {
                    ...tradeLoan,
                    [action.fieldName]: action.value
                }
            };
        case 'UPDATE_AMOUNT':
            return {
                ...state,
                tradeLoan: {
                    ...tradeLoan,
                    [action.fieldName]: action.centsAmount
                },
                amountValues: {
                    ...amountValues,
                    [action.fieldName]: action.values
                }
            };
        case 'UPDATE_FILES':
            return {
                ...state,
                tradeLoan: {
                    ...tradeLoan,
                    [action.fieldName]: action.files
                }
            };
        case 'UPDATE_TRADE_LOAN_ERRORS':
            return {
                ...state,
                tradeLoanErrors: action.errors
            };
        case 'UPDATE_DOCUMENT_ERRORS': {
            if (isEmptyObject(action.errors)) {
                const { [action.fieldName]: value, ...updatedErrors } = documentErrors;
                return {
                    ...state,
                    documentErrors: updatedErrors
                };
            }
            else {
                return {
                    ...state,
                    documentErrors: {
                        ...documentErrors,
                        [action.fieldName]: action.errors
                    }
                };
            }
        }
        case 'REFRESH_TRADE_LOAN':
            return {
                ...state,
                tradeLoan: action.tradeLoanDetails
            };
        case 'UPDATE_CURRENCY_FIELD':
            return {
                ...state,
                tradeLoan: {
                    ...tradeLoan,
                    [action.fieldName]: action.option ? action.option.value : ''
                },
                [action.selectedOptionFieldName]: action.option
            };
        case 'UPDATE_MAXIMUM_LOAN_TERM_FIELD':
            return {
                ...state,
                tradeLoan: {
                    ...tradeLoan,
                    [action.fieldName]: action.value
                }
            };
        default:
            return { ...state };
    }
};
export default TradeLoanDetailsReducer;
