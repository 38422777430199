import React from 'react';
import Tab from '../filter-tabs/Tab';
import FilterTradeLoan from './FilterTradeLoan';
const TradeLoanFilterTabs = ({ tabs, baseUrl, filter }) => {
    return (React.createElement("div", { className: 'filter-tabs', "data-testid": 'filter-tabs' },
        tabs.map((tab, i) => {
            return React.createElement(Tab, { key: i, ...tab });
        }),
        React.createElement(FilterTradeLoan, { ...{
                baseUrl,
                filter
            } })));
};
export default TradeLoanFilterTabs;
