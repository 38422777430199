import React from 'react';
import Tooltip from './InvoiceTodoTooltip';
const InvoiceTodoRule = ({ amountRule, percentageRule, amount, percentage, verifyRandom }) => {
    const setClassname = 'status-box';
    const amountData = {
        value: 'amount',
        text: `Greater than ${amount}`,
        status: `${setClassname} -light`
    };
    const percentageData = {
        value: 'percentage',
        text: `Greater than ${percentage}% of credit limit`,
        status: `${setClassname} -red`
    };
    const randomData = {
        value: 'random',
        text: `Random`,
        status: `${setClassname} -random`
    };
    const rules = [
        ...(amountRule ? [amountData] : []),
        ...(percentageRule ? [percentageData] : []),
        ...(verifyRandom ? [randomData] : [])
    ];
    const [mainRule, ...tooltipRules] = rules;
    const hasMultipleRules = rules.length > 1;
    return (React.createElement("div", null,
        React.createElement("span", { className: mainRule['status'] }, mainRule['text']),
        hasMultipleRules && (React.createElement(Tooltip, { content: tooltipRules },
            React.createElement("span", { className: setClassname + ' -random' },
                "+",
                rules.length - 1)))));
};
export default InvoiceTodoRule;
