import React from 'react';
// Containers
import BorrowerActivities from '../containers/BorrowerActivities';
import BorrowerAssignedAdminsPanel from './assigned-admins-panel/BorrowerAssignedAdminsPanel';
// Components
import ConnectPanel from '../components/connect-panel/ConnectPanel';
import FundingPanel from '../components/funding-panel/FundingPanel';
import TradeLoanPanel from '../components/trade-loan-panel/TradeLoanPanel';
import MyobBankFeedPromptPanel from './myob-bank-feed-reference/MyobBankFeedPromptPanel';
import ManualLedgerFunding from '../components/manual-ledger-funding/ManualLedgerFunding';
const BorrowerDashboard = ({ apiToken, user, userAvatarUrl, notConnected, isManualLedger, xeroPath, myobPath, funds, isConnected, disableRefreshLink, refreshDataPath, tradeLoanBalance, isTradeLoanEnabled, providers, showMyobBankFeedPrompt, myobBankReferencePath, companyFundingValues }) => {
    return (React.createElement("div", { "data-testid": 'borrower-dashboard', className: 'borrower-dashboard' },
        React.createElement(BorrowerActivities, { apiToken: apiToken, userAvatarUrl: userAvatarUrl }),
        React.createElement("div", { className: 'widgets' },
            !notConnected && showMyobBankFeedPrompt && (React.createElement(MyobBankFeedPromptPanel, { ...{ myobBankReferencePath } })),
            notConnected && !isManualLedger ? (React.createElement(ConnectPanel, { ...{
                    xeroPath,
                    myobPath,
                    providers
                } })) : isManualLedger ? (React.createElement(ManualLedgerFunding, { funding: companyFundingValues })) : (React.createElement(FundingPanel, { ...{
                    funds,
                    isConnected,
                    disableRefreshLink,
                    refreshDataPath
                } })),
            isTradeLoanEnabled && React.createElement(TradeLoanPanel, { ...{ tradeLoanBalance } }),
            React.createElement(BorrowerAssignedAdminsPanel, { apiToken: apiToken, borrowerId: user }))));
};
export default BorrowerDashboard;
