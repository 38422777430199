import React from 'react';
import Modal from '../shared/Modal';
const AdminCustomerStatementsModal = ({ isVisible, path, csrfToken, bccEmail, checkedCustomers, checkedSelectedDebtors, customersArray, toggleModal }) => {
    return (React.createElement(Modal, { isOpen: isVisible, onClose: toggleModal },
        React.createElement("div", { className: 'custom-panel -modal -customer-statements-modal', role: 'dialog', "aria-modal": 'true' },
            React.createElement("div", { className: 'header' }, "Confirmation"),
            React.createElement("div", { className: 'content' },
                React.createElement("div", { className: 'section' },
                    React.createElement("div", { className: 'description' }, "This is the list of debtors that you selected to send a statement to their email accounts"),
                    React.createElement("form", { className: 'form form-pane -customer-statement', method: 'post', action: path },
                        React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
                        React.createElement("input", { type: 'hidden', name: 'bcc_email', value: bccEmail, "data-testid": 'bcc-email-value' }),
                        React.createElement("input", { type: 'hidden', name: 'checked_customers', value: String(checkedCustomers) }),
                        React.createElement("input", { type: 'hidden', name: 'email_type', value: 'many_address' }),
                        React.createElement("div", { className: 'rows' }, checkedSelectedDebtors &&
                            customersArray.map((customer) => {
                                if (checkedCustomers.includes(customer.id)) {
                                    return (React.createElement("div", { className: 'row', key: customer.id },
                                        React.createElement("div", null, customer.name),
                                        React.createElement("div", { className: 'email' }, customer.email ? customer.email : 'No email')));
                                }
                            })),
                        React.createElement("div", { className: 'action-buttons -send-statements -space-between' },
                            React.createElement("button", { className: 'button -reset -transparent', onClick: toggleModal }, "Cancel"),
                            React.createElement("input", { type: 'submit', value: 'Send', className: 'button -primary -full-width', "data-disable-with": 'Send' }))))))));
};
export default AdminCustomerStatementsModal;
