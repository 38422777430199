import React, { Fragment, useRef, useState } from 'react';
import ModalForm from '../modal/ModalForm';
import CustomFlatpickr from '../shared/CustomFlatpickr';
const DownloadInvoiceButton = ({ path, className, buttonText, csrfToken, email }) => {
    const [isVisible, setIsVisible] = useState(false);
    const toggleModal = () => {
        isVisible ? setIsVisible(false) : setIsVisible(true);
    };
    const flatpickrSettings = {
        wrap: true,
        altInput: true,
        altFormat: 'd M Y',
        dateFormat: 'Y-m-d',
        mode: 'range'
    };
    const dateClearRef = useRef(null);
    const datePickerRef = useRef(null);
    const ModalHeader = () => {
        return (React.createElement(Fragment, null,
            React.createElement("div", { className: 'text' }, "Download Outstanding Invoices"),
            React.createElement("button", { className: 'icon-button close -cancel', "data-testid": 'close-download-invoice', onClick: () => toggleModal() },
                React.createElement("i", { className: 'icon' }))));
    };
    const ModalBody = () => {
        return (React.createElement("div", { className: 'content custom-scrollbar' },
            React.createElement("div", { className: 'section -list' },
                React.createElement("div", { className: 'form-group', "data-testid": 'input' },
                    React.createElement("label", { className: 'label -uppercase' }, "Email address"),
                    React.createElement("input", { className: 'input form-control', type: 'email', name: 'email', defaultValue: email })),
                React.createElement("div", { className: 'form-group', "data-testid": 'input' },
                    React.createElement("label", { className: 'label -uppercase' }, "Date Range"),
                    React.createElement(CustomFlatpickr, { ...{ flatpickrSettings }, forwardDatePickerRef: datePickerRef, forwardDateClearRef: dateClearRef, selectedDate: '', pickerBackground: 'white', placeholderText: 'Filter by date', clearButtonText: 'Clear Date', name: 'date_range' })),
                React.createElement("div", { className: 'form-group' },
                    React.createElement("label", null,
                        React.createElement("input", { type: 'checkbox', className: 'checkbox', name: 'selected_debtors', value: 'true' }),
                        ' ',
                        "Selected Debtors"))),
            React.createElement("div", { className: 'action-buttons' },
                React.createElement("button", { className: 'button -primary -full-width -center', type: 'submit' }, "SEND"))));
    };
    const formProps = {
        isVisible,
        path,
        csrfToken,
        header: React.createElement(ModalHeader, null),
        body: React.createElement(ModalBody, null)
    };
    return (React.createElement("div", null,
        React.createElement("button", { className: className, onClick: toggleModal, "data-testid": 'modal-button' }, buttonText),
        React.createElement(ModalForm, { ...formProps })));
};
export default DownloadInvoiceButton;
