import React, { useState } from 'react';
import Select from 'react-select';
import { isAPIErrors, isAPIValidationErrors } from '../../../types';
import { updateDebtorStatus } from '../../api/client_setup';
import { dropdownStyles } from '../../custom-styles/dropdown';
import { showNotifyToast } from '../../utils';
import SpinnerButton from '../shared/SpinnerButton';
import ErrorList from '../shared/ErrorList';
const DebtorStatusForm = ({ authToken, borrowerId, debtorStatus, isReadOnly = false, incrementSaves }) => {
    const debtorStatusOptions = [
        { value: 'false', label: 'Not Selected' },
        { value: 'true', label: 'Selected' }
    ];
    const initialDebtorStatus = debtorStatusOptions.find((option) => option.value === debtorStatus.toString());
    const [selectedDebtorStatus, setSelectedDebtorStatus] = useState(initialDebtorStatus);
    const [isLoading, setIsLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const handleDebtorStatusChange = (selectedDebtorStatus) => {
        setSelectedDebtorStatus(selectedDebtorStatus);
    };
    const handleSave = async () => {
        const body = {
            company: {
                defaultDebtorStatus: selectedDebtorStatus
                    ? selectedDebtorStatus.value === 'true'
                    : false
            }
        };
        setIsLoading(true);
        try {
            const response = await updateDebtorStatus(authToken, borrowerId, body);
            if (isAPIValidationErrors(response)) {
                throw response;
            }
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            setValidationErrors({});
            if (incrementSaves) {
                incrementSaves();
            }
            showNotifyToast({
                text: 'Successfully updated Debtor Status',
                type: 'success'
            });
        }
        catch (err) {
            if (isAPIValidationErrors(err)) {
                setValidationErrors(err.errors);
            }
            showNotifyToast({
                text: 'Failed to update Debtor Status',
                type: 'error'
            });
        }
        setIsLoading(false);
    };
    return (React.createElement("div", { className: 'client-setup-form form -debtor-status', "data-testid": 'debtor-status-form' },
        React.createElement("div", { className: 'form-group' },
            React.createElement("div", { className: 'label' }, "Select whether debtors should be selected or not selected by default"),
            React.createElement(Select, { options: debtorStatusOptions, styles: dropdownStyles, value: selectedDebtorStatus, isDisabled: isReadOnly, onChange: handleDebtorStatusChange }),
            React.createElement(ErrorList, { errors: validationErrors, field: 'default_debtor_status' })),
        React.createElement("div", { className: 'action-buttons actions' },
            React.createElement(SpinnerButton, { text: 'Save', buttonType: 'button', isLoading: isLoading, className: 'button -primary', handleClick: handleSave, isDisabled: isReadOnly, testId: 'save' }))));
};
export default DebtorStatusForm;
