import React from 'react';
import Textarea from 'react-expanding-textarea';
import EmojiPicker from '../shared/EmojiPicker';
import { compileVariantClasses } from '../../utils';
const CommentForm = ({ isSubmitting, commentBody, userAvatarUrl, emojiPickerDirection, showEmojiPicker, validationErrors, forwardEmojiPickerRef, forwardTextAreaRef, itemName, formPlaceholder, privacySettingEnabled = false, variantClasses, handleAttachmentTrigger, handlePrivacySettingChange, handleCommentBodyChange, handleSubmit, selectEmoji, toggleEmojiPicker, focusTextArea }) => {
    const className = variantClasses
        ? compileVariantClasses('comment-form form -relative', variantClasses)
        : 'comment-form form -relative';
    const itemNameText = itemName || 'comment';
    const placeholderText = formPlaceholder || `Write a ${itemNameText} or ask Earlypay a question`;
    return (React.createElement("form", { ...{ className }, "data-testid": 'comment-form', onSubmit: handleSubmit },
        React.createElement("img", { className: 'avatar', src: userAvatarUrl }),
        React.createElement("div", { className: 'fields', onClick: focusTextArea },
            React.createElement(Textarea, { className: 'input form-control body -textarea -comment -noresize', "data-testid": 'comment-form-textarea', onChange: handleCommentBodyChange, value: commentBody, placeholder: placeholderText, ref: forwardTextAreaRef, disabled: isSubmitting }),
            React.createElement("button", { className: 'icon-button button -emoji', type: 'button', "data-testid": 'emoji', onClick: toggleEmojiPicker, disabled: isSubmitting },
                React.createElement("i", { className: 'icon' })),
            React.createElement("button", { className: 'icon-button button -attachment', type: 'button', "data-testid": 'attachment', onClick: handleAttachmentTrigger, disabled: isSubmitting },
                React.createElement("i", { className: 'icon' })),
            isSubmitting ? (React.createElement("div", { className: 'loading-container loader -button' },
                React.createElement("div", { className: 'loading-spinner spinner' }))) : (React.createElement("button", { className: 'icon-button button -send', type: 'submit', "data-testid": 'submit', disabled: isSubmitting },
                React.createElement("i", { className: 'icon' }))),
            validationErrors.body &&
                validationErrors.body.map((error, i) => (React.createElement("div", { className: 'form-field-error', "data-testid": 'form-field-error', key: i }, error))),
            validationErrors.attachment &&
                validationErrors.attachment.map((error, i) => (React.createElement("div", { className: 'form-field-error', "data-testid": 'form-field-error', key: i }, error)))),
        React.createElement(EmojiPicker, { ...{ forwardEmojiPickerRef, selectEmoji }, isActive: showEmojiPicker, direction: emojiPickerDirection }),
        privacySettingEnabled && (React.createElement("div", { className: 'settings' },
            React.createElement("label", { className: 'label' }, "Privacy Setting:"),
            React.createElement("select", { className: 'dropdown -privacy-setting', "data-testid": 'privacy-setting', onChange: handlePrivacySettingChange, defaultValue: 'public' },
                React.createElement("option", { value: 'public' }, "Public"),
                React.createElement("option", { value: 'private' }, "Private"),
                React.createElement("option", { value: 'no_notifications' }, "No notifications"))))));
};
export default CommentForm;
