import React from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
const CumulativeInterestTooltip = ({ totalCumulativeInterest, currentCumulativeInterest, accumulatedPenaltyInterest, tooltipId, interestStatus = 'penalty' }) => {
    const theme = {
        backgroundColor: '#ffffff',
        textColor: '#091e42',
        border: true,
        borderColor: '#c1c7d0'
    };
    const iconClass = classNames('custom-icon -notice ', {
        '-red': interestStatus === 'penalty',
        '-yellow': interestStatus === 'transitioning'
    });
    return (React.createElement("div", { className: 'custom-tooltip -text-group-tooltip -no-left-margin -inline-block', "data-testid": 'text-tooltip' },
        React.createElement("div", { className: 'text' }, totalCumulativeInterest),
        React.createElement("span", { className: iconClass, "data-tip": true, "data-for": tooltipId }),
        React.createElement(ReactTooltip, { ...theme, id: tooltipId, place: 'bottom', effect: 'solid' },
            React.createElement("div", { className: 'text-group -tooltip' },
                React.createElement("label", { className: 'label -uppercase' }, "Breakdown"),
                React.createElement("div", { className: 'text' },
                    "Current Cumulative Interest (",
                    React.createElement("span", { className: 'emphasized' }, currentCumulativeInterest),
                    ") + Accumulated Default Interest (",
                    React.createElement("span", { className: 'emphasized' }, accumulatedPenaltyInterest),
                    ") =",
                    ' ',
                    React.createElement("span", { className: 'emphasized' }, totalCumulativeInterest))))));
};
export default CumulativeInterestTooltip;
