import React, { useState } from 'react';
import classNames from 'classnames';
import { buildUrlQuery } from '../../utils/url';
import Flatpickr from 'react-flatpickr';
const Panel = ({ isOpened, baseUrl, filter }) => {
    const [overdue, setOverdue] = useState(filter.overdue);
    const [minAmount, setMinAmount] = useState(filter.minAmount);
    const [maxAmount, setMaxAmount] = useState(filter.maxAmount);
    const [dateCreated, setDateCreated] = useState(filter.dateCreated || '');
    const toggleOverdue = () => setOverdue(!overdue);
    const handleSetDateCreated = (selectedCallDates, dateString) => {
        setDateCreated(dateString);
    };
    const filterUrl = buildUrlQuery({
        status: filter.status,
        overdue: overdue ? 'true' : 'false',
        min_amount: minAmount,
        max_amount: maxAmount,
        date_created: dateCreated
    }, baseUrl);
    const resetUrl = buildUrlQuery({
        status: filter.status
    }, baseUrl);
    const panelClass = classNames('panel', {
        '-active': isOpened
    });
    return (React.createElement("div", { className: panelClass },
        React.createElement("div", { className: 'dropdowns' },
            React.createElement("div", { className: 'form-group -collapse' },
                React.createElement("div", { className: 'label -uppercase -above-spaced' }, "Date"),
                React.createElement(Flatpickr, { className: 'form-control', value: dateCreated, onChange: handleSetDateCreated, required: true }),
                React.createElement("div", { className: 'form-row -col-2' },
                    React.createElement("div", { className: 'form-group -collapse' },
                        React.createElement("div", { className: 'label -uppercase -above-spaced' }, "Minimum Amount"),
                        React.createElement("input", { className: 'input form-control', type: 'text', pattern: '[0-9]*', name: 'min_amount', value: minAmount, onChange: e => setMinAmount(e.target.value.replace(/\D/, '')) })),
                    React.createElement("div", { className: 'form-group -collapse' },
                        React.createElement("div", { className: 'label -uppercase -above-spaced' }, "Maximum Amount"),
                        React.createElement("input", { className: 'input form-control', type: 'text', pattern: '[0-9]*', name: 'maxAmount', value: maxAmount, onChange: e => setMaxAmount(e.target.value.replace(/\D/, '')) }))),
                React.createElement("div", { className: 'label -uppercase -above-spaced' }, "Display"),
                React.createElement("div", { className: 'form-group -inline' },
                    React.createElement("div", { className: 'input -split' },
                        React.createElement("input", { type: 'radio', value: 'false', onChange: toggleOverdue, name: 'overdue', defaultChecked: overdue === false }),
                        ' ',
                        "ALL"),
                    React.createElement("div", { className: 'input' },
                        React.createElement("input", { type: 'radio', value: 'true', onChange: toggleOverdue, name: 'overdue', defaultChecked: overdue === true }),
                        ' ',
                        "Overdue/In Default Loans")),
                React.createElement("div", { className: 'action-buttons action' },
                    React.createElement("a", { className: 'button -neutral', href: resetUrl }, "Reset"),
                    React.createElement("a", { className: 'button -primary', href: filterUrl }, "Apply Filter"))))));
};
export default Panel;
