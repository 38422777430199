import React from 'react';
import CreateAccountInfo from './CreateAccountInfo';
const Sidebar = ({ logoPath, websiteUrl }) => {
    return (React.createElement("aside", { className: 'panel signup-sidebar sidebar -ep-gradient -relative -rounded' },
        React.createElement("div", { className: 'logo' },
            React.createElement("a", { href: websiteUrl, className: 'brand -spaced', "aria-label": 'Back to home.' },
                React.createElement("img", { src: logoPath, alt: '', className: 'earlypay-logo -helix -client-sign-up' }))),
        React.createElement("div", { className: 'body -center' },
            React.createElement(CreateAccountInfo, null))));
};
export default Sidebar;
