import React from 'react';
const LinearProgressBar = ({ progress }) => {
    const parentDivStyle = {
        height: 10,
        width: '100%',
        backgroundColor: '#FFFAEB',
        borderRadius: 20,
        margin: '10px 0'
    };
    const childDivStyle = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: '#E9B949',
        borderRadius: 20
    };
    return (React.createElement("div", { style: parentDivStyle },
        React.createElement("div", { style: childDivStyle })));
};
export default LinearProgressBar;
