import fetch from 'isomorphic-fetch';
export const sendOtp = async (apiToken, phoneNumber) => {
    const endpoint = `/api/borrower/send_otp`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    const params = {
        phone_number: phoneNumber
    };
    return fetch(endpoint, {
        headers,
        method: 'POST',
        body: JSON.stringify(params)
    }).then((res) => res.json());
};
export const verifyOtp = async (apiToken, code, phoneNumber) => {
    const endpoint = `/api/borrower/verify_otp`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    const params = {
        phone_number: phoneNumber,
        code: code
    };
    return fetch(endpoint, {
        headers,
        method: 'POST',
        body: JSON.stringify(params)
    }).then((res) => res.json());
};
export const fetchRecoveryCode = async (apiToken) => {
    const endpoint = `/api/borrower/fetch_recovery_code`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'POST'
    }).then((res) => res.json());
};
