import fetch from 'isomorphic-fetch';
export const createPost = async (apiToken, postBody, selectedFiles, scope) => {
    const endpoint = `/api/${scope}/posts`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'POST',
        body: buildFormData(postBody, selectedFiles)
    }).then((res) => res.json());
};
const buildFormData = (postBody, selectedFiles) => {
    const formData = new FormData();
    formData.append('body', postBody);
    selectedFiles.forEach(file => {
        formData.append('attachments[]', file);
    });
    return formData;
};
