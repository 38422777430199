import classNames from 'classnames';
import React from 'react';
import { formatPrice } from '../../utils/formatPrice';
const FundsAvailable = (props) => {
    const { funds } = props;
    const iconClass = classNames('icon', {
        '-check': funds >= 0,
        '-close': funds < 0
    });
    const dataClass = classNames('data', { '-warning': funds < 0 });
    return (React.createElement("div", { className: 'item icon-figure', "data-testid": 'funds-available' },
        React.createElement("div", { className: iconClass, "data-testid": 'funds-available-icon' }),
        React.createElement("div", { className: 'figure' },
            React.createElement("div", { className: 'label' }, "Funds Available"),
            React.createElement("div", { className: dataClass, "data-testid": 'funds-available-data' }, formatPrice(funds)))));
};
export default FundsAvailable;
