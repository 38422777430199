import React from 'react';
import { Twemoji } from 'react-emoji-render';
const PostBody = ({ bodyText, isEmojiFormatted }) => {
    if (isEmojiFormatted) {
        return (React.createElement("div", { className: 'post-body body -emoji', "data-testid": 'emoji-formatted' },
            React.createElement(Twemoji, { svg: true, text: bodyText })));
    }
    return (React.createElement("div", { className: 'post-body body', "data-testid": 'emoji-unformatted' }, bodyText));
};
export default PostBody;
