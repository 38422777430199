import React, { useState } from 'react';
import { authorize } from '../../api/invoice';
import { showNotifyToast } from '../../utils';
const InvoiceActions = ({ authToken, borrowerId, borrowerInvoicesPath, invoice }) => {
    const [isSubmitted, setIsSubmitted] = useState(invoice.status === 'SUBMITTED');
    const handleAuthorizeClick = async () => {
        if (window.confirm('Are you sure you want to authorize this invoice?')) {
            try {
                await authorize(authToken, borrowerId, invoice.id);
                setIsSubmitted(false);
            }
            catch (error) {
                if (error instanceof Error) {
                    showNotifyToast({
                        text: error.message,
                        type: 'error'
                    });
                }
            }
        }
    };
    return (React.createElement("div", { className: 'custom-action', "data-testid": 'invoice-table-invoice-actions' },
        React.createElement("a", { className: 'action -no-pad', href: `${borrowerInvoicesPath}/${invoice.id}` }, "View"),
        isSubmitted && (React.createElement("button", { className: 'action -default', onClick: handleAuthorizeClick, "data-testid": 'invoice-table-invoice-authorize-action' }, "Authorize"))));
};
export default InvoiceActions;
