import React, { useState } from 'react';
import InvoiceFinanceForm from '../../components/step-forms/InvoiceFinanceForm';
import CreateAccountInfo from './CreateAccountInfo';
import Sidebar from './Sidebar';
import SignUpHeading from './SignUpHeading';
const InvoiceFinanceSignUp = ({ csrfToken, formUrl, logoPath, resource, errors, otherParams, siteKey, websiteUrl }) => {
    const [captchaValue, setCaptchaValue] = useState('');
    const userData = {
        firstName: resource.first_name,
        lastName: resource.last_name,
        signupCompanyName: resource.signup_company_name,
        email: resource.email
    };
    const handleCaptchaChange = value => {
        setCaptchaValue(value);
    };
    return (React.createElement("div", { className: 'signup-layout -invoice-finance -containerized', "data-testid": 'invoice-finance-sign-up' },
        React.createElement(Sidebar, { ...{ logoPath, websiteUrl } }),
        React.createElement(SignUpHeading, { ...{ logoPath, websiteUrl }, title: 'Create an account' }),
        React.createElement("aside", { className: 'panel info' },
            React.createElement(CreateAccountInfo, { color: 'neutral-400' })),
        React.createElement("main", { className: 'panel registration' },
            React.createElement("div", { className: 'help -bottom' },
                "Having trouble?",
                ' ',
                React.createElement("a", { href: 'tel:1300760205', className: 'link' }, "Call us at 1300 760 205")),
            React.createElement(InvoiceFinanceForm, { ...{
                    errors,
                    formUrl,
                    csrfToken,
                    siteKey,
                    userData,
                    otherParams,
                    captchaValue,
                    handleCaptchaChange
                } }))));
};
export default InvoiceFinanceSignUp;
