import React from 'react';
import AdminCustomerRow from './AdminCustomerRow';
const AdminCustomersTableBody = ({ borrowerCustomersPath, isManualLedger, isDebtorsLedgerRecalculating, customersArray, isLoading, enableActions = false, invoiceFilter, approveCustomerHandler, pendingCustomerHandler, isRequestPending, selectCustomerHandler, deselectCustomerHandler, setCustomerCreditCheckHandler, unsetCustomerCreditCheckHandler, setCustomerNoaSentHandler, unsetCustomerNoaSentHandler, discloseCustomerHandler, undiscloseCustomerHandler, insureCustomerHandler, uninsureCustomerHandler, promptCustomerFee, isRelatedPartyDebtorHandler, isNotRelatedPartyDebtorHandler, isGovernmentDebtorHandler, isNotGovernmentDebtorHandler, isMajorCorporationDebtorHandler, isNotMajorCorporationDebtorHandler, checkIcon, updateEarlypayStatusHandler }) => {
    if (isLoading) {
        return (React.createElement("tbody", { "data-testid": 'loading' },
            React.createElement("tr", null,
                React.createElement("td", { className: 'cell', colSpan: isManualLedger ? 17 : 12 },
                    React.createElement("div", { className: 'loading-container -table' },
                        React.createElement("div", { className: 'loading-spinner' }))))));
    }
    if (customersArray.length === 0) {
        return (React.createElement("tbody", { "data-testid": 'no-record' },
            React.createElement("tr", null,
                React.createElement("td", { className: 'cell', colSpan: 7 }, "There are no Debtors to display"))));
    }
    return (React.createElement("tbody", { "data-testid": 'loaded' }, customersArray.map((customer) => {
        return (React.createElement(AdminCustomerRow, { ...{
                isManualLedger,
                isDebtorsLedgerRecalculating,
                customer,
                borrowerCustomersPath,
                enableActions,
                invoiceFilter,
                approveCustomerHandler,
                pendingCustomerHandler,
                isRequestPending,
                selectCustomerHandler,
                deselectCustomerHandler,
                setCustomerCreditCheckHandler,
                unsetCustomerCreditCheckHandler,
                setCustomerNoaSentHandler,
                unsetCustomerNoaSentHandler,
                discloseCustomerHandler,
                undiscloseCustomerHandler,
                insureCustomerHandler,
                uninsureCustomerHandler,
                promptCustomerFee,
                isRelatedPartyDebtorHandler,
                isNotRelatedPartyDebtorHandler,
                isGovernmentDebtorHandler,
                isNotGovernmentDebtorHandler,
                isMajorCorporationDebtorHandler,
                isNotMajorCorporationDebtorHandler,
                checkIcon,
                updateEarlypayStatusHandler
            }, key: customer.id }));
    })));
};
export default AdminCustomersTableBody;
