import classNames from 'classnames';
import React from 'react';
import StopwatchOutline from '../../components/svg-icons/StopwatchOutline';
import LockClosedOutline from '../../components/svg-icons/LockClosedOutline';
import AnalyticsOutline from '../../components/svg-icons/AnalyticsOutline';
const CreateAccountInfo = ({ title, color = 'white' }) => {
    const mainClass = classNames('feature-display features', {
        '-white-text': color === 'white',
        '-neutral-400-text': color === 'neutral-400'
    });
    const iconClass = classNames('svg-icon icon', {
        '-neutral-0-stroke': color === 'white',
        '-neutral-400-stroke': color === 'neutral-400'
    });
    return (React.createElement("div", { className: mainClass },
        title && React.createElement("div", { className: 'title' }, title),
        React.createElement("div", { className: 'text' }, "To fast-track your cash flow, simply sync your accounting software with our platform to create an account."),
        React.createElement("ul", { className: 'list' },
            React.createElement("li", { className: 'feature-item item -stopwatch-outline' },
                React.createElement(StopwatchOutline, { className: iconClass }),
                React.createElement("div", { className: 'content' },
                    React.createElement("b", { className: 'title' }, "Quick and Easy Setup:"),
                    " Syncing lets us view your business information directly, making the whole process faster and easier for you.")),
            React.createElement("li", { className: 'feature-item item -lock-closed-outline' },
                React.createElement(LockClosedOutline, { className: iconClass }),
                React.createElement("div", { className: 'content' },
                    React.createElement("b", { className: 'title' }, "Data Security:"),
                    " Securely syncing your accounting software ensures your information remains safe and confidential.")),
            React.createElement("li", { className: 'feature-item item -analytics-outline' },
                React.createElement(AnalyticsOutline, { className: iconClass }),
                React.createElement("div", { className: 'content' },
                    React.createElement("b", { className: 'title' }, "Streamlined Service:"),
                    " By accessing your real-time data, we provide the most accurate and convenient service tailored to your needs.")))));
};
export default CreateAccountInfo;
