import React from 'react';
import Contact from './Contact';
const setClassname = admin => {
    return !admin ? ' -borrower' : '';
};
const AssignedAdminsList = ({ assignedAdmins, primaryClientManagerId, isAdmin }) => {
    return (React.createElement("div", { className: 'contacts-list' + setClassname(isAdmin) }, assignedAdmins.map((admin, i) => {
        return (React.createElement(Contact, { user: admin, key: i, isAdmin: isAdmin, isPrimary: admin.id === primaryClientManagerId }));
    })));
};
export default AssignedAdminsList;
