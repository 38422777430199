import React, { useState } from 'react';
import classNames from 'classnames';
import FilterPanel from './FilterPanel';
const Filter = ({ handleFilterOptionChange, filterOptions }) => {
    const [isOpened, setIsOpened] = useState(false);
    const togglePanel = () => setIsOpened(!isOpened);
    const buttonClass = classNames('icon-button button', '-primary', '-rectangular', '-margin-double', '-filter', '-text-neutral-0');
    const textClass = classNames('text');
    return (React.createElement("div", { className: 'action-buttons -auto-width toggle-panel -filter-dropdown -assign-borrower', "data-testid": 'assign-borrower-dropdown' },
        React.createElement("button", { className: buttonClass, onClick: togglePanel },
            React.createElement("div", { className: 'icon' }),
            React.createElement("div", { className: textClass }, "Filters")),
        isOpened && React.createElement("div", { className: 'background', onClick: togglePanel }),
        React.createElement(FilterPanel, { ...{
                handleFilterOptionChange,
                isOpened,
                filterOptions
            } })));
};
export default Filter;
