import React, { Fragment } from 'react';
import { formatDate } from '../../utils/formatDate';
const XAxisTick = ({ x, y, payload }) => {
    if (!payload)
        return null;
    return (React.createElement(Fragment, null,
        React.createElement("g", { transform: `translate(${x}, ${y})`, "data-testid": 'x-axis-tick' },
            React.createElement("text", { x: 20, y: 0, dy: 28, textAnchor: 'end', className: 'recharts-text' }, formatDate(payload.value)))));
};
export default XAxisTick;
