import React, { useState } from 'react';
import classNames from 'classnames';
import Panel from './Panel';
const FilterAgedReceivable = ({ debtorsOptions, selectedDebtorsOption, borrowerId, dateOptions, selectedDateOption }) => {
    const [isOpened, setIsOpened] = useState(false);
    const togglePanel = () => setIsOpened(!isOpened);
    const buttonClass = classNames('icon-button button', '-rectangular', '-margin-double', '-border-brand-a-400', '-filter', '-icon-filter-brand-a-400', '-text-brand-a-400');
    return (React.createElement("div", { className: 'process-panel', "data-testid": 'filter-dropdown' },
        React.createElement("a", { href: '#', className: buttonClass, onClick: togglePanel },
            React.createElement("div", { className: 'text' }, "Filter"),
            React.createElement("div", { className: 'icon' })),
        React.createElement(Panel, { ...{
                isOpened,
                debtorsOptions,
                selectedDebtorsOption,
                borrowerId,
                dateOptions,
                selectedDateOption
            } })));
};
export default FilterAgedReceivable;
