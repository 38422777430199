import React from 'react';
import ErrorList from '../shared/ErrorList';
const NonImagePreview = ({ file, fileErrors, index, removeFile }) => {
    const fileUrl = URL.createObjectURL(file);
    return (React.createElement("div", { className: 'preview-item preview -non-image', "data-testid": 'non-image-preview' },
        React.createElement("a", { className: 'display', href: fileUrl, target: '_blank', rel: 'noreferrer' },
            React.createElement("i", { className: 'icon' })),
        React.createElement("div", { className: 'info' },
            React.createElement("div", { className: 'name' }, file.name),
            React.createElement("div", { className: 'actions' },
                React.createElement("a", { href: fileUrl, target: '_blank', rel: 'noreferrer', className: 'view' }, "View"),
                React.createElement("div", { className: 'divider' }),
                React.createElement("button", { type: 'button', className: 'delete', "data-testid": 'delete-button', onClick: () => removeFile(index) }, "Delete")),
            React.createElement(ErrorList, { errors: fileErrors, field: index }))));
};
export default NonImagePreview;
