import React from 'react';
import IconToggle from '../shared/IconToggle';
const AdminCustomerStatementsRow = ({ customer, checkedCustomers, handleCustomerCheckbox }) => {
    const { id, name, receivablesFormatted, isSelected, discloseType, email } = customer;
    const emailExist = email !== '';
    const isConfidential = discloseType === 'confidential';
    return (React.createElement("tr", { "data-testid": 'customer-row' },
        React.createElement("td", { className: 'cell' }, name),
        React.createElement("td", { className: 'cell' }, receivablesFormatted),
        React.createElement("td", { className: 'cell -centered' },
            React.createElement(IconToggle, { onIcon: 'check', offIcon: 'close', isOn: isSelected === true, isToggleable: false })),
        React.createElement("td", { className: 'cell -centered' }, discloseType),
        React.createElement("td", { className: 'cell' }, emailExist ? email : '━'),
        React.createElement("td", { className: 'cell -centered' },
            React.createElement("input", { type: 'checkbox', checked: checkedCustomers.includes(id), onChange: () => handleCustomerCheckbox(id), disabled: !emailExist || isConfidential, "data-testid": 'toggle-customer' }))));
};
export default AdminCustomerStatementsRow;
