import React, { useContext } from 'react';
import CommentPanel from '../../components/comment-panel/CommentPanel';
import ActivitiesContext from '../../contexts/ActivitiesContext';
import { getCommentableId, getCommentableScope, getCommentableType } from '../../utils';
import Action from './Action';
const Activity = ({ event, userAvatarUrl, currentUserId }) => {
    const { attributes } = event;
    const { eventable } = attributes;
    const context = useContext(ActivitiesContext);
    const isAdminFeed = [
        'admin',
        'borrower-scoped-admin',
        'broker-scoped-admin'
    ].includes(context.feedType);
    // TODO: Don't render entire panel if action is null or invalid
    return (React.createElement("div", { className: 'custom-panel event-panel event' },
        eventable ? (React.createElement(Action, { ...{ event } })) : (React.createElement("div", { className: 'placeholder-pane placeholder -activity-error', "data-testid": 'activity-error' },
            React.createElement("div", { className: 'title' }, "Uh oh!"),
            React.createElement("div", { className: 'body' }, "No Activity Found."))),
        React.createElement(CommentPanel, { apiToken: context.apiToken, currentUserId: currentUserId, commentableId: getCommentableId(event), commentableType: getCommentableType(event), scope: getCommentableScope(event, context.feedType), userAvatarUrl: userAvatarUrl || '', itemName: 'comment', feedVariantClasses: ['no-placeholder'], panelVariantClasses: ['activity'], privacySettingEnabled: isAdminFeed, toggleMenuEnabled: isAdminFeed })));
};
export default Activity;
