import classNames from 'classnames';
import React from 'react';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
const EmojiPicker = ({ isActive, direction = 'up', forwardEmojiPickerRef, selectEmoji }) => {
    const emojiPickerClassName = classNames('emoji-picker emojis', {
        '-up': direction === 'up',
        '-down': direction === 'down'
    });
    if (isActive) {
        return (React.createElement("div", { ref: forwardEmojiPickerRef, className: emojiPickerClassName, "data-testid": 'emoji-picker' },
            React.createElement(Picker, { onSelect: selectEmoji, ref: forwardEmojiPickerRef, set: 'twitter', showPreview: false, showSkinTones: false })));
    }
    return null;
};
export default EmojiPicker;
