import classNames from 'classnames';
import React from 'react';
import AvatarWrapper from './AvatarWrapper';
const AvatarUploader = ({ avatarUrl, initialsAvatarUrl, model, removeAvatar, selectedFile, validationErrors, forwardfilePickerRef, showFilePicker, handleClick, handleFileChange, handleRemoveAvatar }) => {
    const selectedFileUrl = () => URL.createObjectURL(selectedFile);
    const filePickerClass = classNames('uploader', { '-shown': showFilePicker });
    return (React.createElement("div", { className: 'avatar-uploader' },
        React.createElement(AvatarWrapper, { ...{
                initialsAvatarUrl,
                removeAvatar,
                handleClick,
                ...(selectedFile && { selectedFileUrl: selectedFileUrl() })
            }, currentAvatarUrl: avatarUrl }),
        React.createElement("label", { className: 'remove' },
            React.createElement("input", { type: 'checkbox', className: 'checkbox', name: `${model}[remove_avatar]`, onChange: handleRemoveAvatar, "data-testid": 'remove-checkbox' }),
            "Remove Avatar"),
        validationErrors.avatar && (React.createElement("div", { className: 'errors', "data-testid": 'errors' }, validationErrors.avatar.map((error, i) => (React.createElement("div", { className: 'form-field-error -box', "data-testid": 'form-field-error', key: i }, error))))),
        React.createElement("div", { className: filePickerClass, "data-testid": 'file-picker' },
            React.createElement("input", { type: 'file', name: `${model}[avatar]`, ref: forwardfilePickerRef, onChange: handleFileChange, "data-testid": 'file-picker-input' }),
            React.createElement("input", { type: 'hidden', name: `${model}[avatar_cache]` }))));
};
export default AvatarUploader;
