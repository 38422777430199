import React from 'react';
import { isImageUrl } from '../../utils';
import AttachmentBox from '../attachment-box/AttachmentBox';
import AttachmentThumb from './AttachmentThumb';
const GalleryItem = ({ attachment, collapseItems = false, index, inNextRows = false, collapsedItemsCount = 0 }) => {
    const { file: { url } } = attachment;
    if (isImageUrl(url)) {
        return (React.createElement(AttachmentThumb, { ...{
                attachment,
                collapseItems,
                index,
                inNextRows,
                collapsedItemsCount
            } }));
    }
    else {
        return React.createElement(AttachmentBox, { ...{ attachment } });
    }
};
export default GalleryItem;
