import React from 'react';
import ErrorList from '../shared/ErrorList';
const PaymentDetails = ({ index, currentPaymentDetails, paymentDetailsErrors, handlePaymentDetailUpdate, handleConfirmPaymentDetailsToggle }) => {
    const { accountName, nameOfBank, accountNumber, bsb, isConfirmed } = currentPaymentDetails;
    return (React.createElement("div", { className: 'form-section section', "data-testid": 'payment-details' },
        React.createElement("div", { className: 'heading' },
            React.createElement("h1", { className: 'title2' }, "Payment Details")),
        React.createElement("div", { className: 'body' },
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { htmlFor: 'payment-account-name', className: 'label -uppercase -required' }, "Account Name"),
                React.createElement("input", { className: 'input form-control', maxLength: 100, size: 50, value: accountName, name: `payment_details[${index}][account_name]`, onChange: handlePaymentDetailUpdate, id: 'payment-account-name', "data-testid": 'payment-account-name' }),
                React.createElement(ErrorList, { errors: paymentDetailsErrors, field: 'account_name' })),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { htmlFor: 'payment-name-of-bank', className: 'label -uppercase -required' }, "Name of Bank"),
                React.createElement("input", { className: 'input form-control', maxLength: 100, size: 50, value: nameOfBank, name: `payment_details[${index}][name_of_bank]`, onChange: handlePaymentDetailUpdate, id: 'payment-name-of-bank', "data-testid": 'payment-name-of-bank' }),
                React.createElement(ErrorList, { errors: paymentDetailsErrors, field: 'name_of_bank' })),
            React.createElement("div", { className: 'form-row -col-2' },
                React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { htmlFor: 'payment-account-number', className: 'label -uppercase -required' }, "Account Number"),
                    React.createElement("input", { className: 'input form-control', maxLength: 100, value: accountNumber, name: `payment_details[${index}][account_number]`, onChange: handlePaymentDetailUpdate, id: 'payment-account-number', "data-testid": 'payment-account-number' }),
                    React.createElement(ErrorList, { errors: paymentDetailsErrors, field: 'account_number' })),
                React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { htmlFor: 'payment-bsb', className: 'label -uppercase -required' }, "BSB / SWIFT"),
                    React.createElement("input", { className: 'input form-control', maxLength: 100, value: bsb, name: `payment_details[${index}][bsb]`, onChange: handlePaymentDetailUpdate, id: 'payment-bsb', "data-testid": 'payment-bsb' }),
                    React.createElement(ErrorList, { errors: paymentDetailsErrors, field: 'bsb' }))),
            React.createElement("div", { className: 'form-row' },
                React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { className: 'remove' },
                        React.createElement("input", { type: 'checkbox', className: 'checkbox', name: `payment_details[${index}][is_confirmed]`, checked: isConfirmed, onChange: handleConfirmPaymentDetailsToggle, "data-testid": 'confirmation-checkbox' }),
                        ' ',
                        "I can confirm that the payment details are correct."),
                    React.createElement(ErrorList, { errors: paymentDetailsErrors, field: 'is_confirmed' }))))));
};
export default PaymentDetails;
