import React from 'react';
import { addressType, completeAddress } from '../../utils/contactUtils';
const AddressList = ({ setAction, addresses, handleEditButton, path, currentPrimaryAddress, isChangingPrimaryAddress, setIsChangingPrimaryAddress, selectedPrimaryAddress, setSelectedPrimaryAddress }) => {
    const selectPrimaryPath = selectedPrimaryAddress
        ? `${path}/${selectedPrimaryAddress.id}/select_primary`
        : null;
    if (addresses.length === 0) {
        return (React.createElement("div", { className: 'content custom-panel' },
            React.createElement("div", { className: 'header' }, "Addresses"),
            React.createElement("div", { className: 'address-item address' }, "No address")));
    }
    return (React.createElement("div", { className: 'content custom-panel', "data-testid": 'address-list' },
        React.createElement("div", { className: 'header -space-between' },
            "Addresses",
            !isChangingPrimaryAddress && (React.createElement("button", { className: 'button -main -transparent -no-border -no-hover', onClick: () => setIsChangingPrimaryAddress(true), "data-testid": 'change-primary-button' }, "Change Primary Address"))),
        React.createElement("div", { className: 'address-list addressses' },
            addresses.map((address, key) => (React.createElement("div", { className: 'address-item address', key: key },
                React.createElement("label", { htmlFor: `is_primary_address_${key}`, className: 'text' }, completeAddress(address)),
                React.createElement("div", { className: 'kind' },
                    addressType(address.kind),
                    ' ',
                    address.isPrimaryAddress && (React.createElement(React.Fragment, null,
                        React.createElement("span", { className: 'spacer' }),
                        " Primary",
                        ' '))),
                React.createElement("div", { className: 'controls action-buttons' }, isChangingPrimaryAddress ? (React.createElement("input", { className: 'radio', name: 'is_primary_address', type: 'radio', defaultChecked: !!selectedPrimaryAddress &&
                        selectedPrimaryAddress.id === address.id, value: address.id, onClick: () => setSelectedPrimaryAddress(address) })) : (React.createElement("button", { onClick: () => {
                        setAction('edit');
                        handleEditButton(address.id);
                    }, className: 'button -default -transparent -no-hover' }, "Edit")))))),
            isChangingPrimaryAddress && (React.createElement("div", { className: 'action-buttons -padding', "data-testid": 'change-primary-address-actions' },
                React.createElement("button", { onClick: () => {
                        setSelectedPrimaryAddress(currentPrimaryAddress);
                        setIsChangingPrimaryAddress(false);
                    }, className: 'custom-button -cancel -narrow -margin-right-8px' }, "Cancel"),
                selectPrimaryPath ? (!!selectedPrimaryAddress &&
                    !!currentPrimaryAddress &&
                    selectedPrimaryAddress.id === currentPrimaryAddress.id ? (React.createElement("button", { className: 'custom-button -disabled -narrow', disabled: true, "data-testid": 'submit-disabled' }, "Submit")) : (React.createElement("a", { href: selectPrimaryPath, "data-method": 'put', className: 'custom-button -default -narrow', "data-testid": 'submit-primary-address' }, "Submit"))) : (React.createElement("button", { className: 'custom-button -disabled -narrow', disabled: true, "data-testid": 'submit-disabled' }, "Submit")))))));
};
export default AddressList;
