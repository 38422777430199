import React from 'react';
import { numberToPercentageFixed } from '../../utils/numberToPercentage';
import ProcessingBadge from './ProcessingBadge';
const Availability = ({ funding, isDebtorsLedgerRecalculating }) => {
    const advanceRateFormatted = `${numberToPercentageFixed(funding.advanceRate)}%`;
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'ledger-summary' },
        React.createElement("div", { className: 'header' }, "Availability"),
        React.createElement("table", { className: 'custom-table' },
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Approved Ledger"),
                    isDebtorsLedgerRecalculating ? (React.createElement(ProcessingBadge, null)) : (React.createElement("td", { className: 'value' }, funding.approvedLedgerFormatted))),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Advance Rate"),
                    React.createElement("td", { className: 'value' }, advanceRateFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "LESS: Debtor Advance Rate Adjustment"),
                    isDebtorsLedgerRecalculating ? (React.createElement(ProcessingBadge, null)) : (React.createElement("td", { className: 'value' }, funding.debtorAdvanceRateAdjustmentFormatted))),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical -right -neutral-900' }, "Maximum Permitted Funding"),
                    isDebtorsLedgerRecalculating ? (React.createElement(ProcessingBadge, null)) : (React.createElement("td", { className: 'value' }, funding.maximumPermittedFundingFormatted))),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }),
                    React.createElement("td", { className: 'value' })),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical -neutral-900' }, "Client Account"),
                    React.createElement("td", { className: 'value' })),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "LESS: Funds in Use"),
                    React.createElement("td", { className: 'value' }, funding.fundsInUseFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "LESS: Accrued Fees & Interest"),
                    React.createElement("td", { className: 'value' }, funding.accruedFeesAndInterestFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "PLUS: Surplus Funds"),
                    React.createElement("td", { className: 'value' }, funding.surplusFundsFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical -right -neutral-900' }, "Total Balance Outstanding"),
                    React.createElement("td", { className: 'value' }, funding.totalBalanceOutstandingFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }),
                    React.createElement("td", { className: 'value' })),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "LESS: Availability Reduction"),
                    React.createElement("td", { className: 'value' }, funding.availabilityReductionFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "LESS: Facility Limit Excess"),
                    React.createElement("td", { className: 'value' }, funding.facilityLimitExcessFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical -right -neutral-900' }, "Available Funds"),
                    isDebtorsLedgerRecalculating ? (React.createElement(ProcessingBadge, null)) : (React.createElement("td", { className: 'value' }, funding.availableFundsFormatted))),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }),
                    React.createElement("td", { className: 'value' })),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Facility Limit"),
                    React.createElement("td", { className: 'value' }, funding.facilityLimitFormatted))))));
};
export default Availability;
