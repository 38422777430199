import React, { useEffect, useReducer, useState } from 'react';
import { updateTradeLoan as defaultUpdateTradeLoan } from '../../api/trade_loan';
import { showNotifyToast as defaultShowNotifyToast, snakeToCamelCase } from '../../utils';
import { isAPIValidationErrors, isAPIErrors } from '../../../types';
import { getBracketValues, findOptionByValue } from '../../utils';
import { convertCents, convertCentsString } from '../../utils/convertCents';
import LoanApplication from '../../components/trade-loan/LoanApplication';
import SpinnerButton from '../../components/shared/SpinnerButton';
import TradeLoanDetailsReducer from '../../reducers/trade-loans/TradeLoanDetailsReducer';
import { format } from 'date-fns';
const TradeLoanEditDashboard = ({ apiToken, urlPath, tradeLoanId, initialTradeLoanDetails, currencyOptions, isAdmin = true, loanTermOptions, updateTradeLoan = defaultUpdateTradeLoan, showNotifyToast = defaultShowNotifyToast }) => {
    const initialState = {
        tradeLoan: initialTradeLoanDetails,
        amountValues: {
            totalAmountCents: {
                floatValue: convertCents(initialTradeLoanDetails.totalAmountCents),
                value: convertCentsString(initialTradeLoanDetails.totalAmountCents),
                formattedValue: ''
            }
        },
        tradeLoanErrors: {},
        documentErrors: {},
        selectedPaymentCurrencyOption: findOptionByValue(currencyOptions, initialTradeLoanDetails.totalAmountCurrency)
    };
    const [isSaving, setIsSaving] = useState(false);
    const [tradeLoanState, dispatch] = useReducer(TradeLoanDetailsReducer, initialState);
    const { tradeLoan, amountValues, tradeLoanErrors } = tradeLoanState;
    const { maximumLoanTerm } = tradeLoan;
    useEffect(() => {
        if (isAdmin && tradeLoan.status === 'Processed') {
            const processedDate = new Date(tradeLoan.processedAt);
            const updatedDueDate = format(new Date(processedDate.setDate(processedDate.getDate() + +maximumLoanTerm)), 'yyyy-MM-dd');
            dispatch({
                type: 'UPDATE_TRADE_LOAN_DETAIL',
                fieldName: 'dueDate',
                value: updatedDueDate
            });
        }
    }, [maximumLoanTerm]);
    const handleTradeLoanDetailUpdate = (event) => {
        const targetName = event.target['name'];
        const [fieldName] = getBracketValues(targetName);
        const value = event.target['value'];
        dispatch({
            type: 'UPDATE_TRADE_LOAN_DETAIL',
            fieldName: snakeToCamelCase(fieldName),
            value
        });
    };
    const handleTradeLoanDateChange = (selectedDates, dateString, instance) => {
        const targetName = instance.input.name;
        const [fieldName] = getBracketValues(targetName);
        dispatch({
            type: 'UPDATE_TRADE_LOAN_DETAIL',
            fieldName: snakeToCamelCase(fieldName),
            value: dateString
        });
    };
    const handleTradeLoanPaymentCurrencyUpdate = (selectedOption) => {
        dispatch({
            type: 'UPDATE_CURRENCY_FIELD',
            fieldName: 'totalAmountCurrency',
            selectedOptionFieldName: 'selectedPaymentCurrencyOption',
            option: selectedOption
        });
    };
    const handleMaximumLoanTermUpdate = value => {
        dispatch({
            type: 'UPDATE_MAXIMUM_LOAN_TERM_FIELD',
            fieldName: 'maximumLoanTerm',
            value: value
        });
    };
    const tradeLoanDateSettings = {
        altInput: true,
        altFormat: 'm/d/Y',
        dateFormat: 'Y-m-d',
        onChange: handleTradeLoanDateChange
    };
    const handleTotalAmountChange = values => {
        dispatch({
            type: 'UPDATE_AMOUNT',
            fieldName: 'totalAmountCents',
            centsAmount: Math.round(values.floatValue * 100) || 0,
            values
        });
    };
    const saveTradeLoan = async () => {
        setIsSaving(true);
        const body = {
            tradeLoanDetails: tradeLoan
        };
        try {
            const response = await updateTradeLoan(apiToken, tradeLoanId, body, urlPath);
            if (isAPIValidationErrors(response)) {
                dispatch({
                    type: 'UPDATE_TRADE_LOAN_ERRORS',
                    errors: response.errors
                });
                showNotifyToast({
                    text: 'Please check if all fields are filled out correctly.',
                    type: 'error'
                });
            }
            else if (isAPIErrors(response)) {
                throw response.errors;
            }
            else {
                dispatch({
                    type: 'UPDATE_TRADE_LOAN_ERRORS',
                    errors: {}
                });
                dispatch({
                    type: 'REFRESH_TRADE_LOAN',
                    tradeLoanDetails: response.data.attributes
                });
                showNotifyToast({
                    text: 'Successfully updated loan details.',
                    type: 'success'
                });
            }
        }
        catch {
            showNotifyToast({
                text: 'Failed to update loan details. Please try again.',
                type: 'error'
            });
        }
        setIsSaving(false);
    };
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'trade-loan-edit-dashboard' },
        React.createElement("div", { className: 'header' }, "Loan Details"),
        React.createElement("div", { className: 'body -padded' },
            React.createElement(LoanApplication, { currentTradeLoanDetails: tradeLoan, currentTotalAmount: amountValues.totalAmountCents, currencyOptions: currencyOptions, tradeLoanErrors: tradeLoanErrors, handleTradeLoanDetailUpdate: handleTradeLoanDetailUpdate, handleTradeLoanPaymentCurrencyUpdate: handleTradeLoanPaymentCurrencyUpdate, handleMaximumLoanTermUpdate: handleMaximumLoanTermUpdate, handleTotalAmountChange: handleTotalAmountChange, dateOfShippingSettings: tradeLoanDateSettings, dueDateSettings: tradeLoanDateSettings, isOutsideContainer: false, isAdmin: isAdmin, loanTermOptions: loanTermOptions }),
            React.createElement("div", { className: 'actions' },
                React.createElement(SpinnerButton, { text: 'Save', className: 'custom-button button -trade-loan-save', handleClick: () => saveTradeLoan(), isLoading: isSaving, testId: 'save-trade-loan' })))));
};
export default TradeLoanEditDashboard;
