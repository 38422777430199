import classNames from 'classnames';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { formatPrice } from '../../utils/formatPrice';
import PaymentsTooltip from './PaymentsTooltip';
import InvoicesTooltip from './InvoicesTooltip';
const Cell = ({ value, format, bg = 'default', title = '', currency = 'AUD', tooltipClassVariant = '', payments = [], invoices = [] }) => {
    const cellRef = useRef(null);
    const [top, setTop] = useState(0);
    useLayoutEffect(() => {
        if (cellRef.current !== null) {
            setTop(cellRef.current.offsetTop - 30);
        }
    }, []);
    const tooltipStyle = {
        top: `${top}px`
    };
    const showPaymentsTooltip = payments.length > 0;
    const showInvoicesTooltip = invoices.length > 0;
    const cellClass = classNames('cell', {
        '-pointer': showPaymentsTooltip || showInvoicesTooltip,
        '-heading': bg === 'header',
        '-lighten': value === 0
    });
    return (React.createElement("div", { className: cellClass, "data-testid": 'cell', ref: cellRef },
        format === 'price'
            ? formatPrice(value)
            : format === 'percent'
                ? `${value}%`
                : value,
        showPaymentsTooltip && (React.createElement(PaymentsTooltip, { classVariant: tooltipClassVariant, currency: currency, title: title, payments: payments, total: value, style: tooltipStyle })),
        showInvoicesTooltip && (React.createElement(InvoicesTooltip, { classVariant: tooltipClassVariant, currency: currency, title: title, invoices: invoices, total: value, style: tooltipStyle }))));
};
export default Cell;
