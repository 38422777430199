import React, { useEffect, useState } from 'react';
import { fetchRecoveryCode } from '../../api/two_factor';
import { showNotifyToast } from '../../utils/notifyToast';
const RecoveryCode = ({ apiToken }) => {
    const [recoveryCode, setRecoveryCode] = useState('');
    const fetchCodes = async () => {
        try {
            const { code } = await fetchRecoveryCode(apiToken);
            // We only generate 1 recovery code for now
            setRecoveryCode(code);
        }
        catch (err) {
            showNotifyToast({
                text: '2FA service failed to retrieve OTP recovery code.',
                type: 'error'
            });
        }
    };
    const handleCopy = () => {
        const copyInput = document.querySelector('#recovery-code');
        copyInput && copyInput.select();
        document.execCommand('copy');
        showNotifyToast({
            text: 'Recovery code copied.',
            type: 'info'
        });
    };
    useEffect(() => {
        fetchCodes();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("br", null),
        React.createElement("p", null, "If you lose your phone, this code is your failsafe to access your account. You can enter the recovery code to access your account, instead of the 6-digit code we would usually send via SMS."),
        React.createElement("br", null),
        React.createElement("div", { className: 'notice-panel -warning -no-padding', "data-testid": 'recovery-code-note' },
            React.createElement("div", { className: 'body -light' },
                React.createElement("i", { className: 'custom-icon -warning -orange' }),
                "Note: Save it somewhere safe and accessible as this will act as your last resort for gaining access to this account.")),
        React.createElement("br", null),
        React.createElement("b", null, "Recovery Code"),
        React.createElement("div", { className: 'form-group -inline', "data-testid": 'recovery-code-input' },
            React.createElement("input", { id: 'recovery-code', type: 'text', className: 'input form-control -medium', value: recoveryCode, readOnly: true }),
            React.createElement("button", { className: 'custom-button -default -header -notice -brand-a-300-text', onClick: handleCopy },
                "Copy ",
                React.createElement("i", { className: 'custom-icon -copy' })))));
};
export default RecoveryCode;
