import React, { useState, useEffect } from 'react';
import { fetchBorrowerAssignableAdmins } from '../../api/user';
import { fetchBorrowerCompanyAssignedAdmins, fetchBorrowerPrimaryClientManager, setBorrowerCompanyAssignedAdmins } from '../../api/company';
import { humanizeString, showNotifyToast } from '../../utils';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import AdminSelect from './AdminSelect';
import AssignedAdminsList from './AssignedAdminsList';
const AssignedAdminsPanel = ({ apiToken, borrowerId }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [admins, setAdmins] = useState([]);
    const [primaryClientManager, setPrimaryClientManager] = useState();
    const [selectedAdminIds, setSelectedAdminIds] = useState([]);
    const [assignedAdmins, setAssignedAdmins] = useState([]);
    const [options, setOptions] = useState([]);
    const [values, setValues] = useState([]);
    const [primaryValue, setPrimaryValue] = useState();
    const fetchData = async () => {
        try {
            const { data: adminData } = await fetchBorrowerAssignableAdmins(apiToken);
            setAdmins(adminData.map(admin => admin.attributes));
            const { data: assignedAdminData } = await fetchBorrowerCompanyAssignedAdmins(apiToken, borrowerId);
            setAssignedAdmins(assignedAdminData.map(admin => admin.attributes));
            setSelectedAdminIds(assignedAdminData.map(admin => admin.attributes.id));
            const primaryAdminDataResponse = await fetchBorrowerPrimaryClientManager(apiToken, borrowerId);
            if (primaryAdminDataResponse['data']) {
                setPrimaryClientManager(primaryAdminDataResponse['data']['attributes']);
            }
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong while fetching admins. Please try again.',
                type: 'error'
            });
        }
        setIsLoading(false);
    };
    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        setOptions(admins.map((admin) => {
            const roleString = admin.adminRole
                ? ` - ${humanizeString(admin.adminRole)}`
                : '';
            return {
                value: admin.id,
                label: `${admin.firstName} ${admin.lastName}${roleString}`
            };
        }));
        setValues(assignedAdmins.map((admin) => {
            const roleString = admin.adminRole
                ? ` - ${humanizeString(admin.adminRole)}`
                : '';
            return {
                value: admin.id,
                label: `${admin.firstName} ${admin.lastName}${roleString}`
            };
        }));
    }, [admins, assignedAdmins]);
    useEffect(() => {
        if (primaryClientManager) {
            setPrimaryValue(options.find((option) => option.value === primaryClientManager.id) || null);
        }
        else {
            setPrimaryValue(null);
        }
    }, [options, primaryClientManager]);
    const handleEdit = () => {
        setIsEditing(true);
    };
    const handleCancel = () => {
        setSelectedAdminIds(assignedAdmins.map((admin) => admin.id));
        setIsEditing(false);
    };
    const handleUpdate = async () => {
        setIsLoading(true);
        setIsEditing(false);
        const selectedPrimaryAdminId = primaryValue ? primaryValue.value : null;
        try {
            const { data } = await setBorrowerCompanyAssignedAdmins(apiToken, borrowerId, selectedAdminIds, selectedPrimaryAdminId);
            setAssignedAdmins(data.map(admin => admin.attributes));
            const primaryAdminDataResponse = await fetchBorrowerPrimaryClientManager(apiToken, borrowerId);
            if (primaryAdminDataResponse['data']) {
                setPrimaryClientManager(primaryAdminDataResponse['data']['attributes']);
            }
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong while assigning admins. Please try again.',
                type: 'error'
            });
        }
        setIsLoading(false);
    };
    const handleChange = options => {
        setSelectedAdminIds(options === null ? [] : options.map(o => o.value));
    };
    const handlePrimaryChange = (selectedOption) => {
        setPrimaryValue(options.find((option) => option.value === parseInt(selectedOption.value)) || null);
    };
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'assigned-admins-panel' },
        React.createElement("div", { className: 'header -space-between' },
            "Assigned Admins",
            isEditing || (React.createElement("a", { href: '#', onClick: handleEdit, "data-testid": 'edit-action' }, "Edit"))),
        isEditing ||
            (isLoading ? (React.createElement(LoadingSpinner, { className: '-small' })) : (React.createElement(AssignedAdminsList, { assignedAdmins: assignedAdmins, primaryClientManagerId: primaryValue && primaryValue.value, isAdmin: true }))),
        isEditing && (React.createElement(AdminSelect, { ...{
                options,
                values,
                handleChange,
                handleCancel,
                handleUpdate,
                handlePrimaryChange,
                primaryValue
            } }))));
};
export default AssignedAdminsPanel;
