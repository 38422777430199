export const getParamsMap = (paramString) => {
    // remove initial separator e.g. '?type=requested'
    const params = paramString.replace('?', '');
    return params.split('&').reduce((acc, p) => {
        // get key-value from param e.g. 'page=1'
        const [key, value] = p.split('=');
        return {
            ...acc,
            [key]: value
        };
    }, {});
};
export const buildUrlQuery = (paramObj, baseUrl) => {
    const queryParams = new URLSearchParams(paramObj).toString();
    return [baseUrl, queryParams].join('?');
};
