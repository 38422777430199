import React, { Component } from 'react';
import Select from 'react-select';
class Dropdown extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            selectedOption: null,
            value: ''
        };
        this.handleChange = selectedOption => {
            this.setState({
                selectedOption,
                value: selectedOption.value
            });
        };
    }
    render() {
        const { model, attribute, options } = this.props;
        const { selectedOption, value } = this.state;
        return (React.createElement("div", { className: 'dropdown-select dropdown', "data-testid": 'file-upload-dropdown' },
            React.createElement(Select, { options: options, placeholder: 'Select File Type', value: selectedOption, onChange: this.handleChange, className: this.props.className }),
            React.createElement("input", { type: 'hidden', name: `${model}[${attribute}]`, value: value, "data-testid": 'dropdown' })));
    }
}
export default Dropdown;
