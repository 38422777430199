import React from 'react';
const AdminClientRow = ({ client }) => {
    const { id, displayedName } = client;
    const clientShowPath = `${window.location.pathname}/${id}`;
    return (React.createElement("tr", { "data-testid": 'admin-client-row' },
        React.createElement("td", { className: 'cell' }, displayedName),
        React.createElement("td", { className: 'cell' },
            React.createElement("a", { href: clientShowPath }, "View"))));
};
export default AdminClientRow;
