import fetch from 'isomorphic-fetch';
export const fetchAdminNotifications = async (apiToken) => {
    const endpoint = '/api/admin/notifications';
    return fetch(endpoint, {
        headers: {
            Authorization: apiToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((res) => res.json());
};
export const fetchBorrowerNotifications = async (apiToken) => {
    const endpoint = '/api/borrower/notifications';
    return fetch(endpoint, {
        headers: {
            Authorization: apiToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((res) => res.json());
};
export const markBorrowerNotificationsAsRead = async (apiToken) => {
    const endpoint = '/api/borrower/notifications/mark_all_as_read';
    return fetch(endpoint, {
        method: 'POST',
        headers: {
            Authorization: apiToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((res) => res.json());
};
export const markAdminNotificationsAsRead = async (apiToken) => {
    const endpoint = '/api/admin/notifications/mark_all_as_read';
    return fetch(endpoint, {
        method: 'POST',
        headers: {
            Authorization: apiToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((res) => res.json());
};
