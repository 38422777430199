import React from 'react';
import classNames from 'classnames';
import Modal from '../../shared/Modal';
const VerifyBulkInvoices = ({ csrfToken, selectedIdString, selectedInvoices, isModalOpenForVerify, openModalForVerify, closeModalForVerify, handleBulkVerifyInvoices }) => {
    const panelClass = classNames('panel', {
        '-active': isModalOpenForVerify
    });
    const handleSubmit = event => {
        event.preventDefault();
        handleBulkVerifyInvoices();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { className: 'button -dropdown', onClick: () => openModalForVerify(), "data-testid": 'verify-bulk-invoices', type: 'button' }, "Mark as verified"),
        React.createElement(Modal, { isOpen: isModalOpenForVerify, onClose: closeModalForVerify },
            React.createElement("form", { method: 'post', onSubmit: handleSubmit, className: panelClass, "data-testid": 'modal' },
                React.createElement("div", { className: 'modal' },
                    React.createElement("div", { className: 'custom-panel -modal' },
                        React.createElement("div", { className: 'header' }, "Confirmation"),
                        React.createElement("div", { className: 'content custom-scrollbar' },
                            React.createElement("div", { className: 'section -list' },
                                React.createElement("p", null, "Summary of Invoices that will be verified."),
                                React.createElement("p", null, "Are you sure you want to do it?"),
                                React.createElement("input", { type: 'hidden', name: 'invoices_ids', value: selectedIdString }),
                                React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
                                React.createElement("ul", { className: 'description -list' }, selectedInvoices.map((i, index) => (React.createElement("li", { key: index }, `#${i.number} - ${i.customerName}`))))),
                            React.createElement("div", { className: 'footer -borderless' },
                                React.createElement("div", { className: 'action-buttons -space-between' },
                                    React.createElement("button", { className: 'button -danger', onClick: () => closeModalForVerify(), type: 'button' }, "Cancel"),
                                    React.createElement("button", { className: 'button -primary', type: 'button', onClick: handleSubmit }, "Yes, I am sure"))))),
                    React.createElement("div", { className: 'modal-overlay' }))))));
};
export default VerifyBulkInvoices;
