import fetch from 'isomorphic-fetch';
import { buildHeaders } from './shared';
export const fetchBorrowerAddress = async (apiToken, borrowerId, addressId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/addresses/${addressId}`;
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'GET'
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.map(error => error.title).join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
