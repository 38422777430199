import { isEmptyString } from './isEmptyString';
export const buildCompanyDisplayedName = (name, displayName, useDisplayName) => {
    if (useDisplayName && !isEmptyString(displayName)) {
        return displayName;
    }
    return name;
};
export const buildCompanySubtitle = (name, displayName, useDisplayName) => {
    if (isEmptyString(displayName)) {
        return 'Company Name';
    }
    if (useDisplayName) {
        return name;
    }
    if (!useDisplayName) {
        return displayName;
    }
};
