import React from 'react';
import CompanyName from '../CompanyName';
const CommentOnInvoice = ({ event }) => {
    const { attributes } = event;
    const { createdAtFormatted, companyName, userType, companyLink, invoiceLink, eventable: { attributes: { number: invoiceNumber } } } = attributes;
    const isAdminAuthor = userType === 'admin';
    const authorName = isAdminAuthor ? 'Earlypay' : companyName;
    return (React.createElement("div", { className: 'event-box event', "data-testid": 'comment-on-invoice-activity' },
        React.createElement("div", { className: 'icon' },
            React.createElement("div", { className: 'activity-icon -comment-on-invoice' })),
        React.createElement("div", { className: 'description' },
            React.createElement("div", { className: 'title', "data-testid": 'activity-title' },
                isAdminAuthor ? (`${authorName}`) : (React.createElement(CompanyName, { name: companyName, companyLink: companyLink })),
                ' ',
                "left a comment on invoice",
                React.createElement("span", { className: 'bold' },
                    React.createElement("a", { href: invoiceLink }, ` ${invoiceNumber}`))),
            React.createElement("div", { className: 'date' }, createdAtFormatted))));
};
export default CommentOnInvoice;
