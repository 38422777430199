import React from 'react';
import NumberField from '../shared/NumberField';
import { createAdjustment } from '../../api/adjustment';
const NewAdjustment = ({ customersOptions = [], currentUserId, apiToken, companyId, uploadedInvoicesFileId, reasons = [], onGoBack }) => {
    const handleOnSubmit = async (event) => {
        event.preventDefault();
        const customerElement = event.target.elements['adjustment[customer_id]'];
        const customerId = customerElement.options[customerElement.selectedIndex].value;
        const amount = event.target.elements['adjustment[amount]'].value;
        const note = event.target.elements['adjustment[note]'].value;
        const reasonElement = event.target.elements['adjustment[reason]'];
        const reason = reasonElement.options[reasonElement.selectedIndex].value;
        const adjustmentTypeElement = event.target.elements['adjustment[adjustment_type]'];
        const adjustmentType = adjustmentTypeElement.options[adjustmentTypeElement.selectedIndex].value;
        await createAdjustment(apiToken, companyId, uploadedInvoicesFileId, {
            adjustment: {
                customerId,
                amount,
                reason,
                note,
                adjustmentType,
                createdById: currentUserId
            }
        });
        window.location.reload();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'cash-received-drawer' },
            React.createElement("div", { className: 'actions' },
                React.createElement("button", { className: 'back', onClick: onGoBack }, "Go Back")),
            React.createElement("div", { className: 'header' }, "New Cash Adjustment"),
            React.createElement("form", { onSubmit: handleOnSubmit },
                React.createElement("div", { className: 'form-group' },
                    React.createElement("div", { className: 'label' }, "Customer"),
                    React.createElement("select", { className: 'form-control', name: 'adjustment[customer_id]', id: 'customer_id' }, customersOptions.map(({ label, value }) => (React.createElement("option", { key: value, value: value }, label)))),
                    React.createElement("div", { className: 'label' }, "Leave blank if there's no customer associated with this adjustment")),
                React.createElement("div", { className: 'form-group' },
                    React.createElement("div", { className: 'label' }, "Type"),
                    React.createElement("select", { className: 'form-control', name: 'adjustment[adjustment_type]', required: true, id: 'adjustment_adjustment_type' },
                        React.createElement("option", { value: 'credit' }, "Credit"),
                        React.createElement("option", { value: 'debit' }, "Debit"))),
                React.createElement("div", { className: 'form-group' },
                    React.createElement("div", { className: 'label' }, "Reason"),
                    React.createElement("select", { className: 'form-control', name: 'adjustment[reason]', required: true, id: 'adjustment_reason' }, reasons.map((reason, index) => (React.createElement("option", { key: index, value: reason }, reason))))),
                React.createElement("div", { className: 'form-group -margin-top' },
                    React.createElement("div", { className: 'label' }, "Note"),
                    React.createElement("textarea", { className: 'form-control', name: 'adjustment[note]', id: 'adjustment_note' })),
                React.createElement("div", { className: 'form-group' },
                    React.createElement("div", { className: 'label' }, "Amount"),
                    React.createElement(NumberField, { model: 'adjustment', attribute: 'amount', required: true })),
                React.createElement("div", { className: 'action-buttons' },
                    React.createElement("button", { className: 'button -cancel', onClick: onGoBack }, "Cancel"),
                    React.createElement("button", { className: 'button -primary' }, "Save"))))));
};
export default NewAdjustment;
