import React, { useState, useRef, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import CustomFlatpickr from '../../shared/CustomFlatpickr';
import { buildUrlQuery } from '../../../utils/url';
import { fetchBorrowerAssignableAdmins as defaultAssignableAdmins } from '../../../api/user';
import { humanizeString } from '../../../utils/humanizeString';
import { showNotifyToast } from '../../../utils/notifyToast';
const Panel = ({ isOpened, bankOptions, selectedBank, drawdownOptions, selectedDrawdown, searchKeyword, initialStartDate, initialEndDate, apiToken, adminIds, fetchBorrowerAssignableAdmins = defaultAssignableAdmins }) => {
    const [bankFilter, setBankFilter] = useState(selectedBank || { value: '', label: '' });
    const [drawdownFilter, setDrawdownFilter] = useState(selectedDrawdown || { value: '', label: '' });
    const baseUrl = '/admin/drawdowns';
    const resetUrl = buildUrlQuery({ status: 'processed' }, baseUrl);
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);
    const [admins, setAdmins] = useState([]);
    const [assignedAdmins, setAssignedAdmins] = useState([]);
    const [selectedAdminIds, setSelectedAdminIds] = useState(adminIds === null || adminIds === void 0 ? void 0 : adminIds.split('.'));
    const adminOptions = useMemo(() => {
        return admins.map((admin) => {
            const roleString = admin.adminRole
                ? ` - ${humanizeString(admin.adminRole)}`
                : '';
            return {
                value: admin.id,
                label: `${admin.firstName} ${admin.lastName}${roleString}`
            };
        });
    }, [admins]);
    useEffect(() => {
        fetchAdmins();
    }, []);
    useEffect(() => {
        adminOptions &&
            selectedAdminIds &&
            setAssignedAdmins(adminOptions.filter(o => selectedAdminIds.includes(o.value.toString())));
    }, [selectedAdminIds, adminOptions]);
    const handleAdminChange = options => {
        setSelectedAdminIds(options === null ? [] : options.map(o => o.value.toString()));
    };
    const defaultDateRange = () => {
        if (startDate && endDate) {
            return [startDate, endDate];
        }
        else {
            return [];
        }
    };
    const handleDateChange = (selectedDates, dateString) => {
        setEndDate(dateString.split(' to ')[1]);
        setStartDate(dateString.split(' to ')[0]);
    };
    const flatpickrSettings = {
        wrap: true,
        altInput: true,
        altFormat: 'd M Y',
        dateFormat: 'Y-m-d',
        mode: 'range',
        onChange: handleDateChange,
        defaultDate: defaultDateRange()
    };
    const dateClearRef = useRef(null);
    const datePickerRef = useRef(null);
    const filters = {
        status: 'processed',
        ...(bankFilter.value && { segment_account_bank: bankFilter.value }),
        ...(drawdownFilter.value && { drawdown_type: drawdownFilter.value }),
        ...(startDate && { start_date: startDate }),
        ...(endDate && { end_date: endDate }),
        ...(searchKeyword && { search: searchKeyword }),
        ...(assignedAdmins && {
            assigned_admins: assignedAdmins.map(o => o.value).join('.')
        })
    };
    const filterUrl = buildUrlQuery(filters, baseUrl);
    const panelClass = classNames('panel -filter -drawdown', {
        '-active': isOpened
    });
    const fetchAdmins = async () => {
        try {
            const { data: adminData } = await fetchBorrowerAssignableAdmins(apiToken);
            setAdmins(adminData.map(admin => admin.attributes));
        }
        catch (error) {
            showNotifyToast({
                text: 'Failed to fetch assignable admins.',
                type: 'error'
            });
        }
    };
    return (React.createElement("div", { className: panelClass, "data-testid": 'panel' },
        React.createElement("div", null, "Filter By"),
        React.createElement("div", { className: 'dropdowns' },
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label -uppercase -small', "aria-labelledby": 'segment-account-bank' },
                    React.createElement("div", { className: 'text' }, "Segment Account Bank"),
                    React.createElement(Select, { options: bankOptions, value: bankFilter, onChange: setBankFilter, className: 'select', name: 'segment_account_bank', inputId: 'segment-account-bank' }))),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label -uppercase -small', "aria-labelledby": 'segment-account-bank' },
                    React.createElement("div", { className: 'text' }, "Drawdown Type"),
                    React.createElement(Select, { name: 'drawdown_type', options: drawdownOptions, value: drawdownFilter, onChange: setDrawdownFilter, className: 'select' }))),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label -uppercase -small', "aria-labelledby": 'assigned-admins' },
                    React.createElement("div", { className: 'text' }, "Assigned Admins"),
                    React.createElement(Select, { options: adminOptions, value: assignedAdmins, onChange: handleAdminChange, className: 'select', name: 'assigned_admins', inputId: 'assigned-admins', isMulti: true }))),
            React.createElement("div", { className: 'form-group' },
                React.createElement("div", { className: 'label -uppercase -small' }, "Date Range"),
                React.createElement(CustomFlatpickr, { ...{ flatpickrSettings }, forwardDatePickerRef: datePickerRef, forwardDateClearRef: dateClearRef, selectedDate: '', pickerBackground: 'neutral-20', placeholderText: 'Filter by date', clearButtonText: 'Clear Date', name: 'date_range' }))),
        React.createElement("hr", { className: 'divider' }),
        React.createElement("div", { className: 'action-buttons' },
            React.createElement("a", { className: 'button -border -blue', href: resetUrl }, "Reset"),
            React.createElement("a", { className: 'button -primary ', href: filterUrl }, "Apply Filter"))));
};
export default Panel;
