import classNames from 'classnames';
import React from 'react';
import Textarea from 'react-expanding-textarea';
import SpinnerButton from '../shared/SpinnerButton';
const Description = ({ description, editedText, isArchived, isEditing, isSaving, resolvedAt, forwardEditTextFieldRef, handleEditorClose, handleSave, handleTextChange }) => {
    const descriptionClassName = classNames('time-line-description description', {
        '-archived': isArchived,
        '-resolved': resolvedAt && !isArchived,
        '-editor': isEditing
    });
    if (isEditing) {
        return (React.createElement("div", { className: descriptionClassName, "data-testid": 'editing-description' },
            React.createElement(Textarea, { className: 'input form-control -textarea -edit -noresize', value: editedText, onChange: handleTextChange, ref: forwardEditTextFieldRef, "data-testid": 'textarea' }),
            React.createElement(SpinnerButton, { text: 'Save', isLoading: isSaving, handleClick: handleSave, testId: 'save-button' }),
            React.createElement("button", { className: 'icon-button close -cancel', type: 'button', onClick: handleEditorClose, "data-testid": 'close-button' },
                React.createElement("i", { className: 'icon' }))));
    }
    if (isArchived) {
        return (React.createElement("div", { className: descriptionClassName, "data-testid": 'archived-description' },
            "This activity has been ",
            React.createElement("strong", null, "archived.")));
    }
    return (React.createElement("div", { className: descriptionClassName, "data-testid": 'text-description' }, description));
};
export default Description;
