import React, { useState } from 'react';
import classNames from 'classnames';
import Panel from './Panel';
const AssignBorrowerDropdown = ({ csrfToken, path, selectedIdString, companyOptions, disable }) => {
    const [isOpened, setIsOpened] = useState(false);
    const togglePanel = () => setIsOpened(!isOpened);
    const buttonClass = classNames('icon-button button', '-rectangular', '-margin-double', {
        '-default-cursor -disabled': disable,
        '-secondary -text-secondary': !disable
    });
    const textClass = classNames('text', {
        '-neutral-100-text': disable
    });
    return (React.createElement("div", { className: 'toggle-panel -filter-dropdown -assign-borrower', "data-testid": 'assign-borrower-dropdown' },
        React.createElement("button", { className: buttonClass, onClick: togglePanel, disabled: disable },
            React.createElement("div", { className: textClass }, "Assign Borrower")),
        !disable && (React.createElement(Panel, { ...{
                isOpened,
                csrfToken,
                path,
                selectedIdString,
                companyOptions
            } }))));
};
export default AssignBorrowerDropdown;
