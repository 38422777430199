import React, { useState } from 'react';
import { processReypayments } from '../../api/repayments';
import { showNotifyToast } from '../../utils';
import SpinnerButton from '../../components/shared/SpinnerButton';
const RepaymentsTable = ({ repayments, apiToken, status }) => {
    const [selectedProcess, setSelectedProcess] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);
    const handleChange = ({ target }) => {
        const id = target.value;
        const isSelected = !!selectedProcess[`${id}`];
        setSelectedProcess({
            ...selectedProcess,
            [`${id}`]: !isSelected ? id : null
        });
    };
    const handleProcess = () => {
        const ids = Object.values(selectedProcess).filter(Boolean);
        if (ids.length > 0) {
            if (ids.length === 1 ||
                window.confirm('Do you to want process multiple repayment?')) {
                processPayments(ids);
            }
        }
    };
    const processPayments = (ids) => {
        setIsProcessing(true);
        const token = document.getElementsByName('csrf-token')[0].getAttribute('content') + '';
        processReypayments(apiToken, token, ids)
            .then((data) => {
            if (data.response === 'ok') {
                document.location.href = data.redirectUrl;
            }
        })
            .catch(error => {
            showNotifyToast({ text: error.message, type: 'error' });
            setIsProcessing(false);
        });
    };
    const toggleSelect = ({ target }) => {
        if (target.checked) {
            const ids = repayments.reduce((acc, val) => {
                return { ...acc, [`${val.id}`]: val.id + '' };
            }, {});
            setSelectedProcess(ids);
        }
        else {
            setSelectedProcess({});
        }
    };
    return (React.createElement("table", { className: 'custom-table', "data-testid": 'repayments-table' },
        React.createElement("thead", null,
            React.createElement("tr", { className: 'tall' },
                status === 'new' ? (React.createElement("th", { className: 'header' },
                    React.createElement("input", { type: 'checkbox', onChange: toggleSelect }))) : null,
                React.createElement("th", { className: 'header' }, "ID"),
                React.createElement("th", { className: 'header' }, "Date"),
                React.createElement("th", { className: 'header' }, "Borrower"),
                React.createElement("th", { className: 'header' }, "Payment Count"),
                React.createElement("th", { className: 'header' }, "Amount"),
                React.createElement("th", { className: 'header' }, "Transfer Amount"),
                React.createElement("th", { className: 'header' }, "Segment Bank"),
                React.createElement("th", { className: 'header' }, "Destination Account"),
                React.createElement("th", { className: 'header' }, status === 'new' &&
                    Object.values(selectedProcess).filter(Boolean).length ? (React.createElement("div", { className: 'action-buttons -flexend' },
                    React.createElement(SpinnerButton, { className: 'custom-button button -repayment-bulk-process', handleClick: handleProcess, isDisabled: isProcessing, isLoading: isProcessing, text: 'Process' }))) : null))),
        React.createElement("tbody", null, repayments.map(({ id, createdAt, borrowerName, borrowerPath, paymentsCount, amountFormatted, transferAmountFormatted, segmentAccountBank, destinationAccount }) => {
            return (React.createElement("tr", { className: 'customer-row', key: id },
                status === 'new' ? (React.createElement("td", { className: 'cell' },
                    React.createElement("input", { type: 'checkbox', name: `repayments[${id}]`, id: `repayments_${id}`, onChange: handleChange, value: id, checked: !!selectedProcess[`${id}`] }))) : null,
                React.createElement("td", { className: 'cell' }, id),
                React.createElement("td", { className: 'cell' }, createdAt),
                React.createElement("td", { className: 'cell' },
                    React.createElement("a", { href: borrowerPath }, borrowerName)),
                React.createElement("td", { className: 'cell' }, paymentsCount),
                React.createElement("td", { className: 'cell' }, amountFormatted),
                React.createElement("td", { className: 'cell' }, transferAmountFormatted),
                React.createElement("td", { className: 'cell' }, segmentAccountBank),
                React.createElement("td", { className: 'cell' },
                    React.createElement("span", { dangerouslySetInnerHTML: { __html: destinationAccount } })),
                React.createElement("td", { className: 'cell' },
                    React.createElement("div", { className: 'action-buttons' },
                        React.createElement("a", { className: 'button -neutral -loose', href: `/admin/repayments/${id}` }, "Review"),
                        React.createElement("a", { className: 'button -danger -loose', "data-confirm": 'Are you sure?', rel: 'nofollow', "data-method": 'delete', href: `/admin/repayments/${id}` }, "Reject")))));
        }))));
};
export default RepaymentsTable;
