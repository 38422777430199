import fetch from 'isomorphic-fetch';
import queryString from 'query-string';
export const archiveTradeLoanUnassignedTransactions = async (apiToken, comment, unassignedTransactionIds) => {
    const params = {
        unassigned_transaction_ids: unassignedTransactionIds,
        delete_unassigned_transaction_comment: comment
    };
    const endpoint = `/api/admin/trade_loan_unassigned_transactions/archive_multiple_unassigned_transaction?${queryString.stringify(params)}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => (res.status === 204 ? res : res.json()));
};
