import React, { useState } from 'react';
import ConfirmationPanel from './ConfirmationPanel';
import DownloadPanel from './DownloadPanel';
const DownloadABA = ({ drawdowns, ids, abaPath, singleABA }) => {
    const [abaDownloadStep, setAbaDownloadStep] = useState(1);
    return (React.createElement(React.Fragment, null,
        React.createElement(ConfirmationPanel, { ...{ drawdowns, abaDownloadStep, setAbaDownloadStep } }),
        React.createElement(DownloadPanel, { ...{
                abaPath,
                ids,
                abaDownloadStep,
                singleABA
            } })));
};
export default DownloadABA;
