import React from 'react';
const RepaymentAmountBox = ({ label, amount, classVariant = '' }) => {
    if (amount === '$0.00') {
        return null;
    }
    return (React.createElement("div", { className: 'field -square', "data-testid": 'activity-feed-repayment-amount-box' },
        React.createElement("div", { className: `text ${classVariant}` },
            " ",
            amount,
            " "),
        React.createElement("div", { className: 'label' },
            " ",
            label)));
};
export default RepaymentAmountBox;
