import React from 'react';
const AnzApplicationGuide = ({ anzStep2Url, anzStep4Url }) => {
    return (React.createElement("div", { className: 'custom-panel -collapsible' },
        React.createElement("input", { className: 'toggle', id: 'anz-application-guide-checkbox', "data-testid": 'anz-application-guide-checkbox', type: 'checkbox', defaultChecked: true }),
        React.createElement("label", { className: 'header -space-between -subheader -border', htmlFor: 'anz-application-guide-checkbox' }, "How to apply Bank Feed via ANZ internet banking?"),
        React.createElement("div", { className: 'body -neutral -section' },
            React.createElement("ol", { className: 'content-list -number' },
                React.createElement("li", { className: 'item -left' }, "Start AccountRight."),
                React.createElement("li", { className: 'item -left' },
                    "Go to the ",
                    React.createElement("b", null, "Banking"),
                    " command centre and click ",
                    React.createElement("b", null, "Bank Feeds"),
                    ".",
                    React.createElement("ol", { className: 'content-list -alpha' },
                        React.createElement("li", { className: 'item -left' },
                            "The ",
                            React.createElement("b", null, "Bank Feeds"),
                            " window appears. Click",
                            ' ',
                            React.createElement("b", null, "Manage Bank Accounts"),
                            ".",
                            React.createElement("img", { className: 'image -small', src: anzStep2Url })),
                        React.createElement("li", { className: 'item -left' },
                            "When the ",
                            React.createElement("b", null, "Manage Bank Accounts"),
                            " window appears, click",
                            ' ',
                            React.createElement("b", null, "add or remove a bank account"),
                            ". An internet browser window opens and the ",
                            React.createElement("b", null, "MYOB Sign In"),
                            " window appears."),
                        React.createElement("li", { className: 'item -left' }, "If prompted, sign in with your MYOB account details (Email Address must be lowercase)."),
                        React.createElement("li", { className: 'item -left' }, "If you have multiple MYOB products or company files, select the applicable product and company file."))),
                React.createElement("li", { className: 'item -left' },
                    "Choose your company file from the",
                    ' ',
                    React.createElement("b", null, "set up bank feed for this company file"),
                    " list."),
                React.createElement("li", { className: 'item -left' },
                    "Click ",
                    React.createElement("b", null, "Add more bank accounts"),
                    " or ",
                    React.createElement("b", null, "Add more credit cards"),
                    ".",
                    React.createElement("img", { className: 'image -medium', src: anzStep4Url })),
                React.createElement("li", { className: 'item -left' },
                    "Select ",
                    React.createElement("b", null, "ANZ"),
                    " from the list of financial institutions."),
                React.createElement("li", { className: 'item -left' },
                    "Choose the option to continue the bank feed application online and then select the ",
                    React.createElement("b", null, "I confirm"),
                    "."),
                React.createElement("li", { className: 'item -left' },
                    "Click ",
                    React.createElement("b", null, "Submit"),
                    ". You'll be provided an 8-digit code which is your Bank Feed Reference Number."),
                React.createElement("li", { className: 'item -left' }, "Enter the provided 8-digit code (Bank Feed Reference Number) above.")))));
};
export default AnzApplicationGuide;
