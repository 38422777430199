import React, { useEffect, useRef, useState } from 'react';
import { fetchBorrowerSearchResults as defaultFetcher } from '../../api/search';
import SearchBar from '../../components/search-bar/SearchBar';
import SearchResults from '../../components/search-bar/SearchResults';
import useOutsideClick from '../../hooks/useOutsideClick';
const BorrowerSearchPanel = ({ apiToken, companyId, fetchBorrowerSearchResults = defaultFetcher }) => {
    const [query, setQuery] = useState('');
    const [hits, setHits] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const searchBarRef = useRef(null);
    const searchInputRef = useRef(null);
    const searchPanelRef = useRef(null);
    const searchActive = query && query.length > 3;
    useEffect(() => {
        loadSearchResults();
    }, [query]);
    useOutsideClick(searchPanelRef, () => {
        if (query.length > 0) {
            resetPanel();
        }
    });
    const loadSearchResults = async () => {
        if (searchActive) {
            setHasErrors(false);
            setIsLoading(true);
            try {
                setHits(await fetchBorrowerSearchResults(apiToken, query, companyId));
                setIsLoading(false);
            }
            catch (err) {
                setHasErrors(true);
                setIsLoading(false);
            }
        }
        else {
            setHasErrors(false);
            setHits([]);
        }
    };
    const handleSearchQueryChange = (event) => {
        event.preventDefault();
        setQuery(event.currentTarget.value);
    };
    const handleSearchBarBlur = (event) => {
        event.preventDefault();
        if (searchBarRef && searchBarRef.current) {
            const { current } = searchBarRef;
            current.classList.remove('-focused');
        }
    };
    const handleSearchBarFocus = (event) => {
        event.preventDefault();
        if (searchBarRef && searchBarRef.current) {
            const { current } = searchBarRef;
            current.classList.add('-focused');
        }
    };
    const resetPanel = () => {
        setQuery('');
        setHits([]);
        setHasErrors(false);
        if (searchInputRef && searchInputRef.current) {
            searchInputRef.current.value = '';
        }
    };
    const handleSearchQueryClear = (event) => {
        event.preventDefault();
        resetPanel();
    };
    return (React.createElement("div", { className: 'search-panel search -borrower', ref: searchPanelRef, "data-testid": 'borrower-search-panel' },
        React.createElement(SearchBar, { ...{
                query,
                handleSearchBarFocus,
                handleSearchBarBlur,
                handleSearchQueryChange,
                handleSearchQueryClear
            }, userType: 'borrower', placeholder: 'Search for a customer', forwardSearchBarRef: searchBarRef, forwardSearchInputRef: searchInputRef }),
        searchActive && React.createElement(SearchResults, { ...{ hasErrors, hits, isLoading } })));
};
export default BorrowerSearchPanel;
