import React, { useState } from 'react';
import ToggleSwitch from '../../components/shared/ToggleSwitch';
import { setIsInsured as defaultSetIsInsured } from '../../api/company';
import { showNotifyToast } from '../../utils';
const InsuranceToggleForm = ({ apiToken, borrowerId, isInsuredAccount, setIsInsured = defaultSetIsInsured }) => {
    const [insuranceToggleStatus, setInsuranceToggleStatus] = useState(isInsuredAccount);
    const errorMessage = 'Something went wrong. Please try again.';
    const enableInsuranceToggle = async () => {
        try {
            const response = await setIsInsured(apiToken, borrowerId, true);
            if (response) {
                setInsuranceToggleStatus(response.isInsured);
                showNotifyToast({
                    text: 'Successfully set as insured account',
                    type: 'success'
                });
            }
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
    };
    const disableInsuranceToggle = async () => {
        try {
            const response = await setIsInsured(apiToken, borrowerId, false);
            if (response) {
                setInsuranceToggleStatus(response.isInsured);
                showNotifyToast({
                    text: 'Successfully set as uninsured account',
                    type: 'success'
                });
            }
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
    };
    return (React.createElement("div", { "data-testid": 'insurance-toggle-form' },
        React.createElement("div", { className: 'form-group group -oneline' },
            React.createElement("label", { className: 'label' }, "Insured Account"),
            React.createElement(ToggleSwitch, { isToggled: insuranceToggleStatus, switchOn: () => enableInsuranceToggle(), switchOff: () => disableInsuranceToggle(), testId: 'insurance-toggle' })),
        React.createElement("p", { className: 'paragraph' }, "By turning on this feature it means that this client has insurance and it's protected from any risks.")));
};
export default InsuranceToggleForm;
