import classNames from 'classnames';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
const AccordionPane = ({ title, type, isExpandedDefault, defaultHeight, isMenu, children, hasWarning, highlightedTitle }) => {
    const initializeContentHeight = () => {
        if (isExpandedDefault && defaultHeight) {
            return defaultHeight;
        }
        else {
            return 0;
        }
    };
    const [isExpanded, setIsExpanded] = useState(isExpandedDefault || false);
    const [isSliding, setIsSliding] = useState(false);
    const [didMount, setDidMount] = useState(false);
    const [contentHeight, setContentHeight] = useState(initializeContentHeight());
    const innerRef = useRef(null);
    useEffect(() => {
        setDidMount(true);
    }, []);
    useLayoutEffect(() => {
        if (didMount) {
            setIsSliding(true);
            const timer = setTimeout(() => {
                setIsSliding(false);
            }, 360);
            return () => clearTimeout(timer);
        }
        if (innerRef && innerRef.current) {
            setContentHeight(innerRef.current.clientHeight);
        }
    }, [isExpanded]);
    const collapse = () => {
        if (!isSliding) {
            setIsExpanded(false);
        }
    };
    const expand = () => {
        if (!isSliding) {
            setIsExpanded(true);
        }
    };
    const isHome = type === 'home';
    const isHowItWorks = type === 'how-it-works';
    const isBusinessDetails = type === 'business-details';
    const isVerticalNav = type === 'vertical-nav';
    const accordionClass = classNames('accordion-pane pane', {
        '-expanded': isExpanded,
        '-menu': isMenu,
        '-home': isHome,
        '-how-it-works': isHowItWorks,
        '-business-details': isBusinessDetails,
        '-vertical-nav': isVerticalNav,
        '-highlighted-title': highlightedTitle
    });
    const iconClass = classNames('icon', {
        '-open': isExpanded,
        '-closed': !isExpanded
    });
    const titleClass = classNames('title', {
        '-warning': hasWarning
    });
    const contentStyle = {
        height: isExpanded ? `${contentHeight}px` : '0'
    };
    return (React.createElement("div", { className: accordionClass, "data-testid": 'accordion-pane' },
        React.createElement("div", { className: titleClass, onClick: isExpanded ? collapse : expand, "data-testid": 'trigger' },
            React.createElement("h3", { className: 'text' }, title),
            React.createElement("i", { className: iconClass })),
        React.createElement("div", { className: 'content', style: contentStyle, "data-testid": isExpanded ? 'expanded' : 'collapsed' },
            React.createElement("div", { className: 'inner', ref: innerRef }, children))));
};
export default AccordionPane;
