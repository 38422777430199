import React from 'react';
import ReactPaginate from 'react-paginate';
const PaginationBar = ({ currentPage, pageSize, totalPages, totalItems, resource, handlePageClick }) => {
    const firstItem = (currentPage - 1) * pageSize + 1;
    const lastOffset = currentPage * pageSize;
    const lastItem = lastOffset > totalItems ? totalItems : lastOffset;
    return (React.createElement("div", { className: 'pagination-bar pagination -flex -right', "data-testid": 'pagination-bar' },
        React.createElement("div", { className: 'entriesinfo' },
            "Displaying",
            ` ${resource}`,
            React.createElement("b", { "data-testid": 'range' }, ` ${firstItem}-${lastItem} `),
            "of",
            React.createElement("b", { "data-testid": 'total' }, ` ${totalItems}`)),
        React.createElement(ReactPaginate, { pageCount: totalPages, pageRangeDisplayed: 5, marginPagesDisplayed: 2, onPageChange: handlePageClick, containerClassName: 'list', pageClassName: 'page', pageLinkClassName: 'item', activeLinkClassName: 'item -active', previousLabel: '‹', previousClassName: 'prev', previousLinkClassName: 'item', nextLabel: '›', nextClassName: 'next', nextLinkClassName: 'item', breakClassName: 'page gap item', breakLinkClassName: 'item' })));
};
export default PaginationBar;
