import React, { useEffect, useState } from 'react';
import { fetchBorrowerAddress } from '../../api/address';
import { showNotifyToast } from '../../utils';
import Addresses from './Addresses';
const AddressesContainer = ({ apiToken, addressId, addresses, currentPrimaryAddress, disableCreate, disableEdit, regions, path, csrfToken, errors, currentAction, borrowerId }) => {
    const [action, setAction] = useState('view');
    const [address, setAddress] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorList, setErrorList] = useState(errors);
    useEffect(() => {
        if (currentAction === 'create') {
            setAction('new');
        }
        else if (currentAction === 'update') {
            setAction('edit');
            addressId && fetchAddress(addressId);
        }
        else {
            setAction('view');
        }
    }, []);
    const fetchAddress = async (id) => {
        setIsLoading(true);
        try {
            const { data } = await fetchBorrowerAddress(apiToken, borrowerId, id);
            setAddress(data.attributes);
        }
        catch (err) {
            showNotifyToast({
                text: 'Unable to fetch this address',
                type: 'error'
            });
        }
        setIsLoading(false);
    };
    return (React.createElement(Addresses, { ...{
            currentPrimaryAddress,
            setErrorList,
            fetchAddress,
            setAction,
            setAddress,
            addresses,
            path,
            isLoading,
            disableCreate,
            disableEdit,
            action,
            regions,
            address,
            csrfToken
        }, errors: errorList }));
};
export default AddressesContainer;
