import React, { useState } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import { dropdownStyles } from '../../custom-styles/dropdown';
const FilterPanel = ({ handleFilterOptionChange, isOpened, filterOptions }) => {
    const [filterOption, setFilterOption] = useState('selected_with_outstanding');
    const panelClass = classNames('panel', {
        '-active': isOpened
    });
    const applyFilter = () => {
        handleFilterOptionChange(filterOption);
    };
    const handleFilterChange = (selectedOption) => {
        setFilterOption(selectedOption.value);
    };
    return (React.createElement("div", { className: panelClass, "data-testid": 'panel' },
        React.createElement("h5", null, "FILTER BY DEBTOR"),
        React.createElement("br", null),
        React.createElement("div", { className: 'dropdown-select -full-width -no-max' },
            React.createElement(Select, { placeholder: '', defaultValue: filterOptions[0], options: filterOptions, onChange: handleFilterChange, className: 'select', styles: dropdownStyles })),
        React.createElement("div", { className: 'buttons action-buttons  -collapse' },
            React.createElement("button", { className: 'button -cancel -border', onClick: () => location.reload(), type: 'button' }, "Reset"),
            React.createElement("button", { className: 'button -primary', onClick: applyFilter }, "Apply Filter"))));
};
export default FilterPanel;
