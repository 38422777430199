import React, { useEffect, useState } from 'react';
import { fetchPaidInvoices } from '../../api/invoice_batch';
import { showNotifyToast } from '../../utils/notifyToast';
const PaidInvoicesTable = ({ apiToken, companyId, invoiceBatchId, invoicePath, customerPath }) => {
    const [paidInvoicesArray, setPaidInvoicesArray] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const errorMessage = 'Something went wrong. Please try again.';
    useEffect(() => {
        paidInvoices();
    }, []);
    const paidInvoices = async () => {
        setIsLoading(true);
        try {
            const response = await fetchPaidInvoices(apiToken, companyId, invoiceBatchId);
            setPaidInvoicesArray(response.data.map(res => res.attributes));
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        finally {
            setIsLoading(false);
        }
    };
    const statusTag = (color, label) => {
        return (React.createElement("span", { className: `badge-color ${color} -small -round -border${color}` }, label));
    };
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'paid-invoices-table' },
        React.createElement("table", { className: 'custom-table' },
            React.createElement("thead", { className: 'table-headers' },
                React.createElement("tr", { className: 'row -header' },
                    React.createElement("th", { className: 'table-header header' }, "Invoice No."),
                    React.createElement("th", { className: 'table-header header' }, "Customer Name"),
                    React.createElement("th", { className: 'table-header header' }, "Amount"),
                    React.createElement("th", { className: 'table-header header' }, "Issue Date"),
                    React.createElement("th", { className: 'table-header header' }, "Payment"),
                    React.createElement("th", { className: 'table-header header' }))),
            React.createElement("tbody", null, isLoading ? (React.createElement("tr", null,
                React.createElement("td", { className: 'cell', colSpan: 6 },
                    React.createElement("div", { className: 'loading-container -small' },
                        React.createElement("div", { className: 'loading-spinner spinner' }))))) : paidInvoicesArray.length !== 0 ? (paidInvoicesArray.map(invoice => (React.createElement("tr", { key: invoice.id },
                React.createElement("td", { className: 'cell' },
                    React.createElement("a", { href: `${invoicePath}/${invoice.id}` }, invoice.number)),
                React.createElement("td", { className: 'cell' },
                    React.createElement("a", { href: `${customerPath}/${invoice.customerId}` }, invoice.customerName)),
                React.createElement("td", { className: 'cell' },
                    React.createElement("div", { className: 'text -emphasize' }, invoice.amountFormatted)),
                React.createElement("td", { className: 'cell' }, invoice.issueDateFormattedString),
                React.createElement("td", { className: 'cell' }, invoice.paymentFromCollectionAccount
                    ? statusTag('-green', 'Collection Account')
                    : statusTag('-red', 'Misbanking')),
                React.createElement("td", { className: 'cell -right' }))))) : (React.createElement("tr", { "data-testid": 'no-record' },
                React.createElement("td", { className: 'placeholder', colSpan: 6 }, "There are no invoices to display")))))));
};
export default PaidInvoicesTable;
