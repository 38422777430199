import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { validateFileType, validateFileSize } from '../../validations/file';
import ErrorList from '../shared/ErrorList';
import Feature from './Feature';
import PreviewGallery from './PreviewGallery';
const ReleaseNoteEditor = ({ csrfToken, formUrl, backLink, releaseNote, errors, features }) => {
    const [releaseNoteDetails, setReleaseNoteDetails] = useState(releaseNote);
    const [featureList, setFeatureList] = useState(features);
    const [files, setFiles] = useState([]);
    const [deleteList, setDeleteList] = useState([]);
    const [fileErrors, setFileErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const filePickerRef = useRef(null);
    const formRef = useRef(null);
    useEffect(() => {
        const currentFileErrors = validateFiles();
        setFileErrors(currentFileErrors);
    }, [files, deleteList]);
    const { heading, description } = releaseNoteDetails;
    const featureTemplate = {
        id: null,
        label: 'new',
        description: '',
        releaseNoteId: null,
        createdAt: null,
        updatedAt: null
    };
    const hasFiles = files.filter((file, index) => !deleteList.includes(index)).length > 0;
    const hasErrors = Object.keys(fileErrors).length > 0;
    const submitButtonClass = classNames('button -primary', {
        '-disabled': hasErrors || isSubmitting
    });
    const handleAddFeature = () => {
        const newFeature = { ...featureTemplate };
        setFeatureList([...featureList, newFeature]);
    };
    const removeFeature = (featureIndex) => {
        const updatedList = featureList.filter((feature, index) => index !== featureIndex);
        setFeatureList(updatedList);
    };
    const updateFeatureField = (featureIndex, field, value) => {
        const updatedList = [...featureList];
        updatedList[featureIndex] = { ...updatedList[featureIndex], [field]: value };
        setFeatureList(updatedList);
    };
    const updateReleaseNoteField = (field, value) => {
        setReleaseNoteDetails({ ...releaseNoteDetails, [field]: value });
    };
    const handleHeadingChange = (event) => {
        updateReleaseNoteField('heading', event.target.value);
    };
    const handleDescriptionChange = (event) => {
        updateReleaseNoteField('description', event.target.value);
    };
    const handleAddFile = (event) => {
        const selectedFiles = event.target.files;
        const selectedList = [...selectedFiles];
        setFiles([...files, ...selectedList]);
    };
    const handleAddAttachment = () => {
        if (filePickerRef && filePickerRef.current) {
            filePickerRef.current.click();
        }
    };
    const removeFile = (index) => {
        setDeleteList([...deleteList, index]);
    };
    const validateFiles = () => {
        return files.reduce((acc, file, index) => {
            if (!deleteList.includes(index)) {
                const fileTypeValidations = validateFileType(file)
                    ? []
                    : [`The file type isn't supported!`];
                const fileSizeValidations = validateFileSize(file)
                    ? []
                    : [`The file needs to be less than 20MB!`];
                if (fileTypeValidations.length > 0 ||
                    fileSizeValidations.length > 0) {
                    return {
                        ...acc,
                        [index]: [...fileTypeValidations, ...fileSizeValidations]
                    };
                }
                else {
                    return { ...acc };
                }
            }
            else {
                return { ...acc };
            }
        }, {});
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        if (formRef && formRef.current) {
            formRef.current.submit();
        }
    };
    return (React.createElement("form", { encType: 'multipart/form-data', className: 'release-note-form', action: formUrl, acceptCharset: 'UTF-8', method: 'post', ref: formRef, onSubmit: handleSubmit, "data-testid": 'release-note-editor' },
        React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
        React.createElement("section", { className: 'details' },
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label -uppercase -small -release-note', htmlFor: 'release_note_heading' }, "Heading"),
                React.createElement("input", { type: 'text', className: 'input form-control', name: 'release_note[heading]', id: 'release_note_heading', value: heading || '', onChange: handleHeadingChange }),
                errors.heading && React.createElement(ErrorList, { errors: errors, field: 'heading' })),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label -uppercase -small -release-note', htmlFor: 'release_note_description' }, "Description"),
                React.createElement("textarea", { className: 'input form-control -textarea -release-note -noresize', name: 'release_note[description]', id: 'release_note_description', value: description || '', onChange: handleDescriptionChange }),
                errors.description && (React.createElement(ErrorList, { errors: errors, field: 'description' })))),
        React.createElement("section", { className: 'features' },
            React.createElement("h3", { className: 'title' }, "Feature List"),
            featureList.map((feature, index) => (React.createElement(Feature, { ...{ feature, index, removeFeature, updateFeatureField }, key: index, errors: errors }))),
            React.createElement("button", { type: 'button', className: 'add', onClick: handleAddFeature, "data-testid": 'add-features' }, "Add features")),
        React.createElement("section", { className: 'attachments' },
            React.createElement("h3", { className: 'title' }, "Attachments"),
            hasFiles && (React.createElement(PreviewGallery, { ...{ files, fileErrors, deleteList, removeFile } })),
            Array(files.length + 1)
                .fill(0)
                .map((item, index) => {
                if (!deleteList.includes(index)) {
                    const ref = index === files.length ? filePickerRef : null;
                    return (React.createElement("input", { key: index, className: 'picker', type: 'file', name: 'attachments[]', onChange: handleAddFile, ref: ref, "data-testid": 'picker' }));
                }
            }),
            React.createElement("button", { type: 'button', className: 'add', onClick: handleAddAttachment }, "Add Attachment")),
        React.createElement("section", { className: 'action-buttons actions -release-notes' },
            React.createElement("a", { href: backLink, className: 'button -cancel' }, "Cancel"),
            React.createElement("button", { type: 'submit', disabled: hasErrors || isSubmitting, className: submitButtonClass, "data-testid": 'submit-button' }, "Publish"))));
};
export default ReleaseNoteEditor;
