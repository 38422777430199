import React, { useState } from 'react';
const DisapprovalReportFilter = ({ borrowerId, params }) => {
    const options = [
        { value: 'all', label: 'Show All' },
        { value: 'issue_date', label: 'Filter by Issue Date' },
        { value: 'disapproval_date', label: 'Filter by Disapproval Date' }
    ];
    const [selectedFilter, setSelectedFilter] = useState(options.find(data => data.value === (params === null || params === void 0 ? void 0 : params.type)) || options[0]);
    const [startDate, setStartDate] = useState(params === null || params === void 0 ? void 0 : params.startDate);
    const [endDate, setEndDate] = useState(params === null || params === void 0 ? void 0 : params.endDate);
    const handleStartDate = (event) => {
        const value = event.target.value;
        setStartDate(value);
    };
    const handleEndDate = (event) => {
        const value = event.target.value;
        setEndDate(value);
    };
    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        if (filter.value === 'all') {
            window.location.replace(`/admin/borrowers/${borrowerId}/reporting/disapproval_reports`);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'custom-dropdown link', "data-testid": 'filter-dropdown' },
            React.createElement("span", { className: 'caret' }, selectedFilter.label),
            React.createElement("div", { className: 'content -long' }, options.map((text, index) => (React.createElement("a", { className: 'item', href: '#', key: index, onClick: () => {
                    handleFilterChange(text);
                } }, text.label))))),
        selectedFilter.value !== 'all' && (React.createElement("form", { className: 'bank-accounts-form', action: `/admin/borrowers/${borrowerId}/reporting/disapproval_reports`, method: 'get' },
            React.createElement("input", { type: 'hidden', name: 'filter[type]', value: selectedFilter.value }),
            React.createElement("label", { htmlFor: 'filter_start_date' }, "Start date"),
            React.createElement("input", { placeholder: 'Start date', type: 'date', name: 'filter[start_date]', id: 'filter_start_date', value: startDate, onChange: handleStartDate, required: true }),
            React.createElement("label", { htmlFor: 'filter_end_date' }, "End date"),
            React.createElement("input", { type: 'date', name: 'filter[end_date]', id: 'filter_end_date', value: endDate, onChange: handleEndDate, required: true }),
            React.createElement("input", { type: 'submit', name: 'commit', value: 'Fetch', className: 'custom-button -default -loose', "data-disable-with": 'Please wait...' })))));
};
export default DisapprovalReportFilter;
