import React from 'react';
import classNames from 'classnames';
const Panel = ({ isOpened, path, selectedIdString, csrfToken, comment, doArchiveUnassignedTransactions, setComment }) => {
    const panelClass = classNames('panel', {
        '-active': isOpened
    });
    const handleSubmit = () => {
        doArchiveUnassignedTransactions();
    };
    return (React.createElement("form", { method: 'post', action: path, onSubmit: handleSubmit, className: panelClass, "data-testid": 'panel' },
        React.createElement("h4", null, "Delete Unassigned Transaction"),
        React.createElement("hr", null),
        React.createElement("div", { className: 'form-group' },
            React.createElement("input", { type: 'text', className: 'input form-control', placeholder: 'Add comments (optional)', onChange: e => setComment(e.target.value), value: comment, name: 'delete_unassigned_transaction_comment' })),
        React.createElement("input", { type: 'hidden', name: 'deleted_at' }),
        React.createElement("input", { type: 'hidden', name: 'unassigned_transaction_ids', value: selectedIdString, "data-testid": 'unassigned-transaction-ids' }),
        React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
        React.createElement("div", { className: 'buttons action-buttons flex-grid -space-between -collapse' },
            React.createElement("button", { className: 'button -danger -nowrap', type: 'submit' }, "Delete Transaction"),
            React.createElement("button", { className: 'button -default -border', onClick: () => location.reload(), type: 'button' }, "Cancel"))));
};
export default Panel;
