import React from 'react';
const AllControls = ({ approvedToggleSwitchClass, selectedToggleSwitchClass, deselectAllCustomersHandler, selectAllCustomersHandler, unapproveAllCustomersHandler, setIsApproveAllModalOpen }) => {
    // TODO(dt): Improve, maybe we update `all` to the actual customer count to be updated,
    // we can also use a fancy confirm modal instead of window.confirm
    const onConfirmationText = `Are you sure you want to approve all customers?`;
    const offConfirmationText = `Are you sure you want to unapprove all customers?`;
    const selectConfirmationText = `Are you sure you want to select all customers?`;
    const deselectConfirmationText = `Are you sure you want to deselect all customers?`;
    // TODO(dt): Not ideal, refactor to use actual state, possibly passed as props and don't use class names
    const isSelectAllOn = selectedToggleSwitchClass.includes('-checked');
    const isApproveAllOn = approvedToggleSwitchClass.includes('-checked');
    const isSelectAllOff = !isSelectAllOn && !selectedToggleSwitchClass.includes('-mid');
    const isApproveAllOff = !isApproveAllOn && !approvedToggleSwitchClass.includes('-mid');
    return (React.createElement("div", { className: 'all-controls', "data-testid": 'all-controls' },
        React.createElement("div", { className: 'control' },
            React.createElement("span", { className: 'description' }, "Select all"),
            React.createElement("div", { className: selectedToggleSwitchClass },
                React.createElement("span", { className: 'peg' }),
                React.createElement("button", { className: 'inactive', onClick: () => {
                        // Simulate click on a normal switch
                        // If the switch is off, clicking on left side turns the switch on / select all
                        if (isSelectAllOff) {
                            if (window.confirm(selectConfirmationText)) {
                                selectAllCustomersHandler();
                            }
                        }
                        else {
                            if (window.confirm(deselectConfirmationText)) {
                                deselectAllCustomersHandler();
                            }
                        }
                    }, "data-testid": 'deselect-all' }),
                React.createElement("button", { className: 'active', onClick: () => {
                        // Simulate click on a normal switch
                        // If the switch is on, clicking on right side turns the switch off / deselect all
                        if (isSelectAllOn) {
                            if (window.confirm(deselectConfirmationText)) {
                                deselectAllCustomersHandler();
                            }
                        }
                        else {
                            if (window.confirm(selectConfirmationText)) {
                                selectAllCustomersHandler();
                            }
                        }
                    }, "data-testid": 'select-all' }))),
        React.createElement("div", { className: 'control' },
            React.createElement("span", { className: 'description' }, "Approve all"),
            React.createElement("div", { className: approvedToggleSwitchClass },
                React.createElement("span", { className: 'peg' }),
                React.createElement("button", { className: 'inactive', onClick: () => {
                        // Simulate click on a normal switch
                        // If the switch is off, clicking on left side opens approve all model / approve all
                        if (isApproveAllOff) {
                            if (window.confirm(onConfirmationText)) {
                                setIsApproveAllModalOpen(true);
                            }
                        }
                        else {
                            if (window.confirm(offConfirmationText)) {
                                unapproveAllCustomersHandler();
                            }
                        }
                    }, "data-testid": 'unapprove-all' }),
                React.createElement("button", { className: 'active', onClick: () => {
                        // Simulate click on a normal switch
                        // If the switch is on, clicking on right side turns the switch off / approve all
                        if (isApproveAllOn) {
                            if (window.confirm(offConfirmationText)) {
                                unapproveAllCustomersHandler();
                            }
                        }
                        else {
                            if (window.confirm(onConfirmationText)) {
                                setIsApproveAllModalOpen(true);
                            }
                        }
                    }, "data-testid": 'approve-all' })))));
};
export default AllControls;
