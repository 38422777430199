import fetch from 'isomorphic-fetch';
export const fetchBorrowerCompanyAssignedAdmins = async (apiToken) => {
    const endpoint = `/api/borrower/company/assigned_admins`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
