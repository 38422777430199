import React, { Fragment, useState } from 'react';
import JSONPretty from 'react-json-pretty';
import { fetchInvoiceFromXero } from '../../api/invoice';
import { showNotifyToast } from '../../utils';
import SpinnerButton from '../shared/SpinnerButton';
import Modal from '../shared/Modal';
const InvoicePreviewModal = ({ apiToken, borrowerId, invoiceId, path, method, headerText }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [previewInvoice, setPreviewInvoice] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const hasFetchedInvoice = !!Object.keys(previewInvoice).length;
    const toggleModal = () => {
        isVisible ? setIsVisible(false) : setIsVisible(true);
        !hasFetchedInvoice && handleFetchedInvoice();
    };
    const handleFetchedInvoice = async () => {
        setIsLoading(true);
        try {
            const data = await fetchInvoiceFromXero(apiToken, borrowerId, invoiceId);
            setPreviewInvoice(data);
        }
        catch (err) {
            if (err instanceof Error) {
                showNotifyToast({
                    text: err.message,
                    type: 'error'
                });
            }
        }
        setIsLoading(false);
    };
    return (React.createElement(Fragment, null,
        React.createElement(SpinnerButton, { text: 'Fetch Invoice', buttonType: 'button', className: 'custom-button -blue -full-width', handleClick: toggleModal, isLoading: isLoading }),
        !isLoading && hasFetchedInvoice && (React.createElement(Modal, { isOpen: isVisible, onClose: toggleModal },
            React.createElement("div", { className: 'custom-panel -modal -preview-invoice-modal', role: 'dialog', "aria-modal": 'true' },
                React.createElement("div", { className: 'header' }, headerText),
                React.createElement("div", { className: 'content -terminal custom-scrollbar' },
                    React.createElement("div", { className: 'section' },
                        React.createElement("div", { className: 'description' },
                            React.createElement(JSONPretty, { data: JSON.stringify(previewInvoice, null, 2), stringStyle: 'color: #C86D00', booleanStyle: 'color: #C86D00' })))),
                React.createElement("div", { className: 'footer' },
                    React.createElement("div", { className: 'action-buttons buttons space' },
                        React.createElement("button", { className: 'button -reset -transparent', onClick: toggleModal }, "Cancel"),
                        React.createElement("a", { className: 'button -primary', "data-method": method, href: path, "data-testid": 'modal-submit' }, "Save Record"))))))));
};
export default InvoicePreviewModal;
