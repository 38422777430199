import React, { Fragment } from 'react';
import NumberFormat from 'react-number-format';
const YAxisTick = ({ x, y, payload }) => {
    if (!payload)
        return null;
    return (React.createElement(Fragment, null,
        React.createElement("g", { transform: `translate(${x}, ${y})`, "data-testid": 'y-axis-tick' },
            React.createElement(NumberFormat, { renderText: value => (React.createElement("text", { className: 'recharts-text', textAnchor: 'end', x: -12, dy: 4 }, value)), displayType: 'text', value: payload.value, prefix: '$', thousandSeparator: true }))));
};
export default YAxisTick;
