import React, { useState, useEffect } from 'react';
import { fetchAllCustomersForBorrower } from '../../api/admin_customer';
import { showNotifyToast } from '../../utils/notifyToast';
import DesignatedAddressPanel from '../../components/admin-customer-statements/DesignatedAddressPanel';
import ManyAddressPanel from '../../components/admin-customer-statements/ManyAddressPanel';
import AdminCustomerStatementsModal from '../../components/admin-customer-statements/AdminCustomerStatementsModal';
const AdminCustomerStatements = ({ path, defaultEmail, borrowerId, apiToken, csrfToken }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [customersArray, setCustomersArray] = useState([]);
    const [checkedCustomers, setCheckedCustomers] = useState([]);
    const [checkedSelectedDebtors, setCheckedSelectedDebtors] = useState(false);
    const [email, setEmail] = useState(defaultEmail);
    const [isVisible, setIsVisible] = useState(false);
    const [bccEmail, setBccEmail] = useState('');
    const errorMessage = 'Something went wrong. Please try again.';
    const fetchAllCustomers = async () => {
        setIsLoading(true);
        try {
            const data = await fetchAllCustomersForBorrower(apiToken, borrowerId);
            const sortedCustomers = data
                .sort((customerA, customerB) => customerA.isSelected === customerB.isSelected
                ? 0
                : customerA.isSelected
                    ? -1
                    : 1)
                .sort((customerA, customerB) => parseFloat(customerB.receivablesFormatted) -
                parseFloat(customerA.receivablesFormatted));
            setCustomersArray(sortedCustomers);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsLoading(false);
    };
    useEffect(() => {
        fetchAllCustomers();
    }, []);
    const handleSelectedDebtors = () => {
        const selectedCustomers = checkedSelectedDebtors
            ? []
            : customersArray
                .filter(customer => customer.email !== '' &&
                customer.isSelected === true &&
                customer.discloseType !== 'confidential')
                .map(customer => customer.id);
        setCheckedSelectedDebtors(!checkedSelectedDebtors);
        setCheckedCustomers(selectedCustomers);
    };
    const handleCustomerCheckbox = (id) => {
        const index = checkedCustomers.indexOf(id);
        const currentCheckedCustomers = checkedCustomers.includes(id)
            ? [
                ...checkedCustomers.slice(0, index),
                ...checkedCustomers.slice(index + 1, checkedCustomers.length)
            ]
            : [...checkedCustomers, id];
        setCheckedCustomers(currentCheckedCustomers);
    };
    const toggleModal = () => {
        isVisible ? setIsVisible(false) : setIsVisible(true);
    };
    return (React.createElement("div", { "data-testid": 'customer-statements' },
        React.createElement(AdminCustomerStatementsModal, { ...{
                isVisible,
                path,
                csrfToken,
                bccEmail,
                checkedCustomers,
                checkedSelectedDebtors,
                customersArray,
                toggleModal
            } }),
        React.createElement(DesignatedAddressPanel, { ...{
                path,
                email,
                setEmail,
                csrfToken
            } }),
        React.createElement(ManyAddressPanel, { ...{
                path,
                csrfToken,
                isLoading,
                setBccEmail,
                checkedSelectedDebtors,
                handleSelectedDebtors,
                customersArray,
                checkedCustomers,
                handleCustomerCheckbox,
                toggleModal,
                isVisible
            } })));
};
export default AdminCustomerStatements;
