import React from 'react';
const AmendedInvoice = (props) => {
    const { notification } = props;
    const { attributes } = notification;
    const { actorAvatar, companyName, showPath, type, createdAtInWords, companyPath, amendedInvoiceThresholdFormatted } = attributes;
    return (React.createElement("div", { className: 'event-panel -notification custom-panel event' },
        React.createElement("div", { className: 'event-box -notification -unread', "data-testid": 'amended-invoice' },
            React.createElement("div", { className: 'user icon' },
                React.createElement("img", { src: actorAvatar, className: 'avatar' })),
            React.createElement("div", { className: 'description' },
                React.createElement("div", { className: 'title' },
                    React.createElement("a", { href: companyPath }, companyName),
                    " amended the value of",
                    React.createElement("a", { href: showPath },
                        " ",
                        type,
                        " "),
                    "above the threshold amount (",
                    amendedInvoiceThresholdFormatted,
                    ")"),
                React.createElement("div", { className: 'date' },
                    " ",
                    createdAtInWords)))));
};
export default AmendedInvoice;
