import classNames from 'classnames';
import React from 'react';
import { getParamsMap } from '../../utils';
const Tab = ({ name, path, tag, counter, variant }) => {
    const params = window.location.search;
    const paramsMap = getParamsMap(params);
    const tabClass = classNames('tab-link tab', {
        '-active': paramsMap.status === tag || paramsMap.tab === tag,
        '-broker': variant === 'broker'
    });
    // explicitly check for undefined because 0 would be falsey
    const hasCounter = counter !== undefined;
    return (React.createElement("a", { href: path, className: tabClass, "data-testid": 'tab-link' },
        React.createElement("div", { className: 'name' }, name),
        hasCounter && (React.createElement("div", { className: 'counter', "data-testid": 'tab-counter' }, counter))));
};
export default Tab;
