import React from 'react';
const CustomerAgeing = ({ customer, isManualLedger, ageingPanelHeader, totalApprovedFormatted, totalFormatted, dpi0To30DaysApprovedFormatted, dpi0To30DaysFormatted, dpi31To60DaysApprovedFormatted, dpi31To60DaysFormatted, dpi61To90DaysApprovedFormatted, dpi61To90DaysFormatted, dpi90DaysApprovedFormatted, dpi90DaysFormatted }) => {
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'customer-ageing' },
        React.createElement("div", { className: 'header' }, ageingPanelHeader),
        isManualLedger ? (React.createElement("table", { className: 'custom-table' },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { className: 'header -unpadded-x' }),
                    React.createElement("th", { className: 'header -unpadded-x' }, "Gross Receivables Ledger"),
                    React.createElement("th", { className: 'header -unpadded-x' }, "Aging %"))),
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "0-30 DPI"),
                    React.createElement("td", { className: 'value -left' }, customer.receivables0To30DaysFormatted),
                    React.createElement("td", { className: 'value -left' }, customer.grossLedger0To30AgingFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "31-60 DPI"),
                    React.createElement("td", { className: 'value -left' }, customer.receivables31To60DaysFormatted),
                    React.createElement("td", { className: 'value -left' }, customer.grossLedger31To60AgingFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "61-90 DPI"),
                    React.createElement("td", { className: 'value -left' }, customer.receivables61To90DaysFormatted),
                    React.createElement("td", { className: 'value -left' }, customer.grossLedger61To90AgingFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "91-120 DPI"),
                    React.createElement("td", { className: 'value -left' }, customer.receivables91To120DaysFormatted),
                    React.createElement("td", { className: 'value -left' }, customer.grossLedger91To120AgingFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "120+ DPI"),
                    React.createElement("td", { className: 'value -left' }, customer.receivables120PlusDaysFormatted),
                    React.createElement("td", { className: 'value -left' }, customer.grossLedger120PlusAgingFormatted)),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Total"),
                    React.createElement("td", { className: 'value -left' }, customer.grossReceivablesLedgerFormatted))))) : (React.createElement("table", { className: 'custom-table' },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { className: 'header -unpadded-x' }),
                    React.createElement("th", { className: 'header -unpadded-x' }, "Approved"),
                    React.createElement("th", { className: 'header -unpadded-x' }, "All"))),
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Total"),
                    React.createElement("td", { className: 'value -left' }, totalApprovedFormatted),
                    React.createElement("td", { className: 'value -left' },
                        React.createElement("div", { className: 'eligible' }, totalFormatted))),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "0-30 DPI"),
                    React.createElement("td", { className: 'value -left' }, dpi0To30DaysApprovedFormatted),
                    React.createElement("td", { className: 'value -left' },
                        React.createElement("div", { className: 'eligible' }, dpi0To30DaysFormatted))),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "31-60 DPI"),
                    React.createElement("td", { className: 'value -left' }, dpi31To60DaysApprovedFormatted),
                    React.createElement("td", { className: 'value -left' },
                        React.createElement("div", { className: 'eligible' }, dpi31To60DaysFormatted))),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "61-90 DPI"),
                    React.createElement("td", { className: 'value -left' }, dpi61To90DaysApprovedFormatted),
                    React.createElement("td", { className: 'value -left' },
                        React.createElement("div", { className: 'eligible' }, dpi61To90DaysFormatted))),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "90+ DPI"),
                    React.createElement("td", { className: 'value -left' }, dpi90DaysApprovedFormatted),
                    React.createElement("td", { className: 'value -left' },
                        React.createElement("div", { className: 'eligible' }, dpi90DaysFormatted))))))));
};
export default CustomerAgeing;
