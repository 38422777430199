import React, { useState } from 'react';
import Modal from '../shared/Modal';
const CloseButton = ({ path, projectedTotal }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleCloseModal = () => {
        setIsOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { className: 'custom-button button -rectangular -danger -loose -full-width', "data-testid": 'close-trade-loan', onClick: () => setIsOpen(true), type: 'button' }, "Close Loan"),
        React.createElement(Modal, { isOpen: isOpen, onClose: handleCloseModal },
            React.createElement("div", { className: 'modal' },
                React.createElement("div", { className: 'custom-panel -modal' },
                    React.createElement("div", { className: 'header' }, "Closing Trade Loan Confirmation"),
                    React.createElement("div", { className: 'content custom-scrollbar' },
                        React.createElement("div", { className: 'section -list' },
                            React.createElement("p", null, "Are you sure you want to close this loan?"),
                            React.createElement("br", null),
                            React.createElement("p", null,
                                React.createElement("b", null, projectedTotal),
                                " will be written off")),
                        React.createElement("br", null),
                        React.createElement("div", { className: 'footer -borderless -bordertop -nopadding' },
                            React.createElement("div", { className: 'action-buttons' },
                                React.createElement("button", { className: 'button -neutral', onClick: () => handleCloseModal(), type: 'button' }, "Cancel"),
                                React.createElement("a", { href: path, "data-method": 'put', className: 'button -primary', "data-testid": 'submit-link' }, "Yes, I am sure"))))),
                React.createElement("div", { className: 'modal-overlay' })))));
};
export default CloseButton;
