import React from 'react';
const FormInputs = ({ csrfToken, upfrontCommission, gstIncluded, receivedApproval, crTrailingFeeRate, crTrailingInterestRate, arTrailingFeeRate, arTrailingInterestRate, arTrailingExpiryDate }) => {
    return (React.createElement("div", { "data-testid": 'form-inputs' },
        React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
        React.createElement("input", { type: 'hidden', name: 'commission_details[upfront_commission]', value: upfrontCommission }),
        React.createElement("input", { type: 'hidden', name: 'commission_details[upfront_commission_gst_included]', value: gstIncluded.toString() }),
        React.createElement("input", { type: 'hidden', name: 'commission_details[upfront_commission_received_approval]', value: receivedApproval.toString() }),
        React.createElement("input", { type: 'hidden', name: 'commission_details[trailing_fee_commission_rate]', value: crTrailingFeeRate }),
        React.createElement("input", { type: 'hidden', name: 'commission_details[trailing_interest_commission_rate]', value: crTrailingInterestRate }),
        React.createElement("input", { type: 'hidden', name: 'commission_details[aggregator_trailing_fee_commission_rate]', value: arTrailingFeeRate }),
        React.createElement("input", { type: 'hidden', name: 'commission_details[aggregator_trailing_interest_commission_rate]', value: arTrailingInterestRate }),
        React.createElement("input", { type: 'hidden', name: 'commission_details[aggregator_trailing_expiry_date]', value: arTrailingExpiryDate }),
        React.createElement("input", { type: 'hidden', name: 'commission_details[pay_upfront_commission]', value: true.toString() })));
};
export default FormInputs;
