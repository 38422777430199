import React, { Fragment, useReducer, useState } from 'react';
import { updateInvoiceDetails as defaultUpdateInvoiceDetails, createInvoiceDetails as defaultCreateInvoiceDetails, removeInvoiceDetails as defaultRemoveInvoiceDetails, removeInvoiceDetailsFile as defaultRemoveInvoiceDetailsFile } from '../../api/trade_loan';
import { isEmptyObject, snakeToCamelCase } from '../../utils';
import InvoicePanel from '../../components/trade-loan/InvoicePanel';
import InvoicesEditSidebar from '../../components/trade-loan/InvoicesEditSidebar';
import { isAPIValidationErrors, isAPIErrors } from '../../../types';
import { convertCentsString } from '../../utils/convertCents';
import { getBracketValues, removeDuplicateFiles } from '../../utils';
import { compileExistingFileNames } from '../../utils/tradeLoanInvoiceFiles';
import { showNotifyToast as defaultShowNotifyToast } from '../../utils/notifyToast';
import { validateFileSize, validateTradeLoanDuplicateFile } from '../../validations/file';
import SupplierDetailsReducer from '../../reducers/trade-loans/SupplierDetailsReducer';
const InvoicesEditDashboard = ({ apiToken, tradeLoanId, urlPath, initialSuppliers, initialInvoices, invoiceCurrencyOptions, navItems, createInvoiceDetails = defaultCreateInvoiceDetails, updateInvoiceDetails = defaultUpdateInvoiceDetails, removeInvoiceDetails = defaultRemoveInvoiceDetails, removeInvoiceDetailsFile = defaultRemoveInvoiceDetailsFile, showNotifyToast = defaultShowNotifyToast }) => {
    const findCurrencyOption = (currency) => {
        return (invoiceCurrencyOptions.find((option) => option.value === currency) || null);
    };
    const buildInitialCurrencies = (invoices) => {
        return invoices.map((invoiceList) => {
            return invoiceList.map((invoice) => {
                return findCurrencyOption(invoice.paymentCurrency);
            });
        });
    };
    const buildInitialAmountValues = (invoices) => {
        return invoices.map((invoiceList) => {
            return invoiceList.map((invoice) => {
                return {
                    invoiceAmountCents: {
                        floatValue: invoice.invoiceAmountCents,
                        value: convertCentsString(invoice.invoiceAmountCents),
                        formattedValue: ''
                    },
                    amountToPayCents: {
                        floatValue: invoice.amountToPayCents,
                        value: convertCentsString(invoice.amountToPayCents),
                        formattedValue: ''
                    }
                };
            });
        });
    };
    const buildInitialInvoiceErrors = (invoices) => {
        return invoices.map((invoiceList) => {
            return Array(invoiceList.length).fill({});
        });
    };
    const buildInitialInvoiceDocumentErrors = (invoices) => {
        return invoices.map((invoiceList) => {
            return Array(invoiceList.length).fill({});
        });
    };
    const initialState = {
        suppliers: initialSuppliers,
        selectedSupplierOptions: Array(initialSuppliers.length).fill(null),
        selectedCurrencyOptions: Array(initialSuppliers.length).fill(null),
        paymentDetailsList: [],
        supplierDetailsErrors: Array(initialSuppliers.length).fill({}),
        paymentDetailsErrors: [],
        invoices: initialInvoices,
        selectedInvoiceCurrencyOptions: buildInitialCurrencies(initialInvoices),
        invoiceAmountValues: buildInitialAmountValues(initialInvoices),
        invoiceErrors: buildInitialInvoiceErrors(initialInvoices),
        invoiceDocumentErrors: buildInitialInvoiceDocumentErrors(initialInvoices),
        supplierDocumentErrors: Array(initialSuppliers.length).fill({})
    };
    const [suppliersState, dispatch] = useReducer(SupplierDetailsReducer, initialState);
    const { suppliers, invoices, invoiceErrors, invoiceDocumentErrors, selectedInvoiceCurrencyOptions, invoiceAmountValues } = suppliersState;
    const [savingStatuses, setSavingStatuses] = useState(initialInvoices.map((invoiceList) => {
        return Array(invoiceList.length).fill(false);
    }));
    const updateSavingStatus = (supplierIndex, invoiceIndex, value) => {
        setSavingStatuses([
            ...savingStatuses.slice(0, supplierIndex),
            [
                ...savingStatuses[supplierIndex].slice(0, invoiceIndex),
                value,
                ...savingStatuses[supplierIndex].slice(invoiceIndex + 1, savingStatuses[supplierIndex].length)
            ],
            ...savingStatuses.slice(supplierIndex + 1, savingStatuses.length)
        ]);
    };
    const [focusedInvoiceField, setFocusedInvoiceField] = useState({
        invoiceIndex: 0,
        supplierIndex: 0,
        fieldName: ''
    });
    const handleInvoiceDetailUpdate = (event) => {
        const targetName = event.target['name'];
        const [supplierIndex, invoiceIndex, fieldName] = getBracketValues(targetName);
        const value = event.target['value'];
        dispatch({
            type: 'UPDATE_INVOICE_FIELD',
            supplierIndex: parseInt(supplierIndex),
            invoiceIndex: parseInt(invoiceIndex),
            fieldName: snakeToCamelCase(fieldName),
            value
        });
    };
    const handleInvoiceDateChange = (selectedDates, dateString, instance) => {
        const targetName = instance.input.name;
        const [supplierIndex, invoiceIndex, fieldName] = getBracketValues(targetName);
        dispatch({
            type: 'UPDATE_INVOICE_FIELD',
            supplierIndex: parseInt(supplierIndex),
            invoiceIndex: parseInt(invoiceIndex),
            fieldName: snakeToCamelCase(fieldName),
            value: dateString
        });
    };
    const invoiceDateSettings = {
        altInput: true,
        altFormat: 'm/d/Y',
        dateFormat: 'Y-m-d',
        onChange: handleInvoiceDateChange
    };
    const handleInvoiceAmountValueChange = (values) => {
        if (focusedInvoiceField) {
            dispatch({
                type: 'UPDATE_INVOICE_AMOUNT',
                supplierIndex: focusedInvoiceField.supplierIndex,
                invoiceIndex: focusedInvoiceField.invoiceIndex,
                fieldName: snakeToCamelCase(focusedInvoiceField.fieldName),
                centsAmount: values.floatValue
                    ? Math.round(values.floatValue * 100)
                    : 0,
                values
            });
        }
    };
    const handleInvoiceFocus = (event) => {
        if (event.target) {
            const targetName = event.target['name'];
            const [supplierIndex, invoiceIndex, fieldName] = getBracketValues(targetName);
            setFocusedInvoiceField({
                supplierIndex: parseInt(supplierIndex),
                invoiceIndex: parseInt(invoiceIndex),
                fieldName: snakeToCamelCase(fieldName)
            });
        }
    };
    const handleInvoiceCurrencyOptionChange = (selectedOption, action) => {
        const [supplierIndex, invoiceIndex, fieldName] = getBracketValues(action.name);
        dispatch({
            type: 'UPDATE_INVOICE_CURRENCY',
            supplierIndex: parseInt(supplierIndex),
            invoiceIndex: parseInt(invoiceIndex),
            fieldName: snakeToCamelCase(fieldName),
            option: selectedOption
        });
    };
    const handleInvoiceFileChange = (acceptedFiles, targetName) => {
        const [supplierIndex, invoiceIndex, fieldName] = getBracketValues(targetName);
        const newFiles = [...acceptedFiles];
        const currentFiles = invoices[supplierIndex][invoiceIndex][snakeToCamelCase(fieldName)] || [];
        const allFiles = removeDuplicateFiles([
            ...currentFiles,
            ...newFiles
        ]);
        dispatch({
            type: 'UPDATE_INVOICE_FILES',
            supplierIndex: parseInt(supplierIndex),
            invoiceIndex: parseInt(invoiceIndex),
            fieldName: snakeToCamelCase(fieldName),
            files: allFiles
        });
        validateInvoiceDocumentsFrontend(allFiles, parseInt(supplierIndex), parseInt(invoiceIndex), snakeToCamelCase(fieldName));
    };
    const handleInvoiceFileReplace = (event) => {
        const targetName = event.target['name'];
        const [supplierIndex, invoiceIndex, fieldName, fileIndex] = getBracketValues(targetName);
        const files = event.target.files;
        const newFiles = [...files];
        const currentFiles = invoices[supplierIndex][invoiceIndex][snakeToCamelCase(fieldName)] || [];
        const updatedFiles = removeDuplicateFiles([
            ...currentFiles.slice(0, parseInt(fileIndex)),
            ...newFiles,
            ...currentFiles.slice(parseInt(fileIndex) + 1, currentFiles.length)
        ]);
        dispatch({
            type: 'UPDATE_INVOICE_FILES',
            supplierIndex: parseInt(supplierIndex),
            invoiceIndex: parseInt(invoiceIndex),
            fieldName: snakeToCamelCase(fieldName),
            files: updatedFiles
        });
        validateInvoiceDocumentsFrontend(updatedFiles, parseInt(supplierIndex), parseInt(invoiceIndex), snakeToCamelCase(fieldName));
        event.target.value = '';
    };
    const handleRemoveInvoiceFile = (event) => {
        const targetName = event.target['name'];
        const [supplierIndex, invoiceIndex, fieldName, fileIndex] = getBracketValues(targetName);
        const fieldFiles = invoices[supplierIndex][invoiceIndex][snakeToCamelCase(fieldName)];
        const updatedFiles = [
            ...fieldFiles.slice(0, parseInt(fileIndex)),
            ...fieldFiles.slice(parseInt(fileIndex) + 1, fieldFiles.length)
        ];
        dispatch({
            type: 'UPDATE_INVOICE_FILES',
            supplierIndex: parseInt(supplierIndex),
            invoiceIndex: parseInt(invoiceIndex),
            fieldName: snakeToCamelCase(fieldName),
            files: updatedFiles
        });
        validateInvoiceDocumentsFrontend(updatedFiles, parseInt(supplierIndex), parseInt(invoiceIndex), snakeToCamelCase(fieldName));
    };
    const generateFileErrors = (file, supplierIndex, invoiceIndex) => {
        const fileSizeValidations = validateFileSize(file, 20000000)
            ? []
            : [`The file needs to be less than 20MB.`];
        const invoice = invoices[supplierIndex][invoiceIndex];
        const existingFileNameList = compileExistingFileNames(invoice);
        const existingFileValidations = validateTradeLoanDuplicateFile(file, existingFileNameList)
            ? []
            : [`The file already exists.`];
        const validations = [...fileSizeValidations, ...existingFileValidations];
        return validations;
    };
    const totalErrors = (files, supplierIndex, invoiceIndex) => {
        return files.reduce((acc, file) => {
            if (file) {
                const errors = generateFileErrors(file, supplierIndex, invoiceIndex);
                if (errors.length > 0) {
                    return { ...acc, [file.name]: errors };
                }
                else {
                    return acc;
                }
            }
            else {
                return acc;
            }
        }, {});
    };
    const validateInvoiceDocumentsFrontend = (files, supplierIndex, invoiceIndex, fieldName) => {
        dispatch({
            type: 'UPDATE_INVOICE_DOCUMENT_ERRORS',
            supplierIndex,
            invoiceIndex,
            fieldName,
            errors: totalErrors(files, supplierIndex, invoiceIndex)
        });
    };
    const saveInvoice = async (supplierIndex, invoiceIndex) => {
        const invoice = invoices[supplierIndex][invoiceIndex];
        const documentErrors = invoiceDocumentErrors[supplierIndex][invoiceIndex];
        if (isEmptyObject(documentErrors)) {
            if (invoice.id) {
                updateInvoice(supplierIndex, invoiceIndex);
            }
            else {
                createInvoice(supplierIndex, invoiceIndex);
            }
        }
        else {
            showNotifyToast({
                text: 'Please check if selected files are valid.',
                type: 'error'
            });
        }
    };
    const updateInvoice = async (supplierIndex, invoiceIndex) => {
        updateSavingStatus(supplierIndex, invoiceIndex, true);
        try {
            const invoice = invoices[supplierIndex][invoiceIndex];
            const supplier = suppliers[supplierIndex];
            const body = {
                invoiceDetails: invoice
            };
            if (!supplier.id || !invoice.id) {
                throw new Error('Not allowed to update an invoice details without a supplier id or invoice id.');
            }
            const response = await updateInvoiceDetails(apiToken, tradeLoanId, supplier.id, invoice.id, body, urlPath);
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            if (isAPIValidationErrors(response)) {
                dispatch({
                    type: 'UPDATE_SINGLE_INVOICE_ERRORS',
                    supplierIndex,
                    invoiceIndex,
                    errors: response.errors
                });
                showNotifyToast({
                    text: 'Please check if all fields are filled out correctly.',
                    type: 'error'
                });
            }
            else {
                // clear previous errors
                dispatch({
                    type: 'UPDATE_SINGLE_INVOICE_ERRORS',
                    supplierIndex,
                    invoiceIndex,
                    errors: {}
                });
                dispatch({
                    type: 'REFRESH_INVOICE',
                    supplierIndex,
                    invoiceIndex,
                    invoiceDetails: response.data.attributes
                });
                showNotifyToast({
                    text: 'Successfully updated invoice.',
                    type: 'success'
                });
            }
        }
        catch {
            showNotifyToast({
                text: 'Failed to update invoice. Please try again.',
                type: 'error'
            });
        }
        updateSavingStatus(supplierIndex, invoiceIndex, false);
    };
    const createInvoice = async (supplierIndex, invoiceIndex) => {
        updateSavingStatus(supplierIndex, invoiceIndex, true);
        try {
            const invoice = invoices[supplierIndex][invoiceIndex];
            const supplier = suppliers[supplierIndex];
            const body = {
                invoiceDetails: invoice
            };
            if (!supplier.id) {
                throw new Error('Not allowed to create an invoice details without a supplier id.');
            }
            const response = await createInvoiceDetails(apiToken, tradeLoanId, supplier.id, body, urlPath);
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            if (isAPIValidationErrors(response)) {
                dispatch({
                    type: 'UPDATE_SINGLE_INVOICE_ERRORS',
                    supplierIndex,
                    invoiceIndex,
                    errors: response.errors
                });
                showNotifyToast({
                    text: 'Please check if all fields are filled out correctly.',
                    type: 'error'
                });
            }
            else {
                // clear previous errors
                dispatch({
                    type: 'UPDATE_SINGLE_INVOICE_ERRORS',
                    supplierIndex,
                    invoiceIndex,
                    errors: {}
                });
                dispatch({
                    type: 'REFRESH_INVOICE',
                    supplierIndex,
                    invoiceIndex,
                    invoiceDetails: response.data.attributes
                });
                showNotifyToast({
                    text: 'Successfully created invoice.',
                    type: 'success'
                });
            }
        }
        catch {
            showNotifyToast({
                text: 'Failed to create invoice. Please try again.',
                type: 'error'
            });
        }
        updateSavingStatus(supplierIndex, invoiceIndex, false);
    };
    const removeInvoice = async (supplierIndex, invoiceIndex) => {
        const supplier = suppliers[supplierIndex];
        const invoice = invoices[supplierIndex][invoiceIndex];
        if (!supplier.id) {
            throw new Error('Not allowed to remove an invoice details without a supplier id.');
        }
        if (invoice.id) {
            if (window.confirm('Are you sure you want to delete this existing invoice?')) {
                try {
                    const response = await removeInvoiceDetails(apiToken, tradeLoanId, supplier.id, invoice.id, urlPath);
                    if (isAPIErrors(response)) {
                        throw response.errors;
                    }
                    showNotifyToast({
                        text: 'Successfully deleted invoice.',
                        type: 'success'
                    });
                    dispatch({
                        type: 'REMOVE_INVOICE',
                        supplierIndex,
                        invoiceIndex
                    });
                }
                catch {
                    showNotifyToast({
                        text: 'Failed to delete invoice. Please try again.',
                        type: 'error'
                    });
                }
            }
        }
        else {
            dispatch({
                type: 'REMOVE_INVOICE',
                supplierIndex,
                invoiceIndex
            });
        }
    };
    const handleDeleteInvoiceFile = (event) => {
        const targetName = event.target['name'];
        const [supplierIndex, invoiceIndex, fieldName, fileIndex] = getBracketValues(targetName);
        removeInvoiceFile(fieldName, parseInt(supplierIndex), parseInt(invoiceIndex), parseInt(fileIndex));
    };
    const removeInvoiceFile = async (fieldName, supplierIndex, invoiceIndex, fileIndex) => {
        const supplier = suppliers[supplierIndex];
        const invoice = invoices[supplierIndex][invoiceIndex];
        const body = {
            fieldName,
            index: fileIndex
        };
        if (!supplier.id || !invoice.id) {
            throw new Error('Not allowed to remove an invoice file without a supplier id or invoice id.');
        }
        if (window.confirm('Are you sure you want to delete this existing file?')) {
            try {
                const response = await removeInvoiceDetailsFile(apiToken, tradeLoanId, supplier.id, invoice.id, body, urlPath);
                if (isAPIErrors(response) || isAPIValidationErrors(response)) {
                    throw response.errors;
                }
                const existingFieldName = snakeToCamelCase(`existing_${fieldName}`);
                const fieldFiles = invoices[supplierIndex][invoiceIndex][existingFieldName];
                const updatedFiles = [
                    ...fieldFiles.slice(0, fileIndex),
                    ...fieldFiles.slice(fileIndex + 1, fieldFiles.length)
                ];
                dispatch({
                    type: 'UPDATE_INVOICE_FILES',
                    supplierIndex: supplierIndex,
                    invoiceIndex: invoiceIndex,
                    fieldName: existingFieldName,
                    files: updatedFiles
                });
                showNotifyToast({
                    text: 'Successfully deleted file.',
                    type: 'success'
                });
            }
            catch {
                showNotifyToast({
                    text: 'Failed to delete file. Please try again.',
                    type: 'error'
                });
            }
        }
    };
    const getInvoiceCount = () => {
        return invoices.reduce((sum, invoiceList) => {
            return sum + invoiceList.length;
        }, 0);
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: 'sidebar' },
            React.createElement(InvoicesEditSidebar, { navItems: navItems, invoiceCount: getInvoiceCount() })),
        React.createElement("div", { className: 'invoices-edit-dashboard body', "data-testid": 'invoices-edit-dashboard' }, suppliers.map((supplier, index) => (React.createElement(InvoicePanel, { ...{
                supplier,
                index,
                invoiceDateSettings,
                invoiceCurrencyOptions,
                handleInvoiceDetailUpdate,
                handleInvoiceAmountValueChange,
                handleInvoiceFocus,
                handleInvoiceCurrencyOptionChange,
                handleInvoiceFileChange,
                handleInvoiceFileReplace,
                handleRemoveInvoiceFile,
                dispatch
            }, invoices: invoices[index], invoiceErrors: invoiceErrors[index], invoiceDocumentErrors: invoiceDocumentErrors[index], selectedInvoiceCurrencyOptions: selectedInvoiceCurrencyOptions[index], invoiceAmountValues: invoiceAmountValues[index], key: index, saveInvoice: saveInvoice, removeInvoice: removeInvoice, handleDeleteInvoiceFile: handleDeleteInvoiceFile, isSavingList: savingStatuses[index] }))))));
};
export default InvoicesEditDashboard;
