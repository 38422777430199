import React, { useState, useEffect } from 'react';
import { fetchBorrowerCompanyAssignedAdmins as defaultFetchBorrowerCompanyAssignedAdmins } from '../../api/borrower_company';
import { showNotifyToast } from '../../utils';
import AssignedAdminsList from './AssignedAdminsList';
const BorrowerAssignedAdminsPanel = ({ apiToken, borrowerId, fetchBorrowerCompanyAssignedAdmins = defaultFetchBorrowerCompanyAssignedAdmins }) => {
    const [assignedAdmins, setAssignedAdmins] = useState([]);
    const fetchData = async () => {
        try {
            const { data: assignedAdminData } = await fetchBorrowerCompanyAssignedAdmins(apiToken, borrowerId);
            setAssignedAdmins(assignedAdminData.map(admin => admin.attributes));
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong while fetching admins. Please try again.',
                type: 'error'
            });
        }
    };
    useEffect(() => {
        fetchData();
    }, []);
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'borrower-assigned-admins-panel' },
        React.createElement("div", { className: 'header -space-between' }, "Assigned Users"),
        React.createElement(AssignedAdminsList, { assignedAdmins: assignedAdmins, isAdmin: false })));
};
export default BorrowerAssignedAdminsPanel;
