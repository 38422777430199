import classNames from 'classnames';
import React from 'react';
const ToggleSwitch = ({ isToggled, isRequestPending = false, isSwitchDisabled = false, tooltipText, switchOn, switchOff, name, variant = 'safe', testId = 'toggle-switch' }) => {
    const displayTooltip = isSwitchDisabled && isToggled && tooltipText;
    const toggleSwitchClass = classNames('toggle-switch switch', {
        '-disabled': isSwitchDisabled,
        '-danger': variant === 'danger'
    });
    return (React.createElement("label", { className: toggleSwitchClass },
        React.createElement("input", { "data-testid": testId, type: 'checkbox', name: name, checked: isToggled, onChange: isToggled ? switchOff : switchOn, disabled: isRequestPending || isSwitchDisabled }),
        React.createElement("span", { className: 'track' },
            React.createElement("span", { className: 'peg' })),
        displayTooltip && (React.createElement("div", { className: 'tooltip-panel tooltip', "data-testid": 'tooltip' }, tooltipText))));
};
export default ToggleSwitch;
