import React from 'react';
const RegularLogo = ({ logoClass }) => {
    return (React.createElement("svg", { viewBox: '0 0 800 230', xmlns: 'http://www.w3.org/2000/svg', className: logoClass, "data-testid": 'earlypay-logo' },
        React.createElement("g", null,
            React.createElement("path", { d: 'M115.12 124.138H34.16C35.8 132.528 39.4 138.961 44.92 143.436C50.44 147.871 58.92 150.108 70.28 150.108C76.88 150.108 82.84 149.189 88.12 147.391C93.4 145.593 99.08 142.956 105.2 139.48L114.6 162.734C108.48 167.528 101.88 171.204 94.8 173.721C87.72 176.278 79.28 177.557 69.56 177.557C46.8 177.557 29.48 172.003 17.68 160.856C5.92 149.749 0 134.366 0 114.749C0 102.043 2.44 90.936 7.32 81.3469C12.2 71.7978 19.16 64.4063 28.24 59.2122C37.32 54.0182 48.04 51.4211 60.44 51.4211C72.48 51.4211 82.64 54.2179 90.88 59.8116C99.12 65.4052 105.24 72.9565 109.2 82.4257C113.16 91.8949 115.16 102.483 115.16 114.189V124.138H115.12ZM34.4 102.643H84.68C83.04 94.7316 80.08 88.7784 75.88 84.8229C71.68 80.8675 66.28 78.8698 59.68 78.8698C53.08 78.8698 47.56 80.9074 43.08 84.9428C38.64 89.0182 35.72 94.8914 34.4 102.643Z' }),
            React.createElement("path", { d: 'M134.199 89.0182L125.519 62.3287C129.799 59.5319 136.679 57.0147 146.079 54.7773C155.479 52.5399 162.999 51.4211 168.599 51.4211C178.159 51.4211 186.919 53.299 194.839 57.0947C202.759 60.8903 209.079 66.1643 213.799 72.9166C218.519 79.6688 220.839 87.2602 220.839 95.6506V174.6H199.799L194.359 161.735C184.279 172.283 173.719 177.557 162.679 177.557C148.319 177.557 137.519 174.041 130.239 167.049C122.959 160.057 119.359 150.588 119.359 138.721C119.359 126.535 123.479 117.066 131.719 110.394C139.959 103.721 151.039 100.365 164.919 100.365H188.439C188.279 94.2522 186.479 89.5376 183.119 86.1414C179.719 82.7853 175.079 81.0672 169.119 81.0672C163.679 81.0672 157.639 81.8663 151.039 83.4245C144.439 85.0227 138.839 86.9006 134.199 89.0182ZM146.839 138.761C146.839 142.397 148.279 145.514 151.159 148.151C154.039 150.788 157.719 152.106 162.159 152.106C171.719 152.106 180.559 148.151 188.639 140.24V121.941H164.399C159.119 121.941 154.879 123.579 151.639 126.895C148.439 130.171 146.839 134.127 146.839 138.761Z' }),
            React.createElement("path", { d: 'M266.921 174.6H234.721V54.6575H251.561L262.201 74.6746C267.961 69.5605 274.761 64.9657 282.521 60.8105C290.281 56.6952 297.121 54.6176 303.081 54.6176L309.521 80.3481C304.081 80.3481 297.361 82.3458 289.321 86.3013C281.321 90.2568 273.841 95.291 266.921 101.404V174.6V174.6Z' }),
            React.createElement("path", { d: 'M315.68 174.6V9.58904L347.64 0V174.6H315.68Z' }),
            React.createElement("path", { d: 'M389.48 180.314L395.16 166.21L353.84 59.6119L385.52 50.2227L413.24 127.374L439.48 54.6576H473.4L419.68 190.422C416.04 199.812 409.52 208.002 400.12 215.034C390.72 222.026 380.48 226.861 369.4 229.498L358 203.048C366.4 199.412 373.32 195.976 378.68 192.66C384.08 189.384 387.64 185.228 389.48 180.314Z' }),
            React.createElement("path", { d: 'M570.12 68.8812C580.28 80.5079 585.36 95.8903 585.36 114.988C585.36 127.175 583 138.042 578.32 147.511C573.6 156.98 566.92 164.412 558.28 169.766C549.6 175.12 539.4 177.797 527.72 177.797C518.96 177.797 509.8 174.76 500.24 168.647V222.585L480.68 228.738V54.6574H492.32L496.52 63.0478C501.48 59.412 506.76 56.5753 512.36 54.4976C517.96 52.42 523.24 51.4211 528.2 51.4211C545.96 51.4611 559.96 57.2545 570.12 68.8812ZM555.6 148.39C562.04 139.8 565.32 128.773 565.52 115.268C565.52 101.084 562.32 89.6574 556 81.0273C549.64 72.3572 540.44 68.0421 528.4 68.0421C522.44 68.0421 517.24 68.9611 512.8 70.759C508.36 72.5969 504.12 75.3937 500.16 79.1494V150.628C504.12 154.104 508.32 156.741 512.8 158.539C517.24 160.337 522.36 161.256 528.16 161.256C540 161.256 549.2 156.98 555.6 148.39Z' }),
            React.createElement("path", { d: 'M606.88 76.6724L601.2 61.8493C605.48 58.8927 611.8 56.4155 620.16 54.4178C628.48 52.46 635.4 51.4612 640.84 51.4612C649.92 51.4612 658 53.1792 665.12 56.6552C672.2 60.1313 677.76 64.8858 681.72 70.9988C685.68 77.1119 687.68 84.0239 687.68 91.7751V174.6H673.08L670.6 162.494C666.16 166.61 660.6 170.205 654 173.242C647.4 176.278 640.96 177.837 634.68 177.837C621.32 177.837 611.12 174.361 604.12 167.449C597.12 160.536 593.6 151.547 593.6 140.479C593.6 128.933 597.56 119.943 605.48 113.51C613.4 107.078 623.88 103.881 636.92 103.881H667.88V97.2089C667.88 88.2991 665.76 81.3071 661.56 76.1929C657.36 71.0787 650.72 68.5217 641.64 68.5217C634.56 68.5217 628.48 69.2409 623.44 70.6393C618.28 71.9977 612.8 74.0354 606.88 76.6724ZM611.56 140.479C611.56 146.233 613.44 151.267 617.24 155.582C621.04 159.857 626.64 162.015 634.08 162.015C640.52 162.015 646.68 160.377 652.52 157.06C658.36 153.744 663.52 149.469 668 144.195V116.986H636.32C628.56 116.986 622.48 119.264 618.12 123.778C613.76 128.333 611.56 133.887 611.56 140.479Z' }),
            React.createElement("path", { d: 'M729.2 194.378L736.64 173.841L692.08 58.3332L709.4 50.9017L745.8 147.351L780.2 54.6175H800L747 199.292C744.2 206.724 739.44 213.036 732.76 218.35C726.08 223.624 718.36 227.18 709.6 228.978L703.64 214.874C711.4 211.238 717.12 208.162 720.84 205.605C724.6 203.088 727.36 199.332 729.2 194.378Z' }))));
};
export default RegularLogo;
