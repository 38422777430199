import React, { Fragment, useState, useRef, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { fileNameFromUrl, replaceSpaces } from '../../utils';
import ErrorList from '../shared/ErrorList';
const baseStyle = {
    textAlign: 'center',
    display: 'block',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};
const focusedStyle = {
    borderColor: '#2196f3'
};
const acceptStyle = {
    borderColor: '#00e676'
};
const rejectStyle = {
    borderColor: '#ff1744'
};
const DocumentPicker = ({ inputName, errors, files, existingFiles, emptyFilesButtonText = 'Choose attachment', presentFilesButtonText = 'Add attachment', isRequired = false, handleRemoveFile, handleFileChange, handleFileReplace, handleDeleteFile }) => {
    const replacerRef = useRef(null);
    const [activeReplacerName, setActiveReplacerName] = useState('');
    const hasFiles = files && files.length > 0;
    const hasExistingFiles = existingFiles && existingFiles.length > 0;
    const numFiles = files && hasFiles ? files.length : 0;
    const numExistingFiles = existingFiles && hasExistingFiles ? existingFiles.length : 0;
    const hasSingleFile = numFiles + numExistingFiles === 1;
    const showDeleteExistingButton = !hasSingleFile || !isRequired;
    const pickerButtonText = hasFiles
        ? presentFilesButtonText
        : emptyFilesButtonText;
    const handleReplaceClick = (event) => {
        setActiveReplacerName(event.target['name']);
        if (replacerRef && replacerRef.current) {
            replacerRef.current.click();
        }
    };
    const onDrop = acceptedFiles => {
        const allAcceptedFiles = acceptedFiles.length > 0 ? acceptedFiles : [];
        handleFileChange(allAcceptedFiles, inputName);
    };
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        onDrop: onDrop
    });
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [isFocused, isDragAccept, isDragReject]);
    return (React.createElement("div", { className: 'document-picker documents', "data-testid": 'document-picker' },
        existingFiles && hasExistingFiles && (React.createElement("div", { className: 'files' }, existingFiles.map((file, index) => (React.createElement(Fragment, { key: `existing-${index}` },
            React.createElement("div", { className: 'file-preview file -trade-loan', key: index, "data-testid": 'existing-file-details' },
                React.createElement("div", { className: 'filename' }, fileNameFromUrl(file.url)),
                React.createElement("div", { className: 'controls' }, showDeleteExistingButton && (React.createElement("button", { className: 'custom-button button -document-picker -remove', type: 'button', "data-testid": 'remove-existing-file', name: `${inputName}[${index}]`, onClick: handleDeleteFile }, "Delete Attachment"))))))))),
        files && hasFiles && (React.createElement("div", { className: 'files' }, files.map((file, index) => (React.createElement(Fragment, { key: index },
            React.createElement("div", { className: 'file-preview file -trade-loan', key: index, "data-testid": 'file-details' },
                React.createElement("div", { className: 'filename' }, replaceSpaces(file.name, '_')),
                React.createElement("div", { className: 'controls' },
                    React.createElement("button", { className: 'custom-button button -document-picker -replace', type: 'button', onClick: handleReplaceClick, "data-testid": `replace-${index}`, name: `${inputName}[${index}]` }, "Replace attachment"),
                    React.createElement("div", { className: 'divider' }),
                    React.createElement("button", { className: 'custom-button button -document-picker -remove', type: 'button', onClick: handleRemoveFile, "data-testid": 'remove', name: `${inputName}[${index}]` }, "Delete Attachment"))),
            React.createElement(ErrorList, { errors: errors, field: file.name })))))),
        React.createElement("section", { className: 'container dropzone', "data-testid": 'picker' },
            React.createElement("div", { className: 'attachments', ...getRootProps({ style }) },
                React.createElement("input", { ...getInputProps() }),
                React.createElement("p", { className: 'content' }, "Drop your attachment here"),
                React.createElement("p", { className: 'content' }, "OR"),
                React.createElement("button", { className: 'button', type: 'button', "data-testid": 'choose-file' }, pickerButtonText))),
        React.createElement("input", { className: 'input', type: 'file', name: activeReplacerName, "data-testid": 'replacer', onChange: handleFileReplace, multiple: true, ref: replacerRef })));
};
export default DocumentPicker;
