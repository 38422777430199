import React from 'react';
const DesktopMenu = ({ menuClass, signUpButtonClass }) => {
    return (React.createElement("div", { className: menuClass },
        React.createElement("a", { href: '/about', className: 'link' }, "About"),
        React.createElement("a", { href: '/how-it-works', className: 'link' }, "How It Works"),
        React.createElement("a", { href: 'https://blog.earlypay.com.au', className: 'link' }, "Blog"),
        React.createElement("a", { href: '/login', className: 'link' }, "Log In"),
        React.createElement("a", { href: '/register', className: signUpButtonClass }, "Sign Up")));
};
export default DesktopMenu;
