import React from 'react';
import classNames from 'classnames';
const SubItem = ({ subItem, className }) => {
    const subItemClass = classNames('item', {
        '-active': subItem.isActive || className === 'active',
        '-check': subItem.showCheck && subItem.isChecked,
        '-cross': subItem.showCheck && !subItem.isChecked
    });
    return (React.createElement("a", { className: subItemClass, href: subItem.path, "data-testid": 'sub-item-link' }, subItem.name));
};
export default SubItem;
