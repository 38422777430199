import React from 'react';
import CompanyName from '../CompanyName';
const NewInvoice = ({ event }) => {
    const { attributes } = event;
    const { companyName, companyLink, customerLink, createdAtFormatted, invoiceLink, eventable: { attributes: { number: invoiceNumber, amountFormatted, customerName } } } = attributes;
    return (React.createElement("div", { className: 'event-box event', "data-testid": 'new-invoice-activity' },
        React.createElement("div", { className: 'icon' },
            React.createElement("div", { className: 'activity-icon -new-invoice' })),
        React.createElement("div", { className: 'description' },
            React.createElement("div", { className: 'title' },
                React.createElement(CompanyName, { name: companyName, companyLink: companyLink }),
                " raised a new invoice",
                ' ',
                React.createElement("span", { className: 'bold' },
                    React.createElement("a", { href: invoiceLink }, invoiceNumber)),
                ' ',
                "of",
                React.createElement("span", { className: 'amount -green' },
                    " ",
                    amountFormatted,
                    " "),
                "for ",
                React.createElement("a", { href: customerLink }, customerName),
                React.createElement("div", { className: 'date' }, createdAtFormatted)))));
};
export default NewInvoice;
