import React, { useRef } from 'react';
import CustomFlatpickr from '../shared/CustomFlatpickr';
const DateRangePicker = () => {
    const flatpickrSettings = {
        wrap: true,
        altInput: true,
        altFormat: 'd M Y',
        dateFormat: 'Y-m-d',
        mode: 'range'
    };
    const dateClearRef = useRef(null);
    const datePickerRef = useRef(null);
    return (React.createElement("div", null,
        React.createElement("div", { className: 'label' }, "Date Range"),
        React.createElement(CustomFlatpickr, { ...{ flatpickrSettings }, forwardDatePickerRef: datePickerRef, forwardDateClearRef: dateClearRef, selectedDate: '', pickerBackground: 'white', placeholderText: 'Filter by date', clearButtonText: 'Clear Date', name: 'date_range' })));
};
export default DateRangePicker;
