import React, { useState } from 'react';
import Modal from '../shared/Modal';
const CloseOutModal = ({ path, companyName }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => {
        setIsOpen(!isOpen);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'actions action-buttons' },
            React.createElement("button", { className: 'button -primary -full-width -center', "data-testid": 'close-out-company-button', onClick: () => toggleModal(), type: 'button' }, "Close out company")),
        React.createElement(Modal, { isOpen: isOpen, onClose: toggleModal },
            React.createElement("div", { className: 'custom-panel -modal', role: 'dialog', "aria-modal": 'true', "data-testid": 'close-out-modal' },
                React.createElement("div", { className: 'header' }, "Confirmation"),
                React.createElement("div", { className: 'content' },
                    React.createElement("div", { className: 'section' },
                        React.createElement("div", { className: 'description' },
                            "Are you sure you want to close out",
                            ' ',
                            React.createElement("strong", { "data-testid": 'company-name' }, companyName),
                            "? Click confirm to continue."))),
                React.createElement("div", { className: 'action-buttons buttons space -border -padding' },
                    React.createElement("button", { className: 'button -neutral', type: 'button', "data-testid": 'cancel-close-out', onClick: toggleModal }, "Cancel"),
                    React.createElement("a", { className: 'button -primary', "data-testid": 'confirm-close-out', "data-method": 'put', href: path }, "Confirm"))))));
};
export default CloseOutModal;
