import fetch from 'isomorphic-fetch';
import { buildHeaders } from './shared';
export const fetchBorrowerCompany = async (apiToken, borrowerId, scope) => {
    const endpoint = `/api/${scope}/${borrowerId}/company`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const fetchBorrowerCompanyAssignedAdmins = async (apiToken, borrowerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/company/assigned_admins`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const fetchBorrowerPrimaryClientManager = async (apiToken, borrowerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/company/primary_client_manager`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const setBorrowerCompanyAssignedAdmins = async (apiToken, borrowerId, assignedAdminIds, assignedPrimaryAdminId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/company/set_assigned_admins`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify({
            admin_ids: assignedAdminIds,
            primary_admin_id: assignedPrimaryAdminId
        })
    }).then((res) => res.json());
};
export const updateBorrowerCompanyBlurb = async (apiToken, blurb, borrowerId, scope) => {
    const endpoint = `/api/${scope}/${borrowerId}/company/update_blurb`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(blurb)
    }).then((res) => res.json());
};
export const fetchFundingAvailable = async (apiToken, companyId) => {
    const endpoint = `/api/admin/companies/${companyId}/fetch_funding_available`;
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'GET'
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.map(error => error.title).join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
export const setIsInsured = async (apiToken, borrowerId, insuranceStatus) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/company/update_is_insured`;
    const body = {
        is_insured: insuranceStatus
    };
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'PUT',
            body: JSON.stringify(body)
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.map(error => error.title).join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
export const enableInvoiceBatch = async (apiToken, borrowerId, isEnabled) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/company/update_invoice_batch_setting`;
    const body = {
        invoice_batch_enabled: isEnabled
    };
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'PUT',
            body: JSON.stringify(body)
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.map(error => error.title).join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
