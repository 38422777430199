import React from 'react';
import CustomTooltip from './CustomTooltip';
import XAxisTick from './XAxisTick';
import YAxisTick from './YAxisTick';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { convertCents } from '../../utils/convertCents';
const LoansOutstandingChart = ({ placeholderImage, transactionHistory }) => {
    const newTransactions = transactionHistory.map(({ recordDate, totalOutstandingCents }) => {
        return {
            date: recordDate,
            totalOutstanding: convertCents(totalOutstandingCents)
        };
    });
    const xAxisInterval = () => {
        if (transactionHistory.length > 300) {
            return 30;
        }
        if (transactionHistory.length > 90) {
            return 10;
        }
        if (transactionHistory.length > 60) {
            return 5;
        }
        if (transactionHistory.length > 30) {
            return 3;
        }
        return 1;
    };
    return (React.createElement("div", { className: 'flex-grid -collapse', "data-testid": 'loans-outstanding-chart' }, transactionHistory.length > 0 ? (React.createElement(ResponsiveContainer, { width: '100%', height: 320 },
        React.createElement(AreaChart, { data: newTransactions },
            React.createElement(CartesianGrid, { vertical: false, stroke: '#EBECF0' }),
            React.createElement(XAxis, { dataKey: 'date', tick: React.createElement(XAxisTick, null), interval: xAxisInterval(), height: 40, tickLine: false }),
            React.createElement(YAxis, { axisLine: false, dataKey: 'totalOutstanding', tickLine: false, tick: React.createElement(YAxisTick, null), interval: 'preserveStartEnd', width: 80 }),
            React.createElement(Area, { type: 'linear', dataKey: 'totalOutstanding', stroke: '#2684FF', strokeWidth: 4, fill: 'rgba(76, 154, 255, 0.3)', dot: false, activeDot: { strokeWidth: 0, r: 6 } }),
            React.createElement(Tooltip, { cursor: { strokeWidth: 1, stroke: 'rgba(38, 132, 255, 0.4)' }, content: React.createElement(CustomTooltip, null) })))) : (React.createElement("div", { className: 'placeholder-pane' },
        React.createElement("div", { className: 'image' },
            React.createElement("img", { src: placeholderImage })),
        React.createElement("div", { className: 'title' }, "Oops!"),
        React.createElement("div", { className: 'body' }, "Looks like you don\u2019t have any data yet.")))));
};
export default LoansOutstandingChart;
