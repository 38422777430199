import React from 'react';
import PaginationBar from '../../components/shared/PaginationBar';
const CreditNotesTable = ({ creditNotes, isLoading, handlePageClick, currentPage, pageSize, totalPages, totalItems }) => {
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'credit-notes-table' },
        isLoading ? (React.createElement("table", { className: 'custom-table' },
            React.createElement("tbody", { "data-testid": 'loading' },
                React.createElement("tr", null,
                    React.createElement("td", { className: 'cell', colSpan: 12 },
                        React.createElement("div", { className: 'loading-container -table' },
                            React.createElement("div", { className: 'loading-spinner' }))))))) : (React.createElement("table", { className: 'custom-table' },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { className: 'header' }, "No."),
                    React.createElement("th", { className: 'header' }, "Remaining Credit"),
                    React.createElement("th", { className: 'header' }, "Amount"),
                    React.createElement("th", { className: 'header' }, "Issued Date"))),
            React.createElement("tbody", null, creditNotes.length !== 0 ? (creditNotes.map((creditNote, i) => (React.createElement("tr", { key: i },
                React.createElement("td", { className: 'cell' }, creditNote.number),
                React.createElement("td", { className: 'cell' }, creditNote.remainingCreditFormatted),
                React.createElement("td", { className: 'cell' }, creditNote.amountFormatted),
                React.createElement("td", { className: 'cell' }, creditNote.issueDateFormatted))))) : (React.createElement("tr", { "data-testid": 'no-record' },
                React.createElement("td", { className: 'placeholder', colSpan: 12 }, "There are no transactions to display")))))),
        totalItems > pageSize && (React.createElement(PaginationBar, { ...{
                currentPage,
                pageSize,
                totalPages,
                totalItems,
                handlePageClick
            }, resource: 'credit_notes' }))));
};
export default CreditNotesTable;
