import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Activity from './Activity';
import Loading from '../shared/Loading';
import Prompt from './Prompt';
const ActivityFeed = (props) => {
    const { events, isLoading, hasErrors, hasMore, nextFetches, userAvatarUrl, currentUserId } = props;
    const withEvents = events.length !== 0;
    const infiniteScrollStyles = {
        overflow: 'visible'
    };
    if (isLoading) {
        return (React.createElement("div", { className: 'activity-feed feed', "data-testid": 'activity-feed-loading' },
            React.createElement(Loading, null)));
    }
    if (hasErrors) {
        return (React.createElement("div", { className: 'activity-feed feed', "data-testid": 'activity-feed-errors' },
            React.createElement("div", { className: 'placeholder-pane' },
                React.createElement("div", { className: 'image' },
                    React.createElement("div", { className: 'custom-icon -activity-feed-placeholder' },
                        React.createElement("i", { className: 'icon' }))),
                React.createElement("div", { className: 'title' }, "Uh oh!"),
                React.createElement("div", { className: 'body' }, "Could not fetch activities."))));
    }
    return (React.createElement("div", { className: 'activity-feed feed', "data-testid": 'activity-feed-loaded' },
        React.createElement(InfiniteScroll, { dataLength: events.length, hasMore: hasMore, next: nextFetches, loader: withEvents && React.createElement(Loading, null), endMessage: withEvents && React.createElement(Prompt, { message: 'No more activities to load.' }), style: infiniteScrollStyles },
            withEvents &&
                events.map((event, i) => (React.createElement(Activity, { event: event, key: i, userAvatarUrl: userAvatarUrl, currentUserId: currentUserId }))),
            !isLoading && !withEvents && (React.createElement(Prompt, { message: 'No activities to display.' })))));
};
export default ActivityFeed;
