import React from 'react';
import { randomEmptyMessage } from '../../utils';
import DrawdownRow from './DrawdownRow';
const DrawdownTableBody = ({ drawdownArray, isLoading, path, processedDrawdown, fetchDrawdowns, showABA }) => {
    if (isLoading) {
        return (React.createElement("tbody", { "data-testid": 'loading' },
            React.createElement("tr", null,
                React.createElement("td", { className: 'cell', colSpan: 12 },
                    React.createElement("div", { className: 'loading-container -table' },
                        React.createElement("div", { className: 'loading-spinner' }))))));
    }
    if (drawdownArray.length === 0) {
        const emptyMessageArray = [
            'Woohoo! No Drawdowns to process!',
            'Yay! Nothing to do!',
            'Relax! Nothing to see here.'
        ];
        return (React.createElement("tbody", { "data-testid": 'no-record' },
            React.createElement("tr", null,
                React.createElement("td", { className: 'cell', colSpan: 12 },
                    React.createElement("div", { className: 'loading-container -table' }, randomEmptyMessage(emptyMessageArray))))));
    }
    return (React.createElement("tbody", { "data-testid": 'table-drawdown' }, drawdownArray.map((drawdown) => {
        return (React.createElement(DrawdownRow, { key: drawdown.id, ...{
                drawdown,
                path,
                processedDrawdown,
                fetchDrawdowns,
                showABA
            } }));
    })));
};
export default DrawdownTableBody;
