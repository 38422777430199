import classNames from 'classnames';
import React, { Fragment } from 'react';
import Select from 'react-select';
import { findOptionByValue } from '../../utils';
import ErrorList from '../shared/ErrorList';
const AddressForm = ({ regions, action, setAction, address, path, csrfToken, handleNewButton, errors }) => {
    const addressTypes = [
        { value: 'POBOX', label: 'Postal Address' },
        { value: 'STREET', label: 'Street Address' }
    ];
    const formUrl = action === 'edit' ? `${path}/${address.id}` : path;
    const handleCancel = e => {
        e.preventDefault();
        setAction('view');
    };
    const header = `${action === 'new' ? 'New' : 'Edit'} Address`;
    const currentRegion = findOptionByValue(regions, address.region);
    const inputClass = (inputName) => classNames('input', {
        'form-control': inputName !== 'region',
        '-error': Object.keys(errors).includes(inputName),
        '-gap': inputName !== 'line_one',
        '-no-spinner': inputName === 'post_code'
    });
    if (action === 'view') {
        return (React.createElement("div", { className: 'content custom-panel', "data-testid": 'new-address-button' },
            React.createElement("button", { className: 'custom-button button -add-form', onClick: handleNewButton }, "Add new address")));
    }
    return (React.createElement("div", { className: 'content custom-panel', "data-testid": 'address-form' },
        React.createElement("div", { className: 'header', "data-testid": 'address-form-header' }, header),
        React.createElement("form", { method: 'post', action: formUrl, className: 'form form-pane' },
            action === 'edit' && (React.createElement("input", { type: 'hidden', name: '_method', value: 'put', "data-testid": 'edit-input' })),
            React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { htmlFor: 'line_one', className: 'label -required' }, "Name"),
                React.createElement("input", { type: 'text', name: 'address[line_one]', defaultValue: address.lineOne ? address.lineOne : '', className: inputClass('line_one'), placeholder: 'e.g Main Office, Level 19', required: true }),
                React.createElement(ErrorList, { errors: errors, field: 'line_one' })),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { htmlFor: 'lineTwo', className: 'label' }, "Address"),
                React.createElement("input", { type: 'text', name: 'address[line_two]', defaultValue: address.lineTwo ? address.lineTwo : '', className: inputClass('line_two'), placeholder: 'e.g 418A Elizabeth St, Surry Hills' })),
            React.createElement("div", { className: 'form-group -inline' },
                React.createElement("div", { className: 'input' },
                    React.createElement("label", { htmlFor: 'city', className: 'label -required' }, "City/Municipality"),
                    React.createElement("input", { type: 'text', name: 'address[city]', defaultValue: address.city ? address.city : '', className: inputClass('city'), placeholder: 'e.g Leichhardt', required: true }),
                    React.createElement(ErrorList, { errors: errors, field: 'city' })),
                React.createElement("div", { className: 'input -medium' },
                    React.createElement("label", { className: 'label -required', htmlFor: 'region' }, "Region"),
                    React.createElement(Select, { id: 'region', options: regions, defaultValue: currentRegion, name: 'address[region]', className: inputClass('region') }),
                    React.createElement(ErrorList, { errors: errors, field: 'region' })),
                React.createElement("div", { className: 'input -short' },
                    React.createElement("label", { htmlFor: 'post_code', className: 'label -required' }, "Postal Code"),
                    React.createElement("input", { type: 'number', name: 'address[post_code]', defaultValue: address.postCode ? address.postCode : '', className: inputClass('post_code'), placeholder: 'e.g 2010', required: true }),
                    React.createElement(ErrorList, { errors: errors, field: 'post_code' }))),
            React.createElement("div", { className: 'form-group' },
                React.createElement("div", { className: 'custom-toggle' }, addressTypes.map((type, key) => (React.createElement(Fragment, { key: key },
                    React.createElement("input", { type: 'radio', className: 'button', name: 'address[kind]', id: `kind_${type.value}`, value: type.value, defaultChecked: address.kind === type.value }),
                    React.createElement("label", { className: 'label', htmlFor: `kind_${type.value}` }, type.label))))),
                React.createElement(ErrorList, { errors: errors, field: 'kind' })),
            React.createElement("div", { className: 'action-buttons' },
                React.createElement("button", { className: 'custom-button -cancel -narrow -margin-right-8px', onClick: handleCancel, "data-testid": 'cancel-action' }, "Cancel"),
                React.createElement("input", { value: 'Save', type: 'submit', className: 'custom-button -default -narrow' })))));
};
export default AddressForm;
