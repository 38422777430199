export const isAPIValidationErrors = (arg) => {
    return arg.errors && arg.errorType === 'validation';
};
export const isAPIValidationMultipleErrors = (arg) => {
    return arg.multipleErrors && arg.errorType === 'validation';
};
export const isAPIValidationMultipleArrayErrors = (arg) => {
    return arg.multipleArrayErrors && arg.errorType === 'validation';
};
export const isAPIErrors = (arg) => {
    return Array.isArray(arg.errors);
};
