import React from 'react';
import NumberFormat from 'react-number-format';
import { computeDrawdownFee, computeNetAmount } from '../../utils';
const Receipt = ({ requestedAmount, drawdownFeeRate = 0, gst = 0, minimumDrawdownFee = 0, reserveAmount = 0 }) => {
    const amount = typeof requestedAmount === 'number' ? requestedAmount : 0;
    const drawdownFee = computeDrawdownFee(amount, drawdownFeeRate, minimumDrawdownFee, reserveAmount);
    return (React.createElement("div", { className: 'drawdown-receipt receipt', "data-testid": 'receipt' },
        React.createElement("div", { className: 'item' },
            React.createElement("div", { className: 'name' }, "Amount Requested"),
            React.createElement(NumberFormat, { value: requestedAmount, displayType: 'text', thousandSeparator: true, prefix: '$', renderText: value => React.createElement("div", { className: 'price -negative' }, value) })),
        React.createElement("div", { className: 'item' },
            React.createElement("div", { className: 'name' }, "Drawdown Fee"),
            React.createElement(NumberFormat, { value: drawdownFee, displayType: 'text', thousandSeparator: true, prefix: drawdownFee > 0 ? '-$' : '', renderText: value => (React.createElement("div", { className: 'price -negative' }, value !== '0' ? value : '0')) })),
        gst > 0 && (React.createElement("div", { className: 'item' },
            React.createElement("div", { className: 'name' }, "GST"),
            React.createElement(NumberFormat, { value: gst, displayType: 'text', thousandSeparator: true, prefix: '-$', renderText: value => React.createElement("div", { className: 'price -negative' }, value) }))),
        React.createElement("div", { className: 'item -total' },
            React.createElement("div", { className: 'name' }, "Net Amount"),
            React.createElement(NumberFormat, { value: computeNetAmount(amount, drawdownFee, gst), displayType: 'text', decimalScale: 2, thousandSeparator: true, prefix: '$', renderText: value => React.createElement("div", { className: 'price' }, value) }))));
};
export default Receipt;
