import fetch from 'isomorphic-fetch';
export const fetchAvailableClients = async (apiToken) => {
    const endpoint = `/api/admin/broker/available_clients`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const setBrokerClient = async (apiToken, brokerCompanyId, clientId) => {
    const endpoint = `/api/admin/brokers/${brokerCompanyId}/company/assign_client`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'POST',
        body: JSON.stringify({ client_id: clientId })
    }).then((res) => res.json());
};
