import React from 'react';
import SupplierDetailsEdit from './SupplierDetailsEdit';
import PaymentDetailsForm from './PaymentDetails';
import SpinnerButton from '../shared/SpinnerButton';
const SupplierEditPanel = ({ index, currentSupplierDetails, currentPaymentDetails, supplierDetailsErrors, supplierDocumentErrors, paymentDetailsErrors, isSaving, displayRemoveButton = true, handleSupplierDetailUpdate, handlePaymentDetailUpdate, handleConfirmPaymentDetailsToggle, saveSupplierDetails, createNewSupplierDetails, removeSupplier, handleSupplierFileChange, handleRemoveSupplierFile, handleSupplierFileReplace, handleDeleteSupplierFile }) => {
    const isFirstPanel = index === 0;
    const handleSave = () => {
        if (currentSupplierDetails.id) {
            saveSupplierDetails(index);
        }
        else {
            createNewSupplierDetails(index);
        }
    };
    return (React.createElement("div", { className: 'custom-panel supplier-panel -collapsible -trade-loan', "data-testid": 'supplier-edit-panel' },
        React.createElement("input", { className: 'toggle', id: `supplier-${index}-label`, "data-testid": `supplier-${index}-checkbox`, type: 'checkbox', defaultChecked: isFirstPanel }),
        React.createElement("label", { className: 'header -space-between', htmlFor: `supplier-${index}-label` }, `Supplier ${index + 1}`),
        React.createElement("div", { className: 'body -padded' },
            React.createElement(SupplierDetailsEdit, { ...{
                    index,
                    currentSupplierDetails,
                    supplierDetailsErrors,
                    supplierDocumentErrors,
                    handleSupplierDetailUpdate,
                    handleSupplierFileChange,
                    handleRemoveSupplierFile,
                    handleSupplierFileReplace,
                    handleDeleteSupplierFile
                } }),
            React.createElement(PaymentDetailsForm, { ...{
                    index,
                    currentPaymentDetails,
                    paymentDetailsErrors,
                    handlePaymentDetailUpdate,
                    handleConfirmPaymentDetailsToggle
                } }),
            displayRemoveButton && (React.createElement("div", { className: 'form-section remove' },
                React.createElement("button", { className: 'custom-button button -remove-trade-loan-supplier', onClick: () => removeSupplier(index), "data-testid": 'remove' }, "Remove Supplier"))),
            React.createElement("div", { className: 'actions' },
                React.createElement(SpinnerButton, { text: 'Save', className: 'custom-button button -trade-loan-save', handleClick: handleSave, isLoading: isSaving, testId: 'save-supplier' })))));
};
export default SupplierEditPanel;
