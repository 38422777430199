import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Panel from './Panel';
const FilterDropdown = ({ bankOptions, selectedBank, drawdownOptions, selectedDrawdown, searchKeyword, initialStartDate, initialEndDate, apiToken, adminIds }) => {
    const [isOpened, setIsOpened] = useState(false);
    const togglePanel = () => setIsOpened(!isOpened);
    const [filterCounter, setCounter] = useState(0);
    useEffect(() => {
        if (selectedBank && Object.keys(selectedBank).length > 0) {
            setCounter(prevCounter => prevCounter + 1);
        }
        if (selectedDrawdown && Object.keys(selectedDrawdown).length > 0) {
            setCounter(prevCounter => prevCounter + 1);
        }
        if (initialStartDate && initialEndDate) {
            setCounter(prevCounter => prevCounter + 1);
        }
    }, []);
    const buttonClass = classNames('icon-button button', '-rectangular', '-border-brand-a-400', '-filter', '-icon-filter-brand-a-400', '-text-brand-a-400');
    const counterClass = classNames('counter', {
        zerocounter: filterCounter === 0
    });
    return (React.createElement("div", { className: 'toggle-panel -drawdowns-download', "data-testid": 'filter-dropdown' },
        React.createElement("a", { className: buttonClass, onClick: togglePanel },
            React.createElement("div", { className: 'text' }, "Filter"),
            React.createElement("div", { className: 'icon' }),
            React.createElement("div", { className: counterClass }, filterCounter)),
        React.createElement(Panel, { ...{
                isOpened,
                bankOptions,
                selectedBank,
                drawdownOptions,
                selectedDrawdown,
                initialStartDate,
                initialEndDate,
                searchKeyword,
                apiToken,
                adminIds
            } })));
};
export default FilterDropdown;
