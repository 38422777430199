import classNames from 'classnames';
import React, { useState, useRef } from 'react';
import Modal from '../../components/shared/Modal';
import CustomFlatpickr from '../shared/CustomFlatpickr';
import { buildUrlQuery } from '../../utils/url';
const ReportsModal = ({ isOpened, currentDate, basePath, status, togglePanel }) => {
    const [fileExtension, setFileExtension] = useState('.csv');
    const [startDate, setStartDate] = useState(currentDate);
    const dateClearRef = useRef(null);
    const datePickerRef = useRef(null);
    const downloadUrl = basePath.replace('drawdowns', 'drawdowns' + fileExtension);
    const filters = {
        status: status,
        ...(startDate && { start_date: startDate }),
        ...(startDate && { end_date: startDate })
    };
    const filterUrl = buildUrlQuery(filters, downloadUrl);
    const csvClass = classNames('input -file-type', {
        '-selected': fileExtension === '.csv',
        '-neutral': fileExtension !== '.csv'
    });
    const xlsxClass = classNames('input -file-type', {
        '-selected': fileExtension === '.xlsx',
        '-neutral': fileExtension !== '.xlsx'
    });
    const handleFileChange = event => {
        setFileExtension(event.target.value);
    };
    const handleDateChange = (selectedDates, dateString) => {
        setStartDate(dateString.split(' to ')[0]);
    };
    const defaultDateRange = () => {
        if (startDate) {
            return [startDate];
        }
        else {
            return [];
        }
    };
    const flatpickrSettings = {
        wrap: true,
        altInput: true,
        altFormat: 'Y-d-m',
        dateFormat: 'Y-m-d',
        onChange: handleDateChange,
        defaultDate: 'today' || defaultDateRange(),
        maxDate: 'today'
    };
    return (React.createElement(Modal, { isOpen: isOpened, onClose: togglePanel },
        React.createElement("div", { className: 'custom-panel -modal', role: 'dialog', "aria-modal": 'true', "data-testid": 'reports-modal' },
            React.createElement("div", { className: 'header' }, "Download Report"),
            React.createElement("div", { className: 'content' },
                React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { className: 'label -uppercase' }, "Date"),
                    React.createElement(CustomFlatpickr, { ...{ flatpickrSettings }, forwardDatePickerRef: datePickerRef, forwardDateClearRef: dateClearRef, selectedDate: '', pickerBackground: 'neutral-20', placeholderText: 'Filter by date', clearButtonText: 'Clear Date', name: 'date_range' })),
                React.createElement("div", { className: 'form-group' },
                    React.createElement("div", { className: 'label -uppercase' }, "File Type")),
                React.createElement("div", { className: 'form-group -file' },
                    React.createElement("div", { className: csvClass },
                        React.createElement("input", { id: 'csvFile', type: 'radio', className: 'radio', value: '.csv', onChange: handleFileChange, checked: fileExtension === '.csv' }),
                        React.createElement("label", { className: 'label', htmlFor: 'csvFile' }, "Download as .csv")),
                    React.createElement("div", { className: xlsxClass },
                        React.createElement("input", { id: 'xlsxFile', type: 'radio', className: 'radio', value: '.xlsx', onChange: handleFileChange, checked: fileExtension === '.xlsx' }),
                        React.createElement("label", { className: 'label', htmlFor: 'xlsxFile' }, "Download as .xlsx")))),
            React.createElement("hr", { className: 'divider' }),
            React.createElement("div", { className: 'action-buttons -padding' },
                React.createElement("div", { className: 'button -border -blue', onClick: togglePanel }, "Cancel"),
                React.createElement("a", { className: 'button -primary', href: filterUrl }, "Download")))));
};
export default ReportsModal;
