import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import CustomFlatpickr from '../shared/CustomFlatpickr';
import ErrorList from '../shared/ErrorList';
const RiskLogForm = ({ csrfToken, formUrl, modelName, resource, errors }) => {
    const [selectedDate, setSelectedDate] = useState(resource.logDate || '');
    const dateClearRef = useRef(null);
    const datePickerRef = useRef(null);
    const { description } = resource;
    const handleSelectedDateChange = (selectedDates, dateString) => {
        setSelectedDate(dateString);
    };
    const flatpickrSettings = {
        wrap: true,
        altInput: true,
        altFormat: 'd M Y',
        dateFormat: 'Y-m-d',
        defaultDate: selectedDate,
        onChange: handleSelectedDateChange
    };
    const hasLogDateError = errors.logDate !== undefined;
    const logDateLabelClass = classNames('label -required', {
        '-error': hasLogDateError
    });
    const descriptionLabelClass = classNames('label -required', {
        '-error': errors.description
    });
    const descriptionInputClass = classNames('input form-control -textarea', {
        '-error': errors.description
    });
    return (React.createElement("form", { className: 'form', action: formUrl, acceptCharset: 'UTF-8', method: 'post' },
        React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: logDateLabelClass }, "Date"),
            React.createElement(CustomFlatpickr, { ...{ selectedDate, flatpickrSettings }, forwardDatePickerRef: datePickerRef, forwardDateClearRef: dateClearRef, hasError: hasLogDateError, clearButtonText: 'Clear Date', name: `${modelName}[log_date]`, pickerBackground: 'neutral-20', placeholderText: ' ' }),
            React.createElement(ErrorList, { errors: errors, field: 'log_date' })),
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: descriptionLabelClass }, "Description"),
            React.createElement("textarea", { className: descriptionInputClass, name: `${modelName}[description]`, defaultValue: description || '' }),
            React.createElement(ErrorList, { errors: errors, field: 'description' })),
        React.createElement("div", { className: 'action-buttons' },
            React.createElement("button", { type: 'submit', className: 'button -primary' }, "Create"))));
};
export default RiskLogForm;
