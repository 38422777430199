import React, { useState } from 'react';
import ErrorList from '../shared/ErrorList';
import SpinnerButton from '../shared/SpinnerButton';
import NumberField from '../shared/NumberField';
import ToggleSwitch from '../shared/ToggleSwitch';
import { convertCentsString } from '../../utils/convertCents';
import { updateAmendedInvoiceThreshold } from '../../api/client_setup';
import { isAPIValidationErrors, isAPIErrors } from '../../../types';
import { showNotifyToast } from '../../utils';
const AmendedInvoiceThresholdForm = ({ authToken, borrowerId, amendedInvoiceThreshold, enableAmendedInvoice = false, isReadOnly = false, incrementSaves }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [enabledAmendedThreshold, setEnableAmendedThreshold] = useState(enableAmendedInvoice);
    const [validationErrors, setValidationErrors] = useState({});
    const initialValue = {
        floatValue: amendedInvoiceThreshold / 100,
        value: convertCentsString(amendedInvoiceThreshold),
        formattedValue: ''
    };
    const [currentAmendedInvoiceThreshold, setCurrentAmendedInvoiceThreshold] = useState(initialValue);
    const handleAmendedInvoiceThresholdChange = (values) => {
        setCurrentAmendedInvoiceThreshold(values);
    };
    const amendedInvoiceToggleOn = () => {
        setEnableAmendedThreshold(true);
    };
    const amendedInvoiceToggleOff = () => {
        setEnableAmendedThreshold(false);
    };
    const handleSave = async () => {
        const body = {
            company: {
                amendedInvoiceThreshold: currentAmendedInvoiceThreshold.floatValue,
                enableAmendedInvoiceThreshold: enabledAmendedThreshold
            }
        };
        setIsLoading(true);
        try {
            const response = await updateAmendedInvoiceThreshold(authToken, borrowerId, body);
            if (isAPIValidationErrors(response)) {
                throw response;
            }
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            setValidationErrors({});
            if (incrementSaves) {
                incrementSaves();
            }
            showNotifyToast({
                text: 'Successfully updated Amended Invoices Threshold.',
                type: 'success'
            });
        }
        catch (err) {
            if (isAPIValidationErrors(err)) {
                setValidationErrors(err.errors);
            }
            showNotifyToast({
                text: 'Failed to update Amended Invoices Threshold.',
                type: 'error'
            });
        }
        setIsLoading(false);
    };
    return (React.createElement("div", { className: 'client-setup-form form', "data-testid": 'amended-ageing-threshold-form' },
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: 'label -uppercase' }, "Turn on amended invoices threshold"),
            React.createElement(ToggleSwitch, { isToggled: enabledAmendedThreshold, switchOn: amendedInvoiceToggleOn, switchOff: amendedInvoiceToggleOff, name: '' })),
        React.createElement("div", { className: 'form-group group' },
            React.createElement("label", { className: 'label -uppercase' }, "Amount"),
            React.createElement(NumberField, { model: '', attribute: '', defaultValue: currentAmendedInvoiceThreshold.value, onValueChange: handleAmendedInvoiceThresholdChange }),
            React.createElement("div", { className: 'label -note' }, "Disapprove amended invoices if they have been changed to be greater than this threshold amount and notif the Admin Client Managers."),
            React.createElement(ErrorList, { errors: validationErrors, field: 'debtor_ageing_threshold' })),
        React.createElement("div", { className: 'action-buttons actions' },
            React.createElement(SpinnerButton, { text: 'Save', buttonType: 'button', isLoading: isLoading, handleClick: handleSave, className: 'button -primary', isDisabled: isReadOnly, testId: 'save' }))));
};
export default AmendedInvoiceThresholdForm;
