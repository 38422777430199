import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import { buildUrlQuery } from '../../../utils/url';
import CustomFlatpickr from '../../shared/CustomFlatpickr';
const Panel = ({ isOpened, bankOptions, selectedBank, transferAmountGreaterThanZero, status = 'processed' }) => {
    const [bankFilter, setBankFilter] = useState((selectedBank && selectedBank.value) || 'all');
    const [hasTransferAmount, setHasTransferAmount] = useState(transferAmountGreaterThanZero);
    const [dateRange, setDateRange] = useState('');
    const handleSelectedDateChange = (selectedDates, dateString) => {
        setDateRange(dateString);
    };
    const flatpickrSettings = {
        wrap: true,
        altInput: true,
        altFormat: 'd M Y',
        dateFormat: 'Y-m-d',
        mode: 'range',
        onChange: handleSelectedDateChange
    };
    const dateClearRef = useRef(null);
    const datePickerRef = useRef(null);
    const toggleTransferAmount = () => setHasTransferAmount(!hasTransferAmount);
    const baseUrl = '/admin/repayments';
    const filterUrl = buildUrlQuery({
        status: status,
        segment_account_bank: bankFilter || '',
        transfer_amount_greater_than_zero: hasTransferAmount ? 'true' : 'false',
        date_range: dateRange
    }, baseUrl);
    const panelClass = classNames('panel', {
        '-active': isOpened
    });
    return (React.createElement("div", { className: panelClass, "data-testid": 'panel' },
        React.createElement("div", { className: 'dropdowns' },
            React.createElement("div", { className: 'form-group -collapse' },
                React.createElement("div", { className: 'label -double-spaced' }, "Filter By"),
                React.createElement("div", { className: 'label -uppercase  -small' }, "Type"),
                React.createElement(Select, { options: bankOptions, defaultValue: selectedBank, onChange: selectedBank => {
                        setBankFilter(selectedBank.value);
                    }, width: 'long', model: 'drawdown', name: 'segment_account_bank' }),
                React.createElement("label", { className: 'label -above-spaced -uppercase -small' }, "Date Range"),
                React.createElement(CustomFlatpickr, { ...{ flatpickrSettings }, forwardDatePickerRef: datePickerRef, forwardDateClearRef: dateClearRef, selectedDate: '', pickerBackground: 'white', placeholderText: 'Filter by date', clearButtonText: 'Clear Date', name: 'date_range' }),
                React.createElement("div", { className: 'label -above-spaced' },
                    React.createElement("input", { className: 'check-input', name: '', type: 'checkbox', checked: hasTransferAmount, onChange: toggleTransferAmount }),
                    React.createElement("label", null, "Transfer Amount > 0"))),
            React.createElement("div", { className: 'action-buttons' },
                React.createElement("a", { className: 'button -cancel -border -loose -loose-panel', href: filterUrl }, "Reset"),
                React.createElement("a", { className: 'button -primary -loose -loose-panel', href: filterUrl }, "Apply Filter")))));
};
export default Panel;
