import { convertToCents } from './money';
import Dinero from 'dinero.js';
export const computeAmountWithGst = (amount) => {
    const amountCents = convertToCents(amount);
    const amountObject = Dinero({ amount: amountCents, currency: 'AUD' });
    const amountWithGst = amountObject.multiply(1.1);
    return amountWithGst.toUnit();
};
export const computeGst = (amount) => {
    const amountCents = convertToCents(amount);
    const amountObject = Dinero({ amount: amountCents, currency: 'AUD' });
    const gst = amountObject.multiply(0.1);
    return gst.toUnit();
};
export const computeAmount = (amountWithGst) => {
    const amountWithGstCents = convertToCents(amountWithGst);
    const amountObject = Dinero({ amount: amountWithGstCents, currency: 'AUD' });
    const amount = amountObject.divide(1.1);
    return amount.toUnit();
};
