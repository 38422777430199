const ReminderPanelReducer = (state, action) => {
    switch (action.type) {
        case 'LOAD_START':
            return { ...state, isLoading: true };
        case 'LOAD_SUCCESS':
            return {
                ...state,
                isLoading: false,
                note: action.loadedReminder.note || '',
                recipients: action.loadedReminder.recipients || [],
                deadline: action.loadedReminder.deadline || ''
            };
        case 'LOAD_NO_DATA':
            return { ...state, isLoading: false };
        case 'LOAD_ERROR':
            return { ...state, isLoading: false };
        case 'SAVE_START':
            return { ...state, isSaving: true };
        case 'SAVE_SUCCESS':
            return {
                ...state,
                isEditMode: false,
                isSaving: false,
                note: action.updatedReminder.note || '',
                recipients: action.updatedReminder.recipients || [],
                deadline: action.updatedReminder.deadline || ''
            };
        case 'SAVE_NO_DATA':
            return { ...state, isSaving: false };
        case 'SAVE_ERROR':
            return { ...state, isSaving: false };
        case 'UPDATE_FIELD':
            return { ...state, [action.field]: action.value };
        case 'OPEN_EDIT_MODE':
            return { ...state, isEditMode: true };
        case 'CLOSE_EDIT_MODE':
            return { ...state, isEditMode: false };
        default:
            return { ...state };
    }
};
export default ReminderPanelReducer;
