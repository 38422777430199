import fetch from 'isomorphic-fetch';
import { serialize } from 'object-to-formdata';
import { buildHeaders } from './shared';
export const validateTradeLoanDetails = async (apiToken, body, urlPath) => {
    const endpoint = `/api/${urlPath}/trade_loan_validation`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'POST',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const validateSupportingDocuments = async (apiToken, body, urlPath) => {
    const endpoint = `/api/${urlPath}/trade_loan_document_validation`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'POST',
        body: serialize(body)
    }).then((res) => res.json());
};
export const validateSupplierDetails = async (apiToken, body, urlPath) => {
    const endpoint = `/api/${urlPath}/trade_loan_supplier_validation`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'POST',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const validatePaymentDetails = async (apiToken, body, urlPath) => {
    const endpoint = `/api/${urlPath}/trade_loan_payment_validation`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'POST',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const validateTradeLoanInvoices = async (apiToken, body, urlPath) => {
    const endpoint = `/api/${urlPath}/trade_loan_invoice_validation`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json'
    };
    const formattedBody = serialize(body, {
        indices: true
    });
    return fetch(endpoint, {
        headers,
        method: 'POST',
        body: formattedBody
    }).then((res) => res.json());
};
export const createTradeLoan = async (apiToken, body, urlPath) => {
    const endpoint = `/api/${urlPath}/trade_loans`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'POST',
        body: serialize(body, { indices: true })
    }).then((res) => res.json());
};
export const fetchSupplierDetailsforBorrower = async (apiToken, supplierId, urlPath) => {
    const endpoint = `/api/${urlPath}/trade_loan_supplier_details/${supplierId}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const updateSupplierDetails = async (apiToken, tradeLoanId, tradeLoanSupplierId, body, urlPath) => {
    const endpoint = `/api/${urlPath}/trade_loans/${tradeLoanId}/trade_loan_supplier_details/${tradeLoanSupplierId}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: serialize(body)
    }).then((res) => res.json());
};
export const createSupplierDetails = async (apiToken, tradeLoanId, body, urlPath) => {
    const endpoint = `/api/${urlPath}/trade_loans/${tradeLoanId}/trade_loan_supplier_details`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'POST',
        body: serialize(body)
    }).then((res) => res.json());
};
export const removeSupplierDetails = async (apiToken, tradeLoanId, supplierId, urlPath) => {
    const endpoint = `/api/${urlPath}/trade_loans/${tradeLoanId}/trade_loan_supplier_details/${supplierId}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'DELETE'
    }).then((res) => (res.status === 204 ? res : res.json()));
};
export const removeSupplierDetailsFile = async (apiToken, tradeLoanId, tradeLoanSupplierId, body, urlPath) => {
    const endpoint = `/api/${urlPath}/trade_loans/${tradeLoanId}/trade_loan_supplier_details/${tradeLoanSupplierId}/remove_file`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const updateTradeLoan = async (apiToken, tradeLoanId, body, urlPath) => {
    const endpoint = `/api/${urlPath}/trade_loans/${tradeLoanId}/trade_loan_details`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const updateSupportingDocuments = async (apiToken, tradeLoanId, body, urlPath) => {
    const endpoint = `/api/${urlPath}/trade_loans/${tradeLoanId}/trade_loan_documents`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: serialize(body)
    }).then((res) => res.json());
};
export const removeTradeLoanFile = async (apiToken, tradeLoanId, body, urlPath) => {
    const endpoint = `/api/${urlPath}/trade_loans/${tradeLoanId}/trade_loan_documents/remove_file`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const updateInvoiceDetails = async (apiToken, tradeLoanId, tradeLoanSupplierId, tradeLoanInvoiceId, body, urlPath) => {
    const endpoint = `/api/${urlPath}/trade_loans/${tradeLoanId}/trade_loan_supplier_details/${tradeLoanSupplierId}/trade_loan_invoices/${tradeLoanInvoiceId}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: serialize(body)
    }).then((res) => res.json());
};
export const createInvoiceDetails = async (apiToken, tradeLoanId, tradeLoanSupplierId, body, urlPath) => {
    const endpoint = `/api/${urlPath}/trade_loans/${tradeLoanId}/trade_loan_supplier_details/${tradeLoanSupplierId}/trade_loan_invoices`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'POST',
        body: serialize(body)
    }).then((res) => res.json());
};
export const removeInvoiceDetails = async (apiToken, tradeLoanId, tradeLoanSupplierId, tradeLoanInvoiceId, urlPath) => {
    const endpoint = `/api/${urlPath}/trade_loans/${tradeLoanId}/trade_loan_supplier_details/${tradeLoanSupplierId}/trade_loan_invoices/${tradeLoanInvoiceId}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'DELETE'
    }).then((res) => (res.status === 204 ? res : res.json()));
};
export const removeInvoiceDetailsFile = async (apiToken, tradeLoanId, tradeLoanSupplierId, tradeLoanInvoiceId, body, urlPath) => {
    const endpoint = `/api/${urlPath}/trade_loans/${tradeLoanId}/trade_loan_supplier_details/${tradeLoanSupplierId}/trade_loan_invoices/${tradeLoanInvoiceId}/remove_invoice_file`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const activatePenalty = async (apiToken, companyId, tradeLoanId, accumulatedPenaltyInterest) => {
    const endpoint = `/api/admin/companies/${companyId}/trade_loans/${tradeLoanId}/activate_penalty`;
    const body = {
        accumulated_penalty_interest: accumulatedPenaltyInterest
    };
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'PUT',
            body: JSON.stringify(body)
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors[0]);
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
export const deactivatePenalty = async (apiToken, companyId, tradeLoanId) => {
    const endpoint = `/api/admin/companies/${companyId}/trade_loans/${tradeLoanId}/deactivate_penalty`;
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'PUT'
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors[0]);
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
