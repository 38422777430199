import classNames from 'classnames';
import React, { useState } from 'react';
import Panel from './Panel';
const UploadTradeLoanRepaymentsCsv = ({ csrfToken, path }) => {
    const [isOpened, setIsOpened] = useState(false);
    const togglePanel = () => setIsOpened(!isOpened);
    const buttonClass = classNames('icon-button button', '-rectangular', '-margin', '-text-neutral-600', {
        '-active': isOpened,
        '-background-neutral-30': !isOpened,
        '-background-neutral-40': isOpened,
        '-default-cursor': isOpened
    });
    return (React.createElement("div", { className: 'toggle-panel', "data-testid": 'upload-repayments-csv' },
        React.createElement("a", { href: '#', className: buttonClass, onClick: togglePanel, "data-testid": 'upload-button' },
            React.createElement("div", { className: 'text' }, "Upload Trade Loan Repayment CSV")),
        React.createElement(Panel, { ...{ csrfToken, isOpened, path } })));
};
export default UploadTradeLoanRepaymentsCsv;
