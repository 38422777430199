import React, { useState } from 'react';
import Select from 'react-select';
import ErrorList from '../shared/ErrorList';
import SpinnerButton from '../shared/SpinnerButton';
import { updateTradingTerms } from '../../api/client_setup';
import { isAPIErrors, isAPIValidationErrors } from '../../../types';
import { dropdownStyles } from '../../custom-styles/dropdown';
import { showNotifyToast } from '../../utils';
const TradingTermsForm = ({ authToken, borrowerId, client, tradingTermsOptions, isReadOnly = false, incrementSaves }) => {
    const { tradingTerms } = client;
    const initialTradingTerms = tradingTermsOptions.find((option) => option.value === tradingTerms) || null;
    const [selectedTradingTerms, setSelectedTradingTerms] = useState(initialTradingTerms);
    const [isLoading, setIsLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const handleTradingTermsChange = (selectedOption) => {
        setSelectedTradingTerms(selectedOption);
    };
    const handleSave = async () => {
        const body = {
            company: {
                tradingTerms: selectedTradingTerms ? selectedTradingTerms.value : ''
            }
        };
        setIsLoading(true);
        try {
            const response = await updateTradingTerms(authToken, borrowerId, body);
            if (isAPIValidationErrors(response)) {
                throw response;
            }
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            setValidationErrors({});
            if (incrementSaves) {
                incrementSaves();
            }
            showNotifyToast({
                text: 'Successfully updated Trading Terms.',
                type: 'success'
            });
        }
        catch (err) {
            if (isAPIValidationErrors(err)) {
                setValidationErrors(err.errors);
            }
            showNotifyToast({
                text: 'Failed to update Trading Terms.',
                type: 'error'
            });
        }
        setIsLoading(false);
    };
    return (React.createElement("div", { className: 'client-setup-form form -trading-terms', "data-testid": 'trading-terms-form' },
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: 'label -uppercase' }, "Standard Trading Terms"),
            React.createElement(Select, { options: tradingTermsOptions, styles: dropdownStyles, placeholder: 'Select terms', value: selectedTradingTerms, onChange: handleTradingTermsChange, isDisabled: isReadOnly }),
            React.createElement(ErrorList, { errors: validationErrors, field: 'trading_terms' })),
        React.createElement("div", { className: 'action-buttons actions' },
            React.createElement(SpinnerButton, { text: 'Save', buttonType: 'button', isLoading: isLoading, handleClick: handleSave, className: 'button -primary', isDisabled: isReadOnly, testId: 'save' }))));
};
export default TradingTermsForm;
