import React from 'react';
const AddessLine = (brokerAddress) => {
    if (!brokerAddress.lineTwo) {
        return React.createElement("p", null, "No listed address");
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("p", null, brokerAddress.lineTwo),
        React.createElement("p", null, `${brokerAddress.city} ${brokerAddress.region} ${brokerAddress.postCode} `)));
};
export default AddessLine;
