import React from 'react';
const ModalForm = ({ isVisible, path, csrfToken, header, body }) => {
    return (React.createElement("form", { method: 'post', action: path, className: 'panel -active' },
        React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
        isVisible && (React.createElement("div", { className: 'modal', "data-testid": 'modal-form' },
            React.createElement("div", { className: 'custom-panel -modal' },
                React.createElement("div", { className: 'header -space-between' }, header),
                body),
            React.createElement("div", { className: 'modal-overlay' })))));
};
export default ModalForm;
