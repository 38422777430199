import React, { useEffect, useRef } from 'react';
const ToggleTextField = ({ defaultValue, isEditable, isLoading, isSubmitting, placeholder, handleSubmit, handleTextFieldChange, setIsEditable }) => {
    const textFieldRef = useRef(null);
    useEffect(() => {
        if (isEditable && textFieldRef && textFieldRef.current) {
            textFieldRef.current.focus();
            textFieldRef.current.select();
        }
    }, [isEditable]);
    const handleEditableClose = (event) => {
        event.preventDefault();
        setIsEditable(false);
    };
    const handleEditableOpen = (event) => {
        event.preventDefault();
        setIsEditable(true);
    };
    if (isEditable) {
        return (React.createElement("div", { className: 'toggle-text-field -editable' },
            React.createElement("form", { className: 'form', onSubmit: handleSubmit, "data-testid": 'editor' },
                React.createElement("div", { className: 'form-group field' },
                    React.createElement("textarea", { className: 'input form-control body -textarea -noresize', "data-testid": 'textarea', defaultValue: defaultValue, onChange: handleTextFieldChange, ref: textFieldRef })),
                React.createElement("div", { className: 'actions' },
                    React.createElement("button", { className: 'custom-button button close -mute -narrow', onClick: handleEditableClose, "data-testid": 'cancel' }, "Cancel"),
                    React.createElement("button", { className: 'custom-button button submit -default -narrow', disabled: isSubmitting, "data-testid": 'submit' }, "Submit")))));
    }
    if (isLoading) {
        return (React.createElement("div", { className: 'toggle-text-field -loading' },
            React.createElement("div", { className: 'info', "data-testid": 'info-loading' }, "Loading...")));
    }
    return (React.createElement("div", { className: 'toggle-text-field -loaded' },
        React.createElement("div", { className: 'info', "data-testid": 'info-loaded', onClick: handleEditableOpen }, defaultValue.length > 0 ? defaultValue : placeholder)));
};
export default ToggleTextField;
