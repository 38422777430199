import React from 'react';
import Tab from './Tab';
import classNames from 'classnames';
const FilterTabs = ({ tabs, variant }) => {
    const filterTabsClass = classNames('filter-tabs', {
        '-repayments': variant === 'repayments'
    });
    return (React.createElement("div", { className: filterTabsClass, "data-testid": 'filter-tabs' }, tabs.map((tab, i) => {
        return React.createElement(Tab, { key: i, variant: variant, ...tab });
    })));
};
export default FilterTabs;
