import fetch from 'isomorphic-fetch';
export const updateDebtorDetails = async (apiToken, debtorId, body) => {
    const endpoint = `/api/admin/debtors/${debtorId}/update_details`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const fetchDebtors = async (apiToken, status, direction, filter, page, search) => {
    const endpoint = `/api/admin/debtors?sort=${direction}&status=${status}&filter=${filter}&page=${page}&search=${search}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
