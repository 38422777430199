import React from 'react';
const Link = ({ icon = '', title, path, method, confirmText }) => {
    const isDelete = method === 'delete';
    const dataMethod = isDelete ? 'delete' : '';
    const dataConfirm = isDelete
        ? 'Are you sure do you want to delete this item?'
        : '';
    return (React.createElement("a", { className: `button ${icon}`, href: path, "data-method": method === 'put' ? method : dataMethod, "data-confirm": confirmText ? confirmText : dataConfirm, "data-testid": 'link' }, title));
};
export default Link;
