import React, { useState } from 'react';
import { showNotifyToast } from '../../utils';
import ToggleSwitch from '../../components/shared/ToggleSwitch';
import { disablePrompt as defaultDisable, enablePrompt as defaultEnable } from '../../api/myob_bank_feed_reference';
const MyobBankFeedPromptForm = ({ apiToken, borrowerId, enableMyobBankFeedPrompt, enablePrompt = defaultEnable, disablePrompt = defaultDisable }) => {
    const errorMessage = 'Something went wrong. Please try again.';
    const [bankFeedPromptStatus, setBankFeedPromptStatus] = useState(enableMyobBankFeedPrompt);
    const showBankFeedPrompt = async () => {
        try {
            const response = await enablePrompt(apiToken, borrowerId);
            setBankFeedPromptStatus(response.enableMyobBankFeedPrompt);
            if (response) {
                showNotifyToast({
                    text: 'Successfully shows prompt on user dashboard',
                    type: 'success'
                });
            }
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
    };
    const hideBankFeedPrompt = async () => {
        try {
            const response = await disablePrompt(apiToken, borrowerId);
            setBankFeedPromptStatus(response.enableMyobBankFeedPrompt);
            if (response) {
                showNotifyToast({
                    text: 'Successfully hides prompt on user dashboard',
                    type: 'success'
                });
            }
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
    };
    return (React.createElement("div", { "data-testid": 'myob-bank-feed-prompt' },
        React.createElement("div", { className: 'form-group group -oneline' },
            React.createElement("label", { className: 'label' }, "Show a prompt on user dashboard"),
            React.createElement(ToggleSwitch, { isToggled: bankFeedPromptStatus, switchOn: () => showBankFeedPrompt(), switchOff: () => hideBankFeedPrompt(), testId: 'toggle-myob-bank-prompt' })),
        React.createElement("div", { className: 'text' }, "When selected, a prompt will appear on the client dashboard instructing them to obtain and enter a bank feed reference number for MYOB bank feeds.")));
};
export default MyobBankFeedPromptForm;
