import DineroFactory from 'dinero.js';
import { convertToMoneyObject } from './money';
export const hasReserveError = ({ reserveAmountMoney, loan, fees, interest }) => {
    const outstanding = totalInputAmount({ loan, fees, interest });
    return reserveAmountMoney < outstanding.toUnit();
};
export const afterAmountInput = (outstandingAmountMoney, inputAmount = 0, isWriteOff) => {
    const result = outstandingAmountMoney.subtract(convertToMoneyObject(inputAmount));
    return isWriteOff ? convertToMoneyObject(0) : result;
};
export const afterAmountReserve = (reserveAmountMoney, sumOutstanding, isWriteOff) => {
    const result = reserveAmountMoney.subtract(sumOutstanding);
    if (isWriteOff && result.isPositive()) {
        return convertToMoneyObject(0);
    }
    else if (!isWriteOff && result.isPositive()) {
        return result;
    }
    return convertToMoneyObject(0);
};
export const afterAmount = (reserveAmountMoney, sumOutstanding) => {
    const result = reserveAmountMoney.subtract(sumOutstanding);
    return reserveAmountMoney.toUnit() > sumOutstanding.toUnit()
        ? result
        : convertToMoneyObject(0);
};
export const totalInputAmount = ({ interest = 0, fees = 0, loan = 0 }) => {
    const totalOutstanding = convertToMoneyObject(interest)
        .add(convertToMoneyObject(fees))
        .add(convertToMoneyObject(loan));
    return totalOutstanding;
};
export const showWriteOff = (isWriteOff, amount, writeOffValue) => {
    return (isWriteOff &&
        amount.subtract(writeOffValue).isPositive() &&
        !writeOffValue.isZero());
};
export const zeroReserveAndTotal = (reserveAmountCents, totalOutstandingCents) => {
    return reserveAmountCents === 0 && totalOutstandingCents === 0;
};
export const computeWriteOff = ({ isWriteOff, loan, fees, interest, currrentLoan, currentFees, currentInterest }) => {
    if (isWriteOff) {
        const loanWriteOff = loan.subtract(currrentLoan);
        const feesWriteOff = fees.subtract(currentFees);
        const interestWriteOff = interest.subtract(currentInterest);
        const totalWriteOff = loanWriteOff.add(feesWriteOff).add(interestWriteOff);
        return {
            loanWriteOff,
            feesWriteOff,
            interestWriteOff,
            totalWriteOff
        };
    }
    else {
        return {
            loanWriteOff: convertToMoneyObject(0),
            feesWriteOff: convertToMoneyObject(0),
            interestWriteOff: convertToMoneyObject(0),
            totalWriteOff: convertToMoneyObject(0)
        };
    }
};
export const computeInputField = ({ reserve, total, loan, fees, interest }) => {
    if (reserve.toUnit() < total.toUnit()) {
        let remainingReserve = reserve;
        const allocate = amount => {
            const minValue = DineroFactory.minimum([remainingReserve, amount]);
            remainingReserve = remainingReserve.subtract(minValue);
            return minValue;
        };
        return {
            loanInput: allocate(loan),
            feesInput: allocate(fees),
            interestInput: allocate(interest)
        };
    }
    return {
        loanInput: loan,
        feesInput: fees,
        interestInput: interest
    };
};
