import React from 'react';
import AccordionPane from '../shared/AccordionPane';
const HomeAccordionContainer = () => (React.createElement("div", { className: 'accordion-container -home', "data-testid": 'home-accordion-container' },
    React.createElement(AccordionPane, { title: 'What is invoice finance?', type: 'home' },
        React.createElement("p", null, "Invoice finance is the broad term for business finance that lets businesses borrow funds against the value of their unpaid customer invoices. Invoice finance is also commonly known as debtor finance or accounts receivable finance. Because the accounts receivable ledger is used as the main security for the loan, there is no need for business owners to put their home up as security."),
        React.createElement("p", null, "Invoice finance providers typically advance up to 80% of eligible invoices and, when the invoice is paid, the other 20% (less some fees) is paid back to the business or is used to pay down the loan balance. Invoices are eligible for finance if they are for goods or services that have been delivered, are with creditworthy business customers and are not more than 90 days past the invoice issue date."),
        React.createElement("p", null, "Within invoice finance, there are two main types of invoice finance: invoice factoring and invoice discounting."),
        React.createElement("p", null),
        React.createElement("h4", null, "Invoice factoring"),
        React.createElement("p", null, "Invoice factoring is where a business sells its invoices to a third-party factoring company at a discount to the full invoice value. As the invoices are sold, the business hands over responsibility for the collection of those invoices to the factoring company. This has the benefit of freeing up resources so that business owners can focus on running their business instead of managing accounts receivable collections, however there is a downside in that the factoring company might not manage customers in the same careful way that the business owner would like. As customers are aware that the invoice factoring company is providing invoice finance to the business, this is known as disclosed invoice factoring."),
        React.createElement("p", null),
        React.createElement("h4", null, "Invoice discounting"),
        React.createElement("p", null, "Invoice discounting is another type of invoice finance and the main difference to invoice factoring is that, although unpaid customer invoices are used as security for the loan, they are not actually sold to the lender. The business is able to retain control of the accounts receivable ledger and will continue to be responsible for invoice collections. Invoice discounting can be non-disclosed (or confidential) where customers don\u2019t know that the invoices are being financed, or it can be disclosed to customers. Whether disclosed or not depends on the requirements of the business and the type of invoice discounting service offered by the lender.")),
    React.createElement(AccordionPane, { title: 'How does invoice finance work?', type: 'home' },
        React.createElement("p", null, "With invoice finance, a business can access funds against invoices at the time they are raised, bringing forward the cash flow rather than having to wait until the invoice is paid. An invoice finance loan is then repaid when the customer pays the invoice. Many businesses prefer this to business loans which require regular repayments regardless of the cash flow position of the business."),
        React.createElement("p", null, "Because customer invoices are used as security for invoice finance, it is important for the lender to have a high level of visibility over the invoices to know that they are legitimate and will be paid by the customer. In the past (and still with some traditional invoice finance providers) it was necessary for borrowers to upload invoices and accounts receivables ledgers which was manual and time-consuming."),
        React.createElement("p", null, "Modern invoice finance providers, like Earlypay, integrate with cloud accounting software platforms such as Xero, MYOB and Quickbooks to view invoices in real-time so the lender and the borrower always know the amount of invoices available for finance. Another major benefit of cloud accounting software integrations is that invoice and loan payment reconciliations are all automated, saving many hours on bookkeeping.")),
    React.createElement(AccordionPane, { title: 'Am I eligible for invoice finance?', type: 'home' },
        React.createElement("p", null, "In addition to having invoices that are eligible for finance, invoice finance providers will also look at the creditworthiness of your business. Earlypay requires borrowers to have:"),
        React.createElement("p", null),
        React.createElement("ul", { className: 'check-list list -display-only' },
            React.createElement("li", { className: 'item -checked' },
                React.createElement("p", { className: 'icon' }),
                React.createElement("p", { className: 'description' }, " A valid ACN or ABN ")),
            React.createElement("li", { className: 'item -checked' },
                React.createElement("p", { className: 'icon' }),
                React.createElement("p", { className: 'description' }, " Invoices with other strong businesses ")),
            React.createElement("li", { className: 'item -checked' },
                React.createElement("p", { className: 'icon' }),
                React.createElement("p", { className: 'description' }, "Invoices for goods or services have been delivered")),
            React.createElement("li", { className: 'item -checked' },
                React.createElement("p", { className: 'icon' }),
                React.createElement("p", { className: 'description' }, "You use Xero, MYOB AccountRight or Quickbooks")))),
    React.createElement(AccordionPane, { title: 'Is invoice finance right for my business?', type: 'home' },
        React.createElement("p", null, "It is generally cheaper to borrow if you can provide a form of security to the lender. This allows the secured lender to charge less than an unsecured business loan as it is more likely that the loan will be fully repaid. Real estate can also be provided as security, and while this will almost always be cheaper than other types of business loans, many business owners do not want to put their home at risk."),
        React.createElement("p", null, "Asset based finance, which uses business assets as loan security, sits in between unsecured business loans and real estate-backed loans. The main types of asset based finance are invoice finance and equipment finance."),
        React.createElement("p", null, "When looking to finance your business, it is best to match your loans with the use of the funds. If you are looking to buy long-term equipment, an equipment loan that is repaid over the life of the equipment might be best. If you are looking to increase working capital to help your business grow but don't want to use your home as security, then using invoice finance to bring forward cash flow from your outstanding invoices could be the answer.")),
    React.createElement(AccordionPane, { title: 'How does Earlypay work?', type: 'home' },
        React.createElement("p", null, "We offer Australian small businesses a line of credit that releases cash from your business\u2019s unpaid customer invoices with the flexibility of choosing the customer invoices you\u2019d like to finance. And because our service is confidential, you will keep full control over your valuable customer relationships."),
        React.createElement("p", null, "Earlypay\u2019s smart integration with your cloud accounting software updates your available funds as invoices are raised and paid and you can access funds with a click of a button. And by posting transaction information to your accounting software, reconciling invoice payments and loan repayments to your bank feeds is a breeze."),
        React.createElement("p", null, "Earlypay is making invoice finance easier and more accessible for Australian businesses than ever before. Sign up now for a no-obligation offer or contact us on 1300 754 777 to learn more about how we can help your business grow."))));
export default HomeAccordionContainer;
