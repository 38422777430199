import fetch from 'isomorphic-fetch';
export const fetchReminder = (apiToken, remindableId, remindableType, scope) => {
    const endpoint = `/api/${scope}/${remindableType}/${remindableId}/reminder`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const setReminderDeadline = (apiToken, remindableId, remindableType, scope, reminderBody) => {
    const endpoint = `/api/${scope}/${remindableType}/${remindableId}/reminder/set_deadline`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PATCH',
        body: JSON.stringify(reminderBody)
    }).then((res) => res.json());
};
export const updateReminder = (apiToken, remindableId, remindableType, scope, reminderBody) => {
    const endpoint = `/api/${scope}/${remindableType}/${remindableId}/reminder`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PATCH',
        body: JSON.stringify(reminderBody)
    }).then((res) => res.json());
};
