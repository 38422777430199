import classNames from 'classnames';
import React from 'react';
import AdminCustomerStatementsTable from './AdminCustomerStatementsTable';
const ManyAddressPanel = ({ isLoading, setBccEmail, checkedCustomers, checkedSelectedDebtors, customersArray, handleSelectedDebtors, handleCustomerCheckbox, toggleModal, isVisible }) => {
    const disabled = checkedCustomers.length === 0;
    const submitClass = classNames('button -primary -full-width', {
        '-disabled': disabled
    });
    if (isLoading) {
        return (React.createElement("div", { className: 'custom-panel', "data-testid": 'loading' },
            React.createElement("div", { className: 'header' }, "Send statements to debtors"),
            React.createElement("div", { className: 'business-info' },
                React.createElement("div", { className: 'info' }, "Loading..."))));
    }
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'many-address-panel' },
        React.createElement("div", { className: 'header' }, "Send statements to debtor"),
        React.createElement("div", { className: 'form form-pane' },
            React.createElement("div", { className: 'form-group' },
                React.createElement("p", { className: 'paragraph' }, "Send statements to debtors. Each debtor will receive their statement for this client (sent from hello@earlypay.com.au) to the address listed below.")),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, "BCC ADDRESS (OPTIONAL)"),
                React.createElement("input", { type: 'text', className: 'input form-control', name: 'bcc_email', "data-testid": 'many-address-panel-email', onChange: e => setBccEmail(e.target.value) })),
            React.createElement("div", { className: 'form-group' },
                React.createElement("div", { className: 'checkbox' },
                    React.createElement("label", null,
                        React.createElement("input", { type: 'checkbox', name: 'selected_debtors_only', checked: checkedSelectedDebtors, onChange: handleSelectedDebtors, "data-testid": 'toggle-selected-debtors-only' }),
                        "\u00A0Selected Debtors Only"))),
            checkedSelectedDebtors && (React.createElement("div", { className: 'form-group', "data-testid": 'customer-table' },
                React.createElement(AdminCustomerStatementsTable, { ...{
                        isLoading,
                        customersArray,
                        checkedCustomers,
                        handleCustomerCheckbox,
                        isVisible
                    } }))),
            React.createElement("div", { className: 'action-buttons -send-statements' },
                React.createElement("input", { type: 'submit', onClick: toggleModal, className: submitClass, disabled: disabled, "data-testid": 'toggle-modal', "data-disable-with": 'Send' })))));
};
export default ManyAddressPanel;
