import React from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import ErrorList from '../shared/ErrorList';
import SpinnerButton from '../shared/SpinnerButton';
import DocumentPicker from './DocumentPicker';
import { dropdownStyles } from '../../custom-styles/dropdown';
const InvoiceDetailsForm = ({ invoice, selectedInvoiceCurrencyOption, invoiceAmountValues, supplierIndex, invoiceIndex, invoiceDateSettings, invoiceCurrencyOptions, invoiceErrors, invoiceDocumentErrors, isSaving = false, displayRemoveButton = true, handleInvoiceDetailUpdate, handleInvoiceAmountValueChange, handleInvoiceFocus, handleInvoiceCurrencyOptionChange, handleInvoiceFileChange, handleInvoiceFileReplace, handleRemoveInvoiceFile, handleDeleteInvoiceFile, saveInvoice, removeInvoice }) => {
    const { invoiceNumber, invoiceDate, purchaseOrder, description, invoiceCopy, existingInvoiceCopy } = invoice;
    const displaySaveButton = saveInvoice !== undefined;
    const actionsClassName = classNames('form-row actions -flex-end', {
        '-col-2': displaySaveButton
    });
    const handleSave = () => {
        if (saveInvoice) {
            saveInvoice(supplierIndex, invoiceIndex);
        }
    };
    return (React.createElement("div", { className: 'form-section invoice -invoice-details', "data-testid": 'invoice-details' },
        React.createElement("div", { className: 'form-row -col-2' },
            React.createElement("div", { className: 'form-group', "data-testid": 'input' },
                React.createElement("div", { className: 'label -uppercase -required' }, "Invoice Number"),
                React.createElement("input", { className: 'input form-control', maxLength: 100, value: invoiceNumber, name: `invoice_details[${supplierIndex}][${invoiceIndex}][invoice_number]`, onChange: handleInvoiceDetailUpdate, id: 'invoice-details-invoice-number', "data-testid": 'invoice-details-invoice-number' }),
                React.createElement(ErrorList, { errors: invoiceErrors, field: 'invoice_number' })),
            React.createElement("div", { className: 'form-group', "data-testid": 'input' },
                React.createElement("label", { className: 'label -uppercase -required' }, "Invoice Date"),
                React.createElement(Flatpickr, { value: invoiceDate, className: 'input form-control', options: invoiceDateSettings, name: `invoice_details[${supplierIndex}][${invoiceIndex}][invoice_date]` }),
                React.createElement(ErrorList, { errors: invoiceErrors, field: 'invoice_date' }))),
        React.createElement("div", { className: 'form-row -col-2' },
            React.createElement("div", { className: 'form-group', "data-testid": 'input' },
                React.createElement("div", { className: 'label -uppercase -required' }, "Purchase Order"),
                React.createElement("input", { className: 'input form-control', maxLength: 100, value: purchaseOrder, name: `invoice_details[${supplierIndex}][${invoiceIndex}][purchase_order]`, onChange: handleInvoiceDetailUpdate, id: 'invoice-details-purchase-order', "data-testid": 'invoice-details-purchase-order' }),
                React.createElement(ErrorList, { errors: invoiceErrors, field: 'purchase_order' })),
            React.createElement("div", { className: 'form-group', "data-testid": 'input' },
                React.createElement("label", { className: 'label -uppercase -required' }, "Payment Currency"),
                React.createElement(Select, { value: selectedInvoiceCurrencyOption, placeholder: 'Select currency', options: invoiceCurrencyOptions, onChange: handleInvoiceCurrencyOptionChange, className: 'select', styles: dropdownStyles, name: `invoice_details[${supplierIndex}][${invoiceIndex}][payment_currency]` }),
                React.createElement(ErrorList, { errors: invoiceErrors, field: 'payment_currency' }))),
        React.createElement("div", { className: 'form-row -col-2' },
            React.createElement("div", { className: 'form-group', "data-testid": 'input' },
                React.createElement("div", { className: 'label -uppercase -required' }, "Invoice Amount"),
                React.createElement(NumberFormat, { value: invoiceAmountValues.invoiceAmountCents.value, className: 'input form-control', thousandSeparator: true, allowNegative: false, decimalScale: 2, onValueChange: handleInvoiceAmountValueChange, onFocus: handleInvoiceFocus, id: 'invoice-details-invoice-amount', "data-testid": 'invoice-details-invoice-amount', name: `invoice_details[${supplierIndex}][${invoiceIndex}][invoice_amount_cents]` }),
                React.createElement(ErrorList, { errors: invoiceErrors, field: 'invoice_amount_cents' })),
            React.createElement("div", { className: 'form-group', "data-testid": 'input' },
                React.createElement("label", { className: 'label -uppercase' }, "Amount to Pay"),
                React.createElement(NumberFormat, { value: invoiceAmountValues.amountToPayCents.value, className: 'input form-control', thousandSeparator: true, allowNegative: false, decimalScale: 2, onValueChange: handleInvoiceAmountValueChange, onFocus: handleInvoiceFocus, id: 'invoice-details-amount-to-pay', "data-testid": 'invoice-details-amount-to-pay', name: `invoice_details[${supplierIndex}][${invoiceIndex}][amount_to_pay_cents]` }),
                React.createElement(ErrorList, { errors: invoiceErrors, field: 'amount_to_pay_cents' }))),
        React.createElement("div", { className: 'form-group group' },
            React.createElement("div", { className: 'label -uppercase -required' }, "Invoice Copy"),
            React.createElement(ErrorList, { errors: invoiceDocumentErrors['invoiceCopy'] || {}, field: 'collectionErrors' }),
            React.createElement(DocumentPicker, { inputName: `invoice_details[${supplierIndex}][${invoiceIndex}][invoice_copy]`, errors: invoiceDocumentErrors['invoiceCopy'] || {}, files: invoiceCopy, existingFiles: existingInvoiceCopy, isRequired: true, handleRemoveFile: handleRemoveInvoiceFile, handleFileChange: handleInvoiceFileChange, handleFileReplace: handleInvoiceFileReplace, handleDeleteFile: handleDeleteInvoiceFile }),
            React.createElement(ErrorList, { errors: invoiceErrors, field: 'invoice_copy' })),
        React.createElement("div", { className: 'form-group' },
            React.createElement("div", { className: 'label -uppercase' }, "Description"),
            React.createElement("textarea", { className: 'form-control -textarea', maxLength: 100, name: `invoice_details[${supplierIndex}][${invoiceIndex}][description]`, onChange: handleInvoiceDetailUpdate, value: description, "data-testid": 'invoice-details-description' }),
            React.createElement(ErrorList, { errors: invoiceErrors, field: 'description' })),
        React.createElement("div", { className: actionsClassName, "data-testid": 'actions' },
            React.createElement("div", { className: 'form-group' }, displayRemoveButton && (React.createElement("button", { className: 'custom-button button -remove-trade-loan-invoice', onClick: () => removeInvoice(supplierIndex, invoiceIndex), "data-testid": 'remove-invoice' }, "Remove Invoice"))),
            React.createElement("div", { className: 'form-group' }, displaySaveButton && (React.createElement(SpinnerButton, { text: 'Save', className: 'custom-button button -save-trade-loan-invoice', handleClick: handleSave, isLoading: isSaving, testId: 'save-invoice' }))))));
};
export default InvoiceDetailsForm;
