import classNames from 'classnames';
import flatpickr from 'flatpickr';
import React, { useEffect } from 'react';
import { isEmptyString } from '../../utils/isEmptyString';
const CustomFlatpickr = ({ selectedDate, clearButtonText, flatpickrSettings = {}, forwardDateClearRef, forwardDatePickerRef, hasError = false, pickerBackground, name, readOnly = false, placeholderText }) => {
    useEffect(() => {
        if (forwardDatePickerRef && forwardDatePickerRef.current) {
            flatpickr(forwardDatePickerRef.current, flatpickrSettings);
        }
    }, []);
    const clearButtonClass = classNames('clear', {
        '-hide': isEmptyString(selectedDate)
    });
    const pickerClass = classNames('picker', {
        '-white': pickerBackground === 'white',
        '-neutral-20': pickerBackground === 'neutral-20',
        '-error': hasError,
        '-has-content': !isEmptyString(selectedDate)
    });
    return (React.createElement("div", { className: 'date-picker date', ref: forwardDatePickerRef },
        React.createElement("div", { className: pickerClass },
            React.createElement("input", { type: 'text', className: 'form-control field -bare -date-picker', placeholder: placeholderText || 'Select date', name: name, "data-input": true, disabled: readOnly }),
            React.createElement("button", { type: 'button', className: 'icon-button button toggle -calendar', "data-toggle": true },
                React.createElement("i", { className: 'icon' }))),
        !readOnly && (React.createElement("div", { className: 'controls' },
            React.createElement("div", { className: clearButtonClass, ref: forwardDateClearRef, "data-clear": true }, clearButtonText || 'Clear')))));
};
export default CustomFlatpickr;
