import React, { useState } from 'react';
import Select from 'react-select';
import ErrorList from '../shared/ErrorList';
import SpinnerButton from '../shared/SpinnerButton';
import { updateExcessReceipt } from '../../api/client_setup';
import { isAPIValidationErrors, isAPIErrors } from '../../../types';
import { dropdownStyles } from '../../custom-styles/dropdown';
import { showNotifyToast } from '../../utils';
const ExcessReceiptProcedure = ({ authToken, borrowerId, excessReceiptProcedureOptions, client, isReadOnly = false, incrementSaves }) => {
    const initialExcessReceiptProcedureName = () => {
        if (client.excessReceipt) {
            return (excessReceiptProcedureOptions.find((option) => option.value === client.excessReceipt) || null);
        }
        return null;
    };
    const [isLoading, setIsLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [selectedOption, setSelectedOption] = useState(initialExcessReceiptProcedureName());
    const handleNameChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };
    const handleSave = async () => {
        const body = {
            company: {
                excessReceipt: selectedOption ? selectedOption.value : ''
            }
        };
        setIsLoading(true);
        try {
            const response = await updateExcessReceipt(authToken, borrowerId, body);
            if (isAPIValidationErrors(response)) {
                throw response;
            }
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            setValidationErrors({});
            if (incrementSaves) {
                incrementSaves();
            }
            showNotifyToast({
                text: 'Successfully updated Excess Receipt.',
                type: 'success'
            });
        }
        catch (err) {
            if (isAPIValidationErrors(err)) {
                setValidationErrors(err.errors);
            }
            showNotifyToast({
                text: 'Failed to update Excess Receipt.',
                type: 'error'
            });
        }
        setIsLoading(false);
    };
    return (React.createElement("div", { className: 'client-setup-form form -excess-receipt-procedure', "data-testid": 'excess-receipt-procedure-form' },
        React.createElement("div", { className: 'form-group group' },
            React.createElement("label", { className: 'label -uppercase' }, "Name"),
            React.createElement(Select, { options: excessReceiptProcedureOptions, styles: dropdownStyles, placeholder: 'Please select an excess receipt', value: selectedOption, onChange: handleNameChange, isDisabled: isReadOnly }),
            React.createElement(ErrorList, { errors: validationErrors, field: 'name' })),
        React.createElement("div", { className: 'action-buttons actions' },
            React.createElement(SpinnerButton, { text: 'Save', buttonType: 'button', isLoading: isLoading, handleClick: handleSave, className: 'button -primary', isDisabled: isReadOnly, testId: 'save' }))));
};
export default ExcessReceiptProcedure;
