import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
const InvoiceBatchItem = ({ invoiceBatch, invoiceBatchPath }) => {
    const percentage = 60;
    const progressbarStyle = {
        pathColor: '#ffc400',
        textColor: '#c86d00',
        trailColor: '#fffae6'
    };
    return (React.createElement("a", { href: `${invoiceBatchPath}/${invoiceBatch.id}` },
        React.createElement("div", { className: 'custom-panel -link' },
            React.createElement("div", { className: 'invoice-batch-info' },
                React.createElement("div", { className: 'info' },
                    React.createElement("div", { className: 'detail -date' },
                        React.createElement("div", { className: 'data-figure -tight' },
                            React.createElement("p", { className: 'label -no-padding' }, "Batch created on"),
                            React.createElement("p", { className: 'value -bold -larger' }, invoiceBatch.createdAtFormatted)))),
                React.createElement("div", { className: 'status' },
                    React.createElement("div", { className: 'progress' },
                        React.createElement("div", { className: 'progressbar' },
                            React.createElement(CircularProgressbar, { value: percentage, strokeWidth: 12, className: 'icon', styles: buildStyles(progressbarStyle) }),
                            React.createElement("div", { className: 'figure' },
                                React.createElement("p", { className: 'data -bold' },
                                    "2 of",
                                    ' ',
                                    invoiceBatch.minimumNumberOfDebtors > 0
                                        ? invoiceBatch.minimumNumberOfDebtors
                                        : 0),
                                React.createElement("p", { className: 'label' }, "Debtors Verified")))),
                    React.createElement("div", { className: 'progress' },
                        React.createElement("div", { className: 'progressbar' },
                            React.createElement(CircularProgressbar, { value: 25, strokeWidth: 12, className: 'icon', styles: buildStyles(progressbarStyle) }),
                            React.createElement("div", { className: 'figure' },
                                React.createElement("p", { className: 'data -bold -yellow' }, "25%"),
                                React.createElement("p", { className: 'label' }, "Invoices Verified")))))),
            React.createElement("span", { className: 'divider' }),
            React.createElement("div", { className: 'data-summary -dividers -sixth' },
                React.createElement("div", { className: 'data-figure figure -tight' },
                    React.createElement("p", { className: 'label -no-padding' }, "Total Receivables"),
                    React.createElement("p", { className: 'value -larger -green' }, "$75,999.20")),
                React.createElement("div", { className: 'data-figure figure -tight' },
                    React.createElement("p", { className: 'label -no-padding' }, "Variances"),
                    React.createElement("p", { className: 'value -larger -green' }, "$75,990.20")),
                React.createElement("div", { className: 'data-figure figure -tight' },
                    React.createElement("p", { className: 'label -no-padding' }, "Approved Receivables"),
                    React.createElement("p", { className: 'value -larger -green' }, "$75,990.20")),
                React.createElement("div", { className: 'data-figure figure -tight' },
                    React.createElement("p", { className: 'label -no-padding' }, "Credit Notes"),
                    React.createElement("p", { className: 'value -larger -green' }, "$75,990.20")),
                React.createElement("div", { className: 'data-figure figure -tight' },
                    React.createElement("p", { className: 'label -no-padding' }, "Unapproved Receivables"),
                    React.createElement("p", { className: 'value -larger -green' }, "$75,990.20")),
                React.createElement("div", { className: 'data-figure figure -tight' },
                    React.createElement("p", { className: 'label -no-padding' }, "Paid Receivables"),
                    React.createElement("p", { className: 'value -larger -green' }, "$75,990.20"))))));
};
export default InvoiceBatchItem;
