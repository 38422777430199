import { buildHeaders } from './shared';
export const enablePrompt = async (apiToken, borrowerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/myob_bank_feed_reference/enable_prompt`;
    const body = { enableMyobBankFeedPrompt: true };
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'PUT',
            body: JSON.stringify(body)
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.map(error => error.title).join(','));
        }
        return response.json();
    }
    catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message);
        }
    }
};
export const disablePrompt = async (apiToken, borrowerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/myob_bank_feed_reference/disable_prompt`;
    const body = { enableMyobBankFeedPrompt: false };
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'PUT',
            body: JSON.stringify(body)
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.map(error => error.title).join(','));
        }
        return response.json();
    }
    catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message);
        }
    }
};
