import React, { useEffect, useMemo, useState } from 'react';
import { dropdownStyles } from '../../custom-styles/dropdown';
import Select from 'react-select';
const DrawdownApprovalOption = ({ isZeroFee, csrfToken, approvalTypes, rejectPath, approvePath }) => {
    const [selectedOption, setSelectedOption] = useState('Destination Account');
    const [approvalTypeOptions, setApprovalTypeOptions] = useState([]);
    const [showPaymentReference, setShowPaymentReference] = useState(false);
    useEffect(() => {
        const results = approvalTypes.map((data) => {
            return {
                value: data,
                label: data
            };
        });
        setApprovalTypeOptions(results);
    }, [approvalTypes]);
    const formattedSelectedOption = useMemo(() => {
        return {
            label: selectedOption,
            value: selectedOption
        };
    }, [selectedOption]);
    const checkApprovalReference = value => {
        const optionValue = value.toLowerCase();
        if (optionValue.includes('third party account'))
            return true;
        if (optionValue.includes('ef loan repayment'))
            return true;
        if (optionValue.includes('earlypay trade repayments'))
            return true;
        if (optionValue.includes('no pay'))
            return true;
        return false;
    };
    const handleChange = (event) => {
        const value = event.value;
        setShowPaymentReference(checkApprovalReference(value));
        setSelectedOption(value);
    };
    return (React.createElement("div", { className: 'custom-panel' },
        React.createElement("div", { className: 'header' }, "Approval Option"),
        React.createElement("div", { className: 'body' },
            React.createElement("form", { className: 'form', action: approvePath, acceptCharset: 'UTF-8', method: 'post' },
                React.createElement("input", { type: 'hidden', name: '_method', value: 'put' }),
                React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
                React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { className: 'label', htmlFor: 'approval_type' }, "Approval Type"),
                    React.createElement(Select, { name: 'approval_type', options: approvalTypeOptions, styles: dropdownStyles, placeholder: 'Please select an approval type', value: formattedSelectedOption, defaultValue: formattedSelectedOption, onChange: handleChange })),
                React.createElement("div", { className: 'form-group' },
                    React.createElement("div", { className: 'checkbox' },
                        React.createElement("label", null,
                            React.createElement("input", { type: 'hidden', name: 'rtgs_required', value: 'false' }),
                            React.createElement("input", { type: 'checkbox', name: 'rtgs_required', id: 'rtgs_required', value: 'true' }),
                            "\u00A0 RTGS Required"))),
                React.createElement("div", { className: 'form-group' },
                    React.createElement("div", { className: 'checkbox' },
                        React.createElement("label", null,
                            React.createElement("input", { type: 'hidden', name: 'send_notifications', value: 'false' }),
                            React.createElement("input", { type: 'checkbox', name: 'send_notifications', id: 'send_notifications', value: 'true' }),
                            "\u00A0 Send Notifications?"))),
                !isZeroFee ? (React.createElement("div", { className: 'form-group' },
                    React.createElement("div", { className: 'checkbox' },
                        React.createElement("label", null,
                            React.createElement("input", { type: 'hidden', name: 'zero_drawdown_fee', value: 'false' }),
                            React.createElement("input", { type: 'checkbox', name: 'zero_drawdown_fee', id: 'zero_drawdown_fee', value: 'true' }),
                            "\u00A0 Zero Drawdown Fee?")))) : null,
                showPaymentReference ? (React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { className: 'label', htmlFor: 'payment_reference' }, "Payment Reference"),
                    React.createElement("input", { type: 'text', name: 'payment_reference', className: 'input admin-input form-control', placeholder: 'Type payment reference here' }))) : null,
                React.createElement("div", { className: 'action-buttons' },
                    React.createElement("button", { name: 'button', type: 'submit', className: 'icon-button -margin -rectangular -header -approve -green', "data-disable-with": "<div className='icon'></div><div className='text'>Processing...</div>" },
                        React.createElement("div", { className: 'icon' }),
                        React.createElement("div", { className: 'text' }, "Approve")),
                    React.createElement("a", { className: 'icon-button -rectangular -header -reject -red keychainify-checked', "data-disable-with": "<div className='icon'></div><div className='text'>Processing...</div>", "data-confirm": 'Are you sure?', rel: 'nofollow', "data-method": 'put', href: rejectPath },
                        React.createElement("div", { className: 'icon' }),
                        React.createElement("div", { className: 'text' }, "Reject")))))));
};
export default DrawdownApprovalOption;
