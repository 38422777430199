import React, { useEffect, useRef, useState } from 'react';
import PostBuilder from '../../components/shared/PostBuilder';
import useOutsideClick from '../../hooks/useOutsideClick';
import { isEmptyObject } from '../../utils';
import { isEmptyString } from '../../utils/isEmptyString';
import { generateFileErrors } from '../../validations/file';
const AdminPostPanel = ({ formAttributes, userAvatarUrl }) => {
    const [postBody, setPostBody] = useState('');
    const [files, setFiles] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const filePickerRef = useRef(null);
    const emojiPickerRef = useRef(null);
    const formRef = useRef(null);
    const { csrfToken, url } = formAttributes;
    useEffect(() => {
        setIsFirstRender(false);
    }, []);
    useEffect(() => {
        if (!isFirstRender) {
            const updatedValidationErrors = pipe([validateBody, validateFiles], validationErrors);
            setValidationErrors(updatedValidationErrors);
        }
    }, [postBody, files]);
    useEffect(() => {
        if (!isFirstRender) {
            isEmptyObject(validationErrors) ? setIsValid(true) : setIsValid(false);
        }
    }, [validationErrors]);
    const pipe = (funcs, params) => funcs.reduce((res, func) => func(res), params);
    useOutsideClick(emojiPickerRef, () => {
        if (showEmojiPicker) {
            setShowEmojiPicker(false);
        }
    });
    const validateBody = (errors) => {
        const bodyErrors = isEmptyString(postBody)
            ? [`You haven't written anything!`]
            : [];
        if (bodyErrors.length > 0) {
            return { ...errors, body: bodyErrors };
        }
        else {
            const { body, ...updatedErrors } = errors;
            return updatedErrors;
        }
    };
    const validateFiles = (errors) => {
        const fileErrors = generateFileErrors(files);
        if (fileErrors.length > 0) {
            return { ...errors, attachment: fileErrors };
        }
        else {
            const { attachment, ...updatedErrors } = errors;
            return updatedErrors;
        }
    };
    const handleAttachmentTrigger = (event) => {
        event.preventDefault();
        if (filePickerRef && filePickerRef.current) {
            filePickerRef.current.click();
        }
    };
    const handleClearAttachments = (event) => {
        event.preventDefault();
        if (filePickerRef && filePickerRef.current) {
            filePickerRef.current.value = '';
        }
        setFiles([]);
    };
    const handleFileChange = (event) => {
        event.preventDefault();
        const currentFileList = event.target.files;
        const currentFiles = [...currentFileList];
        setFiles(currentFiles);
    };
    const handlePostBodyChange = (event) => {
        event.preventDefault();
        const currentPostBody = event.currentTarget.value;
        setPostBody(currentPostBody);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        if (formRef && formRef.current) {
            formRef.current.submit();
        }
    };
    const selectEmoji = emoji => {
        setPostBody(currentBody => currentBody + emoji.native);
    };
    const toggleEmojiPicker = () => {
        setShowEmojiPicker(current => !current);
    };
    return (React.createElement("div", { className: 'post-panel custom-panel -admin', "data-testid": 'admin-post-panel' },
        React.createElement("div", { className: 'body' },
            React.createElement("form", { acceptCharset: 'UTF-8', method: 'post', encType: 'multipart/form-data', action: url, className: 'form', onSubmit: handleSubmit, ref: formRef },
                React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
                React.createElement(PostBuilder, { ...{
                        files,
                        postBody,
                        showEmojiPicker,
                        validationErrors,
                        isSubmitting,
                        userAvatarUrl,
                        handleAttachmentTrigger,
                        handleClearAttachments,
                        handleFileChange,
                        handlePostBodyChange,
                        selectEmoji,
                        toggleEmojiPicker
                    }, disableSubmit: !isValid || isSubmitting, forwardFilePickerRef: filePickerRef, forwardEmojiPickerRef: emojiPickerRef })))));
};
export default AdminPostPanel;
