import fetch from 'isomorphic-fetch';
export const fetchComments = async (apiToken, commentableId, commentableType, scope, page) => {
    const endpoint = `/api/${scope}/${commentableType}/${commentableId}/comments?page=${page}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const addComment = async (apiToken, commentableId, commentableType, comment, scope, privacySetting = 'public') => {
    const endpoint = `/api/${scope}/${commentableType}/${commentableId}/comments`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'POST',
        body: buildFormData(comment, privacySetting)
    }).then((res) => res.json());
};
const buildFormData = (comment, privacySetting = 'public') => {
    const formData = new FormData();
    const files = comment.files;
    formData.append('body', comment.body);
    formData.append('privacy_setting', privacySetting);
    if (files) {
        files.forEach(file => {
            formData.append('attachments[]', file);
        });
    }
    return formData;
};
export const editComment = async (apiToken, updatedComment, commentId, commentableId, commentableType, scope) => {
    const endpoint = `/api/${scope}/${commentableType}/${commentableId}/comments/${commentId}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PATCH',
        body: buildFormData(updatedComment)
    }).then((res) => res.json());
};
export const deleteComment = async (apiToken, commentId, commentableId, commentableType, scope) => {
    const endpoint = `/api/${scope}/${commentableType}/${commentableId}/comments/${commentId}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'DELETE'
    }).then((res) => (res.status === 204 ? res : res.json()));
};
