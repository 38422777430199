import React from 'react';
import CompanyHitDisplay from './CompanyHitDisplay';
import CustomerHitDisplay from './CustomerHitDisplay';
import InvoiceHitDisplay from './InvoiceHitDisplay';
const HitDisplay = ({ hit, userType }) => {
    switch (hit.indexName) {
        case 'Invoice':
            return React.createElement(InvoiceHitDisplay, { ...{ hit, userType } });
        case 'Customer':
            return React.createElement(CustomerHitDisplay, { ...{ hit, userType } });
        case 'Company':
            return React.createElement(CompanyHitDisplay, { ...{ hit } });
    }
    return null;
};
export default HitDisplay;
