import React, { useEffect, useState } from 'react';
import { fetchEvents } from '../api/event';
import { removeDuplicates } from '../utils/removeDuplicates';
import ActivitiesContext from '../contexts/ActivitiesContext';
import ActivityFeed from '../components/activity-feed/ActivityFeed';
const BrokerClientActivities = ({ apiToken, clientId, userAvatarUrl }) => {
    const [events, setEvents] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const scope = `broker/clients/${clientId}`;
    const context = {
        apiToken,
        feedType: 'client-scoped-broker'
    };
    const nextFetches = () => {
        if (hasMore === true) {
            handleFetchEvents(page);
            setPage(page + 1);
        }
    };
    const handleFetchEvents = async (currentPage) => {
        try {
            const response = await fetchEvents(apiToken, scope, currentPage, []);
            if (response.last_page === true) {
                setHasMore(false);
            }
            setEvents(prevEvents => removeDuplicates([...prevEvents, ...response.data]));
            setHasErrors(false);
        }
        catch (err) {
            setHasErrors(true);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        setIsLoading(true);
        setPage(2);
        setEvents([]);
        setHasMore(true);
        handleFetchEvents(1);
    }, []);
    return (React.createElement("div", { className: 'activities-container activities -broker' },
        React.createElement(ActivitiesContext.Provider, { value: context },
            React.createElement("div", { className: 'body -full-width' },
                React.createElement("h2", { className: 'title -sub' }, "Activity Feed"),
                React.createElement(ActivityFeed, { ...{
                        events,
                        isLoading,
                        hasErrors,
                        hasMore,
                        nextFetches,
                        userAvatarUrl
                    } })))));
};
export default BrokerClientActivities;
