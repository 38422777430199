import React from 'react';
const AdminCreditorRow = ({ creditor, borrowerCreditorsPath }) => {
    const { currentDueAmountTotal, id, overdueAmountTotal } = creditor;
    const creditorLink = `${borrowerCreditorsPath}/${id}`;
    return (React.createElement("tr", { "data-testid": 'admin-creditor-row' },
        React.createElement("td", { className: 'cell' }, creditor.name),
        React.createElement("td", { className: 'cell' }, currentDueAmountTotal),
        React.createElement("td", { className: 'cell' }, overdueAmountTotal),
        React.createElement("td", { className: 'cell' },
            React.createElement("a", { href: creditorLink }, "View"))));
};
export default AdminCreditorRow;
