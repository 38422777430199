import classNames from 'classnames';
import React from 'react';
import HitDisplay from './HitDisplay';
const SearchResults = ({ hits, hasErrors, isLoading, userType }) => {
    const searchResultsClass = classNames('search-results results', {
        '-admin': userType === 'admin',
        '-borrower': userType === 'borrower'
    });
    if (isLoading) {
        return (React.createElement("div", { className: `${searchResultsClass} -loading`, "data-testid": 'loading-results' },
            React.createElement("div", { className: 'message loading-container -search-results' },
                React.createElement("div", { className: 'loading-spinner spinner' }))));
    }
    else if (hasErrors) {
        return (React.createElement("div", { className: `${searchResultsClass} -error`, "data-testid": 'error-results' },
            React.createElement("div", { className: 'message' }, "Something went wrong. Please refresh the page and try again.")));
    }
    else if (hits.length > 0) {
        return (React.createElement("div", { className: `${searchResultsClass} scrollable-container`, "data-testid": 'loaded-results' }, hits.map((hit, i) => (React.createElement(HitDisplay, { ...{ hit, userType }, key: i })))));
    }
    else {
        return (React.createElement("div", { className: `${searchResultsClass} -empty`, "data-testid": 'empty-results' },
            React.createElement("div", { className: 'message' },
                React.createElement("div", { className: 'text' }, "There are no results."))));
    }
};
export default SearchResults;
