import classNames from 'classnames';
import React from 'react';
import { humanizeString } from '../../utils';
const CompanyStatus = ({ status }) => {
    const isNeedsAnalysis = status === 'needs_analysis';
    const isInitialOffer = status === 'initial_offer';
    const isSubmission = status === 'submission';
    const isSettlement = status === 'settlement';
    const isLegals = status === 'legals';
    const isClosedWon = status === 'closed_won';
    const isArchived = status === 'archived';
    const isCollectOut = status === 'collect_out';
    const isClosedOut = status === 'closed_out';
    const statusClass = classNames('company-status', {
        '-needs-analysis': isNeedsAnalysis,
        '-initial-offer': isInitialOffer,
        '-submission': isSubmission,
        '-settlement': isSettlement,
        '-legals': isLegals,
        '-closed-won': isClosedWon,
        '-archived': isArchived,
        '-collect-out': isCollectOut,
        '-closed-out': isClosedOut
    });
    if (status) {
        return (React.createElement("div", { className: statusClass, "data-testid": 'company-status' }, humanizeString(status)));
    }
    return null;
};
export default CompanyStatus;
