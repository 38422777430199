import React from 'react';
import InfoTooltip from '../../components/custom-tooltip/InfoTooltip';
const BreakdownContent = ({ rate, interest }) => {
    return (React.createElement("div", { className: 'tooltip-content', "data-testid": 'tooltip' },
        React.createElement("div", { className: 'title' }, "Breakdown"),
        React.createElement("div", { className: 'body' },
            "Base Rate (",
            React.createElement("span", null, rate),
            ") + Interest Rate Margin (",
            React.createElement("span", null, interest),
            ")")));
};
const BreakdownInterest = ({ tooltipId, tooltipDirection, baseRate, interestMargin }) => {
    const tooltipProps = {
        tooltipId: tooltipId,
        tooltipDirection: tooltipDirection,
        customStyle: 'tooltip-content'
    };
    return (React.createElement(InfoTooltip, { ...tooltipProps },
        React.createElement(BreakdownContent, { rate: baseRate, interest: interestMargin })));
};
export default BreakdownInterest;
