import React from 'react';
import LedgerSummary from './LedgerSummary';
import Availability from './Availability';
const ManualLedgerFunding = ({ funding }) => {
    const selectedLedgerSummaryFields = [
        'grossReceivablesLedgerFormatted',
        'unselectedDebtorsFormatted',
        'selectedLedgerFormatted',
        'unallocatedPaymentsFormatted',
        'unapprovedDebtorsFormatted',
        'pendingInvoicesFormatted',
        'ineligibleAdjustmentsFormatted',
        'approvedLedgerFormatted',
        'approvedLedgerFormatted'
    ];
    const selectedAvailabilityFields = [
        'approvedLedgerFormatted',
        'advanceRate',
        'debtorAdvanceRateAdjustmentFormatted',
        'maximumPermittedFundingFormatted',
        'fundsInUseFormatted',
        'accruedFeesAndInterestFormatted',
        'surplusFundsFormatted',
        'totalBalanceOutstandingFormatted',
        'availabilityReductionFormatted',
        'facilityLimitExcessFormatted',
        'availableFundsFormatted',
        'facilityLimitFormatted'
    ];
    const ledgerSummary = selectedLedgerSummaryFields.reduce((acc, item) => {
        return { ...acc, [item]: funding[item] };
    }, {});
    const availability = selectedAvailabilityFields.reduce((acc, item) => {
        return { ...acc, [item]: funding[item] };
    }, {});
    return (React.createElement("div", { "data-testid": 'manual-ledger-funding' },
        React.createElement(LedgerSummary, { funding: ledgerSummary, isDebtorsLedgerRecalculating: funding.isDebtorsLedgerRecalculating }),
        React.createElement(Availability, { funding: availability, isDebtorsLedgerRecalculating: funding.isDebtorsLedgerRecalculating })));
};
export default ManualLedgerFunding;
