import React from 'react';
import EmojiPicker from '../shared/EmojiPicker';
import SpinnerButton from '../shared/SpinnerButton';
import Textarea from 'react-expanding-textarea';
const EditForm = ({ commentBody, isSaving, emojiPickerDirection = 'down', showEmojiPicker, validationErrors, forwardEditTextFieldRef, forwardEmojiPickerRef, closeEditor, handleCommentBodyChange, handleSubmit, selectEmoji, toggleEmojiPicker }) => {
    return (React.createElement("form", { className: 'comment-edit-form', onSubmit: handleSubmit, "data-testid": 'edit-form' },
        React.createElement("div", { className: 'editor' },
            React.createElement("div", { className: 'fields' },
                React.createElement(Textarea, { className: 'input form-control body -textarea -comment -edit -noresize', "data-testid": 'edit-form-textarea', onChange: handleCommentBodyChange, value: commentBody, ref: forwardEditTextFieldRef }),
                validationErrors.body &&
                    validationErrors.body.map((error, i) => (React.createElement("div", { className: 'form-field-error', "data-testid": 'form-field-error', key: i }, error)))),
            React.createElement("div", { className: 'controls' },
                React.createElement("button", { className: 'icon-button button -emoji', type: 'button', "data-testid": 'emoji', onClick: toggleEmojiPicker },
                    React.createElement("i", { className: 'icon' })),
                React.createElement(EmojiPicker, { ...{ forwardEmojiPickerRef, selectEmoji }, direction: emojiPickerDirection, isActive: showEmojiPicker }))),
        React.createElement("div", { className: 'actions' },
            React.createElement(SpinnerButton, { text: 'Save', isDisabled: isSaving, isLoading: isSaving, buttonType: 'submit', testId: 'save-comment' }),
            React.createElement("button", { onClick: closeEditor, className: 'icon-button close -cancel', type: 'button', "data-testid": 'close' },
                React.createElement("i", { className: 'icon' })))));
};
export default EditForm;
