import classNames from 'classnames';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import ErrorList from '../shared/ErrorList';
import { convertCentsString, convertCents } from '../../utils/convertCents';
const ApprovalOption = ({ isTradeLoanApprover, isProcessTradeLoan, invoices, pathApproved, pathReject, pathProcess, csrfToken, errors, processedTotalAmountCents, status }) => {
    const isPending = status === 'Pending';
    const isApproved = status === 'Approved';
    const initialAmount = {
        formattedValue: '',
        value: convertCentsString(processedTotalAmountCents),
        floatValue: convertCents(processedTotalAmountCents)
    };
    const [processAmount, setProcessAmount] = useState(initialAmount);
    const processClass = classNames(`button -primary -full-width -center`, {
        '-disabled': !isProcessTradeLoan
    });
    const handleProcessAmountChange = (values) => {
        setProcessAmount(values);
    };
    const [enableSendNotification, setEnableSendNotification] = useState(false);
    const handleCheckboxChange = () => {
        setEnableSendNotification(!enableSendNotification);
    };
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'approval-options' },
        React.createElement("div", { className: 'header -space-between' },
            React.createElement("div", null, "Approval Option"),
            React.createElement("div", { className: 'notification' },
                React.createElement("label", { className: 'remove' },
                    React.createElement("input", { type: 'checkbox', className: 'checkbox', name: 'send_notification', onChange: handleCheckboxChange }),
                    ' ',
                    "Send Notifications?"))),
        (isProcessTradeLoan || isTradeLoanApprover) && isApproved && (React.createElement("div", { "data-testid": 'for-process-loan' },
            React.createElement("div", { className: 'trade-loan-info' },
                React.createElement("div", { className: 'info' },
                    React.createElement("div", { className: 'title' }, "Foreign Currency Invoices"),
                    invoices.map(invoice => {
                        return (React.createElement("div", { className: 'value', key: invoice.id },
                            React.createElement("span", { className: 'bold' },
                                React.createElement(NumberFormat, { value: convertCents(invoice.amountDueCents), displayType: 'text', thousandSeparator: true, allowNegative: false, decimalScale: 2, id: `summary-amount-${invoice.id}`, "data-testid": 'summary-amount' })),
                            ` ${invoice.paymentCurrency} Invoice`));
                    }))),
            React.createElement("div", { className: 'trade-loan-process' },
                React.createElement("form", { className: 'form', action: pathProcess, acceptCharset: 'UTF-8', method: 'post' },
                    React.createElement("input", { type: 'hidden', name: '_method', value: 'put' }),
                    React.createElement("input", { type: 'hidden', name: 'send_notifications', value: enableSendNotification.toString() }),
                    React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
                    React.createElement("div", { className: 'form-group' },
                        React.createElement("label", { htmlFor: 'trade-loan-total-amount', className: 'label -uppercase -required' }, "Total Amount"),
                        React.createElement(NumberFormat, { value: processAmount.value, className: 'input form-control', prefix: '$', thousandSeparator: true, allowNegative: false, decimalScale: 2, onValueChange: handleProcessAmountChange, id: 'approval-option-total-amount', "data-testid": 'approval-option-total-amount' }),
                        React.createElement(ErrorList, { errors: errors, field: 'processed_total_amount_cents' }),
                        React.createElement("input", { type: 'hidden', name: 'trade_loan[processed_total_amount]', value: processAmount.floatValue })),
                    React.createElement("div", { className: 'form-group' },
                        React.createElement("label", null,
                            React.createElement("input", { type: 'checkbox', className: 'checkbox', name: 'charge_process_fee', value: 'true' }),
                            ' ',
                            "Charge $15 Western Union fee")),
                    React.createElement("div", { className: 'action-buttons' },
                        React.createElement("button", { type: 'submit', className: processClass, disabled: !isProcessTradeLoan }, "Process Loan")))))),
        React.createElement("div", { className: 'body' },
            isTradeLoanApprover && isPending && (React.createElement("div", { className: 'action-buttons space-between', "data-testid": 'trade-loan-manager' },
                React.createElement("a", { className: 'button -reset -border -full-width -center', "data-method": 'PUT', href: pathReject }, "Decline"),
                React.createElement("a", { className: 'button -primary -full-width -center', "data-method": 'PUT', href: pathApproved + '?send_notifications=' + enableSendNotification }, "Approve"))),
            !isTradeLoanApprover && isPending && (React.createElement("div", { className: 'placeholder-pane', "data-testid": 'awaiting-approval' }, "Awaiting approval from Trade Loan Manager")))));
};
export default ApprovalOption;
