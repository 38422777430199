import React from 'react';
import IconToggle from '../shared/IconToggle';
import ToggleSwitch from '../shared/ToggleSwitch';
import EarlypayStatusSelect from './EarlypayStatusSelect';
import classNames from 'classnames';
const AdminCustomerRow = ({ isManualLedger, isDebtorsLedgerRecalculating, customer, enableActions = false, approveCustomerHandler, pendingCustomerHandler, isRequestPending, deselectCustomerHandler, setCustomerCreditCheckHandler, unsetCustomerCreditCheckHandler, setCustomerNoaSentHandler, unsetCustomerNoaSentHandler, insureCustomerHandler, uninsureCustomerHandler, promptCustomerFee, checkIcon, updateEarlypayStatusHandler }) => {
    const { id, isSelected, advanceRateFormatted, creditChecked, noaSent, earlypayStatus, insuredAt, receivablesFormatted, approvedReceivablesAfterConcentrationAdjustmentFormatted, unapprovedReceivablesFormatted, concentrationAdjustmentFormatted, customerLink } = customer;
    const onConfirmationText = `Are you sure you want to turn ${customer.name} on?`;
    const offConfirmationText = `Are you sure you want to turn ${customer.name} off?`;
    const deselectConfirmationText = `Are you sure you want to deselect ${customer.name}?`;
    const discloseTypeClass = classNames({
        'badge-risk -high': customer.discloseType === 'disclosed',
        'badge-risk -critical': customer.discloseType === 'confidential'
    }, 'badge-risk -medium');
    // For now, manual ledger & connected clients (Xero & MYOB) will have different fields
    if (isManualLedger) {
        return (React.createElement("tr", { "data-testid": 'admin-customer-row' },
            React.createElement("td", { className: 'cell' }, customer.sourceId),
            React.createElement("td", { className: 'cell' },
                React.createElement("a", { href: customerLink }, customer.name)),
            React.createElement("td", { className: 'cell' },
                React.createElement(ToggleSwitch, { isToggled: isSelected, switchOn: () => promptCustomerFee(customer), switchOff: () => window.confirm(deselectConfirmationText) &&
                        deselectCustomerHandler(id), isRequestPending: isRequestPending, isSwitchDisabled: !enableActions })),
            React.createElement("td", { className: 'cell' },
                React.createElement(EarlypayStatusSelect, { customer: customer, updateEarlypayStatusHandler: updateEarlypayStatusHandler })),
            React.createElement("td", { className: 'cell' }, advanceRateFormatted),
            React.createElement("td", { className: 'cell' }, customer.approvedInsuranceLimitFormatted === '-'
                ? '$0.00'
                : customer.approvedInsuranceLimitFormatted),
            React.createElement("td", { className: 'cell' },
                React.createElement("td", { className: 'cell' }, customer.debtorLimitFormatted)),
            React.createElement("td", { className: 'cell' }, customer.concentrationLimitFormatted),
            React.createElement("td", { className: 'cell' }, customer.selectedLedgerFormatted),
            React.createElement("td", { className: 'cell' }, customer.pendingInvoicesFormatted),
            React.createElement("td", { className: 'cell -color-neutral-20 ' }, customer.disapprovedInvoicesFormatted),
            React.createElement("td", { className: 'cell -color-neutral-20' }, customer.debtorLimitExcessFormatted),
            React.createElement("td", { className: 'cell -color-neutral-20' }, customer.concentrationLimitExcessFormatted),
            React.createElement("td", { className: 'cell' }, isDebtorsLedgerRecalculating ? (React.createElement("div", { className: 'badge-risk -medium' }, "Processing")) : (customer.ineligibleAdjustmentsFormatted)),
            React.createElement("td", { className: 'cell' }, isDebtorsLedgerRecalculating ? (React.createElement("div", { className: 'badge-risk -medium' }, "Processing")) : (customer.approvedLedgerFormatted)),
            React.createElement("td", { className: 'cell' }, isDebtorsLedgerRecalculating ? (React.createElement("div", { className: 'badge-risk -medium' }, "Processing")) : (customer.advanceRateAdjustmentFormatted))));
    }
    return (React.createElement("tr", { "data-testid": 'admin-customer-row' },
        React.createElement("td", { className: 'cell' },
            React.createElement("a", { href: customerLink },
                customer.name,
                " "),
            React.createElement("img", { className: 'icon-pack -primary -round -medium -margin-left', src: checkIcon })),
        React.createElement("td", { className: 'cell' }, receivablesFormatted),
        React.createElement("td", { className: 'cell' }, approvedReceivablesAfterConcentrationAdjustmentFormatted),
        React.createElement("td", { className: 'cell' }, unapprovedReceivablesFormatted),
        React.createElement("td", { className: 'cell' }, concentrationAdjustmentFormatted),
        React.createElement("td", { className: 'cell' }, advanceRateFormatted),
        React.createElement("td", { className: 'cell' },
            React.createElement(IconToggle, { onIcon: 'check', offIcon: 'close', isOn: creditChecked !== null, isToggleable: !isRequestPending && enableActions, switchOff: () => unsetCustomerCreditCheckHandler(id), switchOn: () => setCustomerCreditCheckHandler(id) })),
        React.createElement("td", { className: 'cell' },
            React.createElement(IconToggle, { onIcon: 'check', offIcon: 'close', isOn: noaSent !== null, isToggleable: !isRequestPending && enableActions, switchOff: () => unsetCustomerNoaSentHandler(id), switchOn: () => setCustomerNoaSentHandler(id) })),
        React.createElement("td", { className: 'cell' },
            React.createElement(IconToggle, { onIcon: 'check', offIcon: 'close', isOn: insuredAt !== null, isToggleable: !isRequestPending && enableActions, switchOff: () => uninsureCustomerHandler(id), switchOn: () => insureCustomerHandler(id) })),
        React.createElement("td", { className: 'cell -capitalize' },
            React.createElement("span", { className: discloseTypeClass }, customer.discloseType)),
        React.createElement("td", { className: 'cell' },
            React.createElement(ToggleSwitch, { isToggled: isSelected, switchOn: () => promptCustomerFee(customer), switchOff: () => window.confirm(deselectConfirmationText) &&
                    deselectCustomerHandler(id), isRequestPending: isRequestPending, isSwitchDisabled: !enableActions })),
        React.createElement("td", { className: 'cell' },
            React.createElement(ToggleSwitch, { isToggled: earlypayStatus === 'APPROVED', switchOn: () => window.confirm(onConfirmationText) &&
                    approveCustomerHandler(id, customer), switchOff: () => window.confirm(offConfirmationText) && pendingCustomerHandler(id), isRequestPending: isRequestPending, isSwitchDisabled: !enableActions }))));
};
export default AdminCustomerRow;
