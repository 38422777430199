import React, { useState } from 'react';
import Drawer from '../shared/Drawer';
const UploadedFileNotesDrawer = ({ submitPath, csrfToken }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'action-buttons -center' },
            React.createElement("button", { className: 'button -secondary -bare -leading-normal', onClick: () => setIsOpen(true) }, "Add Notes")),
        React.createElement(Drawer, { isOpen: isOpen, onClose: () => setIsOpen(false) },
            React.createElement("form", { action: submitPath, method: 'post' },
                React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
                React.createElement("div", { className: 'cash-received-drawer' },
                    React.createElement("div", { className: 'actions' },
                        React.createElement("button", { className: 'back', onClick: () => setIsOpen(false) }, "Go Back")),
                    React.createElement("div", { className: 'header' }, "Add Notes"),
                    React.createElement("div", { className: 'form-group' },
                        React.createElement("label", { className: 'label', htmlFor: 'note' }, "Notes"),
                        React.createElement("textarea", { className: 'textarea form-control', name: 'uploaded_file_note[note]', id: 'note' })),
                    React.createElement("div", { className: 'actions' },
                        React.createElement("button", { className: 'button -cancel', onClick: () => setIsOpen(false) }, "Cancel"),
                        React.createElement("button", { className: 'button -apply' }, "Create")))))));
};
export default UploadedFileNotesDrawer;
