import React from 'react';
const ConnectPanel = ({ xeroPath, myobPath, providers }) => (React.createElement("div", { className: 'custom-panel', "data-testid": 'connect-panel' },
    React.createElement("div", { className: 'header' }, "Choose a platform"),
    React.createElement("div", { className: 'provider provider-pane -panel' },
        React.createElement("img", { className: 'image', src: providers.xero }),
        React.createElement("div", { className: 'detail' },
            React.createElement("div", { className: 'name' }, "Xero"),
            React.createElement("div", { className: 'link' }, "xero.com")),
        React.createElement("a", { className: 'button', href: xeroPath }, "Connect")),
    React.createElement("div", { className: 'provider provider-pane -panel' },
        React.createElement("img", { className: 'image', src: providers.myob }),
        React.createElement("div", { className: 'detail' },
            React.createElement("div", { className: 'name' }, "MYOB"),
            React.createElement("div", { className: 'link' }, "www.myob.com")),
        React.createElement("a", { className: 'button', href: myobPath }, "Connect"))));
export default ConnectPanel;
