import classNames from 'classnames';
import React from 'react';
const IconToggle = ({ isOn, offIcon, onIcon, children, isToggleable = true, switchOff, switchOn }) => {
    const iconToggleClass = classNames({
        'icon-toggle': true,
        '-on': isOn,
        '-off': !isOn,
        [`-${onIcon}`]: isOn,
        [`-${offIcon}`]: !isOn
    });
    const iconClass = classNames({
        icon: true,
        '-toggleable': isToggleable
    });
    const onClickHandler = (isOn ? switchOff : switchOn) || null;
    const testId = isOn ? 'icon-toggle:on' : 'icon-toggle:off';
    return (React.createElement("div", { className: iconToggleClass, "data-testid": testId },
        React.createElement("i", { className: iconClass, onClick: () => isToggleable && onClickHandler && onClickHandler(), "data-testid": 'icon' }),
        children));
};
export default IconToggle;
