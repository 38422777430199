import React, { useEffect, useState } from 'react';
import { fetchAttachments } from '../../api/release_note';
import AttachmentGallery from '../attachment-gallery/AttachmentGallery';
import LoadingSpinner from '../shared/LoadingSpinner';
const AttachmentsPanel = ({ apiToken, releaseNoteId }) => {
    const [attachments, setAttachments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    useEffect(() => {
        let isSubscribed = true;
        const getAttachments = async () => {
            setIsLoading(true);
            try {
                const response = await fetchAttachments(apiToken, releaseNoteId);
                if (!response.data) {
                    throw new Error('Invalid data');
                }
                if (isSubscribed) {
                    setAttachments(response.data.map(item => item.attributes));
                    setIsLoading(false);
                }
            }
            catch (err) {
                if (isSubscribed) {
                    setHasError(true);
                    setIsLoading(false);
                }
            }
        };
        if (isSubscribed) {
            getAttachments();
        }
        return () => {
            isSubscribed = false;
        };
    }, []);
    if (isLoading) {
        return (React.createElement("div", { className: 'attachments-panel attachments -release-notes', "data-testid": 'loading' },
            React.createElement(LoadingSpinner, { className: 'loading -release-notes' })));
    }
    if (hasError) {
        return (React.createElement("div", { className: 'attachments-panel attachments -release-notes', "data-testid": 'error' },
            React.createElement("div", { className: 'placeholder -error' }, "Failed to fetch attachments. Please refresh the page and try again.")));
    }
    return (React.createElement("div", { className: 'attachments-panel attachments -release-notes', "data-testid": 'loaded' },
        React.createElement(AttachmentGallery, { attachments: attachments, columns: 5, collapseItems: true })));
};
export default AttachmentsPanel;
