import classNames from 'classnames';
import React from 'react';
const InvoiceStatus = ({ daysDue, dueDate, isPaid, displayPaidStatus, paidOnDate }) => {
    const hasPaidStatus = isPaid && displayPaidStatus;
    const invoiceStatusClassName = classNames('status-box', {
        '-high': daysDue <= -61 && !hasPaidStatus,
        '-medium': daysDue <= -31 && daysDue >= -60 && !hasPaidStatus,
        '-low': daysDue <= -1 && daysDue >= -30 && !hasPaidStatus,
        '-safe': hasPaidStatus
    });
    const pluralize = Math.abs(daysDue) === 1 ? 'Day' : 'Days';
    const dueDateText = daysDue === 0
        ? 'Today'
        : daysDue >= 1
            ? dueDate
            : daysDue <= -1 && `${Math.abs(daysDue)} ${pluralize} Overdue`;
    const getInvoiceStatus = () => {
        if (hasPaidStatus && paidOnDate) {
            return `Paid on ${paidOnDate}`;
        }
        if (hasPaidStatus) {
            return 'Paid';
        }
        return dueDateText;
    };
    return (React.createElement("span", { className: invoiceStatusClassName, "data-testid": 'status' }, getInvoiceStatus()));
};
export default InvoiceStatus;
