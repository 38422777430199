import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Modal from '../../shared/Modal';
const ApproveInvoice = ({ selectedInvoice, handleSubmitSingleApprove, isModalOpenForApprove, closeModalForApprove, disapprovalReasonsOptions }) => {
    const [hasAllowedForStatusToggle, sethasAllowedForStatusToggle] = useState(false);
    const panelClass = classNames('panel', {
        '-active': isModalOpenForApprove
    });
    const checkAllowedForStatusToggle = () => {
        if (selectedInvoice && !selectedInvoice.allowedForApprovalStatusToggle) {
            sethasAllowedForStatusToggle(true);
        }
        else {
            sethasAllowedForStatusToggle(false);
        }
    };
    const submitClass = classNames('button -primary', {
        '-disabled': hasAllowedForStatusToggle
    });
    const handleSubmit = event => {
        event.preventDefault();
        handleSubmitSingleApprove();
    };
    useEffect(() => {
        checkAllowedForStatusToggle();
    }, [selectedInvoice]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { isOpen: isModalOpenForApprove, onClose: closeModalForApprove },
            React.createElement("form", { method: 'post', onSubmit: handleSubmit, className: panelClass, "data-testid": 'modal' },
                React.createElement("div", { className: 'modal' },
                    React.createElement("div", { className: 'custom-panel -modal' },
                        React.createElement("div", { className: 'header' }, "Confirmation"),
                        React.createElement("div", { className: 'content custom-scrollbar' },
                            React.createElement("div", { className: 'section -list' },
                                React.createElement("p", null,
                                    "Are you sure you want to approve invoice\u00A0",
                                    React.createElement("b", null,
                                        "#",
                                        selectedInvoice && selectedInvoice.id,
                                        " -",
                                        ' ',
                                        selectedInvoice && selectedInvoice.customerName),
                                    "\u00A0which was previously disapproved?"),
                                React.createElement("br", null),
                                React.createElement("br", null),
                                React.createElement("div", { className: 'disapproval-reasons' },
                                    selectedInvoice &&
                                        selectedInvoice.disapprovalReasons.length > 0 && (React.createElement("span", { className: 'title' }, "Previous Disapproval Reason")),
                                    React.createElement("ul", { className: 'reasons' },
                                        selectedInvoice &&
                                            disapprovalReasonsOptions.map((item, index) => {
                                                return (selectedInvoice.disapprovalReasons.includes(item.value) && (React.createElement("li", { key: `${selectedInvoice &&
                                                        selectedInvoice.id}-reason-${index}`, className: 'reason -center' }, item.label)));
                                            }),
                                        React.createElement("div", { className: 'footnote' }, selectedInvoice &&
                                            !selectedInvoice.allowedForApprovalStatusToggle && (React.createElement("span", null, "This invoice is not eligible to approve.")))))),
                            React.createElement("div", { className: 'footer -borderless' },
                                React.createElement("div", { className: 'action-buttons -space-between' },
                                    React.createElement("button", { className: 'button -danger', onClick: () => closeModalForApprove(), type: 'button' }, "Cancel"),
                                    React.createElement("button", { className: submitClass, type: 'button', onClick: handleSubmit }, "Yes, I am sure"))))),
                    React.createElement("div", { className: 'modal-overlay' }))))));
};
export default ApproveInvoice;
