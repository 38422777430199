import React, { useState } from 'react';
const InvoiceTodoTooltip = ({ content, children }) => {
    let timeout;
    const [active, setActive] = useState(false);
    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true);
        }, 200);
    };
    const hideTip = () => {
        clearInterval(timeout);
        setActive(false);
    };
    return (React.createElement("div", { className: 'tooltip-wrapper', onMouseEnter: showTip, onMouseLeave: hideTip },
        children,
        active && (React.createElement("div", { className: 'tooltip-tip right' }, content.map((element, index) => (React.createElement("span", { className: 'tooltip-content', key: index }, element['text'])))))));
};
export default InvoiceTodoTooltip;
