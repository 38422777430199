import React, { useState } from 'react';
import Modal from '../shared/Modal';
import InvoicePdfsTable from './InvoicePdfsTable';
const InvoicePdfsModal = ({ invoicePdfs, url, latestInvoicePdfId }) => {
    const [isVisible, setIsVisible] = useState(false);
    const toggleModal = () => {
        isVisible ? setIsVisible(false) : setIsVisible(true);
    };
    if (invoicePdfs.length === 0)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement("a", { onClick: toggleModal, className: 'icon-button button -text-primary -rectangular -border-brand-a-400', "data-testid": 'invoice-pdfs-modal-button' }, "Download PDF"),
        React.createElement(Modal, { isOpen: isVisible, onClose: toggleModal, contentSize: 'md', dataTestId: 'invoice-pdfs-modal' },
            React.createElement("div", { className: 'custom-panel -shadow-none' },
                React.createElement("div", { className: 'header -space-between' },
                    "Invoice PDF Version History",
                    React.createElement("div", { className: 'icon-button -close -modal', onClick: toggleModal },
                        React.createElement("div", { className: 'icon' }))),
                React.createElement("div", { className: 'content -adjust-height custom-scrollbar' },
                    React.createElement(InvoicePdfsTable, { ...{ invoicePdfs, url, latestInvoicePdfId } }))))));
};
export default InvoicePdfsModal;
