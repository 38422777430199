import React from 'react';
const TradeLoanPanel = ({ tradeLoanBalance }) => {
    return (React.createElement("div", { className: 'custom-panel' },
        React.createElement("div", { className: 'header' }, "Trade Loan Funds"),
        React.createElement("div", { className: 'icon-figure' },
            React.createElement("div", { className: 'icon -funds' }),
            React.createElement("div", { className: 'figure' },
                React.createElement("div", { className: 'label' }, "Total outstanding balance"),
                React.createElement("div", { className: 'data' },
                    React.createElement("span", null, tradeLoanBalance))))));
};
export default TradeLoanPanel;
