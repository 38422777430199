import { titleize } from './titleize';
export const phoneType = phoneType => {
    const types = ['DEFAULT', 'phone1', 'phone2', 'phone3'];
    if (types.includes(phoneType)) {
        return 'Phone';
    }
    else if ('DDI' === phoneType) {
        return 'DDI';
    }
    else {
        return titleize(phoneType);
    }
};
export const completePhoneNumber = (phoneNumber) => {
    return [phoneNumber.areaCode, phoneNumber.number].filter(Boolean).join(' ');
};
export const addressType = addressType => {
    const types = ['address1', 'address2', 'address3'];
    if (types.includes(addressType)) {
        return 'Address';
    }
    else if (addressType === 'POBOX') {
        return 'Postal Address';
    }
    else if (addressType === 'STREET') {
        return `${titleize(addressType)} Address`;
    }
};
export const completeAddress = (address) => {
    const addressFields = [
        address.attentionTo,
        address.lineOne,
        address.lineTwo,
        address.city,
        address.region,
        address.postCode
    ];
    return addressFields.filter(Boolean).join(', ');
};
