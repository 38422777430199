import classNames from 'classnames';
import React from 'react';
import { humanizeString } from '../../utils';
const UserRole = ({ role }) => {
    const isSuperAdmin = role === 'super_admin';
    const isRiskTeam = role === 'risk_team';
    const isRelationshipManager = role === 'relationship_manager_1' || role === 'relationship_manager_2';
    const isCollectionsManager = role === 'collections_manager';
    const isBDM = role === 'business_development_manager';
    const isPrimaryClientManager = role === 'primary';
    const roleClass = classNames('user-role role', {
        '-super-admin': isSuperAdmin,
        '-risk-team': isRiskTeam,
        '-relationship-manager': isRelationshipManager,
        '-collections-manager': isCollectionsManager,
        '-bdm': isBDM,
        '-primary-client-manager': isPrimaryClientManager
    });
    if (role) {
        return (React.createElement("div", { className: roleClass, "data-testid": 'user-role' }, humanizeString(role)));
    }
    return null;
};
export default UserRole;
