export const findOptionByLabel = (options, label) => {
    return (options.find((option) => option.label === label) || null);
};
export const findOptionByValue = (options, value) => {
    return (options.find((option) => option.value === value) || null);
};
export const hasValue = (option, value) => {
    if (option) {
        return option.value === value;
    }
    else {
        return false;
    }
};
export const hasLabel = (option, label) => {
    if (option) {
        return option.label === label;
    }
    else {
        return false;
    }
};
