import React, { Fragment } from 'react';
import SpinnerButton from '../shared/SpinnerButton';
const ActionButtons = ({ currentStep, isSubmitting, stepInfo, showActions = true, saveProgress, clearForm }) => {
    const { nextButtonText, nextButtonHandler, previousButtonText, previousButtonHandler } = stepInfo;
    return (React.createElement(Fragment, null, showActions && (React.createElement("div", { className: 'actions', "data-testid": 'actions' },
        currentStep === 1 ? (React.createElement(SpinnerButton, { text: 'Clear', className: 'custom-button button -trade-loan-clear-form', isDisabled: isSubmitting, isLoading: false, buttonType: 'button', handleClick: clearForm, testId: `step-${currentStep}-clear` })) : (''),
        React.createElement(SpinnerButton, { text: 'Save Progress', className: 'custom-button button -trade-loan-save-progress', isDisabled: isSubmitting, isLoading: false, buttonType: 'button', handleClick: saveProgress, testId: `step-${currentStep}-progress` }),
        previousButtonHandler && previousButtonText && (React.createElement(SpinnerButton, { text: previousButtonText || '', className: 'custom-button button -trade-loan-prev', isDisabled: isSubmitting, isLoading: false, buttonType: 'button', handleClick: () => previousButtonHandler(), testId: `step-${currentStep}-previous` })),
        nextButtonHandler && nextButtonText && (React.createElement(SpinnerButton, { text: nextButtonText || '', className: 'custom-button button -trade-loan-next', isDisabled: isSubmitting, isLoading: isSubmitting, buttonType: 'button', handleClick: () => nextButtonHandler(), testId: `step-${currentStep}-next` }))))));
};
export default ActionButtons;
