import React from 'react';
import ToggleSwitch from '../shared/ToggleSwitch';
const SettingControls = ({ setting, index, enableNotificationHandler, disableNotificationHandler }) => {
    return (React.createElement("div", { className: 'custom-panel -collapsible -no-margin', "data-testid": 'admin-setting' },
        React.createElement("input", { className: 'toggle', id: `notification-setting-${index}-checkbox`, "data-testid": `notification-setting-${index}-checkbox`, type: 'checkbox', defaultChecked: false }),
        React.createElement("label", { className: 'header -space-between -subheader -border', htmlFor: `notification-setting-${index}-checkbox` }, setting.adminFullname),
        React.createElement("div", { className: 'body' },
            React.createElement("div", { className: 'header -group' }, "General Notification"),
            React.createElement("table", { className: 'custom-table', "data-testid": 'notification-settings' },
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("td", { className: 'vertical -notification' }, "Comments on Activity"),
                        React.createElement("td", { className: 'vertical -right' },
                            React.createElement(ToggleSwitch, { isToggled: setting.activityComment, switchOn: () => enableNotificationHandler(setting.id, 'activity_comment'), switchOff: () => disableNotificationHandler(setting.id, 'activity_comment'), testId: 'toggle-activity-comment' }))),
                    React.createElement("tr", null,
                        React.createElement("td", { className: 'vertical -notification' }, "Comments on Customer"),
                        React.createElement("td", { className: 'vertical -right' },
                            React.createElement(ToggleSwitch, { isToggled: setting.customerComment, switchOn: () => enableNotificationHandler(setting.id, 'customer_comment'), switchOff: () => disableNotificationHandler(setting.id, 'customer_comment'), testId: 'toggle-customer-comment' }))),
                    React.createElement("tr", null,
                        React.createElement("td", { className: 'vertical -notification' }, "Comments on Invoice"),
                        React.createElement("td", { className: 'vertical -right' },
                            React.createElement(ToggleSwitch, { isToggled: setting.invoiceComment, switchOn: () => enableNotificationHandler(setting.id, 'invoice_comment'), switchOff: () => disableNotificationHandler(setting.id, 'invoice_comment'), testId: 'toggle-invoice-comment' }))),
                    React.createElement("tr", null,
                        React.createElement("td", { className: 'vertical -notification' }, "Comments on Drawdown"),
                        React.createElement("td", { className: 'vertical -right' },
                            React.createElement(ToggleSwitch, { isToggled: setting.drawdownComment, switchOn: () => enableNotificationHandler(setting.id, 'drawdown_comment'), switchOff: () => disableNotificationHandler(setting.id, 'drawdown_comment'), testId: 'toggle-drawdown-comment' }))),
                    React.createElement("tr", null,
                        React.createElement("td", { className: 'vertical -notification' }, "Added a post"),
                        React.createElement("td", { className: 'vertical -right' },
                            React.createElement(ToggleSwitch, { isToggled: setting.newPost, switchOn: () => enableNotificationHandler(setting.id, 'new_post'), switchOff: () => disableNotificationHandler(setting.id, 'new_post'), testId: 'toggle-add-post' }))),
                    React.createElement("tr", null,
                        React.createElement("td", { className: 'vertical -notification' }, "Added a global post"),
                        React.createElement("td", { className: 'vertical -right' },
                            React.createElement(ToggleSwitch, { isToggled: setting.newGlobalPost, switchOn: () => enableNotificationHandler(setting.id, 'new_global_post'), switchOff: () => disableNotificationHandler(setting.id, 'new_global_post'), testId: 'toggle-add-global-post' })))))),
        React.createElement("div", { className: 'body' },
            React.createElement("div", { className: 'header -group -invoice' }, "Invoice Notifications"),
            React.createElement("table", { className: 'custom-table', "data-testid": 'notification-settings' },
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("td", { className: 'vertical -notification' }, "Amended Invoices"),
                        React.createElement("td", { className: 'vertical -right' },
                            React.createElement(ToggleSwitch, { isToggled: setting.amendedInvoice, switchOn: () => enableNotificationHandler(setting.id, 'amended_invoice'), switchOff: () => disableNotificationHandler(setting.id, 'amended_invoice'), testId: 'toggle-amended-invoice-email' }))),
                    React.createElement("tr", null,
                        React.createElement("td", { className: 'vertical -notification' }, "Disapproved invoices if partial payment received"),
                        React.createElement("td", { className: 'vertical -right' },
                            React.createElement(ToggleSwitch, { isToggled: setting.disapprovedPartPaidInvoice, switchOn: () => enableNotificationHandler(setting.id, 'disapproved_part_paid_invoice'), switchOff: () => disableNotificationHandler(setting.id, 'disapproved_part_paid_invoice'), testId: 'toggle-add-global-post' }))))))));
};
export default SettingControls;
