import React, { useState } from 'react';
import classNames from 'classnames';
import Panel from './Panel';
import { archiveTradeLoanUnassignedTransactions } from '../../../api/trade_loan_unassigned_transaction';
import { showNotifyToast } from '../../../utils/notifyToast';
const DeleteTransaction = ({ path, selectedIdString, csrfToken, apiToken, unassignedTransactions, selectedTransactionIds, setTradeLoanUnassignedTransactions, setSelectedTransactionIds, disable }) => {
    const [isOpened, setIsOpened] = useState(false);
    const togglePanel = () => setIsOpened(!isOpened);
    const buttonClass = classNames('icon-button button', '-rectangular', {
        '-default-cursor -disabled': disable,
        '-red -reject': !disable
    });
    const textClass = classNames('text', {
        '-neutral-100-text': disable
    });
    const [comment, setComment] = useState('');
    const errorMessage = 'Something went wrong. Please try again.';
    const doArchiveTradeLoanUnassignedTransactions = async () => {
        try {
            await archiveTradeLoanUnassignedTransactions(apiToken, comment, selectedIdString);
            const updateTradeLoanUnassignedTransactions = unassignedTransactions.filter((unassignedTransaction) => {
                return !selectedTransactionIds.includes(Number(unassignedTransaction.id));
            });
            setTradeLoanUnassignedTransactions(updateTradeLoanUnassignedTransactions);
            setSelectedTransactionIds([]);
            showNotifyToast({
                text: 'Successfully deleted unassigned transaction.',
                type: 'success'
            });
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        location.reload();
    };
    return (React.createElement("div", { className: 'toggle-panel -filter-dropdown -assign-borrower', "data-testid": 'delete-transaction' },
        React.createElement("button", { className: buttonClass, onClick: togglePanel, disabled: disable },
            React.createElement("div", { className: textClass }, "Delete Transaction")),
        !disable && (React.createElement(Panel, { ...{
                isOpened,
                selectedIdString,
                csrfToken,
                comment,
                setComment,
                doArchiveTradeLoanUnassignedTransactions,
                path
            } }))));
};
export default DeleteTransaction;
