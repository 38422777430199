import { useEffect } from 'react';
import { showNotifyToast } from '../../utils';
// this component is mainl used by Rails for displaying flash messages
const NotificationToast = ({ text, type }) => {
    useEffect(() => {
        // make devise flash keys work with preset toast types
        if (type === 'notice') {
            type = 'info';
        }
        if (type === 'alert') {
            type = 'warning';
        }
        if (type === 'information') {
            type = 'info';
        }
        showNotifyToast({
            text,
            type
        });
    }, []);
    return null;
};
export default NotificationToast;
