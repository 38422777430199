import classNames from 'classnames';
import React from 'react';
import ScrollLock from 'react-scrolllock';
import ToggleMenuWrapper from '../toggle-menu/ToggleMenuWrapper';
const Actions = ({ isArchived, isMenuActive, isResolved, isSaving, handleArchive, handleEdit, handleResolve, setActionsVisible, toggleMenu }) => {
    const actionsClass = classNames('risk-activity-actions actions', {
        '-active': isMenuActive
    });
    const resolveButtonClass = classNames('button -resolve', {
        '-disabled': isSaving
    });
    const editButtonClass = classNames('button -edit-risk-activity', {
        '-disabled': isSaving
    });
    const archiveButtonClass = classNames('button -delete', {
        '-disabled': isSaving
    });
    const handleBackgroundClick = () => {
        toggleMenu();
        setActionsVisible(false);
    };
    if (isArchived) {
        return null;
    }
    return (React.createElement(ScrollLock, { isActive: isMenuActive },
        React.createElement("div", { className: actionsClass, "data-testid": 'risk-activity-actions' },
            React.createElement(ToggleMenuWrapper, { isActive: isMenuActive, handleToggle: () => toggleMenu() },
                !isResolved && (React.createElement("button", { onClick: handleResolve, className: resolveButtonClass, "data-testid": 'resolve-button', disabled: isSaving }, "Resolve")),
                React.createElement("button", { onClick: handleEdit, className: editButtonClass, "data-testid": 'edit-button', disabled: isSaving }, "Edit"),
                React.createElement("button", { onClick: handleArchive, className: archiveButtonClass, "data-testid": 'archive-button', disabled: isSaving }, "Archive")),
            isMenuActive && (React.createElement("div", { className: 'background', onClick: handleBackgroundClick, "data-testid": 'background' })))));
};
export default Actions;
