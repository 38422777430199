import { fileNameFromUrl, replaceSpaces, snakeToCamelCase } from '../utils';
const fileFieldNames = ['invoiceCopy'];
const existingFileFieldNames = fileFieldNames.map(field => {
    return snakeToCamelCase(`existing_${field}`);
});
export const compileFileNames = (excludedFieldNames, invoice) => {
    return fileFieldNames.reduce((list, key) => {
        if (invoice[key] && !excludedFieldNames.includes(key)) {
            const fileNames = invoice[key].map(file => {
                return replaceSpaces(file.name, '_');
            });
            return [...list, ...fileNames];
        }
        else {
            return [...list];
        }
    }, []);
};
export const compileExistingFileNames = (invoice) => {
    return existingFileFieldNames.reduce((list, key) => {
        if (invoice[key]) {
            const fileNames = invoice[key].map(file => {
                return fileNameFromUrl(file.url);
            });
            return [...list, ...fileNames];
        }
        else {
            return [...list];
        }
    }, []);
};
