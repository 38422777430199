import fetch from 'isomorphic-fetch';
export const processReypayments = async (apiToken, csrfToken, ids) => {
    const headers = {
        Authorization: apiToken,
        'content-type': 'application/json',
        'X-CSRF-Token': csrfToken + ''
    };
    return fetch('/api/admin/repayments/batch_process', {
        headers,
        method: 'POST',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({ process: ids })
    }).then(response => response.json());
};
