import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Modal from '../../shared/Modal';
import SelectedInvoice from './SelectedInvoice';
const ApproveBulkInvoices = ({ csrfToken, selectedIdString, selectedInvoices, handleBulkApproveInvoices, isModalOpenForApprove, openModalForApprove, closeModalForApprove, handleInvoiceId, disapprovalReasonsOptions }) => {
    const [hasUnapprove, setHasUnapprove] = useState(false);
    const panelClass = classNames('panel', {
        '-active': isModalOpenForApprove
    });
    const checkUnapprove = () => {
        const uneligible = selectedInvoices.filter(invoice => invoice.allowedForApprovalStatusToggle === false);
        if (uneligible.length > 0) {
            setHasUnapprove(true);
        }
        else {
            setHasUnapprove(false);
        }
    };
    const submitClass = classNames('button -primary', {
        '-disabled': hasUnapprove
    });
    const handleSubmit = event => {
        event.preventDefault();
        handleBulkApproveInvoices();
    };
    useEffect(() => {
        checkUnapprove();
    }, [selectedInvoices]);
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { className: 'button -dropdown', "data-testid": 'approve-bulk-invoices', onClick: () => openModalForApprove(), type: 'button' }, "Mark as approved"),
        React.createElement(Modal, { isOpen: isModalOpenForApprove, onClose: closeModalForApprove },
            React.createElement("form", { method: 'post', onSubmit: handleSubmit, className: panelClass, "data-testid": 'modal' },
                React.createElement("div", { className: 'modal' },
                    React.createElement("div", { className: 'custom-panel -modal' },
                        React.createElement("div", { className: 'header' }, "Confirmation"),
                        React.createElement("div", { className: 'content custom-scrollbar -adjust-height' },
                            React.createElement("div", { className: 'section -disapproval-reason' },
                                React.createElement("p", null, "This is a summary of invoices that will be approved. Are you sure you want to do it? There are some invoices that were previously disapproved."),
                                React.createElement("input", { type: 'hidden', name: 'invoices_ids', value: selectedIdString }),
                                React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
                                React.createElement("div", { className: 'container' }, selectedInvoices.map((invoice, index) => (React.createElement(SelectedInvoice, { ...{
                                        invoice,
                                        disapprovalReasonsOptions,
                                        handleInvoiceId,
                                        index
                                    }, key: index }))))),
                            React.createElement("div", { className: 'footer -borderless -no-padding' },
                                React.createElement("div", { className: 'action-buttons -flex-end' },
                                    React.createElement("button", { className: 'button -danger', onClick: () => closeModalForApprove(), type: 'button' }, "Cancel"),
                                    React.createElement("button", { className: submitClass, type: 'button', onClick: handleSubmit }, "Yes, I am sure"))))),
                    React.createElement("div", { className: 'modal-overlay' }))))));
};
export default ApproveBulkInvoices;
