import fetch from 'isomorphic-fetch';
import { buildHeaders } from './shared';
import queryString from 'query-string';
export const setVerified = async (apiToken, borrowerId, invoiceId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/invoices/${invoiceId}/set_verified`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const setUnverified = async (apiToken, borrowerId, invoiceId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/invoices/${invoiceId}/set_unverified`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT'
    }).then((res) => res.json());
};
export const approve = async (apiToken, borrowerId, invoiceId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/invoices/${invoiceId}/approve`;
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'PUT'
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.map(error => error.title).join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
export const unapprove = async (apiToken, borrowerId, invoiceId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/invoices/${invoiceId}/unapprove`;
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'PUT'
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.map(error => error.title).join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
export const authorize = async (apiToken, borrowerId, invoiceId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/invoices/${invoiceId}/mark_as_authorized`;
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'PUT'
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.map(error => error.title).join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
export const fetchInvoiceFromXero = async (apiToken, borrowerId, invoiceId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/invoices/${invoiceId}/fetch_invoice_from_xero`;
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'GET'
        });
        if (!response.ok) {
            const { error } = await response.json();
            throw new Error(error);
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
export const updateDisapproval = async (apiToken, borrowerId, invoiceId, body) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/invoices/${invoiceId}/update_disapproval_reasons`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(body)
    }).then((res) => res.json());
};
export const fetchInvoices = async (apiToken, borrowerId, page, sortBy, sortDirection, filter, searchQuery, customerId) => {
    const params = {
        page,
        ...(searchQuery && { search_query: searchQuery }),
        ...(searchQuery && customerId && { search_customer_invoice: true }),
        ...(sortBy && { sort_by: sortBy }),
        ...(sortDirection && { sort_direction: sortDirection }),
        ...(customerId && { customer_id: customerId }),
        ...(filter && { filter })
    };
    const search = queryString.stringify(params);
    const endpoint = `/api/admin/borrowers/${borrowerId}/invoices?${search}`;
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'GET'
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.map(error => error.title).join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
export const approveMultipleInvoices = async (apiToken, borrowerId, invoiceIds) => {
    const params = {
        invoice_ids: invoiceIds.join()
    };
    const endpoint = `/api/admin/borrowers/${borrowerId}/invoices/approve_multiple_invoices?${queryString.stringify(params)}`;
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'PUT'
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
export const unapproveMultipleInvoices = async (apiToken, borrowerId, invoiceIds) => {
    const params = {
        invoice_ids: invoiceIds.join()
    };
    const endpoint = `/api/admin/borrowers/${borrowerId}/invoices/unapprove_multiple_invoices?${queryString.stringify(params)}`;
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'PUT'
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
export const verifyMultipleInvoices = async (apiToken, borrowerId, invoiceIds) => {
    const params = {
        invoice_ids: invoiceIds.join()
    };
    const endpoint = `/api/admin/borrowers/${borrowerId}/invoices/verify_multiple_invoices?${queryString.stringify(params)}`;
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'PUT'
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
export const unverifyMultipleInvoices = async (apiToken, borrowerId, invoiceIds) => {
    const params = {
        invoice_ids: invoiceIds.join()
    };
    const endpoint = `/api/admin/borrowers/${borrowerId}/invoices/unverify_multiple_invoices?${queryString.stringify(params)}`;
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'PUT'
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
