import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import ErrorList from '../shared/ErrorList';
import SpinnerButton from '../shared/SpinnerButton';
import { updateReserveAmount } from '../../api/client_setup';
import { isAPIValidationErrors, isAPIErrors } from '../../../types';
import { convertCentsString } from '../../utils/convertCents';
import { showNotifyToast } from '../../utils';
const ReserveAmountForm = ({ authToken, companyId, client, isReadOnly = false, incrementSaves }) => {
    const [clientInfo, setClientInfo] = useState(client);
    const [isLoading, setIsLoading] = useState(false);
    const [currentNote, setCurrentNote] = useState('');
    const [validationErrors, setValidationErrors] = useState({});
    const { requiredReserveCents } = clientInfo;
    const [currentReserveAmount, setCurrentReserveAmount] = useState({
        floatValue: requiredReserveCents / 100,
        value: convertCentsString(requiredReserveCents),
        formattedValue: ''
    });
    const handleReserveAmountChange = (values) => {
        setCurrentReserveAmount(values);
    };
    const handleNoteChange = (event) => {
        setCurrentNote(event.currentTarget.value);
    };
    const handleSave = async () => {
        const body = {
            company: {
                requiredReserveCents: currentReserveAmount.floatValue * 100 || 0
            },
            note: currentNote
        };
        setIsLoading(true);
        try {
            const response = await updateReserveAmount(authToken, companyId, body);
            if (isAPIValidationErrors(response)) {
                throw response;
            }
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            const updatedClientInfo = response.data.attributes;
            setClientInfo(updatedClientInfo);
            setCurrentReserveAmount({
                floatValue: updatedClientInfo.requiredReserveCents / 100,
                value: convertCentsString(updatedClientInfo.requiredReserveCents),
                formattedValue: ''
            });
            setValidationErrors({});
            setCurrentNote('');
            if (incrementSaves) {
                incrementSaves();
            }
            showNotifyToast({
                text: 'Successfully updated Reserve Amount.',
                type: 'success'
            });
        }
        catch (err) {
            if (isAPIValidationErrors(err)) {
                setValidationErrors(err.errors);
            }
            showNotifyToast({
                text: 'Failed to update Reserve Amount.',
                type: 'error'
            });
        }
        setIsLoading(false);
    };
    return (React.createElement("div", { className: 'client-setup-form form -reserve-amount', "data-testid": 'reserve-amount-form' },
        React.createElement("div", { className: 'form-group group' },
            React.createElement("label", { className: 'label -uppercase' }, "Set a reserve amount to be deducted from the client's availability"),
            React.createElement(NumberFormat, { className: 'input form-control', thousandSeparator: true, prefix: '$', decimalScale: 2, allowNegative: false, value: currentReserveAmount.value, onValueChange: handleReserveAmountChange, disabled: isReadOnly, "data-testid": 'reserve-amount-input' }),
            React.createElement(ErrorList, { errors: validationErrors, field: 'required_reserve_cents' })),
        React.createElement("div", { className: 'form-group group' },
            React.createElement("label", { htmlFor: 'reserve-amount-note', className: 'label -uppercase' }, "Note"),
            React.createElement("input", { id: 'reserve-amount-note', type: 'text', className: 'input form-control', value: currentNote || '', onChange: handleNoteChange, disabled: isReadOnly })),
        React.createElement("div", { className: 'action-buttons actions' },
            React.createElement(SpinnerButton, { text: 'Save', buttonType: 'button', isLoading: isLoading, handleClick: handleSave, className: 'button -primary', isDisabled: isReadOnly, testId: 'save' }))));
};
export default ReserveAmountForm;
