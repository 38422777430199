import React, { useState } from 'react';
import Modal from '../../components/shared/Modal';
import NumberFormat from 'react-number-format';
import { convertCentsString, convertCents } from '../../utils/convertCents';
import ErrorList from '../shared/ErrorList';
const AvailabilityAdjustmentModal = ({ path, modelName, csrfToken, errors }) => {
    const initialAvailabilityAdjustment = {
        formattedValue: '',
        value: convertCentsString(0),
        floatValue: convertCents(0)
    };
    const hasErrors = Object.keys(errors).length > 0;
    const [isVisible, setIsVisible] = useState(hasErrors);
    const toggleModal = () => {
        isVisible ? setIsVisible(false) : setIsVisible(true);
    };
    const [availabilityAdjustment, setAvailabilityAdjustment] = useState(initialAvailabilityAdjustment);
    const handleAvailabilityAdjustmentChange = (values) => {
        setAvailabilityAdjustment(values);
    };
    return (React.createElement("div", { className: 'action-buttons button' },
        React.createElement("div", { className: 'button -primary', onClick: toggleModal }, "+ Add Adjustments"),
        React.createElement(Modal, { isOpen: isVisible, onClose: toggleModal },
            React.createElement("form", { acceptCharset: 'UTF-8', method: 'post', action: path, className: 'form' },
                React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
                React.createElement("div", { className: 'custom-panel -modal', role: 'dialog', "aria-modal": 'true', "data-testid": 'customer-modal' },
                    React.createElement("div", { className: 'header' }, "Create Adjustment"),
                    React.createElement("div", { className: 'content' },
                        React.createElement("div", { className: 'form-group group' },
                            React.createElement("label", { className: 'label -uppercase' }, "Set a reserve amount to be deducted from the client's availability"),
                            React.createElement(NumberFormat, { className: 'input form-control', thousandSeparator: true, prefix: '$', decimalScale: 2, allowNegative: false, value: availabilityAdjustment.value, name: `${modelName}[amount]`, onValueChange: handleAvailabilityAdjustmentChange, "data-testid": 'availability-adjustment-input' }),
                            React.createElement(ErrorList, { errors: errors, field: 'amount_cents' })),
                        React.createElement("div", { className: 'form-group group' },
                            React.createElement("label", { htmlFor: 'reserve-amount-note', className: 'label -uppercase' }, "Note"),
                            React.createElement("textarea", { className: 'input form-control -textarea', name: `${modelName}[notes]`, defaultValue: '' }))),
                    React.createElement("div", { className: 'action-buttons buttons space -border -padding' },
                        React.createElement("button", { className: 'button -neutral', type: 'button', onClick: toggleModal }, "Cancel"),
                        React.createElement("button", { className: 'button -primary', type: 'submit' }, "Create")))))));
};
export default AvailabilityAdjustmentModal;
