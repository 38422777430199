import React from 'react';
import CustomFlatpickr from '../shared/CustomFlatpickr';
const BrokerFilters = ({ activeFilters, dateFilter, flatpickrSettings, forwardDateClearRef, forwardDatePickerRef, applyFilters, clearFilters, handleFilterToggle }) => {
    return (React.createElement("div", { className: 'activity-filter filter -broker', "data-testid": 'broker-feed-filters' },
        React.createElement("div", { className: 'title -main' }, "Filter by"),
        React.createElement("div", { className: 'title' }, "Date"),
        React.createElement("div", { className: 'body' },
            React.createElement(CustomFlatpickr, { ...{ flatpickrSettings, forwardDateClearRef, forwardDatePickerRef }, selectedDate: dateFilter, placeholderText: 'Select a date', clearButtonText: 'Clear Date' })),
        React.createElement("div", { className: 'title' }, "Categories"),
        React.createElement("div", { className: 'body' },
            React.createElement("div", { className: 'category-list categories' },
                React.createElement("label", { className: 'category-option option', "data-testid": 'client-invitation-option' },
                    React.createElement("input", { type: 'checkbox', name: 'client_invitations', className: 'input', onChange: handleFilterToggle, checked: activeFilters.client_invitations }),
                    React.createElement("div", { className: 'body' },
                        React.createElement("div", { className: 'icon -client-invitation' }),
                        React.createElement("div", { className: 'text' }, "Client Invitation"),
                        React.createElement("div", { className: 'checkbox' }))),
                React.createElement("label", { className: 'category-option option', "data-testid": 'client-status-option' },
                    React.createElement("input", { type: 'checkbox', name: 'client_statuses', className: 'input', onChange: handleFilterToggle, checked: activeFilters.client_statuses }),
                    React.createElement("div", { className: 'body' },
                        React.createElement("div", { className: 'icon -client-status' }),
                        React.createElement("div", { className: 'text' }, "Client Status"),
                        React.createElement("div", { className: 'checkbox' }))),
                React.createElement("label", { className: 'category-option option', "data-testid": 'drawdown-option' },
                    React.createElement("input", { type: 'checkbox', name: 'drawdowns', className: 'input', onChange: handleFilterToggle, checked: activeFilters.drawdowns }),
                    React.createElement("div", { className: 'body' },
                        React.createElement("div", { className: 'icon -drawdown' }),
                        React.createElement("div", { className: 'text' }, "Drawdown"),
                        React.createElement("div", { className: 'checkbox' }))),
                React.createElement("label", { className: 'category-option option', "data-testid": 'commissions-update-option' },
                    React.createElement("input", { type: 'checkbox', name: 'commissions_updates', className: 'input', onChange: handleFilterToggle, checked: activeFilters.commissions_updates }),
                    React.createElement("div", { className: 'body' },
                        React.createElement("div", { className: 'icon -commissions-update' }),
                        React.createElement("div", { className: 'text' }, "Commissions Update"),
                        React.createElement("div", { className: 'checkbox' }))),
                React.createElement("label", { className: 'category-option option', "data-testid": 'post-activities-option' },
                    React.createElement("input", { type: 'checkbox', name: 'post_activities', className: 'input', onChange: handleFilterToggle, checked: activeFilters.post_activities }),
                    React.createElement("div", { className: 'body' },
                        React.createElement("div", { className: 'icon -post-activities' }),
                        React.createElement("div", { className: 'text' }, "Post Activities"),
                        React.createElement("div", { className: 'checkbox' }))))),
        React.createElement("div", { className: 'actions' },
            React.createElement("button", { className: 'custom-button button -clear-filters', onClick: () => clearFilters(), "data-testid": 'clear-all' }, "Clear All"),
            React.createElement("button", { className: 'custom-button button -apply-filters', onClick: () => applyFilters(), "data-testid": 'apply-filters' }, "Apply Filter"))));
};
export default BrokerFilters;
