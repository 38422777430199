import React from 'react';
import PaginationBar from '../shared/PaginationBar';
import SortableTableHeaders from '../table-headers/SortableTabs';
import DrawdownTableBody from './DrawdownTableBody';
const DrawdownTable = ({ tableName, sortDrawdown, drawdownArray, totalPages, pageSize, totalItems, isLoading, sortDirection, sortBy, currentPage, path, processedDrawdown, handlePageClick, fetchDrawdowns, showABA = true }) => {
    const headerFields = [
        { text: 'ID', field: 'id', defaultSort: 'desc' },
        { text: 'Company Name' },
        { text: 'Approved At' },
        { text: 'Amount' },
        { text: 'Fees' },
        { text: 'Net Amount' },
        { text: 'Payment Reference' },
        { text: 'Funder' },
        { text: 'Destination Account' },
        { text: 'Action' }
    ];
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'drawdown-table' },
        React.createElement("div", { className: 'header -space-between' }, tableName),
        React.createElement("table", { className: 'custom-table -fixed' },
            React.createElement(SortableTableHeaders, { ...{
                    headerFields,
                    isLoading,
                    sortBy,
                    sortDirection
                }, sortItems: sortDrawdown }),
            React.createElement(DrawdownTableBody, { ...{
                    drawdownArray,
                    isLoading,
                    path,
                    processedDrawdown,
                    fetchDrawdowns,
                    showABA
                } })),
        totalItems > pageSize && (React.createElement(PaginationBar, { ...{
                currentPage,
                totalPages,
                totalItems,
                pageSize,
                handlePageClick
            }, resource: tableName }))));
};
export default DrawdownTable;
