import React, { useState } from 'react';
import Select from 'react-select';
import ErrorList from '../shared/ErrorList';
import SpinnerButton from '../shared/SpinnerButton';
import { createIndustry, updateIndustry } from '../../api/client_setup';
import { isAPIValidationErrors, isAPIErrors } from '../../../types';
import { dropdownStyles } from '../../custom-styles/dropdown';
import { showNotifyToast } from '../../utils';
const IndustriesForm = ({ authToken, borrowerId, industry, industryNameOptions, isReadOnly = false, incrementSaves }) => {
    const initialIndustryName = () => {
        if (industry) {
            return (industryNameOptions.find((option) => option.value === industry.name) || null);
        }
        return null;
    };
    const [selectedName, setSelectedName] = useState(initialIndustryName());
    const [currentNote, setCurrentNote] = useState(industry ? industry.note : '');
    const [currentIndustry, setCurrentIndustry] = useState(industry);
    const [isLoading, setIsLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const handleNameChange = (selectedOption) => {
        setSelectedName(selectedOption);
    };
    const handleNoteChange = (event) => {
        setCurrentNote(event.currentTarget.value);
    };
    const handleCreate = async () => {
        const body = {
            industry: {
                name: selectedName ? selectedName.value : '',
                note: currentNote
            }
        };
        setIsLoading(true);
        try {
            const response = await createIndustry(authToken, borrowerId, body);
            if (isAPIValidationErrors(response)) {
                throw response;
            }
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            setCurrentIndustry(response.data.attributes);
            setValidationErrors({});
            if (incrementSaves) {
                incrementSaves();
            }
            showNotifyToast({
                text: 'Successfully created Industry.',
                type: 'success'
            });
        }
        catch (err) {
            if (isAPIValidationErrors(err)) {
                setValidationErrors(err.errors);
            }
            showNotifyToast({
                text: 'Failed to create Industry.',
                type: 'error'
            });
        }
        setIsLoading(false);
    };
    const handleUpdate = async () => {
        const body = {
            industry: {
                name: selectedName ? selectedName.value : '',
                note: currentNote
            }
        };
        setIsLoading(true);
        try {
            const industryId = currentIndustry ? currentIndustry.id : null;
            const response = await updateIndustry(authToken, borrowerId, industryId, body);
            if (isAPIValidationErrors(response)) {
                throw response;
            }
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            setCurrentIndustry(response.data.attributes);
            setValidationErrors({});
            if (incrementSaves) {
                incrementSaves();
            }
            showNotifyToast({
                text: 'Successfully updated Industry.',
                type: 'success'
            });
        }
        catch (err) {
            if (isAPIValidationErrors(err)) {
                setValidationErrors(err.errors);
            }
            showNotifyToast({
                text: 'Failed to update Industry.',
                type: 'error'
            });
        }
        setIsLoading(false);
    };
    const handleSave = async () => {
        if (currentIndustry) {
            handleUpdate();
        }
        else {
            handleCreate();
        }
    };
    return (React.createElement("div", { className: 'client-setup-form form -industries' },
        React.createElement("div", { className: 'form-group group' },
            React.createElement("label", { className: 'label -uppercase' }, "Name"),
            React.createElement(Select, { options: industryNameOptions, styles: dropdownStyles, placeholder: 'Please select an industry', value: selectedName, onChange: handleNameChange, isDisabled: isReadOnly }),
            React.createElement(ErrorList, { errors: validationErrors, field: 'name' })),
        React.createElement("div", { className: 'form-group group' },
            React.createElement("label", { htmlFor: 'industry-note', className: 'label -uppercase' }, "Note"),
            React.createElement("input", { id: 'industry-note', type: 'text', className: 'input form-control', value: currentNote || '', onChange: handleNoteChange, disabled: isReadOnly })),
        React.createElement("div", { className: 'action-buttons actions' },
            React.createElement(SpinnerButton, { text: 'Save', buttonType: 'button', isLoading: isLoading, handleClick: handleSave, className: 'button -primary', isDisabled: isReadOnly, testId: 'save' }))));
};
export default IndustriesForm;
