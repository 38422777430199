import React from 'react';
const InvoicePdfsTable = ({ invoicePdfs, url, latestInvoicePdfId }) => {
    return (React.createElement("table", { className: 'custom-table -outline', "data-testid": 'invoice-pdfs-table' },
        React.createElement("thead", { className: 'table-headers' },
            React.createElement("tr", { className: 'row' },
                React.createElement("th", { className: 'header' }, "File name & date"),
                React.createElement("th", { className: 'header -right' }, "Action"))),
        React.createElement("tbody", null, invoicePdfs.map((invoicePdf, i) => (React.createElement("tr", { key: i, className: '-nowrap' },
            React.createElement("td", { className: 'cell' },
                React.createElement("div", null, invoicePdf.filename),
                React.createElement("div", { className: 'sub' }, invoicePdf.createdAt)),
            React.createElement("td", { className: 'cell -flex-center' },
                invoicePdf.id === latestInvoicePdfId && (React.createElement("div", { className: 'tag' }, "Current Version")),
                React.createElement("a", { href: url + `/${invoicePdf.id}`, className: 'icon-button -rectangular -border-brand-a-400 -text-brand-a-400' }, "Download"))))))));
};
export default InvoicePdfsTable;
