import { replaceSpaces } from '../utils';
const defaultImageMimeTypes = [
    'image/jpeg',
    'image/png',
    'image/tiff',
    'image/gif',
    'image/bmp'
];
const defaultSpreadsheetMimeTypes = [
    'application/excel',
    'application/x-excel',
    'application/x-msexcel',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    'text/csv'
];
const defaultMimeTypes = [
    ...defaultImageMimeTypes,
    ...defaultSpreadsheetMimeTypes,
    'application/pdf',
    'message/rfc822'
];
const tradeLoanMimeTypes = ['application/pdf', 'image/jpeg', 'image/png'];
export const validateFileType = (file, mimeTypes = defaultMimeTypes) => {
    return mimeTypes.includes(file.type);
};
export const validateImageFileType = (file, mimeTypes = defaultImageMimeTypes) => {
    return mimeTypes.includes(file.type);
};
export const validateTradeLoanFileType = (file, mimeTypes = tradeLoanMimeTypes) => {
    return mimeTypes.includes(file.type);
};
// default size limit is 20MB
export const validateFileSize = (file, sizeLimit = 20000000) => {
    return file.size <= sizeLimit;
};
export const generateFileErrors = (files) => {
    return files.reduce((acc, file) => {
        const fileTypeValidations = validateFileType(file)
            ? []
            : [`The file type isn't supported! (${file.name})`];
        const fileSizeValidations = validateFileSize(file)
            ? []
            : [`The file needs to be less than 20MB! (${file.name})`];
        return [...acc, ...fileTypeValidations, ...fileSizeValidations];
    }, []);
};
export const validateTradeLoanDuplicateFile = (file, fileNameList) => {
    const adjustedFileName = replaceSpaces(file.name, '_');
    return !fileNameList.includes(adjustedFileName);
};
export const validateNumberOfFiles = (files, limit = 5) => {
    return files.length <= limit;
};
