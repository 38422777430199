import React from 'react';
import Select from 'react-select';
const AdminSelect = ({ options, values, handleChange, handleCancel, handleUpdate, handlePrimaryChange, primaryValue }) => {
    return (React.createElement("div", { className: 'body', "data-testid": 'admin-select' },
        React.createElement("label", { className: 'label' }, "Primary Client Manager"),
        React.createElement(Select, { className: 'admin-select', placeholder: 'Select a primary client manager', options: options, defaultValue: primaryValue, onChange: handlePrimaryChange }),
        React.createElement("label", { className: 'label' }, "Assign Admins"),
        React.createElement(Select, { className: 'admin-select', options: options, defaultValue: values, onChange: handleChange, isMulti: true }),
        React.createElement("div", { className: 'action-buttons' },
            React.createElement("a", { className: 'button -tertiary', href: '#', onClick: handleCancel, "data-testid": 'cancel-action' }, "Cancel"),
            React.createElement("a", { className: 'button -primary', href: '#', onClick: handleUpdate, "data-testid": 'update-action' }, "Update"))));
};
export default AdminSelect;
