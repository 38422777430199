import React, { useRef, useState } from 'react';
import Select from 'react-select';
import { validateFileSize, validateImageFileType } from '../../validations/file';
import AvatarUploader from '../avatar-uploader/AvatarUploader';
import CustomPassword from '../shared/CustomPassword';
import Input from './Input';
import PasswordValidation from './PasswordValidation';
const EditProfileContainer = ({ user, adminRoleOptions = [], adminUserRole, borrowerRoleOptions = [], borrowerUserRole, backUrl, disableEditEmail, editPhoneNumber, editorUserRole }) => {
    const [selectedFile, setSelectedFile] = useState();
    const [validationErrors, setValidationErrors] = useState({});
    const [removeAvatar, setRemoveAvatar] = useState(false);
    const filePickerRef = useRef(null);
    const backLink = backUrl || '/';
    const handleClick = (event) => {
        event.preventDefault();
        if (filePickerRef && filePickerRef.current) {
            filePickerRef.current.click();
        }
    };
    const handleFileChange = (event) => {
        try {
            if (event.target.files) {
                const targetFile = event.target.files[0];
                setSelectedFile(targetFile);
                validateFile(targetFile);
            }
        }
        catch (err) {
            setValidationErrors(err);
        }
    };
    const handleRemoveAvatar = () => {
        setRemoveAvatar(currentRemoveAvatar => !currentRemoveAvatar);
        setSelectedFile(undefined);
        setValidationErrors({});
        if (filePickerRef && filePickerRef.current) {
            filePickerRef.current.value = '';
        }
    };
    const validateFile = (checkFile) => {
        const invalidImageFileType = checkFile && !validateImageFileType(checkFile);
        const invalidFileSize = checkFile && !validateFileSize(checkFile);
        if (invalidImageFileType) {
            const typeError = new Error('Please upload a valid file type (jpg, png).');
            throw { avatar: [typeError.message] };
        }
        else if (invalidFileSize) {
            const sizeError = new Error('Please upload a file smaller than 20MB.');
            throw { avatar: [sizeError.message] };
        }
        // files are valid so clear previous errors
        setValidationErrors({});
    };
    return (React.createElement("div", { className: 'profile-layout', "data-testid": 'edit-profile-container' },
        React.createElement("div", { className: 'left' },
            React.createElement(AvatarUploader, { ...{
                    removeAvatar,
                    selectedFile,
                    validationErrors,
                    handleClick,
                    handleFileChange,
                    handleRemoveAvatar
                }, avatarUrl: user.avatarUrl, initialsAvatarUrl: user.initialsAvatarUrl, model: 'user', forwardfilePickerRef: filePickerRef })),
        React.createElement("div", { className: 'right' },
            React.createElement("div", { className: 'form-group -section' },
                React.createElement("div", { className: 'form-row -col-2' },
                    React.createElement(Input, { maxLength: 100, labelText: 'First Name', name: 'first_name', size: 100, defaultValue: user.firstName }),
                    React.createElement(Input, { maxLength: 100, labelText: 'Last Name', name: 'last_name', size: 100, defaultValue: user.lastName })),
                editPhoneNumber && (React.createElement(Input, { maxLength: 50, labelText: 'Phone Number', name: 'phone_number', size: 100, defaultValue: user.phoneNumber })),
                React.createElement(Input, { labelText: 'Email', isDisabled: disableEditEmail, inputType: 'email', defaultValue: user.email, name: 'email' }),
                (editorUserRole === 'admin' || editorUserRole === 'super_admin') &&
                    user.isBorrower && (React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { className: 'label' }, "Borrower Role"),
                    React.createElement(Select, { options: borrowerRoleOptions, name: 'borrower_role', defaultValue: borrowerUserRole }))),
                (adminRoleOptions === null || adminRoleOptions === void 0 ? void 0 : adminRoleOptions.length) > 0 &&
                    editorUserRole === 'super_admin' &&
                    user.isAdmin && (React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { className: 'label' }, "Admin Role"),
                    React.createElement(Select, { options: adminRoleOptions, name: 'admin_role', defaultValue: adminUserRole })))),
            React.createElement("div", { className: 'form-group -section' },
                React.createElement(PasswordValidation, { labelText: "New Password (Leave blank if you don't want to change it)", model: 'user', name: 'password' }),
                React.createElement(CustomPassword, { field: 'password_confirmation', model: 'user', ariaLabel: '', labelText: 'New Password Confirmation', placeholder: '', maxLength: 100, size: 100 })),
            !(editorUserRole === 'admin' || editorUserRole === 'super_admin') && (React.createElement(CustomPassword, { field: 'current_password', model: 'user', ariaLabel: '', labelText: 'Current Password (To confirm changes)', placeholder: '', maxLength: 100, size: 100 })),
            React.createElement("div", { className: 'actions' },
                React.createElement("a", { href: backLink, className: 'button -mute' }, "Cancel"),
                React.createElement("input", { type: 'submit', className: 'custom-button button -primary', name: 'commit', value: 'Update', "data-disable-with": 'Please wait...' })))));
};
export default EditProfileContainer;
