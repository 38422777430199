import React, { useState } from 'react';
import { approveTradeLoanSupplier, unapproveTradeLoanSupplier } from '../../api/trade_loan_supplier';
import { showNotifyToast } from '../../utils';
import ToggleSwitch from '../shared/ToggleSwitch';
const TradeLoanSupplierApproval = ({ apiToken, companyId, supplierId, supplierName, isApproved }) => {
    const [isRequestPending, setIsRequestPending] = useState(false);
    const [approvalStatus, setApprovalStatus] = useState(isApproved);
    const errorMessage = 'Something went wrong. Please try again.';
    const onConfirmationText = `Are you sure you want to approve ${supplierName}?`;
    const offConfirmationText = `Are you sure you want to unapprove ${supplierName}?`;
    const approveSupplierHandler = async () => {
        setIsRequestPending(true);
        try {
            const { data } = await approveTradeLoanSupplier(apiToken, companyId, supplierId);
            setApprovalStatus(data.attributes.is_approved);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    const unapproveSupplierHandler = async () => {
        setIsRequestPending(true);
        try {
            const { data } = await unapproveTradeLoanSupplier(apiToken, companyId, supplierId);
            setApprovalStatus(data.attributes.is_approved);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsRequestPending(false);
    };
    return (React.createElement(ToggleSwitch, { isToggled: approvalStatus, switchOn: () => window.confirm(onConfirmationText) && approveSupplierHandler(), switchOff: () => window.confirm(offConfirmationText) && unapproveSupplierHandler(), isRequestPending: isRequestPending, isSwitchDisabled: false }));
};
export default TradeLoanSupplierApproval;
