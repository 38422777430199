import classNames from 'classnames';
import React from 'react';
const SpinnerButton = ({ text, buttonType, className = 'custom-button button -submit -blue', isDisabled, isLoading, testId, handleClick }) => {
    const submitButtonClass = classNames(className, {
        '-disabled': isLoading || isDisabled
    });
    const dataTestId = testId || 'spinner-button';
    return (React.createElement("button", { type: buttonType || 'button', className: submitButtonClass, disabled: isLoading || isDisabled, "data-testid": isDisabled ? `${dataTestId}-disabled` : dataTestId, onClick: handleClick },
        isLoading && (React.createElement("div", { className: 'loading-container loader -in-button', "data-testid": 'loading-container' },
            React.createElement("div", { className: 'loading-spinner spinner -white', "data-testid": 'loading-spinner' }))),
        text));
};
export default SpinnerButton;
