import React from 'react';
import ReactTooltip from 'react-tooltip';
const TextListTooltip = ({ items, tooltipId, tooltipDirection = 'bottom' }) => {
    const hasMultpleItems = items.length > 1;
    const [firstItem, ...otherItems] = items;
    return (React.createElement("div", { className: 'custom-tooltip -text-list', "data-testid": 'text-list-tooltip' },
        React.createElement("div", { className: 'text' }, firstItem),
        hasMultpleItems && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'counter', "data-tip": true, "data-for": tooltipId, "data-testid": 'counter' }, `+${otherItems.length}`),
            React.createElement(ReactTooltip, { id: tooltipId, place: tooltipDirection, effect: 'solid', backgroundColor: '#172b4d' }, otherItems.map((item, index) => (React.createElement("div", { className: 'item', key: index }, item))))))));
};
export default TextListTooltip;
