import React, { useState } from 'react';
import Subheader from '../../components/commission/Subheader';
import CommissionSteps from '../../components/commission/CommissionSteps';
import CommissionForm from '../../components/commission/CommissionForm';
const CommissionDashboard = ({ apiToken, backLink, logoPath, initialStep = 1, broker, email, clientOptions = [], brokerAddress }) => {
    const [currentStep, setCurrentStep] = useState(initialStep);
    const [initiateSave, SetInitiateSave] = useState(false);
    const handleCancel = () => {
        window.location.assign(backLink);
    };
    const handleCreate = () => {
        SetInitiateSave(true);
    };
    const stepInfo = [
        {
            stepLabel: 'Commission Type',
            previousButtonText: 'Cancel',
            previousButtonHandler: () => handleCancel(),
            nextButtonText: 'Next',
            nextButtonHandler: () => setCurrentStep(2)
        },
        {
            stepLabel: 'Commissions',
            previousButtonText: 'Go Back',
            previousButtonHandler: () => setCurrentStep(1),
            nextButtonText: 'Next',
            nextButtonHandler: () => setCurrentStep(3)
        },
        {
            stepLabel: 'Preview',
            previousButtonText: 'Go Back',
            previousButtonHandler: () => setCurrentStep(2),
            nextButtonText: 'Create',
            nextButtonHandler: () => {
                handleCreate();
            }
        }
    ];
    return (React.createElement("div", { className: 'commission-dashboard' },
        React.createElement(Subheader, { ...{ currentStep }, stepInfo: stepInfo[currentStep - 1] }),
        React.createElement(CommissionSteps, { ...{ currentStep }, stepLabels: stepInfo.map(step => step.stepLabel) }),
        React.createElement(CommissionForm, { ...{
                apiToken,
                currentStep,
                logoPath,
                broker,
                email,
                clientOptions,
                initiateSave,
                backLink,
                brokerAddress
            } })));
};
export default CommissionDashboard;
