import React from 'react';
import Modal from '../shared/Modal';
const ReportModal = ({ isModalVisible, path, handleCloseModal }) => {
    return (React.createElement(Modal, { isOpen: isModalVisible, onClose: handleCloseModal },
        React.createElement("div", { className: 'custom-panel -modal', role: 'dialog', "aria-modal": 'true' },
            React.createElement("div", { className: 'header' }, "Include Paid Invoices"),
            React.createElement("div", { className: 'content' },
                React.createElement("div", { className: 'section' },
                    React.createElement("div", { className: 'description' }, "Would you like to include paid invoices in the report?"),
                    React.createElement("div", { className: 'action-buttons -download-report -space-between' },
                        React.createElement("a", { className: 'button -neutral', href: path + '&invoice_type=unpaid', onClick: () => handleCloseModal() }, "No, don't include."),
                        React.createElement("a", { className: 'button -primary -full-width', href: path + '&invoice_type=paid', onClick: () => handleCloseModal() }, "Yes, include it.")))))));
};
export default ReportModal;
