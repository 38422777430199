import fetch from 'isomorphic-fetch';
export const fetchCreditNotes = async (apiToken, borrowerId, customerId, page) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/fetch_credit_notes?page=${page}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
