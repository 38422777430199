import React from 'react';
import CompanyName from '../CompanyName';
const InvoiceMarkAsIneligible = ({ event }) => {
    const { attributes } = event;
    const { createdAtFormatted, companyName, companyLink, customerLink, invoiceLink, eventable: { attributes: { number: invoiceNumber, amountFormatted, customerName } } } = attributes;
    return (React.createElement("div", { className: 'event-box box', "data-testid": 'invoice-mark-as-ineligible-activity' },
        React.createElement("div", { className: 'icon' },
            React.createElement("div", { className: 'activity-icon -invoice-marked-as-ineligible' })),
        React.createElement("div", { className: 'description' },
            React.createElement("div", { className: 'title' },
                "Earlypay marked invoice",
                React.createElement("span", { className: 'bold' },
                    React.createElement("a", { href: invoiceLink }, ` ${invoiceNumber} `)),
                "of",
                React.createElement("span", { className: 'amount -green' },
                    " ",
                    amountFormatted,
                    " "),
                "for",
                React.createElement("a", { href: customerLink }, ` ${customerName} `),
                "as ineligible for",
                ' ',
                React.createElement(CompanyName, { name: companyName, companyLink: companyLink })),
            React.createElement("div", { className: 'date' },
                " ",
                createdAtFormatted,
                " "))));
};
export default InvoiceMarkAsIneligible;
