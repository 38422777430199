import React from 'react';
import { Stepper, Step, useStepper, StepTitle } from 'react-progress-stepper';
import TwoFactorStepOne from '../../components/two-factor-wizard/TwoFactorStepOne';
import TwoFactorStepTwo from '../../components/two-factor-wizard/TwoFactorStepTwo';
import TwoFactorStepThree from '../../components/two-factor-wizard/TwoFactorStepThree';
const TwoFactorWizard = ({ apiToken, header, otpNumber, successIcon, recoveryKeyIcon, linkBack }) => {
    const { step, incrementStep, decrementStep } = useStepper(0, 3);
    const renderContent = step => {
        switch (step) {
            case 2:
                return React.createElement(TwoFactorStepThree, { ...{ linkBack, successIcon } });
            case 1:
                return (React.createElement(TwoFactorStepTwo, { ...{ apiToken, incrementStep, decrementStep, recoveryKeyIcon } }));
            case 0:
            default:
                return (React.createElement(TwoFactorStepOne, { ...{ apiToken, otpNumber, incrementStep, linkBack } }));
        }
    };
    return (React.createElement("div", { className: 'twofactor-wizard' },
        React.createElement("h2", { className: 'title' }, header),
        React.createElement("div", { className: 'step' },
            React.createElement(Stepper, { step: step },
                React.createElement(Step, null,
                    React.createElement(StepTitle, null, "Setup phone number")),
                React.createElement(Step, null,
                    React.createElement(StepTitle, null, "Your recovery code")),
                React.createElement(Step, null,
                    React.createElement(StepTitle, null, "2FA Activated")))),
        renderContent(step)));
};
export default TwoFactorWizard;
