import React, { useReducer, useRef, useState, useEffect } from 'react';
import Select from 'react-select';
import { fetchReminder, updateReminder } from '../../api/reminder';
import { fetchAdmins } from '../../api/user';
import { showNotifyToast } from '../../utils';
import ReminderPanelReducer from '../../reducers/ReminderPanelReducer';
import CustomFlatpickr from '../shared/CustomFlatpickr';
import LoadingSpinner from '../shared/LoadingSpinner';
import SpinnerButton from '../shared/SpinnerButton';
import RecipientsList from './RecipientsList';
import ReminderStatus from './ReminderStatus';
const ReminderPanel = ({ apiToken, fulfillmentMessage, remindableId, remindableType, scope, initialDeadline }) => {
    const [reminder, setReminder] = useState({});
    const [admins, setAdmins] = useState([]);
    const initialState = {
        deadline: initialDeadline || '',
        isEditMode: false,
        isLoading: false,
        isSaving: false,
        note: '',
        recipients: []
    };
    const [{ note, recipients, deadline, isEditMode, isLoading, isSaving }, dispatch] = useReducer(ReminderPanelReducer, initialState);
    const dateClearRef = useRef(null);
    const datePickerRef = useRef(null);
    useEffect(() => {
        let isSubscribed = true;
        const loadReminder = async () => {
            if (isSubscribed) {
                dispatch({ type: 'LOAD_START' });
            }
            try {
                const { data: adminData } = await fetchAdmins(apiToken);
                if (adminData && isSubscribed) {
                    setAdmins(adminData.map(admin => admin.attributes));
                }
                const response = await fetchReminder(apiToken, remindableId, remindableType, scope);
                if (response.data && isSubscribed) {
                    const loadedReminder = response.data.attributes;
                    setReminder(loadedReminder);
                    dispatch({ type: 'LOAD_SUCCESS', loadedReminder: loadedReminder });
                }
                if (isSubscribed) {
                    dispatch({ type: 'LOAD_NO_DATA' });
                }
            }
            catch (err) {
                if (isSubscribed) {
                    dispatch({ type: 'LOAD_ERROR' });
                }
                showNotifyToast({
                    text: 'Something went wrong. Please try again',
                    type: 'error'
                });
            }
        };
        loadReminder();
        return () => {
            isSubscribed = false;
        };
    }, []);
    const selectedAdmins = admins.filter((admin) => {
        return recipients.includes(admin.email);
    });
    const adminOptions = admins.map((admin) => {
        return {
            value: admin.email,
            label: `${admin.firstName} ${admin.lastName}`
        };
    });
    const selectedAdminOptions = adminOptions.filter((option) => {
        return recipients.includes(option.value);
    });
    const handleSelectedDateChange = (selectedDates, dateString) => {
        dispatch({ type: 'UPDATE_FIELD', field: 'deadline', value: dateString });
    };
    const flatpickrSettings = {
        wrap: true,
        altInput: true,
        altFormat: 'd / M / Y',
        dateFormat: 'Y-m-d',
        defaultDate: deadline,
        onChange: handleSelectedDateChange
    };
    const handleEditModeOn = (event) => {
        event.preventDefault();
        dispatch({ type: 'OPEN_EDIT_MODE' });
    };
    const handleNoteChange = (event) => {
        dispatch({ type: 'UPDATE_FIELD', field: 'note', value: event.target.value });
    };
    const handleRecipientsChange = (selectedOptions) => {
        const updatedRecipients = selectedOptions === null ? [] : selectedOptions.map(o => o.value);
        dispatch({
            type: 'UPDATE_FIELD',
            field: 'recipients',
            value: updatedRecipients
        });
    };
    const handleSave = async () => {
        dispatch({ type: 'SAVE_START' });
        try {
            const body = {
                reminder: {
                    deadline: deadline,
                    note: note,
                    recipients: recipients
                }
            };
            const response = await updateReminder(apiToken, remindableId, remindableType, scope, body);
            if (response.data) {
                const updatedReminder = response.data.attributes;
                setReminder(updatedReminder);
                dispatch({ type: 'SAVE_SUCCESS', updatedReminder: updatedReminder });
                showNotifyToast({
                    text: 'Successfully updated reminder.',
                    type: 'success'
                });
            }
            dispatch({ type: 'SAVE_NO_DATA' });
        }
        catch (err) {
            dispatch({ type: 'SAVE_ERROR' });
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
    };
    return (React.createElement("div", { className: 'custom-panel reminder-panel reminder', "data-testid": 'reminder-panel' },
        React.createElement("div", { className: 'header -space-between' },
            "Reminder",
            isEditMode || (React.createElement("a", { href: '#', onClick: handleEditModeOn, "data-testid": 'edit-button' }, "Edit"))),
        isEditMode ||
            (isLoading ? (React.createElement(LoadingSpinner, { className: '-small' })) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'info', "data-testid": 'view-reminder' },
                    React.createElement("div", { className: 'heading' }, "Note"),
                    note ? (React.createElement("div", { className: 'note' }, note)) : (React.createElement("div", { className: 'note -unset' }, "No note set")),
                    React.createElement("div", { className: 'heading' }, "Send email to"),
                    React.createElement(RecipientsList, { recipients: selectedAdmins })),
                React.createElement(ReminderStatus, { ...{ fulfillmentMessage, reminder } })))),
        isEditMode && (React.createElement("div", { className: 'form', "data-testid": 'edit-reminder' },
            React.createElement(CustomFlatpickr, { ...{ flatpickrSettings }, selectedDate: deadline || '', forwardDatePickerRef: datePickerRef, forwardDateClearRef: dateClearRef, clearButtonText: 'Clear Date', pickerBackground: 'neutral-20' }),
            React.createElement("textarea", { className: 'form-control field note -textarea', onChange: handleNoteChange, value: note, placeholder: 'Write a note (optional)' }),
            React.createElement(Select, { className: 'recipients', options: adminOptions, isMulti: true, onChange: handleRecipientsChange, value: selectedAdminOptions }),
            React.createElement("div", { className: 'action-buttons actions' },
                React.createElement("button", { type: 'button', className: 'button -cancel', onClick: () => dispatch({ type: 'CLOSE_EDIT_MODE' }), "data-testid": 'cancel-edit' }, "Cancel"),
                React.createElement(SpinnerButton, { text: 'Save', buttonType: 'button', className: 'button -primary', isLoading: isSaving, isDisabled: isSaving, testId: 'save-button', handleClick: handleSave }))))));
};
export default ReminderPanel;
