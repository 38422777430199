const StepReducer = (state, action) => {
    const { steps } = state;
    switch (action) {
        case 'next': {
            const nextStepIndex = Math.min(steps.indexOf(state.currentStep) + 1, steps.length - 1);
            return { ...state, currentStep: steps[nextStepIndex] };
        }
        case 'back': {
            const previousStepIndex = Math.max(steps.indexOf(state.currentStep) - 1, 0);
            return { ...state, currentStep: steps[previousStepIndex] };
        }
        default:
            throw new Error();
    }
};
export default StepReducer;
