import fetch from 'isomorphic-fetch';
import queryString from 'query-string';
export const computeTradeLoanCommission = async (apiToken, borrowerId, id, date) => {
    const params = {
        date: date,
        trade_loan: id
    };
    const endpoint = `/api/admin/borrowers/${borrowerId}/commissions?${queryString.stringify(params)}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const saveTradeLoanCommissions = async (apiToken, borrowerId, target_date, commissions) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/commissions`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'POST',
        body: JSON.stringify({ target_date: target_date, commissions: commissions })
    }).then((res) => res.json());
};
export const ComputeClientCommission = async (apiToken, brokerId, id, date) => {
    const params = {
        date: date,
        client: id
    };
    const endpoint = `/api/admin/brokers/${brokerId}/commissions?${queryString.stringify(params)}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const SaveClientCommissions = async (apiToken, brokerId, target_date, clients) => {
    const endpoint = `/api/admin/brokers/${brokerId}/commissions`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'POST',
        body: JSON.stringify({ target_date: target_date, clients: clients })
    }).then((res) => res.json());
};
