import React from 'react';
import PaginationBar from '../shared/PaginationBar';
// Components
import CustomersTableBody from './CustomersTableBody';
import SortableTableHeaders from '../table-headers/SortableTabs';
const CustomersTable = ({ currentPage, customersArray, isLoading, pageSize, sortBy, sortDirection, totalItems, totalPages, forwardHeaderRef, headerWidths, isManualLedger, 
// debtorFiles,
// generateExportedFilePath,
// deselectAllCustomersHandler,
handlePageClick, 
// selectAllCustomersHandler,
sortCustomers, switchOffHandler, switchOnHandler }) => {
    // const filterOptions = [
    //   {
    //     label: 'Selected Debtors with an outstanding balance',
    //     value: 'selected_with_outstanding'
    //   },
    //   {
    //     label: 'All Selected Debtor',
    //     value: 'selected'
    //   },
    //   {
    //     label: 'Unselected Debtor',
    //     value: 'unselected'
    //   }
    // ]
    // const selectedCustomers = customersArray.filter(
    //   customer => customer.isSelected === true
    // ).length
    // const allCustomersSelected = selectedCustomers === customersArray.length
    // const someCustomersSelected =
    //   selectedCustomers !== customersArray.length && selectedCustomers !== 0
    // const noCustomersSelected = selectedCustomers === 0
    // const selectAllConfirmationText = `Are you sure? Selecting all customers will nominate all customers to be funded against. Should you wish to deselect any customers, please contact Earlypay.`
    // const headerFields = isManualLedger
    //   ? [
    //       { text: 'Debtor Code', field: 'source_id', defaultSort: 'asc' },
    //       { text: 'Debtor Name', field: 'name', defaultSort: 'asc' },
    //       { text: 'Selected', field: 'is_selected' },
    //       { text: 'Approval Status', field: 'earlypay_status' },
    //       { text: 'Advance Rate', field: 'advance_rate' },
    //       { text: 'Insured Limit', field: 'approved_insurance_limit' },
    //       {
    //         text: 'Debtor Limit'
    //       },
    //       {
    //         text: 'Concentration Limit'
    //       },
    //       {
    //         text: 'Selected Ledger'
    //       },
    //       {
    //         text: 'Pending Invoices'
    //       },
    //       {
    //         text: 'Disapproved Invoices'
    //       },
    //       {
    //         text: 'Debtor Limit Excess'
    //       },
    //       {
    //         text: 'Concentration Limit Excess'
    //       },
    //       {
    //         text: 'Ineligible Adjustments'
    //       },
    //       {
    //         text: 'Approved Ledger'
    //       },
    //       {
    //         text: 'Debtor Advance Rate Adjustment',
    //         field: 'advance_rate_adjustment'
    //       }
    //     ]
    const headerFields = isManualLedger
        ? [
            { text: 'Customer', field: 'name', defaultSort: 'asc' },
            { text: 'Gross Receivables Ledger' },
            { text: 'Status', field: 'earlypay_status' },
            { text: 'Select', field: 'is_selected' },
            { text: 'Action' }
        ]
        : [
            { text: 'Customer', field: 'name', defaultSort: 'asc' },
            { text: 'Current' },
            { text: 'Overdue', field: 'invoice_amount_due', defaultSort: 'desc' },
            { text: 'Status', field: 'earlypay_status' },
            { text: 'Select', field: 'is_selected' },
            { text: 'Action' }
        ];
    return (React.createElement("div", { className: 'custom-panel' },
        React.createElement("table", { "data-testid": 'customers-table', className: 'custom-table -overflow' },
            React.createElement(SortableTableHeaders, { ...{
                    forwardHeaderRef,
                    headerFields,
                    headerWidths,
                    isLoading,
                    sortBy,
                    sortDirection
                }, sortItems: sortCustomers }),
            React.createElement(CustomersTableBody, { ...{
                    customersArray,
                    isLoading,
                    switchOffHandler,
                    switchOnHandler,
                    isManualLedger
                } })),
        totalItems > pageSize && (React.createElement(PaginationBar, { ...{
                currentPage,
                pageSize,
                totalPages,
                totalItems,
                handlePageClick
            }, resource: 'customers' }))));
};
export default CustomersTable;
