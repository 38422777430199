import React from 'react';
import LinearProgressBar from '../../components/progress-bar/LinearProgressBar';
const VerifyInvoicesToastPanel = ({ selectedInvoiceCount, invoicesCount, selectedInvoicesTotalAmountDue, allInvoicesTotalAmountDue, handleVerifyButton, handleCancelVerifyButton }) => {
    const percentage = (selectedInvoiceCount / invoicesCount) * 100;
    return (React.createElement("div", { className: 'custom-panel verify-invoices-toast', "data-testid": 'verify-invoices-toast' },
        React.createElement("div", { className: 'header' },
            selectedInvoiceCount,
            " Invoices Selected"),
        React.createElement("div", { className: 'content' },
            React.createElement("div", { className: 'text' },
                React.createElement("p", null,
                    "Verify ",
                    allInvoicesTotalAmountDue,
                    " worth of invoices to pass verification"),
                React.createElement(LinearProgressBar, { progress: percentage }),
                React.createElement("p", null,
                    selectedInvoicesTotalAmountDue,
                    " worth of invoices selected"))),
        React.createElement("div", { className: 'action-buttons buttons space -padding -space-between' },
            React.createElement("button", { className: 'button -cancel -with-border -loose-width', onClick: handleCancelVerifyButton }, "Cancel"),
            React.createElement("button", { className: 'button -primary -loose-width', onClick: handleVerifyButton }, "Verify"))));
};
export default VerifyInvoicesToastPanel;
