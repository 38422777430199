import React from 'react';
import Author from './Author';
const CommentOnActvity = (props) => {
    const { notification } = props;
    const { attributes } = notification;
    const { actorAvatar, actorFullname, showPath, type, createdAtInWords, currentUserRole, companyName, companyPath } = attributes;
    return (React.createElement("div", { className: 'event-panel -notification custom-panel event' },
        React.createElement("div", { className: 'event-box -notification -unread', "data-testid": 'comment-on-activity' },
            React.createElement("div", { className: 'user icon' },
                React.createElement("img", { src: actorAvatar, className: 'avatar' })),
            React.createElement("div", { className: 'description' },
                React.createElement("div", { className: 'title' },
                    React.createElement(Author, { ...{
                            actorFullname,
                            currentUserRole,
                            companyPath,
                            companyName
                        } }),
                    "left a comment on",
                    React.createElement("a", { href: showPath },
                        " ",
                        type,
                        " "),
                    "activity"),
                React.createElement("div", { className: 'date' },
                    " ",
                    createdAtInWords)))));
};
export default CommentOnActvity;
