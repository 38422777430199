import React from 'react';
const MasterDebtorLink = ({ customerData, checkIcon }) => {
    if (customerData.masterDebtor &&
        customerData.masterDebtor.abrValidated &&
        customerData.masterDebtor.earlypayStatus !== 'PENDING') {
        return (React.createElement("a", { href: `/admin/debtors/${customerData.masterDebtor.id}`, target: '_blank', rel: 'noreferrer' },
            customerData.masterDebtor.name,
            customerData.masterDebtor.validated && checkIcon && (React.createElement("div", { className: 'icon-toggle -check' },
                React.createElement("i", { className: 'icon' })))));
    }
    else {
        return customerData.abn ? (React.createElement("a", { href: `/admin/debtors/request_validation?customer_id=${customerData.id}` }, "Request Validation")) : (React.createElement("strong", null, "ABN REQUIRED"));
    }
};
export default MasterDebtorLink;
