import fetch from 'isomorphic-fetch';
export const updateReconcilingAsOf = async (apiToken, companyId, uploadedLedgerId, uploadedLedger) => {
    const endpoint = `/api/admin/companies/${companyId}/manual_ledger/uploaded_ledgers/${uploadedLedgerId}/update_reconciling_as_of`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: JSON.stringify(uploadedLedger)
    }).then((res) => res.json());
};
export const fetchUploadedLedgerRow = async (apiToken, companyId, uploadedLedgerId, rowType) => {
    const endpoint = `/api/admin/companies/${companyId}/manual_ledger/uploaded_ledgers/${uploadedLedgerId}/rows?row_type=${rowType}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
