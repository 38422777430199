import fetch from 'isomorphic-fetch';
export const fetchAttachments = async (apiToken, releaseNoteId) => {
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    const endpoint = `/api/admin/release_notes/${releaseNoteId}/attachments`;
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
