import React, { useState } from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import { dropdownStyles } from '../../custom-styles/dropdown';
const FinanceEditor = ({ formAttributes, company, isDisabled = false }) => {
    const { entity, funder, segmentAccountBank } = company;
    const { url, csrfToken } = formAttributes;
    const buildInitial = (options, defaultValue) => {
        return options.find(data => data.value === defaultValue) || null;
    };
    const entityOptions = [
        { value: 'ep', label: 'Earlypay' },
        { value: 'sk', label: 'Skippr' }
    ];
    const funderOptions = [
        { value: 'anz', label: 'ANZ' },
        { value: 'westpac', label: 'Westpac' },
        { value: 'earlypay', label: 'Earlypay' }
    ];
    const segmentAccountBankOptions = [
        { value: 'anz', label: 'ANZ' },
        { value: 'nab', label: 'NAB' },
        { value: 'westpac', label: 'Westpac' },
        { value: 'ebury', label: 'ebury' },
        { value: 'boq', label: 'BOQ' }
    ];
    const [entityOption, setEntityOption] = useState(buildInitial(entityOptions, entity));
    const [funderOption, setFunderOption] = useState(buildInitial(funderOptions, funder));
    const [segmentAccountBankOption, setSegmentAccountBankOption] = useState(buildInitial(segmentAccountBankOptions, segmentAccountBank));
    const handleEntityChange = (selectedOption) => {
        setEntityOption(selectedOption);
    };
    const handleFunderChange = (selectedOption) => {
        setFunderOption(selectedOption);
    };
    const handleSegmentAccountBankChange = (selectedOption) => {
        setSegmentAccountBankOption(selectedOption);
    };
    const submitClassNames = classNames('custom-button update -default -narrow', {
        '-disabled': isDisabled
    });
    return (React.createElement("form", { acceptCharset: 'UTF-8', method: 'post', action: url, className: 'form' },
        React.createElement("input", { type: 'hidden', name: '_method', value: 'patch' }),
        React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: 'label' }, "Company Entity"),
            React.createElement(Select, { value: entityOption, options: entityOptions, onChange: handleEntityChange, styles: dropdownStyles, isDisabled: isDisabled, name: 'company[entity]' })),
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: 'label' }, "Funder"),
            React.createElement(Select, { value: funderOption, options: funderOptions, onChange: handleFunderChange, styles: dropdownStyles, isDisabled: isDisabled, name: 'company[funder]' })),
        React.createElement("div", { className: 'form-group' },
            React.createElement("label", { className: 'label' }, "Segment Account Bank"),
            React.createElement(Select, { value: segmentAccountBankOption, options: segmentAccountBankOptions, onChange: handleSegmentAccountBankChange, styles: dropdownStyles, isDisabled: isDisabled, name: 'company[segment_account_bank]' })),
        React.createElement("div", { className: 'action-buttons' },
            React.createElement("input", { type: 'submit', className: submitClassNames, name: 'commit', value: 'Update', "data-disable-with": 'Updating...', disabled: isDisabled }))));
};
export default FinanceEditor;
