import React from 'react';
import DocumentPicker from './DocumentPicker';
import ErrorList from '../shared/ErrorList';
const SupportingDocumentsEdit = ({ currentTradeLoanDetails, tradeLoanErrors, documentErrors, handleRemoveFile, handleFileChange, handleFileReplace, handleDeleteFile }) => {
    const { purchaseOrder, existingPurchaseOrder, proFormaInvoice, existingProFormaInvoice, transportDocument, existingTransportDocument, otherDocuments, existingOtherDocuments } = currentTradeLoanDetails;
    return (React.createElement("div", { className: 'form-section section -trade-loan -supporting-documents', "data-testid": 'supporting-documents-edit' },
        React.createElement("div", { className: 'body' },
            React.createElement("div", { className: 'form-group group' },
                React.createElement("div", { className: 'label -uppercase' }, "Purchase Order"),
                React.createElement(DocumentPicker, { inputName: 'trade_loan_details[purchase_order]', files: purchaseOrder, existingFiles: existingPurchaseOrder, handleFileChange: handleFileChange, handleFileReplace: handleFileReplace, handleRemoveFile: handleRemoveFile, errors: documentErrors['purchaseOrder'] || {}, handleDeleteFile: handleDeleteFile }),
                React.createElement(ErrorList, { errors: tradeLoanErrors, field: 'purchase_order' })),
            React.createElement("div", { className: 'form-group group' },
                React.createElement("div", { className: 'label -uppercase' }, "Pro-Forma Invoice"),
                React.createElement(DocumentPicker, { inputName: 'trade_loan_details[pro_forma_invoice]', files: proFormaInvoice, existingFiles: existingProFormaInvoice, handleFileChange: handleFileChange, handleFileReplace: handleFileReplace, handleRemoveFile: handleRemoveFile, handleDeleteFile: handleDeleteFile, errors: documentErrors['proFormaInvoice'] || {} }),
                React.createElement(ErrorList, { errors: tradeLoanErrors, field: 'pro_forma_invoice' })),
            React.createElement("div", { className: 'form-group group' },
                React.createElement("div", { className: 'label -uppercase' }, "Transport Document (e.g. Bill of Lading or Airway Bill)"),
                React.createElement(DocumentPicker, { inputName: 'trade_loan_details[transport_document]', files: transportDocument, existingFiles: existingTransportDocument, handleFileChange: handleFileChange, handleFileReplace: handleFileReplace, handleRemoveFile: handleRemoveFile, handleDeleteFile: handleDeleteFile, errors: documentErrors['transportDocument'] || {} }),
                React.createElement(ErrorList, { errors: tradeLoanErrors, field: 'transport_document' })),
            React.createElement("div", { className: 'form-group group' },
                React.createElement("div", { className: 'label -uppercase' }, "Other Documents"),
                React.createElement(DocumentPicker, { inputName: 'trade_loan_details[other_documents]', files: otherDocuments, existingFiles: existingOtherDocuments, handleFileChange: handleFileChange, handleFileReplace: handleFileReplace, handleRemoveFile: handleRemoveFile, handleDeleteFile: handleDeleteFile, errors: documentErrors['otherDocuments'] || {} }),
                React.createElement(ErrorList, { errors: tradeLoanErrors, field: 'other_documents' })),
            React.createElement("div", { className: 'form-group group' },
                React.createElement("div", { className: 'paragraph' }, "By submitting this Application, I certify that the above information is true, accurate and complete.")))));
};
export default SupportingDocumentsEdit;
