import React, { useEffect, useState } from 'react';
import Dinero from 'dinero.js';
import { fetchDebtors as defaultFetchDebtors } from '../../api/debtor';
import Loading from '../../components/shared/Loading';
import { showNotifyToast } from '../../utils/notifyToast';
import PaginationBar from '../../components/shared/PaginationBar';
import { formatDateWithYearAU } from '../../utils/formatDateWithYearAU';
const AdminDebtorsTable = ({ apiToken, debtors, status, searchKey = '', fetchDebtors = defaultFetchDebtors }) => {
    const [debtorsList, setDebtorsList] = useState(debtors);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(0);
    const [sortBy, setSortBy] = useState('debtor_count');
    const [sortDirection, setSortDirection] = useState('desc');
    const [currentStatus, setCurrentStatus] = useState(status);
    const fetchData = async (defaultStatus = '', defaultFilter = sortBy, defaultOrder = 'desc') => {
        try {
            const { data } = await fetchDebtors(apiToken, currentStatus !== null && currentStatus !== void 0 ? currentStatus : defaultStatus, sortBy === '' ? defaultOrder : sortDirection, defaultFilter, currentPage, searchKey !== null && searchKey !== void 0 ? searchKey : '');
            const { page, totalItems, totalPages, pageSize } = data.attributes;
            setTotalPages(totalPages);
            setTotalItems(totalItems);
            setPageSize(pageSize);
            setDebtorsList(page);
            setIsLoading(false);
        }
        catch (err) {
            const error = err;
            showNotifyToast({
                text: error.message,
                type: 'error'
            });
        }
    };
    const handleSorting = (defaultStatus, defaultFilter = '') => {
        // If same filter is called, do the opposite sorting
        const sort = defaultFilter === sortBy && sortDirection === 'desc' ? 'asc' : 'desc';
        setSortDirection(sort);
        setSortBy(defaultFilter);
        setCurrentStatus(defaultStatus);
        setCurrentPage(1);
        setIsLoading(true);
    };
    const handleNameSorting = (defaultStatus, defaultFilter = '', defaultSorting = 'asc') => {
        if (sortDirection === '')
            setSortDirection(defaultSorting);
        handleSorting(defaultStatus, defaultFilter);
    };
    const handlePageClick = (data) => {
        setCurrentPage(data.selected + 1);
    };
    useEffect(() => {
        setIsLoading(false);
    }, [debtorsList]);
    useEffect(() => {
        setIsLoading(true);
        fetchData(currentStatus);
    }, [currentPage]);
    useEffect(() => {
        if (sortBy && sortDirection)
            fetchData(currentStatus, sortBy, sortDirection);
    }, [sortDirection, sortBy]);
    return (React.createElement(React.Fragment, null,
        React.createElement("table", { className: 'custom-table' },
            isLoading && (React.createElement("tr", null,
                React.createElement("td", { colSpan: 10, align: 'center' },
                    React.createElement(Loading, null)))),
            !isLoading && (React.createElement(React.Fragment, null,
                React.createElement("thead", { className: 'table-headers' },
                    React.createElement("tr", { className: 'row -header' },
                        React.createElement("th", { className: 'sortable-th', onClick: () => handleNameSorting(currentStatus, 'debtor_name', 'asc') },
                            "Debtor Name",
                            React.createElement("span", { className: 'arrow' },
                                sortBy === 'debtor_name' && sortDirection === 'asc' && '▲',
                                sortBy === 'debtor_name' &&
                                    sortDirection === 'desc' &&
                                    '▼')),
                        React.createElement("th", { className: 'header' }, "ABN"),
                        React.createElement("th", { className: 'header' }, "ACN"),
                        React.createElement("th", { className: 'header' }, "Created"),
                        React.createElement("th", { className: 'header' }, "Validated"),
                        React.createElement("th", { className: 'sortable-th', onClick: () => handleSorting(currentStatus, 'debtor_count') },
                            "Linked Debtors",
                            React.createElement("span", { className: 'arrow' },
                                sortBy === 'debtor_count' &&
                                    sortDirection === 'asc' &&
                                    '▲',
                                sortBy === 'debtor_count' &&
                                    sortDirection === 'desc' &&
                                    '▼')),
                        React.createElement("th", { className: 'sortable-th', onClick: () => handleSorting(currentStatus, 'total_outstanding') },
                            "Total Outstanding",
                            ' ',
                            React.createElement("span", { className: 'arrow' },
                                sortBy === 'total_outstanding' &&
                                    sortDirection === 'asc' &&
                                    '▲',
                                sortBy === 'total_outstanding' &&
                                    sortDirection === 'desc' &&
                                    '▼')),
                        React.createElement("th", { className: 'header' }, "Insurance Limit"))),
                React.createElement("tbody", null, debtorsList &&
                    debtorsList.map((debtor, key) => (React.createElement("tr", { key: key },
                        React.createElement("td", { className: 'cell' },
                            React.createElement("a", { href: `/admin/debtors/${debtor.id}` },
                                debtor.name,
                                debtor.earlypayStatus === 'VALIDATED' && (React.createElement("div", { className: 'icon-toggle -check' },
                                    React.createElement("i", { className: 'icon' }))))),
                        React.createElement("td", { className: 'cell' },
                            debtor.abn,
                            " ",
                            debtor.abn.length !== 11 && '❌'),
                        React.createElement("td", { className: 'cell' }, debtor.acn),
                        React.createElement("td", { className: 'cell' }, formatDateWithYearAU(debtor.createdAt)),
                        React.createElement("td", { className: 'cell' }, debtor.abrValidatedAt
                            ? formatDateWithYearAU(debtor.abrValidatedAt)
                            : 'N/A'),
                        React.createElement("td", { className: 'cell' }, debtor.debtorsCount),
                        React.createElement("td", { className: 'cell' }, Dinero({
                            amount: debtor.totalOutstanding
                        }).toFormat('$0,0.00')),
                        React.createElement("td", { className: 'cell' }, Dinero({
                            amount: debtor.approvedInsuranceLimitCents
                        }).toFormat('$0,0.00'))))))))),
        totalItems > pageSize && (React.createElement(PaginationBar, { ...{
                currentPage,
                totalPages,
                totalItems,
                pageSize,
                handlePageClick
            }, resource: 'debtors' }))));
};
export default AdminDebtorsTable;
