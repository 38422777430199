import React from 'react';
import Modal from '../shared/Modal';
const ApproveWarningModal = ({ isWarningModalOpen, closeApproveWarningModal, customerName, customerSettingsLink }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { isOpen: isWarningModalOpen, onClose: closeApproveWarningModal },
            React.createElement("div", { className: 'custom-panel -modal', "data-testid": 'approve-customer-warning-modal' },
                React.createElement("div", { className: 'header' },
                    React.createElement("div", { className: 'custom-icon icon -warning -alert' },
                        "Approve Customer Error \u00A0 ",
                        React.createElement("i", { className: 'icon' }))),
                React.createElement("div", { className: 'content' },
                    React.createElement("p", null,
                        customerName,
                        " does not have a valid ABN saved. Please enter a valid ABN for this customer to fund invoices."),
                    React.createElement("br", null),
                    React.createElement("a", { href: customerSettingsLink }, "Click here"),
                    " to update the ABN for this customer")))));
};
export default ApproveWarningModal;
