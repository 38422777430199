import React from 'react';
import { formatPrice } from '../../utils/formatPrice';
const PaymentsTooltip = ({ classVariant, currency, title, payments, total, style }) => {
    return (React.createElement("div", { className: `tooltip tooltip-panel ${classVariant}`, "data-testid": 'payments-tooltip', style: style },
        React.createElement("div", { className: 'title' }, title),
        payments.map(payment => {
            const amount = payment.amountCents / 100;
            return (React.createElement("div", { className: 'row', key: payment.id },
                React.createElement("div", { className: 'description' }, payment.text),
                React.createElement("div", { className: 'amount' }, formatPrice(amount))));
        }),
        React.createElement("div", { className: 'row -total' },
            React.createElement("div", { className: 'description' }, "Total"),
            React.createElement("div", { className: 'amount', "data-currency": currency }, formatPrice(total)))));
};
export default PaymentsTooltip;
