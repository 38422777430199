import React from 'react';
import ReactTooltip from 'react-tooltip';
const ReconciliationWarningIndicator = ({ hidden = true, tooltipText = 'Please review irregular transactions' }) => {
    if (hidden) {
        return null;
    }
    return (React.createElement("div", { className: 'tooltip-wrapper' },
        React.createElement("div", { className: 'text', "data-tip": true },
            React.createElement("i", { "aria-label": 'info-icon', className: 'custom-icon -warning -yellow' })),
        React.createElement(ReactTooltip, { place: 'bottom', effect: 'solid' },
            React.createElement("div", { className: 'tooltip-content' },
                React.createElement("p", null, tooltipText)))));
};
export default ReconciliationWarningIndicator;
