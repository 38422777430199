import fetch from 'isomorphic-fetch';
import queryString from 'query-string';
export const fetchSearchResults = async (apiToken, query) => {
    const params = { text: query };
    const endpoint = `/api/admin/algolia_search?${queryString.stringify(params)}`;
    return fetch(endpoint, {
        headers: {
            Authorization: apiToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((res) => res.json());
};
export const fetchBorrowerSearchResults = async (apiToken, query, companyId) => {
    const params = { text: query, company_id: companyId };
    const endpoint = `/api/borrower/algolia_search?${queryString.stringify(params)}`;
    return fetch(endpoint, {
        headers: {
            Authorization: apiToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((res) => res.json());
};
