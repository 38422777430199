import React, { useState } from 'react';
import { findOptionByLabel, findOptionByValue } from '../../utils';
import CustomOptionSelector from '../shared/CustomOptionSelector';
import ErrorList from '../../components/shared/ErrorList';
const TradeLoanSettings = ({ company, csrfToken, formUrl, errors, loanTermOptions }) => {
    const { tradeLoanEnabled, enableTradeLoanFees, enableTradeLoanInterest, tradeLoanDefaultTerm } = company;
    const defaultValue = tradeLoanDefaultTerm
        ? tradeLoanDefaultTerm.toString()
        : '0';
    const customTermOption = findOptionByLabel(loanTermOptions, 'Custom');
    const defaultTerm = findOptionByValue(loanTermOptions, defaultValue) || customTermOption;
    const [selectedLoanTerm, setSelectedLoanTerm] = useState(defaultTerm);
    const handleOptionChange = (event) => {
        const option = findOptionByValue(loanTermOptions, event.target.value) || customTermOption;
        setSelectedLoanTerm(option);
    };
    return (React.createElement("form", { action: formUrl, acceptCharset: 'UTF-8', method: 'post' },
        React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
        React.createElement("input", { type: 'hidden', name: '_method', value: 'put' }),
        React.createElement("div", { className: 'form-group -oneline' },
            React.createElement("div", { className: 'label' }, "Enable Trade Loan"),
            React.createElement("label", { className: 'toggle-switch switch' },
                React.createElement("input", { type: 'hidden', name: 'company[trade_loan_enabled]', value: 'false' }),
                React.createElement("input", { type: 'checkbox', name: 'company[trade_loan_enabled]', value: 'true', defaultChecked: tradeLoanEnabled }),
                React.createElement("span", { className: 'track' },
                    React.createElement("span", { className: 'peg' })))),
        React.createElement("div", { className: 'form-group -oneline' },
            React.createElement("div", { className: 'label' }, "Enable Fees"),
            React.createElement("label", { className: 'toggle-switch switch' },
                React.createElement("input", { type: 'hidden', name: 'company[enable_trade_loan_fees]', value: 'false' }),
                React.createElement("input", { type: 'checkbox', name: 'company[enable_trade_loan_fees]', value: 'true', defaultChecked: enableTradeLoanFees }),
                React.createElement("span", { className: 'track' },
                    React.createElement("span", { className: 'peg' })))),
        React.createElement("div", { className: 'form-group -oneline' },
            React.createElement("div", { className: 'label' }, "Enable Interest"),
            React.createElement("label", { className: 'toggle-switch switch' },
                React.createElement("input", { type: 'hidden', name: 'company[enable_trade_loan_interest]', value: 'false' }),
                React.createElement("input", { type: 'checkbox', name: 'company[enable_trade_loan_interest]', value: 'true', defaultChecked: enableTradeLoanInterest }),
                React.createElement("span", { className: 'track' },
                    React.createElement("span", { className: 'peg' })))),
        React.createElement(CustomOptionSelector, { options: loanTermOptions, selectedOption: selectedLoanTerm, customOptionValue: '0', fieldLabel: 'Default Loan Term', fieldName: 'trade_loan_default_term', modelName: 'company', isRequired: true, handleOptionChange: handleOptionChange },
            React.createElement("input", { type: 'number', step: 0, className: 'input form-control', maxLength: 100, name: 'company[trade_loan_default_term]', id: 'custom_trade_loan_default_term', defaultValue: tradeLoanDefaultTerm }),
            React.createElement(ErrorList, { errors: errors, field: 'trade_loan_default_term' })),
        React.createElement("div", { className: 'action-buttons actions' },
            React.createElement("button", { type: 'submit', className: 'custom-button update -default -narrow' }, "Save"))));
};
export default TradeLoanSettings;
