import React, { useState, useEffect } from 'react';
import Drawer from '../shared/Drawer';
import AdjustmentCard from './AdjustmentCard';
import { fetchUploadedLedgerRow } from '../../api/uploaded_ledger';
import Loading from '../shared/Loading';
import ReconciliationWarningIndicator from './ReconciliationWarningIndicator';
const LedgerAdjustmentsDrawer = ({ apiToken, rowType, uploadedLedgerId, companyId, header, totalAdjustmentsFormatted, userId, exportPath, displayWarningIcon = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [rows, setRows] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    useEffect(() => {
        const fetchRows = async () => {
            const rows = await fetchUploadedLedgerRow(apiToken, companyId, uploadedLedgerId, rowType);
            setIsFetching(false);
            setRows(rows);
        };
        fetchRows();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'shadow-reconciliation-row -sub -clickable', onClick: () => setIsOpen(true) },
            React.createElement("div", { className: 'label' },
                header,
                " ",
                React.createElement("div", { className: 'custom-icon -clickable -open-outline' })),
            React.createElement("div", { className: 'value' },
                React.createElement(ReconciliationWarningIndicator, { hidden: !displayWarningIcon }),
                totalAdjustmentsFormatted)),
        React.createElement(Drawer, { isOpen: isOpen, onClose: () => setIsOpen(false) },
            React.createElement("div", { className: 'cash-received-drawer' },
                React.createElement("div", { className: 'actions' },
                    React.createElement("button", { className: 'back', onClick: () => setIsOpen(false) }, "Go Back")),
                React.createElement("div", { className: 'header' },
                    header,
                    React.createElement("div", { className: 'action-buttons' },
                        React.createElement("a", { className: 'button -secondary', href: exportPath }, "Export"))),
                React.createElement("div", { className: 'separator' }),
                React.createElement("div", { className: 'transactions' },
                    isFetching && React.createElement(Loading, null),
                    !isFetching &&
                        (rows === null || rows === void 0 ? void 0 : rows.map(row => (React.createElement(AdjustmentCard, { key: row.adjustmentId, row: row, userId: userId })))))))));
};
export default LedgerAdjustmentsDrawer;
