import React from 'react';
const AmountField = ({ label, amount, classVariant = '' }) => {
    if (amount === '$0.00') {
        return null;
    }
    return (React.createElement("div", { className: 'field', "data-testid": 'activity-feed-amount-field' },
        React.createElement("div", { className: 'label' },
            " ",
            label),
        React.createElement("div", { className: `text ${classVariant}` },
            " ",
            amount,
            " ")));
};
export default AmountField;
