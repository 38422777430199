import React, { useState, useReducer } from 'react';
import { isAPIErrors, isAPIValidationErrors, isTradeLoanSupplierAPIErrors } from '../../../types';
import { getBracketValues, isEmptyObject, removeDuplicateFiles, snakeToCamelCase } from '../../utils';
import { compileExistingFileNames } from '../../utils/tradeLoanFiles';
import { showNotifyToast as defaultShowNotifyToast } from '../../utils/notifyToast';
import { validateFileSize, validateTradeLoanDuplicateFile } from '../../validations/file';
import { createSupplier as defaultCreateSupplier, updateSupplier as defaultUpdateSupplier, removeSupplierFile as defaultRemoveSupplierFile } from '../../api/trade_loan_supplier';
import SupplierDetailsReducer, { defaultSupplierDetails, defaultPaymentDetails, defaultInvoiceDetails, defaultInvoiceAmountValues } from '../../reducers/trade-loans/SupplierDetailsReducer';
import SupplierDetailsEdit from '../../components/trade-loan/SupplierDetailsEdit';
import PaymentDetailsForm from '../../components/trade-loan/PaymentDetails';
import SpinnerButton from '../../components/shared/SpinnerButton';
const EditSingleSupplierDashboard = ({ apiToken, backLink, urlPath, existingSupplier, existingPaymentDetails, redirectLink, headerText = 'New Supplier', enableRedirect = true, createSupplier = defaultCreateSupplier, updateSupplier = defaultUpdateSupplier, removeSupplierFile = defaultRemoveSupplierFile, showNotifyToast = defaultShowNotifyToast }) => {
    const initialSupplierDetails = existingSupplier || defaultSupplierDetails;
    const initialPaymentDetails = existingPaymentDetails || defaultPaymentDetails;
    const initialState = {
        suppliers: [{ ...initialSupplierDetails }],
        selectedSupplierOptions: [null],
        selectedCurrencyOptions: [null],
        paymentDetailsList: [{ ...initialPaymentDetails }],
        supplierDetailsErrors: [{}],
        paymentDetailsErrors: [{}],
        invoices: [[{ ...defaultInvoiceDetails }]],
        selectedInvoiceCurrencyOptions: [[null]],
        invoiceAmountValues: [[{ ...defaultInvoiceAmountValues }]],
        invoiceErrors: [[{}]],
        invoiceDocumentErrors: [[{}]],
        supplierDocumentErrors: [{}]
    };
    const [suppliersState, dispatch] = useReducer(SupplierDetailsReducer, initialState);
    const [isSaving, setIsSaving] = useState(false);
    const { suppliers, paymentDetailsList, supplierDetailsErrors, paymentDetailsErrors, supplierDocumentErrors } = suppliersState;
    const handleSupplierDetailUpdate = (event) => {
        const targetName = event.target['name'];
        const [index, fieldName] = getBracketValues(targetName);
        const value = event.target['value'];
        dispatch({
            type: 'UPDATE_SUPPLIER_FIELD',
            index: parseInt(index),
            fieldName: snakeToCamelCase(fieldName),
            value
        });
    };
    const handleSupplierFileChange = (acceptedFiles, targetName) => {
        const [index, fieldName] = getBracketValues(targetName);
        const newFiles = [...acceptedFiles];
        const currentFiles = suppliers[index][fieldName] || [];
        const allFiles = removeDuplicateFiles([
            ...currentFiles,
            ...newFiles
        ]);
        dispatch({
            type: 'UPDATE_SUPPLIER_FILES',
            index: parseInt(index),
            fieldName,
            files: allFiles
        });
        validateSupplierDocumentsFrontend(allFiles, parseInt(index), fieldName);
    };
    const handleSupplierFileReplace = (event) => {
        const targetName = event.target['name'];
        const [index, fieldName, fileIndex] = getBracketValues(targetName);
        const files = event.target.files;
        const newFiles = [...files];
        const currentFiles = suppliers[index][fieldName] || [];
        const updatedFiles = removeDuplicateFiles([
            ...currentFiles.slice(0, parseInt(fileIndex)),
            ...newFiles,
            ...currentFiles.slice(parseInt(fileIndex) + 1, currentFiles.length)
        ]);
        dispatch({
            type: 'UPDATE_SUPPLIER_FILES',
            index: parseInt(index),
            fieldName,
            files: updatedFiles
        });
        validateSupplierDocumentsFrontend(updatedFiles, parseInt(index), fieldName);
    };
    const handleRemoveSupplierFile = (event) => {
        const targetName = event.target['name'];
        const [index, fieldName, fileIndex] = getBracketValues(targetName);
        const fieldFiles = suppliers[index][fieldName];
        const updatedFiles = [
            ...fieldFiles.slice(0, parseInt(fileIndex)),
            ...fieldFiles.slice(parseInt(fileIndex) + 1, fieldFiles.length)
        ];
        dispatch({
            type: 'UPDATE_SUPPLIER_FILES',
            index: parseInt(index),
            fieldName,
            files: updatedFiles
        });
        validateSupplierDocumentsFrontend(updatedFiles, parseInt(index), fieldName);
    };
    const generateFileErrors = (file, index) => {
        const fileSizeValidations = validateFileSize(file, 20000000)
            ? []
            : [`The file needs to be less than 20MB.`];
        const supplier = suppliers[index];
        const fileFieldNames = ['attachments'];
        const existingFileFieldNames = fileFieldNames.map(field => {
            return `existing_${field}`;
        });
        const existingFileNameList = compileExistingFileNames(supplier, existingFileFieldNames);
        const existingFileValidations = validateTradeLoanDuplicateFile(file, existingFileNameList)
            ? []
            : [`The file already exists.`];
        const validations = [...fileSizeValidations, ...existingFileValidations];
        return validations;
    };
    const totalErrors = (files, index) => {
        return files.reduce((acc, file) => {
            if (file) {
                const errors = generateFileErrors(file, index);
                if (errors.length > 0) {
                    return { ...acc, [file.name]: errors };
                }
                else {
                    return acc;
                }
            }
            else {
                return acc;
            }
        }, {});
    };
    const validateSupplierDocumentsFrontend = (files, index, fieldName) => {
        dispatch({
            type: 'UPDATE_SUPPLIER_DOCUMENT_ERRORS',
            index,
            fieldName,
            errors: totalErrors(files, index)
        });
    };
    const handleDeleteSupplierFile = (event) => {
        const targetName = event.target['name'];
        const [index, fieldName, fileIndex] = getBracketValues(targetName);
        removeExistingSupplierFile(fieldName, parseInt(index), parseInt(fileIndex));
    };
    const removeExistingSupplierFile = async (fieldName, index, fileIndex) => {
        const supplier = suppliers[index];
        const body = {
            fieldName,
            index: fileIndex
        };
        if (!supplier.id) {
            throw new Error('Not allowed to remove a supplier without a supplier id.');
        }
        if (window.confirm('Are you sure you want to delete this existing file?')) {
            try {
                const response = await removeSupplierFile(apiToken, urlPath, supplier.id, body);
                if (isAPIErrors(response) || isAPIValidationErrors(response)) {
                    throw response.errors;
                }
                const existingFieldName = `existing_${fieldName}`;
                const fieldFiles = suppliers[index][existingFieldName];
                const updatedFiles = [
                    ...fieldFiles.slice(0, fileIndex),
                    ...fieldFiles.slice(fileIndex + 1, fieldFiles.length)
                ];
                dispatch({
                    type: 'UPDATE_SUPPLIER_FILES',
                    index,
                    fieldName: existingFieldName,
                    files: updatedFiles
                });
                showNotifyToast({
                    text: 'Successfully deleted file.',
                    type: 'success'
                });
            }
            catch {
                showNotifyToast({
                    text: 'Failed to delete file. Please try again.',
                    type: 'error'
                });
            }
        }
    };
    const handlePaymentDetailUpdate = (event) => {
        const targetName = event.target['name'];
        const [index, fieldName] = getBracketValues(targetName);
        const value = event.target['value'];
        dispatch({
            type: 'UPDATE_PAYMENT_DETAILS_FIELD',
            index: parseInt(index),
            fieldName: snakeToCamelCase(fieldName),
            value
        });
    };
    const handleConfirmPaymentDetailsToggle = (event) => {
        const targetName = event.target['name'];
        const [index, fieldName] = getBracketValues(targetName);
        const value = event.target.checked;
        dispatch({
            type: 'UPDATE_PAYMENT_DETAILS_FIELD',
            index: parseInt(index),
            fieldName: snakeToCamelCase(fieldName),
            value
        });
    };
    const handleSave = () => {
        const index = 0;
        const supplier = suppliers[index];
        const documentErrors = supplierDocumentErrors[index];
        const noErrors = isEmptyObject(documentErrors);
        if (noErrors && !supplier.id) {
            addNewSupplier();
        }
        else if (noErrors && supplier.id) {
            updateExistingSupplier();
        }
        else {
            showNotifyToast({
                text: 'Please check if selected files are valid.',
                type: 'error'
            });
        }
    };
    const addNewSupplier = async () => {
        const index = 0;
        const supplier = suppliers[index];
        const paymentDetails = paymentDetailsList[index];
        const body = {
            supplierDetails: supplier,
            paymentDetails: paymentDetails
        };
        setIsSaving(true);
        try {
            const response = await createSupplier(apiToken, urlPath, body);
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            if (isTradeLoanSupplierAPIErrors(response)) {
                dispatch({
                    type: 'UPDATE_SINGLE_SUPPLIER_ERRORS',
                    index: index,
                    errors: response.supplierErrors
                });
                dispatch({
                    type: 'UPDATE_SINGLE_PAYMENT_DETAILS_ERRORS',
                    index: index,
                    errors: response.paymentDetailsErrors
                });
                showNotifyToast({
                    text: 'Please check if all fields are filled out correctly.',
                    type: 'error'
                });
            }
            else {
                // clear previous errors
                dispatch({
                    type: 'UPDATE_SINGLE_SUPPLIER_ERRORS',
                    index: index,
                    errors: {}
                });
                dispatch({
                    type: 'UPDATE_SINGLE_PAYMENT_DETAILS_ERRORS',
                    index: index,
                    errors: {}
                });
                if (enableRedirect && redirectLink) {
                    location.href = redirectLink;
                }
            }
        }
        catch {
            showNotifyToast({
                text: 'Failed to create supplier. Please try again.',
                type: 'error'
            });
        }
        setIsSaving(false);
    };
    const updateExistingSupplier = async () => {
        const index = 0;
        setIsSaving(true);
        const supplier = suppliers[index];
        const body = {
            supplierDetails: supplier,
            paymentDetails: paymentDetailsList[index]
        };
        if (!supplier.id) {
            throw new Error('Not allowed to update a supplier without a supplier id.');
        }
        try {
            const response = await updateSupplier(apiToken, urlPath, supplier.id, body);
            if (isAPIErrors(response)) {
                throw response.errors;
            }
            if (isTradeLoanSupplierAPIErrors(response)) {
                dispatch({
                    type: 'UPDATE_SINGLE_SUPPLIER_ERRORS',
                    index: index,
                    errors: response.supplierErrors
                });
                dispatch({
                    type: 'UPDATE_SINGLE_PAYMENT_DETAILS_ERRORS',
                    index: index,
                    errors: response.paymentDetailsErrors
                });
                showNotifyToast({
                    text: 'Please check if all fields are filled out correctly.',
                    type: 'error'
                });
            }
            else {
                // clear previous errors
                dispatch({
                    type: 'UPDATE_SINGLE_SUPPLIER_ERRORS',
                    index: index,
                    errors: {}
                });
                dispatch({
                    type: 'UPDATE_SINGLE_PAYMENT_DETAILS_ERRORS',
                    index: index,
                    errors: {}
                });
                dispatch({
                    type: 'REFRESH_SUPPLIER',
                    index: index,
                    supplierDetails: response.supplier
                });
                dispatch({
                    type: 'REFRESH_PAYMENT_DETAILS',
                    index: index,
                    paymentDetails: response.paymentDetails
                });
                showNotifyToast({
                    text: 'Successfully updated supplier.',
                    type: 'success'
                });
            }
        }
        catch {
            showNotifyToast({
                text: 'Failed to update supplier. Please try again.',
                type: 'error'
            });
        }
        setIsSaving(false);
    };
    return (React.createElement("div", { className: 'content custom-panel', "data-testid": 'edit-single-supplier-dashboard' },
        React.createElement("div", { className: 'header -space-between' },
            headerText,
            React.createElement("a", { href: backLink, className: 'button custom-button -back' }, "Go Back")),
        React.createElement("div", { className: 'body -padded' },
            React.createElement(SupplierDetailsEdit, { ...{
                    handleSupplierDetailUpdate,
                    handleSupplierFileChange,
                    handleRemoveSupplierFile,
                    handleSupplierFileReplace,
                    handleDeleteSupplierFile
                }, index: 0, currentSupplierDetails: suppliers[0], supplierDetailsErrors: supplierDetailsErrors[0], supplierDocumentErrors: supplierDocumentErrors[0] }),
            React.createElement(PaymentDetailsForm, { ...{
                    handlePaymentDetailUpdate,
                    handleConfirmPaymentDetailsToggle
                }, index: 0, currentPaymentDetails: paymentDetailsList[0], paymentDetailsErrors: paymentDetailsErrors[0] }),
            React.createElement("div", { className: 'actions' },
                React.createElement(SpinnerButton, { text: 'Save', className: 'custom-button button -trade-loan-save', handleClick: handleSave, isLoading: isSaving, testId: 'save-supplier' })))));
};
export default EditSingleSupplierDashboard;
