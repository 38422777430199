import React, { useState, useEffect, useRef } from 'react';
import Drawer from '../../shared/Drawer';
import Flatpickr from 'react-flatpickr';
import { fetchUnreconciledPayments } from '../../../api/payment';
import PaymentCard from './PaymentCard';
import { convertCents } from '../../../utils/convertCents';
import { formatPrice } from '../../../utils/formatPrice';
import { format } from 'date-fns';
const CashReceivedDrawer = ({ apiToken, companyId, submitPath, csrfToken, selectedPaymentsIds, isReconciled, startDate, endDate, totalCashReceivedFormatted }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [payments, setPayments] = useState();
    const [isDateRangeSelected, setIsDateRangeSelected] = useState(false);
    const [currentStartDate, setCurrentStartDate] = useState(startDate);
    const [currentEndDate, setCurrentEndDate] = useState(endDate);
    const [unSelectedPaymentsIds, setUnSelectedPaymentsIds] = useState([]);
    useEffect(() => {
        const fetchPayments = async () => {
            const status = isReconciled ? 'all' : 'unreconciled';
            const payments = await fetchUnreconciledPayments(apiToken, companyId, currentStartDate, currentEndDate, status);
            if (currentStartDate && currentEndDate) {
                setIsDateRangeSelected(true);
            }
            if (selectedPaymentsIds.length > 0) {
                setPayments(payments.map(payment => selectedPaymentsIds.includes(payment.id.toString())
                    ? { ...payment, isSelected: true }
                    : { ...payment, isSelected: false }));
            }
            else {
                setPayments(payments.map(payment => ({ ...payment, isSelected: true })));
            }
        };
        if (currentStartDate && currentEndDate) {
            fetchPayments();
        }
    }, []);
    // Handle when date range changed (not called on mount)
    const isInitialMount = useRef(true);
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        }
        else {
            const fetchPayments = async () => {
                if (!currentStartDate || !currentEndDate)
                    return;
                const payments = await fetchUnreconciledPayments(apiToken, companyId, currentStartDate, currentEndDate);
                setPayments(payments.map(payment => ({ ...payment, isSelected: true })));
            };
            fetchPayments();
        }
    }, [currentStartDate, currentEndDate]);
    const handleDateChange = (dateRange) => {
        if (dateRange.length === 2) {
            setCurrentStartDate(format(dateRange[0], 'yyyy-MM-dd'));
            setCurrentEndDate(format(dateRange[1], 'yyyy-MM-dd'));
            setIsDateRangeSelected(true);
        }
    };
    const totalOfSelectedPaymentsCents = (payments === null || payments === void 0 ? void 0 : payments.reduce((acc, payment) => {
        if (payment === null || payment === void 0 ? void 0 : payment.isSelected) {
            return acc + payment.amountCents;
        }
        else {
            return acc;
        }
    }, 0)) || 0;
    const selectedPaymentsCount = payments === null || payments === void 0 ? void 0 : payments.reduce((acc, payment) => {
        if (payment === null || payment === void 0 ? void 0 : payment.isSelected) {
            return acc + 1;
        }
        else {
            return acc;
        }
    }, 0);
    const totalSelectedPayments = convertCents(totalOfSelectedPaymentsCents);
    const totalSelectedPaymentsFormatted = formatPrice(totalSelectedPayments);
    const handleTogglePayment = (paymentClicked) => {
        // Prevent toggling of payments if ledger is already reconciled
        if (isReconciled)
            return;
        if (payments) {
            setPayments(payments.map(payment => payment.id === paymentClicked.id
                ? { ...payment, isSelected: !payment.isSelected }
                : payment));
        }
        if (paymentClicked.isSelected) {
            setUnSelectedPaymentsIds([...unSelectedPaymentsIds, paymentClicked.id]);
        }
        else {
            setUnSelectedPaymentsIds(unSelectedPaymentsIds.filter(id => id !== paymentClicked.id));
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'shadow-reconciliation-row -clickable', onClick: () => setIsOpen(true) },
            React.createElement("div", { className: 'label' },
                "Cash Received ",
                React.createElement("div", { className: 'custom-icon -clickable -open-outline' })),
            React.createElement("div", { className: 'value' }, isReconciled
                ? totalCashReceivedFormatted
                : totalSelectedPaymentsFormatted)),
        React.createElement(Drawer, { isOpen: isOpen, onClose: () => setIsOpen(false) },
            React.createElement("form", { action: submitPath, method: 'post' },
                React.createElement("input", { name: '_method', type: 'hidden', value: 'patch' }),
                React.createElement("input", { type: 'hidden', name: 'authenticity_token', "data-lpignore": 'true', value: csrfToken }),
                React.createElement("div", { className: 'cash-received-drawer' },
                    React.createElement("div", { className: 'actions' },
                        React.createElement("button", { className: 'back', onClick: () => setIsOpen(false) }, "Go Back")),
                    React.createElement("div", { className: 'header' }, "Cash Received"),
                    React.createElement(Flatpickr, { value: [currentEndDate, currentStartDate], onChange: handleDateChange, name: 'uploaded_ledger[payments_date_range]', className: 'input form-control', placeholder: 'Select a date range', disabled: isReconciled, options: { mode: 'range', altInput: true } }),
                    React.createElement("div", { className: 'separator' }),
                    React.createElement("div", { className: 'subheader' }, "List of Transactions"),
                    React.createElement("div", { className: 'row' },
                        React.createElement("div", { className: 'panel' },
                            React.createElement("div", { className: 'label' }, "Selected Transactions"),
                            React.createElement("div", { className: 'value' }, selectedPaymentsCount || 0)),
                        React.createElement("div", { className: 'panel' },
                            React.createElement("div", { className: 'label' }, "Total Selected Transactions"),
                            React.createElement("div", { className: 'value' }, totalSelectedPaymentsFormatted))),
                    React.createElement("div", { className: 'transactions' }, payments === null || payments === void 0 ? void 0 : payments.map(payment => (React.createElement(PaymentCard, { key: payment.id, payment: payment, handleTogglePayment: handleTogglePayment })))),
                    React.createElement("div", { className: 'actions' },
                        React.createElement("button", { className: 'button -cancel', onClick: () => setIsOpen(false) }, "Cancel"),
                        React.createElement("button", { className: 'button -apply', disabled: !isDateRangeSelected || isReconciled }, "Apply")))))));
};
export default CashReceivedDrawer;
