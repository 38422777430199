import React, { useState } from 'react';
import IconToggle from '../shared/IconToggle';
import { showNotifyToast } from '../../utils';
import { setRtgsRequiredOn, setRtgsRequiredOff } from '../../api/drawdown';
const RtgsToggle = ({ authToken, drawdown }) => {
    const { rtgsRequired, id } = drawdown;
    const [isOn, setIsOn] = useState(rtgsRequired);
    const [isToggleable, setIsToggleable] = useState(true);
    const setDrawdownRtgsOn = async () => {
        setIsToggleable(false);
        try {
            const { data } = await setRtgsRequiredOn(authToken, id);
            setIsOn(data.attributes.rtgsRequired);
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsToggleable(true);
    };
    const setDrawdownRtgsOff = async () => {
        setIsToggleable(false);
        try {
            const { data } = await setRtgsRequiredOff(authToken, id);
            setIsOn(data.attributes.rtgsRequired);
        }
        catch (err) {
            showNotifyToast({
                text: 'Something went wrong. Please try again.',
                type: 'error'
            });
        }
        setIsToggleable(true);
    };
    return (React.createElement("div", { className: 'rtgs-toggle', "data-testid": 'rtgs-toggle' },
        React.createElement(IconToggle, { ...{ isOn, isToggleable }, onIcon: 'check', offIcon: 'close', switchOff: () => setDrawdownRtgsOff(), switchOn: () => setDrawdownRtgsOn() })));
};
export default RtgsToggle;
