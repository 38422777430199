import React, { useRef, useState } from 'react';
import CustomFlatpickr from '../components/shared/CustomFlatpickr';
const BorrowerReports = ({ downloadPath }) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startdateClearRef = useRef(null);
    const startdatePickerRef = useRef(null);
    const enddateClearRef = useRef(null);
    const enddatePickerRef = useRef(null);
    const handleStartDateChange = (selectedDates, dateString) => {
        setStartDate(dateString);
    };
    const handleEndDateChange = (selectedDates, dateString) => {
        setEndDate(dateString);
    };
    const defaultSettings = {
        wrap: true,
        altInput: true,
        altFormat: 'd M Y',
        dateFormat: 'Y-m-d'
    };
    const startDateSettings = {
        ...defaultSettings,
        defaultDate: startDate,
        onChange: handleStartDateChange
    };
    const endDateSettings = {
        ...defaultSettings,
        defaultDate: endDate,
        onChange: handleEndDateChange
    };
    return (React.createElement("div", { className: 'custom-panel -commission -center' },
        React.createElement("div", { className: 'header -refresh -space-between' }, "Fees & Interest Report"),
        React.createElement("div", { className: 'body' },
            React.createElement("form", { className: 'form', method: 'GET', action: downloadPath },
                React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { className: 'form-control field -bare -date-picker' }, "Start Date"),
                    React.createElement(CustomFlatpickr, { selectedDate: startDate, name: 'start_date', flatpickrSettings: startDateSettings, forwardDatePickerRef: startdatePickerRef, forwardDateClearRef: startdateClearRef, clearButtonText: 'Clear Date', pickerBackground: 'neutral-20', placeholderText: '' })),
                React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { className: 'form-control field -bare -date-picker' }, "End Date"),
                    React.createElement(CustomFlatpickr, { selectedDate: endDate, name: 'end_date', flatpickrSettings: endDateSettings, forwardDatePickerRef: enddatePickerRef, forwardDateClearRef: enddateClearRef, clearButtonText: 'Clear Date', pickerBackground: 'neutral-20', placeholderText: '' })),
                React.createElement("div", { className: 'action-buttons' },
                    React.createElement("button", { type: 'submit', className: 'button -primary' }, "Download"))))));
};
export default BorrowerReports;
