import classNames from 'classnames';
import React from 'react';
const HeaderTab = ({ headerField, isLoading, sortBy, sortDirection, sortItems, headerWidth, checkboxOnChange }) => {
    const { defaultSort, field, type, checkboxRef } = headerField;
    const isActive = sortBy === field;
    const isAsc = sortDirection === 'asc';
    const isDesc = sortDirection === 'desc';
    const isTab = field !== undefined;
    const applyStyle = headerWidth && isLoading;
    const isCheckbox = type === 'checkbox';
    const headerClassName = classNames('header table-header', {
        '-sortable': isTab
    });
    const tabClassName = classNames('tab', {
        '-active': isActive,
        '-asc': isActive && isAsc,
        '-desc': isActive && isDesc
    });
    if (field) {
        return (React.createElement("th", { className: headerClassName, "data-testid": 'header-tab', style: applyStyle ? { width: headerWidth } : undefined },
            React.createElement("div", { className: tabClassName, "data-testid": 'tab', onClick: () => sortItems && sortItems(field, defaultSort) },
                React.createElement("div", { className: 'text' }, headerField.text))));
    }
    if (isCheckbox) {
        return (React.createElement("th", { className: headerClassName, "data-testid": 'header-checkbox' },
            React.createElement("input", { type: type, ref: checkboxRef, onChange: checkboxOnChange })));
    }
    return (React.createElement("th", { className: headerClassName, "data-testid": 'header-text', style: { width: headerWidth } }, headerField.text));
};
export default HeaderTab;
