import classNames from 'classnames';
import React, { useState } from 'react';
import useScrollEffect from '../../hooks/useScrollEffect';
import Item from '../vertical-navigation/Item';
const InvoicesEditSidebar = ({ navItems, invoiceCount }) => {
    const [currentScroll, setCurrentScroll] = useState(0);
    const handleScroll = () => {
        setCurrentScroll(window.pageYOffset);
    };
    useScrollEffect(handleScroll);
    const sidebarClass = classNames('trade-loan-invoices-sidebar -edit', {
        '-fixed': currentScroll > 144
    });
    return (React.createElement("div", { className: sidebarClass },
        React.createElement("div", { className: 'menu' },
            React.createElement("nav", { className: 'vertical-navigation navigation' }, navItems.map((item, i) => {
                return React.createElement(Item, { key: i, item: item, activeColor: 'blue-300' });
            }))),
        React.createElement("div", { className: 'widgets' },
            React.createElement("div", { className: 'trade-loan-counter counter -invoices' },
                React.createElement("div", { className: 'text' }, "Total Invoices"),
                React.createElement("div", { className: 'count' }, invoiceCount)))));
};
export default InvoicesEditSidebar;
