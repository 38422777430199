import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';
const Drawer = ({ isOpen, onClose, children }) => {
    useEffect(() => {
        // Prevents scrolling when modal is open
        isOpen
            ? (document.body.style.overflow = 'hidden')
            : (document.body.style.overflow = 'unset');
    }, [isOpen]);
    const onKeyDown = (event) => {
        // When esc key is pressed
        if (event.keyCode === 27 && isOpen) {
            onClose();
        }
    };
    useEffect(() => {
        document.addEventListener('keydown', onKeyDown, false);
        return () => {
            document.removeEventListener('keydown', onKeyDown, false);
        };
    }, [isOpen]);
    if (isOpen) {
        return ReactDOM.createPortal(React.createElement(FocusLock, { autoFocus: false },
            React.createElement("div", { className: 'drawer-container' },
                React.createElement("div", { className: 'body', tabIndex: -1 }, children),
                React.createElement("div", { className: 'background', onClick: () => onClose() }))), document.body);
    }
    return null;
};
export default Drawer;
