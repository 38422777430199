import React, { useState } from 'react';
import { showNotifyToast as defaultShowNotifyToast } from '../utils/notifyToast';
import { approve as defaultApprove, unapprove as defaultUnapprove } from '../api/invoice';
import ApprovalStatusToggle from '../components/invoices-table/ApprovalStatusToggle';
const ApprovalStatusToggleContainer = ({ authToken, borrowerId, invoiceId, isApproved, allowedForApprovalStatusToggle, disapprovalReasons, disapprovalReasonsOptions, approve = defaultApprove, unapprove = defaultUnapprove, showNotifyToast = defaultShowNotifyToast }) => {
    const [isCurrentlyApproved, setIsCurrentlyApproved] = useState(isApproved);
    const handleApprove = async (invoiceId) => {
        try {
            const response = await approve(authToken, borrowerId, invoiceId);
            const updatedInvoiceApproved = response.data.attributes.approved;
            setIsCurrentlyApproved(updatedInvoiceApproved);
        }
        catch (error) {
            if (error instanceof Error) {
                showNotifyToast({
                    text: error.message,
                    type: 'error'
                });
            }
        }
    };
    const handleUnapprove = async (invoiceId) => {
        try {
            const response = await unapprove(authToken, borrowerId, invoiceId);
            const updatedInvoiceUnapproved = response.data.attributes.approved;
            setIsCurrentlyApproved(updatedInvoiceUnapproved);
        }
        catch (error) {
            if (error instanceof Error) {
                showNotifyToast({
                    text: error.message,
                    type: 'error'
                });
            }
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ApprovalStatusToggle, { ...{
                invoiceId,
                allowedForApprovalStatusToggle,
                disapprovalReasons,
                disapprovalReasonsOptions
            }, isApproved: isCurrentlyApproved, handleApprove: handleApprove, handleUnapprove: handleUnapprove })));
};
export default ApprovalStatusToggleContainer;
