import React, { useState } from 'react';
import { sendOtp, verifyOtp } from '../../api/two_factor';
import { showNotifyToast } from '../../utils/notifyToast';
const TwoFactorStepOne = ({ apiToken, otpNumber, linkBack, incrementStep }) => {
    const [currentNumber, setCurrentNumber] = useState(otpNumber);
    const [verificationCode, setVerificationCode] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const handleSendOtp = async () => {
        try {
            const { status: status, message: message } = await sendOtp(apiToken, currentNumber);
            if (status === 'pending')
                setOtpSent(true);
            if (status === 'error') {
                showNotifyToast({
                    text: message,
                    type: 'error'
                });
            }
        }
        catch (err) {
            showNotifyToast({
                text: '2FA service failed to send OTP.',
                type: 'error'
            });
        }
    };
    const handleVerifyOtp = async () => {
        if (!otpSent)
            return;
        try {
            const { status: status, message: message } = await verifyOtp(apiToken, verificationCode, currentNumber);
            if (status === 'approved')
                incrementStep();
            if (status === 'error') {
                showNotifyToast({
                    text: message,
                    type: 'error'
                });
            }
        }
        catch (err) {
            showNotifyToast({
                text: 'The code you provided is invalid, Please try again.',
                type: 'error'
            });
        }
    };
    return (React.createElement("div", { className: 'custom-panel -left-align', "data-testid": 'two-factor-step-one' },
        React.createElement("div", { className: 'header' }, "Enter Phone Number"),
        React.createElement("div", { className: 'content' },
            React.createElement("p", null, "Enter your mobile phone number to receive a verification code via SMS when signing in to Earlypay."),
            React.createElement("br", null),
            React.createElement("b", null, "Phone Number"),
            React.createElement("div", { className: 'form-group -inline' },
                React.createElement("label", { className: 'label -m-0 -items-center -prepend-text' }, "+61 "),
                React.createElement("input", { type: 'text', className: 'input form-control -small', value: currentNumber, onChange: e => {
                        setCurrentNumber(e.target.value);
                    } }),
                React.createElement("button", { className: 'custom-button -default -header -notice -brand-a-300-text', onClick: handleSendOtp }, "Send Code")),
            otpSent ? (React.createElement(React.Fragment, null,
                React.createElement("p", { className: 'form-group -inline' },
                    React.createElement("span", { className: 'custom-icon -check -green-300' },
                        React.createElement("i", { className: 'icon' })),
                    ' ',
                    "Input the verification we sent to ",
                    currentNumber,
                    ". It may take a minute for the SMS to arrive."),
                React.createElement("b", null, "Verification Code"),
                React.createElement("div", { className: 'form-group' },
                    React.createElement("input", { type: 'number', placeholder: 'Enter verification code here', className: 'input form-control -medium', value: verificationCode, onChange: e => {
                            setVerificationCode(e.target.value);
                        } })))) : null),
        React.createElement("div", { className: 'actions custom-action' },
            React.createElement("div", { className: 'action -no-pad' },
                React.createElement("button", { onClick: handleVerifyOtp, className: 'custom-button -default' }, "Continue")),
            React.createElement("div", { className: 'action -no-pad' },
                React.createElement("a", { href: linkBack, className: 'custom-button -outline -margin-right-8px' }, "Cancel")))));
};
export default TwoFactorStepOne;
