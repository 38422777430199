import React, { useEffect, useRef, useState } from 'react';
import { fetchEvents } from '../api/event';
import { removeDuplicates } from '../utils/removeDuplicates';
import classNames from 'classnames';
import ActivitiesContext from '../contexts/ActivitiesContext';
import ActivityFeed from '../components/activity-feed/ActivityFeed';
import BrokerActivityFilter from '../components/activity-feed/BrokerActivityFilter';
import PostInputPanel from '../containers/post-input-panel/PostInputPanel';
const BrokerActivities = ({ apiToken, userAvatarUrl }) => {
    const initialActiveFilters = {
        client_invitations: false,
        commissions_updates: false,
        drawdowns: false,
        post_activities: false
    };
    const [events, setEvents] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [currentFilters, setCurrentFilters] = useState([]);
    const [activeFilters, setActiveFilters] = useState(initialActiveFilters);
    const dateClearRef = useRef(null);
    const datePickerRef = useRef(null);
    const [dateFilter, setDateFilter] = useState('');
    const scope = 'broker';
    const nextFetches = () => {
        if (hasMore === true) {
            handleFetchEvents(page);
            setPage(page + 1);
        }
    };
    const handleFetchEvents = async (currentPage) => {
        try {
            const response = await fetchEvents(apiToken, scope, currentPage, currentFilters, dateFilter);
            if (response.last_page === true) {
                setHasMore(false);
            }
            setEvents(prevEvents => removeDuplicates([...prevEvents, ...response.data]));
            setHasErrors(false);
        }
        catch (err) {
            setHasErrors(true);
        }
        setIsLoading(false);
    };
    const context = {
        apiToken,
        feedType: 'broker'
    };
    const handleFilterToggle = (event) => {
        const isChecked = event.target.checked;
        setActiveFilters({
            ...activeFilters,
            [event.target.name]: isChecked
        });
    };
    const applyFilters = () => {
        const activeFilterKeys = Object.keys(activeFilters);
        const appliedFilters = activeFilterKeys.reduce((acc, key) => {
            if (activeFilters[key] === true) {
                return [...acc, key];
            }
            return acc;
        }, []);
        setCurrentFilters(appliedFilters);
    };
    const clearFilters = () => {
        if (dateClearRef && dateClearRef.current) {
            dateClearRef.current.click();
        }
        setActiveFilters({ ...initialActiveFilters });
        setCurrentFilters([]);
    };
    const handleFeedRefresh = () => {
        clearFilters();
    };
    const handleDateFilterChange = (selectedDates, dateString) => {
        setDateFilter(dateString);
    };
    const [isOpened, setIsOpened] = useState(false);
    const togglePanel = (event) => {
        event.preventDefault();
        setIsOpened(!isOpened);
    };
    const buttonClass = () => {
        if (isOpened) {
            return classNames('icon-button button', '-filter', '-icon-filter-neutral-500', '-text-neutral-500', '-border-neutral-60', '-rectangular', '-background-neutral-0', '-active');
        }
        else {
            return classNames('icon-button button', '-filter', '-icon-filter-neutral-500', '-text-neutral-500', '-border-neutral-60', '-rectangular', '-background-neutral-0');
        }
    };
    const flatpickrSettings = {
        wrap: true,
        dateFormat: 'd/m/Y',
        onChange: handleDateFilterChange
    };
    useEffect(() => {
        handleFetchEvents(1);
    }, []);
    useEffect(() => {
        setIsLoading(true);
        setPage(2);
        setEvents([]);
        setHasMore(true);
        handleFetchEvents(1);
    }, [currentFilters, dateFilter]);
    return (React.createElement("div", { className: 'activities-container activities -broker' },
        React.createElement(ActivitiesContext.Provider, { value: context },
            React.createElement("div", { className: 'body' },
                React.createElement("div", { className: 'filter' },
                    React.createElement("h1", { className: 'title -main' }, "Your Dashboard"),
                    React.createElement("div", { className: 'toggle-panel -filter-dropdown -broker', "data-testid": 'filter-dropdown' },
                        React.createElement("a", { href: '#', className: buttonClass(), onClick: togglePanel },
                            React.createElement("div", { className: 'icon' }),
                            React.createElement("div", { className: 'text' }, "Filters")),
                        React.createElement(BrokerActivityFilter, { ...{
                                isOpened,
                                activeFilters,
                                dateFilter,
                                flatpickrSettings,
                                applyFilters,
                                clearFilters,
                                handleFilterToggle
                            }, forwardDateClearRef: dateClearRef, forwardDatePickerRef: datePickerRef }))),
                React.createElement(PostInputPanel, { ...{ apiToken, scope, userAvatarUrl }, handleBrokerFeedRefresh: handleFeedRefresh }),
                React.createElement(ActivityFeed, { ...{
                        events,
                        isLoading,
                        hasErrors,
                        hasMore,
                        nextFetches,
                        userAvatarUrl
                    } })))));
};
export default BrokerActivities;
