import React, { useState } from 'react';
import classNames from 'classnames';
import Panel from './Panel';
const ReportDownload = ({ csvUrl, pdfUrl, excelUrl }) => {
    const [isOpened, setIsOpened] = useState(false);
    const togglePanel = () => setIsOpened(!isOpened);
    const buttonClass = classNames('icon-button button', '-filter', '-icon-filter-neutral-500', '-text-neutral-500', '-border-neutral-60', '-rectangular', '-background-neutral-0', '-active');
    return (React.createElement("div", { className: 'toggle-panel report-download -modal -report-download -report', "data-testid": 'report-download' },
        React.createElement("button", { className: buttonClass, onClick: togglePanel },
            React.createElement("div", { className: 'text' }, "Download Report"),
            React.createElement("div", { className: 'caret' })),
        React.createElement(Panel, { ...{
                isOpened,
                csvUrl,
                pdfUrl,
                excelUrl
            } })));
};
export default ReportDownload;
