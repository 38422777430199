import React, { useState, useReducer } from 'react';
import classNames from 'classnames';
import CommissionRatesUpfront from './CommissionRatesUpfront';
import CommissionRatesTrailing from './CommissionRatesTrailing';
import AggregatorRatesTrailing from './AggregatorRatesTrailing';
import CommissionRatesSummary from './CommissionRatesSummary';
import FormInputs from './FormInputs';
import FormButtons from './FormButtons';
import StepReducer from '../../reducers/StepReducer';
const STEPS = ['crUpfront', 'crTrailing', 'arTrailing', 'summary'];
const CommissionRatesForm = ({ csrfToken, path, cancelPath, defaultCurrentStep }) => {
    const [{ currentStep }, stepDispatch] = useReducer(StepReducer, {
        steps: STEPS,
        currentStep: defaultCurrentStep || STEPS[0]
    });
    const [upfrontCommission, setUpfrontCommission] = useState();
    const [gstIncluded, setGstIncluded] = useState(false);
    const [receivedApproval, setReceivedApproval] = useState(false);
    const [crTrailingFeeRate, setCrTrailingFeeRate] = useState();
    const [crTrailingInterestRate, setCrTrailingInterestRate] = useState();
    const [arTrailingFeeRate, setArTrailingFeeRate] = useState();
    const [arTrailingInterestRate, setArTrailingInterestRate] = useState();
    const [arTrailingExpiryDate, setArTrailingExpiryDate] = useState();
    const handleSubmit = (e) => {
        const lastStep = STEPS[STEPS.length - 1];
        if (currentStep === lastStep)
            return;
        e.preventDefault();
        stepDispatch('next');
    };
    const handleBack = () => {
        stepDispatch('back');
    };
    const panelClass = classNames('custom-panel', {
        '-show': currentStep !== 'summary',
        '-summary': currentStep === 'summary'
    });
    const headerText = () => {
        switch (currentStep) {
            case 'crUpfront':
                return 'Commission Rates (Upfront)';
            case 'crTrailing':
                return 'Commission Rates (Trailing)';
            case 'arTrailing':
                return 'Aggregator Rates (Trailing)';
            case 'summary':
                return 'Review Details';
            default:
                return '';
        }
    };
    return (React.createElement("div", { className: panelClass, "data-testid": 'commission-rates-form' },
        React.createElement("div", { className: 'header' }, headerText()),
        React.createElement("div", { className: 'content' },
            React.createElement("form", { className: 'form', method: 'post', action: path, onSubmit: handleSubmit, id: currentStep + '-form' },
                currentStep === 'crUpfront' && (React.createElement(CommissionRatesUpfront, { ...{
                        upfrontCommission,
                        gstIncluded,
                        receivedApproval,
                        setUpfrontCommission,
                        setGstIncluded,
                        setReceivedApproval
                    } })),
                currentStep === 'crTrailing' && (React.createElement(CommissionRatesTrailing, { ...{
                        crTrailingFeeRate,
                        crTrailingInterestRate,
                        setCrTrailingFeeRate,
                        setCrTrailingInterestRate
                    } })),
                currentStep === 'arTrailing' && (React.createElement(AggregatorRatesTrailing, { ...{
                        arTrailingFeeRate,
                        arTrailingInterestRate,
                        arTrailingExpiryDate,
                        setArTrailingFeeRate,
                        setArTrailingInterestRate,
                        setArTrailingExpiryDate
                    } })),
                currentStep === 'summary' && (React.createElement(FormInputs, { ...{
                        csrfToken,
                        upfrontCommission,
                        gstIncluded,
                        receivedApproval,
                        crTrailingFeeRate,
                        crTrailingInterestRate,
                        arTrailingFeeRate,
                        arTrailingInterestRate,
                        arTrailingExpiryDate
                    } }))),
            currentStep === 'summary' && (React.createElement(CommissionRatesSummary, { ...{
                    upfrontCommission,
                    gstIncluded,
                    receivedApproval,
                    crTrailingFeeRate,
                    crTrailingInterestRate,
                    arTrailingFeeRate,
                    arTrailingInterestRate,
                    arTrailingExpiryDate
                } })),
            React.createElement(FormButtons, { steps: STEPS, ...{
                    currentStep,
                    cancelPath,
                    handleBack
                } }))));
};
export default CommissionRatesForm;
