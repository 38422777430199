import React, { useState } from 'react';
import ToggleSwitch from '../shared/ToggleSwitch';
import { setUnverified as defaultSetUnverified, setVerified as defaultSetVerified, approve as defaultApprove, unapprove as defaultUnapprove, updateDisapproval as defaultUpdateDisapproval } from '../../api/invoice';
import { showNotifyToast } from '../../utils/notifyToast';
import Select from 'react-select';
import SpinnerButton from '../shared/SpinnerButton';
const InvoicesDisapproval = ({ apiToken, borrowerId, invoice, isApproved, invoiceDisapprovalReasonOptions, setUnverified = defaultSetUnverified, setVerified = defaultSetVerified, approve = defaultApprove, unapprove = defaultUnapprove, updateDisapproval = defaultUpdateDisapproval }) => {
    const [invoiceData, setInvoiceData] = useState(invoice);
    const [isApprovalToggleOn, setIsApprovalToggleOn] = useState(isApproved);
    const [isEditing, setIsEditing] = useState(false);
    const [reasons, setReasons] = useState(invoiceData.disapprovalReasons);
    const errorMessage = 'Something went wrong. Please try again.';
    const handleCancel = () => {
        setIsEditing(false);
        setReasons(invoiceData.disapprovalReasons);
    };
    const handleEdit = () => {
        setIsEditing(true);
    };
    const handleSave = async () => {
        try {
            const body = {
                disapprovalReasons: reasons
            };
            const response = await updateDisapproval(apiToken, borrowerId, invoiceData.id, body);
            if (response.data) {
                setInvoiceData(response.data.attributes);
                showNotifyToast({
                    text: 'Successfully updated invoice disapproval reason.',
                    type: 'success'
                });
            }
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        setIsEditing(false);
    };
    const updateInvoiceData = (updatedInvoice) => {
        const updatedData = {
            ...invoiceData,
            approval_status: updatedInvoice.approvalStatus,
            verified: updatedInvoice.verified
        };
        setInvoiceData(updatedData);
        setIsApprovalToggleOn(updatedInvoice.approved);
    };
    const verifyInvoiceHandler = async () => {
        try {
            const { data } = await setVerified(apiToken, borrowerId, invoiceData.id);
            updateInvoiceData(data.attributes);
            if (data) {
                showNotifyToast({
                    text: 'Successfully updated invoice.',
                    type: 'success'
                });
            }
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
    };
    const unverifyInvoiceHandler = async () => {
        try {
            const { data } = await setUnverified(apiToken, borrowerId, invoiceData.id);
            updateInvoiceData(data.attributes);
            if (data) {
                showNotifyToast({
                    text: 'Successfully updated invoice.',
                    type: 'success'
                });
            }
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
    };
    const approveInvoiceHandler = async () => {
        try {
            const { data } = await approve(apiToken, borrowerId, invoiceData.id);
            updateInvoiceData(data.attributes);
            if (data) {
                showNotifyToast({
                    text: 'Successfully updated invoice',
                    type: 'success'
                });
            }
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
    };
    const unapproveInvoiceHandler = async () => {
        try {
            const { data } = await unapprove(apiToken, borrowerId, invoiceData.id);
            updateInvoiceData(data.attributes);
            if (data) {
                showNotifyToast({
                    text: 'Successfully updated invoice.',
                    type: 'success'
                });
            }
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
    };
    const selectedDisapprovalReasonsOptions = invoiceDisapprovalReasonOptions.filter((option) => {
        return reasons.includes(option.value);
    });
    const handleDisapprovalReasonsChange = (selectedOptions) => {
        const updatedDisapprovalReasons = selectedOptions === null ? [] : selectedOptions.map(o => o.value);
        setReasons(updatedDisapprovalReasons);
    };
    return (React.createElement("div", { className: 'custom-panel invoice-panel reminder' },
        React.createElement("div", { className: 'header -space-between' }, "Status"),
        React.createElement("table", { className: 'custom-table -bottom-border' },
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Verify"),
                    React.createElement("td", { className: 'value' },
                        React.createElement(ToggleSwitch, { isToggled: invoiceData.verified, switchOn: () => verifyInvoiceHandler(), switchOff: () => unverifyInvoiceHandler() }))),
                React.createElement("tr", null,
                    React.createElement("td", { className: 'vertical' }, "Approved"),
                    React.createElement("td", { className: 'cell -right' },
                        React.createElement(ToggleSwitch, { isToggled: isApprovalToggleOn, switchOn: () => approveInvoiceHandler(), switchOff: () => unapproveInvoiceHandler() }))))),
        isEditing && (React.createElement("div", { className: 'form' },
            React.createElement(Select, { className: 'disapproval', isMulti: true, options: invoiceDisapprovalReasonOptions, value: selectedDisapprovalReasonsOptions, onChange: handleDisapprovalReasonsChange }),
            React.createElement("div", { className: 'action-buttons actions' },
                React.createElement("button", { type: 'button', className: 'button -cancel', onClick: handleCancel, "data-testid": 'cancel-edit' }, "Cancel"),
                React.createElement(SpinnerButton, { text: 'Save', buttonType: 'button', className: 'button -primary', testId: 'save-button', handleClick: handleSave })))),
        !isEditing && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'info' },
                React.createElement("div", { className: 'heading' }, "Disapproval Reasons"),
                React.createElement("a", { href: '#', className: 'add', onClick: handleEdit, "data-testid": 'edit-action' }, "Add Reasons")),
            React.createElement("ul", { className: 'items' }, selectedDisapprovalReasonsOptions.map((reason, index) => {
                return (React.createElement("li", { className: 'badge', key: index }, reason.label));
            }))))));
};
export default InvoicesDisapproval;
