import React, { useState } from 'react';
import Modal from '../../components/shared/Modal';
const UndoOneOffFee = ({ amount, showUndo, route, feeId }) => {
    const [isVisible, setIsVisible] = useState(false);
    const toggleModal = () => {
        isVisible ? setIsVisible(false) : setIsVisible(true);
    };
    return (React.createElement("div", null,
        showUndo && (React.createElement("button", { className: 'icon-button -undo -rectangular -background-neutral-20 -undo-neutral-50', type: 'button', onClick: () => toggleModal() },
            React.createElement("i", { className: 'icon' }),
            React.createElement("div", { className: 'text' }, "Undo"))),
        React.createElement(Modal, { isOpen: isVisible, onClose: toggleModal },
            React.createElement("div", { className: 'custom-panel -modal -confirmation', role: 'dialog', "aria-modal": 'true' },
                React.createElement("div", { className: 'header' }, "Confirmation"),
                React.createElement("div", { className: 'content' },
                    React.createElement("div", { className: 'section' },
                        React.createElement("div", { className: 'description' },
                            "Are you sure you want to reverse the one off fee of",
                            ' ',
                            React.createElement("span", { className: 'value' }, amount),
                            "?"))),
                React.createElement("div", { className: 'footer' },
                    React.createElement("div", { className: 'action-buttons buttons space' },
                        React.createElement("button", { className: 'button -reset -transparent', onClick: toggleModal }, "Cancel"),
                        React.createElement("a", { className: 'button -primary', "data-method": 'delete', href: `${route}/${feeId}`, "data-testid": 'modal-submit' }, "Yes, I'm sure")))))));
};
export default UndoOneOffFee;
