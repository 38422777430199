import React from 'react';
const AggregatorRatesTrailing = ({ arTrailingFeeRate, arTrailingInterestRate, arTrailingExpiryDate, setArTrailingFeeRate, setArTrailingInterestRate, setArTrailingExpiryDate }) => {
    return (React.createElement("div", { "data-testid": 'aggregator-rates-trailing' },
        React.createElement("div", { className: 'form-group -description' }, "This step is optional. Click the Next button if you're done to review the details."),
        React.createElement("div", { className: 'form-row -col-2' },
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, "Admin Fee Commission (%)"),
                React.createElement("input", { className: 'input form-control', name: 'commission_details[aggregator_trailing_fee_commission_rate]', type: 'number', step: 'any', defaultValue: arTrailingFeeRate, onChange: e => setArTrailingFeeRate(Number(e.target.value)), "data-testid": 'ar-fee-input' })),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, "Interest Commission (%)"),
                React.createElement("input", { className: 'input form-control', name: 'commission_details[aggregator_trailing_interest_commission_rate]', type: 'number', step: 'any', defaultValue: arTrailingInterestRate, onChange: e => setArTrailingInterestRate(Number(e.target.value)), "data-testid": 'ar-interest-input' })),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, "Expiry Date"),
                React.createElement("input", { className: 'input form-control', name: 'commission_details[aggregator_trailing_expiry_date]', type: 'date', defaultValue: arTrailingExpiryDate, onChange: e => setArTrailingExpiryDate(e.target.value), "data-testid": 'ar-expiry-date-input' })))));
};
export default AggregatorRatesTrailing;
