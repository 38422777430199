import React from 'react';
const FundingSummaryTable = ({ figures, headerText }) => {
    return (React.createElement("div", { className: 'funding-summary-table summary', "data-testid": 'funding-summary-table' },
        React.createElement("div", { className: 'custom-panel' },
            React.createElement("div", { className: 'header' }, headerText),
            React.createElement("table", { className: 'custom-table table' },
                React.createElement("tbody", null, figures.map((figure, i) => (React.createElement("tr", { className: 'figure', key: i, "data-testid": 'funding-figure' },
                    React.createElement("td", { className: 'vertical' }, figure.label),
                    React.createElement("td", { className: 'value' }, figure.value)))))))));
};
export default FundingSummaryTable;
