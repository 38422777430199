import React from 'react';
import ImagePreview from './ImagePreview';
import NonImagePreview from './NonImagePreview';
import { isImageFile } from '../../utils';
const PreviewGallery = ({ files, fileErrors, deleteList, removeFile }) => {
    return (React.createElement("div", { className: 'preview-gallery previews', "data-testid": 'preview-gallery' }, files.map((file, index) => {
        if (!deleteList.includes(index)) {
            if (isImageFile(file)) {
                return (React.createElement(ImagePreview, { ...{ file, fileErrors, index, removeFile }, key: index }));
            }
            else {
                return (React.createElement(NonImagePreview, { ...{ file, fileErrors, index, removeFile }, key: index }));
            }
        }
    })));
};
export default PreviewGallery;
