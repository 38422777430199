/* eslint-disable */
import Swal from 'sweetalert2'

const document = window.document

document.addEventListener('DOMContentLoaded', function() {
  const surveyUrl = document.body.dataset.surveyUrl
  const csrfToken = document.body.dataset.csrfToken
  const userCompletedSurvey =
    document.body.dataset.userCompletedSurvey === 'true'

  function showCloseButton() {
    Swal.fire({
      text: 'Thank you for completing our survey.',
      icon: 'success',
      confirmButtonText: 'Close',
      confirmButtonColor: '#3085d6'
    }).then(() => {
      Swal.close()
    })
  }

  if (surveyUrl && !userCompletedSurvey) {
    Swal.fire({
      text: 'Please take a moment to complete our survey.',
      html: `<iframe src="${surveyUrl}" width="100%" height="950" frameborder="0"></iframe>`,
      showConfirmButton: false,
      width: '1200px'
    })

    window.addEventListener('message', function(event) {
      if (
        event.origin === 'https://www.surveymonkey.com' &&
        event.data === '{"eventName":"sm:survey_completed"}'
      ) {
        fetch('/update_survey_completion', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
          },
          body: JSON.stringify({
            survey_completed_at: new Date().toISOString()
          })
        })
          .then(response => {
            if (response.ok) {
              console.log('Survey completion date updated successfully.')
              showCloseButton()
            } else {
              console.error('Failed to update survey completion date.')
            }
          })
          .catch(error => {
            console.error('Error:', error)
          })
      }
    })
  }
})
