import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import { ComputeClientCommission } from '../../api/commission';
import { dropdownStyles } from '../../custom-styles/dropdown';
import { showNotifyToast } from '../../utils';
import format from 'date-fns/format';
const CommissionDetails = ({ isPreview = false, broker, apiToken, monthOption, clientOptions = [], selectedClients, setSelectedClients, totalTally, setTotalTally }) => {
    const [clientsDropdown, setClientDropdown] = useState([]);
    useEffect(() => {
        updateTotalTally();
    }, [selectedClients]);
    useEffect(() => {
        const options = clientOptions.map(client => {
            return {
                value: client.id.toString(),
                label: client.displayedName,
                isDisabled: client.isDisabled
            };
        });
        setClientDropdown(options);
    }, [clientOptions]);
    const updateTotalTally = () => {
        const tally = Object.values(selectedClients)
            .filter(data => {
            return data.status === 'ok';
        })
            .reduce((prev, current) => {
            const amount = parseFloat(prev.amount) + parseFloat(current.amount);
            const gstAmount = parseFloat(prev.gstAmount) + parseFloat(current.gstAmount);
            return {
                amount: amount.toFixed(2),
                gstAmount: gstAmount.toFixed(2),
                all: (amount + gstAmount).toFixed(2)
            };
        }, { amount: '0', gstAmount: '0', all: '0.00' });
        setTotalTally(tally);
    };
    const computeAmount = async (id) => {
        try {
            const targetDate = format(new Date(monthOption.value), `yyyy-MM-dd`);
            const response = await ComputeClientCommission(apiToken, broker.id, id, targetDate);
            return response;
        }
        catch (err) {
            showNotifyToast({
                text: `Something went wrong while computing client's commissions. Please try again.`,
                type: 'error'
            });
            return { amount: '0', gst: '0', status: 'failed' };
        }
    };
    const addCommissionClient = () => {
        const entries = Object.entries(selectedClients).length;
        setSelectedClients({
            ...selectedClients,
            [`item${entries}`]: {
                id: null,
                amount: '0',
                gstAmount: '0',
                status: 'initial'
            }
        });
    };
    const subTotal = ({ amount, gstAmount }) => {
        return (parseFloat(amount) + parseFloat(gstAmount)).toFixed(2);
    };
    const buildLabel = (data) => {
        const selected = clientsDropdown.filter(({ value }) => value === data.id.toString());
        return selected.length ? selected[0].label : '';
    };
    const handleDelete = (target) => {
        setSelectedClients({
            ...selectedClients,
            [`${target}`]: { status: 'deleted' }
        });
    };
    const handleChangeClient = async (selected, target) => {
        const { amount, gst } = await computeAmount(selected.value);
        const formattedData = {
            id: selected.value,
            amount: amount,
            gstAmount: gst,
            status: 'ok'
        };
        setSelectedClients({ ...selectedClients, [`${target}`]: formattedData });
    };
    const handleAmountChange = (new_amount, target) => {
        const currentData = selectedClients[`${target}`];
        const updatedData = {
            ...currentData,
            amount: new_amount,
            gstAmount: parseFloat(new_amount) * 0.1
        };
        setSelectedClients({ ...selectedClients, [`${target}`]: updatedData });
    };
    const handleGstChange = (newGstAmount, target) => {
        const currentData = selectedClients[`${target}`];
        const updatedData = { ...currentData, gstAmount: newGstAmount };
        setSelectedClients({ ...selectedClients, [`${target}`]: updatedData });
    };
    return (React.createElement("table", { className: 'commission-table', "data-testid": 'commission-details' },
        React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement("th", null, "Client"),
                React.createElement("th", null, "Amount"),
                React.createElement("th", null, "GST"),
                React.createElement("th", null, "Total (inc GST)"),
                React.createElement("th", null))),
        React.createElement("tbody", null,
            Object.entries(selectedClients)
                .filter(([_key, data]) => data.status === 'ok' || (!isPreview && data.status === 'initial'))
                .map(([key, data]) => {
                return (React.createElement("tr", { key: key, id: key },
                    React.createElement("td", null, isPreview ? (React.createElement("p", null, buildLabel(data))) : (React.createElement(Select, { options: clientsDropdown, className: 'select', styles: dropdownStyles, onChange: (e) => {
                            handleChangeClient(e, key);
                        }, name: 'broker_client', value: clientsDropdown.filter(({ value }) => value === data.id), isDisabled: isPreview }))),
                    React.createElement("td", null,
                        React.createElement(NumberFormat, { className: `input form-control -right ${!isPreview &&
                                '-bare-padded'}`, thousandSeparator: true, allowNegative: false, decimalScale: 2, value: data.amount, readOnly: !isPreview, onValueChange: e => {
                                handleAmountChange(e.value, key);
                            } })),
                    React.createElement("td", null,
                        React.createElement(NumberFormat, { className: `input form-control -right ${!isPreview &&
                                '-bare-padded'}`, thousandSeparator: true, allowNegative: false, decimalScale: 2, value: data.gstAmount, readOnly: !isPreview, onValueChange: e => {
                                handleGstChange(e.value, key);
                            } })),
                    React.createElement("td", null,
                        React.createElement("div", { className: 'total' }, subTotal(data))),
                    React.createElement("td", null, !isPreview && (React.createElement("button", { type: 'button', className: 'icon-button -trash-red-500 -square -border-red-75', onClick: () => {
                            handleDelete(key);
                        } },
                        React.createElement("i", { className: 'icon' }))))));
            }),
            !isPreview && (React.createElement("tr", null,
                React.createElement("td", { colSpan: 5 },
                    React.createElement("button", { type: 'button', className: 'custom-button button -add-commission', onClick: addCommissionClient }, "Add Commission"))))),
        React.createElement("tfoot", null,
            React.createElement("tr", null,
                React.createElement("td", null, "Total"),
                React.createElement("td", null,
                    React.createElement("span", { className: 'tally' }, totalTally.amount)),
                React.createElement("td", null,
                    React.createElement("span", { className: 'tally' }, totalTally.gstAmount)),
                React.createElement("td", null,
                    React.createElement("span", null, totalTally.all))))));
};
export default CommissionDetails;
