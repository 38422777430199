import classNames from 'classnames';
import React from 'react';
import { buildCompanySubtitle, buildCompanyDisplayedName } from '../../utils/hit';
const CompanyHitDisplay = ({ hit }) => {
    const { name, path, status, displayName, useDisplayName } = hit;
    const statusText = status || '';
    const displayStatus = statusText.replace('_', ' ').toUpperCase();
    const statusModifier = statusText.replace('_', '-').toLowerCase();
    const statusClassName = classNames('hit-status status', {
        '-company': true,
        [`-${statusModifier}`]: true
    });
    const titleText = buildCompanyDisplayedName(name, displayName, useDisplayName);
    const subtitleText = buildCompanySubtitle(name, displayName, useDisplayName);
    return (React.createElement("div", { className: 'search-hit hit -company', "data-testid": 'company-hit' },
        React.createElement("a", { href: path, className: 'body link' },
            React.createElement("div", { className: 'icon' }),
            React.createElement("div", { className: 'details' },
                React.createElement("div", { className: 'heading' }, titleText),
                React.createElement("div", { className: 'text' }, subtitleText)),
            React.createElement("div", { className: statusClassName }, displayStatus))));
};
export default CompanyHitDisplay;
