import React from 'react';
import PaginationBar from '../../components/shared/PaginationBar';
const AdjustmentsTable = ({ adjustments, isLoading, handlePageClick, currentPage, pageSize, totalPages, totalItems }) => {
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'adjustments-table' },
        isLoading ? (React.createElement("table", { className: 'custom-table' },
            React.createElement("tbody", { "data-testid": 'loading' },
                React.createElement("tr", null,
                    React.createElement("td", { className: 'cell', colSpan: 12 },
                        React.createElement("div", { className: 'loading-container -table' },
                            React.createElement("div", { className: 'loading-spinner' }))))))) : (React.createElement("table", { className: 'custom-table' },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { className: 'header' }, "Date"),
                    React.createElement("th", { className: 'header' }, "Created By"),
                    React.createElement("th", { className: 'header' }, "Amount"),
                    React.createElement("th", { className: 'header' }, "Reason"))),
            React.createElement("tbody", null, adjustments.length !== 0 ? (adjustments.map((adjustment, i) => (React.createElement("tr", { key: i },
                React.createElement("td", { className: 'cell' }, adjustment.recordDateFormatted),
                React.createElement("td", { className: 'cell' }, adjustment.createdBy),
                React.createElement("td", { className: 'cell' }, adjustment.amountFormatted),
                React.createElement("td", { className: 'cell' }, adjustment.reason))))) : (React.createElement("tr", { "data-testid": 'no-record' },
                React.createElement("td", { className: 'placeholder', colSpan: 12 }, "There are no transactions to display")))))),
        totalItems > pageSize && (React.createElement(PaginationBar, { ...{
                currentPage,
                pageSize,
                totalPages,
                totalItems,
                handlePageClick
            }, resource: 'credit_notes' }))));
};
export default AdjustmentsTable;
