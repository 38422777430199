import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import InvoiceBatchesList from './InvoiceBatchesList';
import ProgressBar from '../../components/progress-bar/ProgressBar';
const InvoiceBatchesContainer = ({ invoiceBatches, invoiceBatchPath }) => {
    return (React.createElement("div", { className: 'invoice-batch-container', "data-test-id": 'invoice-batches-container' },
        React.createElement("div", { className: 'widget' },
            React.createElement("div", { className: 'custom-panel' },
                React.createElement("div", { className: 'header' }, "Total Verification"),
                React.createElement("div", { className: 'content' },
                    React.createElement(ProgressBar, { percentage: 60 }),
                    React.createElement("div", { className: 'text' })))),
        React.createElement(InvoiceBatchesList, { ...{
                invoiceBatches,
                invoiceBatchPath
            } })));
};
export default InvoiceBatchesContainer;
