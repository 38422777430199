import React, { useState } from 'react';
import Drawer from '../shared/Drawer';
const ConfirmReconciliationDrawer = ({ submitPath, csrfToken, isReconciled, totalPaymentFormatted, availabilityAdjustmentFormatted, cashBalanceFormatted, totalCashAdjustmentsFormatted, paidInvoicesFormatted, closingBalanceFormatted, uploadedDate, selectedDate, status }) => {
    const [isOpen, setIsOpen] = useState(false);
    if (!selectedDate || isReconciled) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'action-buttons -center' },
                React.createElement("button", { className: 'button -primary -disabled' }, "Reconcile")),
            status === 'pending' && (React.createElement("div", null,
                React.createElement("p", null, "Please approve the uploaded ledger first"))),
            !selectedDate && (React.createElement("div", null,
                React.createElement("p", null, "Please select a date range on Cash Received to reconcile")))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'action-buttons' },
            React.createElement("button", { className: 'button -primary', onClick: () => setIsOpen(true) }, "Reconcile")),
        React.createElement(Drawer, { isOpen: isOpen, onClose: () => setIsOpen(false) },
            React.createElement("form", { action: submitPath, method: 'post' },
                React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
                React.createElement("div", { className: 'cash-received-drawer' },
                    React.createElement("div", { className: 'actions' },
                        React.createElement("button", { className: 'back', onClick: () => setIsOpen(false) }, "Go Back")),
                    React.createElement("div", { className: 'header' }, "Confirm Reconciliation"),
                    React.createElement("p", { style: { marginBottom: '16px' } },
                        "Up until ",
                        React.createElement("strong", null, selectedDate),
                        ', ',
                        React.createElement("strong", null, totalPaymentFormatted),
                        " was received into the client's segment account"),
                    React.createElement("p", { style: { marginBottom: '16px' } },
                        "On ",
                        React.createElement("strong", null, uploadedDate),
                        ", the client uploaded a new ledger and indicated their ledger had reduced by",
                        ' ',
                        React.createElement("strong", null, paidInvoicesFormatted),
                        " leaving a difference of",
                        ' ',
                        React.createElement("strong", null, closingBalanceFormatted),
                        "."),
                    React.createElement("p", { style: { marginBottom: '16px' } },
                        "Cash adjustments have been added totalling",
                        ' ',
                        React.createElement("strong", null, totalCashAdjustmentsFormatted)),
                    React.createElement("p", { style: { marginBottom: '16px' } },
                        "The cash balance for this reconciliation is now",
                        ' ',
                        React.createElement("strong", null, cashBalanceFormatted)),
                    React.createElement("p", null,
                        "An availability adjustment of",
                        ' ',
                        React.createElement("strong", null, availabilityAdjustmentFormatted),
                        " has been added to hold back funds."),
                    React.createElement("div", { className: 'actions' },
                        React.createElement("button", { className: 'button -cancel', onClick: () => setIsOpen(false) }, "Cancel"),
                        React.createElement("button", { className: 'button -apply' }, "Confirm")))))));
};
export default ConfirmReconciliationDrawer;
