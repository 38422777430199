import classNames from 'classnames';
import React, { Fragment } from 'react';
const TradeLoanSteps = ({ currentStep, stepLabels }) => {
    const stepClassName = stepLabelKey => {
        return classNames({
            '-current': currentStep === stepLabelKey,
            '-check': currentStep > stepLabelKey
        });
    };
    const checkText = stepLabelKey => {
        return currentStep > stepLabelKey ? '✓' : '';
    };
    return (React.createElement("div", { className: 'steps-container tracker', "data-testid": 'trade-loan-steps' },
        React.createElement("section", { className: 'steps-indicator steps' }, stepLabels.map((label, index) => (React.createElement(Fragment, { key: index },
            index !== 0 && (React.createElement("div", { className: `progress ${stepClassName(index + 1)}` })),
            React.createElement("div", { className: 'step step-details' },
                React.createElement("div", { className: `status ${stepClassName(index + 1)}`, "data-testid": `step-${index + 1}-node` }, `${checkText(index + 1)}`),
                React.createElement("p", { className: `label ${stepClassName(index + 1)}` }, stepLabels[index]))))))));
};
export default TradeLoanSteps;
