import React from 'react';
import ErrorList from '../shared/ErrorList';
const Feature = ({ errors, feature, index, removeFeature, updateFeatureField }) => {
    const options = [
        { value: 'new', label: 'New' },
        { value: 'removed', label: 'Removed' },
        { value: 'fixed', label: 'Fixed' },
        { value: 'enhancements', label: 'Enhancements' }
    ];
    const hasLabelError = `release_note_features[${index}].label` in errors;
    const hasDesciptionError = `release_note_features[${index}].description` in errors;
    const handleChangeLabel = (event) => {
        updateFeatureField(index, 'label', event.target.value);
    };
    const handleChangeDescription = (event) => {
        updateFeatureField(index, 'description', event.target.value);
    };
    return (React.createElement("div", { className: 'release-note-feature-item feature -editor', key: index, "data-testid": 'feature' },
        React.createElement("div", { className: 'form-group label -editor' },
            React.createElement("label", { className: 'label -uppercase -small -release-note', htmlFor: `release_note_feature_${index}_label` }, "Label"),
            React.createElement("select", { className: 'form-control', value: feature.label, id: `release_note_feature_${index}_label`, name: `release_note[release_note_features_attributes][${index}][label]`, onChange: handleChangeLabel }, options.map((option, index) => (React.createElement("option", { value: option.value, key: index }, option.label)))),
            hasLabelError && (React.createElement(ErrorList, { errors: errors, field: `release_note_features[${index}].label` }))),
        React.createElement("div", { className: 'form-group description -editor' },
            React.createElement("label", { className: 'label -uppercase -small -release-note', htmlFor: `release_note_feature_${index}_description` }, "Description"),
            React.createElement("input", { type: 'text', className: 'input form-control', value: feature.description, id: `release_note_feature_${index}_description`, name: `release_note[release_note_features_attributes][${index}][description]`, onChange: handleChangeDescription }),
            hasDesciptionError && (React.createElement(ErrorList, { errors: errors, field: `release_note_features[${index}].description` }))),
        React.createElement("button", { type: 'button', className: 'remove icon-button -square -trash-red-500 -background-red-50', "data-testid": 'remove-button', onClick: () => removeFeature(index) },
            React.createElement("i", { className: 'icon' }))));
};
export default Feature;
