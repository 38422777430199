import React, { useContext } from 'react';
import ActivitiesContext from '../../contexts/ActivitiesContext';
const CompanyName = ({ name, companyLink }) => {
    const context = useContext(ActivitiesContext);
    const { feedType } = context;
    const isAdminSideFeed = ['admin', 'borrower-scoped-admin'].includes(feedType);
    if (isAdminSideFeed) {
        return (React.createElement("span", { "data-testid": 'company-name-link' },
            React.createElement("a", { href: companyLink }, name)));
    }
    else {
        return React.createElement("span", { "data-testid": 'company-name-text' }, name);
    }
};
export default CompanyName;
