import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import ErrorList from '../shared/ErrorList';
const RiskTeamSelect = ({ riskTeam, modelName, errors }) => {
    const endorsedLabelClass = classNames('label -required', {
        '-error': errors.endorsedBy
    });
    return (React.createElement("div", { className: 'form-group', "data-testid": 'risk-team-select' },
        React.createElement("label", { className: endorsedLabelClass }, "Endorsed by"),
        React.createElement(Select, { styles: {
                control: baseStyles => ({
                    ...baseStyles,
                    borderColor: errors.endorsedBy && '#bf2600',
                    backgroundColor: errors.endorsedBy && '#ffebe6'
                })
            }, placeholder: 'Select an Endorser', name: `${modelName}[endorsed_by]`, options: riskTeam }),
        React.createElement(ErrorList, { errors: errors, field: 'endorsedBy' })));
};
export default RiskTeamSelect;
