import fetch from 'isomorphic-fetch';
import queryString from 'query-string';
export const fetchCreditors = async (apiToken, page) => {
    const endpoint = `/api/borrower/creditors?page=${page}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const fetchCreditorsForBorrower = async (apiToken, borrowerId, page, sortBy, sortDirection) => {
    const params = {
        page,
        ...(sortBy && { sort_by: sortBy }),
        ...(sortDirection && { sort_direction: sortDirection })
    };
    const search = queryString.stringify(params);
    const endpoint = `/api/admin/borrowers/${borrowerId}/creditors?${search}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
