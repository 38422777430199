import classNames from 'classnames';
import React from 'react';
const ToggleMenuWrapper = ({ isActive, children, forwardToggleMenuRef, container, icon = 'settings', handleToggle }) => {
    const buttonClass = classNames('button', {
        '-active': isActive,
        '-settings': icon === 'settings'
    });
    const containerClass = classNames('toggle-menu toggle', {
        '-widget': container === 'widget'
    });
    const listClass = classNames('list', 'toggle-list', {
        '-show': isActive
    });
    return (React.createElement("div", { className: containerClass, ref: forwardToggleMenuRef, "data-testid": 'toggle-menu' },
        React.createElement("div", { className: buttonClass, "data-testid": 'trigger', onClick: handleToggle }),
        React.createElement("div", { className: listClass, "data-testid": 'list' }, children)));
};
export default ToggleMenuWrapper;
