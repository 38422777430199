import React from 'react';
import { numberToPercentage } from '../../utils/numberToPercentage';
const PenaltyDisplay = ({ isPenaltyOn, isPenaltyToggleable, penaltyStartDate, appliedInterestRate }) => {
    if (isPenaltyOn && !isPenaltyToggleable) {
        return (React.createElement("div", { className: 'penalty-display -on', "data-testid": 'penalty-display-on-untoggleable' },
            React.createElement("label", { className: 'label' }, "Start Date"),
            React.createElement("div", { className: 'date' }, penaltyStartDate),
            React.createElement("div", { className: 'note' },
                "A default rate of ",
                numberToPercentage(appliedInterestRate),
                " is being applied due to the loan being overdue. The loan went into default starting on ",
                penaltyStartDate,
                ".")));
    }
    if (isPenaltyOn) {
        return (React.createElement("div", { className: 'penalty-display -on', "data-testid": 'penalty-display-on' },
            React.createElement("label", { className: 'label' }, "Start Date"),
            React.createElement("div", { className: 'date' }, penaltyStartDate),
            React.createElement("div", { className: 'note' }, "The daily default rate will be applied starting tomorrow. To change, you have to switch it off and switch it on again.")));
    }
    if (!isPenaltyOn) {
        return (React.createElement("div", { className: 'penalty-display -off', "data-testid": 'penalty-display-off' }, "Default Rate is currently turned off."));
    }
    return null;
};
export default PenaltyDisplay;
