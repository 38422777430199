import queryString from 'query-string';
export const fetchNewInvoices = async (apiToken, companyId, invoiceBatchId) => {
    const endpoint = `/api/admin/companies/${companyId}/invoice_batches/${invoiceBatchId}/fetch_new_invoices`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const fetchVariedInvoices = async (apiToken, companyId, invoiceBatchId) => {
    const endpoint = `/api/admin/companies/${companyId}/invoice_batches/${invoiceBatchId}/fetch_varied_invoices`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const fetchPaidInvoices = async (apiToken, companyId, invoiceBatchId) => {
    const endpoint = `/api/admin/companies/${companyId}/invoice_batches/${invoiceBatchId}/fetch_paid_invoices`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const verifyInvoices = async (apiToken, companyId, invoiceBatchId, selectedInvoiceIds, reason, notes, attachment) => {
    const param = queryString.stringify({ invoice_ids: selectedInvoiceIds }, { arrayFormat: 'bracket' });
    const endpoint = `/api/admin/companies/${companyId}/invoice_batches/${invoiceBatchId}/verify_invoices?${param}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json'
    };
    const formData = new FormData();
    formData.append('notes', notes);
    formData.append('reason', reason);
    formData.append('attachment', attachment);
    return fetch(endpoint, {
        headers,
        method: 'PUT',
        body: formData
    }).then((res) => res.json());
};
