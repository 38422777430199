import React, { Fragment, useState } from 'react';
import Modal from '../shared/Modal';
const ButtonForModal = ({ text, path, method, className, headerText, warningText }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => {
        isOpen ? setIsOpen(false) : setIsOpen(true);
    };
    return (React.createElement(Fragment, null,
        React.createElement("button", { className: className, onClick: toggleModal, "data-testid": 'modal-button' }, text),
        React.createElement(Modal, { isOpen: isOpen, onClose: toggleModal },
            React.createElement("div", { className: 'modal' },
                React.createElement("div", { className: 'custom-panel -modal' },
                    React.createElement("div", { className: 'header' }, headerText),
                    React.createElement("div", { className: 'content custom-scrollbar' },
                        React.createElement("div", { className: 'section' },
                            React.createElement("div", { className: 'description' },
                                React.createElement("span", { className: 'flag' }, "Warning!"),
                                " ",
                                warningText)),
                        React.createElement("br", null),
                        React.createElement("div", { className: 'action-buttons' },
                            React.createElement("a", { href: path, "data-method": method, className: 'button -primary', "data-testid": 'submit-link' }, "Yes, Delete")))),
                React.createElement("div", { className: 'modal-overlay' })))));
};
export default ButtonForModal;
