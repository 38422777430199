import React from 'react';
import Modal from '../shared/Modal';
const ApproveAllWarningModal = ({ isApproveAllModalOpen, closeApproveAllWarningModal, actionHandler }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { isOpen: isApproveAllModalOpen, onClose: closeApproveAllWarningModal },
            React.createElement("div", { className: 'custom-panel -modal', "data-testid": 'approve-all-customer-warning-modal' },
                React.createElement("div", { className: 'header' },
                    React.createElement("div", { className: 'custom-icon icon -alert -warning' },
                        React.createElement("i", { className: 'icon' }),
                        " Approve All Customers")),
                React.createElement("div", { className: 'content' },
                    React.createElement("div", { className: 'section' },
                        React.createElement("p", null,
                            "Only ",
                            React.createElement("strong", null, " selected "),
                            "customers with ",
                            React.createElement("strong", null, "valid ABN"),
                            " will be approved. Do you wish to continue?")),
                    React.createElement("div", { className: 'footer' },
                        React.createElement("div", { className: 'action-buttons -flex-end' },
                            React.createElement("button", { className: 'button -danger', type: 'button', "data-testid": 'cancel-approve-all-warning', onClick: closeApproveAllWarningModal }, "Cancel"),
                            React.createElement("button", { className: 'button -primary', type: 'button', "data-testid": 'yes-approve-all-warning', onClick: actionHandler }, "Yes"))))))));
};
export default ApproveAllWarningModal;
