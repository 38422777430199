import classNames from 'classnames';
import React from 'react';
import Scrollspy from 'react-scrollspy';
import AccordionPane from '../shared/AccordionPane';
import SubItem from './SubItem';
const Item = ({ item, activeColor }) => {
    const { name, isActive, path, subItems, showCheck, isChecked, hasAccordionWarning, scrollSpyItems, useScrollSpy } = item;
    const linkClass = classNames('item', {
        '-active': isActive,
        '-present': showCheck && isChecked,
        '-not-present': showCheck && !isChecked,
        '-brand-a-300': activeColor && activeColor === 'blue-300'
    });
    const defaultAccordionHeight = subItems ? 60 * subItems.length : 0;
    if (subItems) {
        return (React.createElement(AccordionPane, { title: name, type: 'vertical-nav', isExpandedDefault: isActive, defaultHeight: defaultAccordionHeight, hasWarning: hasAccordionWarning, highlightedTitle: isActive }, useScrollSpy ? (React.createElement(Scrollspy, { items: scrollSpyItems, currentClassName: 'active', className: 'vertical-navigation -accordion' }, subItems.map((subItem, index) => (React.createElement(SubItem, { subItem: subItem, key: index }))))) : (React.createElement("div", { className: 'vertical-navigation -accordion' }, subItems.map((subItem, index) => (React.createElement(SubItem, { subItem: subItem, key: index })))))));
    }
    return (React.createElement("a", { href: path, className: linkClass, "data-testid": 'item-link' }, name));
};
export default Item;
