import { buildHeaders } from './shared';
export const enable = async (apiToken, borrowerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/invoice_partial_payment/enable`;
    const body = {
        disapprove_part_paid_invoice: true
    };
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'PUT',
            body: JSON.stringify(body)
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.map(error => error.title).join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
export const disable = async (apiToken, borrowerId) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/invoice_partial_payment/disable`;
    const body = {
        disapprove_part_paid_invoice: false
    };
    try {
        const response = await fetch(endpoint, {
            headers: buildHeaders(apiToken),
            method: 'PUT',
            body: JSON.stringify(body)
        });
        if (!response.ok) {
            const { errors } = await response.json();
            throw new Error(errors.map(error => error.title).join(','));
        }
        return response.json();
    }
    catch (err) {
        const error = err;
        throw new Error(error.message);
    }
};
