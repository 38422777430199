import React, { useState } from 'react';
import { showNotifyToast as defaultShowNotifyToast } from '../../utils';
import ToggleSwitch from '../../components/shared/ToggleSwitch';
import { enableInvoiceBatch as defaultEnableInvoiceBatch } from '../../api/company';
const InvoiceBatchToggle = ({ apiToken, borrowerId, invoiceBatchEnabled, enableInvoiceBatch = defaultEnableInvoiceBatch, showNotifyToast = defaultShowNotifyToast }) => {
    const errorMessage = 'Something went wrong. Please try again.';
    const [invoiceBatchStatus, setInvoiceBatchStatus] = useState(invoiceBatchEnabled);
    const enableToggle = async () => {
        try {
            const response = await enableInvoiceBatch(apiToken, borrowerId, true);
            setInvoiceBatchStatus(response.invoiceBatchEnabled);
            if (response) {
                showNotifyToast({
                    text: 'Successfully enabled Invoice Batch',
                    type: 'success'
                });
            }
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
    };
    const disableToggle = async () => {
        try {
            const response = await enableInvoiceBatch(apiToken, borrowerId, false);
            setInvoiceBatchStatus(response.invoiceBatchEnabled);
            if (response) {
                showNotifyToast({
                    text: 'Successfully disabled Invoice Batch',
                    type: 'success'
                });
            }
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
    };
    return (React.createElement("div", { "data-testid": 'invoice-batch' },
        React.createElement("div", { className: 'form-group group -oneline' },
            React.createElement("label", { className: 'label' }, "Enable invoice batch"),
            React.createElement(ToggleSwitch, { isToggled: invoiceBatchStatus, switchOn: () => enableToggle(), switchOff: () => disableToggle(), testId: 'toggle-invoice-batch' }))));
};
export default InvoiceBatchToggle;
