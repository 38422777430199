import React, { useEffect, useRef, useState } from 'react';
import { fetchNewInvoices } from '../../api/invoice_batch';
import { showNotifyToast } from '../../utils/notifyToast';
const NewInvoicesTable = ({ apiToken, companyId, invoiceBatchId, invoiceBatch, invoicePath, customerPath, selectedInvoiceIds, totalInvoices, handleSelectedInvoice }) => {
    const [newInvoicesArray, setNewInvoicesArray] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const ref = useRef({});
    const errorMessage = 'Something went wrong. Please try again.';
    useEffect(() => {
        newInvoices();
    }, []);
    useEffect(() => {
        if (selectedInvoiceIds.length === 0) {
            if (ref.current[0]) {
                newInvoicesArray.forEach((i, index) => {
                    ref.current[index].checked = false;
                });
            }
        }
    }, [selectedInvoiceIds]);
    const newInvoices = async () => {
        setIsLoading(true);
        try {
            const response = await fetchNewInvoices(apiToken, companyId, invoiceBatchId);
            const invoices = response.data.map(res => res.attributes);
            setNewInvoicesArray(invoices);
            totalInvoices(invoices.reduce((acc, row) => acc + row.amountDueCents, 0), invoices.length);
        }
        catch (err) {
            showNotifyToast({
                text: errorMessage,
                type: 'error'
            });
        }
        finally {
            setIsLoading(false);
        }
    };
    const statusTag = (color, label) => {
        return (React.createElement("span", { className: `badge-color ${color} -small -round -border${color}` }, label));
    };
    const requiresVerification = (invoiceAmountDueCents, isVerified) => {
        if (invoiceAmountDueCents >= invoiceBatch.verifyInvoicesOverCents &&
            !isVerified) {
            return statusTag('-yellow', 'Requires verification');
        }
    };
    const handleCheckboxChange = invoice => {
        const invoiceData = {
            invoiceId: invoice.invoiceId,
            number: invoice.number,
            amountDueCents: invoice.amountDueCents,
            amountDueCurrency: invoice.amountDueCurrency,
            amountDue: invoice.amountDueFormatted,
            issueDate: invoice.issueDateFormattedString,
            customerName: invoice.customerName,
            status: 'New'
        };
        handleSelectedInvoice(invoiceData);
    };
    return (React.createElement("div", { className: 'custom-panel', "data-testid": 'new-invoices-table' },
        React.createElement("table", { className: 'custom-table' },
            React.createElement("thead", { className: 'table-headers' },
                React.createElement("tr", { className: 'row -header' },
                    React.createElement("th", { className: 'table-header header' }),
                    React.createElement("th", { className: 'table-header header' }, "Invoice No."),
                    React.createElement("th", { className: 'table-header header' }, "Customer Name"),
                    React.createElement("th", { className: 'table-header header' }, "Amount Due"),
                    React.createElement("th", { className: 'table-header header' }, "Issue Date"),
                    React.createElement("th", { className: 'table-header header' }))),
            React.createElement("tbody", null, isLoading ? (React.createElement("tr", null,
                React.createElement("td", { className: 'cell', colSpan: 6 },
                    React.createElement("div", { className: 'loading-container -small' },
                        React.createElement("div", { className: 'loading-spinner spinner' }))))) : newInvoicesArray.length !== 0 ? (newInvoicesArray.map((invoice, index) => (React.createElement("tr", { key: invoice.id },
                React.createElement("td", { className: 'cell' },
                    React.createElement("label", { className: 'label -checkbox' },
                        React.createElement("input", { type: 'checkbox', checked: selectedInvoiceIds.includes[invoice.id], onChange: () => handleCheckboxChange(invoice), ref: e => (ref.current[index] = e) }))),
                React.createElement("td", { className: 'cell' },
                    React.createElement("a", { href: `${invoicePath}/${invoice.invoiceId}` }, invoice.number)),
                React.createElement("td", { className: 'cell' },
                    React.createElement("a", { href: `${customerPath}/${invoice.customerId}` }, invoice.customerName)),
                React.createElement("td", { className: 'cell' },
                    React.createElement("div", { className: 'text -emphasize' }, invoice.amountDueFormatted)),
                React.createElement("td", { className: 'cell' }, invoice.issueDateFormattedString),
                React.createElement("td", { className: 'cell -right' },
                    invoice.approved
                        ? statusTag('-green', 'Approved')
                        : statusTag('-red', 'Unapproved'),
                    requiresVerification(invoice.amountDueCents, invoice.isVerified)))))) : (React.createElement("tr", { "data-testid": 'no-record' },
                React.createElement("td", { className: 'placeholder', colSpan: 6 }, "There are no invoices to display")))))));
};
export default NewInvoicesTable;
