import React, { useEffect, useRef, useState } from 'react';
import ActivityFeed from '../components/activity-feed/ActivityFeed';
import Filters from '../components/activity-feed/Filters';
import PostInputPanel from '../containers/post-input-panel/PostInputPanel';
import { fetchEvents } from '../api/event';
import ActivitiesContext from '../contexts/ActivitiesContext';
import { removeDuplicates } from '../utils/removeDuplicates';
const AdminBorrowerActivities = ({ apiToken, currentUserId, disablePosting = false, borrowerId, userAvatarUrl }) => {
    const [adminFilters, setAdminFilters] = useState([]);
    const [currentFilterName, setCurrentFilterName] = useState('all');
    const [currentFilters, setCurrentFilters] = useState([]);
    const [events, setEvents] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [dateFilter, setDateFilter] = useState('');
    const [hasErrors, setHasErrors] = useState(false);
    const dateClearRef = useRef(null);
    const datePickerRef = useRef(null);
    const scope = `admin/borrowers/${borrowerId}`;
    const context = { apiToken, feedType: 'borrower-scoped-admin' };
    const nextFetches = () => {
        if (hasMore === true) {
            handleFetchEvents(page);
            setPage(page + 1);
        }
    };
    const handleFetchEvents = async (currentPage) => {
        try {
            const response = await fetchEvents(apiToken, scope, currentPage, currentFilters, dateFilter);
            if (response.last_page === true) {
                setHasMore(false);
            }
            setAdminFilters(response.filters);
            setEvents(prevEvents => removeDuplicates([...prevEvents, ...response.data]));
            setHasErrors(false);
        }
        catch {
            setHasErrors(true);
        }
        setIsLoading(false);
    };
    const handleFeedRefresh = (filterName) => {
        if (dateClearRef && dateClearRef.current) {
            dateClearRef.current.click();
        }
        const allFilter = [...adminFilters].filter((f) => f.name === filterName)[0];
        setCurrentFilters(allFilter.filters);
        setCurrentFilterName(allFilter.name);
    };
    const handleDateFilterChange = (selectedDates, dateString) => {
        setDateFilter(dateString);
    };
    const flatpickrSettings = {
        wrap: true,
        dateFormat: 'd/m/Y',
        onChange: handleDateFilterChange
    };
    useEffect(() => {
        setIsLoading(true);
        setPage(2);
        setEvents([]);
        setHasMore(true);
        handleFetchEvents(1);
    }, [currentFilters, dateFilter]);
    return (React.createElement("div", { className: 'activities-container activities -admin -borrower-scoped' },
        React.createElement(ActivitiesContext.Provider, { value: context },
            React.createElement("div", { className: 'sidebar' },
                React.createElement(Filters, { ...{
                        currentFilterName,
                        dateFilter,
                        flatpickrSettings,
                        setCurrentFilterName,
                        setCurrentFilters
                    }, filters: adminFilters, forwardDateClearRef: dateClearRef, forwardDatePickerRef: datePickerRef })),
            React.createElement("div", { className: 'body' },
                !disablePosting && (React.createElement(PostInputPanel, { ...{ apiToken, scope, userAvatarUrl, handleFeedRefresh } })),
                React.createElement(ActivityFeed, { ...{
                        events,
                        isLoading,
                        hasErrors,
                        hasMore,
                        nextFetches,
                        userAvatarUrl,
                        currentUserId
                    } })))));
};
export default AdminBorrowerActivities;
