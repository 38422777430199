import React from 'react';
import classNames from 'classnames';
const SelectedInvoice = ({ invoice, index, disapprovalReasonsOptions, handleInvoiceId }) => {
    return (React.createElement("div", { className: 'custom-panel panel -collapsible -no-margin -no-border-bottom', "data-testid": `disapproval-reasons-panel-#${invoice.number}`, key: `${invoice && invoice.number}-disapproval-${index}` },
        React.createElement("input", { className: 'toggle', id: `confirmation-checkbox-#${invoice.number}`, "data-testid": `confirmation-checkbox-#${invoice.number}`, type: 'checkbox', defaultChecked: false }),
        React.createElement("label", { className: classNames('header -space-between -confirmation', {
                '-uneligible': !invoice.allowedForApprovalStatusToggle,
                '-disapproval': invoice.disapprovalReasons.length > 0 &&
                    invoice.allowedForApprovalStatusToggle
            }), htmlFor: `confirmation-checkbox-#${invoice.number}` }, `#${invoice.number} - ${invoice.customerName}`),
        React.createElement("div", { className: 'body -review -section' },
            invoice.disapprovalReasons.length > 0 ? (React.createElement("div", { className: 'disapproval-reasons -padding' },
                React.createElement("span", { className: 'title' }, "Previous Disapproval Reason"),
                React.createElement("ul", { className: 'reasons' }, disapprovalReasonsOptions.map((item, index2) => {
                    return (invoice.disapprovalReasons.includes(item.value) && (React.createElement("li", { key: `${invoice && invoice.id}-reason-${index2}`, className: 'reason -center' }, item.label)));
                })))) : (React.createElement("div", { className: 'content' }, "This invoice doesn't have previous disapproval reasons")),
            React.createElement("div", { className: 'footnote' },
                !invoice.allowedForApprovalStatusToggle && (React.createElement("span", null, "This invoice is not eligible to approve.")),
                React.createElement("a", { href: '#', className: 'button -remove', onClick: () => handleInvoiceId(invoice.id) },
                    React.createElement("i", { className: 'icon' }),
                    "Remove Invoice")))));
};
export default SelectedInvoice;
