import React from 'react';
import { showWriteOff } from '../../utils/reserveAmount';
const OutstandingTable = ({ isWriteOff, feesOutstandingMoney, loanOutstandingMoney, interestOutstandingMoney, reserveAmountMoney, totalOutstandingMoney, writeOffValue, afterReserve, afterInterestOutstanding, afterFeesOutstanding, afterLoanOutstanding, afterTotalOutstanding }) => {
    return (React.createElement("table", { className: 'custom-table -reserve', "data-testid": 'outstanding-table' },
        React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement("th", { className: 'comparison' }),
                React.createElement("th", { className: 'comparison before' }, "Before"),
                React.createElement("th", { className: 'comparison after' }, "After"))),
        React.createElement("tbody", null,
            React.createElement("tr", null,
                React.createElement("td", { className: 'header' }, "Credit Reserve"),
                React.createElement("td", { className: 'before' }, reserveAmountMoney.setLocale('en-AU').toFormat()),
                React.createElement("td", { className: 'after' }, afterReserve.setLocale('en-AU').toFormat())),
            React.createElement("tr", null,
                React.createElement("td", { className: 'header' }, "Principal Outstanding"),
                React.createElement("td", { className: 'before' }, loanOutstandingMoney.setLocale('en-AU').toFormat()),
                React.createElement("td", { className: 'after' },
                    afterLoanOutstanding.setLocale('en-AU').toFormat(),
                    showWriteOff(isWriteOff, loanOutstandingMoney, writeOffValue.loanWriteOff) && (React.createElement("div", { className: 'writeoff', "data-testid": 'write-off-loan-outstanding' },
                        "(",
                        writeOffValue.loanWriteOff.setLocale('en-AU').toFormat(),
                        ' ',
                        "written off)")))),
            React.createElement("tr", null,
                React.createElement("td", { className: 'header' }, "Outstanding Fees"),
                React.createElement("td", { className: 'before' }, feesOutstandingMoney.setLocale('en-AU').toFormat()),
                React.createElement("td", { className: 'after' },
                    afterFeesOutstanding.setLocale('en-AU').toFormat(),
                    showWriteOff(isWriteOff, feesOutstandingMoney, writeOffValue.feesWriteOff) && (React.createElement("div", { className: 'writeoff', "data-testid": 'write-off-fees-outstanding' },
                        "(",
                        writeOffValue.feesWriteOff.setLocale('en-AU').toFormat(),
                        ' ',
                        "written off)")))),
            React.createElement("tr", null,
                React.createElement("td", { className: 'header' }, "Outstanding Interest"),
                React.createElement("td", { className: 'before' }, interestOutstandingMoney.setLocale('en-AU').toFormat()),
                React.createElement("td", { className: 'after' },
                    afterInterestOutstanding.setLocale('en-AU').toFormat(),
                    showWriteOff(isWriteOff, interestOutstandingMoney, writeOffValue.interestWriteOff) && (React.createElement("div", { className: 'writeoff', "data-testid": 'write-off-interest-outstanding' },
                        "(",
                        writeOffValue.interestWriteOff.setLocale('en-AU').toFormat(),
                        ' ',
                        "written off)")))),
            React.createElement("tr", null,
                React.createElement("td", { className: 'header' }, "Total Outstanding"),
                React.createElement("td", { className: 'before' }, totalOutstandingMoney.setLocale('en-AU').toFormat()),
                React.createElement("td", { className: 'after' },
                    afterTotalOutstanding.setLocale('en-AU').toFormat(),
                    showWriteOff(isWriteOff, totalOutstandingMoney, writeOffValue.totalWriteOff) && (React.createElement("div", { className: 'writeoff', "data-testid": 'write-off-total-outstanding' },
                        "(",
                        writeOffValue.totalWriteOff.setLocale('en-AU').toFormat(),
                        ' ',
                        "written off)")))))));
};
export default OutstandingTable;
