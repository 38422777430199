import React from 'react';
import NumberFormat from 'react-number-format';
const AmountField = ({ handleChangeAmount, errorMessage, isDisabled = false }) => {
    const fieldClass = errorMessage === '' ? 'field' : 'field -error';
    const formGroupClass = isDisabled
        ? 'form form-group -drawdown -disabled'
        : 'form form-group -drawdown';
    return (React.createElement("div", { className: fieldClass, "data-testid": 'amount-field' },
        React.createElement("div", { className: formGroupClass },
            React.createElement(NumberFormat, { allowNegative: true, thousandSeparator: true, onValueChange: handleChangeAmount, className: 'input', disabled: isDisabled, autoFocus: true, decimalScale: 2 })),
        React.createElement("div", { className: 'error' }, errorMessage)));
};
export default AmountField;
