import React from 'react';
import Modal from '../../components/shared/Modal';
import OutstandingTable from './OutstandingTable';
import { computeWriteOff } from '../../utils/reserveAmount';
const ReserveAmountModal = ({ path, csrfToken, isVisible, isWriteOff, toggleModal, feesOutstandingMoney, loanOutstandingMoney, interestOutstandingMoney, reserveAmountMoney, currentLoanOutstanding, currentFeesOutstanding, currentInterestOutstanding, totalOutstandingMoney, afterReserve, afterInterestOutstanding, afterFeesOutstanding, afterLoanOutstanding, afterTotalOutstanding }) => {
    const writeOffValue = computeWriteOff({
        isWriteOff,
        loan: loanOutstandingMoney,
        fees: feesOutstandingMoney,
        interest: interestOutstandingMoney,
        currrentLoan: currentLoanOutstanding,
        currentFees: currentFeesOutstanding,
        currentInterest: currentInterestOutstanding
    });
    return (React.createElement(Modal, { isOpen: isVisible, onClose: toggleModal, "data-testid": 'reserve-amount-modal' },
        React.createElement("form", { method: 'post', action: path, className: 'form' },
            React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
            React.createElement("input", { type: 'hidden', name: '_method', value: 'patch' }),
            React.createElement("input", { type: 'hidden', name: 'company[reserve_amount]', "data-testid": 'reserve-input-value', value: afterReserve.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'company[interest_outstanding]', "data-testid": 'interest-input-value', value: afterInterestOutstanding.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'company[fees_outstanding]', "data-testid": 'fees-input-value', value: afterFeesOutstanding.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'company[loan_outstanding]', "data-testid": 'loan-input-value', value: afterLoanOutstanding.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'company[total_outstanding]', "data-testid": 'total-input-value', value: afterTotalOutstanding.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'reserve_amount_log[before_reserve_amount]', "data-testid": 'before-reserve-amount', value: reserveAmountMoney.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'reserve_amount_log[before_loan_outstanding]', "data-testid": 'before-loan-outstanding', value: loanOutstandingMoney.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'reserve_amount_log[before_fees_outstanding]', "data-testid": 'before-fees-outstanding', value: feesOutstandingMoney.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'reserve_amount_log[before_interest_outstanding]', "data-testid": 'before-interest-outstanding', value: interestOutstandingMoney.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'reserve_amount_log[before_total_outstanding]', "data-testid": 'before-total-outstanding', value: totalOutstandingMoney.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'reserve_amount_log[input_loan_outstanding]', "data-testid": 'before-loan-outstanding', value: currentLoanOutstanding.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'reserve_amount_log[input_fees_outstanding]', "data-testid": 'before-fees-outstanding', value: currentFeesOutstanding.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'reserve_amount_log[input_interest_outstanding]', "data-testid": 'before-interest-outstanding', value: currentInterestOutstanding.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'reserve_amount_log[after_reserve_amount]', "data-testid": 'after-reserve-amount', value: afterReserve.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'reserve_amount_log[after_loan_outstanding]', "data-testid": 'after-loan-outstanding', value: afterInterestOutstanding.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'reserve_amount_log[after_fees_outstanding]', "data-testid": 'after-fees-outstanding', value: afterFeesOutstanding.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'reserve_amount_log[after_interest_outstanding]', "data-testid": 'after-interest-outstanding', value: afterLoanOutstanding.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'reserve_amount_log[after_total_outstanding]', "data-testid": 'after-total-outstanding', value: afterTotalOutstanding.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'reserve_amount_log[write_off_loan_outstanding]', "data-testid": 'write-off-loan-outstanding', value: writeOffValue.loanWriteOff.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'reserve_amount_log[write_off_fees_outstanding]', "data-testid": 'write-off-fees-outstanding', value: writeOffValue.feesWriteOff.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'reserve_amount_log[write_off_interest_outstanding]', "data-testid": 'write-off-interest-outstanding', value: writeOffValue.interestWriteOff.toUnit() }),
            React.createElement("input", { type: 'hidden', name: 'reserve_amount_log[write_off_total_outstanding]', "data-testid": 'write-off-total-outstanding', value: writeOffValue.totalWriteOff.toUnit() }),
            React.createElement("div", { className: 'custom-panel -modal -reserve', role: 'dialog', "aria-modal": 'true', "data-testid": 'reserve-amount-modal' },
                React.createElement("div", { className: 'header' }, "Confirmation"),
                React.createElement("div", { className: 'content' },
                    React.createElement("div", { className: 'section' },
                        React.createElement("div", { className: 'description' }, "Please confirm how you would like to allocate the reserve amount to outstanding balances."),
                        React.createElement(OutstandingTable, { ...{
                                isWriteOff,
                                feesOutstandingMoney,
                                loanOutstandingMoney,
                                interestOutstandingMoney,
                                reserveAmountMoney,
                                totalOutstandingMoney,
                                writeOffValue,
                                afterReserve,
                                afterInterestOutstanding,
                                afterFeesOutstanding,
                                afterLoanOutstanding,
                                afterTotalOutstanding
                            } }))),
                React.createElement("div", { className: `action-buttons buttons space -border -padding` },
                    React.createElement("button", { className: 'button -neutral', type: 'button', "data-testid": 'back-button-reserve', onClick: () => toggleModal() }, "Go Back"),
                    React.createElement("input", { type: 'submit', className: 'button -primary -wide', name: 'commit', value: 'Confirm', "data-disable-with": 'Updating...' }))))));
};
export default ReserveAmountModal;
