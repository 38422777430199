import React from 'react';
const ClientAcceptInvitation = ({ event }) => {
    const { attributes } = event;
    const { createdAtFormatted, eventable: { attributes: { firstName, lastName } } } = attributes;
    return (React.createElement("div", { className: 'event-box event', "data-testid": 'client-accept-invitation' },
        React.createElement("div", { className: 'icon' },
            React.createElement("div", { className: 'activity-icon -accept-invitation' })),
        React.createElement("div", { className: 'description' },
            React.createElement("div", { className: 'title' },
                `${firstName} ${lastName} `,
                "accepted the invitation"),
            React.createElement("div", { className: 'date' }, createdAtFormatted))));
};
export default ClientAcceptInvitation;
