import React, { useEffect, useRef, useState } from 'react';
import { isCommentDeletionError, isCommentErrors, isCommentValidationErrors } from '../../../types';
import { addComment, deleteComment, fetchComments } from '../../api/comment';
import { compileVariantClasses } from '../../utils';
import { showNotifyToast } from '../../utils';
import { validateFileSize, validateFileType } from '../../validations/file';
import AttachmentUploader from '../shared/AttachmentUploader';
import useOutsideClick from '../../hooks/useOutsideClick';
import CommentFeed from './CommentFeed';
import CommentForm from './CommentForm';
import { FileDrop } from 'react-file-drop';
const CommentPanel = ({ apiToken, currentUserId, feedVariantClasses, formVariantClasses, itemVariantClasses, commentableId, commentableType, panelVariantClasses, scope, userAvatarUrl, formPlaceholder, itemName, privacySettingEnabled = false, toggleMenuEnabled = false }) => {
    // state hooks
    const [comments, setComments] = useState({
        data: [],
        isLastPage: false
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [commentBody, setCommentBody] = useState('');
    const [validationErrors, setValidationErrors] = useState({});
    const [files, setFiles] = useState([]);
    const [privacySetting, setPrivacySetting] = useState('public');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [emojiPickerDirection, setEmojiPickerDirection] = useState('');
    const [numRefreshes, setNumRefreshes] = useState(0);
    const [hasFetchErrors, setHasFetchErrors] = useState(false);
    const [isLoadingMoreComments, setIsLoadingMoreComments] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMoreComments, setHasMoreComments] = useState(true);
    // ref hooks
    const textAreaRef = useRef(null);
    const filePickerRef = useRef(null);
    const checkboxRef = useRef(null);
    const emojiPickerRef = useRef(null);
    const itemNameText = itemName || 'comment';
    const successMessageText = `Your ${itemNameText} has been added.`;
    const errorMessageText = `Sorry, we could not add your ${itemNameText} at this time.`;
    const deleteSuccessText = 'Your comment has been deleted successfully.';
    const deleteErrorText = 'Sorry, we could not delete your comment at this time.';
    const hasErrors = Object.keys(validationErrors).length > 0;
    const handleLoadMoreComments = async () => {
        if (hasMoreComments === true) {
            setIsLoadingMoreComments(true);
            try {
                const response = await fetchComments(apiToken, commentableId, commentableType, scope, page);
                if (!response.data) {
                    throw new Error('Invalid data');
                }
                if (response.isLastPage === true) {
                    setHasMoreComments(false);
                }
                setComments({
                    data: [...comments.data, ...response.data],
                    isLastPage: response.isLastPage
                });
                setPage(page + 1);
                setIsLoadingMoreComments(false);
            }
            catch (err) {
                setIsLoadingMoreComments(true);
                setHasFetchErrors(true);
            }
        }
    };
    useEffect(() => {
        setPage(1);
        let isSubscribed = true;
        const loadComments = async () => {
            setIsLoading(true);
            try {
                const response = await fetchComments(apiToken, commentableId, commentableType, scope, page);
                if (!response.data) {
                    throw new Error('Invalid data');
                }
                if (response.isLastPage === true) {
                    setHasMoreComments(false);
                }
                if (response.isLastPage === false) {
                    setHasMoreComments(true);
                }
                if (isSubscribed) {
                    setComments(response);
                    setHasFetchErrors(false);
                    setIsLoading(false);
                }
            }
            catch (err) {
                if (isSubscribed) {
                    setHasFetchErrors(true);
                    setIsLoading(false);
                }
            }
        };
        if (!isSubmitting && !hasErrors && !isDeleting) {
            loadComments();
            setPage(page + 1);
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSubmitting, isDeleting, numRefreshes]);
    useOutsideClick(emojiPickerRef, () => {
        if (showEmojiPicker) {
            setShowEmojiPicker(false);
        }
    });
    const focusTextArea = () => {
        if (textAreaRef && textAreaRef.current) {
            textAreaRef.current.focus();
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        submitComment();
    };
    const submitComment = async () => {
        setIsSubmitting(true);
        const comment = {
            body: commentBody,
            files
        };
        try {
            validateFiles();
            const response = await addComment(apiToken, commentableId, commentableType, comment, scope, privacySetting);
            if (isCommentValidationErrors(response)) {
                throw response.errors;
            }
            // no validation errors so clear previous errors
            setValidationErrors({});
            setCommentBody('');
            clearAttachments();
            showNotifyToast({ text: successMessageText, type: 'success' });
        }
        catch (err) {
            setValidationErrors(err);
            showNotifyToast({
                text: errorMessageText,
                type: 'error'
            });
        }
        setIsSubmitting(false);
    };
    const validateFiles = () => {
        if (files.length > 3)
            throw { attachment: ['You can only upload up to 3 files per comment.'] };
        files.forEach(file => {
            if (!validateFileType(file)) {
                const typeError = new Error(`Invalid file type for: ${file.name}`);
                throw { attachment: [typeError.message] };
            }
            else if (!validateFileSize(file)) {
                const sizeError = new Error('Please upload files smaller than 20MB.');
                throw { attachment: [sizeError.message] };
            }
        });
        // files are valid so clear previous errors
        setValidationErrors({});
    };
    const removeComment = async (commentId) => {
        setIsDeleting(true);
        try {
            const response = await deleteComment(apiToken, commentId, commentableId, commentableType, scope);
            if (isCommentDeletionError(response)) {
                throw response.error;
            }
            if (isCommentErrors(response)) {
                throw response.errors;
            }
            showNotifyToast({
                text: deleteSuccessText,
                type: 'success'
            });
        }
        catch (err) {
            showNotifyToast({
                text: deleteErrorText,
                type: 'error'
            });
        }
        setIsDeleting(false);
    };
    const handleCommentBodyChange = (event) => {
        event.preventDefault();
        setCommentBody(event.currentTarget.value);
    };
    const handleFileChange = (event) => {
        const currentFileList = event.target.files;
        const currentFiles = [...currentFileList];
        setFiles(currentFiles);
    };
    const handleAttachmentTrigger = () => {
        if (filePickerRef && filePickerRef.current) {
            filePickerRef.current.click();
        }
    };
    const handleClearAttachments = (event) => {
        event.preventDefault();
        clearAttachments();
    };
    const handlePrivacySettingChange = (event) => {
        event.preventDefault();
        setPrivacySetting(event.target.value);
    };
    const handleFeedRefresh = (event) => {
        event.preventDefault();
        setNumRefreshes(current => current + 1);
    };
    const handleDropChange = (files) => {
        if (files === null)
            return;
        if (filePickerRef.current !== null) {
            filePickerRef.current.files = files;
        }
        const currentFiles = [...files];
        setFiles(currentFiles);
    };
    const clearAttachments = () => {
        if (filePickerRef && filePickerRef.current) {
            filePickerRef.current.value = '';
        }
        setFiles([]);
    };
    const selectEmoji = emoji => {
        setCommentBody(currentBody => currentBody + emoji.native);
    };
    const toggleEmojiPicker = (event) => {
        const screenMiddleY = window.innerHeight / 2;
        const direction = event.screenY < screenMiddleY ? 'down' : 'up';
        setEmojiPickerDirection(direction);
        setShowEmojiPicker(current => !current);
    };
    const className = panelVariantClasses
        ? compileVariantClasses('comment-panel comments panel', panelVariantClasses)
        : 'comment-panel comments panel';
    return (React.createElement("div", { ...{ className }, "data-testid": 'comment-panel' },
        React.createElement(FileDrop, { onDrop: files => handleDropChange(files) },
            React.createElement(CommentForm, { ...{
                    isSubmitting,
                    commentBody,
                    userAvatarUrl,
                    emojiPickerDirection,
                    showEmojiPicker,
                    validationErrors,
                    privacySettingEnabled,
                    handleAttachmentTrigger,
                    handlePrivacySettingChange,
                    handleCommentBodyChange,
                    handleSubmit,
                    selectEmoji,
                    toggleEmojiPicker,
                    focusTextArea,
                    formPlaceholder
                }, variantClasses: formVariantClasses, forwardCheckboxRef: checkboxRef, forwardEmojiPickerRef: emojiPickerRef, forwardTextAreaRef: textAreaRef, itemName: itemNameText })),
        React.createElement(AttachmentUploader, { ...{ files, handleFileChange, handleClearAttachments }, forwardFilePickerRef: filePickerRef, hideFilePicker: true }),
        React.createElement(CommentFeed, { ...{
                isLoadingMoreComments,
                apiToken,
                currentUserId,
                commentableId,
                commentableType,
                comments,
                hasFetchErrors,
                isLoading,
                itemVariantClasses,
                removeComment,
                scope,
                toggleMenuEnabled,
                handleFeedRefresh,
                handleLoadMoreComments,
                hasMoreComments
            }, itemName: itemNameText, variantClasses: feedVariantClasses })));
};
export default CommentPanel;
