export const getCommentableId = (event) => {
    const { attributes } = event;
    const { action, eventableId, id } = attributes;
    if (action === 'comment_on_customer' ||
        action === 'comment_on_invoice' ||
        action === 'request_drawdown') {
        return eventableId;
    }
    return id;
};
export const getCommentableType = (event) => {
    const { attributes } = event;
    const { action } = attributes;
    if (action === 'comment_on_customer') {
        return 'customers';
    }
    if (action === 'comment_on_invoice') {
        return 'invoices';
    }
    if (action === 'request_drawdown') {
        return 'drawdowns';
    }
    return 'activities';
};
export const getCommentableScope = (event, feedType) => {
    const { attributes: { action, companyOwnerId, companyId, brokerCompanyId } } = event;
    const adminScope = feedType === 'admin';
    const adminBorrowerScope = feedType === 'borrower-scoped-admin';
    const adminBrokerScope = feedType === 'broker-scoped-admin';
    const borrowerScope = feedType === 'borrower';
    const brokerScope = feedType === 'broker';
    const brokerClientScope = feedType === 'client-scoped-broker';
    const commentAction = [
        'comment_on_customer',
        'comment_on_invoice',
        'request_drawdown'
    ].includes(action);
    if (adminScope && commentAction) {
        return `admin/borrowers/${companyOwnerId}`;
    }
    if (adminScope) {
        return 'admin';
    }
    if (adminBorrowerScope) {
        return `admin/borrowers/${companyOwnerId}`;
    }
    if (adminBrokerScope) {
        return `admin/brokers/${brokerCompanyId}`;
    }
    if (borrowerScope) {
        return 'borrower';
    }
    if (brokerScope) {
        return 'broker';
    }
    if (brokerClientScope) {
        return `broker/clients/${companyId}`;
    }
    return '';
};
