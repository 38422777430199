import fetch from 'isomorphic-fetch';
export const fetchAdmins = async (apiToken) => {
    const endpoint = `/api/admin/users?admin=true`;
    return fetch(endpoint, {
        headers: { Authorization: apiToken }
    }).then((res) => res.json());
};
export const fetchBorrowerAssignableAdmins = async (apiToken) => {
    const endpoint = `/api/admin/users/borrower_assignable_admins`;
    return fetch(endpoint, {
        headers: { Authorization: apiToken }
    }).then((res) => res.json());
};
