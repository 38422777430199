import React from 'react';
const RecipientsList = ({ recipients }) => {
    if (recipients.length > 0) {
        return (React.createElement("div", { className: 'recipients-list recipients', "data-testid": 'recipients' }, recipients.map((recipient, index) => (React.createElement("div", { key: index, className: 'recipient', "data-testid": 'recipient' },
            React.createElement("img", { src: recipient.avatarUrl, className: 'avatar' }),
            React.createElement("div", { className: 'name' }, `${recipient.firstName} ${recipient.lastName}`))))));
    }
    return (React.createElement("div", { className: 'recipients-list recipients -unset', "data-testid": 'placeholder' }, "No recipients set"));
};
export default RecipientsList;
