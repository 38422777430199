import React from 'react';
import classNames from 'classnames';
import Select from 'react-select';
const Panel = ({ isOpened, csrfToken, allocatePath, selectedIdString, companyOptions }) => {
    const panelClass = classNames('panel', {
        '-active': isOpened
    });
    return (React.createElement("form", { action: allocatePath, method: 'post', className: panelClass, "data-testid": 'panel' },
        React.createElement("h4", null, "Select a Client"),
        React.createElement("hr", null),
        React.createElement("input", { type: 'hidden', name: 'unassigned_transaction_ids', value: selectedIdString, "data-testid": 'unassigned-transaction-ids' }),
        React.createElement("div", { className: 'dropdown-select -long -full-width -no-max' },
            React.createElement(Select, { options: companyOptions, name: 'company_id', className: 'select' })),
        React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
        React.createElement("div", { className: 'buttons action-buttons flex-grid -space-between -collapse' },
            React.createElement("button", { className: 'button -reset -border', onClick: () => location.reload(), type: 'button' }, "Cancel"),
            React.createElement("button", { className: 'button -submit', type: 'submit' }, "Submit"))));
};
export default Panel;
