import fetch from 'isomorphic-fetch';
export const fetchAdjustments = async (apiToken, companyId, uploadedLedgerId) => {
    const endpoint = `/api/admin/companies/${companyId}/manual_ledger/uploaded_ledgers/${uploadedLedgerId}/cash_adjustments`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
export const createAdjustment = async (apiToken, companyId, uploadedLedgerId, adjustment) => {
    const endpoint = `/api/admin/companies/${companyId}/manual_ledger/uploaded_ledgers/${uploadedLedgerId}/cash_adjustments`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'POST',
        body: JSON.stringify(adjustment)
    }).then((res) => res.json());
};
export const fetchCustomerAdjustments = async (apiToken, borrowerId, customerId, page) => {
    const endpoint = `/api/admin/borrowers/${borrowerId}/customers/${customerId}/fetch_adjustments?page=${page}`;
    const headers = {
        Authorization: apiToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return fetch(endpoint, {
        headers,
        method: 'GET'
    }).then((res) => res.json());
};
