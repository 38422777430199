import React from 'react';
import NumberFormat from 'react-number-format';
const CommissionRatesUpfront = ({ upfrontCommission, gstIncluded, receivedApproval, setUpfrontCommission, setGstIncluded, setReceivedApproval }) => {
    const upfrontCommissionGst = upfrontCommission ? upfrontCommission * 1.1 : '';
    const isSkipped = upfrontCommission === undefined &&
        gstIncluded === false &&
        receivedApproval === false;
    const handleUpfrontCommissionChange = (event) => {
        const value = event.target.value;
        if (value) {
            const amount = Number(value);
            setUpfrontCommission(amount);
        }
        else {
            setUpfrontCommission(undefined);
        }
    };
    return (React.createElement("div", { "data-testid": 'commission-rates-upfront' },
        React.createElement("div", { className: 'form-group -description' }, "This step is optional. Click the Next button if you're done to review the details."),
        React.createElement("div", { className: 'form-row -col-2' },
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, "Upfront Commission ($)"),
                React.createElement("input", { className: 'input form-control', name: 'commission_details[upfront_commission]', type: 'number', step: 'any', defaultValue: upfrontCommission, required: !isSkipped, onChange: handleUpfrontCommissionChange, "data-testid": 'upfront-commission-input' })),
            !gstIncluded && (React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, "Upfront Commission GST ($)"),
                React.createElement(NumberFormat, { value: upfrontCommissionGst, defaultValue: '', displayType: 'input', className: 'input form-control -bare', decimalScale: 2, readOnly: true, "data-testid": 'upfront-commission-gst' })))),
        React.createElement("div", { className: 'form-group -inline' },
            React.createElement("input", { name: 'commission_details[upfront_commission_gst_included]', type: 'hidden', value: '0' }),
            React.createElement("input", { className: 'input form-control -checkbox', type: 'checkbox', value: '1', id: 'commission_details_upfront_commission_gst_included', name: 'commission_details[upfront_commission_gst_included]', defaultChecked: gstIncluded, onChange: e => setGstIncluded(e.target.checked), "data-testid": 'gst-checkbox' }),
            React.createElement("label", { className: 'label', htmlFor: 'commission_details_upfront_commission_gst_included' }, "GST included")),
        React.createElement("div", { className: 'form-group -inline' },
            React.createElement("input", { name: 'commission_details[upfront_commission_received_approval]', type: 'hidden', value: '0' }),
            React.createElement("input", { className: 'input form-control -checkbox', type: 'checkbox', value: '1', id: 'commission_details_upfront_commission_received_approval', name: 'commission_details[upfront_commission_received_approval]', defaultChecked: receivedApproval, onChange: e => setReceivedApproval(e.target.checked), "data-testid": 'approval-checkbox' }),
            React.createElement("label", { className: 'label', htmlFor: 'commission_details_upfront_commission_received_approval' }, "The upfront commission received approval by a State Manager."))));
};
export default CommissionRatesUpfront;
