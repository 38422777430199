import classNames from 'classnames';
import React from 'react';
const FundingSummaryPanel = ({ figures, isLoading }) => {
    const panelClassName = classNames('funding-summary-table data-summary summary -dividers', {
        '-third': figures.length === 3,
        '-fourth': figures.length === 4,
        '-fifth': figures.length === 5,
        '-sixth': figures.length === 6
    });
    const fundingValue = value => {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'data' }, value),
            isLoading && React.createElement("div", { className: 'loading-spinner -small' })));
    };
    const activeDebtors = figure => {
        return (React.createElement("div", { className: 'data-summary -dividers' }, figure.map((f, i) => (React.createElement("div", { className: `data-figure figure -nowrap -unpadded ${i === 0 ? `-padding-right` : `-padding-left`}`, key: f.label },
            fundingValue(f.value),
            React.createElement("div", { className: 'label -no-padding -center' }, f.label))))));
    };
    return (React.createElement("div", { className: panelClassName, "data-testid": 'funding-summary-panel' }, figures.map((figure, i) => (React.createElement("div", { className: 'data-figure figure', key: i, "data-testid": 'funding-figure' },
        React.createElement("div", { className: 'label' }, figure.label),
        figure.label === 'Number of Active Debtors'
            ? activeDebtors(figure.value)
            : fundingValue(figure.value))))));
};
export default FundingSummaryPanel;
