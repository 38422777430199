import classNames from 'classnames';
import React from 'react';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import { isImageUrl } from '../../utils';
import GalleryItem from './GalleryItem';
const AttachmentGallery = ({ attachments, columns = 2, collapseItems = false }) => {
    const galleryClass = classNames('attachment-gallery gallery', {
        '-two-column': columns === 2,
        '-five-column': columns === 5,
        '-collapse-items': collapseItems
    });
    const imageAttachments = attachments.filter(attachment => isImageUrl(attachment.file.url));
    const nonImageAttachments = attachments.filter(attachment => !isImageUrl(attachment.file.url));
    return (React.createElement(SimpleReactLightbox, null,
        React.createElement("div", { className: galleryClass, "data-testid": 'attachment-gallery' },
            React.createElement(SRLWrapper, null,
                imageAttachments.map((attachment, i) => {
                    const inNextRows = i >= columns;
                    const lastInFirstRow = i === columns - 1;
                    const collapsedItemsCount = lastInFirstRow
                        ? imageAttachments.length - columns
                        : 0;
                    return (React.createElement(GalleryItem, { ...{
                            attachment,
                            collapseItems,
                            inNextRows,
                            collapsedItemsCount
                        }, key: i, index: i }));
                }),
                nonImageAttachments.map((attachment, i) => (React.createElement(GalleryItem, { ...{ attachment }, key: i })))))));
};
export default AttachmentGallery;
