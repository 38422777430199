import React from 'react';
import CompanyName from '../CompanyName';
const OverdueTaskInvoice = ({ event }) => {
    const { attributes } = event;
    const { companyName, companyLink, customerLink, createdAtFormatted, invoiceLink, eventable: { attributes: { amountFormatted, customerName, number: invoiceNumber } } } = attributes;
    return (React.createElement("div", { className: 'event-box event', "data-testid": 'overdue-task-invoice' },
        React.createElement("div", { className: 'icon' },
            React.createElement("div", { className: 'activity-icon -overdue-invoice' })),
        React.createElement("div", { className: 'description' },
            React.createElement("div", { className: 'title' },
                "Earlypay marked invoice",
                ' ',
                React.createElement("span", { className: 'bold' },
                    React.createElement("a", { href: invoiceLink }, invoiceNumber)),
                ' ',
                "of ",
                React.createElement("span", { className: 'amount -green' }, amountFormatted),
                " for",
                ' ',
                React.createElement("a", { href: customerLink }, customerName),
                " as ineligible due to being non-AUD / 90 days overdue for",
                ' ',
                React.createElement(CompanyName, { name: companyName, companyLink: companyLink })),
            React.createElement("div", { className: 'date' }, createdAtFormatted))));
};
export default OverdueTaskInvoice;
