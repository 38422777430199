import classNames from 'classnames';
import React, { useState } from 'react';
const BOQ_COLLECTION_ACCOUNT_TYPES = ['trading', 'individual'];
const Panel = ({ banks, companies, csrfToken, isOpened, path }) => {
    const [selectedBank, setSelectedBank] = useState('');
    const panelClass = classNames('panel', {
        '-active': isOpened
    });
    return (React.createElement("form", { method: 'post', action: path, className: panelClass, "data-testid": 'panel', encType: 'multipart/form-data' },
        React.createElement("input", { type: 'hidden', name: 'authenticity_token', value: csrfToken }),
        React.createElement("div", { className: 'fields' },
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, "Bank"),
                React.createElement("div", { className: 'form-group -inline -collapse' }, banks.map((bank) => (React.createElement("div", { key: bank, className: 'input -separate' },
                    React.createElement("input", { id: bank, className: 'button -radio', name: 'bank', type: 'radio', value: bank, onChange: () => setSelectedBank(bank) }),
                    React.createElement("label", { htmlFor: bank }, bank.toUpperCase())))))),
            ['anz', 'westpac'].includes(selectedBank) && (React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, "Company"),
                React.createElement("select", { name: 'selected_company_id', id: 'selected_company_id' }, companies.map(company => (React.createElement("option", { key: company.id, value: company.id }, company.name)))))),
            selectedBank === 'boq' && (React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, "Collection account type"),
                React.createElement("div", { className: 'form-group -inline -collapse' }, BOQ_COLLECTION_ACCOUNT_TYPES.map((collection_account, index) => (React.createElement("div", { key: collection_account, className: 'input -separate' },
                    React.createElement("input", { id: collection_account, className: 'button -radio', name: 'boq_collection_account_type', type: 'radio', defaultChecked: index === 0, value: collection_account }),
                    React.createElement("label", { htmlFor: collection_account }, collection_account.toUpperCase()))))))),
            React.createElement("div", { className: 'form-group' },
                React.createElement("label", { className: 'label' }, "CSV File"),
                React.createElement("input", { name: 'csv_file', type: 'file', accept: '.csv', className: 'form-control input' }))),
        React.createElement("button", { className: 'submit' }, "Upload Data")));
};
export default Panel;
